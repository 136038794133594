var ModelController = require('./model_controller');
var ExportView = require('views/export_view');
var MiscHelper = require('lib/misc_helper');

var ExportController = ModelController.extend({
    initialize: function (options) {
        this.logger = log4javascript.getLogger('controllers.ExportController');
    },

    show: function (params) {
        this.logger.debug('viewing product with id ', params.id);
        this.view = new ExportView(params.id);

        this.view.render();
    },

    getNewModel: function(param) {

    }
});

module.exports = ExportController;