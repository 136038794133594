var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return ": "
    + escapeExpression(((helper = (helper = helpers.batchSearchName || (depth0 != null ? depth0.batchSearchName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"batchSearchName","hash":{},"data":data}) : helper)));
},"3":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <button type=\"button\" class=\"btn btn-default\" id=\"storesearchasnew\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "store.search.as.new", {"name":"lbl","hash":{},"data":data})))
    + "</button>\n";
},"5":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <button type=\"button\" class=\"btn btn-default\" id=\"backtosearchhistory\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "backto.searchhistory", {"name":"lbl","hash":{},"data":data})))
    + "</button>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "<fieldset class=\"textareabatchsearch\">\n\n";
  stack1 = this.invokePartial(partials.helpBox, '    ', 'helpBox', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n    <legend>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "batch.search.textarea.legend", {"name":"lbl","hash":{},"data":data})));
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.batchSearchName : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</legend>\n\n    <div class=\"form-group\">\n        <textarea id=\""
    + escapeExpression(((helper = (helper = helpers.batchAreaId || (depth0 != null ? depth0.batchAreaId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"batchAreaId","hash":{},"data":data}) : helper)))
    + "\" name=\""
    + escapeExpression(((helper = (helper = helpers.batchAreaId || (depth0 != null ? depth0.batchAreaId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"batchAreaId","hash":{},"data":data}) : helper)))
    + "\" placeholder=\"";
  stack1 = ((helper = (helper = helpers.batchSearchPlaceholder || (depth0 != null ? depth0.batchSearchPlaceholder : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"batchSearchPlaceholder","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"></textarea>\n    </div>\n</fieldset>\n\n\n<div class=\"form-actions\">\n\n    <button type=\"button\" class=\"btn btn-primary pull-right\" id=\"starttextareasearch\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "start.search", {"name":"lbl","hash":{},"data":data})))
    + "</button>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.batchSearchName : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    <button type=\"button\" class=\"hidden btn cancel\" id=\"cleartextareasearch\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "clear.field", {"name":"lbl","hash":{},"data":data})))
    + "</button>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.batchSearchName : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n\n\n<fieldset class=\"filebatchsearch pull-left\">\n        <div class=\"hidden\">\n            <legend>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "batch.search.fileupload.legend", {"name":"lbl","hash":{},"data":data})))
    + "</legend>\n\n            <label for=\"file\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "batch.search.fileupload.description", {"name":"lbl","hash":{},"data":data})))
    + "</label>\n            <div class=\"form-group\">\n                <label for=\"copyToText\" class=\"control-label\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "batch.search.fileupload.copyToText", {"name":"lbl","hash":{},"data":data})))
    + "</label>\n                <input type=\"checkbox\" name=\"copyToText\" id=\"copyToText\"/>\n            </div>\n            <div class=\"form-group\">\n                <label for=\"saveFile\" class=\"control-label\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "batch.search.fileupload.saveFile", {"name":"lbl","hash":{},"data":data})))
    + "</label>\n                <input type=\"checkbox\" name=\"saveFile\" id=\"saveFile\"/>\n            </div>\n            <div class=\"form-group\">\n                <label for=\"nrOfTitleLines\" class=\"control-label\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "batch.search.fileupload.nrOfTitleLines", {"name":"lbl","hash":{},"data":data})))
    + "</label>\n                <input type=\"text\" name=\"nrOfTitleLines\" id=\"nrOfTitleLines\"/>\n            </div>\n            <div class=\"form-group\">\n                <label for=\"nrOfCols\" class=\"control-label\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "batch.search.fileupload.nrOfCols", {"name":"lbl","hash":{},"data":data})))
    + "</label>\n                <input type=\"text\" name=\"nrOfCols\" id=\"nrOfCols\"/>\n            </div>\n            <div class=\"form-group\">\n                <label for=\"notFoundIds\" class=\"control-label\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "batch.search.fileupload.notFoundIds", {"name":"lbl","hash":{},"data":data})))
    + "</label>\n                <input type=\"hidden\" class=\"codelist-select\" id=\"notFoundIds\">\n            </div>\n        </div>\n\n        <div class=\"form-group form-actions fileupload-buttonbar\">\n            <span class=\"btn btn-success fileinput-button\">\n                <span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "search.batch.add.file", {"name":"lbl","hash":{},"data":data})))
    + "</span>\n                <input id=\"fileupload\" type=\"file\" multiple=\"\" name=\"file\"/>\n            </span>\n        </div>\n    </fieldset>";
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}