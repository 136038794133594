var MsgHelper = require('lib/msg_helper');
var MiscHelper = require('lib/misc_helper');

var IconrenderHelper = function () {
};

IconrenderHelper.prototype.reportStatusIcon = function (row, widget, modelKey) {
    var widgetHtml;

    widgetHtml = '<i class="report invoiceStatus-' + row.status + '" ';
    widgetHtml += '><span>' + row.status + ' </span></i>';

    return widgetHtml;
};

IconrenderHelper.prototype.invoiceStatus = function (row, widget, modelKey) {
    var widgetHtml = '';

    if(!MiscHelper.isEmpty((row[widget['property']])) && row.productType !== "journal" && !row['basic'] === true) {
        // we don't need the invoice status for journals
        var label = "label.form." + modelKey + ".list."
            + widget['propertyToUseForTitle'] + "." + row[widget['property']];
        var message = MsgHelper.getMessage(label);
        widgetHtml = '<i title="' + message + '" class="invoiceStatus-small invoiceStatus-'+ row[widget['property']] +'"><span>' + row[widget['property']] +'-Status</span></i>';
    }
    return widgetHtml;
};

IconrenderHelper.prototype.basicProduct = function (row, widget, modelKey) {
    var widgetHtml = '';
    if(row['basic'] === true) {
        var label = "label.form." + modelKey + ".list."
            + widget['propertyToUseForTitle'] + ".BASIC";
        var message = MsgHelper.getMessage(label);
        widgetHtml = '<i title="' + message + '" class="invoiceStatus-small basicStatus-BASIC"><span>' + message + '</span></i>';
    }
    return widgetHtml;
};

IconrenderHelper.prototype.feedAvailability = function (row, widget, modelKey) {

    var widgetHtml = "";
    var realValue = row[widget['property']];
    var titleValue;
    var colour = "";

    if (row.active === false) {
        colour = "grey";
        titleValue = 'deactivated';
    } else {
        titleValue = row[widget['propertyToUseForTitle']];
        if (realValue === "01") {
            colour = "green";
        } else if (realValue === "02") {
            colour = "white";
        } else if (realValue === "03") {
            colour = "yellow";
        } else if (realValue === "04") {
            colour = "red";
        }
    }
    widgetHtml += "<i class=\"vlb-status-icon vlb-status-icon-availability-"
        + colour + "\"";
    if (widget['propertyToUseForTitle']) {
        var label = "label.form." + modelKey + ".list."
            + widget['propertyToUseForTitle'] + "." + titleValue;
        if (widget['titleMsgPrefix']) {
            label = widget["titleMsgPrefix"] + "." + widget['propertyToUseForTitle'] + "." + titleValue;
        }
        var message = MsgHelper.getMessage(label);
        widgetHtml += " title='" + message + "'";
    }
    widgetHtml += "><span>" + message + "</span></i>";
    
    // we don't need the status for journal products
    if (row.productType === "journal") {
    	widgetHtml = "";
    }

    return widgetHtml;
};

IconrenderHelper.prototype.productIcon = function (row, widget, modelKey) {

    var widgetHtml = "";
    var realValue = row[widget['property']];
    if (row.set === true && realValue !== "h") {
        realValue = realValue + "-h";
    }
    if (row.series === true && realValue !== "r") {
        realValue = realValue + "-r";
    }
    widgetHtml += "<i class=\"vlb-icon vlb-icon-" + realValue + "\"";
    if (widget['propertyToUseForTitle']) {
        var title;
        if (row['productType'] === 'series') {
            title = 'SERIES';
        } else if (row['productType'] === 'set') {
            title = 'SET';
        } else {
            title = row[widget['propertyToUseForTitle']];
        }
        var label = "label.form." + modelKey + ".list."
            + widget['propertyToUseForTitle'] + "." + title;
        if (widget['titleMsgPrefix']) {
            label = widget["titleMsgPrefix"] + "." + widget['propertyToUseForTitle'] + "." + title;
        }
        var message = MsgHelper.getMessage(label);
        widgetHtml += " title='" + message + "'";
    }
    widgetHtml += "><span>" + message + "</span></i>";

    return widgetHtml;
};

module.exports = new IconrenderHelper;