var View = require('./view')
    , template = require('./templates/footer');
var ConfirmationInfo = require('views/templates/forms/confirmationInfo');
var ConfirmationHelper = require('lib/confirmation_helper');
var MsgHelper = require('lib/msg_helper');

module.exports = View.extend({

    id: 'footer',
    region: 'footer',
    className: 'footer',
    template: template,

    getTemplateData: function () {
    	return {
            versionName : versionName,
            versionAliasLink: versionAliasLink,
            currentYear: new Date().getFullYear()
        };
    },
    
    events: {
        'click #scroll-to-top': 'scrollToTop',
        'click .nav-footer a[data-toggle="confirmationSingle"]': 'confirmationSingle'
    },
    
    scrollToTop: function(event) {
    	window.scrollTo(0, 0);
    },

    confirmationSingle: function(event) {

        var scopeData = {};
        scopeData.ident = event.currentTarget.attributes["action"].value;
        var prop = 'msg.form.confirmationInfo.text.' + scopeData.ident;
        var msg = MsgHelper.getMessage(prop);
        prop = 'msg.form.confirmationInfo.link.' + scopeData.ident;
        var linkMsg = MsgHelper.getMessage(prop);
        if(linkMsg.indexOf('{{') > -1 && linkMsg.indexOf('}}') > -1) {
            var links = [];
            var linksMsg = linkMsg.split('#');
            for(var i=0; i< linksMsg.length; i++) {
                var splitLink = linksMsg[i].split('{{');
                var link = {};
                link.text = new Handlebars.SafeString(splitLink[0].trim());
                link.href = splitLink[1].substring(0, splitLink[1].length-2);
                links.push(link);
            }
            scopeData.links = links;
            scopeData.textSecondLine = new Handlebars.SafeString('<br>');
        }
        scopeData.text = new Handlebars.SafeString(msg);
        var html = ConfirmationInfo(scopeData);
        var confirmDialog = function (event) {
            $.fancybox.close();
            var div = $('#' + scopeData.ident + 'Confirmation');
            div.fadeOut('slow');
            div.remove();
        }
        ConfirmationHelper.openDialog(html, scopeData.ident, confirmDialog);
    }
});