


var XMLHelper = function() {
};

/**
 * Formats a given XML and returns the formatted xml to show it.
 *
 * @param xml
 *            the xml to format
 */
XMLHelper.prototype.formatXML = function(xml) {
    var formatted = '';
    var reg = /(>)(<)(\/*)/g;
    xml = xml.replace(reg, '$1\r\n$2$3');
    var pad = 0;
    jQuery.each(xml.split('\r\n'), function(index, node) {
        var indent = 0;
        if (node.match( /.+<\/\w[^>]*>$/ )) {
            indent = 0;
        } else if (node.match( /^<\/\w/ )) {
            if (pad != 0) {
                pad -= 1;
            }
        } else if (node.match( /^<\w[^>]*[^\/]>.*$/ )) {
            indent = 1;
        } else {
            indent = 0;
        }

        var padding = '';
        for (var i = 0; i < pad; i++) {
            padding += '  ';
        }

        formatted += padding + node + '\r\n';
        pad += indent;
    });

    return formatted;
}

/**
 * Escapes the given XML to show it in the view
 *
 * @param xml
 */
XMLHelper.prototype.escapeXML = function(xml) {
    var xml_escaped = '';
    xml_escaped = xml.replace(/&/g,'&amp;').replace(/</g,'&lt;').replace(/>/g,'&gt;').replace(/ /g, '&nbsp;').replace(/\n/g,'<br />');

    return xml_escaped;
}

module.exports = new XMLHelper;