var MsgHelper = require('./msg_helper');
var MiscHelper = require('./misc_helper');
var ExportButton = require('views/templates/forms/button_export');

var Logger = log4javascript.getLogger('lib.ActionHelper');

var ActionHelper = function () {
};

ActionHelper.prototype.getActionOnRowClick = function (formDef) {
    if (formDef.get('actionOnRowClick') !== undefined) {
        var actionName = formDef.get('actionOnRowClick');
        var allActions = formDef.get('actions');
        if (allActions !== undefined) {
            for (var i = 0; i < allActions['action'].length; i++) {
                if (actionName === allActions['action'][i]['id']
                    && allActions['action'][i]['type'] === 'ROW_ACTION') {
                    return allActions['action'][i];
                }
            }
        }
    }
    return undefined;
};

ActionHelper.prototype.getActionOnIconClick = function (formDef, widget) {
    if (widget['actionOnClick'] !== undefined) {
        var actionName = widget['actionOnClick'];
        var allActions = formDef.get('actions');
        if (allActions !== undefined) {
            for (var i = 0; i < allActions['action'].length; i++) {
                if (actionName === allActions['action'][i]['id']) {
                    return allActions['action'][i];
                }
            }
        }
    }
    return undefined;
};

ActionHelper.prototype.getTargetModel = function (action, modelKey) {
    var targetModel = modelKey;
    if (action['targetModel'] !== undefined) {
        targetModel = action['targetModel'];
    }
    return targetModel;
};

ActionHelper.prototype.buildActionUrl = function (action, modelKey, id, index) {
    var targetModel = modelKey;
    if (action['targetModel'] !== undefined) {
        targetModel = action['targetModel'];
    }
    var retVal;
    if (index !== undefined && _.string.endsWith(action['method'], 'rec')) {
        retVal = Chaplin.utils.reverse(targetModel + "_" + action['method'].toLowerCase(), [id, index]);
        retVal += '/' + modelKey;
    } else {
        retVal = Chaplin.utils.reverse(targetModel + "_" + action['method'].toLowerCase(), [id]);
    }

    return retVal;
};

ActionHelper.prototype.buildActionIcon = function (action, modelKey,
                                                   modelUrlRoot, modelId, rowId) {
    var html = "";
    if (action.type === 'PRIMARY') {
        // @TODO (HGF) Please refactor this
        html = "<a class=\"icon-link " + action['icon'];
        if (action.confirmation) {
            html += "\" data-toggle=\"confirmationSingle\" data-modelurlroot=\"/api/v1/product\" action=\""
                + action.id + "\"";
        } else if (action.targetUrl) {
            html += "\" data-toggle=\"targetURL\" data-modelurlroot=\""
                + action.targetUrl + "\" action=\"" + action.id + "\"";
        } else if (action.method.length > 0) {
            html += "\" data-toggle=\"methodCall\" data-modelurlroot=\""
                + action.method + "\" action=\"" + action.id + "\"";
        } else {
            html += "\" href=\"" + "javascript:;\"";
        }
        html += ">";
        var msg = "";

        if (action['customLabel'] === undefined) {
            msg = MsgHelper.getMessage("label.form." + modelKey + "." + action['icon']);
        } else {
            msg = MsgHelper.getMessage(action['customLabel']);
        }
        html += msg;
        html += "</a>";
    } else if (action.type === 'SECONDARY') {
        html = "<a class=\"vlb-action-icon vlb-action-icon-" + action['icon']
            + " small";
        if ("export" === action['method']) {
            html += "\" data-show-menu=\"true\"";
        } else {
            html += "\" action=\"" + action.id + "\"";
        }
        if (action['confirmation'] !== undefined) {
            // model ID can not be relevant for toggle type. So I (HGF) comment out the next lines until we have
            // further knowledges.
            if (rowId === undefined || rowId === null) {
                html += ' data-toggle=\"confirmationMultiple\"';
            } else {
                html += ' data-toggle=\"confirmationSingle\"';
            }
//        html += ' data-toggle=\"confirmationMultiple\"';
        }
        if (action.targetUrl !== undefined) {
            html += ' data-modelurlroot=\"' + action.targetUrl + '\"';
        }
        if (rowId !== undefined && rowId !== null) {
            html += ' data-id=\"' + rowId + '\"';
        } else if (modelId !== undefined && modelId !== null) {
            html += ' data-id=\"' + modelId + '\"';
        }
        if (action.style !== undefined) {
            html += ' style=\"' + action.style + '\"';
        }
        var msg = MsgHelper.getMessage("label.form." + modelKey + "."
            + action['icon']);
        html += " title=\"" + msg + "\"";
        if (action['openInNewTab'] == true) {
            html += ' target="_blank"';
            html += ' href="' + this.buildApiUrl(action['targetUrl'] + '/' + rowId, true) + '"';
        } else {
            html += " href=\"javascript:;\" onclick=\"return false;\"";
        }
        html += "><span>" + msg + "</span></a>";
        if ("export" === action['method']) {
            html += "<ul class=\"settings-menu\" style=\"display: none\">";
            html += "    <li><a class=\"onix21\">Export as Onix21</a></li>";
            html += "    <li><a class=\"csv\">Export as CSV</a></li>";
            html += "    <li><a class=\"ascii\">Export as ASCII</a></li>";
            html += "</ul>";
            html = "<div class=\"settings-menu-wrap\" id=\"exportDownload\">" + html
                + "</div>";
        }
    }

    return html;
};

ActionHelper.prototype.buildModelUrlRoot = function (modelUrlRoot, alternative) {

    var retVal = modelUrlRoot;
    if (alternative !== undefined) {
        retVal = alternative;
    }
    if (_.string.startsWith(retVal, "$api/")) {
        retVal = retVal.replace("$api/", apiRoot);
    }

    return retVal;
};

ActionHelper.prototype.buildAction = function (action, modelKey, modelUrlRoot, id, productId, model) {

    if (action === undefined) {
        return '';
    }
    if (action['appearance'] === 'LINK') {
        return this.buildActionButton(action, modelKey, modelUrlRoot, id, false, undefined, model);
    }
    if (action['appearance'] === 'ICON') {
        return this.buildActionIcon(action, modelKey, modelUrlRoot, id, model);
    }
    if (action['appearance'] === 'BUTTON') {
        return this.buildActionButton(action, modelKey, modelUrlRoot, id, true, undefined, model);
    }
    return '';
};


/**
 *
 * @param action
 * @param modelKey
 * @param modelUrlRoot
 * @param id Any id. Can be a productId.
 * @param asButton
 * @param productId Must always be a products id.
 * @returns {string}
 */
ActionHelper.prototype.buildActionButton = function (action, modelKey,
                                                     modelUrlRoot, id, asButton, productId, model) {

    if (action.showOn !== undefined) {
        if (!MiscHelper.compareComplex(model, action.showOn)) {
            return "";
        }
    }

    var html = "";
    if ("save" === action['method']) {
        var css = "btn btn-primary";
        if (action.style !== undefined) {
            css += " " + action.style;
        }
        if (!asButton) {
            css += " fl-right";
        }
        // custom label implementation
        if (action["customLabel"]) {
            html = '<button type="submit" class="' + css + '">'
                + this.buildLabel(action["customLabel"]) + '</button>';
        } else {
            html = '<button type="submit" class="' + css + '">'
                + this.buildLabel("form.button.save") + '</button>';
        }
    } else if ("saveAndEdit" === action['method'] || "saveAndBack" === action['method'] || "saveAndMmo" === action['method']) {
        var css = "btn btn-primary";
        if (!asButton) {
            css += " fl-right";
        }
        // custom label implementation
        if (action["customLabel"]) {
            html = '<button type="button" id="' + action['method'] + '" class="' + css + '">'
                + this.buildLabel(action["customLabel"]) + '</button>';
        } else {
            html = '<button type="button" id="' + action['method'] + '" class="' + css + '">'
                + this.buildLabel("form.button.save") + '</button>';
        }
    } else if ("cancel" === action['method']) {
        if (asButton) {
            html = '<button type="button" class="btn cancel">'
                + this.buildLabel(action['customLabel'] ? action['customLabel'] : "form.button.cancel") + '</button>';
        } else {
            html = '<a class="txt-underline fl-right" href="' + Chaplin.utils.reverse(modelKey + "_show", [id]) + '"><span>'
                + this.buildLabel("form.button.cancel") + '</span></a>';
        }
    } else if ("back" === action['method']) {
        var label;
        if (action['customLabel']) {
            label = this.buildLabel(action['customLabel']);
        } else {
            label = this.buildLabel("form.button.back");
        }
        html = '<a id="backBtn" class="btn btn-default">' + label + '</a>';
    } else if ("modelSave" === action['method']) {
        // TODO find better solution, maybe form prop?
        // TODO create javascript action to save model
        html = '<a class="btn green btn-arrow"><span>'
            + this.buildLabel("form.button."
                + this.getTargetModel(action, modelKey) + "." + action['method'])
            + '</span></a>';

    } else if ("addPublisherToMVBId" === action['method'] || "removePublisherFromMVBId" === action['method']) {
        var css = "btn btn-primary";
        if (action.style !== undefined) {
            css += " " + action.style;
        }
        if ("removePublisherFromMVBId" === action['method']) {
            css += " btn-danger";
        }
        if (!asButton) {
            css += " fl-right";
        }
        // custom label implementation
        if (action["customLabel"]) {
            html = '<button type="button" id="' + action['method'] + '" class="' + css + '">'
                + this.buildLabel(action["customLabel"]) + '</button>';
        }
    } else {
        html = '<a ';
        html += ' id="actionBtn-' + action.id + '"';
        var labelKey = "form.button." + this.getTargetModel(action, modelKey) + "."
            + action['method'];
        var linkTitle = this.buildLabel(labelKey);
        // try fallback to label without modelKey
        if (linkTitle === ("label." + labelKey)) {
            linkTitle = this.buildLabel("form.button." + action['method']);
        }
        // custom label implementation
        if (action["customLabel"]) {
            linkTitle = this.buildLabel(action["customLabel"]);
        }
        if (!MiscHelper.isEmpty(action.icon)) {
            var icon = action['icon'];
            // TODO do we still need that?
            if (icon === "trash") {
                icon += "-o";
            }
            linkTitle = '<span class="fa fa-' + icon + '">' + linkTitle + '</span>';
        } else if (asButton) {
            html += ' class="btn green btn-arrow' + (action.style ? ' ' + action.style : '') + '"';
        } else {

        }

        if (action['confirmation'] !== undefined) {
            var toggleType = "confirmationSingle";
            if (action.scope === 'list') {
                toggleType = "confirmationMultiple";
            }

            html += 'action="' + action.id + '" data-placement="top" data-toggle="'
                + toggleType + '" data-original-title="" data-modelUrlRoot="'
                + this.buildModelUrlRoot(modelUrlRoot, action['targetUrl']) + '"';
            if (action.scope !== 'list') {
                html += 'data-id="' + id + '"';
                if (productId !== undefined) {
                    html += ' data-productId="' + productId + '"';
                }
            }

        } else if (action.type === 'SECONDARY' || action.type === 'TERTIARY') {
            if (action.scope === 'form') {
                html += ' href="' + this.buildActionUrl(action, modelKey, id) + '"';
            } else {
                // is bind in listview, so we can work on the selected rows
                html += 'action="' + action.id + '" data-toggle="selection" ';
            }
        } else if (action['targetUrl'] !== undefined) {
            if (MiscHelper.isEmpty(id)) {
                html += ' href="'
                    + this.buildApiUrl(action['targetUrl'], true) + '"';
            } else {
                html += ' href="'
                    + this.buildApiUrl(action['targetUrl'] + '/' + id, true) + '"';
            }
        } else if (action['method'] === 'downloadQSReport') {

        } else {
            var parsedId = id;
            if (!MiscHelper.isEmpty(parsedId) && parsedId.indexOf('#') > -1) {
                parsedId = parsedId.substring(parsedId.indexOf('#') + 1);
            }
            html += ' href="' + this.buildActionUrl(action, modelKey, parsedId);
            if (action['scrollToId'] !== undefined) {
                html += '/' + action['scrollToId'] + '"';
            }
            html += '"';
        }

        if (action['openInNewTab'] == 'true') {
            html += ' target="_blank"';
        }

        html += '><span>' + linkTitle + '</span></a>';
    }

    return html;
};

ActionHelper.prototype.buildApiUrl = function (urlPattern, tokenInUrl) {
    if (urlPattern) {
        var url = "";
        if (_.string.startsWith(urlPattern, "$api/")) {
            url = urlPattern.replace("$api/", apiRoot);
            if (tokenInUrl) {
                url += '?access_token=' + user.access_token;
            }
        } else {
            url = applicationRoot + "/" + urlPattern;
        }
        return url;
    }
    throw new Error("url pattern is undefined");

};

ActionHelper.prototype.buildLabel = function (key) {
    var theKey = "label." + key;
    var theMessage = MsgHelper.getMessage(theKey);
    var theReplacedMessage = theMessage;
    if(theReplacedMessage.indexOf("{year") !== -1){
    	theReplacedMessage = MsgHelper.replaceMessagePropertyYearPlaceHolder(theReplacedMessage);
    }
    return theReplacedMessage;
};

ActionHelper.prototype.findActionById = function (formDef, actionId) {
    // look up form actions
    if (formDef.get('actions') !== undefined) {
        for (var i = 0; i < formDef.get('actions').action.length; i++) {
            if (formDef.get('actions').action[i].id === actionId) {
                return formDef.get('actions').action[i];
            }
        }
    }
    if (formDef.get('column')) {
        // check for column actions (only in last column)
        var lastColumn = formDef.get('column')[formDef.get('column').length - 1];
        if (lastColumn.action !== undefined) {
            for (var i = 0; i < lastColumn.action.length; i++) {
                if (lastColumn.action[i].id === actionId) {
                    return lastColumn.action[i];
                }
            }
        }
    }
    var blocks = formDef.get('block');
    if (blocks) {
        for (var i = 0; i < blocks.length; i++) {
            var fieldSets = blocks[i].fieldSet;
            for (var j = 0; j < fieldSets.length; j++) {
                var columns = fieldSets[j].column;
                for (var k = 0; k < columns.length; k++) {
                    var actions = columns[k].action;
                    for (var l = 0; l < actions.length; l++) {
                        if(actions[l].id === actionId) {
                            return actions[l];
                        }
                    }
                }
            }
        }
    }
};

ActionHelper.prototype.prepareHeaderActions = function (formDef, scope, model) {
    // formDef.hasPrimaryAction = false;
    if (formDef.get('actions') && formDef.get('actions').action) {
        for (var i = 0; i < formDef.get('actions').action.length; i++) {
            var action = formDef.get('actions').action[i];
            if (action.type === 'PRIMARY' && action.showOn !== 'false') {
                formDef.set('hasPrimaryAction', true);
            }
            if (formDef.get('actions')[action.type] === undefined) {
                formDef.get('actions')[action.type] = [];
            }
            action.scope = scope || 'list'; // add indiator on action if its for the list
            // (selection) or a single row
            if (model && action.showOn !== undefined) {
                var show = MiscHelper.compareComplex(model, action.showOn);
                if (show) {
                    formDef.get('actions')[action.type].push(action);
                }
            } else {
                formDef.get('actions')[action.type].push(action);
            }

            if (action.confirmation) {
                if (formDef.get('actions')[action.confirmation] === undefined) {
                    formDef.get('actions')[action.confirmation] = [];
                }
                if (model && action.showOn !== undefined) {
                    var show = MiscHelper.compareComplex(model, action.showOn);
                    if (show) {
                        formDef.get('actions')[action.confirmation].push(action);
                    }
                } else {
                    formDef.get('actions')[action.confirmation].push(action);
                }
            }
        }
    }
};

module.exports = new ActionHelper;