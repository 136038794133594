
var Model = require('./model');

var Menu = Model.extend({
    urlRoot: applicationRoot + "/menu",
    defaults: {
        "id": null
    },

    fetch: function (options) {
    	var theOptions = options ? options : {};
        theOptions['cache'] = false;
        theOptions['async'] = false;
        Model.prototype.fetch.call(this, theOptions);
    }

});

module.exports = Menu;