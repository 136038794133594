var UserHelper = require('lib/user_helper');

var Logger = log4javascript.getLogger('lib.MiscHelper');

var MiscHelper = function () {
};

MiscHelper.prototype.padding = function (num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
};

MiscHelper.prototype.annualInvoiceYear = function () {

    var d = new Date();
    d.setMonth(d.getMonth() + 2);

    return d.getFullYear();
};

MiscHelper.prototype.isEmpty = function (string) {
    return (string === undefined) || (string === null) || (string.length === 0);
};

MiscHelper.prototype.removeEscapeCharsFromString = function (string) {
    var regex1 = /[\t]/g, // regular expression for escape characters:
        // tabulator (\t)
        regex2 = /[ ]{2,}/g; // regular expression for removing all whitespaces >
    // 2

    // replace excape characters with whitespace, trim and return
    return string.replace(regex1, " ").replace(regex2, " ").trim();
}

MiscHelper.prototype.buildProductName = function (title, author, ident) {
    var retVal = "";
    if (title) {
        retVal += title;
    }
    if (author) {
        if (retVal.length > 0) {
            retVal += " \u2013 ";
        }
        retVal += author.split(";")[0].trim();
    }
    if (ident) {
        retVal += " (" + ident + ")";
    }

    return retVal;
};

MiscHelper.prototype.buildProductNameByProduct = function (product) {
    var ident;
    if (product.isbnHyphens) {
        ident = product.isbnHyphens;
    } else if (product.gtin) {
        ident = product.gtin;
    }

    return this.buildProductName(product.title, product.author, ident);
};

MiscHelper.prototype.formatNumber = function (aNumber) {
    var number = '' + aNumber;
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(number)) {
        number = number.replace(rgx, '$1' + '.' + '$2');
    }

    return number;
};

/**
 * adds a new value to a json object to a probably dot-separated property
 *
 * @param obj
 *            the object to add the value to
 * @param property
 *            the property the value is stored in
 * @param val
 *            the value to store
 */
MiscHelper.prototype.addValueToProp = function (obj, property, val) {
    var path = property.split(".");

    for (var i = 0, tmp = obj; i < path.length - 1; i++) {
        if (!tmp[path[i]]) {
            tmp = tmp[path[i]] = {};
        } else {
            tmp = tmp[path[i]];
        }
    }

    tmp[path[i]] = val;
};

MiscHelper.prototype.getPropertiesStartsWith = function (myObject, prefix) {
    var startsWiths = new Object();
    for (var name in myObject) {
        if (myObject.hasOwnProperty(name) && name.indexOf(prefix) === 0) {
            startsWiths[name] = myObject[name];
        }
    }
    return startsWiths;
};

MiscHelper.prototype.normalizeBatches = function (rawBatches) {
    return this.getBatchIdArray(rawBatches).join(" ");
};

MiscHelper.prototype.getBatchIdArray = function (rawBatches) {
    if (this.isEmpty(rawBatches)) {
        return;
    }
    rawBatches = rawBatches.trim();
    // previous regEx was '/\b\s+/'. But this leads to errors if an ID ends with
    // an '*':
    var rawArray = rawBatches.split(/\s+/);
    var idArray = [];
    for (var i = 0; i < rawArray.length; i++) {
        if (!this.isEmpty(rawArray[i].trim())) {
            idArray.push(rawArray[i].replace(/-/g, ''));
        }
    }
    return idArray;
};

MiscHelper.prototype.compareComplex = function (dto, expression) {

    if (expression === undefined) {
        return false;
    }

    var regEx = new RegExp('\\|{2}|&{2}', 'g');
    var simpleEx = expression.split(regEx);
    var matches = expression.match(regEx);
    var lValueOuter;
    var rValueOuter;
    for (var i = 0; i < simpleEx.length; i++) {
        var expression = simpleEx[i].trim().split(" ");
        if (expression.length === 1) {
            if (expression[0] === 'true') {
                lValueOuter = true;
            } else {
                lValueOuter = false;
            }
            break;
        }
        var lValueSplits = expression[0].split('.');
        var lValue = dto[lValueSplits[0]];
        for (var j = 1; j < lValueSplits.length; j++) {
            lValue = lValue[lValueSplits[j]];
        }
        var operator = expression[1];
        var rValue = expression[2];
        if (rValue === 'false') {
            rValue = false;
        } else if (rValue === 'true') {
            rValue = true;
        } else if (rValue === 'null') {
            rValue = null;
        }
        rValueOuter = this.compare(lValue, operator, rValue);
        if (lValueOuter === undefined) {
            lValueOuter = rValueOuter;
        } else {
            lValueOuter = this
                .compare(lValueOuter, matches[i - 1], rValueOuter);
        }
    }

    return lValueOuter;
};

MiscHelper.prototype.uuid = function () {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

MiscHelper.prototype.compare = function (lvalue, operator, rvalue) {

    if (arguments.length < 3) {
        if (arguments.length === 1) {
            if (lvalue === 'true') {
                return true;
            } else if (lvalue === 'false') {
                return false;
            }
        }
        throw new Error(
            "Misc Helper 'compare' needs 3 parameters, true or false");
    }

    var operators = {
        '&&': function (l, r) {
            return l && r;
        },
        '||': function (l, r) {
            return l || r;
        },
        '==': function (l, r) {
            return l == r;
        },
        '===': function (l, r) {
            if (r === 'empty') {
                return MiscHelper.prototype.isEmpty(l);
            }
            return l === r;
        },
        '!=': function (l, r) {
            return l != r;
        },
        '!==': function (l, r) {
            if (r === 'empty') {
                return !MiscHelper.prototype.isEmpty(l);
            }
            return l !== r;
        },
        '<': function (l, r) {
            return l < r;
        },
        '>': function (l, r) {
            return l > r;
        },
        '<=': function (l, r) {
            return l <= r;
        },
        '>=': function (l, r) {
            return l >= r;
        },
        'typeof': function (l, r) {
            return typeof l == r;
        }
    }

    if (!operators[operator])
        throw new Error(
            "Handlerbars Helper 'compare' doesn't know the operator "
            + operator);

    var result = operators[operator](lvalue, rvalue);

    return result;

};

MiscHelper.prototype.regExTest = function (regEx, string) {

    var re = new RegExp(regEx);

    return re.test(string);

};

MiscHelper.prototype.checkDecimalNumberOnKeyPressed = function (e,
                                                                decimalPositions) {

    var key = e.key === undefined ? String.fromCharCode(e.keyCode) : e.key;
    var charCode = e.which || e.keyCode;

    if (key === groupingCharacter) {
        return false;
    }
    if ($.inArray(charCode, [8, 9, 13, 27, 46]) !== -1
        ||
        // Allow: backspace, tab, enter, escape, delete
        ((charCode >= 35 && charCode <= 39) && key.length > 1)
        ||
        // Allow: home, end, left, right
        (e.ctrlKey === true && (charCode === 97 || charCode === 99
        || charCode === 36 || charCode === 118 || charCode === 120))
    // ctrl a, ctrl c, ctrl home, ctrl v, ctrl x
    ) {
        return;
    }
    if (charCode === 48
        && (this.getSelectionStart(e.target) < 2
        && _.string.startsWith(e.target.value, '0') || this
            .getSelectionStart(e.target) === 0
        && e.target.value.split(decimalSeparator)[0].length > 0)) {
        return false;
    }
    if (charCode != 44 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    if (charCode == 44 && e.target.value.indexOf(decimalSeparator) !== -1) {
        return false;
    }

    if (e.target.value.indexOf(decimalSeparator) !== -1) {
        if (decimalPositions === undefined) {
            return;
        }
        var dp = decimalPositions;
        if ((typeof dp).toLowerCase() === 'string') {
            dp = parseInt(dp);
        }
        if (dp !== -1) {
            if (dp === -2 || dp === 0) {
                return false;
            } else {
                var caratPos = this.getSelectionStart(e.target);
                var output = [e.target.value.slice(0, caratPos), key,
                    e.target.value.slice(caratPos)].join('');
                if (output.split(decimalSeparator)[1].length > dp) {
                    return false;
                }
            }
        }
    }
}

// thanks: http://javascript.nwbox.com/cursor_position/
MiscHelper.prototype.getSelectionStart = function (o) {
    if (o.createTextRange) {
        var r = document.selection.createRange().duplicate()
        r.moveEnd('character', o.value.length)
        if (r.text == '')
            return o.value.length
        return o.value.lastIndexOf(r.text)
    } else
        return o.selectionStart
};

MiscHelper.prototype.getDbQueryStringForAggregatorIds = function (arrAggregatorId) {

    var retVal = [];
    for (var i = 0; i < arrAggregatorId.length; i++) {
        retVal.push(this.getDbQueryStringForAggregatorId(arrAggregatorId[i]));
    }
    return retVal;
};

MiscHelper.prototype.getDbQueryStringForAggregatorId = function (aggregatorId) {

    var retVal;
    if (aggregatorId === '0') {
        retVal = 'vlb';
    } else if (aggregatorId === '1') {
        retVal = 'archive';
    } else if (aggregatorId === '5001015') {
        retVal = 'osb';
    }
    // as we don't have any umbreit data anymore we don't need to add this to
    // the db list - changed with ticket #13586
    // else if (aggregatorId === '5109037') {
    // retVal = 'umbreit';
    // }
    return retVal;

};

MiscHelper.prototype.getAllDbQueryString = function (mode) {
    if (mode === 'quick') {
        return 'db=vlb';
    }
    return 'db='
        + this.getDbQueryStringForAggregatorIds(
            UserHelper.dataAreaAuthorities()).join(',');
};

MiscHelper.prototype.getAggregatorIds = function (databasesObject) {
    var retVal = [];
    if (databasesObject.archive === true) {
        retVal.push(1);
    }
    if (databasesObject.osb === true) {
        retVal.push(5001015);
    }
    if (databasesObject.vlb === true) {
        retVal.push(0);
        retVal.push(5109037);
    }
    return retVal;
};

MiscHelper.prototype.stripDatabaseFilterFromQuery = function (query) {

    var retVal = query;
    var regEx = new RegExp('(db=)((\\w|,)+)');
    var match = regEx.exec(retVal);
    if (match !== null) {
        retVal = query.replace(match[0], '');
        retVal = retVal.trim();
        if (_.string.endsWith(retVal, ' und')) {
            retVal = retVal.substr(0, retVal.length - 4);
        }
    }

    return retVal;
};

MiscHelper.prototype.rebuildQuery = function (query, database, add,
                                              databasesObject, mode) {

    var retVal = {};
    var regEx = new RegExp("db=");
    var simpleEx = query.split(regEx);

    if (regEx.test(query)) {
        var equals = query.split('=');
        for (var i = 0; i < equals.length - 1; i++) {
            var words = equals[i].split(" ");
            var afterWords = equals[i + 1].split(' ');
            var cat = words[words.length - 1];
            if (cat === 'db') {
                var newString = 'db='
                if (add) {
                    newString += afterWords;
                    if (afterWords.length > 0) {
                        newString += ',';
                    }
                    newString += database;
                } else {
                    var databasesArray = afterWords[0].split(',');
                    var databases = {};
                    for (var j = 0; j < databasesArray.length; j++) {
                        if (databasesArray[j] !== database) {
                            if (newString.length > 'db='.length) {
                                newString += ','
                            }
                            newString += databasesArray[j];
                        }
                    }
                }
                newString = this.normalizeDbQuery(newString);
                if (newString.length > 'db='.length) {
                    var iStartIndex = query.indexOf(equals[i])
                        + equals[i].length - 2;
                    var iEndIndex = iStartIndex
                        + equals[i + 1].split(' ')[0].length + 3;
                    retVal.query = query.substr(0, iStartIndex) + newString
                        + query.substr(iEndIndex);
                } else {
                    var iStartIndex = query.indexOf(equals[i])
                        + equals[i].length - 2;
                    var iEndIndex = iStartIndex
                        + equals[i + 1].split(' ')[0].length + 3;
                    retVal.query = query.substr(0, iStartIndex);
                    retVal.query = retVal.query.trim();
                    retVal.query = query.substr(0, iStartIndex)
                        + query.substr(iEndIndex);
                }
                retVal.dbFilter = newString;
                break;
            }
        }
    } else {
        var dbFilter = 'db=';
        var rValue = '';
        var length = Object.keys(databasesObject).length;
        var i = 0;
        for (var db2 in databasesObject) {
            i++;
            if ((database === db2 && add === true)
                || (databasesObject[db2] && (database !== db2))) {
                if (rValue.length > 0 && i <= length) {
                    rValue += ','
                }
                rValue += db2;
            }
        }
        rValue = this.normalizeDbQuery(rValue);
        dbFilter += rValue;
        if (mode === 'batch') {
            retVal.query = query + ' und ' + dbFilter;
        } else {
            if (this.isEmpty(query)) {
                retVal.query = dbFilter;
            } else {
                retVal.query = query + ' und ' + dbFilter;
            }
        }
        retVal.dbFilter = dbFilter;
    }
    retVal.query = retVal.query.trim();
    if (_.string.endsWith(retVal.query, ' und')) {
        retVal.query = retVal.query.substr(0, retVal.query.length - 4);
    }

    return retVal;
};

MiscHelper.prototype.normalizeDbQuery = function (query) {

    var retVal = '';
    var databasesArray;
    var retValStartsWithDb = _.string.startsWith(query, 'db=');

    if ((retValStartsWithDb && query.length > 'db='.length)
        || (!retValStartsWithDb && query.trim().length > 0)) {
        var databases;
        if (retValStartsWithDb) {
            databases = query.split('=')[1];
        } else {
            databases = query;
        }
        databasesArray = databases.split(',');
    }
    if (databasesArray === undefined) {
        databasesArray = [];
        var usersDatabase = this.getDbQueryStringForAggregatorIds(UserHelper
            .dataAreaAuthorities());
        $('input[name^="database"]').each(function () {
            if (_.contains(usersDatabase, this.id.split('_')[1])) {
                databasesArray.push(this.id.split('_')[1]);
            }
        });
    }

    databasesArray.sort(function (a, b) {
        if (a < b)
            return -1;
        if (b < a)
            return 1;
        return 0;
    });
    for (var i = 0; i < databasesArray.length; i++) {
        retVal += databasesArray[i];
        if (i + 1 < databasesArray.length) {
            retVal += ',';
        }
    }

    if (retValStartsWithDb) {
        retVal = 'db=' + retVal;
    }

    return retVal;
};

MiscHelper.prototype.getReplacements = function (regex, str) {
    var replacements = {};
    var regexp = new RegExp(regex, "ig");
    var result = regexp.exec(str);

    while (result != null) {
        var index = result.index;
        var lastIndex = regexp.lastIndex;
        var replacement = str.substring(index, lastIndex);
        var substr = str.substring(lastIndex);
        if (result.length > 1 && (typeof result[1] !== "undefined" || typeof result[3] !== "undefined")) {
            var params = {};
            var paramIndex = 1;
            for (var i = 1; i < result.length; i++) {
                var p = result[i];
                if (p !== undefined) {
                    params["param" + paramIndex] = p;
                    paramIndex++;
                } else {
                    paramIndex > 1 ? paramIndex-- : 1;
                }
            }
            replacements[replacement] = params;
        } else {
            replacements[replacement] = null;
        }
        regexp = new RegExp(regex, "ig");
        result = regexp.exec(substr);
        str = substr;
    }
    return replacements;
};

MiscHelper.prototype.getDatabases = function (query) {
    var equals = query.split("=");
    for (var i = 0; i < equals.length - 1; i++) {
        var words = equals[i].split(" ");
        var afterWords = equals[i + 1].split(" ");
        var cat = words[words.length - 1];
        if (cat == 'db') {
            var databases = {};
            var databasesArray = afterWords[0].split(',');
            for (db in databasesArray) {
                databases[databasesArray[db]] = true;
            }
        }
    }
    return databases;
};

MiscHelper.prototype.buildModelPathFromUiId = function (id) {

    var retVal = '';
    var split = id.split('_');
    var index = split.length - 1;
    while (index >= 0) {
        if (Number.isInteger(parseInt(split[index]))) {
            retVal = split[index] + '.' + split[index - 1];
            index--;
        } else {
            if(retVal.length > 0) {
                retVal = split[index] + '.' + retVal;
            } else {
                retVal = split[index];
            }
        }
        index--;
    }

    return retVal;
};

MiscHelper.prototype.keywordsSequenceCorrector = function (model) {
    try {
        if (model.get('classifications')) {
            let classifications = model.get('classifications');
            let keywords = model.get('classifications').filter(function (a) {
                return (a.type === '20' && a.sequence == null);
            });

            for (let keyword in keywords) {
                if (keywords.hasOwnProperty(keyword) && classifications.find(function (a) {
                    return a.id === keywords[keyword].id;
                })) {
                    classifications.find(function (a) {
                        return a.id === keywords[keyword].id;
                    }).sequence = getClassificationSequenceMax(classifications) + 1;
                }
            }
            model.set('classifications', classifications);
        }
    } catch (err) {
        Logger.error("Can't correct keyword sequence. There may remain keywords without sequence.", err);
    }
};

function getClassificationSequenceMax(classifications) {
    let len = classifications.length, maxSequence = 0;
    while (len--) {
        if (Number(classifications[len].sequence) > maxSequence) {
            maxSequence = Number(classifications[len].sequence);
        }
    }
    return maxSequence;
}

module.exports = new MiscHelper;