var MsgHelper = require('lib/msg_helper');
var MiscHelper = require('lib/misc_helper');
var EditableWidget = require('./editable');
var FormHelper = require('lib/form_helper');

// number widgets

var NumberWidget = EditableWidget.extend({
    initialize: function (widget, modelKey, parentProperty, index) {
        EditableWidget.prototype.initialize.call(this, widget, modelKey, parentProperty, index);
    },

    binding: function () {
        if (this.doRender) {
            return [{
                name: this.name,
                bindingSelector: {selector: this.bindingSelector, converter: this.buildConverter()}
            }];
        } else {
            return [];
        }
    },

    format: function(value) {
    	var vString = "";
        var numDps = (value.toString().split('.')[1] || []).length;
        if (numDps > 3) {
            vString = value.toFixed(3);
        } else {
            vString = value.toString();
        }
        return vString;
    },
    
    buildConverter: function () {
        var self = this;
        var numberConverter = function (direction, value, attr) {
        	if (MiscHelper.isEmpty(value)) {
        		if (self.textWhenEmpty !== "") {
        			return MsgHelper.getMessage(self.textWhenEmpty);
        		} else {
        			return "";
        		}
            }

            if (direction === 'ModelToView') {
                if (self.unitConversion !== undefined) {
                    value = value / self.unitConversion;
                }
                var vString = "";
                if (self.decimalPlace > -1) {
                    if((typeof value).toLowerCase() === 'string') {
                        value = parseFloat(value);
                    }
                    vString = value.toFixed(self.decimalPlace);
                }
                else {
                	vString = NumberWidget.prototype.format.call(this, value);
                }
                if(decimalSeparator != '.') {
                    vString = vString.replace(/\./, ",");
                }
                return vString
            }

                if(decimalSeparator != '.') {
                    value = value.replace(decimalSeparator, ".");
                }
            value = parseFloat(value.replace(/[^\d.]/g, ''));
            if (self.unitConversion !== undefined) {
                value = value * self.unitConversion;
            }

            return value;
        };
        return numberConverter;
    },

    keypress: function (e) {
        var retVal = MiscHelper.checkDecimalNumberOnKeyPressed(e, $(e.target).attr("data-decimalPlace"));
        return retVal;
    }

});


module.exports = NumberWidget;
