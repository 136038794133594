var Model = require('models/model');
var MiscHelper = require('lib/misc_helper');

var TabHelper = function() {
};

/**
 * checks the given tables for their status and executes a site determined
 * action if loading was completed in all tables
 * 
 * @param loadingTables
 *            all tables that are asynchronously loaded on the current page
 * @param page
 *            the name of the page the user is currently on - determines what
 *            action to take after data is loaded
 */
TabHelper.prototype.actionAfterLoad = function(loadingTables, page) {
	for (var table in loadingTables) {
		// not all tables loaded yet - return
		if (loadingTables[table].status !== "done") {
			return;
		}
	}

	if (page === 'logentry') {
		for (var table in loadingTables) {
			if (loadingTables[table].resultcount > 0) {
				switch (table) {
				default:
				case "fileImportState\\.errorIsbns":
					$('#tabgroup-isbns a[href="#tab-errors-tab"]').click();
					break;
				case "fileImportState\\.warningIsbns":
					$('#tabgroup-isbns a[href="#tab-warnings-tab"]').click();
					break;
				case "fileImportState\\.okIsbns":
					$('#tabgroup-isbns a[href="#tab-oks-tab"]').click();
					break;
				}
				break;
			}
		}
	}
}

module.exports = new TabHelper;