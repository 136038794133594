var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.hasRight || (depth0 && depth0.hasRight) || helperMissing).call(depth0, "PUBLISHER_BASIC", {"name":"hasRight","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\r\n";
  stack1 = ((helpers.hasRight || (depth0 && depth0.hasRight) || helperMissing).call(depth0, "!PUBLISHER_BASIC", {"name":"hasRight","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <div class=\"row\">\r\n                 <div class=\"control-group invoiceStatus invoiceStatus-mb-basic\">\r\n                    <div class=\"col-md-2\"><label\r\n                            class=\"control-label\">"
    + escapeExpression(((helpers.msg || (depth0 && depth0.msg) || helperMissing).call(depth0, "label.form.product.field.productQualityStatus.currentStatus", (depth0 != null ? depth0.year : depth0), {"name":"msg","hash":{},"data":data})))
    + "</label>\r\n                    </div>\r\n                    <div class=\"col-md-1 spacer\"></div>\r\n                    <div class=\"col-md-9\">\r\n                        <div class=\"controls\">\r\n                            <span class=\"readonlyText\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.block.productHeader.mb.basic", {"name":"lbl","hash":{},"data":data})))
    + "</span>\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n            </div>\r\n";
},"4":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.show : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "                <div class=\"text-primary col-sm-12 hint-top\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.productQualityStatus.hint", {"name":"lbl","hash":{},"data":data})))
    + "</div>\r\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.invoiceStatus : stack1), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\r\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.invoices : depth0), {"name":"each","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\r\n                <div class=\"row\">\r\n                    <div class=\"control-group\">\r\n                        <div class=\"col-md-2\"><label\r\n                                class=\"control-label\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.productQualityStatus.relevantAttributes", {"name":"lbl","hash":{},"data":data})))
    + "</label>\r\n                        </div>\r\n                        <div class=\"col-md-1 spacer\"></div>\r\n                        <div class=\"col-md-9 keyValueIcon\">\r\n                            <div class=\"controls relevantAttributes\">\r\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.stateRelevantAttributes : depth0), {"name":"each","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "                            </div>\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n\r\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.ageClass : depth0), {"name":"if","hash":{},"fn":this.program(13, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, functionType="function";
  return "                    <div class=\"row\">\r\n                        <div class=\"control-group invoiceStatus invoiceStatus-"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.invoiceStatus : stack1), depth0))
    + "\">\r\n                            <div class=\"col-md-2\"><label\r\n                                    class=\"control-label\">"
    + escapeExpression(((helpers.msg || (depth0 && depth0.msg) || helperMissing).call(depth0, "label.form.product.field.productQualityStatus.currentStatus", (depth0 != null ? depth0.year : depth0), {"name":"msg","hash":{},"data":data})))
    + "</label>\r\n                            </div>\r\n                            <div class=\"col-md-1 spacer\"></div>\r\n                            <div class=\"col-md-9\">\r\n                                <div class=\"controls\">\r\n                                    <span class=\"readonlyText\">"
    + escapeExpression(((helper = (helper = helpers.invoiceStatus || (depth0 != null ? depth0.invoiceStatus : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"invoiceStatus","hash":{},"data":data}) : helper)))
    + "</span>\r\n                                </div>\r\n                            </div>\r\n                        </div>\r\n                    </div>\r\n                    <br>\r\n";
},"8":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "                    <div class=\"row\">\r\n                        <div class=\"control-group\">\r\n                            <div class=\"col-md-2\"><label\r\n                                    class=\"control-label\">"
    + escapeExpression(((helpers.msg || (depth0 && depth0.msg) || helperMissing).call(depth0, "label.form.product.field.productQualityStatus.confirmState", (depth0 != null ? depth0.year : depth0), {"name":"msg","hash":{},"data":data})))
    + "</label>\r\n                            </div>\r\n                            <div class=\"col-md-1 spacer\"></div>\r\n                            <div class=\"col-md-9 keyValueIcon\">\r\n                                <div class=\"controls\">\r\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.availabilities : depth0), {"name":"each","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                                    <div class=\"text-primary col-md-12 hint\"\r\n                                         style=\"padding-left: 8px;\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.productQualityStatus.availability.hint", {"name":"lbl","hash":{},"data":data})))
    + "</div>\r\n                                </div>\r\n                            </div>\r\n                        </div>\r\n                    </div>\r\n                    <br>\r\n";
},"9":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                                        <div class=\"col-md-3 stateRelevant-"
    + escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"value","hash":{},"data":data}) : helper)))
    + "\">\r\n                                            <span class=\"readonlyText\">"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.product.field.productQualityStatus.availability", (depth0 != null ? depth0.label : depth0), {"name":"label2","hash":{},"data":data})))
    + escapeExpression(((helper = (helper = helpers.year || (depth0 != null ? depth0.year : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"year","hash":{},"data":data}) : helper)))
    + "</span>\r\n                                        </div>\r\n";
},"11":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                                    <div class=\"col-md-4 stateRelevant-"
    + escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"value","hash":{},"data":data}) : helper)))
    + "\">\r\n                                        <span class=\"readonlyText\">"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.product.field.productQualityStatus.relevantAttributes", (depth0 != null ? depth0.label : depth0), {"name":"label2","hash":{},"data":data})))
    + "</span>\r\n                                    </div>\r\n";
},"13":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                    <br>\r\n\r\n                    <div class=\"row\">\r\n                        <div class=\"control-group\">\r\n                            <div class=\"col-md-2\"><label\r\n                                    class=\"control-label\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.productQualityStatus.category", {"name":"lbl","hash":{},"data":data})))
    + "</label>\r\n                            </div>\r\n                            <div class=\"col-md-1 spacer\"></div>\r\n                            <div class=\"col-md-9\">\r\n                                <div class=\"controls\">\r\n                                    <span class=\"readonlyText\">"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.product.field.productQualityStatus.ageClass", (depth0 != null ? depth0.ageClass : depth0), {"name":"label2","hash":{},"data":data})))
    + "</span>\r\n                                </div>\r\n                            </div>\r\n                        </div>\r\n                    </div>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "<div id=\"productQualityStatus\">\r\n";
  stack1 = ((helpers.productBlock || (depth0 && depth0.productBlock) || helperMissing).call(depth0, "productQualityStatus", {"name":"productBlock","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}