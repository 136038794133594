var Model = require('models/model');
var logentryWarningToggleWidget = require('views/templates/forms/logentryWarningToggle');
var logEntryFilter = require('views/templates/forms/logentryfilter');

var LogEntryHelper = function() {
};


LogEntryHelper.prototype.initWarningToggle = function(table) {
	if ($('#logEntryWarningToggle').size() === 0) {
		// initialize html
		$('#' + table + " thead th.columnHeader-1").each(function(index, item) {
			$(item).append(logentryWarningToggleWidget(this));
		});
		
		// register click event
		$('#logEntryWarningToggle').click(function() {
			if ($('#logEntryWarningToggle').is(':checked')) {
				$('.cflWarning').show();				
			} else {
				$('.cflWarning').hide();				
			}
		});
	}
	
	// disable or enable after init
	if ($('#logEntryWarningToggle').is(':checked')) {
		$('.cflWarning').show();				
	} else {
		$('.cflWarning').hide();				
	}
};

LogEntryHelper.prototype.initFilter= function() {
	var filter = {};
	filter.isbn = "form.aggregatedLogentry.field.isbn";
	filter.code = "form.aggregatedLogentry.field.code";
	filter.button = "form.aggregatedLogentry.field.filter";
	return logEntryFilter(filter, this);
}

module.exports = new LogEntryHelper;