// taken from http://log4javascript.org/docs/manual.html#appender
var Logger = function() {
    this.rootLogger = log4javascript.getRootLogger();

    var appender = new log4javascript.BrowserConsoleAppender();
    var logLayout = new log4javascript.PatternLayout("%d{dd.MM.yyyy HH:mm:ss} %-5p %c - %m{1}%n");
    appender.setLayout(logLayout);

    this.rootLogger.setLevel(log4javascript.Level.INFO);
    this.rootLogger.addAppender(appender);
};

module.exports = new Logger;
