//
var MsgHelper = require('lib/msg_helper');
var ActionHelper = require('lib/action_helper');
var MiscHelper = require('lib/misc_helper');
var IconrenderHelper = require('lib/iconrender_helper');
var widget = require('views/templates/forms/widget');
var column = require('views/templates/forms/column');
var group = require('views/templates/forms/group');
var pageWidgetFooter = require('views/templates/pageWidgetFooter');
var modalReceiver = require('views/templates/forms/modalReceiver');
var block = require('views/templates/forms/block');
var fieldset = require('views/templates/forms/fieldset');
var textWidget = require('views/templates/forms/text');
var readonlyWidget = require('views/templates/forms/readonly_field');
var passwordWidget = require('views/templates/forms/password');
var datepickerWidget = require('views/templates/forms/datepicker');
var checkboxWidget = require('views/templates/forms/checkbox');
var hiddenWidget = require('views/templates/forms/widgets/hidden');
var textareaWidget = require('views/templates/forms/textarea');
var iconWidget = require('views/templates/forms/icon');
var imageWidget = require('views/templates/forms/image');
var labelWidget = require('views/templates/forms/widgets/label');
var radioWidget = require('views/templates/forms/radio');
var radioIconWidget = require('views/templates/forms/radioIcon');
var selectWidget = require('views/templates/forms/select');
var actionWidget = require('views/templates/forms/action');
var filterWidget = require('views/templates/forms/filter');
var helpBox = require('views/templates/helpbox');
var readonlyControlGroup = require('views/templates/readonlyControlGroup');
var readonlyControlGroupContent = require('views/templates/readonlyControlGroupContent');
var simpleContainer = require('views/templates/simpleContainer');
var sidebar = require('views/templates/sidebar');
var numberWidget = require('views/templates/forms/widgets/number');
var annualInvoiceReportPartial = require('views/templates/forms/annual_invoice_report');

var Logger = log4javascript.getLogger('lib.ViewHelper');

Handlebars.registerHelper('url', function (routeName, id) {
    if (routeName == 'search#searchhistory') {
        id = user.userId;
    }
    if (_.string.startsWith(routeName, "v2")) {
        return routeName;
    }
    // if(routeName.startsWith("v2")){ // special hack to support links to v2 in nav
    //     return routeName;
    // }

    return Chaplin.utils.reverse(routeName, [id]);
});

Handlebars.registerHelper('editurl', function (modelKey, id) {
    return Chaplin.utils.reverse(modelKey + "_edit", [id]);
});

Handlebars.registerHelper('showurl', function (modelKey, id) {
    return Chaplin.utils.reverse(modelKey + "_show", [id]);
});

Handlebars.registerHelper('actionurl', function (action, modelKey, id, index) {
    return ActionHelper.buildActionUrl(action, modelKey, id, index);
});

Handlebars.registerHelper('iconAction', function (action, modelKey, modelUrlRoot, id, productId) {
    if ((typeof productId).toLowerCase() === 'string') {
        return new Handlebars.SafeString(ActionHelper.buildActionIcon(action, modelKey, modelUrlRoot, id, productId));
    } else {
        return new Handlebars.SafeString(ActionHelper.buildActionIcon(action, modelKey, modelUrlRoot, id));
    }
});
Handlebars.registerHelper('safeString', function (string) {
    return new Handlebars.SafeString(string);
});

Handlebars.registerHelper('action', function (action, modelKey, modelUrlRoot, id, asButton) {

    var retVal = ActionHelper.buildAction(action, modelKey, modelUrlRoot, id, undefined, arguments[arguments.length - 1].data.root.model);
    if (MiscHelper.isEmpty(retVal)) {
        retVal = ActionHelper.buildActionButton(action, modelKey, modelUrlRoot, id, asButton, undefined, arguments[arguments.length - 1].data.root.model);
    }
    if (action.type === 'TERTIARY' && !MiscHelper.isEmpty(retVal)) {
        retVal = '<li>' + retVal + '</li>';
    }
    return new Handlebars.SafeString(retVal);

    //if(action === undefined) {
    //    return "";
    //}
    //if (action['appearance'] === 'LINK') {
    //    return new Handlebars.SafeString(ActionHelper.buildActionButton(action, modelKey, modelUrlRoot, id, false, undefined, arguments[arguments.length - 1].data.root.model));
    //}
    //if (action['appearance'] === 'ICON') {
    //    return new Handlebars.SafeString(ActionHelper.buildActionIcon(action, modelKey, modelUrlRoot, id, arguments[arguments.length - 1].data.root.model));
    //}
    //if (action['appearance'] === 'BUTTON') {
    //    return new Handlebars.SafeString(ActionHelper.buildActionButton(action, modelKey, modelUrlRoot, id, true, undefined, arguments[arguments.length-1].data.root.model));
    //}
    //return new Handlebars.SafeString(ActionHelper.buildActionButton(action, modelKey, modelUrlRoot, id, asButton, undefined, arguments[arguments.length-1].data.root.model));
});

Handlebars.registerHelper('actionConstructFromHash', function (modelKey, modelUrlRoot, id, elementIdName, asButton, options) {
    var action = options.hash;
    return Handlebars.helpers.action.call(this, action, modelKey, modelUrlRoot, id, asButton, options);
});
Handlebars.registerHelper('actionConstructFromHashIfRole', function (role, modelKey, modelUrlRoot, id, elementIdName, asButton, options) {
    if (user.authorities.indexOf(role) > -1) {
        var action = options.hash;
        if (!action.id) {
            action.id = elementIdName + (action.scrollToId ? "_" + action.scrollToId : '');
        }
        return Handlebars.helpers.action.call(this, action, modelKey, modelUrlRoot, id, asButton, options);
    } else return '';
});

Handlebars.registerHelper('icon', function (model, widget, modelKey, options) {

    // That's what I wanted to do but did not work. Perhaps caused by circular dependencies.
    // var retVal = RenderHelper.doRender(widget['property'], model, widget, modelKey);

    var retVal = "";
    if (widget['property'] === 'feedAvailability') {
        retVal = IconrenderHelper.feedAvailability(model, widget, modelKey);
    } else if (widget['property'] === 'productIcon') { //
        retVal = IconrenderHelper.productIcon(model, widget, modelKey);
    } else if (widget['property'] === 'invoiceStatus') {
        retVal = IconrenderHelper.invoiceStatus(model, widget, modelKey);
    } else if (widget['property'] === 'basicProduct') {
        retVal = IconrenderHelper.basicProduct(model, widget, modelKey);
    }

    return new Handlebars.SafeString(retVal);
});

Handlebars.registerHelper('image', function (model, key, options) {
    /*
     var src = model[key];
     var alt = model['title'];
     var onerror = mediaRoot + "/images/dummycover/cover_vlb_nocover.png";
     if (src.indexOf(apiRoot) != -1) {
     src += '?access_token=' + user.access_token;
     }
     */
    var src = apiRoot + "cover/" + model['gtin'] + '/s';
    var alt = model['title'];
    var onerror = mediaRoot;
    if (versionCountry === 'brasil' || versionCountry === 'latam') {
        onerror += '/images/dummycover/cover_mb_nocover.png';
    } else {
        onerror += '/images/dummycover/cover_vlb_nocover.png';
    }
    // var onerror = mediaRoot + "/images/dummycover/cover_vlb_nocover.png";
    if (src != undefined && src.indexOf(apiRoot) != -1) {
        src += '?access_token=' + user.access_token;
    }

    return new Handlebars.SafeString(imageWidget({"src": src, "alt": alt, "onerror": onerror}));
});

Handlebars.registerHelper('get', function (model, key, options) {
    return model[key];
});

Handlebars.registerHelper('listurl', function (modelKey) {
    return Chaplin.utils.reverse(modelKey + "_list");
});


Handlebars.registerHelper('deleteurl', function (urlRoot, id) {
    return urlRoot + "/" + id;
});

Handlebars.registerHelper('render_widget', function (model) {
    var retVal;

    if ((this.widget !== undefined && this.widget.showOn !== undefined) || this.doRender) {


        // this is hack solution. model is modifying before rendering. the loops are converting each reports-array by each groupMapping-Object to string (comma-space separated labels)
        if (location.href.split("user/show/").length == 2 && this.widget.property == "user.groupMapping"){
            var groupMappings = model.attributes.user.groupMapping;
            for (var j = 0; j < groupMappings.length; j++){
                var reports = groupMappings[j].reports;
                var reportsLabels = [];
                for (var i = 0; i < reports.length; i++){
                    var label = MsgHelper.getMessage("label.form.collection.reports." + reports[i].type);
                    reportsLabels.push(label);
                }
                groupMappings[j].reports = reportsLabels.join().replace(",", ", "); //model change
            }
        }

        retVal = new Handlebars.SafeString(this.render(model));
    } else {
        retVal = "";
    }
    return retVal;
});


var singleWidget = function (options) {
    Logger.debug("RegHelper: Widget");
    Logger.debug(this);

    var widgetType = this.type;

    if (options.data.root.listIndex !== undefined && options.data.root.listIndex[options.index].readOnly !== undefined) {
        var readOnly = options.data.root.listIndex[options.index].readOnly;
        if (readOnly.indexOf(this.property) !== -1) {
            return readonlyWidget(this, {data: options.data});
        }
    }

    if ((this.readonly || options.forceFieldReadonly === this.property ) && widgetType !== 'CheckboxWidget') {
        return readonlyWidget(this, {data: options.data});
    }
    //// disable product related data and multi bundle prices at each following price.
    //if (this.id.indexOf('bundleParts') > -1) {
    //    var index = options.data.id.split('_')[1];
    //    if(index !== '0') {
    //        if(this.id.split('_')[1] !== 'valueNetto' && this.id.split('_')[1] !== 'taxAmount') {
    //            return readonlyWidget(this, {data: options.data});
    //        }
    //    }
    //}

    if (widgetType === 'DateWidget') {
        if (!this.placeholder) {
            this.placeholder = 'placeholder.dateformat.de';
        }
        // views/templates/forms/datepicker.hbs
        return datepickerWidget(this, {data: options.data});
    } else if (widgetType === 'SelectionWidget') {
        return selectWidget(this, {data: options.data});
    } else if (widgetType === 'CheckboxWidget') {
        return checkboxWidget(this, {data: options.data});
    } else if (widgetType === 'PasswordWidget') {
        return passwordWidget(this, {data: options.data});
    } else if (widgetType === 'HiddenWidget') {
        return hiddenWidget(this, {data: options.data});
    } else if (widgetType === 'TextareaWidget') {
        return textareaWidget(this, {data: options.data});
    } else if (widgetType === 'IconWidget') {
        return iconWidget(this, {data: options.data});
    } else if (widgetType === 'RadioWidget') {
        var retVal = radioWidget(this, {data: options.data});
        return retVal;
    } else if (widgetType === 'RadioIconWidget') {
        return radioIconWidget(this, {data: options.data});
    } else if (widgetType === 'ActionWidget') {
        return actionWidget(this, {data: options.data});
    } else if (widgetType === 'LabelWidget') {
        return labelWidget(this, {data: options.data});
    } else if (widgetType === 'NumberWidget') {
        return numberWidget(this, {data: options.data});
    }

    return textWidget(this, {data: options.data});
};

Handlebars.registerHelper('index_in_list', function (index, list) {
    return list[index].lindex;
});

Handlebars.registerHelper('sub_widget', function (index, list, labelAsPlaceholder, parentId, data) {
    var lIndex = list[index].lindex;
    var id = this.id + '_' + lIndex;
    if (!MiscHelper.isEmpty(parentId)) {
        id = parentId + '_' + id;
    }
    data.index = index;
    data.lIndex = lIndex;
    data.data.id = id;

    data.data.placeholder = labelAsPlaceholder;
    data.forceFieldReadonly = list[index].forceFieldReadonly;

    return singleWidget.call(this, data);
});

Handlebars.registerHelper('widget', singleWidget);

Handlebars.registerHelper('msg', function (key) {
    var retVal = '';
    if (arguments.length > 2) {
        var parameters = [];
        for (var i = 1; i < arguments.length - 1; i++) {
            parameters.push(arguments[i]);
        }
        retVal = MsgHelper.formatMessage(key, parameters);
    } else {
        retVal = MsgHelper.getMessage(key);
    }

    return new Handlebars.SafeString(retVal);
});

Handlebars.registerHelper('lbl', function (key) {
    var retVal = new Handlebars.SafeString(ActionHelper.buildLabel(key));
    return retVal;
});

Handlebars.registerHelper('label2', function (key, key2) {
    var retVal;
    if (key2 === undefined) {
        retVal = new Handlebars.SafeString(ActionHelper.buildLabel(key));
    } else {
        retVal = new Handlebars.SafeString(ActionHelper.buildLabel(key + "." + key2));
    }

    return retVal;
});
Handlebars.registerHelper('label3', function (key, key2, key3) {
    return new Handlebars.SafeString(ActionHelper.buildLabel(key + "." + key2 + "." + key3));
});
Handlebars.registerHelper('nl2br', function (text) {
    if (!text) return '';
    return new Handlebars.SafeString((text + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br/>' + '$2'));
});

Handlebars.registerHelper('lbl_id', function (key) {
    var widgetType = this.type;

    if (widgetType === 'SelectionWidget') {
        return key + '_SELECT';
    }

    return key;
});

Handlebars.registerHelper('length', function (array) {
    if (array) {
        return array.length;
    }
    return 0;
});

Handlebars.registerHelper('add', function (sum1, sum2) {
    return sum1 + sum2;
});

Handlebars.registerHelper('printIfLast', function (index, array, print) {
    if (array != null) {
        if (index === array.length - 1) {
            return print;
        }
    }
    return "";
});

/**
 * checks if the given typeDef equals the type
 */
Handlebars.registerHelper('isType', function (typeDef, type, options) {
    var fnTrue = options.fn, fnFalse = options.inverse;
    return typeDef === type ? fnTrue(this) : fnFalse(this);
});

/**
 * checks if the given typeDef is of Type isEditable
 */
Handlebars.registerHelper('isEditableType', function (typeDef, options) {
    return typeDef.isEditable;
});

Handlebars.registerHelper('equals', function (left, right, options) {
    if (left === right) {
        return options.fn(this);
    }
    return options.inverse(this);
});

Handlebars.registerHelper('notequals', function (left, right, options) {
    if (left !== right) {
        return options.fn(this);
    }
    return options.inverse(this);
});

Handlebars.registerHelper('get', function (map, key, options) {
    return map[key];
});


/**
 * iterate array as key value.
 */
Handlebars.registerHelper('eachKeyValue', function (context, options) {
    var ret = "";
    for (var prop in context) {
        ret = ret + options.fn({property: prop, value: context[prop]});
    }
    return ret;
});


/**
 *
 */
Handlebars.registerHelper('quickListColumnHelper', function (context, options) {
    var ret = "";
    for (var data in context.dataSource) {
        ret = ret + options.fn({key: context.data[context.column1], value: context.data[context.column2]});
    }
    return ret;
});

Handlebars.registerHelper('hasRight', function (value, options) {

    var isNegation = value.startsWith('!');
    var right = isNegation ? value.substring(1) : value;
    var result =  user.authorities.indexOf(right) > -1;
    result = isNegation ? !result : result;

    if (result) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }

});

Handlebars.registerHelper('compareComplex', function (dto, expression, options) {

    var result = MiscHelper.compareComplex(dto, expression);

    if (result) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }

});

Handlebars.registerHelper('compare', function (lvalue, rvalue, options) {

    operator = options.hash.operator || "==";

    var result = MiscHelper.compare(lvalue, operator, rvalue);

    if (result) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }
    action
});

Handlebars.registerHelper('fieldset', function (label, options) {
    return new Handlebars.SafeString(
        '<fieldset class="with-legend">' +
        '<p class="legend">' + ActionHelper.buildLabel(label) + '</p>' +
        options.fn(this) +
        '</fieldset>');
});
Handlebars.registerHelper('block', function (id, label, options) {
    var content = options.fn(this);
    // only render if content is not empty and does not exists of whitespaces and new lines only
    // TODO: please refactor! if only the edit button exists, then block does not hide
    if (content && content.replace(/\s/g, '')) {
        return new Handlebars.SafeString(
            '<div id="block_' + id + '" class="block">' +
            '<h3>' + ActionHelper.buildLabel(label) + '</h3>' +
            options.fn(this) +
            '</div>');
    } else {
        return '';
    }
});
Handlebars.registerHelper('productBlock', function (id, options) {
    return Handlebars.helpers.block.call(this, id, 'form.product.block.' + id, options);
});
Handlebars.registerHelper('ifRole', function (role, options) {
    if (user.authorities.indexOf(role) > -1) {
        return options.fn(this)
    } else return '';
});

Handlebars.registerHelper('eachInMap', function (map, block) {
    var out = '';
    Object.keys(map).map(function (prop) {
        out += block.fn({key: prop, value: map[prop]});
    });
    return out;
});


Handlebars.registerPartial('block', block);
Handlebars.registerPartial('fieldset', fieldset);
//Handlebars.registerPartial('modalReceiver', modalReceiver);
Handlebars.registerPartial('column', column);
Handlebars.registerPartial('group', group);
Handlebars.registerPartial('pageWidgetFooter', pageWidgetFooter);
Handlebars.registerPartial('widget', widget);
Handlebars.registerPartial('filter', filterWidget);
Handlebars.registerPartial('helpBox', helpBox);
Handlebars.registerPartial('readonlyControlGroup', readonlyControlGroup);
Handlebars.registerPartial('readonlyControlGroupContent', readonlyControlGroupContent);
Handlebars.registerPartial('simpleContainer', simpleContainer);
Handlebars.registerPartial('sidebar', sidebar);
Handlebars.registerPartial('annual_invoice_report', annualInvoiceReportPartial);