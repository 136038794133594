var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.productBlock || (depth0 && depth0.productBlock) || helperMissing).call(depth0, "returnInfoShow", {"name":"productBlock","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "            "
    + escapeExpression(((helpers.actionConstructFromHashIfRole || (depth0 && depth0.actionConstructFromHashIfRole) || helperMissing).call(depth0, (depth0 != null ? depth0.editButtonRole : depth0), (depth0 != null ? depth0.modelKey : depth0), (depth0 != null ? depth0.modelUrlRoot : depth0), ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.id : stack1), "edit", true, {"name":"actionConstructFromHashIfRole","hash":{
    'scrollToId': ("block_returnInfo"),
    'style': ("grey pull-right"),
    'method': ("edit")
  },"data":data})))
    + "\n\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.returnInfo : stack1), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': (((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.returnInfo : stack1)) != null ? stack1.recallDate : stack1)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.recallDate", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': (((helpers.nl2br || (depth0 && depth0.nl2br) || helperMissing).call(depth0, (depth0 != null ? depth0.returnInfoRemarks : depth0), {"name":"nl2br","hash":{},"data":data}))),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.returnInfoRemarks", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "        "
    + escapeExpression(((helpers.actionConstructFromHashIfRole || (depth0 && depth0.actionConstructFromHashIfRole) || helperMissing).call(depth0, (depth0 != null ? depth0.editButtonRole : depth0), (depth0 != null ? depth0.modelKey : depth0), (depth0 != null ? depth0.modelUrlRoot : depth0), ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.id : stack1), "edit", true, {"name":"actionConstructFromHashIfRole","hash":{
    'scrollToId': ("block_edition"),
    'style': ("grey pull-right"),
    'method': ("edit")
  },"data":data})))
    + "\n\n";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.type : stack1), "journal", {"name":"compare","hash":{
    'operator': ("!==")
  },"fn":this.program(6, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.type : stack1), "journal", {"name":"compare","hash":{
    'operator': ("===")
  },"fn":this.program(9, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.edition : stack1), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"7":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.editionText : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.edition", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.editionTypes : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.edition.type", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.publicationCity : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "from.product.field.publicationCity", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.publicationCountry : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.publicationCountry", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"9":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.zisInfo : stack1), {"name":"if","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"10":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.publisherFrequency : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.block.publicationFrequency", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.advertising : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.block.advertising", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"12":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "        "
    + escapeExpression(((helpers.actionConstructFromHashIfRole || (depth0 && depth0.actionConstructFromHashIfRole) || helperMissing).call(depth0, (depth0 != null ? depth0.editButtonRole : depth0), (depth0 != null ? depth0.modelKey : depth0), (depth0 != null ? depth0.modelUrlRoot : depth0), ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.id : stack1), "edit", true, {"name":"actionConstructFromHashIfRole","hash":{
    'scrollToId': ("block_availability"),
    'style': ("grey pull-right"),
    'method': ("edit")
  },"data":data})))
    + "\n\n";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.type : stack1), "journal", {"name":"compare","hash":{
    'operator': ("!==")
  },"fn":this.program(13, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.type : stack1), "journal", {"name":"compare","hash":{
    'operator': ("===")
  },"fn":this.program(20, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"13":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.publicationDate : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.publicationDate", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.hasRight || (depth0 && depth0.hasRight) || helperMissing).call(depth0, "LATAM_REPRINT", {"name":"hasRight","hash":{},"fn":this.program(14, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.availability : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.availability", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.publishingStatus : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.publishingStatus", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.additionAvailabilities : depth0), {"name":"if","hash":{},"fn":this.program(16, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"14":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.reprintDate : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.reprintDate", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"16":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.fieldset || (depth0 && depth0.fieldset) || helperMissing).call(depth0, "form.product.fieldset.additional", {"name":"fieldset","hash":{},"fn":this.program(17, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"17":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                    ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.availabilityDate : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.availabilityDate", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.onSaleDate : stack1), {"name":"if","hash":{},"fn":this.program(18, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                    ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.announcementDate : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.announcementDate", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"18":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "                        <div class=\"row\">\n";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                            ', 'readonlyControlGroup', depth0, {
    'wrapperStyles': ("col-sm-4"),
    'text': ((depth0 != null ? depth0.onSaleDate : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.onSaleDate", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                            <div class=\"text-primary col-sm-8 hint\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.onSaleDate.hint", {"name":"lbl","hash":{},"data":data})))
    + "</div>\n                        </div>\n";
},"20":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.hasDate : depth0), {"name":"if","hash":{},"fn":this.program(21, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"21":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.fieldset || (depth0 && depth0.fieldset) || helperMissing).call(depth0, "form.product.fieldset.archivedDate", {"name":"fieldset","hash":{},"fn":this.program(22, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"22":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                    ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.archivedDate : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.archivedDate.date", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                    ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.archivedReason : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.archivedReason", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"24":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.productBlock || (depth0 && depth0.productBlock) || helperMissing).call(depth0, "gameCategory", {"name":"productBlock","hash":{},"fn":this.program(25, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"25":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "        "
    + escapeExpression(((helpers.actionConstructFromHashIfRole || (depth0 && depth0.actionConstructFromHashIfRole) || helperMissing).call(depth0, (depth0 != null ? depth0.editButtonRole : depth0), (depth0 != null ? depth0.modelKey : depth0), (depth0 != null ? depth0.modelUrlRoot : depth0), ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.id : stack1), "edit", true, {"name":"actionConstructFromHashIfRole","hash":{
    'scrollToId': ("block_gameCategory"),
    'style': ("grey pull-right"),
    'method': ("edit")
  },"data":data})))
    + "\n";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.numberofplayers : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.numberofplayers", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.gameduration : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.gameduration.show", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.gamepieces : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.gamepieces", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.gameClassification : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.classification", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"27":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.productBlock || (depth0 && depth0.productBlock) || helperMissing).call(depth0, "gameCategory", {"name":"productBlock","hash":{},"fn":this.program(28, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"28":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "            "
    + escapeExpression(((helpers.actionConstructFromHashIfRole || (depth0 && depth0.actionConstructFromHashIfRole) || helperMissing).call(depth0, (depth0 != null ? depth0.editButtonRole : depth0), (depth0 != null ? depth0.modelKey : depth0), (depth0 != null ? depth0.modelUrlRoot : depth0), ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.id : stack1), "edit", true, {"name":"actionConstructFromHashIfRole","hash":{
    'scrollToId': ("block_gameCategory"),
    'style': ("grey pull-right"),
    'method': ("edit")
  },"data":data})))
    + "\n";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.numberofplayers : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.numberofplayers", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.gameduration : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.gameduration.show", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.gamepieces : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.gamepieces", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.gameClassification : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.classification", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"30":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.productBlock || (depth0 && depth0.productBlock) || helperMissing).call(depth0, "extent", {"name":"productBlock","hash":{},"fn":this.program(31, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"31":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "            "
    + escapeExpression(((helpers.actionConstructFromHashIfRole || (depth0 && depth0.actionConstructFromHashIfRole) || helperMissing).call(depth0, (depth0 != null ? depth0.editButtonRole : depth0), (depth0 != null ? depth0.modelKey : depth0), (depth0 != null ? depth0.modelUrlRoot : depth0), ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.id : stack1), "edit", true, {"name":"actionConstructFromHashIfRole","hash":{
    'scrollToId': ("block_extent"),
    'style': ("grey pull-right"),
    'method': ("edit")
  },"data":data})))
    + "\n";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.pages : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.block.productHeader.pages", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.sizeweight : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.sizeweight.show", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.fileSize : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.extent.fileSize.show", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.duration : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.extent.duration.show", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.mapScale : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.extent.mapScale.show", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.formatPages : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.form.formatPages", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"33":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.productBlock || (depth0 && depth0.productBlock) || helperMissing).call(depth0, "illustrations", {"name":"productBlock","hash":{},"fn":this.program(34, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"34":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "            "
    + escapeExpression(((helpers.actionConstructFromHashIfRole || (depth0 && depth0.actionConstructFromHashIfRole) || helperMissing).call(depth0, (depth0 != null ? depth0.editButtonRole : depth0), (depth0 != null ? depth0.modelKey : depth0), (depth0 != null ? depth0.modelUrlRoot : depth0), ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.id : stack1), "edit", true, {"name":"actionConstructFromHashIfRole","hash":{
    'scrollToId': ("block_illustrations"),
    'style': ("grey pull-right"),
    'method': ("edit")
  },"data":data})))
    + "\n";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.illustrationsTotal : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.illustrations.show", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.illustrations : depth0), {"name":"each","hash":{},"fn":this.program(35, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"35":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.text : depth0)),
    'label': ((depth0 != null ? depth0.type : depth0))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"37":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.showVolumes : depth0), {"name":"if","hash":{},"fn":this.program(38, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"38":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.productBlock || (depth0 && depth0.productBlock) || helperMissing).call(depth0, "volumes", {"name":"productBlock","hash":{},"fn":this.program(39, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"39":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "                "
    + escapeExpression(((helpers.actionConstructFromHashIfRole || (depth0 && depth0.actionConstructFromHashIfRole) || helperMissing).call(depth0, (depth0 != null ? depth0.editButtonRole : depth0), (depth0 != null ? depth0.modelKey : depth0), (depth0 != null ? depth0.modelUrlRoot : depth0), ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.id : stack1), "edit", true, {"name":"actionConstructFromHashIfRole","hash":{
    'scrollToId': ("block_volumes"),
    'style': ("grey pull-right"),
    'method': ("edit")
  },"data":data})))
    + "\n";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.numberOfPieces : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.form.numberOfPieces.show", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.volumes : depth0), {"name":"each","hash":{},"fn":this.program(40, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"40":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (data && data.first), {"name":"if","hash":{},"fn":this.program(41, data),"inverse":this.program(43, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"41":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                        ', 'readonlyControlGroup', depth0, {
    'text': (depth0),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.containedItems", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"43":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                        ', 'readonlyControlGroup', depth0, {
    'text': (depth0),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "blank", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"45":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "        "
    + escapeExpression(((helpers.actionConstructFromHashIfRole || (depth0 && depth0.actionConstructFromHashIfRole) || helperMissing).call(depth0, (depth0 != null ? depth0.editButtonRole : depth0), (depth0 != null ? depth0.modelKey : depth0), (depth0 != null ? depth0.modelUrlRoot : depth0), ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.id : stack1), "edit", true, {"name":"actionConstructFromHashIfRole","hash":{
    'scrollToId': ("block_productclassification"),
    'style': ("grey pull-right"),
    'method': ("edit")
  },"data":data})))
    + "\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.showProductGroup : depth0), {"name":"if","hash":{},"fn":this.program(46, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.hasRight || (depth0 && depth0.hasRight) || helperMissing).call(depth0, "MULTIPLE_BISAC_CODES", {"name":"hasRight","hash":{},"fn":this.program(48, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.themaCategories : depth0), {"name":"each","hash":{},"fn":this.program(54, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.hasRight || (depth0 && depth0.hasRight) || helperMissing).call(depth0, "SHOW_THEMA_ADDITION", {"name":"hasRight","hash":{},"fn":this.program(59, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.themaInfo : depth0), {"name":"if","hash":{},"fn":this.program(63, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.themaInfoBisac : depth0), {"name":"if","hash":{},"fn":this.program(65, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '        ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.keywords : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.keywords", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '        ', 'readonlyControlGroup', depth0, {
    'text': (((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.bicMainSubject : stack1)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.bicbisac.bic", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.hasRight || (depth0 && depth0.hasRight) || helperMissing).call(depth0, "SINGLE_BISAC_CODE", {"name":"hasRight","hash":{},"fn":this.program(67, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '        ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.keywordsDNB : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.keywordsdnb", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '        ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.groupsDDC : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.block.ddc", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.type : stack1), "journal", {"name":"compare","hash":{
    'operator': ("===")
  },"fn":this.program(69, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.hasRight || (depth0 && depth0.hasRight) || helperMissing).call(depth0, "READING_RATIONALES", {"name":"hasRight","hash":{},"fn":this.program(72, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"46":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "            <div class=\"categoryofgoods\">\n";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.categoryOfGoods : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.categoryofgoods", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </div>\n";
},"48":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.bisacCodes : depth0), {"name":"each","hash":{},"fn":this.program(49, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "            <!--";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.bisacMainSubjectValue : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.bisacMainSubject", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "-->\n";
},"49":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (data && data.first), {"name":"if","hash":{},"fn":this.program(50, data),"inverse":this.program(52, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"50":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                    ', 'readonlyControlGroup', depth0, {
    'text': (depth0),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.bisacMainSubject", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"52":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                    ', 'readonlyControlGroup', depth0, {
    'text': (depth0),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "blank", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"54":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (data && data.first), {"name":"if","hash":{},"fn":this.program(55, data),"inverse":this.program(57, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"55":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': (depth0),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "themaDialog.tab.thema.subject", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"57":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': (depth0),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "blank", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"59":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.themaQualifier : depth0), {"name":"each","hash":{},"fn":this.program(60, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"60":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (data && data.first), {"name":"if","hash":{},"fn":this.program(61, data),"inverse":this.program(52, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"61":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                    ', 'readonlyControlGroup', depth0, {
    'text': (depth0),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "themaDialog.tab.thema.addition", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"63":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "            <div class=\"control-group themaStarInfo\">\n                <label class=\"control-label\">\n                    ";
  stack1 = ((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "blank", {"name":"lbl","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n                </label>\n\n                <div class=\"controls\">\n                    <div class=\"text-primary hint\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.thema.starinfo", {"name":"lbl","hash":{},"data":data})))
    + "</div>\n                </div>\n            </div>\n";
},"65":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "            <div class=\"control-group themaStarInfo\">\n                <label class=\"control-label\">\n                    ";
  stack1 = ((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "blank", {"name":"lbl","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n                </label>\n\n                <div class=\"controls\">\n                    <div class=\"text-primary hint\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.thema.starinfoBisac", {"name":"lbl","hash":{},"data":data})))
    + "</div>\n                </div>\n            </div>\n";
},"67":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'text': (((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.bisacMainSubject : stack1)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.bicbisac.bisac", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"69":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.zisInfo : stack1), {"name":"if","hash":{},"fn":this.program(70, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"70":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.zisKeywords : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.block.zisKeywords.plural", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"72":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.mainReadingRationale : stack1), {"name":"if","hash":{},"fn":this.program(73, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.readingRationale : stack1), {"name":"if","hash":{},"fn":this.program(75, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"73":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': (((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.collection.readingRationales", ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.mainReadingRationale : stack1), {"name":"label2","hash":{},"data":data}))),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.block.mainReadingRationaleSubject", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"75":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': (((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.collection.readingRationales", ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.readingRationale : stack1), {"name":"label2","hash":{},"data":data}))),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.block.readingRationaleSubject", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"77":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "        "
    + escapeExpression(((helpers.actionConstructFromHashIfRole || (depth0 && depth0.actionConstructFromHashIfRole) || helperMissing).call(depth0, (depth0 != null ? depth0.editButtonRole : depth0), (depth0 != null ? depth0.modelKey : depth0), (depth0 != null ? depth0.modelUrlRoot : depth0), ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.id : stack1), "edit", true, {"name":"actionConstructFromHashIfRole","hash":{
    'scrollToId': ("block_audiences"),
    'style': ("grey pull-right"),
    'method': ("edit")
  },"data":data})))
    + "\n";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '        ', 'readonlyControlGroup', depth0, {
    'text': (((helpers.nl2br || (depth0 && depth0.nl2br) || helperMissing).call(depth0, (depth0 != null ? depth0.mainAudienceClassification : depth0), {"name":"nl2br","hash":{},"data":data}))),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.audience.description", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '        ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.customerGroups : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.audience.customer_groups", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.hasRight || (depth0 && depth0.hasRight) || helperMissing).call(depth0, "BRASIL_PRICES", {"name":"hasRight","hash":{},"fn":this.program(78, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.agesBlock : depth0), {"name":"if","hash":{},"fn":this.program(80, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"78":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.educationalLevel : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.audience.educational_level", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"80":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.fieldset || (depth0 && depth0.fieldset) || helperMissing).call(depth0, "form.product.fieldset.agerating", {"name":"fieldset","hash":{},"fn":this.program(81, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"81":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.readAge : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.audience.agerating.reading", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.interestAge : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.audience.agerating.interest", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.fskAge : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.audience.agerating.fsk", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"83":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "        "
    + escapeExpression(((helpers.actionConstructFromHashIfRole || (depth0 && depth0.actionConstructFromHashIfRole) || helperMissing).call(depth0, (depth0 != null ? depth0.editButtonRole : depth0), (depth0 != null ? depth0.modelKey : depth0), (depth0 != null ? depth0.modelUrlRoot : depth0), ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.id : stack1), "edit", true, {"name":"actionConstructFromHashIfRole","hash":{
    'scrollToId': ("block_additionalInformation"),
    'style': ("grey pull-right"),
    'method': ("edit")
  },"data":data})))
    + "\n\n\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.mainDescriptions : depth0), {"name":"each","hash":{},"fn":this.program(84, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.additionalInformation : depth0), {"name":"each","hash":{},"fn":this.program(84, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.shortDescription : depth0), {"name":"each","hash":{},"fn":this.program(84, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '        ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.commercialClassifications : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.commercialClassifications", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '        ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.countryOfManufacture : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.countryOfManufacture", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.saftyWarnings : depth0), {"name":"each","hash":{},"fn":this.program(89, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"84":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isHtml : depth0), {"name":"if","hash":{},"fn":this.program(85, data),"inverse":this.program(87, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"85":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'wrapperStyles': ("additionalInformationWithHtml"),
    'text': ((depth0 != null ? depth0.text : depth0)),
    'label': ((depth0 != null ? depth0.label : depth0))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"87":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': (((helpers.nl2br || (depth0 && depth0.nl2br) || helperMissing).call(depth0, (depth0 != null ? depth0.text : depth0), {"name":"nl2br","hash":{},"data":data}))),
    'label': ((depth0 != null ? depth0.label : depth0))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"89":function(depth0,helpers,partials,data) {
  var stack1, buffer = "            <div class=\"row\">\n";
  stack1 = helpers['if'].call(depth0, (data && data.first), {"name":"if","hash":{},"fn":this.program(90, data),"inverse":this.program(92, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </div>\n";
},"90":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                    ', 'readonlyControlGroup', depth0, {
    'wrapperStyles': ("col-sm-7"),
    'text': ((depth0 != null ? depth0.typeText : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.block.safetywarning", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                    <div class=\"col-sm-5\">"
    + escapeExpression(((helpers.nl2br || (depth0 && depth0.nl2br) || helperMissing).call(depth0, (depth0 != null ? depth0.description : depth0), {"name":"nl2br","hash":{},"data":data})))
    + "</div>\n";
},"92":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                    ', 'readonlyControlGroup', depth0, {
    'wrapperStyles': ("col-sm-7"),
    'text': ((depth0 != null ? depth0.typeText : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "blank", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                    <div class=\"col-sm-5\">"
    + escapeExpression(((helpers.nl2br || (depth0 && depth0.nl2br) || helperMissing).call(depth0, (depth0 != null ? depth0.description : depth0), {"name":"nl2br","hash":{},"data":data})))
    + "</div>\n";
},"94":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "        "
    + escapeExpression(((helpers.actionConstructFromHashIfRole || (depth0 && depth0.actionConstructFromHashIfRole) || helperMissing).call(depth0, (depth0 != null ? depth0.editButtonRole : depth0), (depth0 != null ? depth0.modelKey : depth0), (depth0 != null ? depth0.modelUrlRoot : depth0), ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.id : stack1), "editAssets", true, {"name":"actionConstructFromHashIfRole","hash":{
    'style': ("grey pull-right"),
    'method': ("editAssets")
  },"data":data})))
    + "\n\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.assetListModel : depth0), {"name":"if","hash":{},"fn":this.program(95, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"95":function(depth0,helpers,partials,data) {
  return "            <div id=\"asset-list-view\"></div>\n";
  },"97":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.productBlock || (depth0 && depth0.productBlock) || helperMissing).call(depth0, "relatedProductsSeriesHierarchies", {"name":"productBlock","hash":{},"fn":this.program(98, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"98":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "            "
    + escapeExpression(((helpers.actionConstructFromHashIfRole || (depth0 && depth0.actionConstructFromHashIfRole) || helperMissing).call(depth0, (depth0 != null ? depth0.editButtonRole : depth0), (depth0 != null ? depth0.modelKey : depth0), (depth0 != null ? depth0.modelUrlRoot : depth0), ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.id : stack1), "edit", true, {"name":"actionConstructFromHashIfRole","hash":{
    'scrollToId': ("block_relatedProducts"),
    'style': ("grey pull-right"),
    'method': ("edit")
  },"data":data})))
    + "\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.relatedProducts : depth0), {"name":"if","hash":{},"fn":this.program(99, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.setsAndSeries : depth0), {"name":"if","hash":{},"fn":this.program(103, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"99":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.fieldset || (depth0 && depth0.fieldset) || helperMissing).call(depth0, "form.product.block.relatedProducts", {"name":"fieldset","hash":{},"fn":this.program(100, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"100":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.relatedProducts : depth0), {"name":"each","hash":{},"fn":this.program(101, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"101":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                        ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.text : depth0)),
    'label': ((depth0 != null ? depth0.label : depth0))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"103":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.fieldset || (depth0 && depth0.fieldset) || helperMissing).call(depth0, "form.product.fieldset.setsAndSeries", {"name":"fieldset","hash":{},"fn":this.program(104, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"104":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.series : depth0), {"name":"each","hash":{},"fn":this.program(105, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                    ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.hierarchie : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.block.hierarchy", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"105":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (data && data.first), {"name":"if","hash":{},"fn":this.program(106, data),"inverse":this.program(108, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"106":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                            ', 'readonlyControlGroup', depth0, {
    'text': (depth0),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.block.series", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"108":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                            ', 'readonlyControlGroup', depth0, {
    'text': (depth0),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "blank", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"110":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.productBlock || (depth0 && depth0.productBlock) || helperMissing).call(depth0, "salesRights", {"name":"productBlock","hash":{},"fn":this.program(111, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"111":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "            "
    + escapeExpression(((helpers.actionConstructFromHashIfRole || (depth0 && depth0.actionConstructFromHashIfRole) || helperMissing).call(depth0, (depth0 != null ? depth0.editButtonRole : depth0), (depth0 != null ? depth0.modelKey : depth0), (depth0 != null ? depth0.modelUrlRoot : depth0), ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.id : stack1), "edit", true, {"name":"actionConstructFromHashIfRole","hash":{
    'scrollToId': ("block_salesRights"),
    'style': ("grey pull-right"),
    'method': ("edit")
  },"data":data})))
    + "\n\n";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.exclusiveRights : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.exclusiveRights.show", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.nonExclusiveRights : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.nonExclusiveRights.show", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.notForSale : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.notForSale.show", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"113":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.type : stack1), "journal", {"name":"compare","hash":{
    'operator': ("!==")
  },"fn":this.program(114, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"114":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.productBlock || (depth0 && depth0.productBlock) || helperMissing).call(depth0, "barsortiment", {"name":"productBlock","hash":{},"fn":this.program(115, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"115":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.deliveries : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.barsortiment", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.orderNoUmbreit : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.umbreitnr", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.orderNoCbz : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.cbznr", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.orderNoEdis : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.edisnr", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = "<div id=\"productData2Wrapper\" class=\"view-helper-type-"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.uiType : stack1), depth0))
    + "\">\n\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.showReturnInfo : stack1), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.productBlock || (depth0 && depth0.productBlock) || helperMissing).call(depth0, "edition", {"name":"productBlock","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.productBlock || (depth0 && depth0.productBlock) || helperMissing).call(depth0, (depth0 != null ? depth0.availabilityLabel : depth0), {"name":"productBlock","hash":{},"fn":this.program(12, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.type : stack1), "toy", {"name":"compare","hash":{
    'operator': ("===")
  },"fn":this.program(24, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.type : stack1), "nonbook", {"name":"compare","hash":{
    'operator': ("===")
  },"fn":this.program(27, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.type : stack1), "journal", {"name":"compare","hash":{
    'operator': ("!==")
  },"fn":this.program(30, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.type : stack1), "journal", {"name":"compare","hash":{
    'operator': ("!==")
  },"fn":this.program(33, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.type : stack1), "journal", {"name":"compare","hash":{
    'operator': ("!==")
  },"fn":this.program(37, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.productBlock || (depth0 && depth0.productBlock) || helperMissing).call(depth0, "productclassification", {"name":"productBlock","hash":{},"fn":this.program(45, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.productBlock || (depth0 && depth0.productBlock) || helperMissing).call(depth0, (depth0 != null ? depth0.audiencesLabel : depth0), {"name":"productBlock","hash":{},"fn":this.program(77, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.productBlock || (depth0 && depth0.productBlock) || helperMissing).call(depth0, "additionalInformation", {"name":"productBlock","hash":{},"fn":this.program(83, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.productBlock || (depth0 && depth0.productBlock) || helperMissing).call(depth0, "assetsshow", {"name":"productBlock","hash":{},"fn":this.program(94, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.type : stack1), "journal", {"name":"compare","hash":{
    'operator': ("!==")
  },"fn":this.program(97, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.type : stack1), "journal", {"name":"compare","hash":{
    'operator': ("!==")
  },"fn":this.program(110, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.barsortiment : depth0), {"name":"if","hash":{},"fn":this.program(113, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n</div>";
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}