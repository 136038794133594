var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "error-"
    + escapeExpression(lambda(depth0, depth0))
    + " ";
},"3":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <label class=\"control-label\" for=\""
    + escapeExpression(((helpers.lbl_id || (depth0 && depth0.lbl_id) || helperMissing).call(depth0, (data && data.id), {"name":"lbl_id","hash":{},"data":data})))
    + "\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, (depth0 != null ? depth0.label : depth0), {"name":"lbl","hash":{},"data":data})))
    + "</label>\n";
},"5":function(depth0,helpers,partials,data) {
  var stack1, helper, options, functionType="function", helperMissing=helpers.helperMissing, blockHelperMissing=helpers.blockHelperMissing, buffer = "            <div class=\"controls \">\n";
  stack1 = ((helper = (helper = helpers.widget || (depth0 != null ? depth0.widget : depth0)) != null ? helper : helperMissing),(options={"name":"widget","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data}),(typeof helper === functionType ? helper.call(depth0, options) : helper));
  if (!helpers.widget) { stack1 = blockHelperMissing.call(depth0, stack1, options); }
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </div>\n";
},"6":function(depth0,helpers,partials,data) {
  return "";
},"8":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.notequals || (depth0 && depth0.notequals) || helperMissing).call(depth0, (depth0 != null ? depth0.dateFormat : depth0), "ONIX", {"name":"notequals","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.equals || (depth0 && depth0.equals) || helperMissing).call(depth0, (depth0 != null ? depth0.dateFormat : depth0), "ONIX", {"name":"equals","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"9":function(depth0,helpers,partials,data) {
  var stack1, helper, options, functionType="function", helperMissing=helpers.helperMissing, blockHelperMissing=helpers.blockHelperMissing, buffer = "                <div class=\"controls \">\n";
  stack1 = ((helper = (helper = helpers.widget || (depth0 != null ? depth0.widget : depth0)) != null ? helper : helperMissing),(options={"name":"widget","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data}),(typeof helper === functionType ? helper.call(depth0, options) : helper));
  if (!helpers.widget) { stack1 = blockHelperMissing.call(depth0, stack1, options); }
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                    <span class=\"help-inline\"/>\n                </div>\n";
},"11":function(depth0,helpers,partials,data) {
  var stack1, helper, options, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, functionType="function", blockHelperMissing=helpers.blockHelperMissing, buffer = "                <div class=\"controls row\">\n                    <div class=\"controls-inner col-sm-6\">\n                        <select id=\""
    + escapeExpression(lambda((data && data.id), depth0))
    + "_datetoggle\" class=\"datetoggle\">\n                            <option value=\"day\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "datePrecision.day", {"name":"lbl","hash":{},"data":data})))
    + "</option>\n                            <option value=\"month\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "datePrecision.month", {"name":"lbl","hash":{},"data":data})))
    + "</option>\n                            <option value=\"year\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "datePrecision.year", {"name":"lbl","hash":{},"data":data})))
    + "</option>\n                        </select>\n                    </div>\n                    <div class=\"controls col-sm-6\">\n";
  stack1 = ((helper = (helper = helpers.widget || (depth0 != null ? depth0.widget : depth0)) != null ? helper : helperMissing),(options={"name":"widget","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data}),(typeof helper === functionType ? helper.call(depth0, options) : helper));
  if (!helpers.widget) { stack1 = blockHelperMissing.call(depth0, stack1, options); }
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                    </div>\n                    <span class=\"help-inline\"/>\n                </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "<div class=\"control-group control-datefield widget_"
    + escapeExpression(((helper = (helper = helpers.widgetIndex || (depth0 != null ? depth0.widgetIndex : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"widgetIndex","hash":{},"data":data}) : helper)))
    + " "
    + escapeExpression(lambda((data && data.id), depth0))
    + " dateFormat-"
    + escapeExpression(((helper = (helper = helpers.dateFormat || (depth0 != null ? depth0.dateFormat : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"dateFormat","hash":{},"data":data}) : helper)))
    + " "
    + escapeExpression(((helper = (helper = helpers.styleClass || (depth0 != null ? depth0.styleClass : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"styleClass","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"controls-inner ";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.errorAnchor : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.hasLabel : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.readonly : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.program(8, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        <!-- widget coming -->\n    </div>\n</div> <!-- form-group widget_"
    + escapeExpression(((helper = (helper = helpers.widgetIndex || (depth0 != null ? depth0.widgetIndex : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"widgetIndex","hash":{},"data":data}) : helper)))
    + " "
    + escapeExpression(lambda((data && data.id), depth0))
    + " -->";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}