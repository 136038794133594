var Model = require('./model_with_auth');

var OnixCodelist = Model.extend({
    urlRoot: apiRoot + "codelists",

    fetch: function (options) {
        var theOptions = {};
        theOptions['success'] = function (model, response, options) {

            model.codelistData = [];
            for(var i=0; i<model.attributes.codes.length;i++) {
                model.codelistData.push({id: model.attributes.codes[i].code, text: model.attributes.codes[i].description});
            }
        };
        theOptions['cache'] = true;
        theOptions['async'] = false;
        return Model.prototype.fetch.call(this, theOptions);
    }

});

module.exports = OnixCodelist;