var logger = log4javascript.getLogger('views.DownloadHelper');

var DownloadHelper = function () {
	this.httpType = 'POST';
	this.urlRoot = apiRoot + 'export/';
	this.downloadRoot = apiRoot + 'files/';

	this.table = null;
	this.iframe = null;
	this.form = null;
	this.idField = null;

	this.selection = [];

	this.container = null;

	this.fileChooseModal = null;

};

DownloadHelper.prototype.init = function(context)
{

	var container = $('#exportDownload');

	this.container = container;
	if (container.data('isInitialized') === true)
	{
		return;
	}

	/// Create the iframe container, which we will use to download the file
	this.iframe = $("<iframe />").hide().appendTo(container);
	this.iframe.attr('src', '');

	this.setupEventHandlers(container, context);

	container.data('isInitialized', true);
};

DownloadHelper.prototype.createFileNameDialog = function(container, callback)
{
	if (this.fileChooseModal != null)
	{
		this.fileChooseModal.remove();
	}
	var self = this;
	// TODO: Move the template as a seperate entity. How can I initialize a View I wonder? :-(
    // TODO: make this i18n compatible
	var confirmDialogTemplate = 
		'<div class="modal fade" id="fileChooseModal"  aria-hidden="true">' +
		'    <div class="modal-dialog">' +
		'        <div class="modal-content">' +
		'            <div class="modal-body">' +
		'            	<div class="row">' +
		'            		<div class="col-sm1">' +
		'            			<label for="fileNameTextBox" class="control-label">Geben Sie einen Dateinamen ein: </label>' +
		'           		</div>' +
		'            		<div class="col-sm-11">' +
		'            			<input type="text" class="form-control" id="fileNameTextBox" placeholder="dateiname">' +
		'            		</div>' +
		'				</div>' +
		'            </div>' +
		'            <div class="modal-footer">' +
		'            	<button type="button" class="btn btn-primary downloadBtn">Download</button>' +
		'            	<button type="button" class="btn btn-cancel" data-dismiss="modal">{{lbl "cancel"}}</button>' +
		'            </div>' +
		'        </div>' +
		'    </div>' +
		'</div>';

	this.fileChooseModal = $(confirmDialogTemplate).appendTo(container);

	//console.log('context: ', $('button.downloadBtn', this.fileChooseModal), this.fileChooseModal);
	$('button.downloadBtn', this.fileChooseModal).click(
		function()		
		{
			var fileName = $("#fileNameTextBox", self.fileChooseModal).val();
			callback(fileName);
			self.fileChooseModal.modal('hide');
		}
	);

	this.fileChooseModal.modal(
	{
		backdrop : 'static',
		keyboard : false
	});
	this.fileChooseModal.on('hidden.bs.modal', function()
	{
		$('.modal-backdrop').remove(); /// This remains hanging, not sure why
	});
};

DownloadHelper.prototype.setupEventHandlers = function(container, context)
{
	var self = this;	

	
	$('.onix21', container).click(
		function()
		{			
			if (context.selections.length < 1)
			{
				return;
			}			
			self.onOnixExport(context.selections.ids);
		}
	);	

	$('.csv', container).click(
		function()
		{
			if (context.selections.length < 1)
			{
				return;
			}
			self.onCSVExport(context.selections.ids);
		}
	);	

	$('.ascii', container).click(
		function()
		{
			if (context.selections.length < 1)
			{
				return;
			}
			self.onASCIIExport(context.selections.ids);
		}
	);	
};

DownloadHelper.prototype.onOnixExport = function(ids)
{
	this.doDownload('onix21', ids);
};

DownloadHelper.prototype.onCSVExport = function(ids)
{
	this.doDownload('csv', ids);
};

DownloadHelper.prototype.onASCIIExport = function(ids)
{
	this.doDownload('ascii', ids);
};

DownloadHelper.prototype.doDownload = function(type, ids)
{
	var self = this;	
	var url = this.urlRoot + type;
	var selection = new Object();
	selection.ids = [];
	for(var i=0; i<ids.length; i++) {
	  selection.ids.push(ids[i].id);
	}

	self.createFileNameDialog(self.container, function(fileName) { 
		jQuery.ajax(
	    {
	        url : url + "?filename=" + fileName,
	        processData : false,
	        type : self.httpType,
	        dataType : 'json',
	        data : JSON.stringify(selection),
	        contentType: 'application/json',
	        success : function(data) 
	        {
	        	var downloadId = data;
	        	var downloadUrl = self.downloadRoot + downloadId + "?access_token=" + user.access_token;

	        	self.iframe.attr('src', downloadUrl);
	        },
	        error : function(data) 
	        {
	            logger.error("Error: ", data);
	        },
	        beforeSend : function() {},
	        complete : function() {},        
	        headers : 
	        {
	            'Authorization' : 'Bearer ' + user.access_token
	        }
	    });
	});
};


module.exports = new DownloadHelper;