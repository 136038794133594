var View = require('./view'),
	template = require('./templates/forms/modal'),
	GlobalErrorModel = require('models/modalPanel');

module.exports = View.extend({

	id: 'modalPanel',
    tagName: 'div',
    className: 'modalPanel',
    region: 'alertify',
    template: template,
    
    getTemplateData: function () {
        var data = {};
        if (this.model) {
        	data.id = this.model.attributes.modalData.id;
        	data.column = this.model.attributes.modalData.column;        	
        }
        return data;
    }
});