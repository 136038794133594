//
var SiteView = require('views/site');
var SidebarView = require('views/sidebar');
var NavbarView = require('views/navbar');
var FooterView = require('views/footer');
var Menu = require('models/menu');
var NavbarMenu = require('models/navbarMenu');
var HistoryHelper = require('lib/history_helper');
var UserHelper = require('lib/user_helper');
var MsgHelper = require('lib/msg_helper');

var Controller = Chaplin.Controller.extend({
	menu: function () {
		return "main";
    },
	
    beforeAction: function (params) {
    	Chaplin.mediator.subscribe('redirectTo', this.doRedirect, this);
        this.reuse('site', SiteView);
        this.menu = new Menu({id: this.menu()});
        this.menu.fetch();
        this.sidebarView = new SidebarView({model: this.menu});
        this.navbarMenu = new NavbarMenu({id: 'navbar'});
        this.navbarMenu.fetch();
        this.navbarView = new NavbarView({'params': params, model: this.navbarMenu});
        //this.reuse('navbar', NavbarView);
        this.reuse('footer', FooterView);
        this.trackSite(params);
        HistoryHelper.writeHistory(params, 'controller');
    },

    doRedirect : function(targetData){
    	this.redirectTo(targetData.url, targetData.data);
    },

    trackSite: function(params){
        // piwik tracking-code
        if (window._paq) {
            var pagenameVar = window.location.href;
            window._paq.push(['setCustomUrl', pagenameVar]);
            window._paq.push(['trackPageView']);
            window._paq.push(['enableLinkTracking']);
        }
    }
});

module.exports = Controller;