var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.showTitle : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.blockDef : depth0)) != null ? stack1.errorAnchor : stack1), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.each.call(depth0, ((stack1 = (depth0 != null ? depth0.blockDef : depth0)) != null ? stack1.fieldSet : stack1), {"name":"each","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "        <h3><span class=\"blockTitle\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, (depth0 != null ? depth0.title : depth0), {"name":"lbl","hash":{},"data":data})))
    + "</span>\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.blockDef : depth0)) != null ? stack1.showHelpLink : stack1), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </h3>\n";
},"3":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                <div class=\"help-icons add-icons\">\n                        <a class=\"txt-link txt-link-help\" href=\""
    + escapeExpression(((helpers.msg || (depth0 && depth0.msg) || helperMissing).call(depth0, ((helpers.add || (depth0 && depth0.add) || helperMissing).call(depth0, "help.link.", ((helpers.add || (depth0 && depth0.add) || helperMissing).call(depth0, (depth0 != null ? depth0.mode : depth0), (depth0 != null ? depth0.helplink : depth0), {"name":"add","hash":{},"data":data})), {"name":"add","hash":{},"data":data})), {"name":"msg","hash":{},"data":data})))
    + "\" target=\"_blank\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "help", {"name":"lbl","hash":{},"data":data})))
    + "</a>\n                        <a href=\""
    + escapeExpression(((helpers.msg || (depth0 && depth0.msg) || helperMissing).call(depth0, ((helpers.add || (depth0 && depth0.add) || helperMissing).call(depth0, "help.link.", ((helpers.add || (depth0 && depth0.add) || helperMissing).call(depth0, (depth0 != null ? depth0.mode : depth0), (depth0 != null ? depth0.helplink : depth0), {"name":"add","hash":{},"data":data})), {"name":"add","hash":{},"data":data})), {"name":"msg","hash":{},"data":data})))
    + "\" target=\"_blank\" class=\"vlb-action-icon vlb-action-icon-help\"\n                           title=\""
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "help", {"name":"lbl","hash":{},"data":data})))
    + "\"><span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "help", {"name":"lbl","hash":{},"data":data})))
    + "</span></a>\n                </div>\n";
},"5":function(depth0,helpers,partials,data) {
  var stack1, buffer = "        <div class=\"control-group\">\n            <div class=\"controls ";
  stack1 = helpers.each.call(depth0, ((stack1 = (depth0 != null ? depth0.blockDef : depth0)) != null ? stack1.errorAnchor : stack1), {"name":"each","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n                <span class=\"help-inline\"></span>\n            </div>\n        </div>\n";
},"6":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "error-"
    + escapeExpression(lambda(depth0, depth0))
    + " ";
},"8":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials.fieldset, '            ', 'fieldset', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1;
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.blockDef : depth0)) != null ? stack1.visible : stack1), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { return stack1; }
  else { return ''; }
  },"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}