var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "    <style>\n        [id^=\"actionBtn-edit\"] {\n            display: none;\n        }\n    </style>\n";
  },"3":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, functionType="function", escapeExpression=this.escapeExpression, buffer = "    <div class=\"control-group publisherData\">\n        <label class=\"control-label\">\n            ";
  stack1 = ((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.publishers", {"name":"lbl","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n        </label>\n        <div class=\"controls\">\n            <div class=\"publisherShortName\">\n                <span class=\"readonlyText\">";
  stack1 = ((helper = (helper = helpers.publisherName || (depth0 != null ? depth0.publisherName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"publisherName","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</span>\n                <a class=\"txt-link txt-link-plus add collapseButton\" data-toggle=\"collapse\"\n                   href=\"#publisherDetailData\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "blank", {"name":"lbl","hash":{},"data":data})))
    + "</a>\n            </div>\n            <div class=\"collapse in\" id=\"publisherDetailData\">\n";
  stack1 = this.invokePartial(partials.simpleContainer, '                ', 'simpleContainer', depth0, {
    'wrapperStyles': ("address"),
    'text': ((depth0 != null ? depth0.postalAddress : depth0))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.simpleContainer, '                ', 'simpleContainer', depth0, {
    'wrapperStyles': ("contact"),
    'text': ((depth0 != null ? depth0.contactData : depth0))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = this.invokePartial(partials.simpleContainer, '                ', 'simpleContainer', depth0, {
    'wrapperStyles': ("mvbId"),
    'text': ((depth0 != null ? depth0.mvbId : depth0))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = this.invokePartial(partials.simpleContainer, '                ', 'simpleContainer', depth0, {
    'wrapperStyles': ("deliverer"),
    'text': ((depth0 != null ? depth0.deliveries : depth0))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = this.invokePartial(partials.simpleContainer, '                ', 'simpleContainer', depth0, {
    'wrapperStyles': ("deliveryAdendum"),
    'text': (((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.publisherData : stack1)) != null ? stack1.deliveryAddendum : stack1))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = this.invokePartial(partials.simpleContainer, '                ', 'simpleContainer', depth0, {
    'wrapperStyles': ("deliveryNote"),
    'text': ((depth0 != null ? depth0.deliveryNote : depth0))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n            </div>\n\n        </div>\n    </div>\n";
},"5":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '    ', 'readonlyControlGroup', depth0, {
    'text': (((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.publisherName : stack1)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.publishers", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<!--"
    + escapeExpression(((helpers.actionConstructFromHashIfRole || (depth0 && depth0.actionConstructFromHashIfRole) || helperMissing).call(depth0, (depth0 != null ? depth0.editButtonRole : depth0), (depth0 != null ? depth0.modelKey : depth0), (depth0 != null ? depth0.modelUrlRoot : depth0), ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.id : stack1), "edit_metadata", true, {"name":"actionConstructFromHashIfRole","hash":{
    'customLabel': ("form.button.editMetadata"),
    'style': ("grey pull-right"),
    'method': ("edit")
  },"data":data})))
    + "-->\n\n";
  stack1 = helpers.unless.call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.publisherForMvbId : stack1), {"name":"unless","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.publisherData : stack1), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.program(5, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n\n";
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}