var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <br>"
    + escapeExpression(((helpers.safeString || (depth0 && depth0.safeString) || helperMissing).call(depth0, (depth0 != null ? depth0.textSecondLine : depth0), {"name":"safeString","hash":{},"data":data})))
    + "\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = "        <p>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.warnings : depth0), {"name":"each","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </p>\n";
},"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                "
    + escapeExpression(((helpers.safeString || (depth0 && depth0.safeString) || helperMissing).call(depth0, (depth0 != null ? depth0.text : depth0), {"name":"safeString","hash":{},"data":data})))
    + "<br>\n";
},"6":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "                <p class=\"label mandetory\">\n                    "
    + escapeExpression(((helpers.safeString || (depth0 && depth0.safeString) || helperMissing).call(depth0, (depth0 != null ? depth0.textSelect : depth0), {"name":"safeString","hash":{},"data":data})))
    + "\n              	</p> \n               	<div class=\"input-group ";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.errorAnchor : depth0), {"name":"each","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n				    <input type=\"hidden\" class=\"form-control migrate-publishers\" ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.changeEvent : depth0), {"name":"if","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.specialBehaviour : depth0), {"name":"if","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "/>\n				</div>\n";
},"7":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "error-"
    + escapeExpression(lambda(depth0, depth0))
    + " ";
},"9":function(depth0,helpers,partials,data) {
  return "data-changeEvent=\"true\"";
  },"11":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "data-special-behaviour=\""
    + escapeExpression(((helper = (helper = helpers.specialBehaviour || (depth0 != null ? depth0.specialBehaviour : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"specialBehaviour","hash":{},"data":data}) : helper)))
    + "\"";
},"13":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                <p class=\"label mandetory\">\n                    "
    + escapeExpression(((helpers.safeString || (depth0 && depth0.safeString) || helperMissing).call(depth0, (depth0 != null ? depth0.textRadioSelect : depth0), {"name":"safeString","hash":{},"data":data})))
    + "\n                </p>\n";
},"15":function(depth0,helpers,partials,data,depths) {
  var helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing;
  return "                <div class=\"row\">\n                    <div class=\"col-xs-12\">\n                        <div class=\"form-wrap-inner\">\n                            <input type=\"radio\" name=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].ident : depths[1]), depth0))
    + "\" id=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].ident : depths[1]), depth0))
    + "-"
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\" value=\""
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n                            <label for=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].ident : depths[1]), depth0))
    + "-"
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.confirmationRadio.item", (depth0 != null ? depth0.label : depth0), {"name":"label2","hash":{},"data":data})))
    + "</label>\n                        </div>\n                    </div>\n                </div>\n";
},"17":function(depth0,helpers,partials,data) {
  var stack1, buffer = "            <p>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.hint : depth0), {"name":"each","hash":{},"fn":this.program(18, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </p>\n";
},"18":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                    "
    + escapeExpression(((helpers.safeString || (depth0 && depth0.safeString) || helperMissing).call(depth0, (depth0 != null ? depth0.text : depth0), {"name":"safeString","hash":{},"data":data})))
    + "<br>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div id=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "\" class=\"lb-default\">\n    <div class=\"add-icons\">\n        <a href=\"javascript:;\" onclick=\"return false;\" class=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-close myfancybox-close\" title=\"Close\">CLOSE</a>\n    </div>\n\n    <h2><span>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.confirmationMigrate.headline", (depth0 != null ? depth0.ident : depth0), {"name":"label2","hash":{},"data":data})))
    + "</span></h2>\n\n    <p>\n        "
    + escapeExpression(((helpers.safeString || (depth0 && depth0.safeString) || helperMissing).call(depth0, (depth0 != null ? depth0.text : depth0), {"name":"safeString","hash":{},"data":data})))
    + "\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.textSecondLine : depth0), {"name":"if","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </p>\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.warnings : depth0), {"name":"if","hash":{},"fn":this.program(3, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n    <form class=\"form-horizontal\">\n		<div class=\"form-group new-publisher\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.textSelect : depth0), {"name":"if","hash":{},"fn":this.program(6, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </div>\n        <div class=\"form-group migration-categories\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.textRadioSelect : depth0), {"name":"if","hash":{},"fn":this.program(13, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.collection : depth0), {"name":"each","hash":{},"fn":this.program(15, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </div>\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.hint : depth0), {"name":"if","hash":{},"fn":this.program(17, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n	    <div id=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-msg\" class=\"error-messages\">\n	        <div id=\"error\" style=\"display: none\">\n	            <div class=\"vlb-icon vlb-icon-input-error\"></div>\n	            <span style=\"color: #e24844;\" class=\"help-inline nod_msg\"></span>\n	        </div>\n	        <div id=\"valid\" style=\"display: none\">\n	            <div class=\"vlb-icon vlb-icon-input-check\"></div>\n	            <span style=\"color: #14aa96;\" class=\"help-inline nod_msg\"></span>\n	        </div>\n	    </div>\n\n        <div class=\"form-actions align-right\">\n            <a href=\"javascript:;\" onclick=\"return false;\" class=\"btn green btn-arrow "
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-close\"><span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "cancel", {"name":"lbl","hash":{},"data":data})))
    + "</span></a>\n            <a href=\"javascript:;\" onclick=\"return false;\" class=\"btn green btn-arrow "
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-confirm\"><span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "confirm", {"name":"lbl","hash":{},"data":data})))
    + "</span></a>\n        </div>\n    </form>\n</div>\n";
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}