var MsgHelper = require('lib/msg_helper');
var View = require('./view')
var AssetFormTemplate = require('./templates/forms/assetsForm');
var AssetFormBasicTemplate = require('./templates/forms/assetsForm_basic');
var Collection = require('models/collection_with_auth');
var AssetTypeCollection = Collection.extend({url : apiRoot+"asset/fileTypes"});
var PageContentModel = require('models/pagecontent');
var FormDefinition = require('models/formDefinition');
var ListView = require('./list_view');
var AjaxSpinner = require('lib/ajaxspinner_helper');
var HistoryHelper = require('lib/history_helper');
var MiscHelper = require('lib/misc_helper');

var AssetsFromView;

module.exports = AssetsFromView =  View.extend({

    LOGGER : log4javascript.getLogger('views.AssetsView'),

    container: '#fieldSet_assets',
    id: 'asset-dialog',
    autoRender: true,
    autoAttach: false,
    regions: {
        'asset-list-view': '#asset-list-view'
    },
    className: 'assetDialog',
    assetFileTypes:new AssetTypeCollection(),

    initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);

        var historyStack = HistoryHelper.getCurrentHistory();
        var fragment = Backbone.history.fragment;

        this.modelKey = 'assets';

        // loading assets from assetStore
        var assetListModel = new PageContentModel({ urlRoot: "$api/asset/"+options.model.get("id")+"/assetFile", modelKey: 'assets'});

        // loading collection of fileTypes
        this.assetFileTypes.fetch({async:false});

        // loading listView with form definition (auto-attached)
        var assetListFormDef = new FormDefinition({id: 'assetFile/list', wid:"assets-table"});
        new ListView({model: assetListModel, formDef: assetListFormDef, region: "asset-list-view", id: "assets-table", showHeader:false, productId : options.model.get('id')});
        assetListFormDef.fetch();
    },

    getTemplateData: function () {
        var data = View.prototype.getTemplateData.call(this);
        data.ident = this.id;
        data.labelPrefix = 'form.assets';
        data.helpbox = { description : data.labelPrefix + 'description.text', url : 'help.link.asset.upload'}
        data.numbers = [""];
        data.assetFileTypes = this.assetFileTypes.models;
        data.isbn = this.model.get('isbnHyphens');
        data.productId = this.model.get('id');
        if(data.isbn != undefined)
            data.isbn = data.isbn.replace(/-/g,'');
        for(var i = 1; i<=25; i++){ data.numbers.push(i);}
        data.isCopy = !MiscHelper.isEmpty(this.options.routeParams.copy);
        return data;
    },
    getTemplateFunction: function () {
        if (user.authorities.indexOf('PUBLISHER_BASIC') >= 0) {
            return AssetFormBasicTemplate;
        } else {
            return AssetFormTemplate;
        }
    },
    render: function () {
        View.prototype.render.call(this);
    },
    attach: function() {
        View.prototype.attach.call(this);

        //this.modelBinder.bind(this.model, this.el);//, null, {'changeTriggers': {'*': 'change'}});

        $('#asset-dialog-medianumber').select2({
            minimumResultsForSearch : 30,
            allowClear : true,
            placeholder : " "
        });
        $('#asset-dialog-mediatype').select2({
            minimumResultsForSearch : 10,
            allowClear : true,
            placeholder : " "
        });

        this.initFileupload();

    },
    
    events: {
        'change #asset-dialog-filename': 'toggleUploadButton',
        'change #asset-dialog-mediatype': 'toggleUploadButton'
    },
    toggleUploadButton:function(event) {

        $('#asset-dialog-upload').toggleClass('disabled',($('#asset-dialog-filename').val() == "" || $('#asset-dialog-mediatype').val() == ""));
    },
    initFileupload: function() {
        Chaplin.mediator.subscribe('assetFileUploadSuccess', this.uploadSuccess, this);
        Chaplin.mediator.subscribe('assetFileUploadError', this.uploadError, this);
        $('#fileupload').fileupload({
            pasteZone:null,
            url: apiRoot+"asset/upload",
            sequentialUploads: true,
            headers: {
                'Authorization' :"Bearer " + user.access_token
            },
            add: function (e, data) {
                $('#asset-dialog-filename').val(data.fileInput.val().split(/(\\|\/)/g).pop());
                $('#asset-dialog-filename').change();
                $('#asset-dialog-upload').off("click");
                data.context = $('#asset-dialog-upload').click(function () {
                    AjaxSpinner.show(undefined, MsgHelper.formatMessage('label.form.assetUpload.uploading.message', [data.files[0].name]));
                    if (!data.form) {
                        data.form = new Object();
                    }

                    var jqXHR = data.submit()
                        .success(function(result, textStatus, jqXHR) {
                            AjaxSpinner.hide();
                            MsgHelper.clearAlerts();
                            Chaplin.mediator.publish('assetFileUploadSuccess', result, textStatus, jqXHR);
                        })
                        .error(function (jqXHR, textStatus, errorThrown) {
                            AjaxSpinner.hide();
                            MsgHelper.clearAlerts();
                            Chaplin.mediator.publish('assetFileUploadError', jqXHR, textStatus, errorThrown);
                        });
                });
            }
        });
    },
    uploadSuccess: function(result, textStatus, jqXHR) {

        if(result.valid){
            alertify.success(MsgHelper.getMessage('msg.form.assetUpload.success'));
            $(".asset-upload-form")[0].reset();
            $(".asset-upload-form select").change();
        } else {
            if(result.messages != undefined) {
                for (var i = 0; i < result.messages.length; i++) {
                    var message = result.messages[i];
                    if (message.type !== null && message.type !== undefined && 'error' === message.type.toLowerCase()) {
                        alertify.error(message.message);
                    }
                }
            }
        }


        $('#datatable_asset-list-view').DataTable().ajax.reload();
    },
    uploadError: function(jqXHR, textStatus, errorThrown) {
        alertify.error(MsgHelper.getMessage('msg.form.assetUpload.error'));
    }
});