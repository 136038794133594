var MsgHelper = require('lib/msg_helper');
var ActionHelper = require('lib/action_helper');
var FormHelper = require('lib/form_helper');
var ConfirmationSimple = require('views/templates/forms/confirmationSimple');
var ConfirmationReason = require('views/templates/forms/confirmationReason');
var ConfirmationRadio = require('views/templates/forms/confirmationRadio');
var ConfirmationDownload = require('views/templates/forms/confirmationDownload');
var ConfirmationAcknowledge = require('views/templates/forms/confirmationAcknowledge');
var ConfirmationMigrate = require('views/templates/forms/confirmationMigrate');
var ConfirmationOpenDialogHelper = require('lib/confirmation_open_dialog_helper');
var MiscHelper = require('lib/misc_helper');
var OrderbookOrderFormView = require('views/orderbook_order_form_view');
var Model = require('models/model_with_auth');
var LinkHelper = require('lib/link_helper');

var logger = log4javascript.getLogger('lib.ConfirmationHelper');

var ConfirmationHelper = function () {
};

ConfirmationHelper.prototype.confirmSingleAction = function (event, options, successCallback, productInformation) {
    var arrProductInformations;
    if (productInformation === undefined) {
        arrProductInformations = [];
    } else {
        arrProductInformations = [productInformation];
    }
    this.confirmMultipleActionId(event, options, arrProductInformations, successCallback);
};

ConfirmationHelper.prototype.confirmMultipleActionId = function (event, options,
                                                                 arrProductInformations,
                                                                 successCallback) {

    var action = ActionHelper.findActionById(options.formDef,
        event.currentTarget.attributes["action"].value);
    if (arrProductInformations.length > 0 && (action['method'] === 'order')) {
        var that = this;
        var url = apiRoot + 'products/possibleProductActions?access_token=' + user.access_token;
        var productIDs = [];
        for (var i = 0; i < arrProductInformations.length; i++) {
            if (arrProductInformations[i]['productId']) {
                productIDs.push(arrProductInformations[i]['productId']);
            } else {
                productIDs.push(arrProductInformations[i]['id']);
            }
        }
        var sendData = JSON.stringify(productIDs);
        jQuery.ajax({
            contentType: 'application/json',
            data: sendData,
            url: url,
            type: 'POST',
            "success": function (data) {
                that.confirmMultipleActionIdReal(event, options, arrProductInformations, successCallback, data);
            }
        });
    } else {
        this.confirmMultipleActionIdReal(event, options, arrProductInformations, successCallback, arrProductInformations);
    }
}

ConfirmationHelper.prototype.confirmMultipleActionIdReal = function (event, options,
                                                                     arrProductInformations,
                                                                     successCallback, arrPossibleActions) {

    var action = ActionHelper.findActionById(options.formDef,
        event.currentTarget.attributes["action"].value);
    var msg = "";
    var arrTargetModelIds = [];
    var arrProductID = [];
    var arrProduct = [];
    var arrAggrProduct = [];
    var counterSeries = 0;
    var counterSet = 0;
    var counterNotActive = 0;
    var counterActive = 0;
    var counterAggregatorId = 0;
    var counterNoOrder = 0;
    var counterDigital = 0;
    var counterNoOesbOrder = 0;
    var counterNotOwner = 0;
    var counterJournal = 0;
    var counterMultiBundles = 0;
    var warnings = [];
    var notAvailableForOrder = false;
    var o;
    var doCheck = true;

    if(action['method'] === 'reactivate' || action['method'] === 'archive') {
        doCheck = false;
    }

    for (var i = 0; i < arrProductInformations.length; i++) {
        var productInfo = arrProductInformations[i];
        var possibleActions = arrPossibleActions[i];

        if (productInfo === undefined) {
            continue;
        }
        var productId;
        if (productInfo.productId !== undefined) {
            // If productId is explicit set use that id
            productId = productInfo.productId;
        } else {
            productId = productInfo.id;
        }

        if (doCheck) {
            if (productInfo.type === 'multiBundle' || possibleActions.type === 'multiBundle') {
                counterMultiBundles++;
                if (action['id'] === 'deleteTitleList' || action['method'] === 'migrate') {
                    continue;
                }
            }
            if (productInfo.type === 'series' || possibleActions.type === 'series') {
                counterSeries++;
                if (action['id'] !== 'deleteTitleList' && action['method'] !== 'transformation') {
                    continue;
                }
            }
            if (productInfo.type === 'set' || possibleActions.type === 'set') {
                counterSet++;
                if (action['id'] !== 'deleteTitleList' && action['method'] !== 'transformation') {
                    continue;
                }
            }
            if (productInfo.type === 'digital' || possibleActions.type === 'digital' || productInfo.type === 'ebook' || possibleActions.type === 'ebook') {
                counterDigital++;
                if (action['method'] === 'order') {
                    continue;
                }
            }
            if (productInfo.type === 'journal' || possibleActions.type === 'journal') {
                counterJournal++;
                if (action['method'] === 'order' || (action['method'] === 'migrate' && action['id'] === 'migrateProductList')) {
                    continue;
                }
            }
            if (productInfo.productVLB !== true || possibleActions.productVLB !== true) {
                counterAggregatorId++;
                if (action['method'] === 'reactivate' || action['method'] === 'archive' || action['method'] === 'confirm' || action['method'] === 'migrate') {
                    continue;
                }
                // only memorize aggregator products that can be processed.
                arrAggrProduct.push(productId);
            }
            if (productInfo.active === true || possibleActions.active === true) {
                counterActive++;
                if (action['method'] === 'reactivate') {
                    continue;
                }
            } else {
                counterNotActive++;
                if (action['method'] === 'archive' || action['method'] === 'confirm' || action['method'] === 'order') {
                    continue;
                }
            }
            if (!productInfo.publisherForMvbId || !possibleActions.publisherForMvbId) {
                counterNotOwner++;
                if (action['method'] === 'reactivate' || action['method'] === 'archive' || action['method'] === 'confirm') {
                    continue;
                }
            }
            if (possibleActions.order === false && action['method'] === 'order') {
                if (productInfo.aggregatorId == 5001015) {
                    counterNoOesbOrder++;
                } else {
                    notAvailableForOrder = true;
                }
                continue;
            }
            if (possibleActions.confirmAvailability === false && action['method'] === 'confirm') {
                counterNoOrder++;
                continue;
            }
        }
        arrProductID.push(productId);
        arrProduct.push(productInfo);
        if (productInfo.productId !== undefined) {
            arrTargetModelIds.push(productInfo.id);
        }
    }

    if (counterAggregatorId > 0 && (action['method'] === 'reactivate' || action['method'] === 'archive' || action['method'] === 'confirm')) {
        var property = "label.form." + action['confirmation'] + ".text."
            + action.id + ".aggregatorId." + action['method'];
        if (counterAggregatorId == 1) {
            property += ".single";
        }
        o = {};
        o.text = MsgHelper.formatMessage(property, [counterAggregatorId]);
        warnings.push(o);
    }

    if (counterNotActive > 0 && (action['method'] === 'archive' || action['method'] === 'confirm' || action['method'] === 'order')) {
        var property = "label.form." + action['confirmation'] + ".text."
            + action.id + ".archived." + action['method'];
        if (counterNotActive == 1) {
            property += ".single";
        }
        o = {};
        o.text = MsgHelper.formatMessage(property, [counterNotActive]);
        warnings.push(o);
    }
    if (counterActive > 0 && action['method'] === 'reactivate') {
        var property = "label.form." + action['confirmation'] + ".text."
            + action.id + ".alreadyActive";
        if (counterActive == 1) {
            property += ".single";
        }
        o = {};
        o.text = MsgHelper.formatMessage(property, [counterActive]);
        warnings.push(o);
    }
    if ((counterSet > 0 || counterSeries > 0) && action['id'] !== 'deleteTitleList' && action['method'] !== 'transformation' && action['method'] !== 'migrate') {
        var property = "label.form." + action['confirmation'] + ".text."
            + action.id + ".noSeriesNoSet";
        if ((counterSet + counterSeries) == 1) {
            property += '.single';
        }
        o = {};
        o.text = MsgHelper.formatMessage(property, [counterSet, counterSeries]);
        warnings.push(o);
    }
    if (counterMultiBundles > 0 && (action['id'] === 'deleteTitleList' || action['method'] === 'migrate')) {
        var property = "label.form." + action['confirmation'] + ".text."
            + action.id + ".noMultiBundles";
        if (counterMultiBundles == 1) {
            property += '.single';
        }
        o = {};
        o.text = MsgHelper.formatMessage(property, [counterSet, counterSeries]);
        warnings.push(o);
    }
    if (action['method'] === 'order') {
        if (counterDigital > 0) {
            var property = "label.form." + action['confirmation'] + ".text."
                + action.id + ".noDigitalOrder";
            if (counterDigital == 1) {
                property += '.single';
            }
            o = {};
            o.text = MsgHelper.formatMessage(property, [counterSet, counterSeries]);
            warnings.push(o);
        }
        if (counterJournal > 0) {
            var property = "label.form." + action['confirmation'] + ".text."
                + action.id + ".noJournalOrder";
            if (counterDigital == 1) {
                property += '.single';
            }
            o = {};
            o.text = MsgHelper.formatMessage(property, [counterSet, counterSeries]);
            warnings.push(o);
        }
    }

    if (action['method'] === 'migrate') {

        if (arrProductInformations.length > 0) {
            if (counterAggregatorId > 0) {
                o = {};
                o.text = MsgHelper.getMessage('label.form.confirmationMigrate.hint.aggretatorId');
                // TODO should be a hint, not a warning
                warnings.push(o);
            }

            if (counterJournal > 0 && action['id'] === 'migrateProductList') {
                o = {};
                o.text = MsgHelper.getMessage('label.form.confirmationMigrate.hint.zis');
                // TODO should be a hint, not a warning
                warnings.push(o);
            }

            // TODO: hint for invalid products

            // TODO: hint for isbn-präfix

            if (counterSet > 0 || counterSeries > 0) {
                o = {};
                o.text = MsgHelper.getMessage('label.form.confirmationMigrate.hint.setSeries');
                // TODO should be a hint, not a warning
                warnings.push(o);
            }

            // this case should only be temporarily --> wee need to find a solution for #11021
        } else {
            // add a hint for aggregatorId
            o = {};
            o.text = MsgHelper.getMessage('label.form.confirmationMigrate.hint.aggretatorId');
            warnings.push(o);

            // add a hint for journals
            o = {};
            o.text = MsgHelper.getMessage('label.form.confirmationMigrate.hint.zis');
            warnings.push(o);

            // add a hint for set and series
            o = {};
            o.text = MsgHelper.getMessage('label.form.confirmationMigrate.hint.setSeries');
            warnings.push(o);
        }

    }

    if (counterNoOesbOrder > 0) {
        var property = "label.form." + action['confirmation'] + ".text."
            + action.id + ".noOesbOrder";
        if (counterDigital == 1) {
            property += '.single';
        }
        o = {};
        o.text = MsgHelper.formatMessage(property, [counterSet, counterSeries]);
        warnings.push(o);
    }

    if (counterNotOwner > 0 && (action['method'] === 'reactivate' || action['method'] === 'archive' || action['method'] === 'confirm')) {
        var property = "label.form." + action['confirmation'] + ".text."
            + action.id + ".notOwner";
        if (counterDigital == 1) {
            property += '.single';
        }
        o = {};
        o.text = MsgHelper.formatMessage(property, [counterSet, counterSeries]);
        warnings.push(o);
    }
    if (notAvailableForOrder) {
        var property = "label.form." + action['confirmation'] + ".text."
            + action.id + ".notAvailableForOrder";
    }

    if (arrProductInformations.length > 0) {
        var dataArr = arrTargetModelIds.length > 0 ? arrTargetModelIds : arrProductID;
        if (dataArr.length == 1) {
            if (action['targetModel'] == 'assetFile') {
                var filename = $($('#' + dataArr[0] + ' td').get(1)).text();
                msg = MsgHelper.formatMessage("label.form.confirmationSimple.assetFile.delete.single", [filename]);
            } else {
                var input = $('input[data-id="' + dataArr[0] + '"]');
                var prop = "label.form." + action['confirmation'] + ".text." + action.id + ".single";
                msg = MsgHelper.formatMessage(prop, ["" + MiscHelper.formatNumber(arrProductInformations.length)]);
                // formerly we wrote the whole titel to the message block
                //+ ".text." + action.id + ".single", [MiscHelper.buildProductName(input
                //.data('title'), input.data('author'), input.data('ident'))]);
            }
        } else if (dataArr.length == 0) {
            var prop = "label.form." + action['confirmation'] + ".text." + action.id + ".none";
            msg = MsgHelper.formatMessage(prop, ["" + MiscHelper.formatNumber(arrProductInformations.length)]);
        } else {
            var prop = "label.form." + action['confirmation'] + ".text." + action.id;
            msg = MsgHelper.formatMessage(prop, ["" + MiscHelper.formatNumber(arrProductInformations.length)]);
        }
    } else if (action['confirmation'] !== 'confirmationDownload' || action['confirmation'] !== 'migrateProductList') {
        msg = MsgHelper.formatMessage("label.form." + action['confirmation']
            + ".text." + action.id + ".atLeastOne");
    }
    this.confirmAction(event, options, arrProductID, msg, warnings, successCallback, arrAggrProduct,
        arrProductInformations.length, counterSeries, counterSet, arrTargetModelIds, arrProduct);
};

ConfirmationHelper.prototype.confirmSingleActionProduct = function (event, options, product, successCallback) {
    var action = ActionHelper.findActionById(options.formDef,
        event.currentTarget.attributes["action"].value);
    var msg = MsgHelper
        .formatMessage("label.form." + action['confirmation'] + ".text." + action.id, ['1']);
    // formerly we wrote the whole titel to the message block
    //+ action.id, [MiscHelper.buildProductNameByProduct(product)]);
    var arrProductId = [product.id];
    var arrNonVLBProduct = [];
    if (product.productVLB !== true) {
        arrNonVLBProduct = [product.id];
    }
    this.confirmAction(event, options, arrProductId, msg, undefined, successCallback, arrNonVLBProduct,
        arrProductId.length, -1, -1, undefined, [product]);
};

ConfirmationHelper.prototype.confirmAction = function (event, options,
                                                       arrProductId, msg, warnings,
                                                       successCallback, arrNonVLBProduct,
                                                       selectionLength, counterSeries, counterSet,
                                                       arrTargetModelIds, arrProduct) {

    var action = ActionHelper.findActionById(options.formDef,
        event.currentTarget.attributes["action"].value);
    var httpType = "POST";
    var modelUrlRoot = '';
    if (event.currentTarget.attributes["data-modelUrlRoot"] !== undefined) {
        modelUrlRoot = event.currentTarget.attributes["data-modelUrlRoot"].value;
    }
    var url;
    if (!MiscHelper.isEmpty(action['targetUrl'])) {
        url = FormHelper.buildApiUrl(action['targetUrl']);
    } else {
        if (modelUrlRoot.charAt(0) !== "/") {
            url = '/' + modelUrlRoot;
        } else {
            url = modelUrlRoot;
        }
    }

    var localSuccessCallback = undefined;
    if ("delete" === action['method']) {
        httpType = "DELETE";
        localSuccessCallback = function (data) {


            if (successCallback !== undefined && successCallback !== null) {
                if (data != undefined && data.id != undefined) {
                    successCallback(data);
                } else {
                    successCallback({id: data});
                }
            }

            if (httpType === 'DELETE') {
                if ($(options.tableSelector).length > 0) {
                    var table = $(options.tableSelector).dataTable();
                    table.fnDeleteRow();
                }
            }
        };
    } else if ("edit" === action['method'] || "ibuexport" === action['method']) {
        localSuccessCallback = function (data) {
            if (successCallback !== undefined && successCallback !== null) {
                successCallback(data);
            }
            var table = $(options.tableSelector).DataTable();
            table.ajax.reload();
            if ("ibuexport" === action['method']) {
                $('#datatable_widget-sentOrders').DataTable().ajax.reload();
            }

        };
    } else {
        localSuccessCallback = successCallback;
    }
    var confirmMsg = MsgHelper.getMessage("msg.confirm.dialog");

    var confirmation = action['confirmation'];
    if (confirmation === 'confirmationRadio'
        || confirmation === 'confirmationSimple'
        || confirmation === 'confirmationAcknowledge'
        || confirmation === 'confirmationSimpleDownload'
        || confirmation === 'confirmationReason'
        || confirmation === 'confirmationDownload'
        || confirmation === 'confirmationOrderbookOrder'
        || confirmation === 'confirmationQuickExport'
        || confirmation === 'confirmationMigrate') {

        var html = "";
        var view = null;
        var confirm;
        var scopeData = jQuery.extend(true, {}, options.formDef, {
            ident: action.id
        });
        scopeData.text = msg;
        scopeData.warnings = warnings;
        scopeData.collection = null;

        var dialogOptions = {};
        if (arrProduct.length < selectionLength) {
            var prop = "label.form." + action['confirmation'] + ".textSecondLine." + action.id;
            scopeData.textSecondLine = MsgHelper.formatMessage(prop, ["" + (selectionLength - arrProduct.length)]);
        }
        if (action['method'] === 'confirm') {
            var hint = [];
            var o = {};
            o.text = MsgHelper.getMessage('label.form.' + action['confirmation'] + '.hint.' + action['method']);
            hint.push(o);
            scopeData.hint = hint;
        } else if (action['method'] === 'delete') {
            for (var i = 0; i < arrProduct.length; i++) {
                if (arrProduct[i].type === 'set' || arrProduct[i].type === 'series') {
                    scopeData.textSecondLine = MsgHelper.getMessage('label.form.confirmationSimple.text.deleteTitle.dependentHint');
                    break;
                }
            }
        } else if (action['method'] === 'migrate') {
            var hint = [];
            var o = {};
            o.text = MsgHelper.getMessage('label.form.' + action['confirmation'] + '.hint.' + action['method']);
            hint.push(o);
            scopeData.hint = hint;
        } else if (action['method'] === 'archive') {
            var hint = [];
            var o = {};
            o.text = MsgHelper.getMessage('label.form.' + action['confirmation'] + '.hint.' + action['method']);
            hint.push(o);
            scopeData.hint = hint;
            var maxWidth;
            if (versionCountry === 'brasil' || versionCountry === 'latam') {
                maxWidth = '615px';
            } else {
                maxWidth = '500px';
            }
            dialogOptions = {
                'closeImmediateOnConfirm': true,
                'maxWidth': maxWidth
            };
        } else if (action['method'] === 'reactivate') {
            var hint = [];
            var o = {};
            o.text = MsgHelper.getMessage('label.form.' + action['confirmation'] + '.hint.' + action['method']);
            hint.push(o);
            scopeData.hint = hint;
            dialogOptions = {
                'closeImmediateOnConfirm': true,
            };
        }

        if (confirmation === 'confirmationDownload') {
            var headerText;
            if (selectionLength > 0) {
                headerText = msg;
            } else {
                headerText = MsgHelper.formatMessage("label.pageContent.download.headerText.all",
                    [MiscHelper.formatNumber(options.table.context[0]._iRecordsTotal)]);
            }
            scopeData.headerText = headerText;
            scopeData.collection = FormHelper.getCollection(
                options.formDef.attributes['collections'], action.collection);
            html = ConfirmationDownload(scopeData);
        } else if (confirmation === 'confirmationMigrate') {
            scopeData = this.setConfirmationMigrateScopeData(scopeData, action, options, arrProduct, selectionLength);
            html = ConfirmationMigrate(scopeData);
        } else if (action["method"] === "ibuexport") {
            var OrderbookCredentialsCheckModel = Model.extend({urlRoot: apiRoot + 'orderbook/checkCredentials'});
            var orderbookCredentialsCheckModel = new OrderbookCredentialsCheckModel();
            orderbookCredentialsCheckModel.fetch({async: false});
            if (orderbookCredentialsCheckModel.get('hasIbuNumber') && orderbookCredentialsCheckModel.get('hasIbuPassword')) {
                html = ConfirmationSimple(scopeData);
            } else {
                scopeData.text = MsgHelper.formatMessage('label.form.confirmationSimple.text.ibu.noCredentials');
                html = ConfirmationAcknowledge(scopeData);
            }
        } else if (selectionLength === 0) {
            html = ConfirmationAcknowledge(scopeData);
        } else if (arrProduct.length == 0) {
            html = ConfirmationAcknowledge(scopeData);
        } else if (confirmation === 'confirmationSimple') {
            html = ConfirmationSimple(scopeData);
        } else if (confirmation === 'confirmationAcknowledge') {
            scopeData.whichButton = 'confirm';
            html = ConfirmationAcknowledge(scopeData);
        } else if (confirmation === 'confirmationSimpleDownload') {
            html = ConfirmationSimple(scopeData);
        } else if (confirmation === 'confirmationReason') {
            if (action.method === 'saveAsFavourite') {
                scopeData.value = arrProduct[0].name;
            } else {
                scopeData.placeholder = MsgHelper.getMessage('label.form.confirmation.inputfield');
            }
            html = ConfirmationReason(scopeData);
        } else if (confirmation === 'confirmationRadio') {
            scopeData.textRadioSelect = MsgHelper.formatMessage("label.form." + action['confirmation']
                + ".textRadioSelect." + action.id, ["" + (selectionLength - arrProduct.length)]);
            scopeData.collection = FormHelper.getCollection(
                options.formDef.attributes['collections'], action.collection);
            html = ConfirmationRadio(scopeData);
        } else if (confirmation === 'confirmationOrderbookOrder') {
            view = new OrderbookOrderFormView({
                id: scopeData.ident, scopeData: scopeData, productIds: arrProductId,
                counterSet: counterSet, counterSeries: counterSeries, mode: action.method
            });
        }

        var confirm = this.alertifyJsonCallbackSingleSelectionAction(action, url, httpType, true, localSuccessCallback,
            options, view, arrTargetModelIds, arrProduct);

        if (confirmation === 'confirmationQuickExport') {
            confirm(event, undefined, "vlb-download.kvp", arrNonVLBProduct, arrTargetModelIds, undefined);
        } else {
            var dialogConfirm = function (event, str) {
                if (arrProductId.length > 0 || action['confirmation'] === 'confirmationDownload' || action['confirmation'] === 'confirmationMigrate') {
                    var hasSelection = $('#' + action.id + ' input[type="radio"]').length > 0;
                    var hasMigrationPublisher = $('#' + action.id + ' input.migrate-publishers').length > 0;
                    var checkedItemVal;
                    var textItemVal;
                    var checkedSetSeriesVal;
                    var doAction = true;
                    var messages = [];

                    if (hasMigrationPublisher) {
                        var textItem = $('#' + action.id + ' input.migrate-publishers');
                        if (textItem.val() === '') {
                            var msg = MsgHelper.getMessage("label.pageContent." + action.id + ".pleaseChoosePublisher");
                            messages.push(msg);
                            doAction = false;
                        } else {
                            $('#' + action.id + '-msg #error').attr('style', 'display: none;')
                            textItemVal = textItem.val();
                        }
                    }

                    if (hasSelection) {
                        var checkedItem = $('.fancybox-skin #' + action.id + ' input[name="' + action.id + '"]:checked');
                        if (checkedItem.val() === undefined) {
                            var msg = MsgHelper.getMessage("label.pageContent." + action.id + ".pleaseChooseOneReaason");
                            messages.push(msg);
                            doAction = false;
                        } else {
                            $('#' + action.id + '-msg #error').attr('style', 'display: none;')
                            checkedItemVal = checkedItem.val();
                        }
                    }

                    if (messages.length > 0) {
                        var msg = '';
                        for (var i = 0; i < messages.length; i++) {
                            msg += messages[i] + '</br>';
                        }

                        $('#' + action.id + '-msg #error').attr('style', '')
                        $('#' + action.id + '-msg #error span').html(msg);
                    }

                    if (doAction) {
                        var textItem = $('#' + action.id + ' #toBeSend');
                        if (!MiscHelper.isEmpty(textItem)) {
                            textItemVal = textItem.val();
                            textItemVal = LinkHelper.encodeURI(textItemVal);
                        }
                        $('.' + action.id + '-confirm').addClass('disabled');

                        if (action['confirmation'] === 'confirmationMigrate') {
                            $('.' + action.id + '-confirm').addClass('hidden');
                            $('.' + action.id + '-close').addClass('disabled');
                            $('.' + action.id + '-close span').text(MsgHelper.getMessage('label.form.button.ok'));
                            // $.fancybox.showLoading();
                        }

                        confirm(event, checkedItemVal, textItemVal, arrNonVLBProduct, arrTargetModelIds);
                    }
                }
            };
            if (view != null) {
                // if we initialzed a view we use the views html
                html = view.el;
            }

            this.openDialog(html, action.id, dialogConfirm, dialogOptions);

            if (view != null) {
                // we already have the view attached manually to the fancybox,
                // but we want to trigger addedToDOM event and initialization in views attach function
                view.attach();
            }
            if (arrProductId.length === 0 && selectionLength > 0) {
                $('.' + action.id + '-confirm').addClass('disabled');
            }
            this.initConfirmationDownload(confirmation, action, arrNonVLBProduct, arrProduct, options);

            //init the confirmation for migration
            if (action['confirmation'] === 'confirmationMigrate') {
                this.initConfirmationMigrate(confirmation, action, options, arrNonVLBProduct, arrProduct, scopeData, selectionLength);
            } else if (action['method'] === 'archive') {
                this.initConfirmationArchive(confirmation, action, options, arrNonVLBProduct, arrProduct, scopeData, selectionLength);
            }
        }


    } else {
        alertify.confirm(confirmMsg, this.alertifyJsonCallbackSingleSelectionAction(action, url, httpType, false, localSuccessCallback, arrProduct));
    }
};

ConfirmationHelper.prototype.openDialog = function (dialogDiv, dialogId, confirmFunction, options) {

    // Now openDialog Routines resides in it's own source file. Please use that call direct without that indirection
    // for new dialogs that must be opened.
    ConfirmationOpenDialogHelper.openDialog(dialogDiv, dialogId, options, confirmFunction);
};


ConfirmationHelper.prototype.alertifyJsonCallbackSingleSelectionAction = function (action, url, httpType,
                                                                                   appendTextToUrl, successCallback,
                                                                                   options, view, arrTargetModelIds,
                                                                                   arrProduct) {
    var that = this;

    return function (e, checkedItemVal, textItemVal, arrNonVLBProduct, arrTargetModelIds) {

        if (arrProduct.length == 0
            && action['confirmation'] !== 'confirmationDownload' && action['confirmation'] !== 'confirmationQuickExport'
            && action['confirmation'] !== 'confirmationMigrate') {
            // if action is confirmationDownload or confirmationQuickExport all titles should be downloaded.
            // else nothing to do here so go away again...
            return;
        }

        var sendData = null;
        var sendDataType = null;
        var localURL = url;
        var msg = "";

        if (localURL.substr(localURL.lengh - 1) !== '/') {
            localURL += '/';
        }

        var o = {};

        if (view != null) {
            o = view.model;
        } else {
            o = {};
            if (arrTargetModelIds !== undefined && arrTargetModelIds.length > 0
                && ((action['confirmation'] !== 'confirmationDownload' && action['confirmation'] !== 'confirmationQuickExport'))) {
                o.ids = arrTargetModelIds;
            } else {
                o.ids = [];
                o.product = [];
                if (options.table) {
                    o.order = [];
                    var arrOrder = options.table.order();
                    for (var i = 0; i < arrOrder.length; i++) {
                        var tmp = [];
                        tmp.push(options.table.ajax.params().columns[arrOrder[i][0]].name, arrOrder[i][1]);
                        o.order.push(tmp);
                    }
                }
                if (action['id'] === 'deleteTitleList') {
                    for (var i = 0; i < arrProduct.length; i++) {
                        o.ids.push(arrProduct[i].id);
                        o.product.push(arrProduct[i]);
                    }
                } else if (action['confirmation'] === 'confirmationDownload' || action['confirmation'] === 'confirmationQuickExport') {
                    var numberOfInvalidISBNProducts = 0;
                    var push;
                    for (var i = 0; i < arrProduct.length; i++) {
                        push = true;
                        // It is not allowed to export titles for reimport or rightsLink Format if they are unknown by MVB.
                        // Products are unknown if they are imported from any aggregator. That can be seen if an
                        // aggregatorId is available.
                        if (arrNonVLBProduct.indexOf(arrProduct[i].id) > -1 && (checkedItemVal === 'reimport' || checkedItemVal === 'rightsLink')) {
                            push = false;
                        }
                        if (MiscHelper.isEmpty(arrProduct[i].isbn) && checkedItemVal === 'rightsLink') {
                            // rights link information exists only for products which have an ISBN number.
                            numberOfInvalidISBNProducts++;
                            push = false;
                        }
                        if (push) {
                            o.ids.push(arrProduct[i].id);
                            o.product.push(arrProduct[i]);
                        }
                    }
                    var messages = [];
                    if (arrNonVLBProduct.length > 0) {

                        if (checkedItemVal === 'reimport') {
                            if (arrNonVLBProduct.length === 1) {
                                msg = MsgHelper.formatMessage('label.form.confirmationDownload.reimport.NoExport.single');
                            } else {
                                msg = MsgHelper.formatMessage('label.form.confirmationDownload.reimport.NoExport', [arrNonVLBProduct.length]);
                            }
                            messages.push(msg);
                        } else if (checkedItemVal === 'rightsLink') {
                            if (arrNonVLBProduct.length === 1) {
                                msg = MsgHelper.formatMessage('label.form.confirmationDownload.rightsLink.NoExport.single');
                            } else {
                                msg = MsgHelper.formatMessage('label.form.confirmationDownload.rightsLink.NoExport', [arrNonVLBProduct.length]);
                            }
                            messages.push(msg);
                        }
                    }
                    if (numberOfInvalidISBNProducts === 1) {
                        messages.push(MsgHelper.formatMessage('label.form.confirmationDownload.rightsLink.isbn.NoExport.single'));
                    } else if (numberOfInvalidISBNProducts > 1) {
                        messages.push(MsgHelper.formatMessage('label.form.confirmationDownload.rightsLink.isbn.NoExport', [numberOfInvalidISBNProducts]));
                    }
                    if (messages.length > 0) {
                        var html = '<ul>';
                        for (var i = 0; i < messages.length; i++) {
                            html += '<li>' + messages[i] + '</li>';
                        }
                        html += '</ul>';
                        $('#' + action.id + '-msg #error span').html(html);
                        $('#' + action.id + '-msg #error').attr('style', '')
                        // There is no product left which is a valid export.
                        if (o.ids.length === 0) {
                            return;
                        }
                    }
                    // TODO: confirmationMigration:
                    // if nothing is selected there also should be an array with ids, make sure to send one
                } else {
                    for (var i = 0; i < arrProduct.length; i++) {
                        o.ids.push(arrProduct[i].id);
                        o.product.push(arrProduct[i]);
                    }
                    //arrProduct.each(function(){
                    //    o.ids.push(this.id);
                    //});
                }
            }
        }

        o.fields = [];
        $('#cdSelection ul li input:checked~span').each(function (index, elem) {
            o.fields.push(this.textContent);
        });

        // (HGF) Please do not longer support this horrible difference in handling a single or multiple products!!!
        if (action['id'] === 'deleteTitleList' || action['method'] === 'confirm') {
            localURL += action['method'];
        } else if (httpType === "DELETE") {
            localURL += arrProduct[0].id;
        } else {
            if (arrProduct.length == 1 && action['confirmation'] !== 'confirmationDownload'
                && action['confirmation'] !== 'confirmationQuickExport'
                && action['confirmation'] !== 'confirmationMigrate') {
                localURL += arrProduct[0].id + '/';
            }
            localURL += action['method'];
        }

        if (action['confirmation'] === 'confirmationDownload' || action['confirmation'] === 'confirmationQuickExport') {
            if (arrProduct.length == 0) {
                if (options.model.attributes.id !== undefined) {
                    o.queryId = options.model.attributes.id;
                }
                if (options.model.attributes.query !== undefined) {
                    o.query = LinkHelper.encodeURI(options.model.attributes.query);
                }
            }
            if (checkedItemVal !== undefined) {
                localURL += '/' + checkedItemVal;
            }
            if (arrProduct.length == 0) {
                localURL += "/" + options.modelKey;
                if (options.searchType !== undefined) {
                    localURL += "/" + options.searchType;
                } else if (options.modelKey === 'productTracker' || options.modelKey === 'productTrackerEdit') {
                    localURL += "/currentUserId";
                }
            }
            localURL += '?access_token=' + user.access_token + '&filename=' + textItemVal;
            localURL += '&columnHeading=' + ($('#cdColumns .form-wrap-inner input[name=columnHeading]').is(':checked') ? 'true' : 'false');
            localURL += '&publisherData=' + ($('#cdPublisherData .form-wrap-inner input[name=publisherData]').is(':checked') ? 'true' : 'false');
            var delimiter;
            if ($('#cdAdditionals input[name=separatorCheck][value=select]:checked').attr('value') === 'select') {
                delimiter = $('#cdAdditionals div label select[id=delimiterSelect]').val();
            } else {
                delimiter = $('#cdAdditionals div label input[id=separatorSign]').val();
            }
            if (!MiscHelper.isEmpty(delimiter)) {
                localURL += '&delimiter=' + LinkHelper.encodeURI(delimiter);
            }
            localURL += '&textRecognition=' + LinkHelper.encodeURI($('#cdAdditionals div label select[id=textRecognition]').val());
        } else if (action['confirmation'] === 'confirmationMigrate') {
            if (arrProduct.length == 0) {
                if (options.model.attributes.id !== undefined) {
                    o.queryId = options.model.attributes.id;
                }
                if (options.model.attributes.query !== undefined) {
                    o.query = options.model.attributes.query;
                }
            }
            // in case of migrate textItemVal holds the id of the new publisher
            localURL += '/' + textItemVal;
            localURL += '?access_token=' + user.access_token + '&migrationCategory=' + checkedItemVal;
        } else if (appendTextToUrl) {
            localURL = localURL + '?access_token=' + user.access_token;
            var str = checkedItemVal === undefined ? textItemVal : checkedItemVal;
            if (str !== undefined) {
                localURL += "&reason=" + str;
            }
        }
        if (action['targetModel'] === 'assetFile' && action['method'] == 'delete') {
            o.queryId = options.productId;
        }

        sendData = JSON.stringify(o);
        sendDataType = 'json';
        that.doTheConfirmation(sendData, sendDataType, localURL, httpType, action, successCallback, view);

    };
};

ConfirmationHelper.prototype.downloadReport = function (downloadURL) {
    var fancyBoxesDiv = $('#download-div');
    var iframe = $('#download-div iframe');
    if (iframe !== undefined) {
        $('#download-div iframe').remove();
    }
    iframe = $("<iframe id=\"iFrameDownload\" />").hide().appendTo(fancyBoxesDiv);
    iframe.attr('src', downloadURL);
}

ConfirmationHelper.prototype.doTheConfirmation = function (sendData, sendDataType, localURL, httpType, action, successCallback, view, contentType) {
    var that = this;

    if (contentType === undefined) {
        contentType = 'application/json';
    }

    var request = jQuery.ajax({
        contentType: contentType,
        data: sendData,
        dataType: sendDataType,
        url: localURL,
        type: httpType,
        "success": function (data) {
            if (action['method'] === 'confirm' || action['method'] === 'migrate') {
                return;
            }
            $('.' + action.id + '-confirm').removeClass('disabled');
            if (action['method'] === 'transformation' || action['method'] === 'downloadOpenOrders' || action['method'] === 'downloadSentOrders'
                || action['method'] === 'directAccountUserExport' || action['method'] === 'directFilterExport'
                || action['method'] === 'switchActiveLogEntryState' || action['confirmation'] === "confirmationSimpleDownload"
                || action['confirmation'] === 'confirmationQuickExport' || action['confirmation'] === 'exportReport') {
                var fancyBoxesDiv = $('#download-div');
                var iframe = $('#download-div iframe');
                if (iframe !== undefined) {
                    $('#download-div iframe').remove();
                }
                iframe = $("<iframe id=\"iFrameDownload\" />").hide().appendTo(fancyBoxesDiv);
                var downloadId = data;
                var downloadURL;
                if (action['method'] === 'directAccountUserExport') {
                    downloadURL = apiRoot + 'files/aue/' + downloadId + "?access_token=" + user.access_token;
                } else if (action['confirmation'] === 'directFilterExport') {
                    downloadURL = apiRoot + 'files/assetExport/' + downloadId + "?access_token=" + user.access_token;
                } else if (action['confirmation'] === 'exportReport') {
                    downloadURL = apiRoot + 'files/reporting/export/' + downloadId + "?access_token=" + user.access_token;
                } else if (action['method'] === 'switchActiveLogEntryState') {
                    downloadURL = apiRoot + 'logentry/archive/';
                } else {
                    downloadURL = apiRoot + 'files/' + downloadId + "?access_token=" + user.access_token;
                }
                iframe.attr('src', downloadURL);

                if (successCallback !== undefined && successCallback !== null) {
                    successCallback();
                }
                $('.' + action.id + '-close').trigger('click');
            } else if (action['method'] === 'edit' || action['id'] === 'deleteTitleList') {
                if (successCallback !== undefined && successCallback !== null) {
                    successCallback(data);
                    $('.' + action.id + '-close').trigger('click');
                }
            } else if (action['method'] === 'ibuexport' || (action['targetModel'] == 'assetFile' && action['method'] == 'delete')) {
                if (data.responseType === "ERROR") {
                    return this.error(data);
                }
                if (data.successMessage != undefined) {
                    alertify.success(MsgHelper.getMessage(data.successMessage));
                }
                if (successCallback !== undefined && successCallback !== null) {
                    successCallback(data);
                }
                $('.' + action.id + '-close').trigger('click');

            } else if (action['id'] === 'deleteUser' || action['id'] === 'deleteAccount') {
                if (data.errors && data.errors.length > 0) {
                    $('#' + action.id + '-msg #error span').text(MsgHelper.getMessage(data.errors[0].msg));
                    $('#' + action.id + '-msg #error').attr('style', '');
                } else {
                    $('.' + action.id + '-close').trigger('click');
                    successCallback(data);
                }
            } else {
                if (data.responseType === "ERROR") {
                    return this.error(data);
                }

                if (data.successMessage != undefined) {
                    alertify.success(MsgHelper.getMessage(data.successMessage));
                }

                if (view != undefined && view.successCallback != undefined) {
                    view.successCallback(data);
                }

                var errorP = 0;
                var validP = 0;
                var errorMDS = 0;
                var errorMigrate = 0;
                var errorMsg = "";
                var validMsg = "";
                var counterPreventSave = 0;
                if (Array.isArray(data)) {
                    for (var i = 0; i < data.length; i++) {
                        if (successCallback !== undefined && successCallback !== null) {
                            successCallback(data[i]);
                        }
                    }
                } else {
                    for (var feedback in data) {
                        if (data[feedback] !== null && data[feedback].responseType === 'ERROR') {
                            if (errorP === 0 && validP > 0) {
                                $('#' + action.id + '-msg').css('margin-bottom', '50px');
                            }
                            errorP++;
                            if (data[feedback].validationMessages !== undefined) {
                                if (action.method === 'migrate') {
                                    var validationMessages = data[feedback].validationMessages.validationMessages;
                                    var singleSuffix = '';
                                    if (!MiscHelper.isEmpty(validationMessages)) {
                                        for (var i = 0; i < validationMessages.length; i++) {
                                            if (validationMessages[i].code === '206') {
                                                // the validation code 206 is only set once in the validation messages so we can count the errors for ISBN Prefix here
                                                errorMDS++;
                                            } else if (validationMessages[i].type === 'error') {
                                                // counter for invalid titles
                                                counterPreventSave++;
                                            }
                                        }
                                    }

                                    if (counterPreventSave > 0) {
                                        // calculate the general error counter for invalid titles
                                        errorMigrate = errorMDS > 0 ? errorP - errorMDS : errorP;
                                    }

                                    if (errorMDS === 1 || errorMigrate === 1) {
                                        singleSuffix = ".single";
                                    }

                                    if (errorMDS > 0 && errorMigrate > 0) {
                                        errorMsg = MsgHelper.formatMessage('label.pageContent.migrateProduct.noISBNPrefix' + singleSuffix, [errorMDS]);
                                        errorMsg += ' ' + MsgHelper.formatMessage('label.pageContent.migrateProduct.invalidProduct' + singleSuffix, [errorMigrate]);
                                    } else if (errorMDS > 0) {
                                        errorMsg = MsgHelper.formatMessage('label.pageContent.migrateProduct.noISBNPrefix' + singleSuffix, [errorMDS]);
                                    } else if (errorMigrate > 0) {
                                        errorMsg = MsgHelper.formatMessage('label.pageContent.migrateProduct.invalidProduct' + singleSuffix, [errorMigrate]);
                                    }

                                } else {
                                    if (errorP === 1) {
                                        errorMsg = MsgHelper.formatMessage("invalid.products.simple", [errorP]);
                                    } else {
                                        errorMsg = MsgHelper.formatMessage("invalid.products", [errorP]);
                                    }
                                }
                            } else {
                                errorMsg = MsgHelper.getMessage("unknown.error");
                            }
                            if (!MiscHelper.isEmpty(errorMsg)) {
                                $('#' + action.id + '-msg #error span').text(errorMsg);
                                $('#' + action.id + '-msg #error').attr('style', '');
                            }
                        } else {
                            if (errorP > 0 && validP === 0) {
                                $('#' + action.id + '-msg').css('margin-bottom', '50px');
                            }
                            validP++;
                            if (validP === 1) {
                                validMsg = MsgHelper.formatMessage("label.pageContent."
                                    + action.id + ".success.simple");
                            } else {
                                validMsg = MsgHelper.formatMessage("label.pageContent."
                                    + action.id + ".success", [validP]);
                            }

                            if (!MiscHelper.isEmpty(validMsg)) {
                                $('#' + action.id + '-msg #valid span').text(validMsg);
                                $('#' + action.id + '-msg #valid').attr('style', '');
                            }
                            if (successCallback !== undefined && successCallback !== null) {
                                successCallback(data[feedback]);
                            }
                        }
                        if (data[feedback] != null && data[feedback].validationMessages !== undefined) {
                            // leave this message until we have a better concept to show validation messages.
                            console.log(data[feedback].validationMessages);

                        }
                    }
                }
                if (errorP === 0) {
                    $('.' + action.id + '-close').trigger('click');
                }
            }
        },
        "error": function (data) {
            var msg = MsgHelper.getMessage("label.pageContent." + action.id + (data.status != undefined ? "." + data.status : '.error'));
            $('.' + action.id + '-confirm').removeClass('disabled');
            $('#' + action.id + '-msg #error span').html(msg);
            $('#' + action.id + '-msg #error').attr('style', '');
        },
        "beforeSend": function () {
            // jQuery('.datatableProcessing').show();
        },
        "complete": function () {
            // jQuery('.datatableProcessing').hide();
        },
        "headers": {
            'Authorization': "Bearer " + user.access_token
        }
    });
    if (action['method'] === 'transformation' || action['method'] === 'exportReport') {
        setTimeout(function () {
            if (request.state() == 'pending') {
                //$('.vlb-action-icon-confirm-availability').click();
                var scopeData = {};
                scopeData.text = MsgHelper.getMessage('label.form.confirmationAcknowledge.' + action['method'] + '.text.thisTakesALongTimeId');
                scopeData.ident = 'thisTakesALongTimeId';
                scopeData.whichButton = 'ok';
                var html = ConfirmationAcknowledge(scopeData);
                var confirmDialog = function (event) {
                    $.fancybox.close();
                    var div = $('#' + scopeData.ident + 'Confirmation');
                    div.fadeOut('slow');
                    div.remove();
                }
                that.openDialog(html, scopeData.ident, confirmDialog);
            }
            //if(request.state() == 'pending') alert('dauert ewig');
        }, 3000);
    } else if (action['method'] === 'confirm' || action['method'] === 'migrate') {
        successCallback();
        $.fancybox.close();
    }
    // else if (action['method'] === 'migrate') {
    // 	// check if the ajax request is done so we can enable the close btn
    // 	$(document).ajaxStop(function() {
    // 		$.fancybox.hideLoading();
    // 		$('.' + action.id + '-close').removeClass('disabled');
    // 	});
    // }
}

ConfirmationHelper.prototype.alertifyJsonCallbackMultipleSelectionAction = function (action, url, appendTextToUrl) {
    var ajaxCall = this.runSelectionAjax;
    var selections = this.selections;
    return function (e, str) {
        if (e) {
            if (appendTextToUrl) {
                url += "?reason=" + str;
            }
            ajaxCall(action, url, selections);
        }
    };
};

ConfirmationHelper.prototype.initConfirmationDownload = function (confirmation, action, arrNonVLBProduct, arrProduct, options) {

    if (action['confirmation'] === 'confirmationDownload') {
        //var saveIsActive = false;
        var simpleList = $('#cdSelection #simpleList');
        var saveButton = $('.' + action.id + '-save');
        var selectionName = $('#cdSelection #selectionName');
        var selectAll = $('#cdSelection .savedSelection input[name=selectAll]');
        var saveDisk = $('#cdSelection .savedSelection a');
        var iconInputCheck = $('#' + action.id + ' .vlb-icon-input-check');

        $('#' + action.id + '-msg #error').attr('style', 'display: none;');
        //$('#' + action.id + '-msg #valid').attr('style', 'display: none;');
        selectAll.off('click');
        selectAll.on('click', function (event) {
            iconInputCheck.get()[0].style.display = 'none';
            saveDisk.get()[0].style.display = 'inline';
            var checked = this.checked;
            $('#cdSelection  li input').each(function (index, elem) {
                this.checked = checked;
            })
        });
        selectionName.off('keyup');
        selectionName.on('keyup', function () {
            iconInputCheck.get()[0].style.display = 'none';
            saveDisk.get()[0].style.display = 'inline';
        });
        simpleList.off('click');
        simpleList.on('click', function () {
            iconInputCheck.get()[0].style.display = 'none';
            saveDisk.get()[0].style.display = 'inline';
        });
        saveButton.unbind("click");
        saveButton.click(function () {
                iconInputCheck.get()[0].style.display = 'none';
                saveDisk.get()[0].style.display = 'inline';
                //if (saveIsActive === false) {
                //    return;
                //}
                if (MiscHelper.isEmpty(selectionName.val())) {
                    return;
                }

                var o = {};
                if (simpleList.data('databaseId') !== undefined) {
                    o.id = simpleList.data('databaseId');
                }
                if (simpleList.data('version') !== undefined) {
                    o.version = simpleList.data('version');
                }
                o.name = selectionName.val();
                o.userExportColumnData = []
                $('#cdSelection ul li').each(function (index, elem) {
                    var checked = $(this).find('input').is(':checked');
                    var name = $(this).find('span').text();
                    var userExportColumnData = new Object();
                    userExportColumnData.column = name;
                    userExportColumnData.orderIndex = index;
                    userExportColumnData.checked = checked;
                    o.userExportColumnData.push(userExportColumnData);
                });
                $.ajax({
                    url: "userExportColumns/saveColumnsDefinition",
                    contentType: 'application/json',
                    dataType: 'json',
                    data: JSON.stringify(o),
                    type: 'POST',
                    "success": function (data, textStatus, jqXHR) {
                        simpleList.data('version', data.version);
                        simpleList.data('databaseId', data.id);
                        var msg = MsgHelper.getMessage("label.pageContent." + action.id + ".save.success");
                        $('#cdColumns .savedSelection .select2-chosen').text(o.name);
                        //$('#' + action.id + '-msg #valid span').text(msg);
                        //$('#' + action.id + '-msg #valid').attr('style', '');
                        iconInputCheck.get()[0].style.display = 'inline';
                        saveDisk.get()[0].style.display = 'none';
                        $('#' + action.id + '-msg #error').attr('style', 'display: none;')
                    },
                    "error": function (jqXHR, textStatus, errorThrown) {
                        var msg = MsgHelper.getMessage("label.pageContent." + action.id + ".save.error");
                        $('#' + action.id + '-msg #error').attr('style', '');
                        $('#' + action.id + '-msg #error span').text(msg);
                        //$('#' + action.id + '-msg #valid').attr('style', 'display: none;')
                    }
                });
            }
        );

        $('#delimiterSelect').select2({
            minimumResultsForSearch: -1
        });
        $('#textRecognition').select2({
            minimumResultsForSearch: -1
        });

        var myTerm;
        $(".js-data-example-ajax").select2({
            ajax: {
                url: "userExportColumns/columnsDefinitions",
                dataType: 'json',
                delay: 0,
                data: function (term) {
                    myTerm = term;
                    return {
                        term: term
                    };
                },
                results: function (data, page, query) {
                    //if (myTerm.length > 0 && (data.length !== 1 || myTerm !== data[0].text)) {
                    //    var o = new Object();
                    //    o.id = myTerm;
                    //    o.text = myTerm;
                    //    o.new = true;
                    //    data.unshift(o);
                    //}
                    //data.unshift({
                    //    "id": "createNew",
                    //    "value": MsgHelper.getMessage("label.pageContent.download.createNew"),
                    //    "text": MsgHelper.getMessage("label.pageContent.download.createNew")
                    //});
                    return {
                        results: data
                    };
                },
                cache: true
            },
            initSelection: function (element, callback) {
                var id = $(element).val();
            },
            minimumResultsForSearch: -1,
            placeholder: MsgHelper.getMessage('label.pageContent.download.columnSelectionName')
        }).on('select2-selecting', function (e) {
            //saveIsActive = true;
            if (e.object.new !== undefined && e.object.new === true) {
                //jQuery.ajax({
                //    contentType: 'application/json',
                //    url: 'userExportColumns/availableColumnsFull',
                //    type: 'GET',
                //    "success": function (data) {
                //        var list = simpleList;
                //        list.removeData('version');
                //        list.removeData('databaseId');
                //        list.empty();
                //        for (var i = 0; i < data.length; i++) {
                //            list.append('<li><input type="checkbox" checked="checked"><span>' + data[i] + '</span></li>');
                //        }
                //        var el = document.getElementById('simpleList');
                //        Sortable.create(el);
                //    },
                //    "error": function (data) {
                //    }
                //});
            } else if (e.object.id === 'createNew') {
                $.removeData(simpleList[0], 'databaseId');
                selectionName.prop("disabled", false);
                selectionName.val('');
                selectAll.prop('disabled', false);
                selectAll.prop('checked', true);
                selectAll.parent().removeClass('deactivated');
                $('#cdSelection  li input').each(function (index, elem) {
                    this.checked = true;
                    this.disabled = false;
                })
                var placeholder = MsgHelper.getMessage('label.pageContent.download.enterName');
                selectionName.attr('placeholder', placeholder);
                iconInputCheck.get()[0].style.display = 'none';
                simpleList.removeClass('deactivated');
            } else {
                selectionName.prop("disabled", false);
                var name = e.object.text;
                selectionName.val(name);
                selectionName.attr('placeholder', '');
                selectAll.prop('disabled', false);
                selectAll.prop('checked', false);
                selectAll.parent().removeClass('deactivated');
                simpleList.removeClass('deactivated');
                $('#cdSelection  li input').each(function (index, elem) {
                    this.disabled = false;
                })
                jQuery.ajax({
                    contentType: 'application/json',
                    url: 'userExportColumns/columnsDefinitionById/' + e.object.id,
                    type: 'GET',
                    "success": function (data, textStatus, jqXHR) {
                        var list = simpleList;
                        list.empty();
                        list.data('version', data.version);
                        list.data('databaseId', data.id);
                        var columns = data.userExportColumnData;
                        for (var i = 0; i < columns.length; i++) {
                            list.append('<li><input type="checkbox"' + (columns[i].checked === true ? 'checked="checked"' : ' ') + '"><span style="cursor: s-resize">' + columns[i].column + '</span></li>');
                        }
                        var msg = MsgHelper.getMessage("label.pageContent." + action.id + ".load.success");
                        //$('#' + action.id + '-msg #valid span').text(msg);
                        //$('#' + action.id + '-msg #valid').attr('style', '')
                        iconInputCheck.get()[0].style.display = 'inline';
                        saveDisk.get()[0].style.display = 'none';
                        $('#' + action.id + '-msg #error').attr('style', 'display: none;');
                    },
                    "error": function (data) {
                        var msg = MsgHelper.getMessage("label.pageContent." + action.id + ".load.error");
                        $('#' + action.id + '-msg #error span').text(msg);
                        $('#' + action.id + '-msg #error').attr('style', '')
                        //$('#' + action.id + '-msg #valid').attr('style', 'display: none;')
                    }
                });
                var el = document.getElementById('simpleList');
                Sortable.create(el);
            }
        });
//        $('#download #savedSelection .select2-arrow').attr('style', 'display: none;');
        $('#cdFormat .form-wrap-inner input[type=radio]').unbind('click');
        $('#cdColumns .form-wrap-inner input[type=radio]').unbind('click');
        $('#cdAdditionals .form-wrap-inner input[type=radio]').unbind('click');
        $('#cdFormat .form-wrap-inner input[type=radio]').click(function (event) {
            iconInputCheck.get()[0].style.display = 'none';
            saveDisk.get()[0].style.display = 'inline';
            $('#' + action.id + '-msg #error').attr('style', 'display: none;');
            if (arrProduct.length > 0) {
                $('.' + action.id + '-confirm').removeClass('disabled');
            }

            if (event.currentTarget.attributes["value"].value === 'xls'
                || event.currentTarget.attributes["value"].value === 'csv') {
                if (event.currentTarget.attributes["value"].value === 'csv') {
                    $("#cdAdditionals").css("visibility", "visible");
                } else {
                    $("#cdAdditionals").css("visibility", "hidden");
                }
                $('#cdColumns').css("visibility", "visible");
                $('#cdSelection').css("visibility", "visible");
                $('#cdColumns .form-wrap-inner input[name=columnHeading]').parent().css("visibility", "visible");
                $('#cdPublisherData').css("visibility", "hidden");
                $('#cdPublisherData .form-wrap-inner input[name=publisherData]').attr('checked', false);
            } else {
                if (event.currentTarget.attributes["value"].value === 'ascii') {
                    $('#cdPublisherData').css("visibility", "visible");
                } else {
                    $('#cdPublisherData').css("visibility", "hidden");
                    $('#cdPublisherData .form-wrap-inner input[name=publisherData]').attr('checked', false);
                }
                $('#cdColumns .form-wrap-inner input[type=radio]').each(function () {
                    $(this).prop('checked', false);
                });
                $('#cdColumns .form-wrap-inner input[value=full]').attr('checked', true);
                $('#cdColumns .form-wrap-inner input[value=full]').trigger('click');
                $('#cdColumns').css("visibility", "hidden");
                $('#cdSelection').css("visibility", "hidden");
                $('#download form .savedSelection').css("visibility", "hidden");
                $(".js-data-example-ajax").select2('val', '');
                $('#cdColumns .form-wrap-inner input[name=columnHeading]').attr('checked', true);
                $('#cdColumns .form-wrap-inner input[name=columnHeading]').parent().css("visibility", "hidden");
                $("#cdAdditionals").css("visibility", "hidden");
            }
            if (event.currentTarget.attributes["value"].value === 'bwaOrder') {
                $('#bwaOrderHint').css("visibility", "visible");
            } else {
                $('#bwaOrderHint').css("visibility", "hidden");
            }

            var messages = [];
            var o = {};
            o.ids = [];
            for (var i = 0; i < arrProduct.length; i++) {
                o.ids.push(arrProduct[i].id);
            }
            if (options.model.attributes.id !== undefined) {
                o.queryId = options.model.attributes.id;
            }
            if (options.model.attributes.query !== undefined) {
                o.query = LinkHelper.encodeURI(options.model.attributes.query);
            }
            var sendData = JSON.stringify(o);
            var url = apiRoot + 'export/checkExportable';
            url += '/' + event.currentTarget.attributes["value"].value;
            url += "/" + options.modelKey;
            if (options.searchType !== undefined) {
                url += "/" + options.searchType;
            } else if (options.modelKey === 'productTracker' || options.modelKey === 'productTrackerEdit') {
                url += "/currentUserId";
            }
            url += '?access_token=' + user.access_token;
            $('.' + action.id + '-confirm').addClass('disabled');
            jQuery.ajax({
                contentType: 'application/json',
                data: sendData,
                url: url,
                type: 'POST',
                async: true,
                "success": function (data) {
                    for (var permanent in data['permanent']) {
                        var msg = MsgHelper.formatMessage(permanent, data['permanent'][permanent]);
                        messages.push(msg);
                    }
                    for (var dependent in data['dependent']) {
                        var msg = MsgHelper.formatMessage(dependent, data['dependent'][dependent]);
                        messages.push(msg);
                    }
                    if (messages.length > 0) {
                        var html = '<ul>';
                        for (var i = 0; i < messages.length; i++) {
                            html += '<li>' + messages[i] + '</li>';
                        }
                        html += '</ul>';
                        $('#' + action.id + '-msg #error span').html(html);
                        $('#' + action.id + '-msg #error').attr('style', '')
                    }
                    // if user has selected some title take the array of selected titles length. If the user has nothing
                    // selected the user tries to esport all titles.
                    var listLength = arrProduct.length > 0 ? arrProduct.length : options.table.context[0]._iRecordsTotal;
                    if (parseInt(data['result']['sum'][0]) < listLength) {
                        $('.' + action.id + '-confirm').removeClass('disabled');
                    }
                    // we need to update the fancybox, so it can resize itself, e.g. when error messages should be shown
                    $.fancybox.update();
                },
                "beforeSend": function () {
                    $.fancybox.showLoading();
                },
                "complete": function () {
                    $.fancybox.hideLoading();
                },
            });
        });
        $('#cdColumns .form-wrap-inner input[type=radio]').click(function (event) {
            iconInputCheck.get()[0].style.display = 'none';
            saveDisk.get()[0].style.display = 'none';
            $('#' + action.id + '-msg #error').attr('style', 'display: none;');
            if (event.currentTarget.attributes["value"].value === 'user') {
                $('#cdSelection').css("visibility", "visible");
                $('#download form .savedSelection').css("visibility", "visible");
                selectAll.prop('checked', true);
                selectionName.val('');
                $(".js-data-example-ajax").select2('val', '');
                //saveIsActive = false;
            } else {
                $('#cdSelection').css("visibility", "visible");
                $('#download form .savedSelection').css("visibility", "hidden");
            }

            $(".js-data-example-ajax").select2('val', '');
            var el;

            var url = event.currentTarget.attributes["value"].value === 'short' ? apiRoot + 'export/availableColumnsShort' : apiRoot + 'export/availableColumnsFull';
            url += '?access_token=' + user.access_token;
            jQuery.ajax({
                contentType: 'application/json',
                url: url,
                type: 'GET',
                "success": function (data) {
                    var list = simpleList;
                    list.empty();
                    if (event.currentTarget.attributes !== undefined && event.currentTarget.attributes !== null && event.currentTarget.attributes["value"].value === 'user') {
                        for (var i = 0; i < data.length; i++) {
                            list.append('<li><input type="checkbox" checked="checked"><span style="cursor: s-resize">' + data[i] + '</span></li>');
                        }
                        if (el === undefined) {
                            el = document.getElementById('simpleList');
                            Sortable.create(el);
                        } else {
                            //el.option('disabled', false);
                        }
                        //selectAll.prop('disabled', 'disabled');
                        selectAll.parent().removeClass('deactivated');
                        el.classList.remove('deactivated');
                    } else {
                        for (var i = 0; i < data.length; i++) {
                            list.append('<li><input type="checkbox" disabled="disabled" checked="checked"><span>' + data[i] + '</span></li>');
                        }
                        if (el === undefined) {
                            el = document.getElementById('simpleList');
                        }
                        //el.option('disabled', true);
                        el.classList.add('deactivated');
                    }
                }
            });


            // we need to update the fancybox, so it can resize itself, e.g. when error messages should be shown
            $.fancybox.update();
        });

        // at least load the columns for a selected full (all columens) checkBox.
        $('#cdColumns .form-wrap-inner input[value=full]').trigger('click');
        $('#cdSelection').css("visibility", "hidden");


        // we need to update the fancybox, so it can resize itself, e.g. when error messages should be shown
        $.fancybox.update();
    }
};

ConfirmationHelper.prototype.initConfirmationArchive = function (confirmation, action, options, arrNonVLBProduct, arrProduct, scopeData, selectionLength) {

    var submitBtn = $('.' + action.id + '-confirm');
    var archiveOptions = $('#' + action.id + ' input[type="radio"]');
    // check if a migration option is selected
    archiveOptions.on('click', function () {
        submitBtn.removeClass('disabled');
    });

};

ConfirmationHelper.prototype.initConfirmationMigrate = function (confirmation, action, options, arrNonVLBProduct, arrProduct, scopeData, selectionLength) {
    // get the collection for the publishers
    var publisherCollection = FormHelper.getCollection(options.formDef.attributes['collections'], 'mainpublishers');
    var selectMigratePublishers = $('.migrate-publishers');

    // add it to the select
    selectMigratePublishers.select2({
        ajax: {
            url: publisherCollection.url,
            dataType: 'json',
            delay: 500,
            data: function (term, page) {
                return {
                    search: term
                };
            },
            results: function (data, page) {
                if (self.specialBehaviour === "hierarchyMaster") {
                    data.push({
                        "id": "createNew",
                        "value": MsgHelper.getMessage("label.form.product.field.hierarchy.createNew")
                    });
                }
                if (self.specialBehaviour === "seriesMaster") {
                    data.push({
                        "id": "createNew",
                        "value": MsgHelper.getMessage("label.form.product.field.series.createNew")
                    });
                }

                for (var i = 0; i < data.length; i++) {
                    if (data[i].value === null) {
                        data[i].text = data[i].id;
                    } else {
                        data[i].text = data[i].value;
                    }
                }
                return {results: data};
            },
            cache: true,
            params: {
                "headers": {
                    'Authorization': "Bearer " + user.access_token
                }
            }
        },
        placeholder: MsgHelper.getMessage('label.form.confirmationMigrate.textSelect.migrateProduct.placeholder'),
        allowClear: false
    });

    // we need to update the fancybox, so it can resize itself, e.g. when error messages should be shown
    $.fancybox.update();

    var submitBtn = $('.' + action.id + '-confirm');
    var migrationOptions = $('#' + action.id + ' input[type="radio"]');
    var migrationPublisher = $('#' + action.id + ' input.migrate-publishers');
    var migrationPublisherSelected = false;
    var migrationOptionSelected = false;
    var hasMigratableProducts = arrProduct.length > 0 || (arrProduct.length === 0 && selectionLength === 0 && options.table.context[0]._iRecordsTotal > 0);

    // set the submit button default to disabled.
    submitBtn.addClass('disabled');

    // check if a migration option is selected
    migrationOptions.on('click', function () {
        migrationOptionSelected = true;

        if (migrationOptionSelected && migrationPublisherSelected && hasMigratableProducts) {
            // enable button if both migration option and publisher are selected and the submittable products are not null
            submitBtn.removeClass('disabled');
        }
    });

    // check if a migration publisher is selected
    migrationPublisher.on('click', function () {
        migrationPublisherSelected = true;

        // reset the abort and submit button
        $('.' + action.id + '-confirm').removeClass('hidden');
        $('.' + action.id + '-close').removeClass('disabled');
        $('.' + action.id + '-close span').text(MsgHelper.getMessage('label.form.button.cancel'));

        //reset the error messages
        $('#' + action.id + '-msg #error').attr('style', 'display: none;')

        if (migrationOptionSelected && migrationPublisherSelected && hasMigratableProducts) {
            // enable button if both migration option and publisher are selected and the submittable products are not null
            submitBtn.removeClass('disabled');
        }
    });
};


ConfirmationHelper.prototype.setConfirmationMigrateScopeData = function (scopeData, action, options, arrProduct, selectionLength) {

    var scopeData = scopeData;
    var selectedProductsCount = selectionLength;

    // if we don't have selected anything, try to get the number of products in the list
    if (selectedProductsCount === 0) {
        selectedProductsCount = MiscHelper.formatNumber(options.table.context[0]._iRecordsTotal);
    }

    scopeData.text = MsgHelper.formatMessage("label.form." + action['confirmation'] + ".text." + action.id, ["" + selectedProductsCount]);

    // number of products that cannot be migrated
    if (arrProduct.length < selectionLength) {
        scopeData.textSecondLine = MsgHelper.formatMessage("label.form." + action['confirmation'] + ".textSecondLine." + action.id, ["" + (selectionLength - arrProduct.length)]);
    }

    // publisher select
    scopeData.textSelect = MsgHelper.formatMessage("label.form." + action['confirmation'] + ".textSelect." + action.id, ["" + (selectionLength - arrProduct.length)]);
    scopeData.publisherCollection = FormHelper.getCollection(options.formDef.attributes['collections'], 'mainpublishers');

    // migration categories
    scopeData.textRadioSelect = MsgHelper.formatMessage("label.form." + action['confirmation'] + ".textRadioSelect." + action.id, ["" + (selectionLength - arrProduct.length)]);
    scopeData.collection = FormHelper.getCollection(options.formDef.attributes['collections'], 'migrationCategory');

    return scopeData;
};


module.exports = new ConfirmationHelper;