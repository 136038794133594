var View = require('./view');
var StartPageTemplate = require('./templates/start_pageContent');
var ContentPageTemplate = require('./templates/content_pageContent');

module.exports = View.extend({

    id: 'pageContent-view',
    autoRender: true,
    region: 'content',

    initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);
        var grid = this.model.get('grid');        
        grid.data = [];
        for (var i = 0; i < grid.rows; i++) {
            var d = [];
            for (var j = 0; j < grid.columns; j++) {
                d.push(null);
            }
            grid.data.push(d);
        }

        var widgets = this.model.get('widgets');
        
        for (var i = 0; i < widgets.length; i++) {
            var widget = widgets[i];
            this.registerRegion('widget-' + widget.id, '#widget-' + widget.id);
            grid.data[widget.position.row - 1][widget.position.column - 1] = widget;
            grid.data[widget.position.row - 1].id = widget.id;
        }

        for (var i = 0; i < grid.rows; i++) {
            var numberOfUsedColumns = 0;
            if(i >= grid.data.length) {
                continue;
            }
            for (var j = 0; j < grid.columns; j++) {
                if (grid.data[i][j] != null) {
                    numberOfUsedColumns++;
                }
            }
            var colClass;
            switch (numberOfUsedColumns) {
                case 0:
                    grid.data.splice(i, 1);
                    i--;
                    continue
                    break;
                case 1:
                    colClass = "col-xs-12";
                    break;
                case 2:
                    colClass = "col-xs-6";
                    break;
                case 3:
                    colClass = "col-xs-4";
                    break;
                default:
                    colClass = "col-xs-12";
            }
            for (var j = 0; j < grid.columns; j++) {
                if (grid.data[i][j] != null) {
                    if (j == 0) {
                        grid.data[i][j].newLine = true;
                        grid.data[i][j].endOfLine = true;
                    } else {
                        grid.data[i][j].newLine = false;
                        grid.data[i][j].endOfLine = true;
                        if(grid.data[i][j - 1] !== null) {
                            grid.data[i][j - 1].endOfLine = false;
                        }
                    }
                    grid.data[i][j].colClass = colClass;
                }
            }
        }
    },
    getTemplateFunction: function () {
        if (this.model.attributes.type === "start") {
            return StartPageTemplate;
        }
        return ContentPageTemplate;
    },
    getTemplateData: function () {
        return this.model.attributes;
    },

    disposeWidget: function (widget, index) {
        if (widget.view !== undefined) {
            widget.view.dispose();
        }
    },
    dispose: function () {
        if (this.disposed) {
            return;
        }
        _.each(this.model.get('widgets'), this.disposeWidget, this);
        View.prototype.dispose.apply(this);
    },

    attach: function () {
        View.prototype.attach.call(this);

        //needed so panel header can be styled
        if (this.model.attributes.type !== "start") {
            $('#accordion').on('hide.bs.collapse', function (event) {
                $("#" + event.target.id).siblings(".panel-heading").removeClass("accordion_open");
            });
            $('#accordion').on('show.bs.collapse', function (event) {
                $("#" + event.target.id).siblings(".panel-heading").addClass("accordion_open");
                setTimeout(function () { // we need to wait untill accordeon is fully opened
                        $('html, body').animate({
                            scrollTop: $("#" + event.target.id).offset().top - 250 // 250 is headersize
                        }, 100);
                    }
                    , 500);
            });
            /*
             mh seems like this does the oposite now then it should - makes it smaller instead of 100% and is 100% now
             styles must have been changed so we don't need this any more
             $('#accordion').on('shown.bs.collapse', function (event) {
             // recalculate datatable sizes, if contains some
             $("#" + event.target.id).find("table[id^='datatable_widget']").dataTable().fnAdjustColumnSizing();
             });
             */
        }
        return this;
    }
});
