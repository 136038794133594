var ModelController = require('./model_controller');
var FormDefinition = require('models/formDefinition');
var Product = require('models/product');
var ProductSearchResultView = require('views/product_search_result_view');
var MiscHelper = require('lib/misc_helper');
var AjaxSpinner = require('lib/ajaxspinner_helper');
var FormHelper = require('lib/form_helper');

var ProductController = ModelController.extend({
    initialize: function (options) {
        this.logger = log4javascript.getLogger('controllers.ProductController');
    },

    getNewModel: function (param) {
        if (param.id !== undefined) {
            var newModel = new Product({id: param.id, publisherForMvbId: true});
            return newModel;
        } else if (param.type !== undefined) {
            var newModel = new Product({id: 'empty_' + param.type, type: param.type});
            newModel.fetch();
            if (param.type === 'bundles') {
                newModel.set('type', 'nonbook', {silent: true});
            } else if (param.type === 'avbook') {
                newModel.set('type', 'abook', {silent: true});
            } else {
                newModel.set('type', param.type, {silent: true});
            }
            newModel.unset("id", {silent: true});
            newModel.set('publisherForMvbId', true);
            return newModel;
        }
        return new Product({type: 'pbook', publisherForMvbId: true});
    },
    getModelKeyForForm: function (model) {
        return 'product';

    },
    edit: function (params) {
        AjaxSpinner.hide();
        AjaxSpinner.show();
        this.model = this.getNewModel(params);
        this.model.fetch();

        if (!this.model.attributes.publisherForMvbId) {
            this.constructFormView('/noedit', params);
        } else {
            this.constructFormView('/edit', params);
            Chaplin.mediator.subscribe(this.model.modelKey + 'Saved', this.modelSaved, this);
            Chaplin.mediator.subscribe(this.model.modelKey + 'SavedAndEdit', this.modelSavedAndEdit, this);
            Chaplin.mediator.subscribe(this.model.modelKey + 'SavedAndBack', this.modelSavedAndBack, this);
            Chaplin.mediator.subscribe(this.model.modelKey + 'SavedAndMmo', this.modelSavedAndMmo, this);
            Chaplin.mediator.subscribe(this.model.modelKey + 'Cancelled', this.modelCancelled, this);
            var type = this.model.attributes['type'];
            if (type === 'duoBundle' || type === 'multiBundle') {
                this.model.attributes['iconType'] = 'bundles';
                // In bundles the prices are ordered by the sequence number. Top down. The containedItems must have the
                // same order to be able to place any changes to the correct item.
                this.model.get('containedItems').sort(function (a, b) {
                    return a.sequence - b.sequence;
                });
            } else {
                this.model.attributes['iconType'] = type;
            }
        }
        var hasISSN = false;
        if(this.model.get('identifiers')) {
            for (var i = 0; i < this.model.get('identifiers').length; i++) {
                if (this.model.get('identifiers')[i].type === 'IS') {
                    hasISSN = true;
                    break;
                }
            }
        }
        if (hasISSN === true) {
            this.model.set('issnswitch', 'with');
        } else {
            this.model.set('issnswitch', 'without');
        }
        // Was a theme code derived from the product group by VLB?
        var themeHint = false;
        // Was a theme code derived from the BISAC code by VLB?
        var themeHintBisac = false;
        var themaCategories = [];
        var classifications = this.model.get('classifications');
        if (classifications) {
            for (var i = 0; i < classifications.length; i++) {
                if (classifications[i].type == '93') {
                    themaCategories.push(classifications[i]);
                }
            }
        }
        var tmpCategorie = themaCategories.slice();
        for (var i = 0; i < tmpCategorie.length; i++) {
            var themaInfo = (['WGSneuMapping', 'WGS1Mapping'].indexOf(tmpCategorie[i].sourceName)) > -1;
            if (themaInfo === true) {
                themeHint = true;
            }
            var themaInfo = (['BISACMapping'].indexOf(tmpCategorie[i].sourceName)) > -1;
            if (themaInfo === true) {
                themeHintBisac = true;
            }
        }
        this.model.set('themeHint', themeHint);
        this.model.set('themeHintBisac', themeHintBisac);


        this.model.set('lastModifiedDate', new Date());

        FormHelper.sortClassifications(this.model);
        FormHelper.sortKeywordsBySequence(this.model);
        if(user.authorities.indexOf('CREATE_AUDIO_VIDEO') >= 0 && this.model.get('uiType') === 'abook' && user.authorities.indexOf('CREATE_AUDIO') < 0) {
            this.model.set('uiType', 'avbook');
        }
    },

    editAssets: function (params) {
        this.model = this.getNewModel(params);
        this.model.fetch();

        if (!this.model.attributes.publisherForMvbId) {
            this.constructFormView('/noedit', params);
        } else {
            this.constructFormView('/editAssets', params);
            Chaplin.mediator.subscribe(this.model.modelKey + 'Saved', this.modelSaved, this);
            Chaplin.mediator.subscribe(this.model.modelKey + 'SavedAndEdit', this.modelSavedAndEdit, this);
            Chaplin.mediator.subscribe(this.model.modelKey + 'SavedAndBack', this.modelSavedAndBack, this);
            Chaplin.mediator.subscribe(this.model.modelKey + 'Cancelled', this.modelCancelled, this);
        }
    },

    editAssets_copy: function (params) {
        this.model = this.getNewModel(params);
        this.model.fetch();

        if (!this.model.attributes.publisherForMvbId) {
            this.constructFormView('/noedit', params);
        } else {
            this.constructFormView('/editAssets', params);
            Chaplin.mediator.subscribe(this.model.modelKey + 'Saved', this.modelSaved, this);
            Chaplin.mediator.subscribe(this.model.modelKey + 'SavedAndEdit', this.modelSavedAndEdit, this);
            Chaplin.mediator.subscribe(this.model.modelKey + 'SavedAndBack', this.modelSavedAndBack, this);
            Chaplin.mediator.subscribe(this.model.modelKey + 'Cancelled', this.modelCancelled, this);
        }
    },

    removeEmptyPrices: function () {
        if (this.model.get('uiType') === 'journal') {
            var zisInfo = this.model.get('zisInfo');
            if (zisInfo != null && zisInfo.medium === 'WW') {
                var prices = this.model.get('pricesJournalBundle');
                var tmpPrices = [];
                if (!MiscHelper.isEmpty(prices)) {
                    for (var i = 0; i < prices.length; i++) {
                        var pricePart1 = prices[i].priceParts[0];
                        var pricePart2 = prices[i].priceParts[1];
                        if (pricePart1.netPriceAmount != null || pricePart1.taxAmount !== null
                            || pricePart2.netPriceAmount != null || pricePart2.taxAmount !== null) {
                            tmpPrices.push(prices[i]);
                        }
                    }
                }
                this.model.set('pricesJournalBundle', tmpPrices);
            }
        } else {
            var retailPrices = this.model.get('retailPrices');
            var tmpRetailPrices = [];
            if (retailPrices) {
                for (var i = 0; i < retailPrices.length; i++) {
                    var price = retailPrices[i];
                    if (price.value !== null) {
                        tmpRetailPrices.push(price);
                    }
                }
            }
            this.model.set('retailPrices', tmpRetailPrices);
        }
    },

    show: function (params) {
        ModelController.prototype.show.call(this, params);
        FormHelper.sortClassifications(this.model);
        FormHelper.sortKeywordsBySequence(this.model);
        if (!this.model.attributes.publisherForMvbId && user.authorities.indexOf('BOOKSTORE') < 0) {
            this.constructFormView('/noShow', params);
        } else {
            this.removeEmptyPrices();
            $.fancybox.close();
        }
    },

    show_rec: function (params) {
        ModelController.prototype.show_rec.call(this, params);
        FormHelper.sortClassifications(this.model);
        FormHelper.sortKeywordsBySequence(this.model);
        if (!this.model.attributes.publisherForMvbId && user.authorities.indexOf('BOOKSTORE') < 0) {
            this.constructFormView('/noShow', params);
        } else {
            this.removeEmptyPrices();
        }
    },

    create: function (params) {
        ModelController.prototype.create.call(this, params);
        if (params.type === 'bundles') {
            this.model.set('form.type', 'WW');
        } else if (params.type === 'journal') {
            this.model.set('form.type', 'JO');
        }
        if (versionCountry === 'brasil' || versionCountry === 'latam') {
            var classification = {
                code: '9110',
                id: MiscHelper.uuid(),
                main: true,
                schmeName: null,
                sourceName: null,
                systematicVersion: null,
                type: '26',
                value: 'Nonbooks, PBS / Belletristik/Erzählende Literatur'
            };
            this.model.get('classifications').push(classification);
        }
        Chaplin.mediator.subscribe(this.model.modelKey + 'Cancelled', this.modelList, this);
    },

    copy: function (params) {
        ModelController.prototype.copy.call(this, params);
        Chaplin.mediator.subscribe(this.model.modelKey + 'Cancelled', this.modelList, this);
    },

    resetIds: function (obj) {
        for (var k in obj) {
            if (k == 'id') {
                obj[k] = MiscHelper.uuid();
            } else if (!MiscHelper.isEmpty(obj[k]) && $.isArray(obj[k])) {
                for (var i = 0; i < obj[k].length; i++) {
                    var sObj = obj[k][i];
                    if ($.isPlainObject(sObj)) {
                        this.resetIds(sObj);
                    }
                }
            } else if (!MiscHelper.isEmpty(obj[k]) && $.isPlainObject(obj[k])) {
                this.resetIds(obj[k]);
            }
        }
    },

    clearCopy: function (params) {
        if (params.relateEbook === 1) {
            if (MiscHelper.isEmpty(this.model.get('relatedProducts'))) {
                this.model.set('relatedProducts', []);
            }
            var ids = this.model.get('identifiers');
            var isbn = null;
            for (var i = 0; i < ids.length; i++) {
                if (ids[i].type === '15') {
                    isbn = ids[i].value;
                    break;
                }
            }

            this.model.get('relatedProducts').push({
                "type": "13",
                "productIdType": "15",
                "productIdValue": isbn,
                "productForm": this.model.get("form.type")
            });

            this.model.set("form.type", "DG");
            this.model.set('commercialClassifications', []);

            var classifications = this.model.get('classifications');
            for (var i = 0; i < classifications.length; i++) {
                if (classifications[i].type === '26') {
                    classifications[i].code = "9" + classifications[i].code.substring(1);
                    break;
                }

                if (classifications[i].type === "ZS") {
                    console.log(classification[i].code.replace(/^(0+)/g, ''));
                    // remove leading 0 for ZIS Code classifications
                    classification[i].code = classification[i].code.replace(/^(0+)/g, '');
                }
            }
            this.model.set('type', 'ebook');
            this.model.set('uiType', 'ebook');
            this.model.set('form.description', null);
            // ref #14183 - binding depends on description. So also reset attribute bindimg.
            this.model.set('form.binding', null);
        }

        // Ticket 16298 - remove information about the previous assets. These information must be set from the backend
        // as soon as the product is been saved. But because the interURl at least later do iedentify valid assets these
        // attributes are set to null when they are selected to bee adopt to the new product.

        // Ticket 12615 - remove DDC groups and DNB keywords because they can not been changed.
        var newClassifications = [];
        var classifications = this.model.get('classifications');
        for (var i = 0; i < classifications.length; i++) {
            if (['27', '91', '18', '30'].indexOf(classifications[i].type) < 0) {
                newClassifications.push(classifications[i]);
            }
        }

        // #27886: no productformdetails should be duplicated
        this.model.set("form.detail", null);
        this.model.set("form.detailO3", null);
        this.model.set('classifications', newClassifications);


        this.model.set('copyOfThisProduct', this.model.get('id'));
        this.model.set('identifiers', []);
        this.resetIds(this.model.attributes);
        this.model.attributes.id = undefined;

        this.model.set('aggregatorId', null);
        this.model.set('archivedDate', null);
        this.model.set('archivedReason', null);
        this.model.set('bitNo', null);
        this.model.set('bvdId', null);
        this.model.set('createdBy', user.userId);
        this.model.set('createdDate', null);
        this.model.set('deletionDate', null);
        this.model.set('deletionReason', null);
        this.model.set('lastModifiedBy', null);
        this.model.set('lastModifiedDate', null);
        this.model.set('lastPriceDate', null);
        this.model.set('lastPriceSource', null);
        this.model.set('lastUpdateSource', null);
        this.model.set('newbooksId', null);
        this.model.set('nextPriceDate', null);
        this.model.set('priceRepealDate', null);
        this.model.set('providerId', null);
        this.model.set('ranking', null);
        this.model.set('reactivationDate', null);
        this.model.set('reactivationReason', null);
        this.model.set('recordSource', 'titleservice');
        this.model.set('returnInfo', null);
        this.model.set('priceDataDachChangeDate', null);
        this.model.set('priceDataInternationalChangeDate', null);
    },

    list: function (params, options) {
        AjaxSpinner.hide();
        AjaxSpinner.show();
        this.model = this.getNewModel(params);
        this.formDef = new FormDefinition({id: this.getModelKeyForForm(this.model) + '/list'});
        this.view = new ProductSearchResultView({
            model: this.model,
            formDef: this.formDef,
            modelKey: 'product',
            searchType: "all"
        });
        this.formDef.fetch();
    }
});

module.exports = ProductController;