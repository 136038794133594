var OnixCodelist = require('models/codelist');
var MiscHelper = require('lib/misc_helper');
var MsgHelper = require('lib/msg_helper');
var FormHelper = require('lib/form_helper');
var DateHelper = require('lib/date_helper');
var IconrenderHelper = require('lib/iconrender_helper');
var LinkHelper = require('lib/link_helper');

var Logger = log4javascript.getLogger('lib.RendererHelper');
var that;

var RendererHelper = function () {
    this.renderers = [];
    that = this;
};

// Check if we can handle this renderer based on model property.
RendererHelper.prototype.hasRenderer = function (propertyName) {
    var result = false;
    $.each(this.renderers, function () {
        if (this.propertyName == propertyName) {
            result = true;
            return false;
        }
    });
    return result;
};

// Get the renderer
RendererHelper.prototype.getRenderer = function (propertyName) {
    var renderer = null;
    $.each(this.renderers, function () {
        if (this.propertyName == propertyName) {
            renderer = this;
            return false;
        }
    });
    return renderer;
};

// Run the renderer
RendererHelper.prototype.doRender = function (propertyName, row, value, additionalData) {
    var renderer = this.getRenderer(propertyName);
    if (renderer != null) {
        return renderer.fn(row, value, additionalData);
    }
    return value;
};

// Register a new renderer.
RendererHelper.prototype.addRenderer = function (prop, fn) {
    this.renderers.push({
        propertyName: prop,
        fn: fn
    });
};

/**
 * This function checks if we have a styleClass (style) and if so it wraps a span around the value.
 * This is very useful for styling purposes.
 * @param style
 * @param value
 * @returns either the value with styleClass or the value itself
 */
RendererHelper.prototype.addStyleClass = function (style, value) {
    style = style !== "" && value === "" ? style + " is-empty" : style;
    return style ? '<span class="' + style + '">' + value + '</span>' : value;
}

var renderHelper = new RendererHelper;

renderHelper.addRenderer('reportStatusIcon', function (row, value, additionalData) {
    return IconrenderHelper.reportStatusIcon(row, additionalData.widget, additionalData.modelKey);
});

renderHelper.addRenderer('max3Authors', function (row, value, additionalData) {
    var widget = additionalData.widget;
    var retVal = row['max3Authors'] ? row['max3Authors'] : '';

    retVal = renderHelper.addStyleClass(widget.styleClass, retVal);

    return retVal;
});

/*
 renderHelper.addRenderer('publisher', function (row, value, additionalData) {
 var widget = additionalData.widget;
 var retVal = row['publisher'] ? row['publisher'] : '';

 if (row['productType'] === 'journal') {
 }

 retVal = renderHelper.addStyleClass(widget.styleClass, retVal);

 return retVal;
 });
 */

renderHelper.addRenderer('queryOrBatchIds', function (row, value, additionalData) {

    if (row['mode'] === 'batch') {
        var items = [];
        if (row['batchIds'].length <= 10) {
            items = row['batchIds'];
        } else {
            for (var i = 0; i <= 10; i++) {
                items.push(row['batchIds'][i]);
            }
        }
        var data = [row['nrOfResults'], row['batchIds']];
        var msg = MsgHelper.formatMessage('title.searchhistory.numberOfRecordsBatch', data);
        if (row['batchIds'].length <= 10) {
            msg += ', ...';
        }
        var retVal = '<span title="' + msg + '">ID=';
        var numberOfIDs = 3;
        if (row['batchIds'].length < 3) {
            numberOfIDs = row['batchIds'].length;
        }
        for (var i = 0; i < numberOfIDs; i++) {
            retVal += row['batchIds'][i];
            if (i < numberOfIDs - 1) {
                retVal += ", ";
            }
        }
        if (row['batchIds'].length > 3) {
            retVal += ', ...';
        }
        retVal += '</span>';
        return retVal;
    } else {
        var data = [row['nrOfResults'], row['query']];
        var msg = MsgHelper.formatMessage('title.searchhistory.numberOfRecordsQuery', data);
        var retVal = '<span title="' + msg + '">';
        retVal += that.doRender('listWidget', row, row['query'], additionalData);
        retVal += '</span>'
        return retVal;
    }

});

renderHelper.addRenderer('publicationDate', function (row, value, additionalData) {

    var widgetHtml = "";
    var widget = additionalData.widget;
    var realValue = row[widget['property']];

    if (row.productType === 'journal') {
        if (row.perspective === 'listPerspective' || row.perspective2 === 'listPerspective') {
            // get the code list for the zis frequency
            var codeList = new OnixCodelist({
                id: 400
            });
            codeList.fetch();

            $.each(codeList.get('codes'), function () {
                if (this.code === row.frequency) {
                    // Found unpriced item code description
                    widgetHtml = this.description;
                    return widgetHtml; // break foreach
                }
            });
        }
    } else if (realValue !== undefined && realValue !== null && realValue.length > 0) {
        if (additionalData.multiple && !additionalData.isFirst) {
            if (additionalData.columnDef.separator !== undefined) {
                widgetHtml += additionalData.columnDef.separator;
            } else {
                widgetHtml += "<br/>";
            }
        }
        widgetHtml += DateHelper.convert(realValue);
    }

    widgetHtml = renderHelper.addStyleClass(widget.styleClass, widgetHtml);

    return widgetHtml;
});


renderHelper.addRenderer('identifier', function (row, value, additionalData) {
    var widgetHtml = "";
    var value = "";
    var widget = additionalData.widget;
    var realValue = row[widget['property']];

    if (!row.identifierIsFake) {
        value = realValue;
    }

    if (value !== "") {
        // copied from lines ~ 430 to set the link around the right identifier
        if (widget.type === 'ModelDetailsLinkWidget') {
            // in case somebody wants to use a different model, the id may contain <model>#<id>
            if (row.id.indexOf('#') > -1) {
                widgetHtml += "<a href='"
                    + Chaplin.utils
                        .reverse(row.id.substring(0, row.id.indexOf('#')) + "_show", [row.id.substring(row.id.indexOf('#') + 1)]) + "'>"
                    + value + "</a>";
            } else {
                var targetModel = additionalData.modelKey;
                if (targetModel === 'productTrackerEdit' || targetModel === 'productTracker') {
                    targetModel = 'product';
                }
                if (targetModel === 'product' && (row.productType === 'set' || row.productType === 'series')) {
                    targetModel = 'collection';
                }
                widgetHtml += "<a href='";
                if (additionalData.recordPosition !== undefined
                    && (targetModel === 'product' || targetModel === 'collection')) {
                    widgetHtml += Chaplin.utils
                        .reverse(targetModel + "_show_rec", [row.id, additionalData.recordPosition]) + "'>"
                } else {
                    widgetHtml += Chaplin.utils
                        .reverse(targetModel + "_show", [row.id]) + "'>"
                }
                widgetHtml += value + "</a>";
            }
        } else {
            widgetHtml += value;
        }
    }

    widgetHtml = renderHelper.addStyleClass(widget.styleClass, widgetHtml);

    return widgetHtml;
});

renderHelper.addRenderer('editionNumber', function (row, value, additionalData) {
    var widgetHtml = "";
    var widget = additionalData.widget;
    var realValue = row[widget['property']];
    if (realValue !== undefined && realValue !== null && realValue.length > 0) {
        if (additionalData.multiple && !additionalData.isFirst) {
            if (additionalData.columnDef.separator !== undefined) {
                widgetHtml += additionalData.columnDef.separator;
            } else {
                widgetHtml += "<br/>";
            }
        }
        var msg = MsgHelper.formatMessage('label.form.product.list.editionNumber', [realValue]);
        widgetHtml += msg;
    }

    widgetHtml = renderHelper.addStyleClass(widget.styleClass, widgetHtml);

    return widgetHtml;
});

renderHelper.addRenderer('invoiceStatus', function (row, value, additionalData) {
    return IconrenderHelper.invoiceStatus(row, additionalData.widget, additionalData.modelKey);
});

renderHelper.addRenderer('feedAvailability', function (row, value, additionalData) {
    return IconrenderHelper.feedAvailability(row, additionalData.widget, additionalData.modelKey);
});

renderHelper.addRenderer('basicProduct', function (row, value, additionalData) {
    return IconrenderHelper.basicProduct(row, additionalData.widget, additionalData.modelKey);
});

renderHelper.addRenderer('productIcon', function (row, value, additionalData) {
    return IconrenderHelper.productIcon(row, additionalData.widget, additionalData.modelKey);
});

renderHelper.addRenderer('nrOfResults', function (row, value, additionalValue) {
    return MiscHelper.formatNumber(value);
});

renderHelper.addRenderer('priceReferenceDe', function (row, value, additionalData) {
    var widget = additionalData.widget;
    var retVal = '';

    if (row.referencePriceDE) {
        // We have a reference price
        var msg = MsgHelper.getMessage('label.form.product.list.referenceprice');
        retVal = '<i title=\"' + msg + '\" class=\"vlb-icon vlb-icon-reference-price\"><span>Referenz Preis</span></i>';
    } else if (!MiscHelper.isEmpty(row.unpricedItemCode)) {
        // we have an
        retVal = '';
    } else if (MiscHelper.isEmpty(row.priceEurD)) {
        retVal = '';
    } else if (row.priceFixedDe === false) {
        var msg = MsgHelper.getMessage('label.form.product.list.uvp');
        retVal = msg;
    } else {
        retVal = '';
    }

    retVal = renderHelper.addStyleClass(widget.styleClass, retVal);

    return retVal;
});

// Start price column renderer
renderHelper.addRenderer('priceEurD', function (row, value, additionalData) {
    var widget = additionalData.widget;
    var retVal = '';

    if (MiscHelper.isEmpty(row.priceEurD)) {
        // No price has been
        var display;
        if (!MiscHelper.isEmpty(row.unpricedItemCode)) {
            // defined, get it from unpricedItemCode
            var codeList = new OnixCodelist({
                id: 406 // this can just be used in listforms
                // Undefined price codelist
            });
            codeList.fetch();
            $.each(codeList.get('codes'), function () {
                if (this.code == row.unpricedItemCode) {
                    // Found unpriced item code description
                    display = this.description;
                    return false; // break foreach
                }
            });
        } else if (row.productType !== 'series' && row.productType !== 'set') {
            display = MsgHelper.getMessage('label.form.product.list.priceOnRequest');
        } else {
            display = '';
        }
        retVal = display;
    } else if (row.priceEurD === '0.00' && !MiscHelper.isEmpty(row.priceCHF)) {
        retVal = MsgHelper.getMessage('label.form.product.list.priceOnRequest');
    } else {
        if (decimalSeparator == '.') {
            retVal = row[additionalData.widget['property']].toFixed(2);
        } else {
            retVal = row[additionalData.widget['property']].toFixed(2).replace(/\./, decimalSeparator);
        }
    }
    if (row.priceProvisionalDe === true) {
        retVal = "ca. " + retVal;
    }
    if (row.country && row.currency) {
        var currency = MsgHelper.getMessage('label.form.collection.currencyCountry.' + row.currency + '_' + row.country);
        if(currency) {
            retVal = currency + ' ' + retVal;
        }
    }

    retVal = renderHelper.addStyleClass(widget.styleClass, retVal);

    return retVal;
});
// End price column renderer

renderHelper.addRenderer('subTitle', function (row, value, additionalData) {

    var retVal = '';
    var widget = additionalData.widget;
    var newValue = row[widget['property']];
    var emptyClass = "";

    if (newValue !== undefined && newValue !== null && newValue.length > 0 && value !== null) {
        if (MiscHelper.regExTest('[\.,?!]', value.slice(-1))) {
            retVal = ' ';
        } else {
            retVal = '<br/> ';
        }

        if (row.perspective === 'detailedPerspective' || row.perspective2 === 'detailedPerspective') {
            retVal += newValue;
        } else {
            retVal += renderHelper.getRenderer('listWidget').fn(row, newValue, additionalData);
        }
    } else {
        emptyClass = " is-empty";
    }

    retVal = '<span class="subTitle' + emptyClass + '">' + retVal + '</span>';

    var addedLineBreak = false;
    if (!MiscHelper.isEmpty(row['sequence'])) {
        if (row.perspective === 'detailedPerspective' || row.perspective2 === 'detailedPerspective') {
            addedLineBreak = true;
            retVal += '<span class="volume">Band ' + row['sequence'] + '</span>';
        } else {
            addedLineBreak = true;
            retVal += '<br>Band ' + row['sequence'];
        }
    }
    if (!MiscHelper.isEmpty(row['levelSequence'])) {
        if (!addedLineBreak) {
            retVal += '<br>';
        } else {
            retVal += ', ';
        }
        if (row.perspective === 'detailedPerspective' || row.perspective2 === 'detailedPerspective') {
            retVal += '<span class="position">Position: ' + row['levelSequence'] + '</span>';
        } else {
            retVal += 'Position: ' + row['levelSequence'];
        }
    }

    if (row.perspective === 'detailedPerspective' || row.perspective2 === 'detailedPerspective') {
        retVal = renderHelper.addStyleClass(widget.styleClass, retVal);
    }

    return retVal;
});

// Start price column renderer
renderHelper.addRenderer('listWidget', function (row, value, additionalData) {
    var widget = additionalData.widget;
    var widgetType = widget.type;
    var realValue = value; // row[widget['property']];
    var widgetHtml = "";
    var widgetProperty = widget.property;

//  console.log("2. addRenderer->multiple->" + additionalData.multiple + ' - addRenderer->isFirst->' + additionalData.isFirst);
    if (additionalData.multiple) {
        if (additionalData.isFirst) {
//        realValue = row[widget['property']];
        } else {
            realValue = row[widget['property']];
            if (realValue !== null) {
                if (additionalData.columnDef.separator !== undefined) {
                    widgetHtml += additionalData.columnDef.separator;
                } else {
                    widgetHtml += "<br/>";
                }
            }
        }
    }
    if (widgetType === 'LabelWidget') {
        //console.log("3. addRenderer->realValue->" + realValue + " - widgetType->" + widgetType);
        //console.log("widget['property']->" + widget['property']);
        var messageKey = 'label.' + widget['msgKey'];
        if (widget['property']) {
            messageKey += '.' + row[widget['property']];
        }
        widgetHtml += MsgHelper.getMessage(messageKey);
    } else if (widgetType === 'ImageWidget') {
        // TODO make this configurable...
        if (widgetProperty === "productCover") {
            // coverImage for list
            var ean = row.isbn;

            if (ean === undefined || ean === null) {
                ean = row.gtin;
            }

            var src,
                alt = row.title.replace(/"/g, '&quot;').replace(/'/g, '&quot;'),
                onerror = 'this.onerror=null;this.src="' + mediaRoot;
            if (MiscHelper.isEmpty(ean)) {
                if (versionCountry === 'brasil' || versionCountry === 'latam') {
                    src = mediaRoot + '/images/dummycover/cover_mb_nocover.png'
                } else {
                    src = mediaRoot + '/images/dummycover/cover_vlb_nocover.png'
                }
            } else {
                src = apiRoot + "cover/" + ean + '/s';
            }
            if (versionCountry === 'brasil' || versionCountry === 'latam') {
                onerror += '/images/dummycover/cover_mb_nocover.png";'
            } else {
                onerror += '/images/dummycover/cover_vlb_nocover.png";'
            }

            if (src != undefined && src.indexOf(apiRoot) != -1) {
                src += '?access_token=' + user.access_token;
            }

            widgetHtml = "<div class='cover-wrapper'><img src='" + src + "' title='" + alt + "' alt='" + alt + "'  onerror='" + onerror + "' /></div>";
        }
    } else {
        if (realValue !== null && realValue !== undefined) {

            if (widgetType === 'ModelDetailsLinkWidget') {
                // in case somebody wants to use a different model, the id may contain <model>#<id>
                if (row.id.indexOf('#') > -1) {
                    widgetHtml += "<a href='"
                        + Chaplin.utils
                            .reverse(row.id.substring(0, row.id.indexOf('#')) + "_show", [row.id.substring(row.id.indexOf('#') + 1)]) + "'>"
                        + realValue + "</a>";
                } else {
                    var targetModel = additionalData.modelKey;
                    if (targetModel === 'productTrackerEdit' || targetModel === 'productTracker') {
                        targetModel = 'product';
                    }
                    if (targetModel === 'product' && (row.productType === 'set' || row.productType === 'series')) {
                        targetModel = 'collection';
                    }
                    widgetHtml += "<a href='";
                    if (additionalData.recordPosition !== undefined
                        && (targetModel === 'product' || targetModel === 'collection')) {
                        widgetHtml += Chaplin.utils
                            .reverse(targetModel + "_show_rec", [row.id, additionalData.recordPosition]) + "'>"
                    } else {
                        widgetHtml += Chaplin.utils
                            .reverse(targetModel + "_show", [row.id]) + "'>"
                    }
                    widgetHtml += realValue + "</a>";
                }
            } else if (widgetType === 'IconWidget') {
                // TODO make this configurable...
                widgetHtml += "<img src='" + mediaRoot + "/images/icons/"
                    + additionalData.modelKey + "/" + widget['property'] + "/"
                    + realValue + ".png' alt='" + realValue + "' title='"
                    + MsgHelper.getMessage('label.image.' + realValue) + "'>";
            } else if (widgetType === 'LinkWidget') {
                widgetHtml += LinkHelper.getHtmlLink(undefined, widget, realValue, widget.url + realValue);
            } else if (widgetType === 'CheckboxWidget') {
                widgetHtml = "<input type='checkbox' disabled='disabled'"
                    + (realValue ? "checked" : "") + ">";
            } else if (widgetType === 'GlyphWidget') {
                widgetHtml = "<i class='" + realValue + "'></i>";
            } else if (widgetType === 'SelectionWidget') {
                widgetHtml += FormHelper.getTextForSelect(additionalData.collections[widget.collection],
                    widget.multiple, widget.showId, realValue, widget.nullable);
            } else if (widgetType === 'DateWidget') {
                widgetHtml += renderHelper.renderDateWidget(widget, realValue);
            } else {
                widgetHtml += realValue;
            }

            if (additionalData.multiple && additionalData.isFirst && additionalData.columnDef.separator === undefined) {
                widgetHtml = "<b>" + widgetHtml + "</b>";
            }
        }
    }

    widgetHtml = renderHelper.addStyleClass(widget.styleClass, widgetHtml);

    return widgetHtml;
});

// End price column renderer

renderHelper.renderDateWidget = function (widget, value) {

    var renderString = DateHelper.convert(value, undefined, widget.dateFormat);

    return renderString;
};

renderHelper.addRenderer('type', function (row, value, additionalData) {
    var retVal = value;

    if (additionalData.modelKey == 'assets') {        
        retVal = MsgHelper.getMessage('label.assetFileType.' + value);
    }

    return retVal;
});

renderHelper.addRenderer('lastDownloadDate', function (row, value, additionalData) {
    if (row.status === 'finished' || row.status === null) {
        return renderHelper.getRenderer('listWidget').fn(row, value, additionalData);
    } else {
        return MsgHelper.getMessage('msg.form.userDownload.status.' + row.status);
    }
});

renderHelper.addRenderer('status', function (row, value, additionalData) {
    // we have to make sure that the status icons for the logentries (OK, WARN, ERROR) are rendered as an icon, not as text
    if (row.status === null || row.status === undefined || row.status === "OK" || row.status === "WARN" || row.status === "ERROR" || row.status === "IN_PROGRESS") {
        return renderHelper.getRenderer('listWidget').fn(row, value, additionalData);
    } else {
        return MsgHelper.getMessage('msg.form.confirmationDownload.status.' + row.status);
    }
});

module.exports = renderHelper;