var View = require('./view')
var Template;
var MsgHelper = require('lib/msg_helper');
var FormHelper = require('lib/form_helper');


module.exports = View.extend({

    LOGGER : log4javascript.getLogger('views.StandardViewWidget'),

    autoRender: true,
    autoAttach: false,

initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);
        Template = require('./templates/forms/'+options.widget.viewName);
    },

    getTemplateData: function () {
        var data = View.prototype.getTemplateData.call(this);
        return data;
    },

    getTemplateFunction: function () {
        return Template;
    },
    render: function () {
        View.prototype.render.call(this);
    },
    attach: function() {
        View.prototype.attach.call(this);
    }
});