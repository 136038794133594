var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <br>"
    + escapeExpression(((helpers.safeString || (depth0 && depth0.safeString) || helperMissing).call(depth0, (depth0 != null ? depth0.textSecondLine : depth0), {"name":"safeString","hash":{},"data":data})))
    + "\r\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = "        <p>\r\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.warnings : depth0), {"name":"each","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </p>\r\n";
},"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                "
    + escapeExpression(((helpers.safeString || (depth0 && depth0.safeString) || helperMissing).call(depth0, (depth0 != null ? depth0.text : depth0), {"name":"safeString","hash":{},"data":data})))
    + "<br>\r\n";
},"6":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <p>\r\n            <a href=\""
    + escapeExpression(((helper = (helper = helpers.href || (depth0 != null ? depth0.href : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"href","hash":{},"data":data}) : helper)))
    + "\" class=\"\" target=\"_blank\"><span>"
    + escapeExpression(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"text","hash":{},"data":data}) : helper)))
    + "</span></a>\r\n        </p>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div id=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "\" class=\"lb-default\">\r\n    <div class=\"add-icons\">\r\n        <a href=\"javascript:;\" onclick=\"return false;\" class=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-close myfancybox-close\" title=\"Close\">CLOSE</a>\r\n    </div>\r\n\r\n    <h2><span>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.confirmationInfo.headline", (depth0 != null ? depth0.ident : depth0), {"name":"label2","hash":{},"data":data})))
    + "</span></h2>\r\n\r\n    <p>\r\n        "
    + escapeExpression(((helpers.safeString || (depth0 && depth0.safeString) || helperMissing).call(depth0, (depth0 != null ? depth0.text : depth0), {"name":"safeString","hash":{},"data":data})))
    + "\r\n\r\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.textSecondLine : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </p>\r\n\r\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.warnings : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\r\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.links : depth0), {"name":"each","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\r\n    <div id=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-msg\">\r\n        <div id=\"error\" style=\"display: none\">\r\n            <div class=\"vlb-icon vlb-icon-input-error\"></div>\r\n            <span style=\"color: #e24844;\" class=\"help-inline nod_msg\"></span>\r\n        </div>\r\n        <div id=\"valid\" style=\"display: none\">\r\n            <div class=\"vlb-icon vlb-icon-input-check\"></div>\r\n            <span style=\"color: #14aa96;\" class=\"help-inline nod_msg\"></span>\r\n        </div>\r\n    </div>\r\n\r\n    <form class=\"form-horizontal\" >\r\n        <div class=\"form-actions align-right\" style=\"float: right\">\r\n            <a href=\"javascript:;\" onclick=\"return false;\" class=\"btn green btn-arrow "
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-close\"><span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "close", {"name":"lbl","hash":{},"data":data})))
    + "</span></a>\r\n        </div>\r\n    </form>\r\n</div>";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}