var Model = require('./nested_model');

// join Model, Backbone.NestedModel and our stuff together
var SearchQuery = Model.extend({
    urlRoot: applicationRoot + "/searchquery",
    modelKey: "searchquery",
    
    fetch: function (options) {

    	var theOptions = options ? options : {};
        theOptions['async'] = false;
        theOptions['cache'] = false;

        return Model.prototype.fetch.call(this, theOptions);
    }

});

module.exports = SearchQuery;