var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "";
  stack1 = helpers.each.call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.itemRow : stack1), {"name":"each","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "        <div class=\"item ";
  stack1 = helpers['if'].call(depth0, (data && data.first), {"name":"if","hash":{},"fn":this.program(3, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n            <div class=\"row\">\n";
  stack1 = helpers.each.call(depth0, depth0, {"name":"each","hash":{},"fn":this.program(5, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </div>\n        </div>\n";
},"3":function(depth0,helpers,partials,data) {
  return "active";
  },"5":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "                    <div class=\"col-md-3\">\n                        <div >\n";
  stack1 = helpers.each.call(depth0, ((stack1 = (depths[2] != null ? depths[2].formDef : depths[2])) != null ? stack1.column : stack1), {"name":"each","hash":{},"fn":this.program(6, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                          \n                        </div>\n                    </div>\n";
},"6":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.equals || (depth0 && depth0.equals) || helperMissing).call(depth0, (data && data.index), 1, {"name":"equals","hash":{},"fn":this.program(7, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "                                <p ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.styleClass : depth0), {"name":"if","hash":{},"fn":this.program(9, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n                                \n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.widget : depth0), {"name":"each","hash":{},"fn":this.program(11, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "                                \n                                </p>\n";
  stack1 = helpers['if'].call(depth0, (data && data.last), {"name":"if","hash":{},"fn":this.program(18, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"7":function(depth0,helpers,partials,data) {
  return "                                    <div class=\"caption\">\n";
  },"9":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "class=\""
    + escapeExpression(((helper = (helper = helpers.styleClass || (depth0 != null ? depth0.styleClass : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"styleClass","hash":{},"data":data}) : helper)))
    + "\"";
},"11":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.equals || (depth0 && depth0.equals) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "ImageWidget", {"name":"equals","hash":{},"fn":this.program(12, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.equals || (depth0 && depth0.equals) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "IconWidget", {"name":"equals","hash":{},"fn":this.program(14, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.equals || (depth0 && depth0.equals) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "TextWidget", {"name":"equals","hash":{},"fn":this.program(16, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"12":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                                        <a href=\""
    + escapeExpression(((helpers.actionurl || (depth0 && depth0.actionurl) || helperMissing).call(depth0, (depths[5] != null ? depths[5].defaultAction : depths[5]), ((stack1 = (depths[5] != null ? depths[5].model : depths[5])) != null ? stack1.modelKey : stack1), (depths[3] != null ? depths[3].id : depths[3]), (depths[3] != null ? depths[3].index : depths[3]), {"name":"actionurl","hash":{},"data":data})))
    + "\" class=\"thumbnail\">"
    + escapeExpression(((helpers.image || (depth0 && depth0.image) || helperMissing).call(depth0, (depths[3] != null ? depths[3].attributes : depths[3]), (depth0 != null ? depth0.property : depth0), {"name":"image","hash":{},"data":data})))
    + "</a>\n";
},"14":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                                        <a href=\""
    + escapeExpression(((helpers.actionurl || (depth0 && depth0.actionurl) || helperMissing).call(depth0, (depths[5] != null ? depths[5].defaultAction : depths[5]), ((stack1 = (depths[5] != null ? depths[5].model : depths[5])) != null ? stack1.modelKey : stack1), (depths[3] != null ? depths[3].id : depths[3]), (depths[3] != null ? depths[3].index : depths[3]), {"name":"actionurl","hash":{},"data":data})))
    + "\" class=\"thumbnail\">"
    + escapeExpression(((helpers.icon || (depth0 && depth0.icon) || helperMissing).call(depth0, (depths[3] != null ? depths[3].attributes : depths[3]), depth0, ((stack1 = (depths[5] != null ? depths[5].model : depths[5])) != null ? stack1.modelKey : stack1), {"name":"icon","hash":{},"data":data})))
    + "</a>\n";
},"16":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                                        <a href=\""
    + escapeExpression(((helpers.actionurl || (depth0 && depth0.actionurl) || helperMissing).call(depth0, (depths[5] != null ? depths[5].defaultAction : depths[5]), ((stack1 = (depths[5] != null ? depths[5].model : depths[5])) != null ? stack1.modelKey : stack1), (depths[3] != null ? depths[3].id : depths[3]), (depths[3] != null ? depths[3].index : depths[3]), {"name":"actionurl","hash":{},"data":data})))
    + "\">"
    + escapeExpression(((helpers.get || (depth0 && depth0.get) || helperMissing).call(depth0, (depths[3] != null ? depths[3].attributes : depths[3]), (depth0 != null ? depth0.property : depth0), {"name":"get","hash":{},"data":data})))
    + "</a>\n";
},"18":function(depth0,helpers,partials,data) {
  return "                                    </div>        \n";
  },"20":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        "
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, (depth0 != null ? depth0.emptyText : depth0), {"name":"lbl","hash":{},"data":data})))
    + "\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"carousel-inner\">\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.itemRow : stack1), {"name":"if","hash":{},"fn":this.program(1, data, depths),"inverse":this.program(20, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n\n<a class=\"left carousel-control\" href=\"#"
    + escapeExpression(((helper = (helper = helpers.carouselId || (depth0 != null ? depth0.carouselId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"carouselId","hash":{},"data":data}) : helper)))
    + "\" data-slide=\"prev\"><i class=\"fa fa-chevron-left fa-2x\"></i></a>\n<a class=\"right carousel-control\" href=\"#"
    + escapeExpression(((helper = (helper = helpers.carouselId || (depth0 != null ? depth0.carouselId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"carouselId","hash":{},"data":data}) : helper)))
    + "\" data-slide=\"next\"><i class=\"fa fa-chevron-right fa-2x\"></i></a>\n\n";
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}