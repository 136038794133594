var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return ": "
    + escapeExpression(((helper = (helper = helpers.advancedSearchName || (depth0 != null ? depth0.advancedSearchName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"advancedSearchName","hash":{},"data":data}) : helper)));
},"3":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "            <div id=\"block_"
    + escapeExpression(((helper = (helper = helpers.blockId || (depth0 != null ? depth0.blockId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"blockId","hash":{},"data":data}) : helper)))
    + "\" class=\"block block_"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\">\n            </div>\n";
},"5":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                <button type=\"button\" class=\"btn btn-default\" id=\"storesearchasnew\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "store.search.as.new", {"name":"lbl","hash":{},"data":data})))
    + "</button>\n                <button type=\"button\" class=\"btn btn-default\" id=\"backtosearchhistory\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "backto.searchhistory", {"name":"lbl","hash":{},"data":data})))
    + "</button>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = this.invokePartial(partials.helpBox, '', 'helpBox', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n<div class=\"form-horizontal\">\n<div class=\"block_0\">\n    <legend>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "search.advanced.legend", {"name":"lbl","hash":{},"data":data})));
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.advancedSearchName : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</legend>\n    <fieldset class=\"advancedsearch fieldSet_0\">\n";
  stack1 = helpers.each.call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.block : stack1), {"name":"each","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        <br/>\n        <div class=\"form-group form-actions\">\n            <a class=\"btn btn-primary\" id=\"clearadvancedsearch\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "search.advanced.clear", {"name":"lbl","hash":{},"data":data})))
    + "</a>\n            <button type=\"submit\" class=\"btn btn-primary\" id=\"startadvancedsearch\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "start.search", {"name":"lbl","hash":{},"data":data})))
    + "</button>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.advancedSearchName : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </div>\n    </fieldset>\n</div>\n</div>";
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}