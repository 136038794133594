var Model = require('./model');

// join Model, Backbone.NestedModel and our stuff together
var UserDownload = Model.extend(
    _.extend({}, Backbone.NestedModel.prototype, {
    urlRoot: applicationRoot + "/userDownload",
    modelKey: "userDownload",
    
    fetch: function (options) {

    	var theOptions = options ? options : {};
        theOptions['async'] = false;
        theOptions['cache'] = false;
        return Model.prototype.fetch.call(this, theOptions);
    },

    sync: function (method, model, options) {
    	return Model.prototype.sync.call(this, method, model, options);
    }

}));

module.exports = UserDownload;