var MiscHelper = require('lib/misc_helper');
var EditableWidget = require('./editable');
var FormHelper = require('lib/form_helper');

// date widgets

var ProtocolWidget = EditableWidget.extend( {
	initialize : function(widget, modelKey, parentProperty, index) {
		EditableWidget.prototype.initialize.call(this, widget, modelKey, parentProperty, index);
	}
});


module.exports = ProtocolWidget;
