var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  return "Welcome Home!\n\n\n<div class=\"row\">\n\n    <div class=\"col-md-4\">\n        <div id=\"tree\" class=\"aciTree\"></div>\n    </div>\n\n    <div class=\"col-md-4\">\n        <input id=\"treesearch\" type=\"text\" value=\"\">\n        <div id=\"treeflat\" class=\"aciTree\"></div>\n    </div>\n\n\n    <div class=\"col-md-4\">\n        <div id=\"treeselection\"></div>\n    </div>\n\n</div>\n\n";
  },"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}