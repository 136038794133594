UserHelper = function () {

};

UserHelper.prototype.dataAreaAuthorities = function () {

    var retVal = undefined;
    var url;
    if (_.string.endsWith(applicationRoot, '/')) {
        url = applicationRoot + 'user/aggregatorAuthority/'
    } else {
        url = applicationRoot + '/user/aggregatorAuthority/'
    }

    $.ajax(
        {
            dataType: 'json',
            url: url,
            type: 'GET',
            async: false,
            success: function (data) {
                retVal = data;
            },
            error: function (data) {
                logger.error("Error: ", data);
            },
            beforeSend: function (jqXHR, settings) {

            }

        });
    return retVal;
};

UserHelper.prototype.getUserGroups = function () {
    var retVal = undefined;

    $.ajax({
        dataType: 'json',
        url: '/select/groupMappings',
        type: 'GET',
        async: false,
        success: function (data) {
            retVal = data;
        }
    });

    return retVal;
};

/**
 * This function is used to get the correct usergroup for etracker.
 * @param userGroup
 * @returns the label suffix accroding to the usergroup
 */

UserHelper.prototype.getUserGroupForTracking = function (userGroup) {
    var retVal = undefined;

    switch (userGroup) {
        case "MVB":
            retVal = "mvb";
            break;
        case "Preisreferenz":
            retVal = "priceReference";
            break;
        case "Buchhändler":
            retVal = "bookseller";
            break;
        case "Dienstleister":
            retVal = "serviceProvider";
            break;
        case "Bibliothek":
            retVal = "biblio";
            break;
        case "Publisher":
            retVal = "publisher";
            break;
        case "ZIS Publisher":
            retVal = "zisPublisher";
            break;
        default:
            retVal = "other";
            break;
    }

    return retVal;
}


module.exports = new UserHelper;