var logger = log4javascript.getLogger('views.AjaxSpinner');
var MsgHelper = require('lib/msg_helper');

var AjaxSpinner = function () 
{
	this.container = "#ajax-loader";
	this.hideTimeout = null;
};

AjaxSpinner.prototype.show = function(infoText, progressText)
{
	this.adjustLoaderContent();
    $(this.container + ' .loader-progress').text(progressText != undefined ? progressText : MsgHelper.getMessage('label.ajax.spinner.loading.standard.text'));
    $(this.container + ' .loader-info').text(infoText != undefined ? infoText : '');
	$(this.container).fadeIn(500);
};

AjaxSpinner.prototype.hide = function()
{
	$(this.container).dequeue();
	$(this.container).hide();
};


AjaxSpinner.prototype.adjustLoaderContent = function()
{
	$('.loader-content', this.container).css(
	{
		marginTop : ($(this.container).height() - $('.loader-content', this.container).height()) / 2
	});
};

module.exports = new AjaxSpinner;