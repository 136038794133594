var MiscHelper = require('lib/misc_helper');
var MsgHelper = require('lib/msg_helper');
var EditableWidget = require('./editable');
var FormHelper = require('lib/form_helper');

// select widgets

SelectionWidget = EditableWidget.extend({
    initialize: function (widget, modelKey, parentProperty) {
        EditableWidget.prototype.initialize.call(this, widget, modelKey, parentProperty);
    },

    getOptions: function (formCollections, model) {

        var doFetch = this.specialBehaviour != "hierarchyMaster" && this.specialBehaviour != "seriesMaster";
        if (doFetch && this.widget.searchOnServer === true) {
            if (MiscHelper.isEmpty(this.widget.minimumInputLength) || this.widget.minimumInputLength > 0) {
                doFetch = false;
            }
        }
        this.options = FormHelper.getCollection(formCollections, this.collection, !doFetch, model);
        return this.options;
    },

    getOptionsSelectMapping: function () {
        return {
            selector: this.bindingSelector,
            multiple: this.multiple,
            key: this.name,
            simpleProperty: this.simple,
            showId: this.showId,
            nullable: this.nullable,
            collectionId: this.collection,
            readonly: this.readonly,
            multipleAsFlat: this.multipleAsFlat
        };
    },

    hasElementWithPropertyValue: function (array, property, value) {
        for (var l = 0; l < array.length; l++) {
            if (array[l][property] === value) {
                return true;
            }
        }
        return false;
    },

    initModelValue: function (model, parentProperty, parentIndex) {
        EditableWidget.prototype.initModelValue.call(this, model);
        var selectValue = '';
        var buildedName = this.buildName(this, parentIndex);
        var modelValue = model.get(buildedName);
        if (modelValue === undefined || modelValue === null) {
            modelValue = "";
        }
        if (this.multipleAsFlat === true) {
            if (MiscHelper.isEmpty(modelValue)) {
                selectValue = "";
            } else {
                selectValue = modelValue.replace(new RegExp('[ ]', 'g'), ',');
            }
        } else if ($.isArray(model.get(buildedName))) {
            for (var i = 0; i < modelValue.length; i++) {
                if (selectValue.length > 0) {
                    selectValue += ',';
                }
                // not all selects are nested object which have an id, we got
                // lists of string too
                // type example: "IMPORT". is from user.groupMapping[i].reports[j].type
                var theValue = modelValue[i].hasOwnProperty("type") ? modelValue[i].type : modelValue[i].id;
                if (theValue === undefined) {
                    theValue = modelValue[i];
                }
                selectValue += theValue;
            }
        } else if (modelValue.id) {
            selectValue = modelValue.id;
        } else {
            selectValue = modelValue;
        }

        if (MiscHelper.isEmpty(selectValue)) {
            if (buildedName === 'form.type' && (model.get('type') === 'multiBundle' || model.get('type') === 'duoBundle')) {
                selectValue = 'WW';
            } else if (buildedName === 'orderingAttribute') {
                // PUBLISHER
                // BOOKSTORE
                // MVB
                // ADMIN
            	if (user.authorities.indexOf('BRASIL_PRICES') > -1) {
                    selectValue = 'creationDate';
                } else if ($.inArray(model.get('collectionType'), ['series', 'set']) > -1) {
                    selectValue = 'sequence';
                } else {
                    if (user.authorities.indexOf('MVB') > -1 || user.authorities.indexOf('ADMIN') > -1) {
                        selectValue = 'score';
                    } else if (user.authorities.indexOf('BOOKSTORE') > -1 && user.authorities.indexOf('PUBLISHER') > -1) {
                        if (model.get('mode') === 'quick') {
                            var query = model.get('query');
                            query = MiscHelper.stripDatabaseFilterFromQuery(query);
                            if (MiscHelper.isEmpty(query)) {
                                selectValue = 'creationDate';
                            } else {
                                selectValue = 'score';
                            }
                        } else {
                            selectValue = 'score';
                        }
                    } else if (user.authorities.indexOf('BOOKSTORE') > -1) {
                        selectValue = 'score';
                    } else if (user.authorities.indexOf('PUBLISHER') > -1) {
                        selectValue = 'creationDate';
                    }
                    if (MiscHelper.isEmpty(selectValue)) {
                        selectValue = 'score';
                    }
                }
            }
        }

        if (model.get('type') === 'journal' && !MiscHelper.isEmpty(buildedName)) {
            var classifications = model.get('classifications');
            if (buildedName.indexOf('maincode') > -1) {
                var maincode = Math.floor(classifications[parentIndex].code / 100) * 100;
                // set the code of the main subject group for zis
                if (!MiscHelper.isEmpty(maincode)) {
                    selectValue = maincode;
                }
            } else if (buildedName.indexOf('subcode') > -1) {
                var subcode = Math.floor(classifications[parentIndex].code);
                var maincode = Math.floor(classifications[parentIndex].code / 100) * 100;
                if (!MiscHelper.isEmpty(subcode) && subcode !== maincode) {
                    selectValue = subcode;
                }
            }
        }

        if ((selectValue === undefined) && this.multiple !== true) {
            selectValue = null;
        }

        if (modelValue != selectValue) {
            model.set(buildedName, selectValue);
        }
    },

    initAfterBinding: function (aOptions, model, editable, bindingSelectorOverride, nameOverride) {
        var localBindingSelector = bindingSelectorOverride;
        var options = aOptions;
        if (bindingSelectorOverride === undefined) {
            localBindingSelector = this.bindingSelector;
        }

        var localName = nameOverride;
        if (nameOverride === undefined) {
            localName = this.name;
        }
        var select = $(localBindingSelector);
        var selectElement = document.getElementById(FormHelper.selector2Id(localBindingSelector));
        var filterFunction = undefined;
        // if (this.filter === 'orderingAttributeFilter') {
        //     filterFunction = function (options) {
        //         if (user.authorities.indexOf('BOOKSTORE') > -1 && user.authorities.indexOf('PUBLISHER') > -1 && model.get('mode') === 'quick') {
        //             var query = model.get('query');
        //             query = MiscHelper.stripDatabaseFilterFromQuery(query);
        //             if (MiscHelper.isEmpty(query)) {
        //                 return options;
        //             } else {
        //                 var result = [];
        //                 for (var i = 0; i < options.length; i++) {
        //                     if ($.inArray(options[i].id, ['creationDate', 'modificationDate']) === -1) {
        //                         result.push(options[i]);
        //                     }
        //                 }
        //                 return result;
        //             }
        //         } else {
        //             return options;
        //         }
        //     }
        // } else
        if (this.filter === 'foreignPriceTypeFilter') {
            var theIndex = localBindingSelector.replace(/.*_/, '');
            filterFunction = function (options) {
                var cc = $('#foreignPrices_currencyCountry_' + theIndex).val();
                if (MiscHelper.isEmpty(cc)) {
                    return options;
                }
                ;
                var result = [];
                for (var i = 0; i < options.length; i++) {
                    //sure we  could putthat in one if...
                    if ('USD_US' === cc && options[i].id === '01') {
                        result.push(options[i]);
                    } else if (cc !== "USD_US" && options[i].id === '02') {
                        result.push(options[i]);
                    } else if (['EUR_DE', 'EUR_AT', 'EUR_FR', 'EUR_GR', 'EUR_IT', 'EUR_NL', 'EUR_PT', 'EUR_SK', 'EUR_SI', 'EUR_ES', 'DKK_DK', 'NOK_NO', 'MXN_MX'].indexOf(cc) !== -1 && options[i].id === '04') {
                        result.push(options[i]);
                    }
                }
                return result;
            };
        } else if (this.filter === 'foreignPriceCurrencyFilter') {
            var theIndex = localBindingSelector.replace(/.*_/, '');
            filterFunction = function (options) {
                var cc = $('#foreignPrices_currencyCountry_' + theIndex).val();
                var fp = model.get('foreignPrices');
                var excludes = [];
                if (fp !== null) {
                    for (var i = 0; i < fp.length; i++) {
                        if (fp[i] !== null && fp[i].currencyCountry !== cc && excludes.indexOf(fp[i].currencyCountry) === -1) {
                            excludes.push(fp[i].currencyCountry);
                        }
                    }
                }
                var result = [];
                for (var i = 0; i < options.length; i++) {
                    //sure we  could putthat in one if...
                    if (excludes.indexOf(options[i].id) === -1) {
                        result.push(options[i]);
                    }
                }
                return result;
            };
        } else if (this.filter === 'queryAreaFilter') {
            var theIndex = localBindingSelector.replace(/.*_/, '');
            filterFunction = function (options) {
                var areas = model.get('selectionareas');
                var cc = areas[theIndex].column;
                var excludes = [];
                for (var i = 0; i < areas.length - 1; i++) {
                    if (areas[i] === null) {
                        continue;
                    }
                    for (var j = i + 1; j < areas.length; j++) {
                        if (areas[j] === null) {
                            continue;
                        }
                        if (areas[i].column === areas[j].column) {
                            for (var k = 0; k < options.length; k++) {
                                var option = options[k].id;
                                var l = 0;
                                for (; l < areas.length; l++) {
                                    if (areas[l] !== null && areas[l].column !== null && option === areas[l].column) {
                                        break;
                                    }
                                }
                                if (l === areas.length) {
                                    areas[j].column = option;
                                    $('#selectionareas_column_' + j).val(option);
                                    break;
                                }
                            }
                        }
                    }
                }
                for (var i = 0; i < areas.length; i++) {
                    if (areas[i] !== null && areas[i].column != cc && excludes.indexOf(areas[i].column) === -1) {
                        excludes.push(areas[i].column);
                    }
                }
                var result = [];
                for (var i = 0; i < options.length; i++) {
                    //sure we  could putthat in one if...
                    if (excludes.indexOf(options[i].id) === -1) {
                        result.push(options[i]);
                    }
                }
                return result;
            };
        } else if (this.filter === 'safetyWarningsFilter') {
            var theIndex = localBindingSelector.replace(/.*_/, '');
            filterFunction = function (options) {
                var cc = $('#formFeatures_code_' + theIndex).val();
                var ff = model.get('formFeatures');
                var excludes = [];
                for (var i = 0; i < ff.length; i++) {
                    if (ff[i] !== null && ff[i].type == 13 && ff[i].code !== cc && excludes.indexOf(ff[i].code) === -1) {
                        excludes.push(ff[i].code);
                    }
                }
                var result = [];
                for (var i = 0; i < options.length; i++) {
                    //sure we  could putthat in one if...
                    if (excludes.indexOf(options[i].id) === -1) {
                        result.push(options[i]);
                    }
                }
                return result;
            };

        } else if (this.filter === 'currencyCountryFilter') {
            var theIndex = localBindingSelector.replace(/.*_/, '');
            filterFunction = function (options) {
                var pj = model.get('pricesJournal');
                var cc = pj[theIndex].currencyCountry;
                var excludes = [];
                if (model.get('bundleswitch') === 'duoTax') {
                    excludes.push('EUR_DE');
                }
                for (var i = 0; i < pj.length; i++) {
                    if (pj[i] !== null && pj[i].currencyCountry !== cc && excludes.indexOf(pj[i].currencyCountry) === -1) {
                        excludes.push(pj[i].currencyCountry);
                    }
                }
                var result = [];
                for (var i = 0; i < options.length; i++) {
                    //sure we  could putthat in one if...
                    if (excludes.indexOf(options[i].id) === -1) {
                        result.push(options[i]);
                    }
                }
                return result;
            };
        } else if (this.filter === 'publisherTypeFilter') {
            filterFunction = function (options) {
                var type = model.get('type');
                if (MiscHelper.isEmpty(type)) {
                    return [];
                }
                var result = [];
                for (var i = 0; i < options.length; i++) {
                    if ((type === 'journal' && options[i].zis) || type !== 'journal') {
                        result.push(options[i]);
                    }
                }
                return result;
            };
        } else if (this.filter === 'productformDetailsFilter') {
            filterFunction = function (options) {
                var form_type = $('#form_type').val();
                if (MiscHelper.isEmpty(form_type)) {
                    return [];
                }
                var result = [];
                for (var i = 0; i < options.length; i++) {
                    if (_.string.startsWith(options[i].id, form_type.charAt(0)) && options[i].visibleUi === true) {
                        result.push(options[i]);
                    }
                }
                return result;
            };
        } else if (this.filter === 'productformTypeFilter') {
            filterFunction = function (options) {
                var type = "";
                if ($('#type').length > 0) {
                    type = $('#type').val();
                } else {
                    type = $('input[name=uiType]:checked').val();
                }
                // Workaround until selection of bundles works
                if (type === undefined) {
                    type = model.get('type');
                }


                if (MiscHelper.isEmpty(type)) {
                    return [];
                }
                var result = [];
                for (var i = 0; i < options.length; i++) {
                    if (
                        (type === 'bundles' && options[i].id === 'WW') ||
                        (type === 'duoBundle' && options[i].id === 'WW') ||
                        (type === 'multiBundle' && options[i].id === 'WW') ||
                        (type === 'pbook' && _.string.startsWith(options[i].id, "B")) ||
                        (type === 'ebook' && options[i].id == 'DG') ||
                        ((type === 'abook' || type === 'avbook') && _.string.startsWith(options[i].id, "A")) ||
                        (type === 'avbook' && _.string.startsWith(options[i].id, "V")) ||
                        (type === 'calendar' && options[i].id == 'PC') ||
                        (type === 'map' && _.string.startsWith(options[i].id, "C")) ||
                        (type === 'digital' && options[i].id !== 'DG' && _.string.startsWith(options[i].id, "D")) ||
                        (type === 'undefined' && options[i].id == '00') ||
                        (type === 'journal' && options[i].id == 'JO') ||
                        (type === 'nonbook' && options[i].id !== 'JO' && options[i].id !== '00' && !_.string.startsWith(options[i].id, "B")
                            && !_.string.startsWith(options[i].id, "D") && !_.string.startsWith(options[i].id, "A") && !_.string.startsWith(options[i].id, "V")
                            && !_.string.startsWith(options[i].id, "C") && options[i].id !== 'PC' && options[i].id !== 'WW')
                    ) {
                        if (options[i].pseudo === undefined || (options[i].pseudo !== undefined && options[i].pseudo === true)) {
                            result.push(options[i]);
                        } else if (options[i].visibleUi === undefined || (options[i].visibleUi !== undefined && options[i].visibleUi === true)) {
                            result.push(options[i]);
                        }
                    }
                }
                return result;
            };
        } else if (this.filter === 'mainZisGroupsFilter') {
            // only return the main ZIS Groups (all ending in a hundred)
            filterFunction = function (options) {
                var type = model.get('type');
                if (type !== 'journal') {
                    return [];
                }

                var result = [];

                // fill the drop down only with the elements ending in a hundred
                for (var i = 0; i < options.length; i++) {
                    if (options[i].code % 100 === 0) {
                        result.push(options[i]);
                    }
                }

                return result;
            };
        } else if (this.filter === 'subZisGroupsFilter') {
            // dependent on the selection made for mainZisGroups
            filterFunction = function (options) {
                var type = model.get('type');
                if (type !== 'journal') {
                    return [];
                }

                var result = [];
                var classifications = model.get('classifications');
                var elementIndex = localBindingSelector.replace(/.*_/, '');
                var maincode = classifications[elementIndex].maincode != null ? classifications[elementIndex].maincode : classifications[elementIndex].code;
                var maincodeshort = Math.floor(maincode / 100);

                // fill the dropdown only with the elements that match the mainvalue
                for (var i = 0; i < options.length; i++) {
                    var sub = Math.floor(options[i].code / 100);
                    if (sub === maincodeshort && Math.floor(options[i].code) !== maincodeshort * 100) {
                        result.push(options[i]);
                    }
                }

                return result;
            };
        }
        else if (this.filter === "noJournalsInListFilter") {
            filterFunction = function (options) {
                // remove the journal entry from the product form list only for related products and bundles (#22470)
                if (model.get('type') !== 'journal') {
                    for (var i = 0; i < options.length; i++) {
                        if (options[i].code && options[i].code === "JO") {
                            options.splice(i, 1);
                        }
                    }
                }
                return options;
            }
        }
        else if (options.collectionId !== undefined && FormHelper.isNumber(options.collectionId)) {
            var that = this;
            filterFunction = function (options) {
                var cv = $(localBindingSelector).val();
                var result = [];
                if (that.multiple && that.multipleAsFlat) {
                    var values = cv.split(' ');
                    for (var i = 0; i < options.length; i++) {
                        if ($.inArray(values, options[i].id) >= 0 || ((options[i].visibleUi !== undefined && options[i].visibleUi === true))) {
                            result.push(options[i]);
                        }
                    }
                } else {
                    for (var i = 0; i < options.length; i++) {
                        if (options[i].pseudo === undefined || (options[i].pseudo !== undefined && options[i].pseudo === true)) {
                            result.push(options[i]);
                        } else if (cv === options[i].id || ((options[i].visibleUi !== undefined && options[i].visibleUi === true))) {
                            // Ticket 16422 - added else. With the previous version it was pssible that an option was
                            // added twice.
                            result.push(options[i]);
                        }
                    }
                }
                return result;
            };
        }
        var show = true;
        if (this.specialBehaviour === 'specialPricesFuture') {
            var validFrom = model.get(localName.replace("typeTypeQualifier", "validFrom"));
            if (!MiscHelper.isEmpty(validFrom)) {
                show = false;
                var spanId = FormHelper.selector2Id(localBindingSelector) + "_sp";
                if ($(selectElement).next().length === 0) {
                    $(selectElement).after($('<span />').attr("id", spanId));
                }
                select = $('#' + spanId);
            }
        }
        if (this.specialBehaviour === 'formDescription') {
            var formDescription = model.get('form.description');
            var match;
            if (!MiscHelper.isEmpty(formDescription)) {
                for (var i = 0; i < options.length; i++) {
                    if (options[i].id === formDescription) {
                        match = options;
                        break;
                    }
                }
                if (match === undefined) {
                    match = {
                        'code': formDescription,
                        'description': formDescription,
                        'id': formDescription,
                        'text': new Handlebars.SafeString(formDescription)
                    }
                    var newOptions = [];
                    $.extend(true, newOptions, options);
                    newOptions.push(match);
                    options = newOptions;
                }
            }
        }

        if (show && editable && selectElement != null && selectElement.tagName !== "SPAN") {
            var minimumInputLength = 0;
            if (this.minimumInputLength !== undefined) {
                minimumInputLength = this.minimumInputLength;
            } else if (this.searchOnServer) {
                minimumInputLength = 3;
            }
            var selectConfig = {
                minimumResultsForSearch: 30,
                allowClear: this.nullable,
                placeholder: MsgHelper.getMessageOrDefault("label." + this.label + ".placeholder", MsgHelper.getMessageOrDefault("label.selection.placeholder" + (this.multiple ? '.multiple' : ''), " ")),
                multiple: this.multiple,
                minimumInputLength: minimumInputLength,
                formatResult: this.format,
                formatSelection: this.format
            };
            if (this.autoSort === true) {
                selectConfig['sortResults'] = this.sortResults;
            }

            if (this.freeText) {
                selectConfig['createSearchChoice'] = function (term, data) {
                    if ($(data).filter(function () {
                            return this.text.localeCompare(term) === 0;
                        }).length === 0) {
                        return {id: term, text: term};
                    }
                };

                //selectConfig['formatSelection'] = function (addr, node) {
                //    node.replaceWith("<div data-sitem='" + addr.id + "'>" + addr.text + "</div>");
                //};
            }

            // TODO perhaps someone have an better idea to show the IDs for exactly these three fields. Please do it! (HGF)
            if (this.id === 'classifications_index' ||
                this.id === 'classifications_group' ||
                this.id === 'form_type' ||
                this.id === 'bundleParts_type' ||
                this.id === 'priceParts_productTypeCode' ||
                (this.id === 'classifications_code' && model.get('type') === 'journal')) {
                options = FormHelper.showIdForSelect(options);
            }
            if (filterFunction === undefined) {
                selectConfig['data'] = options;
            } else {
                selectConfig['data'] = function () {
                    return {results: filterFunction(options)};
                };
            }

            var isNoDropDown = this.isNoDropDown(this.searchOnServer, model, options, filterFunction);
            //this.searchOnServer === true && options.length === 1
            if (isNoDropDown) {
                var id = options[0].id;
                if (model.get('type') === "journal") {
                    if (filterFunction !== undefined) {
                        id = filterFunction(options)[0].id;
                    }
                }
                model.set(localName, id);
            } else if (this.searchOnServer === true && options.length === 0) {
                delete selectConfig['data'];

                var ajaxUrl = options.url;
                var self = this;
                if (this.specialBehaviour === "hierarchyMaster" || this.specialBehaviour === "seriesMaster") {
                    var bindingSnippets = bindingSelectorOverride.split('_');
                    var collection = model.get('collections')[bindingSnippets[2]];
                    // Copy the collection key from the identifier object to the virtual attribute 'series' or 'set'
                    // in the master object. That is where the select box reads it's value from. Take care of a create
                    // new szenario! In this case identifiers value is empty and the set or series attribute of the
                    // master has the value createNew. This value must not be overwritten! Use Case in the UI for this
                    // is adding more than one series without saving the product between.
                    if (collection.master.identifiers && !MiscHelper.isEmpty(collection.master.identifiers[0].value)) {
                        model.set('collections.' + bindingSnippets[2] + '.' + bindingSnippets[1], collection.master.identifiers[0].value);
                    }
                    var pubs = model.get('publishers');
                    var pubMvbId = null;
                    for (var i = 0; i < pubs.length; i++) {
                        if (pubs[i].type === '01') {
                            pubMvbId = pubs[i].publisherId;
                            break;
                        }
                    }

                    if (!MiscHelper.isEmpty(pubMvbId)) {
                        ajaxUrl += "/" + pubMvbId;
                    } else {
                        ajaxUrl += "/0";
                    }
                }

                selectConfig['initSelection'] = function (element, callback) {
                    var id = $(element).val();
                    if (id !== "") {
                        var useThisAjaxURL = ajaxUrl;
                        if (self.specialBehaviour === 'advancedSearch') {
                            var index = localName.split('.')[1];
                            var condition = model.get('conditions')[index];
                            if (condition.searchPhrase !== null) {
                                useThisAjaxURL += '/' + condition.column;
                            }
                        }
                        $.ajax(useThisAjaxURL + "?search=" + id, {
                            "headers": {
                                'Authorization': "Bearer " + user.access_token
                            }
                        }).done(function (data) {
                            for (var i = 0; i < data.length; i++) {
                                if (data[i].value === null) {
                                    data[i].text = data[i].id;
                                } else {
                                    data[i].text = data[i].value;
                                }
                            }
                            if (self.specialBehaviour === 'advancedSearch') {
                                if (data.length === 1 && data[0].value === id) {
                                    callback(data[0]);
                                } else {
                                    var data = {id: id, text: id, value: id};
                                    callback(data);
                                }
                            }
                            else if (self.specialBehaviour === 'seriesMaster') {
                                if (data.length === 0) {
                                    var msg = MsgHelper.getMessage('label.form.product.field.series.createNew');
                                    var data = {
                                        id: 'createNew',
                                        text: msg,
                                        value: msg
                                    };
                                    callback(data);
                                } else if (data.length === 1) {
                                    callback(data[0]);
                                }
                            }
                            else if (self.specialBehaviour === 'hierarchyMaster') {
                                if (data.length === 0) {
                                    var msg = MsgHelper.getMessage('label.form.product.field.hierarchy.createNew');
                                    var data = {
                                        id: 'createNew',
                                        text: msg,
                                        value: msg
                                    };
                                    callback(data);
                                } else if (data.length === 1) {
                                    callback(data[0]);
                                }
                            }
                            else {
                                callback(data[0]);
                            }
                        });
                    }
                };

                selectConfig['ajax'] = {
                    url: function () {
                        if (self.specialBehaviour === "advancedSearch") {
                            var iPosition = localBindingSelector.lastIndexOf('_');
                            var rowNumber = localBindingSelector.substring(iPosition + 1);
                            var target = $('#conditions_column_' + rowNumber).attr('value')
                            return ajaxUrl + '/' + target;
                        } else if (self.specialBehaviour === 'hierarchyMaster' || self.specialBehaviour === 'seriesMaster') {
                            var pubs = model.get('publishers');
                            var pubMvbId = null;
                            for (var i = 0; i < pubs.length; i++) {
                                if (pubs[i].type === '01') {
                                    pubMvbId = pubs[i].publisherId;
                                    break;
                                }
                            }
                            ajaxUrl = ajaxUrl.substring(0, ajaxUrl.lastIndexOf('/') + 1) + pubMvbId;
                        } else if (self.specialBehaviour === 'publisherType') {
                            var type = $("input[name=uiType]:checked");
                            if (type.length === 1) {
                                type = type.val();
                                if (type === 'journal') {
                                    ajaxUrl += "?zis";
                                } else {
                                    ajaxUrl = ajaxUrl.replace('?zis', '');
                                }
                            } else {
                                ajaxUrl = ajaxUrl.replace('?zis', '');
                            }
                        }
                        return ajaxUrl;
                    },
//			        dataType: 'json',
                    quietMillis: 1000,
                    data: function (term, page) {
                        return {
                            search: term
                        };
                    },
                    results: function (data, page) {
                        if (self.specialBehaviour === "hierarchyMaster") {
                            data.push({
                                "id": "createNew",
                                "value": MsgHelper.getMessage("label.form.product.field.hierarchy.createNew")
                            });
                        }
                        if (self.specialBehaviour === "seriesMaster") {
                            data.push({
                                "id": "createNew",
                                "value": MsgHelper.getMessage("label.form.product.field.series.createNew")
                            });
                        }

                        var dataArray;
                        if (Array.isArray(data)) {
                            dataArray = data;
                        } else if (Array.isArray(data.codes)) {
                            dataArray = data.codes;
                        }

                        for (var i = 0; i < dataArray.length; i++) {
                            if (!MiscHelper.isEmpty(dataArray[i].value)) {
                                dataArray[i].text = dataArray[i].value;
                            } else if (!MiscHelper.isEmpty(dataArray[i].description)) {
                                // onix codelist codes comes whether with a text nor an id attribute along.
                                if (!MiscHelper.isEmpty(dataArray[i].code)) {
                                    dataArray[i].text = dataArray[i].code + ' - ' + dataArray[i].description;
                                } else {
                                    dataArray[i].text = dataArray[i].description;
                                }
                            } else {
                                dataArray[i].text = dataArray[i].id;
                            }
                            if (MiscHelper.isEmpty(dataArray[i].id) && !MiscHelper.isEmpty(dataArray[i].code)) {
                                dataArray[i].id = dataArray[i].code;
                            }
                        }
                        return {results: data};
                    },
                    cache: true,
                    params: {
                        "headers": {
                            'Authorization': "Bearer " + user.access_token
                        }
                    }
                };
            }

            select.select2(selectConfig);

            // this.searchOnServer === true && options.length === 1
            if (isNoDropDown) {
                select.select2("enable", false);
            }
            //if (this.freeText) {
            //    var s2container = select.select2("container");
            //    var id = s2container[0].id;
            //    console.log(id);
            //    $( '#' + id + ' .select2-choice' ).on( "click", function() {
            //        console.log( $( this ).text() );
            //    });
            //    s2container.on('dblclick', '.select2-choice', function (event) {
            //        console.log('huhu');
            //    });
            //}

        } else {
            // get the value first - this might also be an array
            var value = model.get(localName);
            select.html(FormHelper.getTextForSelect(options, this.multiple, this.showId, value, this.nullable));

        }
    },

    getConfig: function (multiple) {

        return {
            minimumResultsForSearch: 10,
            allowClear: true,
            placeholder: MsgHelper.getMessageOrDefault("label." + this.label + ".placeholder", " "),
            multiple: multiple,
            sortResults: this.sortResults
        };
    },

    sortResults: function (results, container, query) {
        if (Array.isArray(results)) {
            return results.sort(function (a, b) {
                if (a.id === 'createNew') {
                    return -1;
                }
                if (b.id === 'createNew') {
                    return 1;
                }
                var textA = (typeof a.text).toLowerCase() === 'string' ? a.text : a.text.string;
                var textB = (typeof b.text).toLowerCase() === 'string' ? b.text : b.text.string;

                return textA.localeCompare(textB.toLowerCase());
            });
        } else if (Array.isArray(results.codes)) {
            return results.codes.sort(function (a, b) {
                var textA = (typeof a.description).toLowerCase() === 'string' ? a.description : a.description.string;
                var textB = (typeof b.description).toLowerCase() === 'string' ? b.description : b.description.string;

                return textA.localeCompare(textB.toLowerCase());
            });
        }
    },

    format: function (item) {
        var originalText = item.text;
        return "<div title ='" + originalText + "'>" + originalText + "</div>";
    },

    isNoDropDown: function (searchOnServer, model, options, filterFunction) {
        var returnValue = false;
        if (model.get("type") === "journal") {
            if (filterFunction !== undefined) {
                returnValue = searchOnServer === true && filterFunction(options).length === 1;
            } else {
                returnValue = false;
            }
        } else {
            returnValue = searchOnServer === true && options.length === 1;
        }
        return returnValue;
    }

});

module.exports = SelectionWidget;
