var View = require('./view')
    , template = require('./templates/home');

module.exports = View.extend({

    id: 'home-view',
    autoRender: true,
    region: 'content',
    template: template,
    
    findIn: function(collection, node) {
    	for(var i=0; i< collection.length; i++) {
    		if(collection[i].id === node) {
    			return collection[i];
    		}
    		if(collection[i].branch !== undefined) {
    			var r = this.findIn(collection[i].branch, node);
    			if(r !== null) {
    				return r;
    			}
    		}
    	}
    	
    	return null;
    },
    
    attach: function () {
        View.prototype.attach.call(this);
        
        var findIn = this.findIn.bind(this);
        jQuery.ajax(
                {
                    url : apiRoot + 'codelists/393',
                    type : 'GET',
                    dataType : 'json',
                    contentType: 'application/json',
                    success : function(data) 
                    {
                        var collectionData = [];
                        var collectionDataFlat = [];
                        
                        for(var i=0; i< data.codes.length; i++) {
                            var code = data.codes[i];

                    		var node = {
                        			"id":code.code,
                                    "label": "[" + code.code + "] " + code.description,
                                    "inode":false,
                                    "checkbox":true,
                                    "radio":false,
                        	};
                            
                    		collectionDataFlat.push({
                    			"id":code.code,
                                "label": "[" + code.code + "] " + code.description,
                                "inode":false,
                                "checkbox":true,
                                "radio":false,
                    	});
                    		
                            if(code.code.length === 1) {
                            	collectionData.push(node);
                            } else {                            	
                    			var parentNode = findIn(collectionData, code.code.substring(0, code.code.length -1));
                                if (parentNode.branch === undefined) {
                                	parentNode.branch = [];
                                	parentNode.inode =true;
                                }
                                
                            	parentNode.branch.push(node);	
                            }
                        }
                    
                        // init the tree
                        $('#tree').aciTree({
                            rootData : collectionData,
                            checkbox: true,
                            checkboxChain: false,

                        });
                        
                        $('#treeflat').aciTree({
                            rootData : collectionDataFlat,
                            checkbox: true,
                            checkboxChain: false,

                        });
                        var api = $('#treeflat').aciTree('api');
                        $('#treesearch').keyup(function() {
                            api.filter(null, {
                                search: $(this).val(),
                                success: function(item, options) {
                                    if (!options.first) {
                                        alert('No results found!');
                                    }
                                }
                            });
                        });

                        $('#tree').on('acitree', function(event, api, item, eventName, options) {
                        	if(eventName === "checked") {
                                if (api.isItem(item)) {
                                	var id = api.getId(item);
                                    $('#treeselection').prepend('<p id="'+ id + '">' + api.getLabel(item) + '</p>');
                                }                        		
                        	} else if(eventName === "unchecked") {
                                if (api.isItem(item)) {
                                	var id = api.getId(item);
                                    $('#treeselection #' + id).remove();
                                }                        		                        		
                        	}
                        });
                    },
                    error : function(data) 
                    {
                        console.error(data);
                    },
                    headers : 
                    {
                        'Authorization' : 'Bearer ' + user.access_token
                    }
                });
        
        
        
        return this;
    },
});
