var MiscHelper = require('lib/misc_helper');
var EditableWidget = require('./editable');
var FormHelper = require('lib/form_helper');
var WidgetTemplate = require('views/templates/forms/widgets/hidden');
// radio widgets

var HiddenWidget = EditableWidget.extend( {
	initialize : function(widget, modelKey, parentProperty) {
		EditableWidget.prototype.initialize.call(this, widget, modelKey, parentProperty);
		this.isHidden = true;
	},

    render : function(model, renderData) {
    	var data = this.fillDataId(renderData);
    	return WidgetTemplate(this, { data: data });
    }

});


module.exports = HiddenWidget;
