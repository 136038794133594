var MsgHelper = require('lib/msg_helper');
var View = require('./view')
var Model = require('models/model_with_auth');

var AnnualInvoiceReport;

module.exports = AnnualInvoiceReport = View.extend({

    container: '#fieldSet_annual_invoice_report',
    autoRender: true,
    autoAttach: false,
    className: 'annualInvoiceReport',
    codelists: {},

    initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);
    },

    getTemplateData: function () {

        var data = View.prototype.getTemplateData.call(this);

        var AvailableInvoiceReportYears = Model.extend({urlRoot: apiRoot + 'reporting/' + this.model.get('modelKey') + 'ReportYears'});
        var availableInvoiceReportYears = new AvailableInvoiceReportYears();
        availableInvoiceReportYears.fetch({async: false});
        var years = availableInvoiceReportYears.get('content');
        years.sort(function (a, b) {
            return b - a;
        });

        var currentAnnualInvoiceYear = years[0];

        data.helpDescription = new Handlebars.SafeString(MsgHelper.formatMessage('label.form.' + this.model.get('modelKey') + '.info.text0', [currentAnnualInvoiceYear]));
        data.text1 = new Handlebars.SafeString(MsgHelper.formatMessage('label.form.' + this.model.get('modelKey') + '.info.text1',
            [currentAnnualInvoiceYear - 1, currentAnnualInvoiceYear, currentAnnualInvoiceYear, currentAnnualInvoiceYear]));
        data.text2 = new Handlebars.SafeString(MsgHelper.formatMessage('label.form.' + this.model.get('modelKey') + '.info.text2', [currentAnnualInvoiceYear+1]));

        data.items = [];
        data.year = currentAnnualInvoiceYear;

        var managedAccounts;
        if(this.model.get('modelKey').indexOf('Zis') > -1) {
            managedAccounts = this.model.get('managedZisAccounts');
        } else {
            managedAccounts = this.model.get('managedAccounts');
        }

        for (var i = 0; i < managedAccounts.length; i++) {
            var item = {
                name: 'Fakturreport' + currentAnnualInvoiceYear + '_' + managedAccounts[i].mvbId + '_'
                + (managedAccounts[i].shortName === null ? "???" : managedAccounts[i].shortName),
                accountId: managedAccounts[i].mvbId,
                year: data.year
            }
            if (i % 2 === 0) {
                item.open = true;
            } else {
                item.close = true;
            }
            data.items.push(item);
        }

        data.prevYearAvailable = years.length > 1;
        if (data.prevYearAvailable === true) {

            data.text3 = new Handlebars.SafeString(MsgHelper.formatMessage('label.form.' + this.model.get('modelKey') + '.info.text3', [currentAnnualInvoiceYear-1]));

            data.items2 = [];
            data.year2 = currentAnnualInvoiceYear - 1;

            for (var i = 0; i < managedAccounts.length; i++) {
                var item = {
                    name: 'Fakturreport' + (currentAnnualInvoiceYear-1) + '_' + managedAccounts[i].mvbId + '_'
                    + (managedAccounts[i].shortName === null ? "???" : managedAccounts[i].shortName),
                    accountId: managedAccounts[i].mvbId,
                    year: data.year2
                }
                if (i % 2 === 0) {
                    item.open = true;
                } else {
                    item.close = true;
                }
                data.items2.push(item);
            }
        }


        return data;
    },

    render: function () {
        View.prototype.render.call(this);
    },

    download: function (event) {
        var year = event.currentTarget.attributes["data-year"].value;
        var accountId = event.currentTarget.attributes["data-accountid"].value;
        var localURL = apiRoot + 'reporting/' + this.model.get('modelKey') + '/' + accountId + '/' + year;
        localURL = localURL + '?access_token=' + user.access_token;
        var request = jQuery.ajax({
            contentType: 'application/json',
            url: localURL,
            type: 'POST',
            dataType: 'json',
            "success": function (data) {
                var fancyBoxesDiv = $('#download-div');
                var iframe = $('#download-div iframe');
                if (iframe !== undefined) {
                    $('#download-div iframe').remove();
                }
                iframe = $("<iframe id=\"iFrameDownload\" />").hide().appendTo(fancyBoxesDiv);
                var downloadId = data;
                var downloadURL;
                downloadURL = apiRoot + 'files/reporting/export/' + downloadId + "?access_token=" + user.access_token;
                iframe.attr('src', downloadURL);
            }
        });
    },

    attach: function () {
        View.prototype.attach.call(this);

        this.delegate('click', '[data-toggle="download"]', this.download);
    }
});