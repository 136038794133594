var MsgHelper = require('lib/msg_helper');
var View = require('./view')
var SummaryTemplate = require('./templates/forms/product_data');
var MsgHelper = require('lib/msg_helper');
var DateHelper = require('lib/date_helper');
var PageContentModel = require('models/pagecontent');
var ListView = require('./list_view');
var FormDefinition = require('models/formDefinition');

var FormHelper = require('lib/form_helper');

var CoverView;

module.exports = CoverView = View.extend({

    LOGGER: log4javascript.getLogger('views.ProductDataView'),

    container: '#product_data',
    autoRender: true,
    autoAttach: false,
    className: 'productData',
    codelists: {},
    isniBaseUrl: 'https://isni.org/isni/{:isni}',
    gndBaseUrl: 'https://d-nb.info/gnd/{:gnd}',
    orcIdBaseUrl: 'https://orcid.org/{:orcId}',

    initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);

        this.codelists["languageCodes"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '74');
        this.codelists["countryCodes"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '91');
        this.codelists["thesisTypes"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '72');
        this.codelists["contributorTypes"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '17');
        this.codelists["productFormTypes"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '7');
        this.codelists["productDetailTypes"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '78');
        this.codelists["fileFormats"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '10');
        this.codelists["drmCodes"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '144');
        this.codelists["productContentTypes"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '81');
        this.codelists["zisMedium"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '402');
        this.codelists["gameClassification"]=
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections,'531');
        this.modelKey = 'assets';

    },

    getTemplateData: function () {
        var data = View.prototype.getTemplateData.call(this);

        /*
         * routines for mb basic
         */
        data.showProductBasic = user.authorities.indexOf('PUBLISHER_BASIC') >= 0
            || user.authorities.indexOf('BOOKSTORE_BASIC') >= 0
            || data.model.basic;

        // getting availabilities
        var availabilityColorMap = {1: 'green', 2: 'yellow', 3: 'red'};
        if (data.model.wholesalerAvailabilitySet && data.model.wholesalerAvailabilitySet.length > 0) {
            data.wholesalerAvailabilitySet = {};
            for (var i = 0; i < data.model.wholesalerAvailabilitySet.length; i++) {
                var message = data.model.availabilityMessage ? data.model.availabilityMessage[data.model.wholesalerAvailabilitySet[i].recordSource] : '';
                data.wholesalerAvailabilitySet[data.model.wholesalerAvailabilitySet[i].recordSource]
                    = {
                    statusCode: data.model.wholesalerAvailabilitySet[i].mvbStatusDetail,
                    color: availabilityColorMap[data.model.wholesalerAvailabilitySet[i].mvbStatusDetail],
                    message: message
                };
            }
        }

        //  getting identifiers
        data.dois = [];
        if(data.model.identifiers) {
            for (var i = 0; i < data.model.identifiers.length; i++) {
                switch (data.model.identifiers[i].type) {
                    case '03':
                        data.gtin = data.model.identifiers[i].value;
                        break;
                    case '01':
                        data.ordernumber = data.model.identifiers[i].value;
                        break;
                    case '06':
                        data.dois.push(data.model.identifiers[i].value);
                        break;
                    case 'IS':
                        // for ZIS
                        data.issn = data.model.identifiers[i].value;
                        data.issnlabel = MsgHelper.getMessage('label.form.product.issn');
                        // check if we need another label for zis bundles
                        if (data.model.zisInfo.medium === "WW") {
                            data.issnlabel = MsgHelper.getMessage('label.form.product.issnl');
                        }
                        break;
                    case 'FI':
                        // for ZIS /Fake ISSN
                        data.issn = " ";
                        data.issnlabel = MsgHelper.getMessage('label.form.product.column.productWithoutIssn');
                        break;
                }
            }
        }

        // Zolltarifnummer
        if (user.authorities.indexOf('SHOW_COMMERCIAL_CLASSIFICATION_BIPB') > -1 && user.authorities.indexOf('SHOW_COMMERCIAL_CLASSIFICATION_VLB') < 0 && user.authorities.indexOf('SHOW_COMMERCIAL_CLASSIFICATION_LATAM') < 0) {
            data.commercialClassifications = '';
            if (data.model.commercialClassifications) {
                for (var i = 0; i < data.model.commercialClassifications.length; i++) {
                    if (data.model.commercialClassifications[i].type == '10') {
                        data.commercialClassifications = data.model.commercialClassifications[i].value;
                        break;
                    }
                }
            }
        }
        // SAT Code
        if(user.authorities.indexOf('SHOW_COMMERCIAL_CLASSIFICATION_LATAM') > -1 && user.authorities.indexOf('SHOW_COMMERCIAL_CLASSIFICATION_VLB') < 0 && user.authorities.indexOf('SHOW_COMMERCIAL_CLASSIFICATION_BIPB') < 0) {
            data.commercialClassifications = '';
            if (data.model.commercialClassifications) {
                for (var i = 0; i < data.model.commercialClassifications.length; i++) {
                    if(data.model.commercialClassifications[i].type = '07') {
                        data.commercialClassifications = data.model.commercialClassifications[i].value;
                        break;
                    }
                }
            }
        }


        // publishers
        if (data.model.publishers) {
            data.copublishers = [];
            for (var i = 0; i < data.model.publishers.length; i++) {
                if (data.model.publishers[i].type == '01') {
                    data.publisher = data.model.publishers[i].name;
                    if (data.model.publishers[i].publisherId) {
                        data.publisher += " (" + data.model.publishers[i].publisherId + ")";
                    }
                } else if (data.model.publishers[i].type == '02' && data.model.publishers[i].imprint == false) {
                    var copub = data.model.publishers[i].name;
                    if (data.model.publishers[i].publisherId) {
                        copub += " (" + data.model.publishers[i].publisherId + ")";
                    }
                    data.copublishers.push(copub);
                } else if (data.model.publishers[i].type == '04') {
                    if (data.originaltitle === undefined) {
                        data.originaltitle = {};
                    }
                    data.originaltitle.publisher = data.model.publishers[i].name;
                }

            }
            if(data.model.coPublishersNoMbId) {
                for (var i = 0; i < data.model.coPublishersNoMbId.length; i++) {
                    var copub = data.model.coPublishersNoMbId[i].name;
                    if (data.model.coPublishersNoMbId[i].publisherId) {
                        copub += " (" + data.model.publishers[i].publisherId + ")";
                    }
                    data.copublishers.push(copub);
                }
            }
            data.copublishers = data.copublishers.join(", ");
        }
        // supplier
        if (data.model.supplier) {
            data.supplier = data.model.supplier.name;
            if (data.model.supplier.supplierId) {
                data.supplier += " (" + data.model.supplier.supplierId + ")";
            }
        }
        // getting titles
        if(data.model.titles) {
            for (var i = 0; i < data.model.titles.length; i++) {
                switch (data.model.titles[i].type) {
                    case '05':
                        data.shorttitle = data.model.titles[i].title;
                        break;
                    case '10':
                        data.distributortitle = data.model.titles[i].title;
                        break;
                    case '03':
                        if (data.originaltitle === undefined) {
                            data.originaltitle = data.model.titles[i];
                        } else {
                            data.originaltitle.title = data.model.titles[i].title;
                            data.originaltitle.subtitle = data.model.titles[i].subtitle;
                        }
                        // for (var j = 0; j < data.model.publishers.length; j++) {
                        //     if (data.model.publishers[j].type == '04') {
                        //         data.originaltitle.publisher = data.model.publishers[j].name;
                        //     }
                        // }
                        // for (var k = 0; k < data.model.languages.length; k++) {
                        //     if (data.model.languages[k].type == '02') {
                        //         data.originaltitle.language = FormHelper.getCodelistLabel(this.codelists["languageCodes"], data.model.languages[k].value);
                        //     }
                        // }
                        break;
                }
            }
        }

        // setting the languages
        data.languages = [];
        data.languagespartly = [];

        if(data.model.languages) {
            for (var i = 0; i < data.model.languages.length; i++) {
                if (data.model.languages[i].type == '01') {
                    // languages of type 01 are fully available in the product
                    data.languages.push(FormHelper.getCodelistLabel(this.codelists["languageCodes"], data.model.languages[i].value));
                } else if (data.model.languages[i].type == '02') {
                    // languages of type 02 are the orginal language
                    if (data.originaltitle === undefined) {
                        data.originaltitle = {};
                    }
                    data.originaltitle.language = FormHelper.getCodelistLabel(this.codelists["languageCodes"], data.model.languages[i].value);
                } else if (data.model.languages[i].type == '03') {
                    // languages of type 03 are only partly available in the product
                    data.languagespartly.push(FormHelper.getCodelistLabel(this.codelists["languageCodes"], data.model.languages[i].value));
                }
            }
        }
        data.languages = data.languages.join(', ');
        data.languagespartly = data.languagespartly.join(', ');

        // set variable to check if languages are available for zis products
        if (data.languages || data.languagespartly) {
            data.languageszis = true;
        }

        // setting the thesis type
        data.thesisType = FormHelper.getCodelistLabel(this.codelists["thesisTypes"], data.model.thesisType);


        // contributors
        var contributors = {};
        // group contributors

        if(data.model.contributors) {
            for (var i = 0; i < data.model.contributors.length; i++) {
                var contributor = data.model.contributors[i];
                if (contributors[contributor.type] == undefined) {
                    contributors[contributor.type] = [];
                }
                contributor.typeTranslation = FormHelper.getCodelistLabel(this.codelists["contributorTypes"], contributor.type);
                if (contributor.language) {
                    contributor.typeTranslation = contributor.typeTranslation.replace(/von$/, '');
                    contributor.typeTranslation += MsgHelper.getMessage('label.language.code.' + contributor.language + '.from');
                }

                contributor.lifeString = '';
                if (contributor.dateOfBirth) {
                    var dateOfBirth = DateHelper.convert(contributor.dateOfBirth);
                    contributor.lifeString = MsgHelper.getMessage('label.form.product.field.contributors.dateOfBirth.sign') + ' ' + dateOfBirth;
                }

                if (contributor.dateOfDeath) {
                    if (contributor.lifeString != '') {
                        contributor.lifeString += ', ';
                    }
                    var dateOfDeath = DateHelper.convert(contributor.dateOfDeath);
                    contributor.lifeString += MsgHelper.getMessage('label.form.product.field.contributors.dateOfDeath.sign') + ' ' + dateOfDeath;
                }
                if (contributor.professionalPosition) {
                    if (contributor.lifeString != '') {
                        contributor.lifeString += ', ';
                    }
                    contributor.lifeString += MsgHelper.getMessage('label.form.product.field.contributors.professionalPosition') + ': ' + contributor.professionalPosition;
                }
                if (contributor.biographicalNote) {
                    /*contributor.bioString = '<div id="contributor-bio-'+contributor.id+'" class="collapse collapse-more">'+
                     '<a class="txt-link add collapsed collapseButton with-label" data-toggle="collapse" data-target="#contributor-bio-'+contributor.id+'">&nbsp;</a>'+
                     MsgHelper.getMessage('label.form.product.field.contributors.info') + ': ' + contributor.biographicalNote +
                     '</div>';*/
                    contributor.bioString = '<div class="collapser">' + contributor.biographicalNote + '</div>'
                }

                if (contributor.isni) {
                    contributor.isniUrl = this.isniBaseUrl.replace('{:isni}', contributor.isni);
                }

                if (contributor.dnbId) {
                    contributor.dnbIdUrl = this.gndBaseUrl.replace('{:gnd}', contributor.dnbId);
                }

                if (contributor.orcId) {
                    contributor.orcIdUrl = this.orcIdBaseUrl.replace('{:orcId}', contributor.orcId);
                }

                contributors[contributor.type].push(contributor);
            }
        }
        // now sort contributors by their sequence numbers.
        for (var contributorType in contributors) {
            contributors[contributorType].sort(function (a, b) {
                return a.sequence - b.sequence;
            });
        }
        data.contributors = this.sortObjectByKey(contributors);
        data.maxContributors = 10;

        // form
        if (data.model.form) {
            data.form = FormHelper.getCodelistLabel(this.codelists['productFormTypes'], data.model.form.type);
            if (data.model.form.detail) {
                var details = data.model.form.detail.split(" ");
                for (var i = 0; i < details.length; i++) {
                    data.form += ", " + FormHelper.getCodelistLabel(this.codelists['productDetailTypes'], details[i]);
                }
            }
            data.productContentType = [];
            if (data.model.form.productContentType) {
                var contentTypes = data.model.form.productContentType.split(" ");
                for (var i = 0; i < contentTypes.length; i++) {
                    data.productContentType.push(FormHelper.getCodelistLabel(this.codelists['productContentTypes'], contentTypes[i]));
                }
            }
            data.productContentType = data.productContentType.join(", ");

            // additional Info for ZIS
            if (data.model.zisInfo && data.model.type === 'journal') {
                var medium = data.model.zisInfo.medium,
                    mediumtext = medium !== '00' ? ' ' + FormHelper.getCodelistLabel(this.codelists['zisMedium'], medium) : '';

                if (mediumtext !== "") {
                    data.medium = mediumtext;
                }
            }

            // form binding
            if (data.model.form.description !== undefined) {
                var fromDescriptionLabel = 'label.form.collection.binding.' + data.model.form.description;
                data.formDescription = MsgHelper.getMessage(fromDescriptionLabel);
                if (fromDescriptionLabel === data.formDescription) {
                    data.formDescription = data.model.form.description;
                }
            }
        }
        // extent
        data.pages = '';
        if (data.model.extent) {
            data.fileFormat = '';
            data.ebookFormat = '';

            data.extentLabel = MsgHelper.getMessage('label.form.product.fieldset.form.fileFormat');

            if (data.model.extent.fileFormat) {
                data.fileFormat = FormHelper.getCodelistLabel(this.codelists["fileFormats"], data.model.extent.fileFormat);
            }
            if (data.fileFormat) {
                data.ebookFormat = data.fileFormat + (data.model.extent.epubTypeVersion ? ', ' + data.model.extent.epubTypeVersion : '');
            }
            if (data.model.extent.drmCode) {
                if (data.ebookFormat) {
                    data.ebookFormat += ', ';
                }
                data.ebookFormat += data.drmCode = FormHelper.getCodelistLabel(this.codelists["drmCodes"], data.model.extent.drmCode);
            }

            if (data.model.type === 'journal') {
                data.extentLabel = MsgHelper.getMessage('label.form.product.fieldset.form.ejorunalformat');
            }
        }

        data.editButtonRole = data.model.publisherForMvbId && data.model.type !== 'multiBundle' ? 'MODIFY_PRODUCT' : 'doNotRenderButtonsHack';

        return data;
    },
    getTemplateFunction: function () {
        return SummaryTemplate;
    },
    render: function () {
        View.prototype.render.call(this);
    },
    attach: function () {
        View.prototype.attach.call(this);
        $(this.el).find('.collapser').collapser(
            {
                mode: 'lines',
                truncate: 2,
                showText: MsgHelper.getMessage('label.show.more'),
                hideText: MsgHelper.getMessage('label.show.less'),
                showClass: 'show-class',
                hideClass: 'collapsed',
                controlBtn: 'collapseButton with-label',
                changeText: false
            }
        );
    },
    sortObjectByKey: function (obj) {
        var keys = [];
        var sorted_obj = {};

        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                keys.push(key);
            }
        }

        // sort keys
        keys.sort();

        // create new array based on Sorted Keys
        jQuery.each(keys, function (i, key) {
            sorted_obj[key] = obj[key];
        });

        // add lfd counter
        var c = 1;
        jQuery.each(sorted_obj, function (key, o) {
            jQuery.each(o, function (key2, o2) {
                o2.lfd = c++;
            });
        });

        return sorted_obj;
    }
});