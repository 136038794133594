var View = require('./view')
    , template = require('./templates/navbar');

module.exports = View.extend({

    id: 'navbar',
    region: 'navbar',
    className: 'container-fluid',
    template: template,
    autoRender: true,

    getTemplateData: function () {
        var model = this.model.attributes;
        if(model.entry === undefined) {
            return {};
        }
        for (var i = 0; i < model.entry.length; i++) {
            model.entry[i].clazz = 'icon-link ' + model.entry[i].icon;
        }
        var data = {"user": user, activeNavEntry: this.options.params.navEntry, model: model};
        return data;
    }


});