var WidgetTemplate = require('views/templates/forms/widgets/datefield');
var MiscHelper = require('lib/misc_helper');
var EditableWidget = require('./editable');
var DateHelper = require('lib/date_helper');
var MsgHelper = require('lib/msg_helper');
// date widgets

var DateWidget = EditableWidget.extend({
    initialize: function (widget, modelKey, parentProperty, index) {
        EditableWidget.prototype.initialize.call(this, widget, modelKey, parentProperty, index);
    },

    initAfterBinding: function (model, bindingSelectorOverride, nameOverride) {
        var localBindingSelector = bindingSelectorOverride;
        if (bindingSelectorOverride === undefined) {
            localBindingSelector = this.bindingSelector;
        }
        var localName = nameOverride;
        if (nameOverride === undefined) {
            localName = this.name;
        }
        var format = this.getDateFormat();

        var language = 'de';
        if (versionCountry === 'brasil') {
            language = 'pt-BR';
        }
        else if (versionCountry === 'latam') {
        	language = 'es-MX'
        }

        // init datepickers
        var initData = {
            orientation: "top auto",
            format: format,
            autoclose: true,
            forceParse: true,
            language: language,
            keyboardNavigation: false,
            showOnFocus: false  // copied from version 1.4.0 to our current datepicker version.
        };
        if (this.onlyFuture === true) {
            var d = new Date();
            d.setDate(d.getDate() + 1);
            initData.startDate = d;
        }

        $(localBindingSelector).datepicker(initData);

        if (this['dateFormat'] === 'ONIX') {
            $(localBindingSelector + "_datetoggle").select2({
                minimumResultsForSearch: 10
            });
            var origDateValue = model.get(localName);
            if (origDateValue.length === 6) {
                $(localBindingSelector + "_datetoggle").val("month").change();
                this.precisionChanged("month");
            } else if (origDateValue.length === 4) {
                $(localBindingSelector + "_datetoggle").val("year").change();
                this.precisionChanged("year");
            } else {
                $(localBindingSelector + "_datetoggle").val("day").change();
                this.precisionChanged("day");
            }
        }

        $(localBindingSelector + "_icon").click(function () {
            $(localBindingSelector).data('datepicker').show();
        });
    },

    deBindingConverter: function (direction, value, attr) {
        if (MiscHelper.isEmpty(value)) {
            return "";
        }

        if (direction === 'ModelToView') {
            var theDate = DateHelper.convert(value);
            return theDate;
        } else {
            var theDate = DateHelper.convert(value, formatDateDayMonthYear, 'dd.MM.yyyy');
            return theDate;
        }
    },

    onixBindingConverter: function (direction, value, attr) {
        if (MiscHelper.isEmpty(value)) {
            return "";
        }
        var localBindingSelector = "#" + attr;
        var dateToggleValue = $(localBindingSelector + "_datetoggle").val();

        if (direction === 'ModelToView') {
            if (value.length === 6) {
                return DateHelper.convert(value + '01', 'yyyyMMdd', formatDateMonthYear);
            } else if (value.length === 4) {
                return DateHelper.convert(value + '0101', 'yyyyMMdd', 'yyyy');
            }
            return DateHelper.convert(value, 'yyyyMMdd', formatDateDayMonthYear);
        } else {
            var nvalue = "";
            if (dateToggleValue === 'day') {
                nvalue = DateHelper.convert(value, formatDateDayMonthYear, 'yyyyMMdd');
            } else if (dateToggleValue === 'month') {
                nvalue = DateHelper.convert(value, formatDateMonthYear, 'yyyyMM');
            } else {
                nvalue = DateHelper.convert(value, 'yyyy', 'yyyy');
            }

            if (MiscHelper.isEmpty(nvalue) || nvalue.indexOf("NaN") !== -1) {
                nvalue = "";
            }
            return nvalue;
        }
    },


    buildReadonlyBindingConverter: function () {
        var self = this;
        return function (direction, value, attr) {
            if (direction === 'ModelToView') {
                if (!MiscHelper.isEmpty(value)) {
                    var theDate = DateHelper.convert(value, undefined, self['dateFormat']);
                    return theDate;
                } else if (!MiscHelper.isEmpty(self.labelForEmptyValue)) {
                    return MsgHelper.getMessage("label." + self.labelForEmptyValue);
                }
                return value;
            }
        };
    },

    binding: function (model, bindingSelectorOverride, nameOverride) {

        var localName = nameOverride;
        if (nameOverride === undefined) {
            localName = this.name;
        }

        if (!this.doRender || (this.hideWhenEmpty && MiscHelper.isEmpty(model.get(localName)))) {
            return [];
        }

        var localBindingSelector = bindingSelectorOverride;
        if (bindingSelectorOverride === undefined) {
            localBindingSelector = this.bindingSelector;
        }

        if (this['dateFormat'] === 'ONIX') {
            return [{
                name: localName,
                bindingSelector: {selector: localBindingSelector, converter: this.onixBindingConverter}
            }];
        }
        if (this.readonly === true) {
            return [{
                name: localName,
                bindingSelector: {selector: localBindingSelector, converter: this.buildReadonlyBindingConverter()}
            }];
        }

        return [{
            name: localName,
            bindingSelector: {selector: localBindingSelector, converter: this.deBindingConverter}
        }];
    },

    getDateFormat: function () {
        return formatDateDayMonthYear.replace('MM', 'mm');
    },

    precisionChanged: function (dateToggleValue) {
        var newViewMode = '';
        var format = this.getDateFormat();
        if (dateToggleValue === 'day') {
            newViewMode = 0;
        }
        else if (dateToggleValue === 'month') {
            newViewMode = 1;
            format = format.replace('dd' + dateSeparator, '');
        }
        else if (dateToggleValue === 'year') {
            newViewMode = 2;
            format = format.replace('dd' + dateSeparator, '').replace('mm' + dateSeparator, '');
        }

        //changing the minViewMode of a running datepicker did not work always, so we remove the old and create a new one. 
        $(this.bindingSelector).datepicker('remove');

        // init datepickers
        $(this.bindingSelector).datepicker({
            orientation: "top auto",
            format: format,
            autoclose: true,
            language: datePickersLocale,
            forceParse: true,
            minViewMode: newViewMode,
            keyboardNavigation: false,
            showOnFocus: false  // copied from version 1.4.0 to our current datepicker version.
        });
    },

    render: function (model, renderData) {
        if (this.hideWhenEmpty === true && renderData !== undefined) {
            var splits = renderData.id.split('_');
            var value;
            if (splits.length === 1) {
                value = model.get(splits[0]);
                if (value === null) {
                    return "";
                }
            } else if (splits.length === 3) {
                var path = splits[0] + '.' + splits[2] + '.' + splits[1];
                value = model.get(path);
                if (value === null) {
                    return "";
                }
            }
        }
        var data = this.fillDataId(renderData);
        if (!this.placeholder) {
            this.placeholder = 'placeholder.dateformat.de';
        }
        // views/templates/forms/widgets/datefield.hbs
        return WidgetTemplate(this, {data: data});
    },

    keydown: function (e) {
        // Allow: backspace, delete, tab, escape, enter and .
        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13]) !== -1 ||
            // date separation character.
            e.key === dateSeparator ||
            // Allow: Ctrl+A
            (e.keyCode == 65 && e.ctrlKey === true) ||
            // Allow: home, end, left, right
            (e.keyCode >= 35 && e.keyCode <= 39)) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    }

});


module.exports = DateWidget;
