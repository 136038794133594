var MiscHelper = require('lib/misc_helper');
var ActionHelper = require('lib/action_helper');
var ValidationHelper = function () {
};

ValidationHelper.prototype.uniqueFieldcheck = function (model, modelId, field) {
	var check = function check(value) {
		if(!MiscHelper.isEmpty(value)) {
			var id= modelId;
			if(modelId === undefined) {
				id = null;
			}
			return $.get('validation/' + model.modelKey + "/" + id + "/" + field + "/" + value);
		}
		return true;
	};
	return check;
};

module.exports = new ValidationHelper;