var View = require('./view');
var CarouselViewTemplate = require('./templates/carouselview');
var ActionHelper = require('lib/action_helper');


this.logger = log4javascript.getLogger('views.CarouselView');

module.exports = CarouselView = View.extend({

	className : 'carousel slide',
	tagName : 'div',
	containerMethod : 'html',

	initialize : function(options) {
		View.prototype.initialize.apply(this, [options]);
		this.id = options.id;
	},

	getTemplateFunction : function() {
		return CarouselViewTemplate;
	},
    getTemplateData: function () {
        var data = {};
        if (this.model) {
            data.model = this.model;
            data.modelKey = this.model.modelKey;
            data.modelUrlRoot = this.model.urlRoot;
        }
        data.carouselId = this.id;
        data.formDef = this.options.formDef.attributes;
        data.emptyText = "widget." + this.id + ".emptyText";
        data.defaultAction = this.defaultAction;        
        return data;
    },
	listen : {
		'collection_loaded mediator' : 'collectionLoaded',
		'form_def_loaded mediator' : 'formDefinitionLoaded'
	},
	formDefinitionLoaded : function(loadedFormDef) {
    	var formId = this.options.formDef.attributes['id'];
    	if(loadedFormDef.get('id') !== formId) {
    		return;
    	}
    	
		this.defaultAction = ActionHelper.getActionOnRowClick(this.options.formDef);
    	
    	this.model.fetch();
	},
	
	collectionLoaded : function(collectionLoaded) {
    	if(collectionLoaded.wid !== this.id) {
    		return;
    	}
    	this.model.itemRow = [];
    	//slice into chunks
    	for(var i=0; i <this.model.models.length; i+=this.options.formDef.attributes['entriesPerPage']) {
    		var itemRow = this.model.models.slice(i, i+this.options.formDef.attributes['entriesPerPage']);
			for(j=0; j<itemRow.length; j++) {
				itemRow[j].index = i + j;
			}
    		this.model.itemRow.push(itemRow);
    	}

    	this.render();

        var carouselLeft = '#' + this.options.id + ' .carousel-control.left';
        var carouselRight = '#' + this.options.id + ' .carousel-control.right';
        if(this.model.models.length <= this.options.formDef.attributes['entriesPerPage']) {
            $(carouselLeft).css('display', 'none');
            $(carouselRight).css('display', 'none');
        } else {
            $(carouselLeft).css('display', 'inline');
            $(carouselRight).css('display', 'inline');
        }
        // Set title to all cells which have dotToLongText set as class.
        $('.carousel-inner .dotToLongText a').each(function(){
            $(this).attr('title', $(this).text() );
        });
    },

    attach: function () {
        View.prototype.attach.call(this, arguments);
        
    	$('#' + this.id).carousel({
    		interval:   false
    	});
        return this;
    }

});

