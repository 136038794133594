var ModelController = require('./model_controller');
var ListView = require('views/list_view');
var FormDefinition = require('models/formDefinition');
var UserDownload = require('models/userDownload');
var MsgHelper = require('lib/msg_helper');

var UserDownloadController = ModelController.extend({
    initialize: function (options) {
        this.logger = log4javascript.getLogger('controllers.UserDownloadController');
    },

	getNewModel: function(param) {
		if(param.id !== undefined) {
			var newModel = new UserDownload({id: param.id});
			return newModel;
		}
		return new UserDownload();
	}
});

module.exports = UserDownloadController;