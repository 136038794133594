var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <h5 class=\"compositeWidgetLabel\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, (depth0 != null ? depth0.title : depth0), {"name":"lbl","hash":{},"data":data})))
    + "</h5>\n";
},"3":function(depth0,helpers,partials,data) {
  return "edit-data-table";
  },"5":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "                <th class=\""
    + escapeExpression(((helper = (helper = helpers.styleClass || (depth0 != null ? depth0.styleClass : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"styleClass","hash":{},"data":data}) : helper)))
    + " columnHeader-"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\"><span>"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</span></th>\n";
},"7":function(depth0,helpers,partials,data) {
  return "                <th class=\"removeColumnHeader\">&nbsp;</th>\n";
  },"9":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "            <tr class=\"listWidget ";
  stack1 = helpers['if'].call(depth0, (data && data.last), {"name":"if","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.rowCss : depth0), {"name":"if","hash":{},"fn":this.program(12, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n\n";
  stack1 = helpers.each.call(depth0, ((stack1 = (data && data.root)) && stack1.column), {"name":"each","hash":{},"fn":this.program(14, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isRemovable : depth0), {"name":"if","hash":{},"fn":this.program(21, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "            </tr>\n\n            <tr class=\"hidden vlx_error nod_msg\" data-id=\""
    + escapeExpression(((helpers.index_in_list || (depth0 && depth0.index_in_list) || helperMissing).call(depth0, (data && data.index), ((stack1 = (data && data.root)) && stack1.listIndex), {"name":"index_in_list","hash":{},"data":data})))
    + "\">\n                <td colspan=\""
    + escapeExpression(lambda(((stack1 = (data && data.root)) && stack1.columns_length), depth0))
    + "\">\n                    <div class=\"control-group error\">\n                        <div class=\"controls\">\n                            <div class=\"help-inline\"></div>\n                        </div>\n                    </div>\n                </td>\n            </tr>\n\n";
  stack1 = helpers.each.call(depth0, ((stack1 = (data && data.root)) && stack1.additionalRows), {"name":"each","hash":{},"fn":this.program(24, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"10":function(depth0,helpers,partials,data) {
  return "lastRow";
  },"12":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return escapeExpression(((helper = (helper = helpers.rowCss || (depth0 != null ? depth0.rowCss : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"rowCss","hash":{},"data":data}) : helper)));
  },"14":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.newRow : depth0), {"name":"unless","hash":{},"fn":this.program(15, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"15":function(depth0,helpers,partials,data) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing, buffer = "                        <td class=\"column-"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.widget : depth0)) != null ? stack1['0'] : stack1)) != null ? stack1.property : stack1), depth0))
    + " "
    + escapeExpression(((helper = (helper = helpers.styleClass || (depth0 != null ? depth0.styleClass : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"styleClass","hash":{},"data":data}) : helper)));
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.hasDateWidget : depth0), {"name":"if","hash":{},"fn":this.program(16, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.widget : depth0), {"name":"each","hash":{},"fn":this.program(18, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                        </td>\n";
},"16":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return " datepicker-readonly-"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.widget : depth0)) != null ? stack1['0'] : stack1)) != null ? stack1.readonly : stack1), depth0))
    + " datepicker-input";
},"18":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.sub_widget || (depth0 && depth0.sub_widget) || helperMissing).call(depth0, (this.data(data, 2) && this.data(data, 2).index), ((stack1 = (data && data.root)) && stack1.listIndex), false, ((stack1 = (data && data.root)) && stack1.parentId), {"name":"sub_widget","hash":{},"fn":this.program(19, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"19":function(depth0,helpers,partials,data) {
  return "";
},"21":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "                    <td class=\"removeColumn\">\n                        <div class=\"removeButtonContainer button_"
    + escapeExpression(((helper = (helper = helpers.lindex || (depth0 != null ? depth0.lindex : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"lindex","hash":{},"data":data}) : helper)))
    + "\">\n                            <i ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (data && data.root)) && stack1.parentId), {"name":"if","hash":{},"fn":this.program(22, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + " class=\"vlb-action-icon vlb-action-icon-delete remove\"></i>\n                        </div>\n                    </td>\n";
},"22":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "id=\""
    + escapeExpression(lambda(((stack1 = (data && data.root)) && stack1.parentId), depth0))
    + "_remove\"";
},"24":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "                <tr class=\"listWidget additionalRow ";
  stack1 = helpers['if'].call(depth0, (this.data(data, 1) && this.data(data, 1).last), {"name":"if","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n                    <td colspan=\""
    + escapeExpression(lambda(((stack1 = (data && data.root)) && stack1.columns_length), depth0))
    + "\" ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.hasDateWidget : depth0), {"name":"if","hash":{},"fn":this.program(25, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.widget : depth0), {"name":"each","hash":{},"fn":this.program(27, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                    </td>\n                </tr>\n";
},"25":function(depth0,helpers,partials,data) {
  return "class=\"datepicker-input\"";
  },"27":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.sub_widget || (depth0 && depth0.sub_widget) || helperMissing).call(depth0, (this.data(data, 2) && this.data(data, 2).index), ((stack1 = (data && data.root)) && stack1.listIndex), true, ((stack1 = (data && data.root)) && stack1.parentId), {"name":"sub_widget","hash":{},"fn":this.program(19, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"29":function(depth0,helpers,partials,data) {
  var stack1, buffer = "        <div class=\"control-group\">\n            <a ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (data && data.root)) && stack1.parentId), {"name":"if","hash":{},"fn":this.program(30, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " class=\"txt-link txt-link-plus add\" style=\"height: 15px;\" href=\"javascript:;\"\n               onclick=\"return false;\">";
  stack1 = helpers['if'].call(depth0, ((stack1 = (data && data.root)) && stack1.addDesc), {"name":"if","hash":{},"fn":this.program(32, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</a>\n        </div>\n";
},"30":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "id=\""
    + escapeExpression(lambda(((stack1 = (data && data.root)) && stack1.parentId), depth0))
    + "_add\"";
},"32":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, ((stack1 = (data && data.root)) && stack1.addDesc), {"name":"lbl","hash":{},"data":data})));
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\""
    + escapeExpression(((helper = (helper = helpers.styleClass || (depth0 != null ? depth0.styleClass : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"styleClass","hash":{},"data":data}) : helper)))
    + " "
    + escapeExpression(((helper = (helper = helpers.property || (depth0 != null ? depth0.property : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"property","hash":{},"data":data}) : helper)))
    + " comp_"
    + escapeExpression(((helper = (helper = helpers.compIndex || (depth0 != null ? depth0.compIndex : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"compIndex","hash":{},"data":data}) : helper)))
    + " listSet\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.hasTitle : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n    <table class=\"table table-striped table-bordered ";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.readonly : depth0), {"name":"unless","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"\n           id=\""
    + escapeExpression(((helper = (helper = helpers.property || (depth0 != null ? depth0.property : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"property","hash":{},"data":data}) : helper)))
    + "\">\n        <thead>\n        <tr>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.columnHeader : depth0), {"name":"each","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.renderRemoveColumnHeader : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </tr>\n        </thead>\n        <tbody>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.listIndex : depth0), {"name":"each","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </tbody>\n    </table>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isAddable : depth0), {"name":"if","hash":{},"fn":this.program(29, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}