var MiscHelper = require('lib/misc_helper');
var EditableWidget = require('./editable');
var FormHelper = require('lib/form_helper');

// radio widgets

var Radiowidget = EditableWidget.extend({
    initialize: function (widget, modelKey, parentProperty) {
        EditableWidget.prototype.initialize.call(this, widget, modelKey, parentProperty);
        if (!this.readonly) {
            this.bindingSelector = '[name=' + this.name + ']';
        }
    },

    binding: function (model) {
        if (_.isBoolean(model.get(this.name))) {
            var id = this.buildId(this);
            var name = this.buildName(this);
            var bindingSelector = '[name=' + id + ']';
            return [{
                name: this.name,
                bindingSelector: {selector: bindingSelector, converter: this.booleanConverter}
            }];
        } else {
            return EditableWidget.prototype.binding.apply(this);
        }
    },

    booleanConverter: function (direction, value) {
        if (_.isEqual(direction, 'ViewToModel')) {
            return value === "true";
        } else if (value === true) {
            return "true";
        } else if (value === false) {
            return 'false';
        }
        return '';
    },

    getOptions: function (formCollections) {

        //if (!this.readonly)
        {
            this.radios = FormHelper.getCollection(formCollections, this.collection);
            if (this.specialBehaviour) {
                for (var i = 0; i < this.radios.length; i++) {
                    if (this.radios[i].id === this.specialBehaviour) {
                        this.radios[i].specialBehaviour = this.specialBehaviour;
                    }
                }
            }
            if (this.collection !== undefined && FormHelper.isNumber(this.collection)) {
                var validRadios = [];
                for (var i = 0; i < this.radios.length; i++) {
                    if (this.radios[i].visibleUi === undefined) {
                        validRadios.push(this.radios[i]);
                    } else if (this.radios[i].visibleUi === true) {
                        validRadios.push(this.radios[i]);
                    }
                }
                this.radios = validRadios;
            }
            return this.radios;
        }
    },

    getOptionsSelectMapping: function () {
        if (!this.readonly) {
            return this.bindingSelector;
        }
    },

    initAfterBinding: function (aOptions, model, editable, bindingSelectorOverride, nameOverride) {

        var options;
        if (aOptions.collectionId !== undefined && FormHelper.isNumber(aOptions.collectionId)) {
            options = [];
            for (var i = 0; i < aOptions.length; i++) {
                if (aOptions[i].visibleUi === undefined) {
                    options.push(options[i]);
                } else if (aOptions[i].visibleUi === true) {
                    options.push(aOptions[i]);
                }
            }
        } else {
            options = aOptions;
        }

        if (this.readonly) {
            var localBindingSelector = bindingSelectorOverride;
            if (bindingSelectorOverride === undefined) {
                localBindingSelector = this.bindingSelector;
            }

            var localName = nameOverride;
            if (nameOverride === undefined) {
                localName = this.name;
            }
            var select = $(localBindingSelector);
            var selectElement = document.getElementById(FormHelper.selector2Id(localBindingSelector));
            var value = model.get(localName);
            select.html(FormHelper.getTextForSelect(options, this.multiple, this.showId, value, this.nullable));

        }
        if (this.id === 'bundleswitch' && model.get('uiType') === 'bundles') {
            $('#fieldSet_bundleswitch').removeClass('hidden');
        }
        if (this.showOn != undefined) {
            var target = $(this.bindingSelector).parents('.RadioWidget');

            if (MiscHelper.compareComplex(model.attributes, this.showOn)) {
                target.removeClass('hidden');
            } else {
                target.addClass('hidden');
            }
        }


    }

});


module.exports = Radiowidget;
