var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "    <div class=\"row\">\n        <div class=\"col-md-3\">&nbsp;</div>\n        <div class=\"col-md-6\">\n            <div class=\"nav-footer\">\n                <ul class=\"nav nav-pills nav-justified\">\n                    <li class=\"\">\n                        <a action=\"imprint\" data-toggle=\"confirmationSingle\" href=\"javascript:;\">Impressum</a>\n                    </li>\n                    <li class=\"\">\n                        <a href=\""
    + escapeExpression(((helpers.msg || (depth0 && depth0.msg) || helperMissing).call(depth0, "help.link.search.homePageFooter", {"name":"msg","hash":{},"data":data})))
    + "\" target=\"vlb_help\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "help", {"name":"lbl","hash":{},"data":data})))
    + "</a>\n                    </li>\n                    <li class=\"\">\n                        <a action=\"contact\" data-toggle=\"confirmationSingle\" href=\"javascript:;\">Kontakt</a>\n                    </li>\n                    <li class=\"\">\n                        <a action=\"termsOfUse\" data-toggle=\"confirmationSingle\" href=\"javascript:;\">Einstell-/Nutzungsbedingungen</a>\n                    </li>\n                </ul>\n            </div>\n        </div>\n        <div class=\"col-md-3\">&nbsp;</div>\n    </div>\n    <div class=\"row\">\n        <div class=\"col-md-12\">\n            <p class=\"a-center\">\n                <a href=\"#\" class=\"logo logo-small\"><span>VLB</span></a>\n            </p>\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"col-md-12\">\n            <p class=\"a-center\" id=\"versionName\">Build: <a href=\""
    + escapeExpression(((helper = (helper = helpers.versionAliasLink || (depth0 != null ? depth0.versionAliasLink : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"versionAliasLink","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">"
    + escapeExpression(((helper = (helper = helpers.versionName || (depth0 != null ? depth0.versionName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"versionName","hash":{},"data":data}) : helper)))
    + "</a></p>\n            <p class=\"a-center\" id=\"copyright\">&copy; "
    + escapeExpression(((helper = (helper = helpers.currentYear || (depth0 != null ? depth0.currentYear : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"currentYear","hash":{},"data":data}) : helper)))
    + " "
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "footer.copyright", {"name":"lbl","hash":{},"data":data})))
    + "</p>\n        </div>\n    </div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}