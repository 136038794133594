var View = require('./view')
	, template = require('./templates/sidebar');

module.exports = View.extend({
	/**
	 * get only those sidebar elements the user has a right to see
	 */
	getTemplateData: function () {
        var data = {};
        if (this.model) {
        	data.model = this.model.attributes;        	        	       	                  
        }
        return data;
    },
        
    id: 'sidebar',
    region: 'sidebar',
    tagName: 'ul',
    className: 'nav nav-list',
    template: template,
    autoRender: true

});