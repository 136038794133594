var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.wholesalerAvailabilitySet : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "            <div class=\"row\">\n";
  stack1 = ((helpers.eachInMap || (depth0 && depth0.eachInMap) || helperMissing).call(depth0, (depth0 != null ? depth0.wholesalerAvailabilitySet : depth0), {"name":"eachInMap","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </div>\n            <div class=\"text-primary col-sm-12 hint-1\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.wholesalerAvailability.hint", {"name":"lbl","hash":{},"data":data})))
    + "</div>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;
  return "                    <div class=\"col-sm-12 control-group\">\n                        <i class=\"vlb-status-icon vlb-status-icon-availability-"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.color : stack1), depth0))
    + "\"\n                           title=\""
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "wholesalerAvailability.status.detail", ((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.statusCode : stack1), {"name":"label2","hash":{},"data":data})))
    + "\"><span></span></i>\n\n                        <div class=\"controls\">\n                    <span class=\"readonlyText\">\n                        <strong>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "provider", (depth0 != null ? depth0.key : depth0), {"name":"label2","hash":{},"data":data})))
    + "</strong> "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.value : depth0)) != null ? stack1.message : stack1), depth0))
    + "\n                    </span>\n                        </div>\n                    </div>\n";
},"5":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    	"
    + escapeExpression(((helpers.actionConstructFromHashIfRole || (depth0 && depth0.actionConstructFromHashIfRole) || helperMissing).call(depth0, (depth0 != null ? depth0.editButtonRole : depth0), (depth0 != null ? depth0.modelKey : depth0), (depth0 != null ? depth0.modelUrlRoot : depth0), ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.id : stack1), "edit", true, {"name":"actionConstructFromHashIfRole","hash":{
    'scrollToId': ("block_productType"),
    'style': ("grey pull-right"),
    'method': ("edit")
  },"data":data})))
    + "\n		\n";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '        ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.form : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.form.type", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.uiType : stack1), "journal", {"name":"compare","hash":{
    'operator': ("===")
  },"fn":this.program(6, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.formDescription : depth0), {"name":"if","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '        ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.productContentType : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.form.productContentType", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '        ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.ebookFormat : depth0)),
    'label': ((depth0 != null ? depth0.extentLabel : depth0))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '       		', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.medium : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.form.medium", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.formDescription : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.form.binding", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"10":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    	"
    + escapeExpression(((helpers.actionConstructFromHashIfRole || (depth0 && depth0.actionConstructFromHashIfRole) || helperMissing).call(depth0, (depth0 != null ? depth0.editButtonRole : depth0), (depth0 != null ? depth0.modelKey : depth0), (depth0 != null ? depth0.modelUrlRoot : depth0), ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.id : stack1), "edit", true, {"name":"actionConstructFromHashIfRole","hash":{
    'scrollToId': ("block_publishers"),
    'style': ("grey pull-right"),
    'method': ("edit")
  },"data":data})))
    + "\n		\n";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '        ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.publisher : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.publishers", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '        ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.copublishers : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.copublishers", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.hasRight || (depth0 && depth0.hasRight) || helperMissing).call(depth0, "SHOW_SUPPLIER", {"name":"hasRight","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"11":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.supplier : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.supplier", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"13":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "	    "
    + escapeExpression(((helpers.actionConstructFromHashIfRole || (depth0 && depth0.actionConstructFromHashIfRole) || helperMissing).call(depth0, (depth0 != null ? depth0.editButtonRole : depth0), (depth0 != null ? depth0.modelKey : depth0), (depth0 != null ? depth0.modelUrlRoot : depth0), ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.id : stack1), "edit", true, {"name":"actionConstructFromHashIfRole","hash":{
    'scrollToId': ("block_identifiers"),
    'style': ("grey pull-right"),
    'method': ("edit")
  },"data":data})))
    + "\n        \n        <div class=\"row\">\n";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.uiType : stack1), "journal", {"name":"compare","hash":{
    'operator': ("!==")
  },"fn":this.program(14, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.uiType : stack1), "journal", {"name":"compare","hash":{
    'operator': ("===")
  },"fn":this.program(16, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </div>\n	    \n";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.uiType : stack1), "journal", {"name":"compare","hash":{
    'operator': ("!==")
  },"fn":this.program(18, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"14":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '	            ', 'readonlyControlGroup', depth0, {
    'wrapperStyles': ("col-sm-6"),
    'text': (((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.isbnHyphens : stack1)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.isbn13", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '	            ', 'readonlyControlGroup', depth0, {
    'wrapperStyles': ("col-sm-6"),
    'text': ((depth0 != null ? depth0.gtin : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.gtin13", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'wrapperStyles': ("col-sm-12"),
    'text': ((depth0 != null ? depth0.commercialClassifications : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.commercialClassifications", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"16":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '        		', 'readonlyControlGroup', depth0, {
    'wrapperStyles': ("col-sm-6"),
    'text': ((depth0 != null ? depth0.issn : depth0)),
    'label': ((depth0 != null ? depth0.issnlabel : depth0))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"18":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "        <div class=\"row\">\n";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'wrapperStyles': ("col-sm-12"),
    'text': ((depth0 != null ? depth0.ordernumber : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.ordernumber", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'wrapperStyles': ("col-sm-12"),
    'delimiter': (", "),
    'text': ((depth0 != null ? depth0.dois : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.doi", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </div>\n";
},"20":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "	    "
    + escapeExpression(((helpers.actionConstructFromHashIfRole || (depth0 && depth0.actionConstructFromHashIfRole) || helperMissing).call(depth0, (depth0 != null ? depth0.editButtonRole : depth0), (depth0 != null ? depth0.modelKey : depth0), (depth0 != null ? depth0.modelUrlRoot : depth0), ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.id : stack1), "edit", true, {"name":"actionConstructFromHashIfRole","hash":{
    'scrollToId': ("block_title"),
    'style': ("grey pull-right"),
    'method': ("edit")
  },"data":data})))
    + "\n        \n";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '        ', 'readonlyControlGroup', depth0, {
    'text': (((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.title : stack1)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.title", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '        ', 'readonlyControlGroup', depth0, {
    'text': (((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.subtitle : stack1)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.subtitle", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '        ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.shorttitle : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.shorttitle", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '        ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.distributortitle : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.distributortitle", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.uiType : stack1), "journal", {"name":"compare","hash":{
    'operator': ("!==")
  },"fn":this.program(21, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "        \n";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.uiType : stack1), "journal", {"name":"compare","hash":{
    'operator': ("===")
  },"fn":this.program(25, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.originaltitle : depth0), {"name":"if","hash":{},"fn":this.program(32, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.thesisPresentedTo : stack1), {"name":"if","hash":{},"fn":this.program(35, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"21":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.languages : depth0), {"name":"if","hash":{},"fn":this.program(22, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"22":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.fieldset || (depth0 && depth0.fieldset) || helperMissing).call(depth0, "form.product.fieldset.form.product.field.language", {"name":"fieldset","hash":{},"fn":this.program(23, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"23":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '	                ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.languages : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.form.product.field.language", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"25":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.languageszis : depth0), {"name":"if","hash":{},"fn":this.program(26, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"26":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.fieldset || (depth0 && depth0.fieldset) || helperMissing).call(depth0, "form.product.fieldset.form.product.field.language", {"name":"fieldset","hash":{},"fn":this.program(27, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"27":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.languages : depth0), {"name":"if","hash":{},"fn":this.program(28, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.languagespartly : depth0), {"name":"if","hash":{},"fn":this.program(30, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"28":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '	                	', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.languages : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.form.product.field.languagefully", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"30":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '			        	', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.languagespartly : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.form.product.field.languagepartly", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"32":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.fieldset || (depth0 && depth0.fieldset) || helperMissing).call(depth0, "form.product.field.originaltitle", {"name":"fieldset","hash":{},"fn":this.program(33, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"33":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': (((stack1 = (depth0 != null ? depth0.originaltitle : depth0)) != null ? stack1.title : stack1)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.originaltitle", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': (((stack1 = (depth0 != null ? depth0.originaltitle : depth0)) != null ? stack1.subtitle : stack1)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.originalsubtitle", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': (((stack1 = (depth0 != null ? depth0.originaltitle : depth0)) != null ? stack1.publisher : stack1)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.originalpublishers", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': (((stack1 = (depth0 != null ? depth0.originaltitle : depth0)) != null ? stack1.language : stack1)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.originallanguage", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"35":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.fieldset || (depth0 && depth0.fieldset) || helperMissing).call(depth0, "form.product.fieldset.thesis.show", {"name":"fieldset","hash":{},"fn":this.program(36, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"36":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': (((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.thesisPresentedTo : stack1)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.thesisPresentedTo", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.thesisType : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.thesisType", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'text': (((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.thesisYear : stack1)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.thesisYear", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"38":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "	    "
    + escapeExpression(((helpers.actionConstructFromHashIfRole || (depth0 && depth0.actionConstructFromHashIfRole) || helperMissing).call(depth0, (depth0 != null ? depth0.editButtonRole : depth0), (depth0 != null ? depth0.modelKey : depth0), (depth0 != null ? depth0.modelUrlRoot : depth0), ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.id : stack1), "edit", true, {"name":"actionConstructFromHashIfRole","hash":{
    'scrollToId': ("block_contributors"),
    'style': ("grey pull-right"),
    'method': ("edit")
  },"data":data})))
    + "\n        \n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.noContributor : stack1), {"name":"if","hash":{},"fn":this.program(39, data, depths),"inverse":this.program(41, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"39":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <div class=\"control-group \">\n                <label class=\"control-label nowrap\">\n                    "
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.show.contributor.none", {"name":"lbl","hash":{},"data":data})))
    + "\n                </label>\n            </div>\n";
},"41":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.eachInMap || (depth0 && depth0.eachInMap) || helperMissing).call(depth0, (depth0 != null ? depth0.contributors : depth0), {"name":"eachInMap","hash":{},"fn":this.program(42, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.contributors : stack1)) != null ? stack1.length : stack1), (depth0 != null ? depth0.maxContributors : depth0), {"name":"compare","hash":{
    'operator': (">")
  },"fn":this.program(61, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"42":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.value : depth0), {"name":"each","hash":{},"fn":this.program(43, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"43":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, (depth0 != null ? depth0.lfd : depth0), (depths[2] != null ? depths[2].maxContributors : depths[2]), {"name":"compare","hash":{
    'operator': (">")
  },"fn":this.program(44, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.language : depth0), {"name":"if","hash":{},"fn":this.program(46, data, depths),"inverse":this.program(48, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                    ', 'readonlyControlGroup', depth0, {
    'wrapperStyles': ("leftPaddingControls-1"),
    'text': ((depth0 != null ? depth0.lifeString : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "blank", (depth0 != null ? depth0.type : depth0), {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                    ', 'readonlyControlGroup', depth0, {
    'wrapperStyles': ("leftPaddingControls-1"),
    'text': (((helpers.nl2br || (depth0 && depth0.nl2br) || helperMissing).call(depth0, (depth0 != null ? depth0.bioString : depth0), {"name":"nl2br","hash":{},"data":data}))),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "blank", (depth0 != null ? depth0.type : depth0), {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isni : depth0), {"name":"if","hash":{},"fn":this.program(53, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.dnbId : depth0), {"name":"if","hash":{},"fn":this.program(55, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.orcId : depth0), {"name":"if","hash":{},"fn":this.program(57, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, (depth0 != null ? depth0.lfd : depth0), (depths[2] != null ? depths[2].maxContributors : depths[2]), {"name":"compare","hash":{
    'operator': (">")
  },"fn":this.program(59, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"44":function(depth0,helpers,partials,data) {
  return "                    <div class=\"collapse collapseContributor\">\n";
  },"46":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                        ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.fullName : depth0)),
    'label': ((depth0 != null ? depth0.typeTranslation : depth0))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"48":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (data && data.first), {"name":"if","hash":{},"fn":this.program(49, data),"inverse":this.program(51, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"49":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                            ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.fullName : depth0)),
    'label': ((depth0 != null ? depth0.typeTranslation : depth0))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"51":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                            ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.fullName : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "blank", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"53":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                        ', 'readonlyControlGroup', depth0, {
    'wrapperStyles': ("leftPaddingControls-1"),
    'labelStyles': ("indent-label"),
    'target': ("_blank"),
    'href': ((depth0 != null ? depth0.isniUrl : depth0)),
    'text': ((depth0 != null ? depth0.isni : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.contributors.isni", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"55":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                        ', 'readonlyControlGroup', depth0, {
    'wrapperStyles': ("leftPaddingControls-1"),
    'labelStyles': ("indent-label"),
    'target': ("_blank"),
    'href': ((depth0 != null ? depth0.dnbIdUrl : depth0)),
    'text': ((depth0 != null ? depth0.dnbId : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.contributors.dnbId", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"57":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                        ', 'readonlyControlGroup', depth0, {
    'wrapperStyles': ("leftPaddingControls-1"),
    'labelStyles': ("indent-label"),
    'target': ("_blank"),
    'href': ((depth0 != null ? depth0.orcIdUrl : depth0)),
    'text': ((depth0 != null ? depth0.orcId : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.contributors.orcId", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"59":function(depth0,helpers,partials,data) {
  return "                    </div>\n";
  },"61":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                <a class=\"txt-link add collapsed collapseButton showMoreAuthorsButton with-label grey-label\"\n                   data-toggle=\"collapse\"\n                   data-target=\".collapseContributor\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.contributors.view.more", {"name":"lbl","hash":{},"data":data})))
    + "</a>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "<div id=\"productDataWrapper\">\n\n";
  stack1 = ((helpers.productBlock || (depth0 && depth0.productBlock) || helperMissing).call(depth0, "wholesalerAvailability", {"name":"productBlock","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.productBlock || (depth0 && depth0.productBlock) || helperMissing).call(depth0, "productform", {"name":"productBlock","hash":{},"fn":this.program(5, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.productBlock || (depth0 && depth0.productBlock) || helperMissing).call(depth0, "publishers", {"name":"productBlock","hash":{},"fn":this.program(10, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.productBlock || (depth0 && depth0.productBlock) || helperMissing).call(depth0, "identifiers", {"name":"productBlock","hash":{},"fn":this.program(13, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.block || (depth0 && depth0.block) || helperMissing).call(depth0, "title", "form.product.field.title", {"name":"block","hash":{},"fn":this.program(20, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n\n";
  stack1 = ((helpers.productBlock || (depth0 && depth0.productBlock) || helperMissing).call(depth0, "contributors", {"name":"productBlock","hash":{},"fn":this.program(38, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n</div>";
},"usePartial":true,"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}