var View = require('./view');
var BooleSearchTemplate = require('./templates/boolesearch');
var MsgHelper = require('lib/msg_helper');
var MiscHelper = require('lib/misc_helper');
var LinkHelper = require('lib/link_helper');
var AjaxSpinner = require('lib/ajaxspinner_helper');

var BooleSearchView;

module.exports = BooleSearchView = View.extend({

    id: 'boole-search-view',
    tagName: "form",
    className: "booleform form-horizontal",
    region: 'content',
    containerMethod: 'html',
    autoRender: true,

    initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);
        this.options.query = this.model.get('query');
        if (_.string.startsWith(this.options.query, '(') && _.string.endsWith(this.options.query, ')')) {
            this.model.set('query', this.model.get('query').substring(1, this.model.get('query').length - 1));
        }
        this.modelBinder = new Backbone.ModelBinder();
        this.options.booleSearchInputId = "booleSearchInput";
        this.options.bindings = {'query': '#' + this.options.booleSearchInputId};
        this.options.mode = 'boole';
        this.logger = log4javascript.getLogger('views.BooleSearchView');
    },

    getTemplateFunction: function () {
        return BooleSearchTemplate;
    },

    getTemplateData: function () {
        var data = View.prototype.getTemplateData.call(this);
        data.booleSearchInputId = this.options.booleSearchInputId;
        data.booleSearchName = this.model.get('name');

        data.helpbox = {
            description: "search.boole.description.text",
            url: "help.link.search.boole"
        };

        return data;
    },

    events: {
        'click #startboolesearch': 'startBooleSearch',
        'keyup #boolsearch': 'submitOnEnter',
        // 'keyup #booleSearchInput': 'showStoreSearchAsNewBtn',
        'submit': 'startBooleSearch',
        'click #storesearchasnew': 'storeSearchAsNew',
        'click #backtosearchhistory': 'backToSearchHistory'
    },

    listen: {},

    attach: function () {
        View.prototype.attach.call(this);
        this.modelBinder.bind(this.model, this.el, this.options.bindings, {'changeTriggers': {'*': 'change'}});
        return this;
    },

    submitOnEnter: function (event) {
        if (event.keyCode === 13) {
            this.startBooleSearch(event);
        }
    },

    // showStoreSearchAsNewBtn: function (event) {
    //     if (!MiscHelper.isEmpty(this.model.get('name'))) {
    //         if (this.options.query != $('#' + this.options.booleSearchInputId).val()) {
    //             $('#storesearchasnew').show();
    //         } else {
    //             $('#storesearchasnew').hide();
    //         }
    //     }
    // },

    startBooleSearch: function (event) {
        AjaxSpinner.show();
        event.preventDefault();
        Chaplin.mediator.publish('executedNewSearch');
        Chaplin.mediator.publish('redirectTo', {
            url: 'search_boolesearch',
            data: {
                id: this.model.get('id'),
                search: LinkHelper.encodeURI(this.model.get('query')),
                mode: this.options.mode
            }
        });
    },

    storeSearchAsNew: function (event) {
        Chaplin.mediator.publish('booleSearchPublishPoint', undefined, this.model.get('query'), this.options.mode);
    },

    backToSearchHistory: function (event) {
        Chaplin.mediator.publish('backToSearchHistory');
    }
});