var Controller = require('./controller');
var FormView = require('views/form_view');
var PreviewFormView = require('views/previewForm_view');
var ListView = require('views/list_view');
var WidgetGroupView = require('views/widgetGroup_view');
var PreviewListView = require('views/previewList_view');
var CarouselView = require('views/carousel_view');
var TeaserView = require('views/teaser_view');
var PageContentView = require('views/pageContent_view');
var PageContentDefinition = require('models/pageContentDefinition');
var FormDefinition = require('models/formDefinition');
var PageContentModel = require('models/pagecontent');
var PageContentDataCollection = require('models/pagecontent_dataCollection');
var BooleSearchView = require('views/boole_search_view');
var SearchQuery = require('models/searchQuery');
var AdvancedSearchView = require('views/advanced_search_view');
var BatchSearchView = require('views/batch_search_view');
var MiscHelper = require('lib/misc_helper');
var AjaxSpinner = require('lib/ajaxspinner_helper');

var PageContentController = Controller.extend({
    initialize: function (options) {
        this.logger = log4javascript.getLogger('controllers.PageContentController');
    },

    show: function (params) {
		AjaxSpinner.hide();
//    	Chaplin.mediator.subscribe('redirectTo', this.doRedirect, this);
		if(!MiscHelper.isEmpty(params.query)) {
			params.query = decodeURIComponent(params.query);
			params.query =  MiscHelper.stripDatabaseFilterFromQuery(params.query);
		}
    	logger.debug("load pageContent with id ", params.id);
    	this.model = new PageContentDefinition({id: params.id});
    	this.model.fetch();
    	logger.debug("loaded pageContent with id ", params.id);

    	var widgets = this.model.get('widgets');
    	this.prepareWidgets(widgets);

    	this.model.set('activeWidget',params.activeWidget);
    	this.model.set('type', this.model.get('type').toLowerCase());
    	logger.debug("start pageContentView with id ", params.id);
    	this.view = new PageContentView({model: this.model});

    	logger.debug("load widgets for pageContent with id ", params.id);
        this.params = params;
    	_.each(widgets, this.loadWidget, this);
    },

	//give them ids and do stuff we need for displaying the PageContentView
    prepareWidgets : function(widgets, parentId) {
        if(widgets === undefined) {
            // that only may happen when a page is under construction.
            return;
        }
		for(var i=0; i< widgets.length; i++) {
			widgets[i].type = widgets[i].type.toLowerCase();
			widgets[i].title = "widget." + widgets[i].id + ".title";
			if(widgets[i].type !== 'group') {
				widgets[i].showListLabelCode = "pageContent." +  widgets[i].id + ".showlist";
			}
		}
    },

    loadWidget: function(widget, index) {
    	if(widget.type !== 'group') {
	    	var formKey = widget.dataModelKey + "/" + widget.mode;
	    	widget.formDef = new FormDefinition({id: formKey, wid : widget.id});
	    	if(widget.type === 'form') {
	    		if (widget.dataId === '$user') {
	    			widget.dataId = user.userId;
	    		}
	    		widget.model = new PageContentModel({ id: widget.dataId, urlRoot: widget.dataUrl,  modelKey : widget.dataModelKey, showHeader: false});
	    		if(widget.dataId !== undefined) {
	    			widget.model.fetch();
	    		}
	    		widget.view = new FormView({model: widget.model, formDef: widget.formDef, region: "widget-" + widget.id});
	    	} else if(widget.type === 'preview_form') {
                widget.model = new PageContentModel({ id: widget.dataId, urlRoot: widget.dataUrl,  modelKey : widget.dataModelKey, showHeader: false});
                widget.view = new PreviewFormView({model: widget.model, formDef: widget.formDef, region: "widget-" + widget.id, id: widget.id});
	    	} else if(widget.type === 'list') {
	    		widget.model = new PageContentModel({ urlRoot: widget.dataUrl,  modelKey : widget.dataModelKey, fixedFilter: widget.fixedFilter});
	    		widget.view = new ListView({model: widget.model, formDef: widget.formDef, region: "widget-" + widget.id, id: widget.id, showHeader: false, controller: this});
	    	} else if(widget.type === 'preview') {
	    		widget.model = new PageContentModel({ urlRoot: widget.dataUrl,  modelKey : widget.dataModelKey, fixedFilter: widget.fixedFilter});
	    		widget.view = new PreviewListView({model: widget.model, formDef: widget.formDef, region: "widget-" + widget.id, id: widget.id});
	    	} else if(widget.type === 'carousel') {
	    		widget.model = new PageContentDataCollection({ urlRoot: widget.dataUrl,  modelKey : widget.dataModelKey, wid : widget.id});
	    		widget.view = new CarouselView({model: widget.model, formDef: widget.formDef, region: "widget-" + widget.id, id: widget.id});
            } else if(widget.type === 'boolsearch') {
                widget.model = new SearchQuery({wid : widget.id, query: this.params.searchType == 'boole' ? this.params.query : null});
                widget.view = new BooleSearchView({model: widget.model, formDef: widget.formDef, region: "widget-" + widget.id, id: widget.id});
            } else if(widget.type === 'advancedsearch') {
                widget.model = new SearchQuery({wid : widget.id, query: this.params.searchType == 'advanced' ? this.params.query : null});
                widget.view = new AdvancedSearchView({model: widget.model, formDef: widget.formDef, region: "widget-" + widget.id, id: widget.id});
            } else if(widget.type === 'batchsearch') {
                widget.model = new SearchQuery({wid : widget.id, query: this.params.searchType == 'batch' ? this.params.query : null});
                widget.model.set('copyToText', true);
                widget.model.set('saveFile', false);
                widget.model.set('nrOfTitleLines', '0');
                widget.model.set('nrOfCols', '1');
                widget.model.set('notFoundIds', 'onlyDisplay');
                widget.view = new BatchSearchView({model: widget.model, formDef: widget.formDef, region: "widget-" + widget.id, id: widget.id});
            } else if(widget.type === 'teaser') {
				widget.view = new TeaserView({region: "widget-" + widget.id, id: widget.id});
				widget.view.render();
			}
	    	if(widget.type !== 'boolsearch' && widget.type !== 'batchsearch' && widget.type !== 'teaser') {
                widget.formDef.fetch();
            }

    	} else {
    		this.prepareWidgets(widget.widgets, widget.id);
    		widget.view = new WidgetGroupView({widgetDef: widget, region: "widget-" + widget.id});
    		//and now load
    		for(var i=0; i< widget.widgets.length; i++) {
    			this.loadWidget(widget.widgets[i]);
    		}
    	}
    }

});

module.exports = PageContentController;
