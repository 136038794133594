var Application = require('application');
var View = require('./view');
var BatchSearchTemplate = require('./templates/batchsearch');
var MsgHelper = require('lib/msg_helper');
var MiscHelper = require('lib/misc_helper');
var ErrorHelper = require('lib/error_helper');
var LinkHelper = require('lib/link_helper');
var AjaxSpinner = require('lib/ajaxspinner_helper');

var BatchSearchView;

module.exports = BatchSearchView = View.extend({

    id: 'batch-search-view',
    tagName: "form",
    className: "batchform form-horizontal",
    region: 'content',
    containerMethod: 'html',
    autoRender: true,
    
    initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);
        this.options.query = this.model.get('query');
		if(this.options.query === undefined || this.options.query === null) {
			this.model.set('query','');
		} else {
			this.model.set('query',this.options.query.replace(' ', '\n'));
		}
        this.modelBinder = new Backbone.ModelBinder();
        this.options.batchAreaId = "batcharea";
        this.options.fileUploadUrl = '/batchupload/file';
        this.options.bindings = {'query': '#' + this.options.batchAreaId, 
        		'copyToText': '#copyToText', 
        		'saveFile': '#saveFile', 
        		'nrOfTitleLines': '#nrOfTitleLines', 
        		'nrOfCols': '#nrOfCols',
        		'notFoundIds': '#notFoundIds'};
        this.logger = log4javascript.getLogger('views.BatchSearchView');
    },

    getTemplateFunction: function () {
        return BatchSearchTemplate;
    },

    getTemplateData: function () {
        var data = View.prototype.getTemplateData.call(this);
        data.batchAreaId = this.options.batchAreaId;
        data.batchSearchName = this.model.get('name');
        data.batchSearchPlaceholder = MsgHelper.getMessage('label.search.batch.description.placeholder');

        data.helpbox = { description : 'search.batch.description.text', url : 'help.link.search.batch'}
        return data;
    },

    events: {
        'click #starttextareasearch': 'starttextareasearch',
    	'click #cleartextareasearch': 'cleartextareasearch',
    	'click #storesearchasnew': 'storeSearchAsNew',
        'click #backtosearchhistory': 'backToSearchHistory'
//        'keyup #batcharea': 'showStoreSearchAsNewBtn'
    },

    listen: {
        
    },

    attach: function () {
    	View.prototype.attach.call(this);
        this.initFileupload();
        this.initSelects();
        this.modelBinder.bind(this.model, this.el, this.options.bindings, {'changeTriggers': {'*': 'change'}});
        return this;
    },
    
    initFileupload: function() {
    	Chaplin.mediator.subscribe('batchFileUploadSuccess', this.uploadSuccess, this);
    	Chaplin.mediator.subscribe('batchFileUploadError', this.uploadError, this);
    	$('#fileupload').fileupload({
            pasteZone:null,
    	    url: this.options.fileUploadUrl,
    	    sequentialUploads: true,
    	    headers: {
            	'Authorization' :"Bearer " + user.access_token
            },
            add: function (e, data) {
            	if (!data.form) {
            		data.form = new Object();
            	}
            	data.form.saveFile = $('#saveFile').val();
            	data.form.copyToText = $('#copyToText').val();
            	data.form.nrOfTitleLines = $('#nrOfTitleLines').val();
            	data.form.nrOfCols = $('#nrOfCols').val();
            	data.form.notFoundIds = $('#notFoundIds').val();
    	        var jqXHR = data.submit()
    	            .success(function(result, textStatus, jqXHR) {
	    	            	MsgHelper.clearAlerts();
	    	            	Chaplin.mediator.publish('batchFileUploadSuccess', result, textStatus, jqXHR);
    	            	})
    	            .error(function (jqXHR, textStatus, errorThrown) {
	    	            	MsgHelper.clearAlerts();
	    	            	Chaplin.mediator.publish('batchFileUploadError', jqXHR, textStatus, errorThrown);
    	            	});
    	    }
    	});
    },
    
    initSelects: function() {
    	var selectFields = [{id: "onlyDisplay", text: MsgHelper.getMessage("select.batch.search.notFoundIds.onlyDisplay")},
    		{id: "asTxtFile", text: MsgHelper.getMessage("select.batch.search.notFoundIds.asTxtFile")},
    		{id: "asCsvFile", text: MsgHelper.getMessage("select.batch.search.notFoundIds.asCsvFile")},
    		{id: "asXlsFile", text: MsgHelper.getMessage("select.batch.search.notFoundIds.asXlsFile")},
    		{id: "asXlsxFile", text: MsgHelper.getMessage("select.batch.search.notFoundIds.asXlsxFile")}];
    	$('#notFoundIds').select2({
            minimumResultsForSearch: 99,
            data: selectFields,
            placeholder: MsgHelper.getMessage("select.batch.search.notFoundIds.onlyDisplay"),
            sortResults: function(results, container, query) {
                return results.sort(function(a, b) {
                    if (a.text < b.text)
                        return -1;
                    if (a.text > b.text)
                        return 1;
                    return 0;
                });
            },
            width: $('.filebatchsearch .col-md-2').outerWidth()
        });
    },
    
    starttextareasearch: function(event) {
        AjaxSpinner.show();
		Chaplin.mediator.publish('executedNewSearch');
    	var batchQueryString = MiscHelper.normalizeBatches(this.model.get('query'));
        Chaplin.mediator.publish('redirectTo', {url: 'search_batchsearch',
			data:{'id': this.model.get('id'), 'search': LinkHelper.encodeURI(batchQueryString), 'unknownIds': this.model.get('notFoundIds')}});
    },

    cleartextareasearch: function(event) {
    	this.model.set('query','');
    },
    
    showSearchWithoutStoreBtn: function(event) {
    	if (!MiscHelper.isEmpty(this.model.get('name'))) {
    		if (this.options.query != $('#'+this.options.batchAreaId).val()) {
    			$('#searchwithoutstore').show();
    		} else {
    			$('#searchwithoutstore').hide();
    		}
    	}
    },
    
    uploadSuccess: function(result, textStatus, jqXHR) {
    	var contentNew = result.batchIds.join(' ');
    	if(this.model.get('copyToText')) {
	    	var contentBefore = this.model.get('query');
	    	var contentAfter = contentNew;
	    	if (!MiscHelper.isEmpty(contentBefore)) {
	    		contentAfter = contentBefore + ' ' + contentNew;
	    	}
	    	this.model.set('query',contentAfter);
	    	this.correctTextAreaSize();
	    	this.showSearchWithoutStoreBtn(null);
	    	
	    	var nrOfNewBatchIds = MiscHelper.getBatchIdArray(contentNew).length;
	    	this.updateUploadMessage(Application.flashMessage, nrOfNewBatchIds, result.filename);
	    	MsgHelper.showSuccessAlert(Application.flashMessage);
    	} else {
    		$('#fileupload').fileupload('destroy');
    		this.sendSearchQueryInfo(contentNew);
    		Chaplin.mediator.publish('batchSearchPublishPoint', this.model.get('id'), contentNew, this.model.get('notFoundIds'));
    	}
    },
    
    uploadError: function(jqXHR, textStatus, errorThrown) {
        this.logger.error('batch_search_view:uploadError', textStatus, errorThrown);

    	if (jqXHR.responseText.indexOf("InvalidFileFormatException") !== -1) {
    		ErrorHelper.showCustomError(MsgHelper.getMessage("error.batch.InvalidFileFormatException"));
    	}
    	if (jqXHR.responseText.indexOf("UnreadableFileException") !== -1) {
    		ErrorHelper.showCustomError(MsgHelper.getMessage("error.batch.UnreadableFileException"));
    	}
    },
    
    updateUploadMessage: function(messageBefore, nrOfNewBatchIds, filename) {
    	var props = { "_NR_OF_IDS_": nrOfNewBatchIds.toString(), "_FILENAME_": filename};
    	var newMsg = MsgHelper.getPropertyMessage( "msg.batch.fileupload.success", props);
    	if (!MiscHelper.isEmpty(messageBefore) && this.isUploadMessage(messageBefore)) {
    		newMsg = messageBefore + "<br/>" + newMsg;
    	}
    	Application.flashMessage = newMsg;
    },
    
    isUploadMessage: function(messageBefore) {
    	//TODO: find criterias for that
    	return true;
    },
    
    correctTextAreaSize: function() {
    	var rawbatches = this.model.get('query');
    	var nrOfBatches = typeof(MiscHelper.getBatchIdArray(rawbatches)) === 'undefined' ? 0 : MiscHelper.getBatchIdArray(rawbatches).length;
    	var lineHeight = $(this.options.bindings.query).css('lineHeight');
    	var newHeight = nrOfBatches * parseInt(lineHeight);
    	newHeight = Math.min(300, newHeight);
//    	$(this.options.bindings.query).height(newHeight);
    },
    
    storeSearchAsNew: function(event) {
    	Chaplin.mediator.publish('batchSearchPublishPoint', undefined, this.model.get('query'));
    },
    
    backToSearchHistory: function(event) {
    	Chaplin.mediator.publish('backToSearchHistory');
    }
});