var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return ": "
    + escapeExpression(((helper = (helper = helpers.quickSearchName || (depth0 != null ? depth0.quickSearchName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"quickSearchName","hash":{},"data":data}) : helper)));
},"3":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <button type=\"button\" class=\"btn btn-default\" id=\"storesearchasnew\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "store.search.as.new", {"name":"lbl","hash":{},"data":data})))
    + "</button>\n            <button type=\"button\" class=\"btn btn-default\" id=\"backtosearchhistory\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "backto.searchhistory", {"name":"lbl","hash":{},"data":data})))
    + "</button>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "<h3>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "search.quick", {"name":"lbl","hash":{},"data":data})))
    + "</h3>\n<div id=\"alertBox\"></div>\n\n<fieldset class=\"quicksearch\">\n    <legend>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "search.quick.legend", {"name":"lbl","hash":{},"data":data})));
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.quickSearchName : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</legend>\n    <div class=\"form-group\">\n        <label for=\""
    + escapeExpression(((helper = (helper = helpers.quickSearchInputId || (depth0 != null ? depth0.quickSearchInputId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"quickSearchInputId","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "search.quick.label", {"name":"lbl","hash":{},"data":data})))
    + "</label>\n        <input type=\"text\" id=\""
    + escapeExpression(((helper = (helper = helpers.quickSearchInputId || (depth0 != null ? depth0.quickSearchInputId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"quickSearchInputId","hash":{},"data":data}) : helper)))
    + "\" name=\""
    + escapeExpression(((helper = (helper = helpers.quickSearchInputId || (depth0 != null ? depth0.quickSearchInputId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"quickSearchInputId","hash":{},"data":data}) : helper)))
    + "\"></input>\n    </div>\n    <div class=\"form-group form-actions\">\n        <button type=\"button\" class=\"btn btn-primary\" id=\"startquicksearch\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "start.search", {"name":"lbl","hash":{},"data":data})))
    + "</button>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.quickSearchName : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\n</fieldset>";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}