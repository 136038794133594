var Model = require('./model_with_auth');

// join Model, Backbone.NestedModel and our stuff together
var Product = Model.extend(
{
    urlRoot: apiRoot + "product",
    modelKey: "product",

    initialize: function (options) {
      if(options.urlRoot !== undefined) {
          this.urlRoot = options.urlRoot;
      }
    },

    defaults: {
        "id": null
    },
    
    fetch: function (options) {

    	var theOptions = options ? options : {};
        theOptions['async'] = false;
        theOptions['cache'] = false;
        return Model.prototype.fetch.call(this, theOptions);
    }

});


module.exports = Product;