var View = require('./view')
var UpsellingTemplate = require('./templates/forms/upselling');
var MsgHelper = require('lib/msg_helper');

var UpsellingView;

module.exports = UpsellingView = View.extend({

    LOGGER: log4javascript.getLogger('views.UpsellingView'),

    container: '#fieldSet_upselling',
    autoRender: true,
    autoAttach: false,
    className: 'upsellingView',


    initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);
    },

    getTemplateData: function () {
        var data = View.prototype.getTemplateData.call(this);

        data.src = mediaRoot + '/images/upselling.png';
        data.alt = this.model.get('title');
        data.href = MsgHelper.getMessage('label.form.product.block.upselling.href');
        data.body = new Handlebars.SafeString(MsgHelper.getMessage('label.form.product.block.upselling.body'));

        return data;
    },
    getTemplateFunction: function () {
        return UpsellingTemplate;
    },
    render: function () {
        View.prototype.render.call(this);
    },
    attach: function () {
        View.prototype.attach.call(this);
    }
});