var Model = require('./model');
var MsgHelper = require('lib/msg_helper');

var logger = log4javascript.getLogger('views.FormDefinition');

var FormDefinition = Model.extend({
    urlRoot: applicationRoot + "/form",
    defaults: {
        "id": null
    },

    fetch: function (options) {
        var theOptions = {};        
        theOptions['success'] = function (model, response, options) {        	        	        	

            if (model.attributes.mode.toLowerCase() !== 'show') {
                model.attributes.isEditable = true;
            } 
        	
    		// only fetch this model if the user has the right to
    		Chaplin.mediator.publish('form_def_loaded', model);    		

        };
        theOptions['error'] = function (model, response, options) {
            logger.info("Model: " + JSON.stringify(model));
            logger.info("Response: " + JSON.stringify(response));
            logger.info("Options: " + JSON.stringify(options));
        };

        theOptions['cache'] = false;

        Model.prototype.fetch.call(this, theOptions);

    },
    url: function() {
        var base =
            _.result(this, 'urlRoot') ||
                _.result(this.collection, 'url') ||
                urlError();
        if (this.isNew()) return base;
        return base.replace(/([^\/])$/, '$1/') + this.id;
    },
});

module.exports = FormDefinition;