var Application = require('application');
var ProductSearchResultView = require('views/product_search_result_view');
var MsgHelper = require('lib/msg_helper');
var MiscHelper = require('lib/misc_helper');
var XMLHelper = require('lib/xml_helper');
var DownloadHelper = require('lib/download_helper');

var ExportView = require('views/export_view');

var batchData;
var codelists;

var logger = log4javascript.getLogger('views.BatchSearchResultView');

module.exports = BatchSearchResultView = ProductSearchResultView.extend({

	tagName : 'div',
	region : 'content',
	containerMethod : 'html',

	initialize : function(options) {
        ProductSearchResultView.prototype.initialize.apply(this, [options]);
	},

	formDefinitionLoaded : function(loadedFormDef) {
        ProductSearchResultView.prototype.formDefinitionLoaded.apply(this, [loadedFormDef]);
	},

    getDatatablesRequestHttpType : function() {
    	return 'POST';
    },
    
    getDatatablesRequestData : function(paramMap) {
    	var requestData = ProductSearchResultView.prototype.getDatatablesRequestData.call(this, paramMap);
    	requestData.batch = MiscHelper.stripDatabaseFilterFromQuery(paramMap["listViewModel"].get('query'));
		requestData.search = paramMap["listViewModel"].get('query');

    	return requestData;
    }
    
});

