var Model = require('./model');
var MsgHelper = require('lib/msg_helper');

var logger = log4javascript.getLogger('views.PageContentDefinition');

var PageContentDefinition = Model.extend({
    urlRoot: applicationRoot + "/page",
    defaults: {
        "id": null
    },

    fetch: function (options) {
        var theOptions = {};        
        theOptions['error'] = function (model, response, options) {
            logger.info("Model: " + JSON.stringify(model));
            logger.info("Response: " + JSON.stringify(response));
            logger.info("Options: " + JSON.stringify(options));
        };

        theOptions['cache'] = false;
        theOptions['async'] = false;

        Model.prototype.fetch.call(this, theOptions);

    },
    url: function() {
        var base =
            _.result(this, 'urlRoot') ||
                _.result(this.collection, 'url') ||
                urlError();
        if (this.isNew()) return base;
        return base.replace(/([^\/])$/, '$1/') + this.id;
    },
});

module.exports = PageContentDefinition;