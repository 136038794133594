var MiscHelper = require('lib/misc_helper');
var DateHelper = function () {
};

/**
 * Converts a date/time/timestamp string to a date/time/timestamp string in consideration of a from format and a to format.
 *
 * If a format is not given a default format is taken for the to format and the given value ist parsed trying to find out
 * which format is used.
 *
 * @param value
 * @param aFromFormat optional
 * @param aToFormat optional
 * @returns {*}
 */
DateHelper.prototype.convert = function (value, aFromFormat, aToFormat) {

    if (MiscHelper.isEmpty(value)) {
        return null;
    }

    var retVal = value;
    var toFormat = aToFormat;
    var fromFormat = aFromFormat;
    var dateObj;

    if (MiscHelper.isEmpty(fromFormat) && MiscHelper.isEmpty(toFormat) && typeof value == 'string') {
        // assumption: format is onix.
        switch (value.length) {
            case 4:
                toFormat = 'yyyy';
                fromFormat = 'yyyy';
                break;
            case 6:
                toFormat = formatDateMonthYear;
                fromFormat = 'yyyyMM';
                break;
            case 8:
                toFormat = formatDateDayMonthYear;
                fromFormat = 'yyyyMMdd';
                break;
        }
    }

    if (typeof value == 'string') {
        if ('Ab sofort' === value) {
            dateObj = new Date();
        } else {
            if (MiscHelper.isEmpty(fromFormat)) {
                dateObj = this.parseToDateObject(value)
            } else {
                switch (fromFormat) {
                    case 'TIMESTAMP':
                        fromFormat = formatTimeStamp;
                        break;
                    case 'DATE' :
                        fromFormat = formatDateDayMonthYear;
                        break;
                    default :
                    // fromFormat = fromFormat;
                }
                dateObj = this.getDateFromFormat(value, fromFormat);
            }
        }
    } else {
        dateObj = value;
    }

    switch (toFormat) {
        case 'TIMESTAMP':
            toFormat = formatTimeStamp;
            break;
        case 'DATE':
            toFormat = formatDateDayMonthYear;
            break;
        default :
        // toFormat = aToFormat;
    }
    retVal = this.formatDate(dateObj, toFormat);
    if (retVal === null) {
        retVal = value;
    }

    return retVal;
};

/**
 * From Date-Object to String
 *
 * @param date Date-Object
 * @param format
 * @returns {XML|string|*}
 */
DateHelper.prototype.formatDate = function (date, format) {

    if (date == null || !date instanceof Date || typeof date.getUTCFullYear !== 'function') {
        return null
    }

    format = format || formatDateDayMonthYear;

    // Set utc to false so user's local input Date is used instead of the Date in UTC format
    // Caused a bug in #27701 where the user was in a different timezone than 'de'
    var utc = false;

    var MMMM = ["\x00", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var MMM = ["\x01", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var dddd = ["\x02", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var ddd = ["\x03", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    function ii(i, len) {
        var s = i + "";
        len = len || 2;
        while (s.length < len) s = "0" + s;
        return s;
    }

    var y = utc ? date.getUTCFullYear() : date.getFullYear();
    format = format.replace(/(^|[^\\])yyyy+/g, "$1" + y);
    format = format.replace(/(^|[^\\])yy/g, "$1" + y.toString().substr(2, 2));
    format = format.replace(/(^|[^\\])y/g, "$1" + y);

    var M = (utc ? date.getUTCMonth() : date.getMonth()) + 1;
    format = format.replace(/(^|[^\\])MMMM+/g, "$1" + MMMM[0]);
    format = format.replace(/(^|[^\\])MMM/g, "$1" + MMM[0]);
    format = format.replace(/(^|[^\\])MM/g, "$1" + ii(M));
    format = format.replace(/(^|[^\\])M/g, "$1" + M);

    var d = utc ? date.getUTCDate() : date.getDate();
    format = format.replace(/(^|[^\\])dddd+/g, "$1" + dddd[0]);
    format = format.replace(/(^|[^\\])ddd/g, "$1" + ddd[0]);
    format = format.replace(/(^|[^\\])dd/g, "$1" + ii(d));
    format = format.replace(/(^|[^\\])d/g, "$1" + d);

    var H = utc ? date.getUTCHours() : date.getHours();
    format = format.replace(/(^|[^\\])HH+/g, "$1" + ii(H));
    format = format.replace(/(^|[^\\])H/g, "$1" + H);

    var h = H > 12 ? H - 12 : H == 0 ? 12 : H;
    format = format.replace(/(^|[^\\])hh+/g, "$1" + ii(h));
    format = format.replace(/(^|[^\\])h/g, "$1" + h);

    var m = utc ? date.getUTCMinutes() : date.getMinutes();
    format = format.replace(/(^|[^\\])mm+/g, "$1" + ii(m));
    format = format.replace(/(^|[^\\])m/g, "$1" + m);

    var s = utc ? date.getUTCSeconds() : date.getSeconds();
    format = format.replace(/(^|[^\\])ss+/g, "$1" + ii(s));
    format = format.replace(/(^|[^\\])s/g, "$1" + s);

    var f = utc ? date.getUTCMilliseconds() : date.getMilliseconds();
    format = format.replace(/(^|[^\\])fff+/g, "$1" + ii(f, 3));
    f = Math.round(f / 10);
    format = format.replace(/(^|[^\\])ff/g, "$1" + ii(f));
    f = Math.round(f / 10);
    format = format.replace(/(^|[^\\])f/g, "$1" + f);

    var T = H < 12 ? "AM" : "PM";
    format = format.replace(/(^|[^\\])TT+/g, "$1" + T);
    format = format.replace(/(^|[^\\])T/g, "$1" + T.charAt(0));

    var t = T.toLowerCase();
    format = format.replace(/(^|[^\\])tt+/g, "$1" + t);
    format = format.replace(/(^|[^\\])t/g, "$1" + t.charAt(0));

    var tz = -date.getTimezoneOffset();
    var K = utc || !tz ? "Z" : tz > 0 ? "+" : "-";
    if (!utc) {
        tz = Math.abs(tz);
        var tzHrs = Math.floor(tz / 60);
        var tzMin = tz % 60;
        K += ii(tzHrs) + ":" + ii(tzMin);
    }
    format = format.replace(/(^|[^\\])K/g, "$1" + K);

    var day = (utc ? date.getUTCDay() : date.getDay()) + 1;
    format = format.replace(new RegExp(dddd[0], "g"), dddd[day]);
    format = format.replace(new RegExp(ddd[0], "g"), ddd[day]);

    format = format.replace(new RegExp(MMMM[0], "g"), MMMM[M]);
    format = format.replace(new RegExp(MMM[0], "g"), MMM[M]);

    format = format.replace(/\\(.)/g, "$1");

    return format;
};

// ------------------------------------------------------------------
// getDateFromFormat( date_string , format_string )
//
// This function takes a date string and a format string. It matches
// If the date string matches the format string, it returns the
// getTime() of the date. If it does not match, it returns 0.
// ------------------------------------------------------------------
DateHelper.prototype.getDateFromFormat = function (val, format) {
    val = val + "";
    format = format || formatDateDayMonthYear;
    var i_val = 0;
    var i_format = 0;
    var c = "";
    var token = "";
    var token2 = "";
    var x, y;
    var now = new Date();
    var year = now.getYear();
    var month = now.getMonth() + 1;
    var date = 1;
    var hh = now.getHours();
    var mm = now.getMinutes();
    var ss = now.getSeconds();
    var ampm = "";

    while (i_format < format.length) {
        // Get next token from format string
        c = format.charAt(i_format);
        token = "";
        while ((format.charAt(i_format) == c) && (i_format < format.length)) {
            token += format.charAt(i_format++);
        }
        // Extract contents of value based on format token
        if (token == "yyyy" || token == "yy" || token == "y") {
            if (token == "yyyy") {
                x = 4;
                y = 4;
            }
            if (token == "yy") {
                x = 2;
                y = 2;
            }
            if (token == "y") {
                x = 2;
                y = 4;
            }
            year = _getInt(val, i_val, x, y);
            if (year == null) {
                return 0;
            }
            i_val += year.length;
            if (year.length == 2) {
                if (year > 70) {
                    year = 1900 + (year - 0);
                }
                else {
                    year = 2000 + (year - 0);
                }
            }
        } else if (token == "MMM" || token == "NNN") {
            month = 0;
            for (var i = 0; i < MONTH_NAMES.length; i++) {
                var month_name = MONTH_NAMES[i];
                if (val.substring(i_val, i_val + month_name.length).toLowerCase() == month_name.toLowerCase()) {
                    if (token == "MMM" || (token == "NNN" && i > 11)) {
                        month = i + 1;
                        if (month > 12) {
                            month -= 12;
                        }
                        i_val += month_name.length;
                        break;
                    }
                }
            }
            if ((month < 1) || (month > 12)) {
                return 0;
            }
        } else if (token == "EE" || token == "E") {
            for (var i = 0; i < DAY_NAMES.length; i++) {
                var day_name = DAY_NAMES[i];
                if (val.substring(i_val, i_val + day_name.length).toLowerCase() == day_name.toLowerCase()) {
                    i_val += day_name.length;
                    break;
                }
            }
        } else if (token == "MM" || token == "M") {
            month = _getInt(val, i_val, token.length, 2);
            if (month == null || (month < 1) || (month > 12)) {
                return 0;
            }
            i_val += month.length;
        } else if (token == "dd" || token == "d") {
            date = _getInt(val, i_val, token.length, 2);
            if (date == null || (date < 1) || (date > 31)) {
                return 0;
            }
            i_val += date.length;
        }
        else if (token == "hh" || token == "h") {
            hh = _getInt(val, i_val, token.length, 2);
            if (hh == null || (hh < 1) || (hh > 12)) {
                return 0;
            }
            i_val += hh.length;
        }
        else if (token == "HH" || token == "H") {
            hh = _getInt(val, i_val, token.length, 2);
            if (hh == null || (hh < 0) || (hh > 23)) {
                return 0;
            }
            i_val += hh.length;
        } else if (token == "KK" || token == "K") {
            hh = _getInt(val, i_val, token.length, 2);
            if (hh == null || (hh < 0) || (hh > 11)) {
                return 0;
            }
            i_val += hh.length;
        } else if (token == "kk" || token == "k") {
            hh = _getInt(val, i_val, token.length, 2);
            if (hh == null || (hh < 1) || (hh > 24)) {
                return 0;
            }
            i_val += hh.length;
            hh--;
        } else if (token == "mm" || token == "m") {
            mm = _getInt(val, i_val, token.length, 2);
            if (mm == null || (mm < 0) || (mm > 59)) {
                return 0;
            }
            i_val += mm.length;
        } else if (token == "ss" || token == "s") {
            ss = _getInt(val, i_val, token.length, 2);
            if (ss == null || (ss < 0) || (ss > 59)) {
                return 0;
            }
            i_val += ss.length;
        } else if (token == "a") {
            if (val.substring(i_val, i_val + 2).toLowerCase() == "am") {
                ampm = "AM";
            }
            else if (val.substring(i_val, i_val + 2).toLowerCase() == "pm") {
                ampm = "PM";
            }
            else {
                return 0;
            }
            i_val += 2;
        } else {
            if (val.substring(i_val, i_val + token.length) != token) {
                return 0;
            }
            else {
                i_val += token.length;
            }
        }
    }
    // If there are any trailing characters left in the value, it doesn't match
    if (i_val != val.length) {
        return 0;
    }
    // Is date valid for month?
    if (month == 2) {
        // Check for leap year
        if (( (year % 4 == 0) && (year % 100 != 0) ) || (year % 400 == 0)) { // leap year
            if (date > 29) {
                return 0;
            }
        } else {
            if (date > 28) {
                return 0;
            }
        }
    }
    if ((month == 4) || (month == 6) || (month == 9) || (month == 11)) {
        if (date > 30) {
            return 0;
        }
    }
    // Correct hours value
    if (hh < 12 && ampm == "PM") {
        hh = hh - 0 + 12;
    } else if (hh > 11 && ampm == "AM") {
        hh -= 12;
    }
    var newdate = new Date(year, month - 1, date, hh, mm, ss);

    return newdate;
};

// ------------------------------------------------------------------
// Utility functions for parsing in getDateFromFormat()
// ------------------------------------------------------------------
function _isInteger(val) {
    var digits = "1234567890";
    for (var i = 0; i < val.length; i++) {
        if (digits.indexOf(val.charAt(i)) == -1) {
            return false;
        }
    }
    return true;
}

function _getInt(str, i, minlength, maxlength) {
    for (var x = maxlength; x >= minlength; x--) {
        var token = str.substring(i, i + x);
        if (token.length < minlength) {
            return null;
        }
        if (_isInteger(token)) {
            return token;
        }
    }
    return null;
}

/**
 * This is the formerly called "Rate mal mit Rosenthal" function which tries to find out which format a date string has
 * to be able to generate a Date-Object from the given string value.
 *
 * @param value
 * @returns {*}
 */
DateHelper.prototype.parseToDateObject = function (aValue) {
    if (MiscHelper.isEmpty(aValue)) {
        return null;
    }

    // remove any additional blanks.
    var parts = aValue.split('.');
    var value = parts[0].trim();
    for (var i = 1; i < parts.length; i++) {
        value = value + '.' + parts[i].trim();
    }
    parts = aValue.split(':');
    value = parts[0].trim();
    for (var i = 1; i < parts.length; i++) {
        value = value + ':' + parts[i].trim();
    }

    // add 19th century to the year if there are only two digits.
    parts = value.split('.');
    if (parts.length === 3 && parts[2].length === 2) {
        value = parts[0] + '.' + parts[1] + '.' + '19' + parts[2];
    }
    if (!validTimeStampCharacters.test(value)) {
        return null;
    }

    var dateString = '';
    var timeString = '';

    switch (value.length) {
        case 4: // year
            dateString = value;
            break;
        case 6: // year + month
            dateString = value.substr(0, 4) + '-' + value.substr(4, 2);
            break;
        case 7: // month.year
            dateString = value.substr(3, 4) + '-' + value.substr(0, 2);
            break;
        case 8: // year + month + day
            dateString = value.substr(0, 4) + '-' + value.substr(4, 2) + '-' + value.substr(6);
            break;
        case 10: // dd.MM.yyyy => year + month + date
            dateString = value.substr(6, 4) + '-' + value.substr(3, 2) + '-' + value.substr(0, 2);
            break;
        case 19: // dd.MM.yyyy HH:mm:ss => year + month + date currently we
            // display no time information
            dateString = value.substr(6, 4) + '-' + value.substr(3, 2) + '-' + value.substr(0, 2);
            timeString = value.substr(11, 8);
            return new Date(dateString + "T" + timeString + "Z");
        case 22:
        case 23:
        case 24:
        case 25: // yyyy-MM-dd'T'HH:mm:ss.S'Z' => year + month + date
            // don't know what now happens is good for. First seperate an UTC-String and second put these parts together again. :-(
            dateString = value.substr(0, 4) + '-' + value.substr(5, 2) + '-' + value.substr(8, 2);
            // time information
            timeString = value.substr(11, 2) + ':' + value.substr(14, 2) + ':' + value.substr(17, 2);

            return new Date(dateString + "T" + timeString + "Z");
    }

    var format = 'yyyy-mm-dd';
    var parts = dateString.match(/(\d+)/g),
        i = 0, fmt = {};
    // extract date-part indexes from the format
    format.replace(/(yyyy|dd|mm)/g, function (part) {
        fmt[part] = i++;
    });

    var retVal;

    if (MiscHelper.isEmpty(parts)) {
        retVal = null;
    } else if (parts.length === 3) {
        retVal = new Date(parts[fmt['yyyy']], parts[fmt['mm']] - 1, parts[fmt['dd']]);
    } else if (parts.length === 2) {
        retVal = new Date(parts[fmt['yyyy']], parts[fmt['mm']] - 1, 1);
    } else if (parts.length === 1) {
        retVal = new Date(parts[fmt['yyyy']]);
    }

    if (retVal != null && retVal.getTime() % 1000000 === 0) {
        // Browsers have a different interpretation about the date if midnight is the time. So I add some millis in
        // that case to make the date clear.
        retVal.setMilliseconds(100);
    }
    return retVal;
};

DateHelper.prototype.formatNumber = function (val) {
    if (val < 10) {
        val = '0' + val;
    }
    return val;
};

module.exports = new DateHelper;