var View = require('./view');
var TeaserViewTemplate = require('./templates/teaser');

module.exports = TeaserView = View.extend({

    className: 'teaser-view',
    tagName: 'div',
    containerMethod: 'html',
    enableEvents: false,
    useNod: false,

    initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);
    },

    getTemplateFunction: function () {
        return TeaserViewTemplate;
    },

    getTemplateData: function () {
        var data = View.prototype.getTemplateData.call(this);

        data.ident = 'teaserIdent';
        data.imgSrc = mediaRoot + '/images/teaser/VLB_Banner_elektronische-Rechnung_760x100px_RZ.png';
        data.link = 'https://mvb-online.de/ueber-uns/service';

        data.target = 'blank';

        return data;
    }

});