var MiscHelper = require('lib/misc_helper');
var WidgetTemplate = require('views/templates/forms/widgets/base');

// Base class for all widgets

var BaseWidget = function () {
    this.initialize.apply(this, arguments);
};

BaseWidget.prototype = {
    initialize: function (widget, modelKey, parentProperty) {
        this.widget = widget;
        this.modelKey = modelKey;
        this.parentProperty = parentProperty;
        for (attr in widget) {
            this[attr] = widget[attr];
        }
    },

    render: function (model) {
        if (this.showOn !== undefined) {
            if (MiscHelper.isEmpty(this.style)) {
                this.style = "";
            } else {
                this.style = this.style.replace(/hidden/g, '').trim();
            }
            if (!MiscHelper.compareComplex(model.attributes, this.showOn)) {
                this.style = this.style + ' hidden';
            }
        }
        if (this.msgKey === 'form.product.fieldset.prices.starinfo') {
            var retailPrices = model.get('retailPrices');
            for (var i = 0; i < retailPrices.length; i++) {
                if (retailPrices[i] !== null && retailPrices[i].calculated === true) {
                    return WidgetTemplate(this);
                }
            }
            return '';
        } else if (this.msgKey === 'form.product.fieldset.prices.plusinfo') {
            var retailPrices = model.get('retailPrices');
            for (var i = 0; i < retailPrices.length; i++) {
                if (retailPrices[i] !== null && !MiscHelper.isEmpty(retailPrices[i].typeDescription)
					&& retailPrices[i].typeDescription.indexOf('0% MwSt.-Angabe vom Verlag') > -1) {
                    return WidgetTemplate(this);
                }
            }
            return '';
        } else {
            return WidgetTemplate(this);
        }
    }

};

BaseWidget.extend = Backbone.Model.extend;

module.exports = BaseWidget;
