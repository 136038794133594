var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div id=\"collectionEditHeader\">\n\n    <div class=\"starLegend\">\n        <span class=\"mandetory\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "field.mandetory", {"name":"lbl","hash":{},"data":data})))
    + "</span>\n        <div class=\"help-icons add-icons\">\n            <a class=\"txt-link txt-link-help\" href=\""
    + escapeExpression(((helpers.msg || (depth0 && depth0.msg) || helperMissing).call(depth0, "help.link.collectionEdit", {"name":"msg","hash":{},"data":data})))
    + "\" target=\"_blank\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "help", {"name":"lbl","hash":{},"data":data})))
    + "</a>\n            <a href=\""
    + escapeExpression(((helpers.msg || (depth0 && depth0.msg) || helperMissing).call(depth0, "help.link.collectionEdit", {"name":"msg","hash":{},"data":data})))
    + "\" target=\"_blank\" class=\"vlb-action-icon vlb-action-icon-help\"\n               title=\""
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "help", {"name":"lbl","hash":{},"data":data})))
    + "\"><span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "help", {"name":"lbl","hash":{},"data":data})))
    + "</span></a>\n        </div>\n    </div>\n\n    "
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.block.collectionEditHeader.msg", {"name":"lbl","hash":{},"data":data})))
    + "\n\n</div>";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}