var MsgHelper = require('lib/msg_helper');
var View = require('./view')
var SummaryTemplate = require('./templates/forms/collection_summary');
var FormHelper = require('lib/form_helper');

var CollectionSummaryView;

module.exports = CollectionSummaryView =  View.extend({

    LOGGER : log4javascript.getLogger('views.ProductSummaryView'),

    container: '#fieldSet_collection_summary',
    autoRender: true,
    autoAttach: false,
    className: 'collectionSummary',
    codelists:{},


    initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);
    },

    getTemplateData: function () {
        var data = View.prototype.getTemplateData.call(this);
        data.labelPrefix = 'form.product.block.productHeader';
        data.renderSourceHint = ['5109037','5001015','70601'].indexOf(data.model.aggregatorId) > -1; // for Umbreit, ÖSB, SBZ

        // publisherData
        if(data.model.publisherData){
            if(data.model.publisherData.postalAddress){
                var address = this.ab(data.model.publisherData.postalAddress.street1);
                address += this.ab(data.model.publisherData.postalAddress.street2);
                address += this.aw(data.model.publisherData.postalAddress.country);
                address += this.aw(data.model.publisherData.postalAddress.zipStreet);
                address += this.aw(data.model.publisherData.postalAddress.cityStreet);
                data.postalAddress = address;
            }
            if(data.model.publisherData.contacts){
                var teltel = '', tel = '', fax = '', email = '', url = '', contactData = '';
                for(var i = 0; i < data.model.publisherData.contacts.length; i++){
                    var type = data.model.publisherData.contacts[i].type;
                    var label = MsgHelper.getMessage('label.publisher.contact.type.' + type);
                    var value = data.model.publisherData.contacts[i].value;
                    if(type == 'TELEPHONE'){
                        if(data.model.publisherData.contacts[i].purpose == 'TELEPHONE' && !teltel){
                            teltel =  label + ' ' + value;
                        } else if(!tel){
                            tel = label + ' ' + value;
                        }
                    }
                    if(type == 'FAX' && !fax){
                        fax = label + ' ' + value;
                    }
                    if(type == 'EMAIL' && !email){
                        email = label + ' <a href="mailto:' + value + '">'+value+'</a>';
                    }
                    if(type == 'URL' && !url){
                        url = label + ' <a target="_blank" href="http://' + value + '">'+value+'</a>';
                    }
                }
                contactData += this.ab(teltel ? teltel : tel);
                contactData += this.ab(fax);
                contactData += this.ab(email);
                contactData += this.ab(url);
                data.contactData = contactData;
            }

            data.publisherName = data.model.publisherData.shortNameVLB;
            if (versionCountry === 'latam') {
                // for mexico the name of the imprint holder is relevant
                if (data.model.publisherData.imprintHolder && data.model.publisherData.companyName) {
                    data.publisherName = data.model.publisherData.companyName + ' - ' + data.publisherName;
                }
            }

            if(data.model.publisherData.mvbId){
                data.mvbId = '<strong> ' + MsgHelper.getMessage('label.form.product.field.mvbId') + '</strong> ' + data.model.publisherData.mvbId;
            }

            data.deliveries = [];
            if(data.model.publisherData) {
                if(data.model.publisherData.sigelDE)
                    data.deliveries.push(data.model.publisherData.sigelDE.replace(/^\//,""));
                if(data.model.publisherData.sigelAT)
                    data.deliveries.push(data.model.publisherData.sigelAT.replace(/^\//,""));
                if(data.model.publisherData.sigelCH)
                    data.deliveries.push(data.model.publisherData.sigelCH.replace(/^\//,""));
            }
            data.deliveries = data.deliveries.join(" ");
            if(data.deliveries){
                data.deliveries = '<strong> ' + MsgHelper.getMessage('label.publisher.deliverer') + '</strong> ' + data.deliveries;
            }

            if(data.model.publisherData.deliveryNote){
                data.deliveryNote = '';
                if(data.model.publisherData.deliveryNote.indexOf("e") > -1){
                    data.deliveryNote += this.ab(MsgHelper.getMessage('label.publisher.deliveryNoteE'));
                }
                if(data.model.publisherData.deliveryNote.indexOf("r") > -1){
                    data.deliveryNote += this.ab(MsgHelper.getMessage('label.publisher.deliveryNoteR'));
                }
                if(data.model.publisherData.deliveryNote.indexOf("n") > -1){
                    data.deliveryNote += this.ab(MsgHelper.getMessage('label.publisher.deliveryNoteN'));
                }
                if(data.deliveryNote){
                    data.deliveryNote =  '<strong>' + this.ab(MsgHelper.getMessage('label.publisher.deliveryNote'))+ '</strong>' + data.deliveryNote;
                }
            }
        }

        data.editButtonRole = data.model.publisherForMvbId && data.model.type !== 'multiBundle' ? 'MODIFY_PRODUCT' : 'doNotRenderButtonsHack';

        return data;
    },

    ab: function(text){
        // append br if not empty else empty string
        return text ? text + '<br/>' : '';
    },

    aw:function(text){
        // append whitespace if not empty else empty string
        return text ? text + ' ' : '';
    },

    getTemplateFunction: function () {
        return SummaryTemplate;
    },

    render: function () {
        View.prototype.render.call(this);
    },

    attach: function() {
        View.prototype.attach.call(this);

        $('[data-show-menu="true"]').click(function () {
            var _menu = $(this).parent().find('.settings-menu');
            $('.settings-menu').filter(function (a, b) {
                return _menu.get(0) != b;
            }).hide();
            _menu.toggle();
            return false;
        });

        $('fieldset#fieldSet_title span#titleShortVersion').attr('title', this.model.get('title'));
        $('fieldset#fieldSet_title span#subtitleShortVersion').attr('title', this.model.get('subtitle'));
    }
});