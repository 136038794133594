var View = require('./view'),
	template = require('./templates/globalerror'),
	GlobalErrorModel = require('models/globalError');

module.exports = View.extend({

	id: 'globalError',
    tagName: 'div',
    className: 'globalError',
    region: 'alertify',
    template: template,

    getTemplateData: function () {
        var data = {};

        this.logger = log4javascript.getLogger('views.GlobalErrorView');

        if (this.model) {
        	data.ajaxUrl = this.model.get('ajaxUrl');
        	data.statusCode = this.model.get('statusCode');
        	data.errorMessage = this.model.get('errorMessage');
        }

        this.logger.error(this.model);

        return data;
    }
});