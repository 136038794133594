var Application = require('application');
var View = require('./view');
var CreateProductTemplate = require('./templates/form');
var MsgHelper = require('lib/msg_helper');
var PriceHelper = require('lib/price_helper');
var MiscHelper = require('lib/misc_helper');
var FormHelper = require('lib/form_helper');
var ActionHelper = require('lib/action_helper');
var FormBlock = require('./form_block_view');
var SelectMediafilesFormView = require('views/select_mediafiles_form_view');
var ConfirmationHelper = require('lib/confirmation_helper');
var ConfirmationAcknowledge = require('views/templates/forms/confirmationAcknowledge');
var ThemaView = require('./thema_view');
var BisacView = require('./bisac_view');
var AjaxSpinner = require('lib/ajaxspinner_helper');
var HistoryHelper = require('lib/history_helper');
var PageContentModel = require('models/pagecontent');
var DateHelper = require('lib/date_helper');

var FormView;

var Logger = log4javascript.getLogger('views.FormView');
var eurozone = ['AD', 'AT', 'BE', 'CY', 'EE', 'FI', 'FR', 'DE', 'ES', 'GR', 'IE', 'IT', 'LT', 'LU', 'LV', 'MC', 'ME', 'MT', 'NL', 'PT', 'SI', 'SK', 'SM', 'VA'];

module.exports = FormView = View.extend({

    // id: 'form-view',
    tagName: "form",
    className: "form-horizontal",
    region: 'content',
    containerMethod: 'html',
    enableEvents: true,
    useNod: true,
    lastError: -1,

    initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);

        var action = options.model.get('action');

        if (action !== 'startQuickSearch' && action !== 'startAdvancedSearch') {
            $('.quick-search #globalQuickSearch').val('');
        }

        if (!this.options.modelKey) {
            this.options.modelKey = this.model.modelKey;
        }

        if (this.options.showHeader === undefined) {
            this.options.showHeader = true;
        }

        if (options.routeParams !== undefined && options.routeParams.iTotalRecords !== undefined) {
            this.iTotalRecords = options.routeParams.iTotalRecords;
        }

        this.model.set('routeParams', options['routeParams']);
    },

    getTemplateFunction: function () {
        return CreateProductTemplate;
    },

    getTemplateData: function () {
        var data = View.prototype.getTemplateData.call(this);
        data.dealWithNavTitle = MsgHelper.getMessage('label.' + this.options.modelKey + '.form.dealWith.navTitle');
        var type = this.model.get('type');
        if (type === 'duoBundle') {
            this.model.set('duoBundles', this.model.get('bundles'));
            this.model.set('multiBundles', []);
        } else if (type === 'multiBundle') {
            this.model.set('multiBundles', this.model.get('bundles'));
            this.model.set('duoBundles', []);
        } else if (type !== undefined) {
            this.model.set('multiBundles', []);
            this.model.set('duoBundles', this.model.get('bundles'));
        }
        data.formDef = this.options.formDef.attributes;
        data.showHeader = data.formDef.displayHeader == 'true';
        if (this.id === 'product_show' || this.id === 'collection_show') {
            if (this.options.routeParams.clickedInList === undefined
                || !_.string.startsWith(this.options.routeParams.clickedInList, 'productTracker')) {
                var regEx = new RegExp('^\\d+$');
                if ((typeof this.options.routeParams.record).toLowerCase() === 'number'
                    || regEx.test(this.options.routeParams.record)) {
                    data.usePaginator = true;
                    this.options.usePaginator = true;
                }
            }
        }
        return data;
    },

    listen: {
        'form_def_loaded mediator': 'formDefinitionLoaded'
    },

    blockLoaded: function (block) {
        this.blocksToLoad -= 1;

        if (MiscHelper.isEmpty(block)) {
            return;
        }

        if (block.showOn !== undefined && !MiscHelper.compareComplex(this.model.attributes, block.showOn)) {
            $('#block_' + block.blockId).addClass('hidden');
        }

        // remove mandatory and statusrelevent classes for zis product blocks
        if (this.model.get('type') === "journal") {
            // remove the class statusrelevant
            if (block.blockId === 'title' ||
                block.blockId === 'contributors' ||
                block.blockId === 'availability' ||
                block.blockId === 'contributor' ||
                block.blockId === 'productclassification' ||
                block.blockId === 'additionalInformation') {

                $('#block_' + block.blockId).removeClass('statusrelevent');
                // I know it is a nasty hack here but i could not find another way of removing the statusrelevant class from fieldsets or selections or else
                $('.statusrelevent').removeClass('statusrelevent');
            }

            // remove the class mandatory
            if (block.blockId === 'availability') {

                $('#block_' + block.blockId).removeClass('mandetory');
            }
        }

        if (this.blocksToLoad === 0) {
            //all loaded

            if (this.options.formDef.attributes.model === 'product') {
                $('#type').trigger('change');
            }

            //TODO find out if we need to rebind it also after adding/removing widgets
            if (this.options.formDef.attributes.isEditable && this.useNod) {
                if (this.options.formDef.attributes.validateOnServer) {
                    this.delegate('change', 'input', this.validateOnServer);
                    this.delegate('change', 'input', this.cleanInputValues);
                    this.delegate('change', 'textarea', this.validateOnServer);
                    this.delegate('change', 'textarea', this.cleanInputValues);
                } else {
                    // bind nod
                    if (this.validations && this.validations.length > 0) {
                        $("#" + this.id).nod(this.validations, {delay: false});
                        Chaplin.mediator.subscribe('rebind_model', this.rebind_validator, this);
                    }
                }
            }

            // its form wide, so we should only need it once - viewWidgets handle their actions on their own
            // reduce this event handler to links in the blocks. So other links outside the blocks are not affected.
            this.delegate('click', '[id^="block"] [data-toggle="confirmationSingle"]:not(.viewWidget [data-toggle="confirmationSingle"])', this.confirmAction);
            // do not forget the action buttons on every page.
            this.delegate('click', '.form-actions [data-toggle="confirmationSingle"]', this.confirmAction);
            // init custom actions if available
            if (this.model.initActions) {
                this.model.initActions();
            }
            if (this.model.attributes && this.model.attributes.sendCredentials) {
                var formBlockView = new FormBlock({
                    model: this.model,
                    formDef: this.options.formDef,
                    parentView: this,
                    showTitle: false
                });
                formBlockView.sendCredentials();
            }

            //change handling of return and backspace keys
            $(document).unbind('keydown').bind('keydown', function (event) {
                var doPrevent = false;
                // 8 = backspace, 13=returns
                if (event.keyCode === 8 || event.keyCode === 13) {
                    var d = event.srcElement || event.target;
                    if ((event.keyCode === 8 && ( (d.tagName.toUpperCase() === 'INPUT' && (
                                    d.type.toUpperCase() === 'TEXT' ||
                                    d.type.toUpperCase() === 'PASSWORD' ||
                                    d.type.toUpperCase() === 'FILE' ||
                                    d.type.toUpperCase() === 'EMAIL' ||
                                    d.type.toUpperCase() === 'SEARCH' ||
                                    d.type.toUpperCase() === 'DATE')
                                ) ||
                                d.tagName.toUpperCase() === 'TEXTAREA' ||
                                d.id === 'mail-body')
                        )
                        || (event.keyCode === 13 && (d.tagName.toUpperCase() === 'TEXTAREA' || d.id === 'mail-body') )
                    ) {
                        doPrevent = d.readOnly || d.disabled;
                    } else {
                        doPrevent = true;
                    }
                }
                if (doPrevent) {
                    event.preventDefault();
                }
            });

            // scroll to block
            if (this.options.routeParams != undefined && this.options.routeParams.scrollToId != undefined) {
                var element = $('#' + this.options.routeParams.scrollToId);
                if (element) {
                    $('html, body').animate({scrollTop: (element.offset().top - 200)}, 'slow');
                }
            }

            var priceBlockMultiBundle = $('#block_priceMultiBundle.view-edit'),
                bundleParts = priceBlockMultiBundle.find('#bundleParts').first(),
                tableRows = bundleParts.find('tbody tr').not('.hidden');

            if (tableRows.length > 3) {
                priceBlockMultiBundle.addClass('has-removable-elements');
            } else {
                priceBlockMultiBundle.removeClass('has-removable-elements');
            }

            // Open copy media files dialog
            if (MiscHelper.isEmpty(this.model.get('id'))
                && this.options.formDef.get('mode') === 'CREATE'
                && this.options.formDef.get('model') === 'product'
                && !MiscHelper.isEmpty(this.model.get('createdBy'))) {

                var showDialog = false;
                if (this.model.get('mediaFiles').length > 0) {
                    showDialog = true;
                }
                if (showDialog === false) {
                    var texts = this.model.get('texts');
                    for (var i = 0; i < texts.length; i++) {
                        if (!MiscHelper.isEmpty(texts[i].assetFileId)) {
                            showDialog = true;
                            break;
                        }
                    }
                }

                if (showDialog) {
                    var tmpFiles = this.model.get('mediaFiles');
                    var tmpTexts = this.model.get('texts');
                    var candidateFiles = [];
                    var tmpMediaFiles = [];
                    var tmpTextsFiles = [];
                    var prevTexts = tmpTexts.slice();

                    for (var i = 0; i < tmpFiles.length; i++) {
                        if (!MiscHelper.isEmpty(tmpFiles[i].internalUrl)) {
                            tmpFiles[i].source = 'mediaFiles';
                            candidateFiles.push(tmpFiles[i]);
                            tmpMediaFiles.push(tmpFiles[i]);
                        }
                    }
                    for (var i = 0; i < tmpTexts.length; i++) {
                        if (!MiscHelper.isEmpty(tmpTexts[i].internalUrl)) {
                            tmpTexts[i].source = 'texts';
                            candidateFiles.push(tmpTexts[i]);
                        } else {
                            tmpTextsFiles.push(tmpTexts[i]);
                        }
                    }
                    this.model.set('mediaFiles', tmpMediaFiles);
                    // this.model.set('texts', tmpTextsFiles);
                    if (candidateFiles.length > 0) {
                        var assetListModel = new PageContentModel({
                            urlRoot: "$api/asset/" + this.model.get('copyOfThisProduct') + '/assetFiles?page=1&size=100&search=',
                            modelKey: 'assets'
                        });
                        assetListModel.fetch({async: false});
                        var assets = assetListModel.get('content');
                        for (var i = 0; i < candidateFiles.length; i++) {
                            var assetCandidate = candidateFiles[i];
                            var found = false;
                            for (var j = 0; j < assets.length; j++) {
                                var asset = assets[j];
                                if (asset.id === assetCandidate.assetFileId) {
                                    var tmpType = assetCandidate.type;
                                    var tmpLabel = asset.type;
                                    var tmpId = assetCandidate.id;
                                    $.extend(assetCandidate, assetCandidate, asset);
                                    assetCandidate.type = tmpType;
                                    assetCandidate.typeLabel = tmpLabel;
                                    assetCandidate.id = tmpId;
                                    found = true;
                                    break;
                                }
                            }
                            if (!found) {
                                candidateFiles.splice(i, 1);
                                i--;
                            }
                        }

                        if (candidateFiles.length > 0) {
                            var scopeData = {};
                            scopeData.ident = 'selectMediafiles';
                            scopeData.mediaFiles = candidateFiles;
                            scopeData.routeParams = this.model.get('routeParams');
                            var view = new SelectMediafilesFormView({id: scopeData.ident, scopeData: scopeData});
                            var that = this;
                            var confirmDialog = function (event) {
                                var newMediaFiles = [];
                                var newTexts = [];
                                var viewModel = event.data.options.viewModel;
                                if (viewModel.get('selectMediafiles') === 'takeMedia' || viewModel.get('selectMediafiles') === 'takeAllMedia') {
                                    var prevMediaFiles = that.model.get('mediaFiles');
                                    var obj = viewModel.get('medium');
                                    for (var assetCandidate in obj) {
                                        if (obj[assetCandidate] === true) {
                                            var found = false;
                                            // Ticket 16298 - remove information about the previous assets. Relevant
                                            // information must be set from the backend as soon as the product is been
                                            // saved.
                                            for (var i = 0; i < prevMediaFiles.length; i++) {
                                                if (prevMediaFiles[i].id === assetCandidate) {
                                                    prevMediaFiles[i].id = MiscHelper.uuid();
                                                    prevMediaFiles[i].assetFileId = null;
                                                    prevMediaFiles[i].internalUrl = null;
                                                    prevMediaFiles[i].validFrom = null;
                                                    prevMediaFiles[i].validTo = null;
                                                    prevMediaFiles[i].version = null;
                                                    newMediaFiles.push(prevMediaFiles[i]);
                                                    found = true;
                                                    break;
                                                }
                                            }
                                            if (!found) {
                                                for (var i = 0; i < prevTexts.length; i++) {
                                                    if (prevTexts[i].id === assetCandidate) {
                                                        prevTexts[i].id = MiscHelper.uuid();
                                                        prevTexts[i].assetFileId = null;
                                                        prevTexts[i].internalUrl = null;
                                                        prevTexts[i].validFrom = null;
                                                        prevTexts[i].validTo = null;
                                                        prevTexts[i].version = null;
                                                        newTexts.push(prevTexts[i]);
                                                        break;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                // Rebuild the origin order. That is important because th UI was build up with the origin indexes.
                                for (var i = 0; i < prevTexts.length; i++) {
                                    if (!MiscHelper.isEmpty(prevTexts[i].assetFileId)) {
                                        var found = false;
                                        for (var j = 0; j < newTexts.length; j++) {
                                            if (prevTexts[i].assetFileId === newTexts[j].assetFileId) {
                                                found = true;
                                                break;
                                            }
                                        }
                                        if (found === false) {
                                            prevTexts[i] = null;
                                        }
                                    }
                                }
                                var mediaFiles = newMediaFiles;
                                var texts = prevTexts;
                                that.model.set('mediaFiles', newMediaFiles);
                                that.model.set('texts', prevTexts);

                                $.fancybox.close();
                                var div = $('#' + scopeData.ident + 'Confirmation');
                                div.fadeOut('slow');
                                div.remove();
                            }
                            var options = {
                                'closeOnClick': false,
                                'maxWidth': '650px',
                                'minHeight': '330px',
                                'openSpeed': 10,
                                'viewModel': view.model
                            };
                            ConfirmationHelper.openDialog(view.el, scopeData.ident, confirmDialog, options);
                            view.attach();
                        }
                    }
                }
            }
            AjaxSpinner.hide();
        }
    },

    rebind_validator: function () {
        // TODO: for fix #9318 the validation needs to be displayed in tables
        //$("#" + this.id).nod(this.validations, {delay: false});
    },

    formDefinitionLoaded: function (loadedFormDef) {
        var formId = this.options.formDef.attributes['id'];
        if (loadedFormDef.get('id') !== formId) {
            return;
        }

        var aggregatorId = this.model.get('aggregatorId');
        var productVLB = MiscHelper.isEmpty(aggregatorId) || '0' === aggregatorId || '1' === aggregatorId;
        this.model.set('productVLB', productVLB);
        ActionHelper.prepareHeaderActions(this.options.formDef, 'form', this.model.attributes);

        this.validations = [];
        this.collections = {};

        this.id = formId.replace(/\//g, '_');
        this.options.formDef.attributes['editModeWarningText'] = "form." + this.model.modelKey + ".editModeWarningText";
        this.options.formDef.attributes['cancelAction'] = ActionHelper.findActionById(this.options.formDef, "cancel");
        this.options.formDef.attributes['saveAction'] = ActionHelper.findActionById(this.options.formDef, "save");

        this.options.formDef.attributes['hideEditModeBar'] = this.options.formDef.attributes['hideEditModeBar'] || (this.options.formDef.attributes['mode'] !== 'EDIT');
        if (this.model.modelKey === 'product' && this.options.formDef.attributes['mode'] !== 'SHOW' && this.options.formDef.attributes['mode'] !== 'EDIT_ASSETS') {
            this.options.formDef.attributes['hideEditModeBar'] = false;
        }

        Chaplin.mediator.subscribe('triggerValidation', this.triggerValidation, this);

        if (this.options.formDef.attributes['saveAction'] !== undefined
            && this.options.formDef.attributes['saveAction'].showResultAlerts) {
            Chaplin.mediator.subscribe(this.model.modelKey + 'Saved', this.showSavedMessage, this);
        }

        $(this.el).attr('id', this.id);
        //render form first, so FormBlockViews can auto render and attach correctly
        this.render();

        if (this.enableEvents) {
            //this.delegate('click', '#backBtn', this.historyBack);
            this.delegate('click', '#backBtn', this.backButtonPressed);
            this.delegate('submit', this.submit);
            this.delegate('click', 'button.cancel', this.cancel);
            this.delegate('click', 'button#saveAndEdit', this.saveAndEdit);
            this.delegate('click', 'button#saveAndBack', this.saveAndBack);
            this.delegate('click', 'button#saveAndMmo', this.saveAndMmo);
            this.delegate('click', '.save-bar .message.error', this.jumpToError)
        }

        this.delegate('click', '.icon-nav-list [data-toggle="confirmationSingle"]', this.confirmationSingle);
        this.delegate('click', '.icon-nav-list [action=print]', function () {
            window.print();
        });
        this.delegate('click', '[action=backToSearch]', this.backToSearch);

        if (this.model) {
            this.blocksToLoad = this.options.formDef.attributes['block'].length;
            var delay = 0;
            for (var i = 0; i < this.options.formDef.attributes['block'].length; i++) {
                var block = this.options.formDef.attributes['block'][i];
                block.blockIndex = i;
                var buildBlock = MiscHelper.compareComplex(this.model.attributes, block.visible);
                if (buildBlock) {
                    var formBlockView = new FormBlock({
                        el: "#block_" + block.blockId,
                        model: this.model,
                        blockDef: block,
                        formDef: this.options.formDef,
                        parentView: this,
                        showTitle: ( block.showTitle !== false),
                        routeParams: this.options.routeParams
                    });
                    this.subview("block_" + block.blockId, formBlockView);
//            		formBlockView.startBlock(formBlockView, this.blockLoaded);
                    _.delay(formBlockView.startBlock, delay, formBlockView, _.bind(this.blockLoaded, this));
                    delay += 100;
                }
            }
        }

        if (Application.flashMessage !== undefined) {
            MsgHelper.showSuccessAlert(Application.flashMessage);
            Application.flashMessage = undefined;
        }
    },

    backToSearch: function () {
        var historyStack = HistoryHelper.getCurrentHistory();
        var counter = 0;
        while (counter < HISTORY_SIZE) {
            counter++;
            var historyStack = HistoryHelper.getHistoryRelativeFromCurrent(counter * -1);
            if (historyStack === undefined) {
                break;
            } else if (historyStack.fragment.indexOf('showAllTitles') > -1) {
                Chaplin.mediator.publish("redirectTo", {
                    url: 'search_quicksearch',
                    data: {search: '', action: 'showAllTitles'}
                });
                return;
            } else if (historyStack.fragment.indexOf('startQuickSearch') > -1) {
                var searchTerm = historyStack.fragment.split('/')[3];
                Chaplin.mediator.publish("redirectTo", {
                    url: 'search_quicksearch',
                    data: {search: searchTerm, action: 'startQuickSearch'}
                });
                return;
            } else if (historyStack.fragment.indexOf('advancedsearch') > -1) {
                var searchTerm = historyStack.fragment.split('/')[2];
                // TODO check if there is a way to reuse the search id. using that below leads to a 404 response.
                var id = historyStack.historyParams.id;
                Chaplin.mediator.publish('redirectTo', {
                    url: 'search_advancedsearch',
                    data: {id: undefined, search: searchTerm, mode: 'advanced'}
                });
                return;
            } else if (historyStack.fragment.indexOf('boolesearch') > -1) {
                var searchTerm = historyStack.fragment.split('/')[2];
                // TODO check if there is a way to reuse the search id. using that below leads to a 404 response.
                var id = historyStack.historyParams.id;
                Chaplin.mediator.publish('redirectTo', {
                    url: 'search_boolesearch',
                    data: {
                        id: undefined,
                        search: searchTerm,
                        mode: 'boole'
                    }
                });
                return;
            } else if (historyStack.fragment.indexOf('batchsearch') > -1) {
                var searchTerm = historyStack.fragment.split('/')[2];
                // TODO check if there is a way to reuse the search id. using that below leads to a 404 response.
                var id = historyStack.historyParams.id;
                Chaplin.mediator.publish('redirectTo', {
                    url: 'search_batchsearch',
                    data: {
                        id: undefined,
                        search: searchTerm,
                        mode: 'batch'
                    }
                });
                return;
            } else if (historyStack.fragment.indexOf('searchhistorys') > -1 || historyStack.fragment.indexOf('productTracker') > -1) {
                Chaplin.mediator.publish('redirectTo', {
                    url: historyStack.historyParams.clickedInList ? historyStack.historyParams.clickedInList : 'productTracker_list',
                    data: {
                        'id': user.userId
                    }
                });
                return;
            }
        }
        // did not find the way back. So go to show all titles
        Chaplin.mediator.publish("redirectTo", {
            url: 'search_quicksearch',
            data: {search: '', action: 'showAllTitles'}
        });
    },

    showSavedMessage: function (id) {
        if (Application.flashMessage !== undefined) {
            MsgHelper.showSuccessAlert(Application.flashMessage);
            Application.flashMessage = undefined;
            $('html, body').animate({
                scrollTop: 0
            }, 100);
            $('#' + this.id + ' [type=submit]').removeClass('disabled').removeAttr('disabled');
        }
        this.model.fetch();
    },

    backButtonPressed: function (event) {

        if (this.id === 'user_show') {
            var historyStack;
            var previousPage;
            var counter = 0;
            while (previousPage === undefined && counter < HISTORY_SIZE) {
                counter++;
                var historyStack = HistoryHelper.getHistoryRelativeFromCurrent(counter * -1);
                if (historyStack === undefined) {
                    break;
                } else if (historyStack.fragment.indexOf('account') > -1) {
                    previousPage = 'account_list';
                } else if (historyStack.fragment.indexOf('profile') > -1) {
                    previousPage = 'profile_view'
                }
            }
            if (previousPage === undefined) {
                Chaplin.mediator.publish('redirectTo', {url: 'account_list'});
            } else {
                Chaplin.mediator.publish('redirectTo', {url: previousPage});
            }
        } else if (this.id === 'product_show' || this.id === 'productHistory_show' || this.id === 'aggregatedLogentry_show'
            || this.id === 'collection_show') {
            HistoryHelper.writeHistory(undefined, 'back');
            Backbone.history.history.back();
        } else {
            // This here was the previous version. Meanwhile I think that this line is never really correct.
            Chaplin.mediator.publish('redirectTo', {url: this.model.modelKey + '_list'});
        }
    },

    cancel: function (event) {
        if (this.id === 'product_editAssets') {
            Chaplin.mediator.publish('redirectTo', {
                url: 'product_show_rec',
                data: {
                    id: this.model.get('id'),
                    record: sessionStorage.getItem('form-view-paginator')
                }
            });

        } else {
            HistoryHelper.writeHistory(undefined, 'back');
            Backbone.history.history.back();
        }
    },

    saveAndEdit: function (e) {
        e.preventDefault();
        // special behavior for accounts: may return to list or may enter edit mode after saving
        var clone = this.prepareModelForServerRequest();
        clone.save(clone.attributes, {
            async: true,
            wait: false,
            success: function (model, response, options) {
                Application.flashMessage = MsgHelper.getMessage('msg.' + model.modelKey + '.saved.successful');
                Chaplin.mediator.publish(model.modelKey + 'SavedAndEdit', response.id);
            },
            error: function (model, response, options) {
                FormHelper.error(jQuery.parseJSON(response.responseText), model);
                options.xhr.ajaxErrorProcessed = true;
            }
        });
    },

    saveAndBack: function (e) {
        e.preventDefault();

        // special behavior for accounts: may return to list or may enter edit mode after saving
        var clone = this.prepareModelForServerRequest();
        clone.save(clone.attributes, {
            async: true,
            wait: false,
            success: function (model, response, options) {
                Application.flashMessage = MsgHelper.getMessage('msg.' + model.modelKey + '.saved.successful');
                Chaplin.mediator.publish(model.modelKey + 'SavedAndBack', response.id);
            },
            error: function (model, response, options) {
                FormHelper.error(jQuery.parseJSON(response.responseText), model);
                options.xhr.ajaxErrorProcessed = true;
            }
        });
    },

    saveAndMmo: function (e) {
        e.preventDefault();

        // special behavior for products: may switch to asset list after saving
        var clone = this.prepareModelForServerRequest();
        var that = this;
        clone.save(clone.attributes, {
            async: true,
            wait: false,
            success: function (model, response, options) {
                Application.flashMessage = MsgHelper.getMessage('msg.' + model.modelKey + '.saved.successful');
                Chaplin.mediator.publish(model.modelKey + 'SavedAndMmo', response.id);
            },
            error: function (model, response, options) {
                $('.save-bar .infoText .message').text(MsgHelper.getMessage('label.handle.errors'));
                FormHelper.error(jQuery.parseJSON(response.responseText), model, that.options.formDef.id.indexOf('create') > -1);
                var saveAndMmoButton = $('#content-container button#saveAndMmo');
                var submitButton = $('#content-container [type=submit]');
                saveAndMmoButton.removeClass('disabled').attr('disabled', 'disabled');
                submitButton.addClass('disabled').attr('disabled', 'disabled');
                options.xhr.ajaxErrorProcessed = true;
            }
        });
        if (this.id === 'product_create') {
            sessionStorage.removeItem('form-view-paginator');
        }
    },

    submit: function (e) {
        e.preventDefault();

        if (this.model.modelKey === "user") {
            // special behavior for users: needs to open mailing dialog after creation
            var groupMappings = [];

            if (this.model.attributes.user.groupMapping) {
                for (var gm = 0; gm < this.model.attributes.user.groupMapping.length; gm++) {
                    if (this.model.attributes.user.groupMapping[gm] !== null) {
                        var account = this.model.attributes.user.groupMapping[gm].account;
                        var reportsRaw = this.model.attributes.user.groupMapping[gm].reports;
                        var reports = reportsRaw.length === 0 ? [] : reportsRaw.split(",").map(function(x){return new Object({"type": x})}); //example: [[], {"type": "NEW_PUBLICATION"}]
                        groupMappings.push({
                            account: account == null || Array.isArray(account.id) ? null : account,
                            group: this.model.attributes.user.groupMapping[gm].group,
                            reports: reports
                        });
                    }
                }
            }

            this.model.attributes.user.groupMapping = groupMappings;

            var clone = this.prepareModelForServerRequest();

            var editMode = e.target.id === "user_edit";
            clone.save(clone.attributes, {
                async: true,
                wait: false,
                success: function (model, response, options) {
                    Application.flashMessage = MsgHelper.getMessage('msg.' + model.modelKey + '.saved.successful');
                    if (editMode) {
                        Chaplin.mediator.publish(model.modelKey + 'Saved', response.id);
                    } else {
                        Chaplin.mediator.publish(model.modelKey + 'SavedAndSendCredentials', response.id);
                    }
                }, error: function (model, response, options) {
                    FormHelper.error(jQuery.parseJSON(response.responseText), model);
                    options.xhr.ajaxErrorProcessed = true;
                }
            });
        } else if (this.model.modelKey === "account") {
            $('#' + this.id + ' [type=submit]').addClass('disabled').attr('disabled', 'disabled');
            // regular behavior
            var clone = this.prepareModelForServerRequest();
            clone.attributes.userDetails = null;
            var cmodel = this.model;
            clone.save(clone.attributes, {
                async: true,
                wait: false,
                success: function (model, response, options) {
                    Application.flashMessage = MsgHelper.getMessage('msg.' + model.modelKey + '.saved.successful');
                    Chaplin.mediator.publish(model.modelKey + 'Saved', response.id);
                }, error: function (model, response, options) {
                    FormHelper.error(jQuery.parseJSON(response.responseText), model);
                    options.xhr.ajaxErrorProcessed = true;
                }
            });
        } else {
            $('#' + this.id + ' [type=submit]').addClass('disabled').attr('disabled', 'disabled');
            // regular behavior
            var clone = this.prepareModelForServerRequest();
            var cmodel = this.model;
            var that = this;
            clone.save(clone.attributes, {
                async: true,
                wait: false,
                success: function (model, response, options) {
                    if (MiscHelper.isEmpty(that.model.get('id'))
                        && that.options.formDef.get('mode') === 'CREATE'
                        && that.options.formDef.get('model') === 'product'
                        && !MiscHelper.isEmpty(that.model.get('createdBy'))) {
                        var showDialog = false;
                        if (that.model.get('mediaFiles').length > 0) {
                            showDialog = true;
                        }
                        if (showDialog === false) {
                            var texts = that.model.get('texts');
                            for (var i = 0; i < texts.length; i++) {
                                if (texts[i] !== null && !MiscHelper.isEmpty(texts[i].assetFileId)) {
                                    showDialog = true;
                                    break;
                                }
                            }
                        }
                        if (showDialog === true) {
                            that.showDialogCopyMediaFiles(response.id, this);
                        } else {
                            Application.flashMessage = MsgHelper.getMessage('msg.' + model.modelKey + '.saved.successful');
                            Chaplin.mediator.publish(model.modelKey + 'Saved', response.id);
                        }
                    } else {
                        Application.flashMessage = MsgHelper.getMessage('msg.' + model.modelKey + '.saved.successful');
                        Chaplin.mediator.publish(model.modelKey + 'Saved', response.id);
                    }
                },
                error: function (model, response, options) {
                    $('.save-bar .infoText .message').text(MsgHelper.getMessage('label.handle.errors'));
                    FormHelper.error(jQuery.parseJSON(response.responseText), model, that.options.formDef.id.indexOf('create') > -1);
                    var saveAndMmoButton = $('#content-container button#saveAndMmo');
                    var submitButton = $('#content-container [type=submit]');
                    saveAndMmoButton.removeClass('disabled').attr('disabled', 'disabled');
                    submitButton.addClass('disabled').attr('disabled', 'disabled');
                    options.xhr.ajaxErrorProcessed = true;
                }
            });
        }

        if (this.id === 'product_create') {
            sessionStorage.removeItem('form-view-paginator');
        }


    },

    showDialogCopyMediaFiles: function (id) {
        var model = this.model;
        var scopeData = {};
        scopeData.text = MsgHelper.getMessage('label.form.confirmation.copyMediafiles');
        scopeData.ident = 'copyMediafiles';
        scopeData.whichButton = 'ok';
        scopeData.closeClass = 'invisible';
        var html = ConfirmationAcknowledge(scopeData);
        var confirmDialog = function (event) {
            $.fancybox.close();
            var div = $('#' + scopeData.ident + 'Confirmation');
            div.fadeOut('slow');
            div.remove();
            Application.flashMessage = MsgHelper.getMessage('msg.' + model.modelKey + '.saved.successful');
            Chaplin.mediator.publish(model.modelKey + 'Saved', id);
        }
        ConfirmationHelper.openDialog(html, scopeData.ident, confirmDialog);
    },

    removeNullValuesinArray: function (obj) {
        //clean up null values in array
        for (var key in obj) {
            var values = obj[key];
            if ($.isArray(values)) {
                for (var i = values.length - 1; i >= 0; i--) {
                    if (values[i] === null) {
                        values.splice(i, 1);
                    } else if ($.isPlainObject(values[i])) {
                        this.removeNullValuesinArray(values[i]);
                    }
                }
            } else if ($.isPlainObject(values)) {
                this.removeNullValuesinArray(values);
            }
        }
    },

    prepareModelForServerRequest: function () {
        var clone = null;
        if (this.model.deepClone !== undefined) {
            clone = this.model.deepClone();
        } else {
            clone = this.model.clone();
        }
        // don't do that with users - this leads to problems
        if (this.model.modelKey !== "user") {
            this.fillSelects(clone);
            this.fillTypeaheads(clone);
        }
        //clear up clone
        for (var i = 0; i < this.options.formDef.attributes['block'].length; i++) {
            var block = this.options.formDef.attributes['block'][i];
            //// (HGF) I really don't know what for is the second part of this check. That one exists before I added the
            //// first part.
            //if ($('#block_' + block.blockId).is(':visible') && MiscHelper.compareComplex(this.model.attributes, block.visible))
            {
                this.subview("block_" + block.blockId).prepareForSubmit(clone);
            }
        }

        // Only keep the real wanted unpricedItemCode and throw the other one away. If not doing that the second value
        // overwrites the first value because ther is only one real backing unpricedItemCode in the product class.
        if (clone.get('type') === 'journal') {
            clone.set('unpricedItemCode', undefined);
            // clone.set('identifiers', this.model.get('identifiers'));

            // set the correct zis subjectgroup if selected
            var classifications = clone.get('classifications');
            var code = null;
            for (var i = 0; i < classifications.length; i++) {
                if (!MiscHelper.isEmpty(classifications[i]) && classifications[i].type === 'ZS') {
                    if (!MiscHelper.isEmpty(classifications[i].subcode)) {
                        code = classifications[i].subcode;
                    } else if (!MiscHelper.isEmpty(classifications[i].maincode)) {
                        code = classifications[i].maincode;
                    }
                }
                clone.set('classifications.' + i + '.code', code);
            }
        } else if (this.options.modelKey === 'product') {
            clone.set('zisInfo', undefined);
            clone.set('unpricedItemCodeJournal', undefined);
        } else {
            clone.set('zisInfo', undefined);
        }

        // send all additionalInformation type text (otherText) with formatCode 05 to get it through the html validation in backend
        var texts = clone.get("texts");
        if (!MiscHelper.isEmpty(texts)) {
            for (var i = 0; i < texts.length; i++) {
                // remember: deleted notes are not removed but set to null !!
                if (texts[i] == null) {
                    continue;
                }
                if (texts[i].linkType === null && (texts[i].formatCode === "06" || texts[i].formatCode === "05" || texts[i].formatCode === "02")) {
                    clone.set("texts." + i + ".formatCode", "05");
                }
            }
        }

        if (!MiscHelper.isEmpty(clone.get('contributors'))) {
            var contributors = clone.get('contributors');
            for (i = 0; i < contributors.length; i++) {
                var contributor = contributors[i];
                if (!MiscHelper.isEmpty(contributor)) {
                    var dateOfBirth = contributor.dateOfBirth;
                    var dateOfDeath = contributor.dateOfDeath;
                    if (!MiscHelper.isEmpty(dateOfBirth)) {
                        dateOfBirth = DateHelper.convert(dateOfBirth, formatDateDayMonthYear, 'yyyyMMdd');
                        if (!MiscHelper.isEmpty(dateOfBirth)) {
                            clone.set('contributors.' + i + '.dateOfBirth', dateOfBirth);
                        }
                    }
                    if (!MiscHelper.isEmpty(dateOfDeath)) {
                        dateOfDeath = DateHelper.convert(dateOfDeath, formatDateDayMonthYear, 'yyyyMMdd');
                        if (!MiscHelper.isEmpty(dateOfDeath)) {
                            clone.set('contributors.' + i + '.dateOfDeath', dateOfDeath);
                        }
                    }
                }
            }
        }

        // don't send empty suppliers
        var supplier = clone.get('supplier');
        if (
            !MiscHelper.isEmpty(supplier) &&
            MiscHelper.isEmpty(supplier.supplierId) &&
            MiscHelper.isEmpty(supplier.type)
        ) {
            clone.set('supplier', null);
        }

        this.removeNullValuesinArray(clone.attributes);
        MiscHelper.keywordsSequenceCorrector(clone);

        return clone;
    },

    triggerValidation: function () {
        if (this.options.formDef.attributes.validateOnServer) {
            this.validateOnServer();
        }
    },

    validateOnServer: function (event) {
        if (event !== undefined) {
            event.preventDefault();
            if ($(event.currentTarget).data('no-validate')) {
                return;
            }
        }

        // avoid multiple executions on the same event. The datepicker sends three events on the same click.
        if (event !== undefined) {
            if (this.lastEventTimeStamp && this.lastTarget === event.target && event.timeStamp - this.lastEventTimeStamp < 500) {
                return;
            }
            this.lastEventTimeStamp = event.timeStamp;
            this.lastTarget = event.target;
        }

        if (event !== undefined && (event.target.type === 'text' || event.target.type === 'textarea') && !MiscHelper.isEmpty(event.target.value)) {
            var trimedValue = _.string.rtrim(event.target.value);
            if (event.target.value.length != trimedValue.length) {
                var modelNode = FormHelper.fromUiIdtoModelNode(event.target.id);
                this.model.set(modelNode, trimedValue);
            }
        }

        var modelClone = this.prepareModelForServerRequest();

        var model = this.model;
        var self = this;

        jQuery.ajax({
            contentType: 'application/json',
            dataType: 'json',
            url: this.model.urlRoot + "/validate",
            async: true,
            "data": JSON.stringify(modelClone.toJSON()),
            type: 'POST',
            "success": function (response, status, options) {
                var errorMessages = [];
                if ($.isArray(response)) {
                    errorMessages = response;
                } else if ($.isPlainObject(response)) {
                    errorMessages = response.validationMessages;
                    if (model.modelKey === 'product') {

                        model.set('announcementDate', response.product.announcementDate);

                        for (var i = 0; i < response.product.identifiers.length; i++) {
                            var id = response.product.identifiers[i];
                            if (id.type === '03' || id.type === '15' || id.type === 'FI') {
                                for (var j = 0; j < model.attributes.identifiers.length; j++) {
                                    var idM = model.attributes.identifiers[j];
                                    if (!MiscHelper.isEmpty(idM) && id.type === idM.type) {
                                        model.set("identifiers." + j + ".value", id.value);
                                        break;
                                    }
                                }
                            }
                        }

                        if (response.product.type === 'duoBundle') {
                            // Do not iterate over the response because MDS removes prices without a value.
                            var gtin = {};
                            var containedItems = response.product.containedItems;
                            containedItems.sort(function (a, b) {
                                return a.sequence - b.sequence
                            });
                            for (i = 0; i < model.get('duoBundles').length; i++) {
                                var bundle = model.get('duoBundles')[i];
                                for (var j = 0; j < bundle.bundleParts.length; j++) {
                                    if (i === 0) {
                                        // response may be different to our current bundles because the mds removes
                                        // records without prices.
                                        if (containedItems === null || containedItems[j] === null) {
                                            continue;
                                        }
                                        gtin[j] = containedItems[j].productIdentifierValue;
                                        gtin[j] = gtin[j] === null ? '' : gtin[j];
                                    }
                                    model.set('duoBundles.' + i + '.bundleParts.' + j + '.gtin', gtin[j]);
                                    model.set('containedItems.' + i + '.productIdentifierValue', gtin[j]);
                                    var selector = '#duoBundles_' + i + '_bundleParts_gtin_' + j;
                                    if ($(selector).is('span')) {
                                        $(selector).text(gtin[j]);
                                    } else {
                                        $(selector).val(gtin[j]);
                                    }
                                }
                            }
                        } else if (response.product.type === 'journal' && response.product.zisInfo.medium === 'WW') {
                            // Do not iterate over the response because MDS removes prices without a value.
                            var gtin = {};
                            var containedItems = response.product.containedItems;
                            for (var k = 1; k < containedItems.length; k++) {
                                if (containedItems[k - 1].sequence > containedItems[k].sequence) {
                                    var temp = containedItems[k - 1];
                                    containedItems[k - 1] = containedItems[k];
                                    containedItems[k] = temp;
                                }
                            }
                            for (i = 0; i < model.get('pricesJournalBundle').length; i++) {
                                var priceHead = model.get('pricesJournalBundle')[i];
                                for (var j = 0; j < priceHead.priceParts.length; j++) {
                                    if (i === 0) {
                                        if (containedItems[j] !== undefined) {
                                            gtin[j] = containedItems[j].productIdentifierValue === null ? undefined : containedItems[j].productIdentifierValue;
                                        } else {
                                            gtin[j] = undefined;
                                        }
                                    }
                                    model.set('pricesJournalBundle.' + i + '.priceParts.' + j + '.identifier', gtin[j]);
                                    model.set('containedItems.' + i + '.productIdentifierValue', gtin[j]);
                                    var selector = '#pricesJournalBundle_' + i + '_priceParts_identifier_' + j;
                                    if ($(selector).is('span')) {
                                        $(selector).text(gtin[j]);
                                    } else {
                                        $(selector).val(gtin[j]);
                                    }
                                }
                            }
                        }

                        var containedItemsRes = response.product.containedItems;
                        for (var i = 0; i < containedItemsRes.length; i++) {
                            var containedItemsCurr = model.get('containedItems');
                            for (var j = 0; j < containedItemsCurr.length; j++) {
                                if (containedItemsCurr[j].id === containedItemsRes[i].id) {
                                    model.set('containedItems.' + j + '.numberOfPieces', containedItemsRes[i].numberOfPieces);
                                }
                            }
                        }

                        if (model.attributes.availability !== response.product.availability) {
                            model.set("availability", response.product.availability);
                            $("#availability").select2("val", response.product.availability);
                        }

                        if (model.attributes.availabilityDate !== response.product.availabilityDate) {
                            model.set("availabilityDate", response.product.availabilityDate == null ? "" : response.product.availabilityDate);
                        }

                        var extentL = model.get('extent');
                        var extentR = response.product.extent;
                        if (extentL.pagesRoman !== extentR.pagesRoman) {
                            model.set("extent.pagesRoman", extentR.pagesRoman);
                        }

                        //if($('#block_priceMultiBundle').is(":visible")) {
                        //    self.subview("block_priceMultiBundle").rebind("multiBundles");
                        //}

                        if ($('#fieldSet_prices').is(":visible")) {
                            PriceHelper.copyAutocorrections(response.product.retailPrices, model, "retailPrices");
                            //PriceHelper.copyAutocorrections(response.product.specialPrices, model, "specialPrices");
                            self.subview("block_prices").rebind("retailPrices");
                            self.subview("block_prices").rebind("specialPrices");
                            self.subview("block_prices").rebind("foreignPrices");
                            self.subview("block_prices").render();
                        }
                        var audiencesResponse = response.product.audiences;
                        var audiencesOrg = model.get('audiences');
                        for (var i = 0; i < audiencesResponse.length; i++) {
                            var audienceResponse = audiencesResponse[i];
                            var idResponse = audienceResponse.id;
                            for (j = 0; j < audiencesOrg.length; j++) {
                                audienceOrg = audiencesOrg[j];
                                var idOrg = audienceOrg.id;
                                if (idOrg === idResponse) {
                                    model.set('audiences.' + j + '.ageTo', audienceResponse.ageTo);
                                    model.set('audiences.' + j + '.ageFrom', audienceResponse.ageFrom);
                                    break;
                                }
                            }
                        }


                        var rpL = model.get('relatedProducts');
                        var rpR = response.product.relatedProducts;
                        for (var i = 0; i < rpL.length; i++) {

                            for (var j = 0; j < rpR.length; j++) {
                                if (rpL[i] != null && rpR[j] != null)
                                    if (rpL[i].id === rpR[j].id) {
                                        model.set("relatedProducts." + i + ".productIdValue", rpR[j].productIdValue);
                                        break;
                                    }
                            }

                        }


                    }
                }
                var saveAndMmoButton = $('#content-container button#saveAndMmo');
                var submitButton = $('#content-container [type=submit]');
                if (!FormHelper.hasError(errorMessages)) {
                    $('.save-bar .infoText .message').text("");
                    FormHelper.error(errorMessages, model, self.options.formDef.id.indexOf('create') > -1);
                    saveAndMmoButton.removeClass('disabled').removeAttr('disabled');
                    return submitButton.removeClass('disabled').removeAttr('disabled');
                } else {
                    $('.save-bar .infoText .message').text(MsgHelper.getMessage('label.handle.errors'));
                    FormHelper.error(errorMessages, model, self.options.formDef.id.indexOf('create') > -1);
                    saveAndMmoButton.removeClass('disabled').attr('disabled', 'disabled');
                    return submitButton.addClass('disabled').attr('disabled', 'disabled');
                }
            },
            "headers": {
                'Authorization': "Bearer " + user.access_token
            }
        });
    },

    cleanInputValues: function (event) {
        var target = $(event.target),
            value = event.target.value;

        target.val(MiscHelper.removeEscapeCharsFromString(value));
    },

    attach: function () {
        View.prototype.attach.call(this);

        if (this.options.usePaginator) {
            var model = this.model.attributes;
            var that = this;
            this.options.table = $('.form-view-paginator').DataTable({
                "iDisplayLength": 1,
                "iDisplayStart": parseInt(that.options.routeParams['record']),
                "dom": 'p',
                "bServerSide": true,
                "sAjaxDataProp": 'content',
                "columns": [
                    // any colum. id is something that is always available.
                    {"data": "id", "width": null, "visible": false}
                ],
                "fnServerData": this.getPaginatorDataFromRest,
                "fnServerParams": function (aoData) {
                    aoData.model = model;
                    aoData.that = that;
                    aoData.clickedInList = that.options.routeParams.clickedInList;
                }
            });
        }


        return this;
    },

    confirmAction: function (event) {
        let action = ActionHelper.findActionById(this.options.formDef, event.currentTarget.attributes["action"].value);
        if ("openThemaDialog" === event.currentTarget.attributes["action"].value) {
            var themaDialog = this.subview("themaDialog");
            if (themaDialog === undefined || themaDialog === null) {
                themaDialog = new ThemaView({
                    model: this.model,
                    collections: this.collections,
                    formDef: this.options.formDef
                });
                this.subview("themaDialog", themaDialog);
            }
            var themaHtml = themaDialog.render().$el.prop('outerHTML');
            var self = this;

            var confirmDialog = function (event) {
                var classifications = themaDialog.dialogConfirm();
                self.model.set('classifications', classifications);
                self.model.set('themeHint', false);
                self.subview("block_thema").rebind("classifications");
                self.subview("block_thema").render();
                self.validateOnServer();
                $('.' + themaDialog.id + '-close').click();
            };


            ConfirmationHelper.openDialog(themaHtml, this.subview("themaDialog").id, confirmDialog);
            this.subview("themaDialog").dialogOpened();
        } else if ("openBisacDialog" === event.currentTarget.attributes["action"].value) {
            var bisacDialog = this.subview("bisacDialog");
            if (bisacDialog === undefined || bisacDialog === null) {
                bisacDialog = new BisacView({
                    model: this.model,
                    collections: this.collections,
                    formDef: this.options.formDef
                });
                this.subview("bisacDialog", bisacDialog);
            }
            var bisacHtml = bisacDialog.render().$el.prop('outerHTML');
            var self = this;

            var confirmDialog = function (event) {
                bisacDialog.dialogConfirm();
                var blockBisac = self.subview("block_bisac");
                blockBisac.find('classifications').doRender = true;
                blockBisac.rebind("classifications");
                blockBisac.render();
                self.validateOnServer();
                $('.' + bisacDialog.id + '-close').click();
            };


            ConfirmationHelper.openDialog(bisacHtml, this.subview("bisacDialog").id, confirmDialog);
            this.subview("bisacDialog").dialogOpened();
        } else if ("openSeries" === event.currentTarget.attributes["action"].value || "openSet" === event.currentTarget.attributes["action"].value) {
            Chaplin.mediator.publish('redirectTo', {
                url: 'collection_list',
                data: {
                    id: undefined,
                    rh: this.model.get('identifier'),
                    search: 'RH=' + this.model.get('identifier'),
                    mode: 'bool',
                    sortCol: 'hierarchySort',
                    contentType: this.model.get('collectionType'),
                    collectionId: this.model.get('id')
                }
            });
        } else if (_.string.startsWith(event.currentTarget.attributes["action"].value, 'selectEurozone')) {
            var orgType = event.currentTarget.attributes["action"].value.substring('selectEurozone'.length);
            var type = orgType;
            if (type.length === 1) {
                type = '0' + type;
            }
            var salesRights = this.model.get('salesRights');
            for (var i = 0; i < salesRights.length; i++) {
                if (salesRights[i] != null && salesRights[i].type === type) {
                    var currentSelection = this.model.get('salesRights.' + i + '.country');
                    if (currentSelection === null) {
                        currentSelection = "";
                    }
                    for (var j = 0; j < eurozone.length; j++) {
                        if (currentSelection.indexOf(eurozone[j]) < 0) {
                            if (currentSelection.length > 0) {
                                currentSelection += ',';
                            }
                            currentSelection += eurozone[j];
                        }
                    }
                    this.model.set('salesRights.' + i + '.country', currentSelection);
                    $('#salesRights_country_' + (orgType - 1)).trigger('change');
                    this.subview("block_salesRights").render();
                }
            }
        } else if (_.string.startsWith(event.currentTarget.attributes["action"].value, 'exportReport')) {
            var localURL = apiRoot + 'reporting/' + action.method + '' + '?access_token=' + user.access_token;
            var identNumbers = this.model.get('identNumber')[0].publisherId.split(',');
            var o = {};
            o.ids = [];
            for (var i = 0; i < identNumbers.length; i++) {
                o.ids.push(identNumbers[i]);
            }
            ConfirmationHelper.doTheConfirmation(JSON.stringify(o), 'json', localURL, 'POST', action, undefined);
        } else if (_.string.startsWith(event.currentTarget.attributes["action"].value, 'VgWort')) {
            var attributeAction = event.currentTarget.attributes["action"].value;
            var localUrl = apiRoot + 'vgwort?access_token=' + user.access_token;
            if (attributeAction === 'VgWort_export') {
                console.log(localUrl);
                ConfirmationHelper.downloadReport(localUrl);
            } else if (attributeAction === 'VgWort_export') {
                this.initFileupload(localUrl);
            }
        } else {
            var that = this;
            var thatEvent = event;
            var o = {'id': this.model.get('id')};
            var successCallback = function (data) {
                if ("actionBtn-deleteAccount" === thatEvent.currentTarget.attributes["id"].value) {
                    Chaplin.mediator.publish('redirectTo', {
                        url: 'account_list'
                    });
                } else if ("actionBtn-deleteUser" === thatEvent.currentTarget.attributes["id"].value) {
                    var previousPage = 'account_list';
                    var counter = 0;

                    // find out if the previous page was the profile view
                    while (counter < HISTORY_SIZE) {
                        counter++;
                        var historyStack = HistoryHelper.getHistoryRelativeFromCurrent(counter * -1);
                        if (historyStack === undefined) {
                            break;
                        } else if (historyStack.fragment.indexOf('profile') > -1) {
                            previousPage = 'profile_view';
                            break;
                        }
                    }

                    Chaplin.mediator.publish('redirectTo', {
                        url: previousPage
                    });
                }

            };
            ConfirmationHelper.confirmSingleAction(event, this.options, successCallback, o);
        }
    }
    ,

    removeEmptyEntires: function (anArray) {
        for (var i = 0; i < anArray.length; i++) {
            if (MiscHelper.isEmpty(anArray[i])) {
                anArray.splice(i, 1);
            }
        }
    }
    ,

    fillSelects: function (clone) {
        for (var key in this.collections) {
            var mapping = this.collections[key];
            for (var i = 0; i < mapping.selects.length; i++) {
                // If the selector is a span element the element can not be an input field.
                if (!mapping.selects[i].readonly && !$(mapping.selects[i].selector).is('span')) {
                    if (mapping.selects[i].multiple) {
                        if (mapping.selects[i].multipleAsFlat === true) {
                            var val = $(mapping.selects[i].selector).val();
                            if (val !== undefined && val !== null) {
                                clone.set(mapping.selects[i].key, val.replace(new RegExp('[,]', 'g'), ' '));
                            } else {
                                clone.set(mapping.selects[i].key, null);
                            }
                        } else {
                            var ids = $(mapping.selects[i].selector).val().split(',');
                            if (mapping.selects[i].simpleProperty === false) {
                                var values = [];
                                for (var k = 0; k < ids.length; k++) {
                                    for (var j = 0; j < mapping.options.length; j++) {
                                        if (mapping.options[j].id === ids[k]) {
                                            values.push(mapping.options[j]);
                                            break;
                                        }
                                    }
                                }

                                clone.set(mapping.selects[i].key, values);
                            } else {
                                this.removeEmptyEntires(ids);
                                clone.set(mapping.selects[i].key, ids);
                            }
                        }
                    } else {
                        var selectKey = mapping.selects[i].key;
                        var value = $(mapping.selects[i].selector).val();

                        if (value === null) {
                            value = null;
                            selectKey = selectKey.substring(0, selectKey.lastIndexOf('.'));
                        }

                        Logger.debug("mapping value " + value + " to selectKey " + selectKey);
                        clone.set(selectKey, value);

                    }
                }
            }
        }
    }
    ,

    fillTypeaheads: function (clone) {
        for (var key in this.collections) {
            var mapping = this.collections[key];
            if (mapping.typeaheads) {
                for (var i = 0; i < mapping.typeaheads.length; i++) {
                    if (!mapping.typeaheads[i].readonly) {
                        var ids = $(mapping.typeaheads[i].selector).val().split(',');
                        if (mapping.typeaheads[i].simpleProperty === false) {
                            var values = [];
                            for (var k = 0; k < ids.length; k++) {
                                for (var j = 0; j < mapping.options.length; j++) {
                                    if (mapping.options[j].id === ids[k]) {
                                        values.push(mapping.options[j]);
                                        break;
                                    }
                                }
                            }

                            clone.set(mapping.typeaheads[i].key, values);
                        } else {
                            this.removeEmptyEntires(ids);
                            clone.set(mapping.typeaheads[i].key, ids);
                        }
                    }
                }
            }
        }
    }
    ,

    confirmationSingle: function (event) {

        var that = this;
        var action = ActionHelper.findActionById(this.options.formDef, event.currentTarget.attributes["action"].value);

        var id = event.currentTarget.attributes["data-id"].value;
        if ((action['method'] === 'copy' || action['method'] === 'copyAsEbook' || action['method'] === 'history')) {
            if (this.model.get('publisherForMvbId') === true) {
                Chaplin.mediator.publish('redirectTo', {
                    url: that.options.modelKey + '_' + action['method'].toLowerCase(),
                    data: {
                        id: id
                    }
                });
            } else {
                var scopeData = {};
                scopeData.text = MsgHelper.getMessage('label.form.confirmationAcknowledge.notAllowed.text.' + action['method']);
                scopeData.ident = action['method'];
                scopeData.whichButton = 'ok';
                var html = ConfirmationAcknowledge(scopeData);
                var confirmDialog = function (event) {
                    $.fancybox.close();
                    var div = $('#' + scopeData.ident + 'Confirmation');
                    div.fadeOut('slow');
                    div.remove();
                }
                ConfirmationHelper.openDialog(html, scopeData.ident, confirmDialog);
            }
        } else {
            var o = {};
            o.id = this.model.get('id');
            o.active = this.model.get('active');
            o.type = this.model.get('type');
            o.aggregatorId = this.model.get('aggregatorId');
            // TODO eliminate 'publisherForMvbId'
            o.publisherForMvbId = this.model.get('publisherForMvbId');
            o.publisherId = this.model.get('publisherForMvbId');
            o.productVLB = MiscHelper.isEmpty(o.aggregatorId) || '0' === o.aggregatorId || '1' === o.aggregatorId;
            if(this.model.get('identifiers')){
              for (var i = 0; i < this.model.get('identifiers').length; i++) {
                var identifier = this.model.get('identifiers')[i];
                if (identifier.type === '15') {
                  o.isbn = identifier.value;
                  break;
                }
              }
            }
            //this.model.put('productVLB'.o.productVLB);

            var successCallback = function (data) {
                if (action['method'] === 'archive' || action['method'] === 'reactivate' || action['method'] === 'migrate') {
                    location.reload();
                } else if (action['id'] === 'deleteTitleList') {
                    that.backToSearch();
                }
            }
            ConfirmationHelper.confirmSingleAction(event, this.options, successCallback, o);
        }
    },
    jumpToError: function () {
        // this seems to do the magic
        // as we check not only for not empty elements we also check for not hidden elements aswell
        // so in the end we only have the visible errors
        var errors = $('.vlx_error:not(:empty)').not('.hidden');
        //var errors = $('.vlx_error:not(:empty)');
        if (errors) {
            this.lastError = this.lastError + 1 < errors.length ? this.lastError + 1 : 0;
            var error = errors[this.lastError];
            if (error !== undefined) {
                $('html, body').animate({scrollTop: $(error).offset().top - 300}, 'slow');
            }
        }
    },

    getAsParamMap: function (aoData) {
        var paramMap = {};
        for (var i = 0; i < aoData.length; i++) {
            paramMap[aoData[i].name] = aoData[i].value;
            if (aoData[i].tableSelector !== undefined) {
                paramMap["tableSelector"] = aoData[i].tableSelector;
            }
            if (aoData[i].model !== undefined) {
                paramMap["model"] = aoData[i].model;
            }
            if (aoData[i].view !== undefined) {
                paramMap["view"] = aoData[i].view;
            }
        }
        return paramMap;
    },

    /**
     *
     * @param sSource
     * @param aoData
     * @param fnCallback
     * @param oSettings
     */
    getPaginatorDataFromRest: function (sSource, aoData, fnCallback, oSettings) {

        var paramMap = FormView.prototype.getAsParamMap(aoData);
        var that = paramMap["that"];
        var thatOptions = that.options;
        var settings = oSettings;
        var model = 'product';

        if (paramMap['clickedInList']) {
            model = paramMap['clickedInList'].replace('_list', '');
        }
        if (parseInt(thatOptions.routeParams['record']) === settings._iDisplayStart && that.iTotalRecords !== undefined) {
            var data = {};
            data.iTotalRecords = that.iTotalRecords;
            data.iTotalDisplayRecords = that.iTotalRecords;
            data.content = [];
            data.content.push({id: that.model.get('id')});
            fnCallback(data);
            var width = $('#paginationTop .dataTables_paginate').width();
            width = Math.floor(width / 2.5);
            width = Math.min(width, 140);
            width = '-' + width;
            var style = {
                'margin-left': width + 'px'
            }
            $('.form-table .dataTables_paginate').css(style);

            return;
        }

        if (parseInt(thatOptions.routeParams['record']) === settings._iDisplayStart && that.iTotalRecords !== undefined) {
            var data = {};
            data.iTotalRecords = that.iTotalRecords;
            data.iTotalDisplayRecords = that.iTotalRecords;
            data.content = [];
            data.content.push({id: that.model.get('id')});
            fnCallback(data);
            var width = $('#paginationTop .dataTables_paginate').width();
            width = Math.floor(width / 2.5);
            width = Math.min(width, 140);
            width = '-' + width;
            var style = {
                'margin-left': width + 'px'
            }
            $('.form-table .dataTables_paginate').css(style);

            return;
        }

        jQuery.ajax({
            "dataType": 'json',
            url: "/api/v1/product/titleByIndex",
            type: "GET",
            async: false,
            data: {
                "index": oSettings._iDisplayStart,
                "model": model
            },
            "success": function (data) {
                sessionStorage.setItem('form-view-paginator', settings._iDisplayStart);
                data.iTotalRecords = data.totalElements;
                data.iTotalDisplayRecords = data.totalElements;
                fnCallback(data);
                if (that.iTotalRecords === undefined) {
                    // show is called the first time from a list of product. In that case redirectTo is not necessary
                    // because loading a product was already started.
                    that.iTotalRecords = data.totalElements;
                } else {
                    that.iTotalRecords = data.totalElements;
                    if (data.content[0].type === 'series' || data.content[0].type === 'set') {
                        var paramMap = FormView.prototype.getAsParamMap(aoData);
                        Chaplin.mediator.publish('redirectTo', {
                            url: 'collection_show_rec',
                            data: {
                                id: data.content[0]['id'],
                                record: settings._iDisplayStart,
                                clickedInList: paramMap['clickedInList'],
                                content: data.content[0],
                                iTotalRecords: data.totalElements
                            }
                        });
                    } else {
                        var paramMap = FormView.prototype.getAsParamMap(aoData);
                        Chaplin.mediator.publish('redirectTo', {
                            url: 'product_show_rec',
                            data: {
                                id: data.content[0]['id'],
                                record: settings._iDisplayStart,
                                clickedInList: paramMap['clickedInList'],
                                content: data.content[0],
                                iTotalRecords: data.totalElements
                            }
                        });
                    }
                }
            },
            "headers": {
                'Authorization': "Bearer " + user.access_token
            }
        });
    },

    initFileupload: function(downloadUrl) {
        Chaplin.mediator.subscribe('assetFileUploadSuccess', this.uploadSuccess, this);
        Chaplin.mediator.subscribe('assetFileUploadError', this.uploadError, this);
        $('#VgWort_import').fileupload({
            pasteZone:null,
            url: downloadUrl,
            sequentialUploads: true,
            headers: {
                'Authorization' :"Bearer " + user.access_token
            },
            add: function (e, data) {
                $('#asset-dialog-filename').val(data.fileInput.val().split(/(\\|\/)/g).pop());
                $('#asset-dialog-filename').change();
                $('#asset-dialog-upload').off("click");
                data.context = $('#asset-dialog-upload').click(function () {
                    AjaxSpinner.show(undefined, MsgHelper.formatMessage('label.form.assetUpload.uploading.message', [data.files[0].name]));
                    if (!data.form) {
                        data.form = new Object();
                    }

                    var jqXHR = data.submit()
                        .success(function(result, textStatus, jqXHR) {
                            AjaxSpinner.hide();
                            MsgHelper.clearAlerts();
                            Chaplin.mediator.publish('assetFileUploadSuccess', result, textStatus, jqXHR);
                        })
                        .error(function (jqXHR, textStatus, errorThrown) {
                            AjaxSpinner.hide();
                            MsgHelper.clearAlerts();
                            Chaplin.mediator.publish('assetFileUploadError', jqXHR, textStatus, errorThrown);
                        });
                });
            }
        });
    }
});