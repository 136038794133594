var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <div class=\"hint-red-only\">\n            "
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.assets.copyhint", {"name":"lbl","hash":{},"data":data})))
    + "\n        </div>\n        </p>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.isMmo : stack1), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;
  return "                                    <option value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.name : stack1), depth0))
    + "\">"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "assetFileType", ((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.name : stack1), {"name":"label2","hash":{},"data":data})))
    + "</option>\n";
},"6":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "                                <option value=\""
    + escapeExpression(lambda(depth0, depth0))
    + "\">"
    + escapeExpression(lambda(depth0, depth0))
    + "</option>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div id=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-content\" class=\"assetsForm\">\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isCopy : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n    <h4><span>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, (depth0 != null ? depth0.labelPrefix : depth0), "uploaded.headline", {"name":"label2","hash":{},"data":data})))
    + "</span></h4>\n\n    <div id=\"asset-list-view\" class=\"clearfix\"></div>\n\n    <h4 class=\"mandetory\"><span>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, (depth0 != null ? depth0.labelPrefix : depth0), "upload.headline", {"name":"label2","hash":{},"data":data})))
    + "</span></h4>\n    <form class=\"form-horizontal asset-upload-form\">\n        <input type=\"hidden\" name=\"isbn\" value=\""
    + escapeExpression(((helper = (helper = helpers.isbn || (depth0 != null ? depth0.isbn : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"isbn","hash":{},"data":data}) : helper)))
    + "\"/>\n        <input type=\"hidden\" name=\"productId\" value=\""
    + escapeExpression(((helper = (helper = helpers.productId || (depth0 != null ? depth0.productId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"productId","hash":{},"data":data}) : helper)))
    + "\"/>\n        <div>\n            <div class=\"form-group\">\n                <div class=\"row\">\n                    <div class=\"col-xs-3\">\n                        <div class=\"form-group form-actions fileupload-buttonbar\">\n                                <span class=\"btn btn-success fileinput-button\">\n                                    <span>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, (depth0 != null ? depth0.labelPrefix : depth0), "select.file", {"name":"label2","hash":{},"data":data})))
    + "</span>\n                                    <input id=\"fileupload\" type=\"file\" multiple=\"\" name=\"file\"/>\n                                </span>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-4\">\n                        <input type=\"text\" id=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-filename\" name=\"filename\" disabled=\"true\">\n                    </div>\n                </div>\n                <div class=\"row\">\n                    <div class=\"col-xs-3\"></div>\n                    <div class=\"col-xs-4\">\n                        <label for=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-mediatype\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.assets.mediatype.placeholder", {"name":"lbl","hash":{},"data":data})))
    + "</label>\n                        <select id=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-mediatype\" name=\"mediatype\" class=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-mediatype\">\n                            <option></option>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.assetFileTypes : depth0), {"name":"each","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "                        </select>\n                    </div>\n                    <div class=\"col-xs-3\">\n                        <label for=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-medianumber\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.assets.medianumber.placeholder", {"name":"lbl","hash":{},"data":data})))
    + "</label>\n                        <select id=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-medianumber\" name=\"medianumber\" class=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-medianumber\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.numbers : depth0), {"name":"each","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                        </select>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row\">\n            <div class=\"col-xs-7\"></div>\n            <div class=\"col-xs-2\">\n                <div class=\"form-actions\">\n                    <a href=\"javascript:;\" id=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-upload\" onclick=\"return false;\"\n                       class=\"disabled btn green btn-arrow "
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-upload\"><span>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, (depth0 != null ? depth0.labelPrefix : depth0), "upload", {"name":"label2","hash":{},"data":data})))
    + "</span></a>\n                </div>\n            </div>\n        </div>\n\n    </form>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}