var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "";
},"3":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <div class=\"add-icons\">\n            <a href=\"javascript:;\" onclick=\"return false;\" class=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-close myfancybox-close\"\n               title=\"Close\">CLOSE</a>\n        </div>\n";
},"5":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <br>"
    + escapeExpression(((helpers.safeString || (depth0 && depth0.safeString) || helperMissing).call(depth0, (depth0 != null ? depth0.textSecondLine : depth0), {"name":"safeString","hash":{},"data":data})))
    + "\n";
},"7":function(depth0,helpers,partials,data) {
  var stack1, buffer = "        <p>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.warnings : depth0), {"name":"each","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </p>\n";
},"8":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                "
    + escapeExpression(((helpers.safeString || (depth0 && depth0.safeString) || helperMissing).call(depth0, (depth0 != null ? depth0.text : depth0), {"name":"safeString","hash":{},"data":data})))
    + "<br>\n";
},"10":function(depth0,helpers,partials,data) {
  var stack1, buffer = "        <p>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.hint : depth0), {"name":"each","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </p>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div id=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "\" class=\"lb-default\">\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.noCloseCross : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n    <h2><span>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.confirmationSimple.headline", (depth0 != null ? depth0.ident : depth0), {"name":"label2","hash":{},"data":data})))
    + "</span></h2>\n\n    <p>\n        "
    + escapeExpression(((helpers.safeString || (depth0 && depth0.safeString) || helperMissing).call(depth0, (depth0 != null ? depth0.text : depth0), {"name":"safeString","hash":{},"data":data})))
    + "\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.textSecondLine : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </p>\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.warnings : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.hint : depth0), {"name":"if","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n    <div id=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-msg\">\n        <div id=\"error\" style=\"display: none\">\n            <div class=\"vlb-icon vlb-icon-input-error\"></div>\n            <span style=\"color: #e24844;\" class=\"help-inline nod_msg\"></span>\n        </div>\n        <div id=\"valid\" style=\"display: none\">\n            <div class=\"vlb-icon vlb-icon-input-check\"></div>\n            <span style=\"color: #14aa96;\" class=\"help-inline nod_msg\"></span>\n        </div>\n    </div>\n\n    <form class=\"form-horizontal\">\n        <div class=\"form-actions align-right\">\n            <a href=\"javascript:;\" onclick=\"return false;\"\n               class=\"btn green btn-arrow "
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-close\"><span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "cancel", {"name":"lbl","hash":{},"data":data})))
    + "</span></a>\n            <a href=\"javascript:;\" onclick=\"return false;\" class=\"btn green btn-arrow "
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-confirm\"><span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "confirm", {"name":"lbl","hash":{},"data":data})))
    + "</span></a>\n        </div>\n    </form>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}