module.exports = function (match) {
    match('', 'pageContent#show', {params: { id: "start", trackingLabel: "label.etracker.index"}, name: "start" });

    match('product/administration', 'pageContent#show', {name : 'titel_administration', params: {navEntry: 'administration', id: "titeladministration", trackingLabel: "label.etracker.administration", trackingAreaLabel: "label.etracker.area.administration"} });

    match('product/list', 'product#list', {name : 'product_list', params: {navEntry: 'administration', trackingLabel: "label.etracker.administration", trackingAreaLabel: "label.etracker.area.administration"} });
    match('product/create/:type', 'product#create', {name : 'product_create', params: {navEntry: 'administration', trackingLabel: "label.etracker.administration.create", trackingAreaLabel: "label.etracker.area.administration.create"} });
    match('product/show/:id', 'product#show', {name : 'product_show', params: {navEntry: 'administration', trackingLabel: "label.etracker.administration.show", trackingAreaLabel: "label.etracker.area.administration.show"} });
    match('product/show/:id/*record', 'product#show_rec', {name : 'product_show_rec', params: {navEntry: 'administration', trackingLabel: "label.etracker.administration.show", trackingAreaLabel: "label.etracker.area.administration.show"} });
    match('product/edit/:id/:scrollToId', 'product#edit', {name : 'product_edit_anchor', params: {navEntry: 'administration', trackingLabel: "label.etracker.administration.edit", trackingAreaLabel: "label.etracker.area.administration.edit"} });
    match('product/edit/:id', 'product#edit', {name : 'product_edit', params: {navEntry: 'administration', trackingLabel: "label.etracker.administration.edit", trackingAreaLabel: "label.etracker.area.administration.edit"} });
    match('product/editAssets/:id', 'product#editAssets', {name : 'product_editassets', params: {navEntry: 'administration', trackingLabel: "label.etracker.administration", trackingAreaLabel: "label.etracker.area.administration"} });
    match('product/editAssets/:id/*copy', 'product#editAssets_copy', {name : 'product_editassets_copy', params: {navEntry: 'administration', trackingLabel: "label.etracker.administration.editAsset", trackingAreaLabel: "label.etracker.area.administration"} });
    match('product/copy/:id', 'product#copy', {name : 'product_copy', params: {navEntry: 'administration', trackingLabel: "label.etracker.administration.copy", trackingAreaLabel: "label.etracker.area.administration"} });
    match('product/copyAsEbook/:id', 'product#copy', {name : 'product_copyasebook', params: {navEntry: 'administration', relateEbook: 1 , trackingLabel: "label.etracker.administration.copyAsEbook", trackingAreaLabel: "label.etracker.area.administration"} });

    match('collection/show/:id', 'collection#show', {params: { urlRoot:  "$api/collection", modelKey: 'collection'}, name : 'collection_show', trackingLabel:"label.etracker.administration.collection", trackingAreaLabel: "label.etracker.area.administration"});
    match('collection/show/:id/*record', 'collection#show_rec', {params: { urlRoot:  "$api/collection", modelKey: 'collection'}, name : 'collection_show_rec', trackingLabel:"label.etracker.administration.collection", trackingAreaLabel: "label.etracker.area.administration"});
    match('collection/edit/:id', 'collection#edit', {params: { urlRoot:  "$api/collection", modelKey: 'collection'}, name : 'collection_edit', trackingLabel:"label.etracker.administration.collection", trackingAreaLabel: "label.etracker.area.administration"});
    match('collections/collection/:rh/:contentType', 'collection#list', {params: { urlRoot:  "$api/collections/collection"}, name : 'collection_list', trackingLabel:"label.etracker.administration.collection", trackingAreaLabel: "label.etracker.area.administration"});

    match('product/history/:id', 'model#show', {params: { urlRoot:  "$api/productHistory", modelKey: "productHistory", navEntry: 'ts', trackingLabel:"label.etracker.administration.history", trackingAreaLabel: "label.etracker.area.administration.history"}, name : 'collection_history'});
    match('product/history/:id', 'model#show', {params: { urlRoot:  "$api/productHistory", modelKey: "productHistory", navEntry: 'ts', trackingLabel:"label.etracker.administration.history", trackingAreaLabel: "label.etracker.area.administration.history"}, name : 'product_history'});
    match('product/history/:id', 'model#show', {params: { urlRoot:  "$api/productHistory", modelKey: "productHistory", navEntry: 'ts', trackingLabel:"label.etracker.administration.history", trackingAreaLabel: "label.etracker.area.administration.history"}, name : 'productTracker_history'});
    match('product/history/:id', 'model#show', {params: { urlRoot:  "$api/productHistory", modelKey: "productHistory", navEntry: 'ts', trackingLabel:"label.etracker.administration.history", trackingAreaLabel: "label.etracker.area.administration.history"}, name : 'productTrackerEdit_history'});

    match('account/create', 'account#create', {name : 'account_create', params: {trackingLabel: "label.etracker.account"}});
    match('account/show/:id', 'account#show', {name : 'account_show', params: {trackingLabel: "label.etracker.account"}});
    match('account/edit/:id', 'account#edit', {name : 'account_edit', params: {trackingLabel: "label.etracker.account"}});
    match('account/list', 'account#list', {name : 'account_list', params: {trackingLabel: "label.etracker.account"}});
    
    match('user/create', 'model#create', {params: { urlRoot:  "user", modelKey: "user", trackingLabel: "label.etracker.user"}, name: "user_create" });
    match('user/copy/:id', 'model#copy', {params: { urlRoot:  "user", modelKey: "user", trackingLabel: "label.etracker.user"}, name: "user_copy" });
    match('user/show/:id', 'model#show', {params: { urlRoot:  "user", modelKey: "user", trackingLabel: "label.etracker.user"}, name: "user_show" });
    match('user/show/:id', 'model#show', {params: { urlRoot:  "user", modelKey: "user", sendCredentials: true, trackingLabel: "label.etracker.user"}, name: "user_showAndSendCredentials" });
    match('user/edit/:id', 'model#edit', {params: { urlRoot:  "user", modelKey: "user", trackingLabel: "label.etracker.user"}, name: "user_edit" });
    match('user/list', 'model#list', {params: { urlRoot:  "user", modelKey: "user", trackingLabel: "label.etracker.user"}, name: "user_list" });

    match('apiclientapp/create', 'model#create', {params: { urlRoot:  "tokenadmin/client", modelKey: "apiclientapp", trackingLabel: "label.etracker.apiclientapp"}, name: "apiclientapp_create" });
    match('apiclientapp/show/:id', 'model#show', {params: { urlRoot:  "tokenadmin/client", modelKey: "apiclientapp", trackingLabel: "label.etracker.apiclientapp"}, name: "apiclientapp_show" });
    match('apiclientapp/edit/:id', 'model#edit', {params: { urlRoot:  "tokenadmin/client", modelKey: "apiclientapp", trackingLabel: "label.etracker.apiclientapp"}, name: "apiclientapp_edit" });
    match('apiclientapp/list', 'model#list', {params: { urlRoot:  "tokenadmin/client", modelKey: "apiclientapp", trackingLabel: "label.etracker.apiclientapp"}, name: "apiclientapp_list" });
    match('apitoken/list', 'model#list', {params: { urlRoot:  "tokenadmin/token", modelKey: "apitoken", trackingLabel: "label.etracker.apitoken"}, name: "apitoken_list" });
    match('apitoken/create', 'model#create', {params: { urlRoot:  "tokenadmin/token", modelKey: "apitoken", trackingLabel: "label.etracker.apitoken"}, name: "apitoken_create" });
    
    match('productTracker/list', 'pageContent#show', {params: { id: "searchhistoryPage", activeWidget: "productTracker", navEntry: 'searchhistory', trackingLabel: "label.etracker.searchhistory"}, name: "productTracker_list" });
    match('productTrackerEdit/edit', 'pageContent#show', {params: { id: "searchhistoryPage", activeWidget: "productTrackerEdit", navEntry: 'searchhistory', trackingLabel: "label.etracker.searchhistory"}, name: "productTrackerEdit_list" });
    match('productTracker/copy/:id', 'product#copy', {name : 'productTracker_copy', params: {navEntry: 'ts'} });
    match('productTrackerEdit/copy/:id', 'product#copy', {name : 'productTrackerEdit_copy', params: {navEntry: 'ts'} });
    match('productTracker/copyAsEbook/:id', 'product#copy', {name : 'productTracker_copyasebook', params: {navEntry: 'ts', relateEbook: 1 } });
    match('productTrackerEdit/copyAsEbook/:id', 'product#copy', {name : 'productTrackerEdit_copyasebook', params: {navEntry: 'ts', relateEbook: 1 } });
    match('search/searchhistorys', 'pageContent#show', {params: { id: "searchhistoryPage", activeWidget: "searchhistory", navEntry: 'searchhistory', trackingLabel: "label.etracker.searchhistory"}, name : 'searchhistory_list'});
    match('search/searchhistorysSaved', 'pageContent#show', {params: { id: "searchhistoryPage", activeWidget: "searchhistorySaved", navEntry: 'searchhistory', trackingLabel: "label.etracker.searchhistory"}, name : 'searchhistorySaved_list'});
    
    match('search/executequery/:id', 'search#executequery', {name : 'searchhistory_executequery', params: {navEntry: 'search', trackingLabel: "label.etracker.search"}});
    match('search/executequery/:id', 'search#executequery', {name : 'searchhistorySaved_executequery', params: {navEntry: 'search', trackingLabel: "label.etracker.search"}});

    match('search', 'pageContent#show', {params: { id: "searchPage", activeWidget: "advancedsearch", navEntry: 'search', trackingLabel: "label.etracker.search", trackingAreaLabel: "label.etracker.area.search"}, name : 'search_page'});
    match('search', 'pageContent#show', {params: { id: "searchPage", activeWidget: "boolsearch", navEntry: 'search', trackingLabel: "label.etracker.search", trackingAreaLabel: "label.etracker.area.search"}, name : 'search_page_boole'});
    match('search', 'pageContent#show', {params: { id: "searchPage", activeWidget: "advancedsearch", navEntry: 'search', trackingLabel: "label.etracker.search", trackingAreaLabel: "label.etracker.area.search"}, name : 'search_page_advanced'});
    match('search', 'pageContent#show', {params: { id: "searchPage", activeWidget: "batchsearch", navEntry: 'search', trackingLabel: "label.etracker.search", trackingAreaLabel: "label.etracker.area.search"}, name : 'search_page_batch'});

    match('search/batch', 'search#batch', {name : 'search_batch', params: {navEntry: 'search', trackingLabel: "label.etracker.search.batch", trackingAreaLabel: "label.etracker.area.searchResult.batch"}});
    match('search/quick', 'search#quick', {name : 'search_quick', params: {navEntry: 'search', trackingLabel: "label.etracker.search.quick", trackingAreaLabel: "label.etracker.area.searchResult.quick"}});
    match('search/advanced', 'search#advanced', {name : 'search_advanced', params: {navEntry: 'search', trackingLabel: "label.etracker.search.advanced", trackingAreaLabel: "label.etracker.area.searchResult.advanced"}});
    match('search/boole', 'search#boole', {name : 'search_boole', params: {navEntry: 'search', trackingLabel:"label.etracker.search.boole", trackingAreaLabel: "label.etracker.area.searchResult.boole"}});
    match('search/quicksearch/:action', 'search#quicksearch', {params: {navEntry: 'search', trackingLabel: "label.etracker.search.quick", trackingAreaLabel: "label.etracker.area.searchResult.quick"}});
    match('search/quicksearch/:action/:search', 'search#quicksearch', {name : 'search_quicksearch', params: {navEntry: 'search', trackingLabel: "label.etracker.search.quick", trackingAreaLabel: "label.etracker.area.searchResult.quick"}});
    match('search/batchsearch/:search', 'search#batchsearch', {name : 'search_batchsearch', params: {navEntry: 'search', trackingLabel: "label.etracker.search.batch", trackingAreaLabel: "label.etracker.area.searchResult.batch"}});
    match('search/advancedsearch/:search', 'search#advancedsearch', {name : 'search_advancedsearch', params: {navEntry: 'search', trackingLabel: "label.etracker.search.advanced", trackingAreaLabel: "label.etracker.area.searchResult.advanced"}});
    match('search/boolesearch/:search', 'search#boolesearch', {name : 'search_boolesearch', params: {navEntry: 'search', trackingLabel:"label.etracker.search.boole", trackingAreaLabel: "label.etracker.area.searchResult.boole"}});
    match('search/boolesearch/:search/:sortCol', 'search#boolesearch', {name : 'search_boolesearchSortCol', params: {navEntry: 'search', trackingLabel:"label.etracker.search.boole", trackingAreaLabel: "label.etracker.area.searchResult.boole"}});

    match('search', 'search#quick', {name : 'search_newsearch', params: {navEntry: 'search', trackingLabel: "label.etracker.search"}});
    match('search', 'search#quick', {name : 'search_changesearch', params: {navEntry: 'search', trackingLabel: "label.etracker.search"}});
    
    match('group/create', 'model#create', {params: { urlRoot:  "group", modelKey: "group", trackingLabel: "label.etracker.group"}, name: "group_create" });
    match('group/show/:id', 'model#show', {params: { urlRoot:  "group", modelKey: "group", trackingLabel: "label.etracker.group"}, name: "group_show"});
    match('group/edit/:id', 'model#edit', {params: { urlRoot:  "group", modelKey: "group", trackingLabel: "label.etracker.group"} , name: "group_edit"});
    match('group/list', 'model#list', {params: { urlRoot:  "group", modelKey: "group", trackingLabel: "label.etracker.group"}, name: "group_list" });
    
    match('workflow/list', 'model#list', {params: { urlRoot:  "$api/workflow", modelKey: "workflow" },name : 'workflow_list'});
    match('workflow/show/:id', 'model#show', {params: { urlRoot:  "$api/workflow", modelKey: "workflow" },name : 'workflow_show'});
    match('workflow/edit/:id', 'model#edit', {params: { urlRoot:  "$api/workflow", modelKey: "workflow" },name : 'workflow_edit'});

    match('ftpentry/list', 'model#list', {params: { urlRoot:  "$api/ftpentry", modelKey: "ftpentry", trackingLabel: "label.etracker.ftpentry"}, name : 'ftpentry_list'});
    match('ftpentry/show/:id', 'model#show', {params: { urlRoot:  "$api/ftpentry", modelKey: "ftpentry", trackingLabel: "label.etracker.ftpentry"}, name : 'ftpentry_show'});
    
    match('userDownload/list', 'userDownload#list', {name : 'userDownload_list', params: {navEntry: 'exporthistory', trackingLabel: "label.etracker.exporthistory"}});
    match('userDownload/show/:id', 'userDownload#show', {name : 'userDownload_show', params: {navEntry: 'exporthistory', trackingLabel: "label.etracker.exporthistory"}});
    match('userDownload/edit/:id', 'userDownload#edit', {name : 'userDownload_edit', params: {navEntry: 'exporthistory', trackingLabel: "label.etracker.exporthistory"}});
    
    
//    match('renewforms', 'home#renewforms', {name : 'home_renewforms'});
    
    // functional logging in backoffice
    match('logentry/list', 'model#list', {params: { urlRoot:  "$api/logentry", modelKey: "logentry", trackingLabel: "label.etracker.logentry"}, name : 'logentry_list'});
    match('assetLogentry/list', 'model#list', {params: { urlRoot:  "$api/assetLogentry", modelKey: "assetLogentry", trackingLabel: "label.etracker.assetLogentry"}, name : 'assetLogentry_list'});
    match('logentry/show/:id', 'model#show', {params: { urlRoot:  "$api/logentry", modelKey: "logentry", trackingLabel: "label.etracker.logentry"},name : 'logentry_show'});
    match('assetLogentry/show/:id', 'model#show', {params: { urlRoot:  "$api/assetLogentry", modelKey: "assetLogentry", trackingLabel: "label.etracker.assetLogentry"},name : 'assetLogentry_show'});
    
    match('process/list', 'model#list', {params: { urlRoot:  "$api/process", modelKey: "process" }, name : 'process_list'});
    match('process/show/:id', 'model#show', {params: { urlRoot:  "$api/process", modelKey: "process" },name : 'process_show'});
    
    match('protocol/list', 'pageContent#show', {params: { id: "protocolPage", activeWidget: "metadataFileProtocol"}, name: "protocol_view" });
    
    // user profile page
    match('profile/overview', 'pageContent#show', {params: { id: "profilePage", activeWidget: "userOverview", trackingLabel: "label.etracker.profilePage"}, name: "profile_view" });
    match('profile/profile', 'pageContent#show', {params: { id: "profilePage", activeWidget: "userProfile", trackingLabel: "label.etracker.profilePage"}, name: "profile_show" });
    match('profile/orderbookOptions', 'pageContent#show', {params: { id: "profilePage", activeWidget: "orderbookOptions", trackingLabel: "label.etracker.profilePage"}, name: "orderOptions_show" });
    match('profile/userExportSetting', 'pageContent#show', {params: { id: "profilePage", activeWidget: "userExportSetting", trackingLabel: "label.etracker.profilePage"}, name: "userExportSetting_show" });
    match('profile/annualInvoice', 'pageContent#show', {params: { id: "profilePage", activeWidget: "annualInvoice", trackingLabel: "label.etracker.profilePage"}, name: "profile_annualInvoice" });

    match('aggregatedLogentry/list', 'model#list', {params: { urlRoot:  "$api/aggregatedLogentry", modelKey: "aggregatedLogentry", trackingLabel: "label.etracker.assetLogentry"},name : 'aggregatedLogentry_list'});
    match('aggregatedLogentry/show/:id', 'model#show', {params: { urlRoot:  "$api/aggregatedLogentry", modelKey: "aggregatedLogentry", trackingLabel: "label.etracker.assetLogentry"},name : 'aggregatedLogentry_show'});
      
    match('merkListCategory/list', 'model#list', {params: { urlRoot:  "merkListCategory", modelKey: "merkListCategory"},name : 'merkListCategory_list'});
    match('yellowSupplement/list', 'model#list', {params: { urlRoot:  "yellowSupplement", modelKey: "yellowSupplement", trackingLabel: "label.etracker.yellowSupplement"},name : 'yellowSupplement_list'});
    match('yellowSupplement/show/:id', 'model#show', {params: { urlRoot:  "yellowSupplement", modelKey: "yellowSupplement", trackingLabel: "label.etracker.yellowSupplement"}, name: "yellowSupplement_show"});
    match('yellowSupplement/edit/:id', 'model#edit', {params: { urlRoot:  "yellowSupplement", modelKey: "yellowSupplement", trackingLabel: "label.etracker.yellowSupplement"} , name: "yellowSupplement_edit"});

    match('orderbook', 'pageContent#show', {params: { id: "orderbookPage", activeWidget: "openOrders", navEntry: 'orderbook', trackingLabel: "label.etracker.orderbook"}, name : 'orderbook_page'});
    match('orderbook/openOrders', 'pageContent#show', {params: { id: "orderbookPage", activeWidget: "openOrders", navEntry: 'orderbook', trackingLabel: "label.etracker.orderbook"}, name : 'openOrders_list'});
    match('orderbook/sentOrders', 'pageContent#show', {params: { id: "orderbookPage", activeWidget: "sentOrders", navEntry: 'orderbook', trackingLabel: "label.etracker.orderbook"}, name : 'sentOrders_list'});

    match('reporting', 'pageContent#show', {params: {id: 'reportingPage', activeWidget: 'qualityReport', navEntry: 'reporting', trackingLabel: "label.etracker.reporting"}, name: 'reporting_page'})
    match('reporting/list', 'model#list', {params: { urlRoot:  "$api/reporting", modelKey: "reporting", trackingLabel: "label.etracker.reporting"},name : 'reporting_list'});
    match('reporting/show/:id', 'model#show', {params: { urlRoot:  "$api/reporting", modelKey: "reporting", trackingLabel: "label.etracker.reporting"}, name: "reporting_show"});
    match('reporting/edit/:id', 'model#edit', {params: { urlRoot:  "$api/reporting", modelKey: "reporting", trackingLabel: "label.etracker.reporting"} , name: "reporting_edit"});

    
    match('renewforms', '#renewforms', {name : 'renewforms'});
    match('renewpages', '#renewpages', {name : 'renewpages'});
};
