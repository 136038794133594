var Application = require('application');
var AjaxSpinner = require('lib/ajaxspinner_helper');
var View = require('./view');
var FormBlockTemplate = require('./templates/forms/block');
var MiscHelper = require('lib/misc_helper');
var MsgHelper = require('lib/msg_helper');
var PriceHelper = require('lib/price_helper');
var ValidationHelper = require('lib/validation_helper');
var AfterLoadEventsHelper = require('lib/afterLoad_event_helper');
var FormHelper = require('lib/form_helper');
var LinkHelper = require('lib/link_helper');
var TabHelper = require('lib/tab_helper');
var LogEntryHelper = require('lib/logentry_helper');
var EditableWidget = require('./forms/widgets/editable');
var RadioWidget = require('./forms/widgets/radio');
var NumberWidget = require('./forms/widgets/number');
var RadioIconWidget = require('./forms/widgets/radioIcon');
var SelectionWidget = require('./forms/widgets/selection');
var TypeaheadWidget = require('./forms/widgets/typeahead');
var DateWidget = require('./forms/widgets/datefield');
var HiddenWidget = require('./forms/widgets/hidden');
var BaseWidget = require('./forms/widgets/base');
var CompositeWidget = require('./forms/widgets/composite');
var TableCompositeWidget = require('./forms/widgets/composite_table');
var ListCompositeWidget = require('./forms/widgets/composite_list');
var ActionHelper = require('lib/action_helper');
var EventsHelper = require('lib/events_helper');
var MailView = require("./mail_view");
var StandardViewWidget = require('./standard_view_widget');
var MultipieceView = require('./multipiece');
var ConfirmationHelper = require('lib/confirmation_helper');
var StringUtil = require('lib/string_util');

var Logger = log4javascript.getLogger('views.FormBlockView');

module.exports = FormBlockView = View.extend({

    autoRender: false,

    initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);
        this.modelBinder = new Backbone.ModelBinder();
        this.bindings = {};
        this.linkWidgets = [];
        this.selectWidgets = [];
        this.typeaheadWidgets = [];
        this.compositeWithSelectWidgets = [];
        this.datePickers = [];
        this.compositeWithDatePickers = [];
        this.compositeWidgets = [];
        this.widgetsWithEvents = [];
        this.widgetsWithAfterLoadEvents = [];
        this.widgetsWithSpecialBehaviour = [];
        this.fieldSets = [];
        this.pageableTables = [];
    },

    startBlock: function (me, callback) {
        if (me.options == undefined) {
            callback();
            return;
        }

        var block = me.options.blockDef;

        me.options.blockDef.title = 'form.' + me.options.formDef.attributes.model + '.block.' + block.blockId;
        for (var j = 0; j < block['fieldSet'].length; j++) {
            var fieldSet = block['fieldSet'][j];
            me.fieldSets.push(fieldSet);
            fieldSet.fieldSetIndex = j;
            fieldSet.label = "form." + me.model.modelKey + ".fieldset." + fieldSet['title'];
            fieldSet.isEditable = !block.readonly; //me.options.formDef.attributes.isEditable;
            fieldSet.display = '';
            //var hideFieldset = (fieldSet.showOn != undefined && !MiscHelper.compareComplex(me.model.attributes, fieldSet.showOn))
            //fieldSet.display = hideFieldset ? 'hidden' : '';
            if ((fieldSet.collapsible === true && fieldSet.status === undefined)) {
                fieldSet.status = "closed";
                fieldSet.closedDescription = fieldSet.label + ".closed.description";
            }

            fieldSet.removeLabel = MsgHelper.getMessageOrDefault('label.' + fieldSet.label + '.remove', '');

            me.prepareColumns(fieldSet['column'], fieldSet);
            me.prepareGroups(fieldSet['group'], fieldSet);
        }

        me.render();
        // attach subviews if present
        for (var i = 0; i < me.subviews.length; i++) {
            me.subviews[i].attach();
        }
        callback(block);
    },

    events: {
        'click .listSet .add': 'addListWidget',
        'click .listSet .remove': 'removeListWidget',
        'click .listSet .toggleHidden': 'toggleHiddenListWidget',
        'click .sendCredentials': 'sendCredentials',
        'keyup textarea[data-maxlength]': 'updateTextAreaCounter',
        'change textarea[data-maxlength]': 'updateTextAreaCounter'
    },

    getTemplateFunction: function () {
        return FormBlockTemplate;
    },

    getTemplateData: function () {
        var data = View.prototype.getTemplateData.call(this);
        data.blockDef = this.options.blockDef;
        data.showTitle = this.options.showTitle;
        data.mode = this.options.formDef.get('mode').toLowerCase();

        for (var i = 0; i < data.blockDef.fieldSet.length; i++) {
            var fieldset = data.blockDef.fieldSet[i];
            if (fieldset.showOn !== undefined) {
                if (MiscHelper.compareComplex(this.model.attributes, fieldset.showOn)) {
                    fieldset.display = '';
                } else {
                    fieldset.display = 'hidden';
                }
            }
        }

        // define the block title
        data.title = data.blockDef.title;
        // define the help link 
        data.helplink = data.blockDef.title;
        // different block titles because of the product type
        if ((this.model.get('type') === 'duoBundle' || this.model.get('type') === 'multiBundle')) {
            if (data.blockDef.blockId === 'prices') {
                data.title = data.blockDef.title + 'Bundle';
            }
        } else if (this.model.get('type') === 'journal') {
            var blockId = data.blockDef.blockId;

            // set the help link for zis
            if (blockId === 'productType'
                || blockId === 'publishers'
                || blockId === 'identifiers'
                || blockId === 'title'
                || blockId === 'contributors'
                || blockId === 'pricesJournal'
                || blockId === 'pricesJournalBundle'
                || blockId === 'edition'
                || blockId === 'availability'
                || blockId === 'productclassification'
                || blockId === 'audiences'
                || blockId === 'additionalInformation') {
                data.helplink = data.helplink + '.zis';
            }

            if (blockId === 'pricesJournal' && this.model.get('bundleswitch') === 'duoTax') {
                data.title = data.title + 'Bundle2';
            } else if (blockId === 'audiences') {
                data.title = data.title + '.zis';
            } else if (blockId === 'availability') {
                data.title = data.title + 'Journal';
            }
        }

        return data;
    },

    prepareColumns: function (columns, parentElement) {
        for (var cont in columns) {
            if (columns[cont] === undefined) {
                continue;
            }
            columns[cont].isEditable = parentElement.isEditable;
            columns[cont].compIndex = cont;
            if (!columns[cont].styleClass && parentElement.type !== 'SingleCompositeWidget') {
                columns[cont].styleClass = "col-md-" + Math.floor(12 / columns.length);
            }

            this.prepareWidgets(columns[cont], parentElement);
        }
    },

    prepareGroups: function (groups, parentElement) {
        for (var group in groups) {
            if (groups[group].filter) {
                if (groups[group].filter === 'logentry_filter') {
                    groups[group].filterHtml = LogEntryHelper.initFilter();
                }
            }
            for (var tab in groups[group]['tab']) {
                var column = groups[group]['tab'][tab];
                column.isEditable = parentElement.isEditable;
                this.prepareWidgets(column, parentElement);
            }
        }
    },

    prepareGroupFilters: function () {
        // initilize all group filters known
        for (var b in this.options.formDef.get('block')) {
            var block = this.options.formDef.get('block')[b];
            for (var f in block['fieldSet']) {
                var fieldSet = block['fieldSet'][f];
                for (var g in fieldSet['group']) {
                    var group = fieldSet['group'][g];
                    if (group.filter) {
                        // this filter is exclusive for the logentry page and is searching all tabs for isbns or message codes
                        if (group.filter === 'logentry_filter') {
                            var tables = this.options.tables;
                            var that = this;
                            $('#filterLogEntries').click(function () {
                                $('#groupFilter').val($('#filter-isbn').val() + "|" + $('#filter-errorcode').val());
                                for (var table in tables) {
                                    tables[table].draw();
                                }
                            });
                        }
                    }
                }
            }
        }
    },

    prepareWidgets: function (column, parentElement) {
        for (var k = 0; k < column['widget'].length; k++) {
            var widget = column['widget'][k];
            widget.widgetIndex = k;

            widget.isEditable = column.isEditable && !widget.readonly;

            if (widget.showOn !== undefined) {
                widget.doRender = MiscHelper.compareComplex(this.model.attributes, widget.showOn);
            } else if (widget.type === 'LinkWidget' || widget.type === 'HiddenWidget') {
                widget.doRender = true;
            } else if (this.options.formDef.get('showEmptyReadonlyFields') !== true
                && !parentElement.isComposite
                && widget.isEditable === false
                && widget.property !== undefined
                && MiscHelper.isEmpty(this.model.get(widget.property))) {
                widget.doRender = false;
            } else if (this.options.formDef.get('showEmptyReadonlyFields') !== true
                && parentElement.isComposite && widget.isEditable === false
                && parentElement.type === 'SingleCompositeWidget'
                && widget.property !== undefined
                && MiscHelper.isEmpty(this.model.get(parentElement.property + "." + widget.property))) {
                widget.doRender = false;
            } else {
                widget.doRender = true;
            }


            if (widget.isEditableType) {
                if (widget.type !== 'TableCompositeWidget' && widget.type !== 'ListCompositeWidget' && widget.type !== 'SingleCompositeWidget') {
                    if (parentElement.isComposite) {
                        column['widget'][k] = this.buildFormWidget(widget, parentElement['property'], parentElement.doesHandleBindings);

                    } else {
                        column['widget'][k] = this.buildFormWidget(widget);
                    }
                } else {
                    widget.isComposite = true;

                    if (parentElement.isComposite) {
                        widget.compIndex = parentElement.compIndex + "_" + k;
                        column['widget'][k] = this.buildFormCompositeWidget(widget, parentElement['property'], parentElement.doesHandleBindings);
                    } else {
                        widget.compIndex = k;
                        column['widget'][k] = this.buildFormCompositeWidget(widget);
                    }

                    // register widgets with after load events
                    if (widget.afterLoad !== undefined) {
                        this.widgetsWithAfterLoadEvents.push({widget: widget, afterLoad: widget.afterLoad});
                    }
                }
                //if its a fieldset we need to check if its opend or closed
                if (parentElement.collapsible === true && parentElement.status === "closed") {
                    if (column['widget'][k].hadValueOnLoad === true) {
                        parentElement.status = 'open';
                    }
                }
            } else if (widget.type == "ViewWidget") {

                // instanciate view and add to subviews
                var viewWidgetId = widget.viewName;

                if (widget.useStandardView) {
                    viewWidgetId = widget.viewName + this.cid;
                    this.subview(widget.viewName, new StandardViewWidget({
                        widget: widget,
                        parentView: this.options.parentView,
                        model: this.model,
                        container: '#' + viewWidgetId,
                        className: widget.viewName,
                        routeParams: this.options.routeParams
                    }));

                } else {
                    var ViewWidgetType = require('./' + widget.viewName);
                    this.subview(widget.viewName, new ViewWidgetType({
                        widget: widget,
                        parentView: this.options.parentView,
                        model: this.model,
                        container: '#' + widget.viewName,
                        routeParams: this.options.routeParams
                    }));
                }
                // render container for view

                widget.render = function () {
                    return '<div id="' + viewWidgetId + '" class="viewWidget"></div>';
                }
                column['widget'][k] = widget;
            } else {
                column['widget'][k] = new BaseWidget(widget, this.model.modelKey);
            }
        }
    },

    registerSelect: function (widget, selectMapping) {
        if (!MiscHelper.isEmpty(widget.collection)) {
            var options = widget.getOptions(this.options.formDef.attributes['collections'], this.model);
            if (!(widget.collection in this.options.parentView.collections)) {
                this.options.parentView.collections[widget.collection] = {options: options, selects: []};
            }

            if (selectMapping === undefined) {
                selectMapping = widget.getOptionsSelectMapping();
            }
            if (selectMapping) {
                this.options.parentView.collections[widget.collection].selects.push(selectMapping);
            }
        }
    },

    registerTypeahead: function (widget, selectMapping) {
        if (!MiscHelper.isEmpty(widget.collection)) {
            if (!(widget.collection in this.options.parentView.collections)) {
                var options = widget.getOptions(this.options.formDef.attributes['collections']);
                this.options.parentView.collections[widget.collection] = {options: options, typeaheads: []};
            }

            if (selectMapping === undefined) {
                selectMapping = widget.getOptionsSelectMapping();
            }
            if (selectMapping) {
                this.options.parentView.collections[widget.collection].typeaheads.push(selectMapping);
            }
        }
    },

    add2Binding: function (name, bindingSelector) {
        if (this.bindings[name]) {
            if (Array.isArray(this.bindings[name])) {
                this.bindings[name].push(bindingSelector);
            } else {
                if (this.bindings[name] != bindingSelector) {
                    this.bindings[name] = [this.bindings[name], bindingSelector];
                }
            }
        } else {
            this.bindings[name] = bindingSelector;
        }
    },

    buildFormWidget: function (widgetDef, listproperty, parentdoesHandleBindings) {
        var widgetType = widgetDef.type;
        var widget;
        if (widgetType === "RadioWidget") {
            widget = new RadioWidget(widgetDef, this.model.modelKey, listproperty);
            if (!parentdoesHandleBindings) {
                this.selectWidgets.push(widget);
            }
        } else if (widgetType === "RadioIconWidget") {
            widget = new RadioIconWidget(widgetDef, this.model.modelKey, listproperty);
            widget.action = ActionHelper.getActionOnIconClick(this.options.formDef, widget);
        } else if (widgetType === "SelectionWidget") {
            widget = new SelectionWidget(widgetDef, this.model.modelKey, listproperty);
            if (!parentdoesHandleBindings) {
                this.selectWidgets.push(widget);
            }
        } else if (widgetType === "HiddenWidget") {
            widget = new HiddenWidget(widgetDef, this.model.modelKey, listproperty);
        } else if (widgetType === "DateWidget") {
            widget = new DateWidget(widgetDef, this.model.modelKey, listproperty);
            //TODO should it be part of this view or of the widget itself???
            if (!widget.readonly && parentdoesHandleBindings === undefined) {
                this.datePickers.push(widget);
            } else {
                // TODO do some date conversion in here????
            }
        } else if (widgetType === "NumberWidget") {
            widget = new NumberWidget(widgetDef, this.model.modelKey, listproperty);
        } else if (widgetType === 'TypeaheadWidget') {
            widget = new TypeaheadWidget(widgetDef, this.model.modelKey, listproperty);
            this.typeaheadWidgets.push(widget);
        } else {
            widget = new EditableWidget(widgetDef, this.model.modelKey, listproperty);
        }

        var name = widget.name;


        // only do here if widget is NOT inside a multi composite
        if (!parentdoesHandleBindings) {
            // binding start
            var bindingSelector = widget.binding(this.model);
            for (var i = 0; i < bindingSelector.length; i++) {
                this.add2Binding(bindingSelector[i].name, bindingSelector[i].bindingSelector);
            }
            // binding end

            if (widget.hasEvents) {
                this.widgetsWithEvents.push(widget);
            }


            //model setup start
            widget.initModelValue(this.model);
            //model setup end

            if (widget.specialBehaviour) {
                this.widgetsWithSpecialBehaviour.push(widget);
            }
            //init collections start

            this.registerSelect(widget);

            //init collections end

            // add validation infos
            this.processValidations(widget, name);
        }
        // construct link widget
        if (widgetType === "LinkWidget") {
            Logger.debug("LinkWidget called....");
            this.linkWidgets.push(widget);
        }

        return widget;
    },

    processValidations: function (widget, name) {
        var widgetType = widget.type;
        for (var l = 0; l < widget.validation.length; l++) {
            var validation = widget.validation[l];
            var theMsg = MsgHelper.getMessage("msg." + validation.message);
            if (validation.msgParameter !== undefined && validation.msgParameter.length > 0) {
                theMsg = MsgHelper.format(MsgHelper.getMessage("msg.constraints." + validation.constraint.substring(0, validation.constraint.indexOf(":"))), validation.msgParameter);
            }

            var constraint = validation.constraint;
            if (validation.constraint.match("^function:")) {
                constraint = ValidationHelper[validation.constraint.substring(validation.constraint.indexOf(":") + 1, validation.constraint.length)](this.model, this.model.id, widget.property, name);
            }
            this.options.parentView.validations.push([this.bindings[name], constraint, theMsg]);
            // set preselection if necessary
            if (widgetType === "SelectionWidget" && validation.constraint === "presence" && MiscHelper.isEmpty(this.model.get(name))) {
                // if there is more than one option, set an array of ids to give the user the chance to change the selection
                if (this.options.parentView.collections[widget.collection].options.length === 1) {
                    this.model.set(name, this.options.parentView.collections[widget.collection].options[0].id);
                } else {
                    var possibleIds = [];
                    for (var i = 0; i < this.options.parentView.collections[widget.collection].options.length; i++) {
                        possibleIds.push(this.options.parentView.collections[widget.collection].options[i].id);
                    }
                    this.model.set(name, possibleIds);
                }
            }
        }
    },

    bindCompositeWidgetToForm: function (widget, firstInit) {
        // binding start
        var bindingSelector = [];
        if (widget.doesHandleBindings) {
            bindingSelector = widget.binding(this.model);
            for (var i = 0; i < bindingSelector.length; i++) {
                this.add2Binding(bindingSelector[i].name, bindingSelector[i].bindingSelector);
            }
        }
        // binding end

        var selects = widget.selects(this.model);
        if (selects.length > 0) {
            for (var i = 0; i < selects.length; i++) {
                this.registerSelect(selects[i].widget, selects[i].selectMapping);
            }
        }
        if (firstInit && widget.doesHandleBindings) {
            this.compositeWithSelectWidgets.push(widget);
            this.compositeWithDatePickers.push(widget);
            if (widget.hasEvents) {
                this.widgetsWithEvents.push(widget);
            }
        }

        var typeaheads = widget.typeaheads(this.model);
        if (typeaheads.length > 0) {
            for (var i = 0; i < typeaheads.length; i++) {
                this.registerTypeahead(typeaheads[i].widget, typeaheads[i].selectMapping);
            }
        }

        //validation
        if (widget.doesHandleBindings) {
            for (var i = 0; i < widget.column.length; i++) {
                var column = widget.column[i];
                for (var j = 0; j < column.widget.length; j++) {
                    var subWidget = column.widget[j];
                    if (subWidget.validation !== undefined && subWidget.validation.length > 0) {
                        for (var k = 0; k < bindingSelector.length; k++) {
                            if (bindingSelector[k].name.indexOf("." + subWidget.property) !== -1) {
                                this.processValidations(subWidget, bindingSelector[k].name);
                            }
                        }
                    }
                }
            }
        }

    },

    buildFormCompositeWidget: function (widgetDef, listproperty, parentdoesHandleBindings) {
        var widgetType = widgetDef.type;
        var widget;
        if (widgetType === "TableCompositeWidget") {
            widget = new TableCompositeWidget(widgetDef, this.model.modelKey, listproperty);
            widget.doesHandleBindings = true;
            if (widget.pageable === true) {
                this.pageableTables.push(widget);
            }
        } else if (widgetType === "ListCompositeWidget") {
            widget = new ListCompositeWidget(widgetDef, this.model.modelKey, listproperty);
            widget.doesHandleBindings = true;
        } else {
            widget = new CompositeWidget(widgetDef, this.model.modelKey, listproperty);
            widget.doesHandleBindings = true;
        }
        this.prepareColumns(widget['column'], widget);
        this.prepareGroups(widget['group'], widget);

        if (parentdoesHandleBindings !== true) {
            widget.initModelValue(this.model);
            this.bindCompositeWidgetToForm(widget, true);
            this.compositeWidgets.push(widget);
        }

        return widget;
    },

    find: function (property) {
        for (var i = 0; i < this.compositeWidgets.length; i++) {
            if (this.compositeWidgets[i].property === property) {
                return this.compositeWidgets[i];
            }
        }
        for (var i = 0; i < this.fieldSets.length; i++) {
            var fieldSet = this.fieldSets[i];
            for (var j = 0; j < fieldSet.column.length; j++) {
                var column = fieldSet.column[j];
                for (var k = 0; k < column.widget.length; k++) {
                    if (column.widget[k].property === property) {
                        return column.widget[k];
                    }
                }
            }
        }
    },

    datepickerChange: function (event) {
        var target = $(event.target)[0];
        $('#' + target.id + " input").trigger('datepickerChange');
    },

    changeEvent: function (event, extraParameters) {
        var id = event.target.id;
        if (event.target.type === 'radio') {
            id = event.target.name;
        }

        Logger.debug(id);
        var changeEvents = this.events[id];
        var rerender = false;
        if (changeEvents !== undefined) {
            for (var i = 0; i < changeEvents.length; i++) {
                rerender = EventsHelper[changeEvents[i].type](event, changeEvents[i], this.model, extraParameters, this.options) || rerender;
            }
        }

        if (rerender === true) {
            var subviewsByName;
            this.rebindAndRender();
            if (id === 'bundleswitch') {
                var value = event.target.value;
                subviewsByName = this.options.parentView.subviewsByName;
                subviewsByName['block_prices'].rebindAndRender();
                if (value === 'duoTax') {
                    subviewsByName['block_priceDuoBundle'].rebindAndRender();
                    $('#block_volumes').addClass('hidden');
                } else if (value === 'multiTax') {
                    subviewsByName['block_priceMultiBundle'].rebindAndRender();
                    $('#block_volumes').addClass('hidden');
                } else {
                    subviewsByName['block_volumes'].rebindAndRender();
                    $('#block_volumes').removeClass('hidden');
                }
            } else if (id === 'zisInfo_medium') {
                var value = event.target.value;
                if (value === 'WW') {
                    subviewsByName = this.options.parentView.subviewsByName;
                    subviewsByName['block_pricesJournalBundle'].rebindAndRender();
                    subviewsByName['block_pricesJournal'].rebindAndRender();
                } else {
                    subviewsByName = this.options.parentView.subviewsByName;
                    subviewsByName['block_pricesJournal'].rebindAndRender();
                }

            } else if (id === 'uiType') {
                subviewsByName = this.options.parentView.subviewsByName;
                subviewsByName['block_prices'].rebindAndRender();
                $('#block_volumes').removeClass('hidden');
                if (this.model.get('uiType') === 'bundles') {
                    $('#fieldSet_bundleswitch').removeClass('hidden');
                } else {
                    $('#fieldSet_bundleswitch').addClass('hidden');
                }
            }
        }
    },

    rebindAndRender: function () {
        var compWidget = this.find("retailPrices");
        if (compWidget !== undefined) {
            this.removeBindings(compWidget['property']);
            this.bindCompositeWidgetToForm(compWidget);
        }
        compWidget = this.find("specialPrices");
        if (compWidget !== undefined) {
            this.removeBindings(compWidget['property']);
            this.bindCompositeWidgetToForm(compWidget);
        }
        compWidget = this.find("collections");
        if (compWidget !== undefined) {
            this.bindCompositeWidgetToForm(compWidget);
        }
        compWidget = this.find("foreignPrices");
        if (compWidget !== undefined) {
            this.removeBindings(compWidget['property']);
            this.bindCompositeWidgetToForm(compWidget);
        }
        compWidget = this.find("duoBundles");
        if (compWidget !== undefined) {
            this.removeBindings(compWidget['property']);
            this.bindCompositeWidgetToForm(compWidget);
        }
        compWidget = this.find("multiBundles");
        if (compWidget !== undefined) {
            this.removeBindings(compWidget['property']);
            this.bindCompositeWidgetToForm(compWidget);
        }
        compWidget = this.find("containedItems");
        if (compWidget !== undefined) {
            this.removeBindings(compWidget['property']);
            this.bindCompositeWidgetToForm(compWidget);
        }
        compWidget = this.find("pricesJournal");
        if (compWidget !== undefined) {
            this.removeBindings(compWidget['property']);
            this.bindCompositeWidgetToForm(compWidget);
        }
        compWidget = this.find("pricesJournalBundle");
        if (compWidget !== undefined) {
            this.removeBindings(compWidget['property']);
            this.bindCompositeWidgetToForm(compWidget);
        }
        this.render();
    },

    removeBindings: function (property, index, subpropAndindex) {
        var prop = property;
        if (index !== undefined) {
            prop += "." + index;
        }
        if (subpropAndindex !== undefined) {
            prop += "." + subpropAndindex;
        }
        for (var key in this.bindings) {
            if (this.bindings.hasOwnProperty(key) && _.string.startsWith(key, prop)) {
                delete this.bindings[key];
            }
        }
    },

    rebind: function (property) {
        this.removeBindings(property);
        for (var i = 0; i < this.compositeWidgets.length; i++) {
            if (this.compositeWidgets[i].property === property) {

                this.bindCompositeWidgetToForm(this.compositeWidgets[i]);
            }
        }
    },
    toggleHiddenListWidget: function (event) {
        $(this.el).find('.listWidget.hideable').toggle();
        $(this.el).find('.listSet .toggle-hidden-button span').toggle();
    },

    getCompositeWidget: function (element) {
        var indexMap = FormHelper.getIndexMap(element, {});
        var compIndex = indexMap["comp"].split(/_/);
        var found = [];
        found.push(this.options.blockDef['fieldSet'][indexMap.fieldSet]['column'][0]['widget'][compIndex[0]]);
        if (compIndex.length === 2) {

            if (found[0]['column'][0]['widget'][compIndex[1]].type !== 'HiddenWidget') {
                found.unshift(found[0]['column'][0]['widget'][compIndex[1]]);
            }
        }

        return found;
    },

    addListWidget: function (event) {
        var compWidgets = this.getCompositeWidget(event.target);
        //length 2 = first triggered widget, second its parent

        if (compWidgets) {
            var compWidget = compWidgets[0];
            if (compWidget.property === 'foreignPrices') {
                var cFP = PriceHelper.countCurrentforeignPrices(this.model);
                var n = 27 - cFP;
                if (n >= 5) {
                    n = 5;
                }
                for (var i = 0; i < n; i++) {
                    compWidget.addNew(this.model);
                }
            } else {
                if (compWidgets.length === 2) {
                    compWidget.addNew(this.model, compWidgets[1].property, $(event.target).parent().attr('data-parentIndex'));
                } else {
                    if (event.target.id !== undefined && event.target.id.split('_').length > 1) {
                        compWidget.addNew(this.model, undefined, event.target.id.split('_')[1]);
                    } else {
                        compWidget.addNew(this.model);
                    }
                }
            }
            if (compWidgets.length === 2) {
                this.bindCompositeWidgetToForm(compWidgets[1]);
            } else {
                this.bindCompositeWidgetToForm(compWidget);
            }

            this.render();
            // this is a nasty query, just to make sure, that we are only calling the following method if we are on the
            // advanced search page -- should be avoided
            if (compWidget.id !== undefined && (compWidget.id === "conditions" || compWidget.id === "selectionareas")) {
                Chaplin.mediator.publish('rebind_model', this.options.blockDef.blockId);
            }
            Chaplin.mediator.publish('triggerValidation');
        }
        this.delegateEvents();
    },

    removeListWidget: function (event) {
        var widgetIndex = this.getWidgetIndex(event.target);
        var compWidgets = this.getCompositeWidget(event.target);

        if (compWidgets) {
            var selectPrefix = "";
            var compToRebind = compWidgets[0];
            if (compWidgets.length === 2) {
                var me = compWidgets[0];
                var parent = compWidgets[1];

                this.model.get(parent['property'])[widgetIndex[1]][me.property][widgetIndex[0]] = null;
                this.removeBindings(parent['property'], widgetIndex[1], me['property'] + "." + widgetIndex[0]);
                compToRebind = parent;

                selectPrefix = parent['property'] + "." + widgetIndex[1] + "." + me['property'] + "." + widgetIndex[0];
            } else {
                var compositeWidget = compWidgets[0];
                if (["retailPrices", "specialPrices", "foreignPrices", "duoBundles", "multiBundles", "pricesJournal", "pricesJournalBundle"].indexOf(compositeWidget.property) !== -1) {
                    if (event.target.id !== undefined && event.target.id.split('_').length > 2) {

                    } else {
                        var thePrices = this.model.get(compositeWidget['property']);
                        var rowPrice = this.model.get(compositeWidget['property'])[widgetIndex[0]];

                        var pcs = PriceHelper.filterPricesByCurrencyCountry(thePrices, rowPrice);

                        var rowLocalIndex = -1;
                        for (var i = 0; i < pcs.length; i++) {
                            if (pcs[i].index === widgetIndex[0]) {
                                rowLocalIndex = i;
                                break;
                            }
                        }
                        if (rowLocalIndex > 0) {
                            thePrices[pcs[rowLocalIndex - 1].index].validUntil = null;
                            thePrices[pcs[rowLocalIndex - 1].index].calculated = false;
                        }
                        for (var i = (pcs.length - 1); i >= rowLocalIndex; i--) {
                            thePrices.splice(pcs[i].index, 1);
                        }

                        this.removeBindings(compositeWidget['property']);
                    }
                } else {
                    this.model.get(compositeWidget['property'])[widgetIndex[0]] = null;
                    this.removeBindings(compositeWidget['property'], widgetIndex[0]);
                }

                selectPrefix = compositeWidget['property'] + "." + widgetIndex[0];
            }

            this.bindCompositeWidgetToForm(compToRebind);
            for (var key in this.options.parentView.collections) {
                var mapping = this.options.parentView.collections[key];
                if (mapping.selects !== undefined) {
                    for (var i = mapping.selects.length - 1; i >= 0; i--) {
                        if (_.string.startsWith(mapping.selects[i].key, selectPrefix)) {
                            mapping.selects.splice(i, 1);
                        }
                    }
                }
                if (mapping.typeaheads != undefined) {
                    for (var i = mapping.typeaheads.length - 1; i >= 0; i--) {
                        if (_.string.startsWith(mapping.typeaheads[i].key, selectPrefix)) {
                            mapping.typeaheads.splice(i, 1);
                        }
                    }
                }
            }

            this.render();
            Chaplin.mediator.publish('rebind_model', this.options.blockDef.blockId);
            Chaplin.mediator.publish('triggerValidation');
        }

        this.delegateEvents();
    },

    getWidgetIndex: function (element) {
        var classes = element.parentNode.className.split(/\s+/);
        var indexClass = classes[classes.length - 1];
        var indices = indexClass.split(/_/);
        if (indices.length === 2) {
            return [parseInt(indices[1])];
        }
        //first = my index, second =parent_index
        return [parseInt(indices[1]), parseInt(indices[2])];
    },

    initDatePickers: function () {
        if (this.options.formDef.attributes.isEditable) {
            // remove old datepickers
            //$('.datepicker').datetimepicker('destroy'); doesn't work
            $('.bootstrap-datetimepicker-widget').remove();

            for (var i = 0; i < this.datePickers.length; i++) {
                this.datePickers[i].initAfterBinding(this.model);
            }
            for (var i = 0; i < this.compositeWithDatePickers.length; i++) {
                var sw = this.compositeWithDatePickers[i];
                sw.initDatefieldsAfterBinding(this.model);
            }
        }

    },

    initSelects: function () {
        for (var i = 0; i < this.selectWidgets.length; i++) {
            var sw = this.selectWidgets[i];
            sw.initAfterBinding(this.options.parentView.collections[sw.collection].options, this.model, this.options.formDef.attributes.isEditable);
        }
        for (var i = 0; i < this.compositeWithSelectWidgets.length; i++) {
            var sw = this.compositeWithSelectWidgets[i];
            sw.initSelectsAfterBinding(this.options.parentView.collections, this.model, this.options.formDef.attributes.isEditable);
        }

    },

    initTypeaheads: function () {
        for (var i = 0; i < this.typeaheadWidgets.length; i++) {
            var sw = this.typeaheadWidgets[i];
            sw.initAfterBinding(this.options.parentView.collections[sw.collection].options, this.model, this.options.formDef.attributes.isEditable);
        }
    },

    bindEvents: function () {
        this.events = {};
        for (var i = 0; i < this.widgetsWithEvents.length; i++) {
            var sw = this.widgetsWithEvents[i];
            var wEvents = sw.events(this.model);
            $.extend(this.events, wEvents);
        }
    },

    /**
     * resends the currently selected users credentials to the email address provided
     */
    sendCredentials: function (event) {
        var mailDialog = this.subview("mailDialog");
        if (mailDialog === undefined || mailDialog === null) {
            mailDialog = new MailView({
                model: this.model,
                collections: this.collections,
                formDef: this.options.formDef
            });
            this.subview("mailDialog", mailDialog);
        }
        var mailHtml = mailDialog.render().$el.prop('outerHTML');
        var self = this;

        this.subview("mailDialog").show();

        return false;
    },

    prepareForSubmit: function (model) {
        for (var i = 0; i < this.compositeWidgets.length; i++) {
            this.compositeWidgets[i].prepareForSubmit(model);
        }

        for (var i = 0; i < this.widgetsWithSpecialBehaviour.length; i++) {
            this.widgetsWithSpecialBehaviour[i].prepareForSubmit(model);
        }
    },

    findDatePickerById: function (id) {
        for (var i = 0; i < this.datePickers.length; i++) {
            if (this.datePickers[i].id === id) {
                return this.datePickers[i];
            }
        }
        return null;
    },

    toggleDatePicker: function (e) {
        e.stopPropagation();
        e.preventDefault();

        var dateFieldId = e.target.id.replace('_datetoggle', '');
        var dateWidget = this.findDatePickerById(dateFieldId);
        if (dateWidget !== null) {
            var dateToggleValue = $(e.currentTarget).val();
            dateWidget.precisionChanged(dateToggleValue);
        }
    },

    removeCollapsibleFieldSet: function (e) {
        e.preventDefault();
        var fieldset_selector = $('#' + $(e.target).attr("data-target"));
        $(e.target).addClass("hidden");
        fieldset_selector.addClass("hidden");
        var open_selector = $('#' + $(e.target).attr("data-target") + '+div>a');
        open_selector.removeClass("hidden");

        var id = fieldset_selector.attr('id').replace("fieldSet_", "");
        var block = this.options.blockDef;

        var fieldSet;
        for (var j = 0; j < block['fieldSet'].length; j++) {
            fieldSet = block['fieldSet'][j];
            if (fieldSet.title === id) {
                fieldSet.status = "closed";
                break;
            }
        }
        var rerender = false;
        for (var i = 0; i < fieldSet.column.length; i++) {
            var column = fieldSet.column[i];
            for (j = 0; j < column.widget.length; j++) {
                var widget = column.widget[j];
                if (widget.type === 'ListCompositeWidget' || widget.type === 'SingleCompositeWidget') {
                    var possibleTargets = [];
                    var targetPostition = [];
                    var modelAudiences = this.model.get(widget.name);
                    if (widget.type === 'ListCompositeWidget') {
                        for (var l = 0; l < modelAudiences.length; l++) {
                            if (this.model.get(widget.name)[l] !== null) {
                                possibleTargets.push(modelAudiences[l]);
                                targetPostition.push(l);
                            }
                        }
                    } else {
                        possibleTargets.push(modelAudiences);
                        targetPostition.push(0);
                    }
                    for (var k = 0; k < widget.filter.length; k++) {
                        var filterKey = widget.filter[k].key;
                        var filterValue = widget.filter[k].value;
                        var tempTarget = [];
                        var tempPositions = [];
                        for (l = 0; l < possibleTargets.length; l++) {
                            if (possibleTargets[l] !== null && possibleTargets[l][filterKey] === filterValue) {
                                tempTarget.push(possibleTargets[l]);
                                tempPositions.push(targetPostition[l]);
                            }
                        }
                        possibleTargets = [];
                        targetPostition = [];
                        $.extend(possibleTargets, tempTarget);
                        $.extend(targetPostition, tempPositions);
                    }
                    for (k = 0; k < targetPostition.length; k++) {
                        for (l = 0; l < widget.column.length; l++) {
                            var column2 = widget.column[l];
                            for (var m = 0; m < column2.widget.length; m++) {
                                var widget2 = column2.widget[m];
                                if (widget2.type !== 'HiddenWidget') {
                                    var targetProperty;
                                    if (widget.type === 'ListCompositeWidget') {
                                        targetProperty = widget.property + '.' + targetPostition[k] + '.' + widget2.property;
                                    } else {
                                        targetProperty = widget.property + '.' + widget2.property;
                                    }
                                    this.model.set(targetProperty, null);
                                    if (widget2.type === 'SelectionWidget') {
                                        $('#' + $(e.target).attr("data-target") + ' .' + widget2.parentProperty + ' .select2-container').select2('val', '');
                                    }
                                }
                            }
                        }
                    }
                } else {
                    if (widget.type !== 'HiddenWidget') {
                        this.model.set(widget.property, null);
                        if (widget.type === 'SelectionWidget') {
                            $('#' + $(e.target).attr("data-target") + ' .' + widget.name + ' .select2-container').select2('val', '');
                        }
                        if (widget.type === 'DateWidget' && widget.dateFormat === 'ONIX') {
                            $('#' + widget.property).val('');
                        }
                        if (widget.type === 'TableCompositeWidget') {
                            this.model.set(widget.property, []);
                            widget.initModelValue(this.model);
                            rerender = true;
                        }
                    }
                }
            }
        }

        if (rerender) {
            this.rebindAndRender();
        }
    },

    openCollapsibleFieldSet: function (e) {
        e.preventDefault();
        var fieldset_selector = $('#' + $(e.target).attr("data-target"));
        $(e.target).addClass("hidden");
        fieldset_selector.removeClass("hidden");
        var remove_selector = $('#' + $(e.target).attr("data-target") + ' .remove');
        remove_selector.removeClass("hidden");
        var remove_selector_label = $('#' + $(e.target).attr("data-target") + ' .remove label');
        remove_selector_label.removeClass("hidden");

        var id = fieldset_selector.attr('id').replace("fieldSet_", "");
        var block = this.options.blockDef;

        for (var j = 0; j < block['fieldSet'].length; j++) {
            var fieldSet = block['fieldSet'][j];
            if (fieldSet.title === id) {
                fieldSet.status = "open";
                break;
            }
        }
    },

    attach: function () {
        View.prototype.attach.call(this);
        this.bindEvents();
        this.modelBinder.bind(this.model, this.el, this.bindings, {
            'changeTriggers': {
                'input': 'change',
                'textarea': 'change'
            }
        });
        //console.log('=======================');
        //console.log(this.el.id);
        //for (binding in this.bindings) {
        //    console.log(binding + ': ' + this.bindings[binding]);
        //}
        $('#' + this.el.id + ' textarea[data-maxlength]').change();// trigger change in order to init counter
        this.initDatePickers();
        this.initSelects();
        this.initTypeaheads();

        this.initDatatables();
        this.prepareGroupFilters();

        LinkHelper.initLinks(this.linkWidgets, this.bindings, this.model);
        this.undelegate('change', 'input[data-changeEvent="true"]', this.changeEvent);
        this.undelegate('change', 'span[data-changeEvent="true"]', this.changeEvent);
        this.undelegate('init_block', 'input[data-changeEvent="true"]', this.changeEvent);
        this.undelegate('init_block', 'span[data-changeEvent="true"]', this.changeEvent);
        this.undelegate('change', '.datetoggle', this.toggleDatePicker);
        this.undelegate('click', 'fieldset+div>a', this.openCollapsibleFieldSet);
        this.undelegate('click', 'fieldset .fieldset-actions .remove', this.removeCollapsibleFieldSet);
        this.undelegate('click', '[data-special-behaviour]', this.specialBehaviour);

        this.delegate('click', 'button#addPublisherToMVBId', this.addPublisherToMVBId);
        this.delegate('click', 'button#removePublisherFromMVBId', this.removePublisherFromMVBId);

        this.delegate('change', 'input[data-changeEvent="true"]', this.changeEvent);
        this.delegate('change', 'span[data-changeEvent="true"]', this.changeEvent);
        this.delegate('init_block', 'input[data-changeEvent="true"]', this.changeEvent);
        this.delegate('init_block', 'span[data-changeEvent="true"]', this.changeEvent);


        this.delegate('change', '.datetoggle', this.toggleDatePicker);
        this.delegate('click', 'fieldset+div>a', this.openCollapsibleFieldSet);
        this.delegate('click', 'fieldset .fieldset-actions .remove', this.removeCollapsibleFieldSet);
        // 'click [data-special-behaviour]': 'specialBehaviour'
        this.delegate('click', '[data-special-behaviour]', this.specialBehaviour);
        $('#block_' + this.options.blockDef.blockId + ' input[type=text][data-changeEvent="true"]').trigger('init_block', 'attach');
        $('#block_' + this.options.blockDef.blockId + ' input[type=hidden][data-changeEvent="true"]').trigger('init_block', 'attach');
        $('#block_' + this.options.blockDef.blockId + ' input[type=radio][data-changeEvent="true"]:checked').trigger('init_block', 'attach'); //cant be change as it would create an endless loop for product price autocorrections
        $('#block_' + this.options.blockDef.blockId + ' span[data-changeEvent="true"]').trigger('init_block', 'attach');
        $('#block_' + this.options.blockDef.blockId + ' input[type=checkbox][data-changeEvent="true"]').trigger('init_block', 'attach');
        $('#block_' + this.options.blockDef.blockId + ' .number').keypress(NumberWidget.prototype.keypress);
        $('#block_' + this.options.blockDef.blockId + ' .date-field').keydown(DateWidget.prototype.keydown);


        this.doPricesSpecials('pricesJournal');
        this.doPricesSpecials('pricesJournalBundle');
        this.doPricesSpecials('duoBundles');
        this.doPricesSpecials('multiBundles');
        this.doPricesSpecials('foreignPrices');
        this.doPricesSpecials('retailPrices');
        this.doPricesSpecials('specialPrices');

        // execute after load events
        for (var i in this.widgetsWithAfterLoadEvents) {
            this.doAfterLoadEvent(this.widgetsWithAfterLoadEvents[i].widget, this.widgetsWithAfterLoadEvents[i].afterLoad);
        }
    },

    addPublisherToMVBId: function (e) {
        e.preventDefault();

        if (this.model.attributes !== null && this.model.attributes.over200UsersUserName !== null && this.model.attributes.over200UsersPublisherId) {
            var userName = this.model.attributes.over200UsersUserName;
            var publisherId = this.model.attributes.over200UsersPublisherId;

            AjaxSpinner.show();

            // This gives me the current users information, based on the userName
            $.ajax({
                url: "/app/user/profile-name/" + userName,
                type: "GET",
                dataType: "json",
                success: function (user) {

                    // This gives me the unique ID for the group: Publisher
                    $.ajax({
                        url: "/app/group/group-name/Publisher",
                        type: "GET",
                        dataType: "json",
                        success: function (group) {
                            var groupId = group.id;

                            // This gives me the accountId for the publisher/mvb ID
                            $.ajax({
                                url: "/app/select/accounts?search=" + publisherId,
                                type: "GET",
                                dataType: "json",
                                success: function (account) {
                                    if (user.user !== null && account[0] !== undefined && account[0] !== null && account[0].id !== undefined && account[0].id !== null) {
                                        var accountId = account[0].id;

                                        var groupMappings = user.user.groupMapping;

                                        groupMappings.push({
                                            account: {id: accountId || null},
                                            group: {id: groupId || null},
                                            reports: []
                                        });

                                        user.user.groupMapping = groupMappings;

                                        // Update the user with the new groupMapping with PUT on "/user/ID"
                                        $.ajax({
                                            url: "/app/user/" + user.id,
                                            type: "PUT",
                                            dataType: "json",
                                            data: JSON.stringify(user),
                                            headers: {
                                                'Accept': 'application/json',
                                                'Authorization': "Bearer " + user.access_token,
                                                'Content-Type': 'application/json',
                                            },
                                            success: function () {
                                                AjaxSpinner.hide();
                                                Application.flashMessage = MsgHelper.getMessage('msg.user.saved.successful');
                                                if (Application.flashMessage !== undefined) {
                                                    MsgHelper.showSuccessAlert(Application.flashMessage);
                                                    Application.flashMessage = undefined;
                                                }
                                            }, error: function (data, response) {
                                                this.showErrorMessage(response);
                                            }
                                        });
                                    } else {
                                        Application.flashMessage = MsgHelper.getMessage('msg.user.saved.nothingToRemove');
                                        MsgHelper.showErrorAlert(Application.flashMessage, true);
                                        AjaxSpinner.hide();
                                        if (Application.flashMessage !== undefined) {
                                            Application.flashMessage = undefined;
                                        }
                                    }
                                },
                                error: function (data, response) {
                                    this.showErrorMessage(response);
                                }
                            });
                        },
                        error: function (data, response) {
                            this.showErrorMessage(response);
                        }
                    });
                },
                error: function (data, response) {
                    this.showErrorMessage(response);
                }
            });
        }
    },

    removePublisherFromMVBId: function (e) {
        e.preventDefault();

        if (this.model.attributes !== null && this.model.attributes.over200UsersUserName !== null && this.model.attributes.over200UsersPublisherId) {
            var userName = this.model.attributes.over200UsersUserName;
            var publisherId = this.model.attributes.over200UsersPublisherId;

            AjaxSpinner.show();

            // This gives me the users information
            $.ajax({
                url: "/app/user/profile-name/" + userName,
                type: "GET",
                dataType: "json",
                success: function (user) {

                    // This gives me the ID for the group: Publisher
                    $.ajax({
                        url: "/app/group/group-name/Publisher",
                        type: "GET",
                        dataType: "json",
                        success: function (group) {
                            var groupId = group.id;

                            // This gives me the accountId for the publisher/mvb ID
                            $.ajax({
                                url: "/app/select/accounts?search=" + publisherId,
                                type: "GET",
                                dataType: "json",
                                success: function (account) {
                                    if (user.user !== null && account[0] !== undefined && account[0] !== null && account[0].id !== undefined && account[0].id !== null) {
                                        var accountId = account[0].id;

                                        var groupMappings = user.user.groupMapping;
                                        var removableIndex = undefined;

                                        // Storing the index of the removable element in a separat variable
                                        // since removing an element while iterating through the list is not a good idea in general
                                        for (var i = 0; i < groupMappings.length; i++) {
                                            if (groupMappings[i].group.id === groupId && groupMappings[i].account.id === accountId) {
                                                removableIndex = i;
                                                break;
                                            }
                                        }
                                        if (removableIndex !== undefined && removableIndex > -1) {
                                            groupMappings.splice(removableIndex, 1);
                                        }

                                        user.user.groupMapping = groupMappings;

                                        // Just update the user with the new groupMapping with PUT on "/user/ID"
                                        $.ajax({
                                            url: "/app/user/" + user.id,
                                            type: "PUT",
                                            dataType: "json",
                                            data: JSON.stringify(user),
                                            headers: {
                                                'Accept': 'application/json',
                                                'Authorization': "Bearer " + user.access_token,
                                                'Content-Type': 'application/json',
                                            },
                                            success: function () {
                                                AjaxSpinner.hide();
                                                if (removableIndex === undefined) {
                                                    Application.flashMessage = MsgHelper.getMessage('msg.user.saved.nothingToRemove');
                                                    MsgHelper.showErrorAlert(Application.flashMessage, true);
                                                } else {
                                                    Application.flashMessage = MsgHelper.getMessage('msg.user.saved.successful');
                                                    MsgHelper.showSuccessAlert(Application.flashMessage);
                                                }
                                                if (Application.flashMessage !== undefined) {
                                                    Application.flashMessage = undefined;
                                                }
                                            }, error: function (data, response) {
                                                this.showErrorMessage(response);
                                            }
                                        });
                                    } else {
                                        Application.flashMessage = MsgHelper.getMessage('msg.user.saved.nothingToRemove');
                                        MsgHelper.showErrorAlert(Application.flashMessage, true);
                                        AjaxSpinner.hide();
                                        if (Application.flashMessage !== undefined) {
                                            Application.flashMessage = undefined;
                                        }
                                    }
                                },
                                error: function (data, response) {
                                    this.showErrorMessage(response);
                                }
                            });
                        },
                        error: function (data, response) {
                            this.showErrorMessage(response);
                        }
                    });
                },
                error: function (data, response) {
                    this.showErrorMessage(response);
                }
            });
        }
    },

    showErrorMessage: function (response) {
        AjaxSpinner.hide();
        Application.flashMessage = response.responseText;
        if (Application.flashMessage !== undefined) {
            MsgHelper.showErrorAlert(Application.flashMessage, false);
            Application.flashMessage = undefined;
        }
    },

    specialBehaviour: function (event) {
        var specialBehaviour = $(event.currentTarget).data('special-behaviour');
        // It seems to me that these routines are not used anymore.
        if (specialBehaviour === 'bundles') {

            var multipieceView = new MultipieceView();
            var confirmDialog = function (event) {
                var type = multipieceView.model.get('kindOfProduct') + '-' + multipieceView.model.get('taxRate');
                if (type === 'two-identic') {
                    type = 'pbook';
                } else if (multipieceView.model.get('kindOfProduct') === 'two') {
                    type = 'duoBundle';
                } else {
                    type = 'multiBundle';
                }
                Chaplin.mediator.publish('redirectTo', {
                    url: 'product_create',
                    data: {
                        type: type
                    }
                });
                $('.multipiece-close').click();
            }
            ConfirmationHelper.openDialog(multipieceView.el, 'multipiece', confirmDialog);
            multipieceView.attach();
        }
    },

    updateTextAreaCounter: function (event) {

        var max = parseInt($(event.target).attr('data-maxlength'));

        // only cut when typing so we preserve longer texts from the database - will display negative counter then
        if (event.type == 'keyup') {
            if ($(event.target).val().length > max) {
                $(event.target).val($(event.target).val().substr(0, max));
            }
        }

        $(event.target).parent().find('.charleft').html(max - $(event.target).val().length);
    },

    initDatatables: function () {
        var urls = [];
        var loadingTables = [];
        var linkWidgets = this.linkWidgets;
        var bindings = this.bindings;
        var model = this.model;
        this.options.tables = [];

        for (var table = 0; table < this.pageableTables.length; table++) {
            var selector = this.pageableTables[table].name.replace(".", "\\.");
            var columns = [];
            loadingTables[selector] = {"status": "init", "resultcount": 0};

            var url = this.pageableTables[table].pageableCallbackUrl;
            if (url !== undefined) {
                url = url.replace("$api", apiRoot);

                var placeHolders = StringUtil.getPlaceHolders(url);
                if (placeHolders !== undefined && placeHolders.length > 0) {
                    for (var p = 0; p < placeHolders.length; p++) {
                        url = url.replace('{' + placeHolders[p] + '}', this.model.get(placeHolders[p]));
                    }
                }
            }

            urls[this.pageableTables[table].name] = url;
            var afterLoadEvent = this.pageableTables[table].afterLoad;
            var specialBehaviour = this.pageableTables[table].specialBehaviour;

            // get column definitions
            for (var column = 0; column < this.pageableTables[table].column.length; column++) {
                if (this.pageableTables[table].column[column].widget[0]) {
                    columns.push({
                        "mDataProp": this.pageableTables[table].column[column].widget[0].property,
                        "bSortable": false,
                        "widget": this.pageableTables[table].column[column].widget[0]
                    });
                }
            }

            this.options.tables.push(
                $('#' + selector).DataTable({
                    "dom": '<"tableLengthTop" lp> t <"bottom"i p>',
                    "columns": columns,
                    "iDisplayLength": 50,
                    "lengthMenu": [50, 100, 200, 500],
                    "bProcessing": true,
                    "sAjaxDataProp": "content",
                    "bServerSide": true,
                    "fnCreatedRow": function (nRow, aData, iDataIndex) {
                        for (var column = 0; column < columns.length; column++) {
                            if (columns[column].widget.type === 'LinkWidget') {
                                LinkHelper.initLink(columns[column].widget, undefined, columns[column].mDataProp, model, nRow.cells[column]);
                            }
                        }

                        if (afterLoadEvent && afterLoadEvent === 'markArchivedProducts') {
                            AfterLoadEventsHelper.markArchivedProducts(nRow);
                        }
                    },
                    "fnServerData": function (sSource, aoData, fnCallback, oSettings) {
                        var sort;
                        var search = $('#groupFilter').val();
                        var page;
                        var size;
                        var direction;
                        var tableId = oSettings.nTable.id.replace(".", "\\.");

                        var sb = specialBehaviour;

                        for (var i = 0; i < aoData.length; i++) {
                            if (aoData[i].name === 'length') {
                                size = aoData[i].value;
                            } else if (aoData[i].name === 'order') {
                                direction = aoData[i].value.dir;
                                sort = aoData[i].value.column;
                            } else if (aoData[i].name === 'start') {
                                page = aoData[i].value;
                            }
                        }

                        // get the correct page (page should always be a number % size == 0)
                        page = (page / size) + 1;

                        jQuery.ajax({
                            "dataType": 'json',
                            "url": urls[oSettings.nTable.id],
                            "type": 'GET',
                            "data": {
                                sort: sort,
                                search: search,
                                page: page,
                                size: size,
                                direction: direction
                            },
                            "success": function (data) {
                                data.iTotalRecords = data.totalElements;
                                data.iTotalDisplayRecords = data.totalElements;

                                fnCallback(data);

                                loadingTables[tableId].status = "done";
                                loadingTables[tableId].resultcount = data.totalElements;

                                TabHelper.actionAfterLoad(loadingTables, model['modelKey']);

                                if (sb && sb === "showWarnings") {
                                    LogEntryHelper.initWarningToggle(tableId);
                                }
                            },
                            "error": function (data) {
                                if (data.status == 200) {
                                    window.location.href = '/login?sessionExpired=true';
                                }
                            },
                            "beforeSend": function () {
                                // jQuery('.datatableProcessing').show();
                                loadingTables[tableId].status = "loading";
                            },
                            "complete": function () {
                                // jQuery('.datatableProcessing').hide();
                            },
                            "headers": {
                                'Authorization': "Bearer " + user.access_token
                            }
                        });
                    },
                    "oLanguage": {
                        "sSearch": MsgHelper.getMessage('label.datatable.search'),
                        "sLengthMenu": '_MENU_',
                        "sZeroRecords": MsgHelper.getMessage('label.datatable.zeroRecords'),
                        "oPaginate": {
                            "sNext": MsgHelper.getMessage('label.datatable.next'),
                            "sPrevious": MsgHelper.getMessage('label.datatable.previous'),
                            "sFirst": MsgHelper.getMessage('label.datatable.first'),
                            "sLast": MsgHelper.getMessage('label.datatable.last')
                        },
                        "sInfo": MsgHelper.getMessage('label.datatable.pagination.entriesfound'),
                        "sInfoThousands": MsgHelper.getMessage('label.datatable.pagination.number.thousands'),
                        "sInfoEmpty": MsgHelper.getMessage('label.datatable.info.empty'),
                        "sProcessing": MsgHelper.getMessage('label.datatable.table.busy')
                    }
                })
            );
        }
    },

    doPricesSpecials: function (fieldname) {
        var fp = this.model.get(fieldname);
        if (fp !== undefined && fp !== null) {
            var cc = null;
            var priceTypeGroup = null;
            var count = 0;
            for (var i = 0; i < fp.length; i++) {
                var p = fp[i];
                if (p !== null && !MiscHelper.isEmpty(p.currencyCountry)) {
                    if (p.currencyCountry !== cc || p.priceTypeGroup !== priceTypeGroup) {
                        cc = p.currencyCountry;
                        priceTypeGroup = p.priceTypeGroup;
                        count = 1;
                    } else {
                        count += 1;
                        if (count === 3) {
                            if (fieldname === 'duoBundles' || fieldname === 'multiBundles') {
                                $('#' + fieldname + '_validUntil_' + i).parent().prev().hide();
                            }
                            $('#' + fieldname + '_validUntil_' + i).hide();
                            $('#' + fieldname + '_validUntil_' + i + "_icon" +
                                "").hide();
                        }
                    }
                }
            }

        }
    },

    doAfterLoadEvent: function (widget, event) {

    },

    dispose: function () {
        if (this.disposed) {
            return;
        }
        View.prototype.dispose.apply(this);
        this.modelBinder.unbind();
    }
});

