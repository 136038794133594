var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "	        <div class=\"modal-option";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.hidden : depth0), {"name":"if","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\r\n	            <p>\r\n	                <b>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.confirmationMultipiece", (depth0 != null ? depth0.property : depth0), {"name":"label2","hash":{},"data":data})))
    + "</b>\r\n	            </p>\r\n	\r\n	            <div class=\"row\">\r\n	                <div class=\"col-md-12\">\r\n	                    <div class=\"control-group\">\r\n	                        <div class=\"controls\">\r\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.collection : depth0), {"name":"each","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "	                        </div>\r\n	                    </div>\r\n	                </div>\r\n	            </div>\r\n	        </div>\r\n";
},"2":function(depth0,helpers,partials,data) {
  return " hidden";
  },"4":function(depth0,helpers,partials,data,depths) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;
  return "	                                <input id=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].property : depths[1]), depth0))
    + "-"
    + escapeExpression(lambda(depth0, depth0))
    + "\" type=\"radio\" value=\""
    + escapeExpression(lambda(depth0, depth0))
    + "\"\r\n	                                       name=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].property : depths[1]), depth0))
    + "\">\r\n	                                <label for=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].property : depths[1]), depth0))
    + "-"
    + escapeExpression(lambda(depth0, depth0))
    + "\">"
    + escapeExpression(((helpers.label3 || (depth0 && depth0.label3) || helperMissing).call(depth0, (depths[2] != null ? depths[2].labelPrefix : depths[2]), (depths[1] != null ? depths[1].property : depths[1]), depth0, {"name":"label3","hash":{},"data":data})))
    + "</label>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<!-- it seems to me that this dialog is not used anymore -->\r\n\r\n<!--<div id=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "\" class=\"lb-default\">-->\r\n\r\n<div class=\"add-icons\">\r\n    <a href=\"javascript:;\" onclick=\"return false;\" class=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-close myfancybox-close\"\r\n       title=\"Close\">CLOSE</a>\r\n</div>\r\n\r\n<h2><span>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.confirmationMultipiece.headline", (depth0 != null ? depth0.ident : depth0), {"name":"label2","hash":{},"data":data})))
    + "</span></h2>\r\n\r\n<!--<form class=\"form-horizontal\">-->\r\n    <div id=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-content\">\r\n\r\n        <p>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.confirmationMultipiece.text", {"name":"lbl","hash":{},"data":data})))
    + "</p>\r\n\r\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.options : depth0), {"name":"each","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\r\n\r\n\r\n    <div class=\"form-actions align-right\">\r\n        <a href=\"javascript:;\" onclick=\"return false;\"\r\n           class=\"btn green btn-arrow "
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-close\"><span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "cancel", {"name":"lbl","hash":{},"data":data})))
    + "</span></a>\r\n        <a href=\"javascript:;\" onclick=\"return false;\"\r\n           class=\"btn green btn-arrow "
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-confirm\"><span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "confirm", {"name":"lbl","hash":{},"data":data})))
    + "</span></a>\r\n    </div>\r\n<!--</form>-->\r\n\r\n<!--</div>-->\r\n";
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}