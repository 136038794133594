var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<table id=\"logentryfilter-table\" class=\"filter-table\">\n    <tr>\n        <td>\n            <label class=\"control-label\" for=\"filter-isbn\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, (depth0 != null ? depth0.isbn : depth0), {"name":"lbl","hash":{},"data":data})))
    + "</label>\n        </td>\n        <td>\n            <label class=\"control-label\" for=\"filter-errorcode\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, (depth0 != null ? depth0.code : depth0), {"name":"lbl","hash":{},"data":data})))
    + "</label>\n        </td>\n    </tr>\n    <tr>\n        <td class=\"filter-column\">                                                             \n            <input type=\"text\" id=\"filter-isbn\" name=\"filter-isbn\">                                   \n        </td>\n        <td class=\"filter-column\">                                                         \n            <input type=\"text\" id=\"filter-errorcode\" name=\"filter-errorcode\">                                   \n        </td>\n        <td class=\"filter-column\">                                   \n            <a id=\"filterLogEntries\" class=\"btn btn-default\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, (depth0 != null ? depth0.button : depth0), {"name":"lbl","hash":{},"data":data})))
    + "</a>                                 \n        </td>\n    </tr>\n</table>\n<br>\n<br>";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}