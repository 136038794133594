var FormView = require('./form_view');
var AdvancedSearchTemplate = require('./templates/advancedsearch');
var MsgHelper = require('lib/msg_helper');
var MiscHelper = require('lib/misc_helper');
var UserHelper = require('lib/user_helper');
var LinkHelper = require('lib/link_helper');
var AjaxSpinner = require('lib/ajaxspinner_helper');
var DateHelper = require('lib/date_helper');

var AdvancedSearchView;

var VlbQueryMapping = {
    'AND' : 'und',
    'OR' : 'oder',
    'NOT' : 'nicht'
}

module.exports = AdvancedSearchView = FormView.extend({

    id: 'advanced-search-view',
    tagName: "form",
    className: "advancedform form-horizontal",
    region: 'content',
    containerMethod: 'html',

    initialize: function (options) {
        FormView.prototype.initialize.apply(this, [options]);
        this.options.wildcard = '*';
        this.options.mode = 'advanced';
        this.options.opArray = [MsgHelper.getMessage('label.form.collection.operator.AND'),
            MsgHelper.getMessage('label.form.collection.operator.OR'),
            MsgHelper.getMessage('label.form.collection.operator.NOT')];
        this.options.databases = 'db';
        this.options.singleValues = ['productType', 'productLanguage', 'qualityCheck', 'categoryofgoodsgroup', 'ddccode', 'bisac', 'mainReadingRationaleSubject'];
        this.logger = log4javascript.getLogger('views.AdvancedSearchView');
    },

    getTemplateFunction: function () {
        return AdvancedSearchTemplate;
    },

    getTemplateData: function () {
        var data = FormView.prototype.getTemplateData.call(this);
        data.advancedSearchName = this.model.get('name');
        data.compositeWidget = this.options.formDef.attributes['block'][0]['fieldSet'][0]['compositeWidget'];
        data.helpbox = {description: 'search.advanced.description.text', url: 'help.link.search.advanced'}
        return data;
    },

    events: {
        'click #storesearchasnew': 'storeSearchAsNew',
        'click #backtosearchhistory': 'backToSearchHistory',
        'click #clearadvancedsearch': 'clearadvancedsearch',
        'keyup #block_advanced': 'submitOnEnter'
    },

    listen: {
        'form_def_loaded mediator': 'formDefinitionLoaded'
    },

    clearadvancedsearch: function () {
        this.model.set('query', '');
        this.stringToModel();
        for (var key in this.options.singleValues) {
            this.model.set(this.options.singleValues[key], null);
        }

        this.subview("block_advanced").rebind("conditions");
        this.subview("block_advanced").rebind("selectionareas");
        this.subview("block_advanced").render();

        this.initConditions("advanced");
    },

    formDefinitionLoaded: function (loadedFormDef) {
        this.stringToModel();
        FormView.prototype.formDefinitionLoaded.call(this, loadedFormDef);
        this.model.on('change', this.showStoreSearchAsNewBtn, this);
    },

    submitOnEnter: function (event) {
        if (event.keyCode === 13) {
            // pushing the enter key means that any current value is not written back from input tags to the model. So I
            // need to explicit write the eventual entered data to the model.
            if(_.string.startsWith(event.target.id, 'selectionareas') || _.string.startsWith(event.target.id, 'conditions')) {
                var parts = event.target.id.split('_');
                var dotName = parts[0] + '.' + parts[2] + '.' + parts[1];
                this.model.set(dotName, event.target.value);
            }

            this.submit(event);
        }
    },

    showStoreSearchAsNewBtn: function (event) {
        if (!MiscHelper.isEmpty(this.model.get('name'))) {
            $('#storesearchasnew').show();
        }
    },

    submit: function (event) {
        AjaxSpinner.show();
        event.preventDefault();
        Chaplin.mediator.publish('executedNewSearch');
        this.model.set('query', this.modelToString(this.model.attributes));
        Chaplin.mediator.publish('redirectTo', {
            url: 'search_advancedsearch',
            data: {id: this.model.get('id'), search: this.model.get('query'), mode: this.options.mode}
        });
    },

    storeSearchAsNew: function (event) {
        this.model.set('query', this.modelToString(this.model.get('query')));
        Chaplin.mediator.publish('advancedSearchPublishPoint', undefined, this.model.get('query'), this.options.mode);
    },

    backToSearchHistory: function (event) {
        Chaplin.mediator.publish('backToSearchHistory');
    },

    modelToString: function (query) {
        var queryString = '';
        // TODO this needs to get cleaned --> see stringToModel, should be in the same method or so, I don't like
        // TODO to maintain this in two places
        var abbrevations = {
            'CATCHWORD': 'st',
            'CONTRIBUTOR': 'au',
            'PUBLISHER': 'vl',
            'KEYWORD': 'sw',
            'ISBN': 'is',
            'COLLECTION': 're',
            'TITLE': 'ti',
            'THEME': 'th',
            'PRICE_DE': 'pr',
            'PUBLICATIONDATE': 'ej',
            'CREATIONDATE': 'zd',
            'MODIFICATIONDATE': 'ad',
            'ARCHIVATIONDATE': 'aj',
            'REACTIVATIONDATE': 'rj',
            'ZISGROUP': 'zg',
            'productType': 'pt',
            'productLanguage': 'sp',
            'qualityCheck': 'qc',
            'categoryofgoodsgroup': 'wg',
            'ddccode': 'dw',
            'mainReadingRationaleSubject': 'lh',
            'bisac': 'bi'
        };
        var conditions = query.conditions;
        var lastConditionOperator = null;
        for (var i = 0; i < conditions.length; i++) {
            if (conditions[i] != null && !MiscHelper.isEmpty(conditions[i].searchPhrase)) {
                if (queryString.length > 0) {
                    queryString += ' ' + lastConditionOperator + ' ';
                }
                var conditionWords = conditions[i].searchPhrase.split(" ");
                var conditionString = '';
                lastConditionOperator = VlbQueryMapping[conditions[i].operator];
                for (var j = 0; j < conditionWords.length; j++) {
                    if (j > 0) {
                        conditionString += ' ';
                    }
                    conditionString += this.checkForOperatorWord(conditionWords[j]);
                }
                queryString += abbrevations[conditions[i].column] + '=' + conditionString;
            }
        }
        if(queryString.length > 0) {
            queryString = '(' + queryString + ')';
        }
        var selectionareas = query.selectionareas;
        for (var i = 0; i < selectionareas.length; i++) {
            var fromTill = selectionareas[i];
            if (fromTill != null && (!MiscHelper.isEmpty(fromTill.from) || !MiscHelper.isEmpty(fromTill.till))) {
                if (queryString.length > 0) {
                    queryString += ' ' + VlbQueryMapping['AND'] + ' ';
                }
                queryString += abbrevations[fromTill.column] + '=';
                if (!MiscHelper.isEmpty(fromTill.from)) {
                    if (fromTill.column != 'PRICE_DE') {
                        queryString += DateHelper.convert(fromTill.from, formatDateDayMonthYear, onixDateFormat);
                    } else {
                        queryString += fromTill.from.replace(',', '.');
                    }
                } else {
                    queryString += this.options.wildcard;
                }
                queryString += '^';
                if (!MiscHelper.isEmpty(fromTill.till)) {
                    if (fromTill.column != 'PRICE_DE') {
                        queryString += DateHelper.convert(fromTill.till, formatDateDayMonthYear, onixDateFormat);
                    } else {
                        queryString += fromTill.till.replace(',', '.');
                    }
                } else {
                    queryString += this.options.wildcard;
                }
            }
        }
        var singleValues = this.options.singleValues;
        for (var key in singleValues) {
            var singleValue = query[singleValues[key]];
            if (!MiscHelper.isEmpty(singleValue)) {
                var singleValueArray = singleValue.split(',');
                if (queryString.length > 0) {
                    queryString += ' ' + VlbQueryMapping['AND'] + ' ';
                }
                var singleValueStrings = [];
                for (value in singleValueArray) {
                    var searchValue = singleValueArray[value];
                    if (singleValues[key] === 'mainReadingRationaleSubject') {
                        searchValue = this.determineReadingRationaleTranslation(singleValueArray[value]);
                    }
                    singleValueStrings.push(abbrevations[singleValues[key]] + '=' + searchValue);
                }

                queryString += '(' + singleValueStrings.join(' or ') + ')';
            }
        }
        if (query.databases) {
            var databaseArray = [];
            for (db in query.databases) {
                if (query.databases[db] == true) {
                    databaseArray.push(db);
                }
            }
            if (databaseArray.length > 0) {
                if (queryString.length > 0) {
                    queryString += ' ' + VlbQueryMapping['AND'] + ' ';
                }
                queryString += this.options.databases + '=' + databaseArray.join(',');
            }
        }


        queryString = LinkHelper.encodeURI(queryString)
        return queryString;
    },

    stringToModel: function () {
        var query = this.model.get('query');
        query = decodeURIComponent(query);
        if (!MiscHelper.isEmpty(query)) {
            // replacing brackets as we do not need to care here
            query = query.replace(/\(/g, '');
            query = query.replace(/\)/g, '');
            var conditionsMap = {
                'st': 'CATCHWORD',
                'au': 'CONTRIBUTOR',
                'vl': 'PUBLISHER',
                'sw': 'KEYWORD',
                'is': 'ISBN',
                're': 'COLLECTION',
                'ti': 'TITLE',
                'th': 'THEME',
                'zg': 'ZISGROUP'
            };
            var selectionareasMap = {
                'pr': 'PRICE_DE',
                'ej': 'PUBLICATIONDATE',
                'zd': 'CREATIONDATE',
                'ad': 'MODIFICATIONDATE',
                'aj': 'ARCHIVATIONDATE',
                'rj': 'REACTIVATIONDATE'
            };
            var singleValuesMap = {
                'pt': 'productType',
                'sp': 'productLanguage',
                'qc': 'qualityCheck',
                'wg': 'categoryofgoodsgroup',
                'dw': 'ddccode',
                'bi': 'bisac',
                'lh': 'mainReadingRationaleSubject'
            };
            var operatorMap = {};
            operatorMap[VlbQueryMapping['AND']] = 'AND';
            operatorMap[VlbQueryMapping['OR']] = 'OR';
            operatorMap[VlbQueryMapping['NOT']] = 'NOT';
            var equals = query.split("=");

            var usedConditions = [];
            var usedSelections = [];

            for (var i = 0; i < equals.length - 1; i++) {
                var words = equals[i].split(" ");
                var afterWords = equals[i + 1].split(" ");
                var cat = words[words.length - 1];
                cat =  cat.toLowerCase();
                usedConditions.push(cat);

                if (afterWords.length > 2) {
                    var last1 = afterWords[afterWords.length - 1];
                    var last2 = afterWords[afterWords.length - 2];
                    if ((conditionsMap[last1] || selectionareasMap[last1] || singleValuesMap[last1] || this.options.databases == last1)
                        && (operatorMap[last2])) {
                        afterWords.splice(afterWords.length - 2, 2);
                    }
                }
                if (conditionsMap[cat]) {
                    var condition = new Object();
                    condition.column = conditionsMap[cat];
                    condition.operator = 'AND';
                    condition.searchPhrase = '';
                    for (var j = 0; j < afterWords.length; j++) {
                        if (condition.searchPhrase.length > 0) {
                            condition.searchPhrase += ' ';
                        }
                        condition.searchPhrase += afterWords[j];
                    }
                    condition.operator = operatorMap[last2];

                    if (!this.model.get('conditions')) {
                        this.model.set('conditions', []);
                    }
                    this.model.get('conditions').push(condition);
                } else if (selectionareasMap[cat]) {
                    usedSelections.push(cat);
                    var fromTill = new Object();
                    fromTill.column = selectionareasMap[cat];
                    var ranges = afterWords[0].split("^");
                    if (ranges[0] != this.options.wildcard) {
                        if (fromTill.column != 'PRICE_DE') {
                            fromTill.from = DateHelper.convert(ranges[0], onixDateFormat, formatDateDayMonthYear);
                        } else {
                            fromTill.from = ranges[0].replace('.', ',');
                        }
                    }

                    if (ranges[1] != this.options.wildcard) {
                        if (fromTill.column != 'PRICE_DE') {
                            fromTill.till = DateHelper.convert(ranges[1], onixDateFormat, formatDateDayMonthYear);
                        } else {
                            fromTill.till = ranges[1].replace('.', ',');
                        }
                    }
                    if (!this.model.get('selectionareas')) {
                        this.model.set('selectionareas', []);
                    }
                    this.model.get('selectionareas').push(fromTill);
                } else if (singleValuesMap[cat]) {
                    var singleValue = afterWords[0];
                    var currentModelValue = this.model.get(singleValuesMap[cat]);
                    if (currentModelValue && currentModelValue.length > 0) {
                        currentModelValue += ',' + singleValue;
                    } else {
                        currentModelValue = singleValue;
                    }
                    this.model.set(singleValuesMap[cat], currentModelValue);
                } else if (cat == this.options.databases) {
                    var databasesArray = afterWords[0].split(',');
                    var databases = {};
                    for (db in databasesArray) {
                        databases[databasesArray[db]] = true;
                    }
                    this.model.set('databases', databases);
                }
            }
        }
        if (!query || !this.model.get('conditions')) {
            this.model.set(
                'conditions', [
                    {column: 'TITLE', searchPhrase: '', operator: 'AND'},
                    {column: 'CONTRIBUTOR', searchPhrase: '', operator: 'AND'},
                    {column: 'ISBN', searchPhrase: '', operator: 'AND'}]);
            if (!query) {
                this.model.set('databases', {vlb: true, archive: true});
            }
            if (!query || !this.model.get('selectionareas')) {
                this.model.set('selectionareas', [
                    {column: 'PRICE_DE', from: '', till: ''},
                    {column: 'PUBLICATIONDATE', from: '', till: ''}
                ]);
            }
        }

        if (this.model.get('conditions') === undefined || this.model.get('conditions').length < 3) {
            if (this.model.get('conditions') === undefined) {
                this.model.set('conditions', []);
            }
            else {
                var index = this.model.get('conditions').length - 1;
                var lastCondition = this.model.get('conditions')[index];
                if (lastCondition.operator === undefined) {
                    lastCondition.operator = 'AND';
                    this.model.get('conditions').splice(index, 1);
                    this.model.get('conditions').push(lastCondition);
                }
            }

            var conditionName = undefined;
            var tmpConditions = conditionsMap;
            var lastKey = undefined;

            for (i = this.model.get('conditions').length; i < 3; i++) {
                for (var key in tmpConditions) {
                    if (tmpConditions.hasOwnProperty(key)) {
                        usedIndex = _.indexOf(usedConditions, key);
                        if (usedIndex === -1) {
                            conditionName = tmpConditions[key];
                            usedConditions.push(key);
                            lastKey = key;
                            break;
                        }
                    }
                }

                if (conditionName === undefined) {
                    conditionName = "TITLE";
                }

                var newCondition = {column: conditionName, searchPhrase: '', operator: 'AND'};
                this.model.get('conditions').push(newCondition);
            }
        }

        if (this.model.get('selectionareas') === undefined || this.model.get('selectionareas').length < 2) {
            if (this.model.get('selectionareas') === undefined) {
                this.model.set('selectionareas', []);
            }

            var selectionName = undefined;
            var tmpSelections = selectionareasMap;
            var lastSelectionKey = undefined;

            for (var key in tmpSelections) {
                if (tmpSelections.hasOwnProperty(key)) {
                    usedIndex = _.indexOf(usedSelections, key);
                    if (usedIndex === -1) {
                        selectionName = tmpSelections[key];
                        usedSelections.push(key);
                        lastKey = key;
                        break;
                    }
                }
            }

            if (selectionName === undefined) {
                selectionName = "PRICE_DE";
            }

            var newSelection = {column: selectionName, from: '', till: ''};
            this.model.get('selectionareas').push(newSelection);
        }
    },

    checkForOperatorWord: function (word) {
        for (op in VlbQueryMapping) {
            if (VlbQueryMapping[op] == word) {
            return '"' + word + '"';
            }
        }
            return word;
    },

    blockLoaded: function () {
        Chaplin.mediator.subscribe('rebind_model', this.initConditions, this);
        this.initConditions('advanced');

        var dbInput = $('div[class*=databases_] input');
        if(dbInput.length > 0) {
            var dataAreaAuthorities = UserHelper.dataAreaAuthorities();
            dataAreaAuthorities = MiscHelper.getDbQueryStringForAggregatorIds(dataAreaAuthorities);
            for(var i=0; i<dbInput.length; i++) {
                var id = dbInput[i].id.split('_')[1];
                if(_.contains(dataAreaAuthorities, id) || id === 'basic') {
                    $('div[class*=databases_' + id + '] input').removeClass('disabled');
                    $('div[class*=databases_' + id + '] input').prop('disabled', false);
                } else {
                    $('div[class*=databases_' + id + '] input').addClass('disabled');
                    $('div[class*=databases_' + id + '] input').prop('disabled', true);
                }
            }
        }

    },

    initConditions: function (model) {
        if (model == 'advanced') {
            $(".conditions input[id^='conditions_operator']").last().closest('.input-group ').hide();
        }
    },

    // We need to map the readingRationales here before calling the search since only the german translation is saved in the SOLR.
    // When calling the advanced-/ bool-search we have to give it the german translation instead of the enum.
    determineReadingRationaleTranslation: function (value) {
        var readingRationaleTranslations = {
            'LOOK_INTO': 'Auseinandersetzen',
            'IMMERSE': 'Eintauchen',
            'DISCOVER': 'Entdecken',
            'RELAX': 'Entspannen',
            'LAUGH': 'Lachen',
            'READ_EASY': 'Leichtlesen',
            'THRILL': 'Nervenkitzeln',
            'OPTIMIZE': 'Optimieren',
            'ORIENT': 'Orientieren',
            'UNDERSTAND': 'Verstehen'
        }
        return readingRationaleTranslations[value];
    }
});