var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "error-"
    + escapeExpression(lambda(depth0, depth0))
    + " ";
},"3":function(depth0,helpers,partials,data) {
  return "data-changeEvent=\"true\"";
  },"5":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "data-special-behaviour=\""
    + escapeExpression(((helper = (helper = helpers.specialBehaviour || (depth0 != null ? depth0.specialBehaviour : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"specialBehaviour","hash":{},"data":data}) : helper)))
    + "\"";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"input-group ";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.errorAnchor : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n    <input type=\"hidden\" id=\""
    + escapeExpression(lambda((data && data.id), depth0))
    + "\" class=\"form-control \" ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.changeEvent : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.specialBehaviour : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "/>\n</div>\n\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}