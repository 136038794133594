var Application = require('application');
var View = require('./view');
var ListViewTemplate = require('./templates/listview');
var MsgHelper = require('lib/msg_helper');
var MiscHelper = require('lib/misc_helper');
// var FormDefinition = require('models/formDefinition');
var FormHelper = require('lib/form_helper');
var RenderHelper = require('lib/renderer_helper');
var ActionHelper = require('lib/action_helper');
var UserHelper = require('lib/user_helper');
var DateWidget = require('./forms/widgets/datefield');
var RadioWidget = require('./forms/widgets/radio');
var SelectionWidget = require('./forms/widgets/selection');
var ConfirmationHelper = require('lib/confirmation_helper');
var FormBlock = require('./form_block_view');
var AjaxSpinner = require('lib/ajaxspinner_helper');
var ConfirmationAcknowledge = require('views/templates/forms/confirmationAcknowledge');
var Model = require('models/model_with_auth');

var ListView;
var codelists;
var that;

this.logger = log4javascript.getLogger('views.ListView');

module.exports = ListView = View.extend({

    className: 'form-horizontal',
    tagName: 'form',
    region: 'content',
    containerMethod: 'html',
    enableRowSelection: true,
    enableHead: true,
    enableScrollX: true, //used for Datatables scrollX

    initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);

        var action = options.model.get('action');

        if (action !== 'startQuickSearch' && action !== 'startAdvancedSearch') {
            $('.quick-search #globalQuickSearch').val('');
        }

        if (!this.options.modelKey) {
            this.options.modelKey = this.model.modelKey;
        }

        if (this.options.showHeader === undefined) {
            this.options.showHeader = true;
        }

        this.options.dealWithRowTitle = MsgHelper.getMessage('label.' + this.options.modelKey + '.list.dealWith.rowTitle');

        selectedIds = [];
        selectedIds.remove = function () {
            var what, a = arguments, L = a.length, ax;
            while (L && this.length) {
                what = a[--L];
                while ((ax = this.indexOf(what)) !== -1) {
                    this.splice(ax, 1);
                }
            }
            return this;
        };
        that = this;
        this.selections = {ids: selectedIds};

        this.datePickers = [];
        this.selectWidgets = [];
        this.columns = [];
        this.isExtendedView = false;
        this.lastSortings = {col: -1, count: 0, dir: 'asc'};

        if (this.id === 'qualityReport') {
            var AvailableInvoiceReportYears = Model.extend({urlRoot: apiRoot + 'reporting/invoicePeriods'});
            var availableInvoiceReportYears = new AvailableInvoiceReportYears();
            availableInvoiceReportYears.fetch({async: false});
            var years = availableInvoiceReportYears.get('content');
            years.sort(function (a, b) {
                return a - b;
            });

            var label = MsgHelper.getMessage('label.form.reporting.field.count');
            label = label.replace('{yearinv}', years[0]);
            MsgHelper.setMessage('label.form.reporting.field.count', label);
            label = MsgHelper.getMessage('label.form.reporting.list.footerText');
            label = label.replace('{yearinv}', years[0]);
            label = MsgHelper.setMessage('label.form.reporting.list.footerText', label);
            label = MsgHelper.getMessage('label.form.button.anual/export_current');
            label = label.replace('{yearinv}', years[0]);
            label = MsgHelper.setMessage('label.form.button.anual/export_current', label);
            label = MsgHelper.getMessage('label.form.reporting.list.bottomText');
            label = label.replace(/{yearinv}/g, years[0]);
            label = label.replace(/{year-1inv}/g, years[0] - 1);
            label = MsgHelper.setMessage('label.form.reporting.list.bottomText', label);
            label = MsgHelper.getMessage('label.form.button.anualMulti/export_current');
            label = label.replace('{yearinv}', years[0]);
            label = MsgHelper.setMessage('label.form.button.anualMulti/export_current', label);
            if (years.length > 1) {
                label = MsgHelper.getMessage('label.form.reporting.field.count2');
                label = label.replace('{year+1}', years[1]);
                MsgHelper.setMessage('label.form.reporting.field.count2', label);
                label = MsgHelper.getMessage('label.form.button.anual/export_next');
                label = label.replace('{year+1}', years[1]);
                MsgHelper.setMessage('label.form.button.anual/export_next', label);
                label = MsgHelper.getMessage('label.form.button.anualMulti/export_next');
                label = label.replace('{year+1}', years[1]);
                MsgHelper.setMessage('label.form.button.anualMulti/export_next', label);
            }
        }
    },

    events: {
        'click .collapseButton': 'extendFilter',
        'keyup': 'executeFilter'
    },

    listen: {
        'form_def_loaded mediator': 'formDefinitionLoaded'
    },

    getTemplateFunction: function () {
        return ListViewTemplate;
    },

    getTemplateData: function () {
        var data = View.prototype.getTemplateData.call(this);
        data.formDef = this.options.formDef.attributes;
        data.modelAttributes = this.model.attributes;
        data.contentType = this.model.get('contentType');
        data.tableId = this.options.tableId;
        data.showHeader = this.options.showHeader;
        data.enableRowSelection = this.enableRowSelection;
        data.dealWithNavTitle = MsgHelper.getMessage('label.' + this.options.modelKey + '.list.dealWith.navTitle');
        if (MiscHelper.compareComplex(this.model.attributes, data.formDef.showHelpBox)) {
            var messageIdent = "";
            if (data.formDef.wid !== undefined) {
                messageIdent = data.formDef.wid;
            } else {
                messageIdent = data.formDef.model;
            }
            var headerTextLbl;
            var headerText;
            if (data.formDef.headerText === undefined) {
                headerTextLbl = 'form.' + data.formDef.model + '.' + data.formDef.mode.toLowerCase() + '.' + this.model.get('contentType') + '.headerText';
                if (data.formDef.model === "assetFile" && user.authorities.indexOf('PUBLISHER_BASIC') >= 0) {
                    headerTextLbl += '.mb.basic';
                }
            } else {
                headerTextLbl = data.formDef.headerText;
            }
            headerTextLbl = 'label.' + headerTextLbl;
            headerText = MsgHelper.getMessage(headerTextLbl);
            if (headerText === headerTextLbl) {
                headerText = "";
            } else {
                headerText = new Handlebars.SafeString(MsgHelper.getMessage(headerText));
            }
            data.helpbox = {
                description: headerText,
                url: 'help.link.search.' + messageIdent,
                isPlainDescription: 'true'
            };
        }

        // set list perspective class (initial)
        if (this.model.get('perspective') === 'detailedPerspective' || this.model.get('perspective2') === 'detailedPerspective') {
            data.perspectiveClass = 'perspective-detailed';
        } else {
            data.perspectiveClass = 'perspective-list';
        }

        if (this.model.get('modelKey') === "orderbookOpenOrders" || this.model.get('modelKey') === "orderbookSentOrders") {
            data.perspectiveClass = 'perspective-list';
        }

        return data;
    },

    formDefinitionLoaded: function (loadedFormDef) {
        //on classic single form page,both are undefined, in pagecontent style we need to check if its the correct widget
        if (loadedFormDef.get('wid') !== this.id) {
            return;
        }
//    	console.log("formDefinitionLoaded->" + this.options.modelKey);
//    	console.log(this.options.modelKey + ".formDefinitionLoaded->" + loadedFormDef);

        this.collections = {};

        this.options.tableId = 'datatable_' + this.region;
        this.options.tableSelector = '#' + this.options.tableId;
        if (!MiscHelper.isEmpty(this.options.formDef.attributes.id)) {
            this.id = this.options.formDef.attributes.id.replace(/\//g, '_');
        } else {
            this.id = this.options.formDef.attributes.model + '_' + this.options.formDef.attributes.mode;
        }
        this.options.tableIdentGlobal = 'DataTables_' + this.id;
        if (this.options['searchType'] !== undefined) {
            this.options.tableIdentGlobal += '_' + this.options['searchType'];
        }
        if (this.model.get('contentType') !== undefined) {
            this.options.tableIdentGlobal += '_' + this.model.get('contentType');
        }

        var data = sessionStorage.getItem(this.options.tableIdentGlobal);
        var parsedData = JSON.parse(data);

        var listData = sessionStorage.getItem('listPerspective');
        var parsedListData = JSON.parse(listData);

        if (parsedData !== null && parsedData.databases) {
            // If databases are available in the session storage this table was shown former. So adopt the different
            // databases to the different attributes that contains the databases.
            this.model.set('databases', parsedData.databases);
            this.model.set('dbFilterArray', parsedData.databases);
            var query = MiscHelper.stripDatabaseFilterFromQuery(this.model.get('query'));
            query = MiscHelper.rebuildQuery(query, undefined, undefined, parsedData.databases, this.model.get('mode')).query;
            this.model.set('query', query);
        }

        if (parsedListData !== null && parsedListData.perspective) {
            this.model.set('perspective', parsedListData.perspective);
        } else {
            this.model.set('perspective', 'detailedPerspective');
        }
        if (parsedListData !== null && parsedListData.perspective2) {
            this.model.set('perspective2', parsedListData.perspective2);
        } else {
            this.model.set('perspective2', 'detailedPerspective');
        }
        if (parsedData !== null && parsedData.sortOrder) {
            //this.model.sortOrder = parsedData.sortOrder;
            this.model.set('sortOrder', parsedData.sortOrder);
            //this.setSortOrder(parsedData.sortOrder);
        } else {
            this.model.set('sortOrder', 'neutral');
            //this.setSortOrder('neutral');
        }
        if (parsedData !== null && parsedData.orderingAttribute) {
            //this.model.orderingAttribute = parsedData.orderingAttribute;
            this.model.set('orderingAttribute', parsedData.orderingAttribute);
        }
        // else if(this.id === 'productList') {
        //     if(!MiscHelper.isEmpty(this.model.get('contentType')) && $.inArray(this.model.get('contentType'), ['series','set']) > -1) {
        //         this.model.set('orderingAttribute', 'sequence');
        //     } else {
        //         var selectValue = '';
        //         if (user.authorities.indexOf('MVB') > -1 || user.authorities.indexOf('ADMIN') > -1) {
        //             selectValue = 'score';
        //         } else if (user.authorities.indexOf('BOOKSTORE') > -1 && user.authorities.indexOf('PUBLISHER') > -1) {
        //             // check search type
        //             selectValue = 'score';
        //         } else if (user.authorities.indexOf('BOOKSTORE') > -1) {
        //             selectValue = 'score';
        //         } else if (user.authorities.indexOf('PUBLISHER') > -1) {
        //             selectValue = 'publicationDate';
        //         }
        //         this.model.set('orderingAttribute', selectValue);
        //     }
        // }


        if (parsedData !== null && parsedData.start) {
            //this.model.orderingAttribute = parsedData.orderingAttribute;
            this.model.set('start', parsedData.start);
        }

        if (this.options.formDef.attributes['selectable'] !== undefined) {
            this.enableRowSelection = this.options.formDef.attributes['selectable'];
        }

        $(this.el).attr('id', this.id);
        codelists = {};
        ActionHelper.prepareHeaderActions(this.options.formDef, 'list');
        this.prepareFilters();
        this.render();
        this.prepareBlocks();

        this.initAfterBinding();

        Chaplin.mediator.subscribe('beforeDataLoaded', this.beforeDataLoaded, this);
        Chaplin.mediator.subscribe('afterDataLoaded', this.afterDataLoaded, this);


        Chaplin.mediator.subscribe('tabContentRefresh', this.tabContentRefresh, this);

        this.delegate('click', '[data-toggle="selection"]', this.selectionAction);
        this.delegate('click', '[data-toggle="confirmationMultiple"]', this.confirmMultipleAction);
        this.delegate('click', '[data-special-behaviour]', this.specialBehaviour);
        this.delegate('change', 'input[data-special-behaviour]', this.specialBehaviour);
        this.delegate('click', '[action=print]', function () {
            window.print();
        });
        this.delegate('click', '#backBtn', this.historyBack);

        if (Application.flashMessage !== undefined) {
            MsgHelper.showSuccessAlert(Application.flashMessage);
            Application.flashMessage = undefined;
        }
        this.initDatatable();

        $('#extendedFilter').hide();

        /*
         var listData = sessionStorage.getItem('listPerspective');
         var parsedListData = JSON.parse(listData)
         var perspective = parsedListData.perspective;
         var perspective2 = parsedListData.perspective2;
         $('a[href=#widget-productTracker-tab]').on('shown.bs.tab', function(event) {
         $('#'+perspective).trigger('click');
         });
         $('a[href=#widget-productTrackerEdit-tab]').on('shown.bs.tab', function(event) {
         $('#'+perspective2).trigger('click');
         });
         */
    },

    prepareBlocks: function () {

        if (this.options.formDef.get('mform') && this.options.formDef.get('mform').block) {
            this.blocksToLoad = this.options.formDef.get('mform').block.length;
            //var delay = 0;
            if(this.options.formDef.attributes['mform'].collections && this.options.formDef.attributes['mform'].collections.collection) {
                var arrCollection = this.options.formDef.attributes['mform'].collections.collection;
            
                for (var i = 0; i < arrCollection.length; i++) {
                    this.options.formDef.get('collections').collection.push(arrCollection[i]);
                }
            }
            for (var i = 0; i < this.options.formDef.get('mform').block.length; i++) {
                var block = this.options.formDef.get('mform').block[i];
                block.blockIndex = i;
                if (MiscHelper.compareComplex(this.model.attributes, block.visible)) {
                    var formBlockView = new FormBlock({
                        el: "#block_" + block.blockId,
                        model: this.model.formModel === undefined ? this.model : this.model.formModel,
                        blockDef: block,
                        formDef: this.options.formDef,
                        parentView: this,
                        showTitle: false
                    });
                    this.subview("block_" + block.blockId, formBlockView);
                    formBlockView.startBlock(formBlockView, this.blockLoaded);
                    //_.delay(formBlockView.startBlock, delay, formBlockView, _.bind(this.blockLoaded, this));
                    //delay += 100;
                }
            }
        }
    },

    blockLoaded: function () {
        this.blocksToLoad -= 1;

        if (this.blocksToLoad === 0) {
            //all loaded
            //TODO find out if we need to rebind it also after adding/removing widgets
            if (this.options.formDef.attributes.isEditable && this.useNod) {
                if (this.options.formDef.attributes.validateOnServer) {
                    this.delegate('change', 'input', this.validateOnServer);
                } else {
                    // bind nod
                    $("#" + this.id).nod(this.validations, {delay: false});
                }
            }

            //its form wide, so we should only need it once
            this.delegate('click', '[data-toggle="confirmationSingle"]', this.confirmAction);
            // init custom actions if available
            if (this.model.initActions) {
                this.model.initActions();
            }
            if (this.model.attributes && this.model.attributes.sendCredentials) {
                var formBlockView = new FormBlock({
                    model: this.model,
                    formDef: this.options.formDef,
                    parentView: this,
                    showTitle: false
                });
                formBlockView.sendCredentials();
            }
        }
    },

    prepareFilters: function () {
        if (this.options.formDef.get('filters')) {
            if (this.options.formDef.get('filters').filterGroup) {
                for (var i = 0; i < this.options.formDef.get('filters').filterGroup.length; i++) {
                    for (var j = 0; j < this.options.formDef.get('filters').filterGroup[i].filter.length; j++) {
                        this.bindFilters(this.options.formDef.get('filters').filterGroup[i].filter[j]);
                    }
                }
            }
            if (this.options.formDef.get('filters').extendedFilter) {
                for (var i = 0; i < this.options.formDef.get('filters').extendedFilter.filterGroup.length; i++) {
                    for (var j = 0; j < this.options.formDef.get('filters').extendedFilter.filterGroup[i].filter.length; j++) {
                        this.bindFilters(this.options.formDef.get('filters').extendedFilter.filterGroup[i].filter[j]);
                    }
                }
            }
        }
    },

    bindFilters: function (filter) {
        if (filter.type === "DATE") {
            // ./forms/widgets/datefield.js
            var widget = new DateWidget(filter, this.model.modelKey);
            this.datePickers.push(widget);
        } else if (filter.type === "DROPDOWN") {
            widget = new SelectionWidget(filter, this.model.modelKey);
            if (filter.multiple === true) {
                widget.multiple = true;
            }
            this.selectWidgets.push(widget);
        } else if (filter.type === "RADIO") {
            if (this.options.formDef.get('collections')) {
                for (var k = 0; k < this.options.formDef.get('collections').collection.length; k++) {
                    if (this.options.formDef.get('collections').collection[k].id === filter.collection) {
                        filter.options = [];
                        for (var l = 0; l < this.options.formDef.get('collections').collection[k].value.length; l++) {
                            var val = {};
                            val.value = 'form.collection.' + filter.collection + "." + this.options.formDef.get('collections').collection[k].value[l].id
                            val.id = this.options.formDef.get('collections').collection[k].value[l].id;
                            if (l === 0) {
                                val.checked = true;
                            }
                            filter.options.push(val);
                        }
                    }
                }
            }
        }
    },

    initAfterBinding: function () {
        this.initDatePickers();
        this.initSelects();
    },

    initDatePickers: function () {
        // remove old datepickers
        //$('.datepicker').datetimepicker('destroy'); doesn't work
        $('.bootstrap-datetimepicker-widget').remove();

        for (var i = 0; i < this.datePickers.length; i++) {
            this.datePickers[i].initAfterBinding(this.model, '#filter-' + this.datePickers[i].id);
        }
    },

    initSelects: function () {
        for (var i = 0; i < this.selectWidgets.length; i++) {
            var sw = this.selectWidgets[i];

            var opts = sw.getOptions(this.options.formDef.get('collections'));
            sw.initAfterBinding(opts, this.model, true, '#filter-' + sw.id);
        }
    },

    getModelUrl: function () {
        return this.model.urlRoot;
    },

    displaySearchBox: function () {
        return this.options.formDef.attributes['searchable'];
    },

    getDatatableSDom: function () {
        if (this.options.formDef.attributes['lengthChanging'] === false) {
            if (this.options.formDef.attributes['showPageNumber'] === false) {
                return "t";
            } else {
                return "t p";
            }
        } else {
            return '<"paginationTop"p> <"tableLengthTop"l> t <"tableLengthBottom"l> <"paginationBottom"p>';
        }
    },

    getFirstSortableColumn: function (columns) {
        for (var i = 0; i < columns.length; i++) {
            if (columns[i].sortable) {
                if (this.enableRowSelection) {
                    return i + 1; //due selectionRow in datatables
                }
                return i;
            }
        }
    },

    defaultSortableColumn: function (columns) {
        if (this.id === 'product_list') {
            var selectValue = '';
            // always sort metabooks by creationdate
            if (user.authorities.indexOf('BRASIL_PRICES') > -1) {
            	selectValue = 'creationDate';
            } else if ($.inArray(this.model.get('contentType'), ['series', 'set']) > -1) {
                selectValue = 'sequence';
            } else {
                if (user.authorities.indexOf('MVB') > -1 || user.authorities.indexOf('ADMIN') > -1) {
                    selectValue = 'score';
                } else if (user.authorities.indexOf('BOOKSTORE') > -1 && user.authorities.indexOf('PUBLISHER') > -1) {
                    if (this.model.get('mode') === 'quick') {
                        var query = this.model.get('query');
                        query = MiscHelper.stripDatabaseFilterFromQuery(query);
                        if (MiscHelper.isEmpty(query)) {
                            selectValue = 'creationDate';
                        } else {
                            selectValue = 'score';
                        }
                    } else {
                        selectValue = 'score';
                    }
                } else if (user.authorities.indexOf('BOOKSTORE') > -1) {
                    selectValue = 'score';
                } else if (user.authorities.indexOf('PUBLISHER') > -1) {
                    selectValue = 'creationDate';
                }
            }
            if (MiscHelper.isEmpty(selectValue)) {
                selectValue = 'score';
            }
            for (var i = 0; i < columns.length; i++) {
                if (columns[i].property === selectValue) {
                    return i;
                }
            }
        } else {
            for (var i = 0; i < columns.length; i++) {
                if (columns[i].defaultSort !== undefined
                    && (columns[i].showOn === undefined || MiscHelper.compareComplex(this.model.attributes, columns[i].showOn))) {
                    return i;
                }
            }
        }
        return -1;
    },

    defaultSortingColumn: function () {
        var sort;
        var sortOrder = 'asc';
        //if (this.model.get('sortCol') !== undefined) {
        //    sort = 0;
        //} else
        {
            var tmpCol = this.defaultSortableColumn(this.options.formDef.attributes['column']);
            if (tmpCol > -1) {
                if (sortOrder = this.options.formDef.attributes['column'][tmpCol].defaultSort) {
                    sortOrder = this.options.formDef.attributes['column'][tmpCol].defaultSort.toLowerCase();
                    this.setSortOrderIcon(sortOrder);
                } else {
                    sortOrder = '';
                    this.setSortOrderIcon('neutral');
                }
                if (this.enableRowSelection) {
                    tmpCol += 1; //due selectionColumn in datatables
                }
                sort = tmpCol;
            } else {
                sort = this.getFirstSortableColumn(this.options.formDef.attributes['column']);
            }
        }
        return [sort, sortOrder];
    },

    initDatatable: function () {
        var self = this;
        var columns = [];
        // push the checkbox colum
        logger.debug('Form definition: ', this.options.formDef.attributes['column']);
        if (this.enableRowSelection) {
            var selectCheckBoxColDef = {
                "sClass": "selectionColumn",
                "mData": "selectionColumn",
                "sTitle": '',
                "bSortable": false,
                "orderable": false,
                "render": function (value, type, row, meta) {

                    var ident = row.identifier;
                    var retVal = '<input type="checkbox" class="rowSelectCheckbox" data-id="' + row.id + '" ';
                    if (row.lastModifiedDate !== undefined) {
                        retVal += 'data-lastModifiedDate="' + row.lastModifiedDate + '" ';
                    }
                    // if (row.announcementDate !== undefined) {
                    //     retVal += 'data-announcementDate="' + row.announcementDate + '" ';
                    // }
                    if (row.active !== undefined) {
                        retVal += 'data-active="' + row.active + '" ';
                    }
                    if (row.productType !== undefined) {
                        retVal += 'data-type="' + row.productType + '" ';
                    }
                    if (row.productVLB !== undefined) {
                        retVal += 'data-productvlb="' + row.productVLB + '" ';
                    }
                    if (row.isbn !== undefined) {
                        retVal += 'data-isbn="' + row.isbn + '" ';
                    }
                    if (row.ident !== undefined) {
                        retVal += 'data-ident="' + ident + '" ';
                    }
                    if (row.author != undefined) {
                        retVal += '" data-author="' + row.author + '" ';
                    }
                    if (row.title != undefined) {
                        // make sure the double quotes are escaped as they are responsible for errors in the confirmation
                        var title = row.title.replace(/"/g, '&quot;').replace(/'/g, '&quot;');
                        retVal += 'data-title="' + title + '" ';
                    }
                    if (row.publisher != undefined) {
                        retVal += 'data-publisher="' + row.publisher + '" ';
                    }
                    if (row.publisherForMvbId != undefined) {
                        retVal += 'data-publisherForMvbId="' + row.publisherForMvbId + '" ';
                    }
                    if (row.aggregatorId != undefined) {
                        retVal += 'data-aggregatorid="' + row.aggregatorId + '" ';
                    } else if (row.productVLB) {
                        if (row.active === true) {
                            retVal += 'data-aggregatorid="0"';
                        } else if (row.active === false) {
                            retVal += 'data-aggregatorid="1"';
                        }
                    }
                    if (row.setId != undefined) {
                        retVal += 'data-setid="' + row.setId + '" ';
                    }
                    if (row.seriesId != undefined) {
                        retVal += 'data-seriesid="' + row.seriesId + '" ';
                    }
                    if (row.productId != undefined) {
                        retVal += 'data-productid="' + row.productId + '" ';
                    }
                    if (row.publisherMvbId != undefined) {
                        retVal += 'data-publisherMvbId="' + row.publisherMvbId + '" '
                    }
                    for (var i = 0; i < that.selections.ids.length; i++) {
                        if (that.selections.ids[i].id === row.id) {
                            retVal += '" checked="checked';
                        }
                    }
                    retVal += '" />';
                    return retVal;
                }
            };
            columns.push(selectCheckBoxColDef);
        }

        for (var i = 0; i < this.options.formDef.attributes['column'].length; i++) {
            var column = this.options.formDef.attributes['column'][i];
            var columnDef = this.createColumnDef(column, this.options.formDef.attributes['collections']);
            columns.push(columnDef);
        }

        logger.debug('COLUMNS: ', columns);

        var pageSize = this.options.formDef.attributes['entriesPerPage'] === undefined ? user.profile.numberOfRecords : this.options.formDef.attributes['entriesPerPage'];
        if (this.options.formDef.attributes['maxEntries'] !== undefined) {
            pageSize = this.options.formDef.attributes['maxEntries'];
        }

        $(this.options.tableSelector).attr("data-modelurl", this.getModelUrl());
        var tableSelector = this.options.tableSelector;
        var view = this;
        // (HGF) I don't know who steals my view.options. So I memorize the options extra.
        var viewOptions = view.options;
        var actionOnRowClick = ActionHelper.getActionOnRowClick(this.options.formDef);

        // this is not used anymore, please remove the query for initialSort...
        //if (this.options.formDef.attributes['initialSort'])
        //{
        var tmpCol = this.defaultSortingColumn();
        var sort = tmpCol[0];
        var sortOrder = tmpCol[1];
        //} else {
        //    var sort = undefined;
        //    var sortOrder = undefined;
        //}

        var id = this.id;

        var lengthMenu = [[10, 25, 50, 100], [10, 25, 50, 100]];
        if (this.id === 'productTracker_list' || this.id === 'productTrackerEdit_list') {
            lengthMenu = [[10, 25, 50], [10, 25, 50]];
        }

        this.options.table = $(this.options.tableSelector).DataTable({
            "dom": this.getDatatableSDom(),
            "bServerSide": true,
            "stateSave": true,
            "stateSaveCallback": function (settings, aData) {
                if (self.model) {
                    if (self.model.get('databases')) {
                        // remember the selected databases of that table for later use.
                        aData.databases = self.model.get('databases');
                    }
                    if (self.model.get('perspective')) {
                        aData.perspective = self.model.get('perspective');
                    }
                    if (self.model.get('perspective2')) {
                        aData.perspective2 = self.model.get('perspective2');
                    }
                    if (self.model.get('sortOrder')) {
                        aData.sortOrder = self.model.get('sortOrder');
                    }
                    if (self.model.get('orderingAttribute')) {
                        aData.orderingAttribute = self.model.get('orderingAttribute');
                    }
                    if (self.model.get('start')) {
                        aData.start = self.model.get('start');
                    }
                }

                sessionStorage.setItem(viewOptions.tableIdentGlobal, JSON.stringify(aData));

                var listData = sessionStorage.getItem('listPerspective');
                if (listData === null) {
                    listData = {};
                    listData.perspective = aData.perspective;
                    listData.perspective2 = aData.perspective;
                    sessionStorage.setItem('listPerspective', JSON.stringify(listData));
                }
            },
            "stateLoadCallback": function (settings) {
                var data = sessionStorage.getItem(viewOptions.tableIdentGlobal);
                var parsedData = JSON.parse(data);

                var listData = sessionStorage.getItem('listPerspective');
                var parsedListData = JSON.parse(listData);

                if (parsedData !== null && view.hasLengthChanging()) {
                    //var length = parsedData.length;
                    parsedData.length = user.profile.numberOfRecords;
                }
                if (parsedData !== null && parsedData.databases) {
                    self.model.set('databases', parsedData.databases);
                    self.model.set('dbFilterArray', jQuery.extend(true, {}, self.model.get('databases')));
                }
                //if (parsedData !== null && parsedData.perspective) {
                if (parsedListData !== null && parsedListData.perspective) {
                    self.model.set('perspective', parsedListData.perspective);
                    self.switchList(parsedListData.perspective);
                }
                if (parsedListData !== null && parsedListData.perspective2) {
                    self.model.set('perspective2', parsedListData.perspective2);
                    self.switchList2(parsedListData.perspective2);
                }
                if (parsedData !== null && parsedData.sortOrder) {
                    self.setSortOrderIcon(parsedData.sortOrder);
                }
                if (parsedData !== null && parsedData.orderingAttribute) {
                    self.model.set('orderingAttribute', parsedData.orderingAttribute);
                }
                if (parsedData !== null && parsedData.start) {
                    self.model.set('start', parsedData.start);
                }
                return parsedData;
            },
            "initComplete": function (settings, json) {
                Chaplin.mediator.unsubscribe('executedNewSearch', view.executedNewSearch, viewOptions.tableIdentGlobal);
                Chaplin.mediator.subscribe('executedNewSearch', view.executedNewSearch, viewOptions.tableIdentGlobal);
            },
            "order": sort === undefined ? [] : [[sort, sortOrder]],
            "bSort": sort === undefined ? false : true,
            "sAjaxDataProp": 'content',
            "scrollX": this.enableScrollX,
            "autoWidth": true,
            "columns": columns,
            "fnServerData": this.getDataFromRest,
            "fnServerParams": function (aoData) {
                aoData.listViewModel = self.model;
                // Please do not use this parameter anymore. Please use the previous one.
                aoData.model = self.model.attributes;
                aoData.tableSelector = tableSelector;
                aoData.view = view;
                aoData.actions = "";
                // Is there display start that must be reused? jQuery.Datatable changes that value while redrawing
                // some elements.
                if (!MiscHelper.isEmpty(self.options.saveTheDsisplayStart)) {
                    aoData.start = self.options.saveTheDsisplayStart;
                }
            },
            "fnDrawCallback": function (settings) {

                // Set title to all cells which have dotToLongText set as class.
                $('.dotToLongText').each(function () {
                    $(this).attr('title', $(this).text());
                });

                var hideTableHeader = !eval(viewOptions.formDef.attributes['displayHeader']);
                if (hideTableHeader) {
                    var headSelector = '#' + view.region + ' thead';
                    var o = $(headSelector);
                    if (o !== undefined) {
                        o.remove();
                    }
                    headSelector = '#' + view.region + ' .dataTables_scrollHead';
                    o = $(headSelector);
                    if (o !== undefined) {
                        o.remove();
                    }
                }
            },
            "fnCreatedRow": function (nRow, aData, iDataIndex) {
                $(nRow).attr('id', aData.id);

                if (aData.archived !== undefined && aData.archived) {
                    $(nRow).addClass('deactivated');
                }

                var cell = $('td', nRow);
                if (actionOnRowClick !== undefined) {
                    if (actionOnRowClick['showOn'] === undefined || MiscHelper.compareComplex(aData, actionOnRowClick['showOn'])) {
                        $(nRow).addClass('pointer');
                    }
                    if (actionOnRowClick['targetUrl']) {
                        $(nRow).bind('click', function (e) {
                            if (actionOnRowClick['showOn'] !== undefined) {
                                if (!MiscHelper.compareComplex(aData, actionOnRowClick['showOn'])) {
                                    return;
                                }
                            }
                            var tag = e.target;
                            var tagParent = tag.parentNode;
                            if (tag.tagName !== 'A' && tagParent.tagName !== 'A' && tag.tagName !== 'INPUT') {
                                var fancyBoxesDiv = $('#download-div');
                                var iframe = $('#download-div iframe');
                                if (iframe !== undefined) {
                                    $('#download-div iframe').remove();
                                }
                                iframe = $("<iframe id=\"iFrameDownload\" />").appendTo(fancyBoxesDiv);
                                var url = FormHelper.buildApiUrl(actionOnRowClick['targetUrl'] + '/' + aData[actionOnRowClick['property']], true);
                                if (actionOnRowClick['method'] === 'download') {
                                    iframe.attr('src', url);
                                } else {
                                    window.open(url, '_blank');
                                }
                            } else {
                                var _menu = $('.settings-menu');
                                _menu.hide();
                            }
                        });
                    } else {
                        $(nRow).bind('click', function (e) {
                            if (actionOnRowClick['showOn'] !== undefined) {
                                if (!MiscHelper.compareComplex(aData, actionOnRowClick['showOn'])) {
                                    return;
                                }
                            }
                            var tag = e.target;
                            var tagParent = tag.parentNode;
                            if (tag.tagName !== 'A' && tagParent.tagName !== 'A' && tag.tagName !== 'INPUT') {
                                var targetModel = self.model.modelKey;
                                if (aData.productType === 'set' || aData.productType === 'series') {
                                    targetModel = 'collection';
                                } else if (actionOnRowClick['targetModel'] !== undefined) {
                                    targetModel = actionOnRowClick['targetModel'];
                                }
                                var routeName = targetModel + "_" + actionOnRowClick['method'].toLowerCase();
                                if (routeName === 'searchhistory_executequery') {
                                    aData.action = 'startQuickSearch';
                                }
                                aData.clickedInList = id;
                                var index = view.options.table.page() * 10 + e.currentTarget.rowIndex - 1;
                                aData.record = index;
                                Chaplin.mediator.publish("redirectTo", {url: routeName, data: aData});
                            } else {
                                var _menu = $('.settings-menu');
                                _menu.hide();
                            }

                        });

                    }
                }
            },
            "oLanguage": {
                "sSearch": MsgHelper.getMessage('label.datatable.search'),
                "sLengthMenu": '_MENU_',
                //"sLengthMenu": MsgHelper.getMessage('label.datatable.entries.show') + ' _MENU_ ' + MsgHelper.getMessage('label.datatable.entries.entries'),
                "sZeroRecords": MsgHelper.getMessage('label.datatable.zeroRecords'),
                "oPaginate": {
                    "sNext": MsgHelper.getMessage('label.datatable.next'),
                    "sPrevious": MsgHelper.getMessage('label.datatable.previous'),
                    "sFirst": MsgHelper.getMessage('label.datatable.first'),
                    "sLast": MsgHelper.getMessage('label.datatable.last')
                },
                "sInfo": MsgHelper.getMessage('label.datatable.pagination.entriesfound'),
                "sInfoThousands": MsgHelper.getMessage('label.datatable.pagination.number.thousands'),
                "sInfoEmpty": MsgHelper.getMessage('label.datatable.info.empty'),
                "sProcessing": MsgHelper.getMessage('label.datatable.table.busy')
            },
            "bFilter": view.displaySearchBox(),
            "iDisplayLength": pageSize,
            "aLengthMenu": lengthMenu
        });

        // synchronize the display length chooser.
        $(this.options.tableSelector).on('length.dt', function (e, settings) {
            user.profile.numberOfRecords = settings._iDisplayLength;
            $('.tableLengthTop span[class*="chosen"]').text(settings._iDisplayLength);
            $('.tableLengthBottom span[class*="chosen"]').text(settings._iDisplayLength);
        });

        $("select[name=" + this.options.tableId + "_length]").select2({
            minimumResultsForSearch: 10,
            allowClear: false
        });

        $(".dataTables_scrollBody").css("overflow", "visible");
    },

    hasLengthChanging: function () {
        return this.options.formDef.attributes['lengthChanging'] === true;
    },

    getAsParamMap: function (aoData) {
        var paramMap = {};
        for (var i = 0; i < aoData.length; i++) {
            paramMap[aoData[i].name] = aoData[i].value;
            if (aoData[i].tableSelector !== undefined) {
                paramMap["tableSelector"] = aoData[i].tableSelector;
            }
            if (aoData[i].model !== undefined) {
                paramMap["model"] = aoData[i].model;
            }
            if (aoData[i].view !== undefined) {
                paramMap["view"] = aoData[i].view;
            }
        }
        return paramMap;
    },

    getDatatableUrl: function (tableSelector) {
        return $(tableSelector).attr("data-modelurl") + 's';
    },

    getSearchPhrase: function (paramMap) {
        return paramMap.search.value;
    },

    getDatatablesRequestHttpType: function () {
        return 'GET';
    },

    getDatatablesRequestData: function (paramMap) {
        var view = paramMap["view"];
        // page calculations
        var pageSize = paramMap.length;
//		console.log(this.options.modelKey + ".pageSize->" + pageSize);
//		console.log(paramMap);
        var start = paramMap.start;
        var pageNum = (start == 0) ? 1 : (start / pageSize) + 1; // pageNum is 1 based
        // now ensure that the requested page and the displayed page number are identic.
        if (this.options !== undefined && this.options.table !== undefined) {
            this.options.table.page(pageNum - 1);
        }
        var searchPhrase = view.getSearchPhrase(paramMap);
        var requestData = {
            page: pageNum,
            size: pageSize,
            search: searchPhrase,
            action: view.model.get('action')
        };
        // currently filters are not used on server side.
        var dbFilterArray = view.model.get('dbFilterArray');
        if (dbFilterArray !== undefined) {
            var filters = [];
            for (db in dbFilterArray) {
                if (dbFilterArray[db]) {
                    filters.push({id: 'db', val: db});
                }
            }
            requestData.filters = filters;
        }

        // extract sort information
        if (view.model.get('sortCol') !== undefined && view.lastSortings['col'] === view.defaultSortingColumn()[0]) {
            requestData.sort = view.model.get('sortCol');
            requestData.direction = 'asc';
        } else if (paramMap.order.length > 0) {
            var sortCol = paramMap.order[0].column;
            requestData.sort = paramMap.columns[sortCol].data;
            requestData.direction = paramMap.order[0].dir;
        }
        var filter = {};
        var fixedFilter = paramMap.listViewModel.get('fixedFilter');
        if (fixedFilter) {
            for (var int = 0; int < fixedFilter.length; int++) {
                var entry = fixedFilter[int];
                filter[entry.key] = entry.value;
            }
        }
        if (filter) {
            requestData = $.extend(true, {}, requestData, filter);
        }
        return requestData;
    },

    getDataFromRest: function (sSource, aoData, fnCallback, oSettings) {

        var paramMap = ListView.prototype.getAsParamMap(aoData);
        var tableSelector = paramMap["tableSelector"];
        var view = paramMap["view"];
        if (view.options === undefined) {
            console.log('ERROR');
        }
        // (HGF) Don't know why but under unknown circumstances view.option is been set to undefined.
        var viewOptions = view.options;
        var viewModel = view.model;

        //// go back to the defaultSorting if the same column header was clicked three times
        //if (oSettings.aaSorting[0] !== undefined) {
        //    if (viewModel.get('sortCol') !== undefined && view.lastSortings['col'] === -1) {
        //        oSettings.aaSorting[0][0] = 0;
        //        oSettings.aaSorting[0][1] = 'asc';
        //        view.lastSortings['col'] = view.defaultSortingColumn()[0];
        //    } else {
        //        var col = oSettings.aaSorting[0][0];
        //        var dir = oSettings.aaSorting[0][1];
        //        if (view.lastSortings['col'] === col && view.lastSortings['dir'] !== dir) {
        //            view.lastSortings['count']++;
        //            view.lastSortings['dir'] = dir;
        //            if (view.lastSortings['count'] === 3) {
        //                var tmpCol = view.defaultSortingColumn();
        //                oSettings.aaSorting[0][0] = tmpCol[0];
        //                oSettings.aaSorting[0][1] = tmpCol[1];
        //                view.options.table.draw();
        //                return;
        //            }
        //        } else {
        //            view.lastSortings['col'] = col;
        //            view.lastSortings['count'] = 1;
        //            view.lastSortings['dir'] = dir;
        //        }
        //    }
        //}

        var httpType = view.getDatatablesRequestHttpType();
        var datatableUrl = view.getDatatableUrl(tableSelector);
        var requestData = view.getDatatablesRequestData(paramMap);
        var cacheId = tableSelector + "_" + view.id;
// if there is a cached filter in the global cache
        if (globalCache.has(cacheId)) {
            // there is a filter in the cache - make sure the values are shown again
            var data = globalCache.get(cacheId);

            if (data.filters) {
                for (var i = 0; i < data.filters.length; i++) {
                    if ($('#' + data.filters[i].id).attr('type') === 'text') {
                        $('#' + data.filters[i].id).val(data.filters[i].val);
                    } else if ($('#' + data.filters[i].id).attr('type') === 'hidden') {
                        $('#' + data.filters[i].id).select2('val', data.filters[i].val);
                    }
                }
                $('[id^="filter-"]').each(function (index, item) {
                    if ($(item).is("textarea")) {
                        httpType = "POST";
                    }
                });

                requestData.filters = data.filters;
            }
        } else {
            // apply filters if available
            if ($('[id^="filter-"]').size() > 0) {
                var filters = [];
                $('[id^="filter-"]').each(function (index, item) {
                    if ($(item).attr('type') === "checkbox") {
                        filters.push({id: $(item).attr('id'), val: $(item).is(":checked")});
                    } else if ($(item).is("textarea")) {
                        httpType = "POST";
                        filters.push({id: $(item).attr('id'), val: $(item).val()});
                    } else {
                        filters.push({id: $(item).attr('id'), val: $(item).val()});
                    }
                });
                $('input[type=radio][name^="filter-"]:checked').each(function (index, item) {
                    filters.push({id: $(item).attr('name'), val: $(item).val()});
                });

                requestData.filters = filters;
            }

            globalCache.set(cacheId, requestData);
        }

// finally, make the request
        jQuery.ajax({
            "dataType": 'json',
            url: datatableUrl,
            type: httpType,
            "data": requestData,
            "success": function (data) {
//			    console.log(datatableUrl+": success", data);
                logger.debug(datatableUrl + ": success", data);
                data.iTotalRecords = data.totalElements;
                data.iTotalDisplayRecords = data.totalElements;
                if (data.content.length > 0) {
                    var element = data.content[0];
                    if (Object.prototype.toString.call(element) === '[object Array]') {
                        var elementCount = element.length;
                        data.iTotalRecords = elementCount;
                        data.iTotalDisplayRecords = elementCount;
                    }
                }
                var totalElements = 0;
                //console.log(data.iTotalRecords);
                if (viewModel.get('databases') !== undefined && data.facets) {
                    // fill the databases quantities.
                    //var query = decodeURIComponent(viewModel.get('query'));

                    var hasSelectedDatabases = false;
                    var tempDataBase = viewModel.get('dbFilterArray');
                    for (db in tempDataBase) {
                        if (tempDataBase[db] === true) {
                            hasSelectedDatabases = true;
                            break;
                        }
                    }
                    //var databases = {};
                    var facet;
                    if (data.facets != undefined) {
                        for (var i = 0; i < data.facets.length; i++) {
                            facet = data.facets[i];
                            if (facet.name === 'aggregatorId') {
                                var addedHitCountDB = [];
                                for (j = 0; j < facet.values.length; j++) {
                                    var dbLabel = $('div[class*=databases_' + facet.values[j].key + '] label');
                                    if (dbLabel.length === 1) {
                                        addedHitCountDB.push(facet.values[j].key);
                                        var formatedValue = MiscHelper.formatNumber(facet.values[j].quality);
                                        dbLabel.text(MsgHelper.getMessage('label.' + facet.values[j].key) + ' (' + formatedValue + ')');
                                        if (hasSelectedDatabases) {
                                            if (tempDataBase[facet.values[j].key] === true) {
                                                totalElements += facet.values[j].quality;
                                            }
                                        }
                                        else {
                                            totalElements += facet.values[j].quality;
                                        }
                                    }
                                }
                                // set zero to these datbases which are not part of queries content.
                                var uiDatabases = $('div[class*=databases_] input');
                                if (addedHitCountDB.length < uiDatabases.length) {
                                    var startIndex = 'databases_'.length;
                                    uiDatabases.each(function (index, value) {
                                        var database = this.id.substring(startIndex);
                                        if (!_.contains(addedHitCountDB, database)) {
                                            var dbLabel = $('div[class*=databases_' + database + '] label');
                                            dbLabel.text(MsgHelper.getMessage('label.' + database) + ' (0)');
                                        }
                                    });
                                }
                            }
                        }
                    }
                }

                if (data.content) {
                    for (var i = 0; i < data.content.length; i++) {
                        var dataContent = data.content[i];
                        if (dataContent) {
                            if (Object.prototype.toString.call(dataContent) === '[object Array]') {
                                var hasAvailabilityPeriod = data.content.length == 2;
                                if (hasAvailabilityPeriod) {
                                    var objectsCollection = [];
                                    for (var j = 0; j < dataContent.length; j++) {
                                        if (hasAvailabilityPeriod) {
                                            var availabilityPeriod = data.content[1];
                                            for (var l = 0; l < availabilityPeriod.length; l++) {
                                                if (availabilityPeriod[l]["status"] == dataContent[j]["status"]) {
                                                    dataContent[j]["count2"] = availabilityPeriod[l]["count"];
                                                    break;
                                                }
                                            }
                                        }
                                        dataContent[j]["actions"] = ""; //add default action column
                                        dataContent[j]["selectionColumn"] = ""; //add default selection column
                                        var columns = viewOptions.formDef.get('column');
                                        for (var k = 0; k < columns.length; k++) {
                                            if (columns[k].property !== undefined && dataContent[j][columns[k].property] === undefined) {
                                                dataContent[j][columns[k].property] = "";
                                            }
                                        }
                                        objectsCollection.push(dataContent[j]);
                                    }
                                    data.content = objectsCollection;
                                    break;
                                } else {
                                    var objectsCollection = [];
                                    for (var k = 0; k < dataContent.length; k++) {
                                        dataContent[k]["actions"] = ""; //add default action column
                                        dataContent[k]["selectionColumn"] = ""; //add default selection column
                                        var columns = viewOptions.formDef.get('column');
                                        for (var j = 0; j < columns.length; j++) {
                                            if (columns[j].property !== undefined && dataContent[k][columns[j].property] === undefined) {
                                                dataContent[k][columns[j].property] = "";
                                            }
                                        }
                                        objectsCollection.push(dataContent[k]);
                                    }
                                    data.content = objectsCollection;
                                }
                            }
                        }
                    }
                }
                if (data.searchQueryId !== undefined) {
                    view.selections.queryId = data.searchQueryId;
                }
                fnCallback(data);

            },
            "error": function (data) {
                AjaxSpinner.hide();
                if (data.status == 200) {
                    window.location.href = '/login?sessionExpired=true';
                } else {
                    logger.warn('got response code ' + data.status + ' trying to execute url ' + datatableUrl);
                }
            },
            "beforeSend": function () {
                AjaxSpinner.show();
                // jQuery('.datatableProcessing').show();
                Chaplin.mediator.publish('beforeDataLoaded', tableSelector);
            },
            "complete": function () {
                if (viewModel.modelKey === "reporting") {
                    var dataTable = $("#datatable_widget-qualityReport").DataTable();
                    var data = dataTable.column(3).data();
                    var rows = dataTable.rows();
                    var emptyCells = true;
                    for (var rowCount = 0; rowCount < rows[0].length; rowCount++) {
                        if (data[rowCount] !== "") {
                            emptyCells = false;
                            break;
                        }
                    }
                    if (emptyCells) {
                        dataTable.column(3).visible(false);
                        $("#actionBtn-exportReport_next").css('display', 'none');
                        $("#actionBtn-exportReport_next2").css('display', 'none');
                    } else {
                        dataTable.column(3).visible(true);
                        $("#actionBtn-exportReport_next").css('display', 'inline-block');
                        $("#actionBtn-exportReport_next2").css('display', 'inline-block');
                    }
                }
                // jQuery('.datatableProcessing').hide();
                Chaplin.mediator.publish('afterDataLoaded', tableSelector);
                // (HGF) Did not found a better place where to call this after the complete page is loaded. Because a page
                // can consist of more than one list an addiotionally can have a form_view, AjaxSpinner.hide() can be
                // called multiple and not necessarily at the best time.
                AjaxSpinner.hide();
            },
            "headers": {
                'Authorization': "Bearer " + user.access_token
            }
        });
    },

    getColumnName: function (column, widgets) {
        var name = null;
        if (!MiscHelper.isEmpty(column['property'])) {
            name = column['property'];
        } else if (widgets != null && widgets.length > 0) {
            name = widgets[0]['property'];
        }
        return name;
    },

    createColumnDef: function (column, collections) {
        var widgets = null;
        var widgetsCollections = null;

        var modelKey = this.options.modelKey;
        var modelUrlRoot = this.model.urlRoot;
        var sortable = column.sortable;
        var thatOptions = this.options;
        var thatModel = this.model;

        if (!MiscHelper.isEmpty(column['widget'])) {
            widgets = column['widget'];
        }
        var name = this.getColumnName(column, widgets);

        if (widgets != null) {
            widgetsCollections = {};
            for (var i = 0; i < widgets.length; i++) {
                widgetsCollections[widgets[i]['property']] = FormHelper.getCollection(collections, widgets[i].collection);
            }
        }

        if (column['noLabel'] === true || (widgets != null && widgets[0]['noLabel'] === true)) {
            column['title'] = '';
        } else {
            var title = MsgHelper.getMessage("label.form." + modelKey + ".list.colHeader." + name);
            if (title === "label.form." + modelKey + ".list.colHeader." + name) {
                title = MsgHelper.getMessage("label.form." + modelKey + ".field." + name);
                if (title.indexOf("{year") !== -1) {
                    title = MsgHelper.replaceMessagePropertyYearPlaceHolder(title);
                }
            }
            column['title'] = title;
//            if (column.widget.length > 0 && column.widget[0].property === 'status' && this.model.get('modelKey') === 'reporting') {
//                column['title'] += ' (' + MiscHelper.annualInvoiceYear() + ')';
//            }
        }

        var styleClass = sortable ? "sorting" : "";
        if (!column['visible']) {
            // do not add a styleclass if the column is invisible
            styleClass = "";
        }

        if (column['styleClass']) {
            styleClass += " " + column['styleClass'];
        }

        var visible = true;
        if (column['showOn'] !== undefined && !MiscHelper.compareComplex(this.model.attributes, column.showOn)) {
            visible = false;
        } else {
            visible = column['visible'] && !column['extendedView'];
        }

        var columnDef = {
            "sClass": styleClass,
            //"width": "20%",
            "mDataProp": name,
            "name": name,
            "sTitle": column['title'] + '<span></span>',
            "bSortable": sortable,
            "orderable": sortable,
            "visible": visible,
            "defaultContent": ""
        };

        this.columns.push({"index": this.columns.length, "isExtendedView": column['extendedView']});

        var actions = column['action'];
        var buildActionButtons = this.buildActionButtons;

        columnDef["render"] = function (value, type, row, meta) {

            var widgetHtml = "";
            var info = thatOptions.table.page.info();
            var recordPosition = (info.page * info.length) + meta.row;

            // if this component is loaded asynchronous, load as spinner
            if (column['loadAsync']) {
                return "<p>test</p>";
            }

            if (widgets != null) {
                for (var i = 0; i < widgets.length; i++) {
                    if (widgets[i]['showOn'] !== undefined) {
                        if (!MiscHelper.compareComplex(row, widgets[i]['showOn'])) {
                            continue;
                        }
                    }
                    if (RenderHelper.hasRenderer(widgets[i]['property'])) {
                        widgetHtml += RenderHelper.doRender(widgets[i]['property'], row, value, {
                            widget: widgets[i],
                            modelKey: modelKey,
                            multiple: widgets.length > 1,
                            isFirst: i === 0,
                            columnDef: column,
                            recordPosition: recordPosition
                        });
                    }
                    else {
                        widgetHtml += RenderHelper.doRender('listWidget', row, value, {
                            widget: widgets[i],
                            collections: widgetsCollections,
                            modelKey: modelKey,
                            multiple: widgets.length > 1,
                            isFirst: i === 0,
                            columnDef: column,
                            recordPosition: recordPosition,
                            searchType: thatOptions.searchType
                        });
                    }
                }
            }

            // Put the contentype to the row. So that value can be checked by a show_on.
            if (thatModel === undefined) {
                console.log('thatModel is undefined');
            } else if (thatModel.attributes !== undefined) {
                row.contentType = thatModel.get('contentType');
                row.perspective = thatModel.get('perspective');
                row.perspective2 = thatModel.get('perspective2');
            }
            if (column.showOn !== undefined && !MiscHelper.compareComplex(row, column.showOn)) {

            } else {
                actionInfo = buildActionButtons(row.id, actions, modelKey, modelUrlRoot, row);
                if (actionInfo.actionsHtml) {
                    widgetHtml = widgetHtml + actionInfo.actionsHtml;
                    if (actionInfo.hasTertiary === true) {
                        widgetHtml = '<div class="actions-wrapper">' + widgetHtml + '</div>';
                    } else {
                        widgetHtml = '<div class="actions-wrapper no-settings-menu">' + widgetHtml + '</div>';
                    }
                }
            }
            return widgetHtml;
        };

        return columnDef;
    },

    buildActionButtons: function (rowId, actions, modelKey, modelUrlRoot, row) {
        var retVal = {};
        var actionsHtml = "";

        if (!MiscHelper.isEmpty(actions)) {
            var hasPrimary = false;
            var hasSecondary = false;
            var hasTertiary = false;
            for (var j = 0; j < actions.length; j++) {
                var action = actions[j];
                if (action.showOn !== undefined) {
                    if (!MiscHelper.compareComplex(row, action.showOn)) {
                        continue;
                    }
                }
                if (action['type'] === undefined || action['type'] === 'TERTIARY') {
                    hasTertiary = true;
                } else if (action['type'] === 'SECONDARY') {
                    hasSecondary = true;
                } else if (action['type'] === 'PRIMARY') {
                    hasPrimary = true;
                }
            }

            if (hasTertiary || hasPrimary) {
                actionsHtml += '<div class="settings-menu-wrap">';
                actionsHtml += '<a title="' + that.options.dealWithRowTitle + '" class="vlb-action-icon vlb-action-icon-input-settings-dd" href="javascript:;" '
                    + 'data-show-menu="true"><span>' + that.options.dealWithRowTitle + '</span></a>';
                actionsHtml += '<ul class="settings-menu" style="display: none;">';
                for (var j = 0; j < actions.length; j++) {
                    var action = actions[j];
//                console.log("2. ->" + action + action['id'] + " - " + action['showOn']);
                    if (action['type'] === undefined || action['type'] === 'TERTIARY' || action['type'] === 'PRIMARY') {
                        var action = actions[j];
                        var tmp = ActionHelper.buildActionButton(action, modelKey, modelUrlRoot, rowId, undefined, row.id, row);
                        if (!MiscHelper.isEmpty(tmp)) {
                            actionsHtml += '<li>' + tmp + '</li>';
                        }
                    }
                }

                actionsHtml += '  </ul>';
                actionsHtml += '</div>';
                //console.log('buildActionButtons done');
            }
            if (hasSecondary) {
                for (var j = 0; j < actions.length; j++) {
                    var renderAction = true;
                    var action = actions[j];
//                console.log('3. ->' + action);
                    if (action['type'] === 'SECONDARY') {
                        if (action['showOn'] !== undefined) {
                            if (!MiscHelper.compareComplex(row, action['showOn'])) {
                                renderAction = false;
                            } else {
                                renderAction = true;
                            }
                        }
                        if (renderAction) {
                            actionsHtml += ActionHelper.buildActionIcon(action, modelKey, modelUrlRoot, undefined, rowId);
                        }
                    }
                }
            }
            retVal.hasPrimary = hasPrimary;
            retVal.hasSecondary = hasSecondary;
            retVal.hasTertiary = hasTertiary;
            retVal.actionsHtml = actionsHtml;
        }

        return retVal;
    },


    afterDataLoaded: function (tableSelector) {
        if (this.options.tableSelector !== tableSelector) {
            return;
        }

        // Jump to the top of the page if a bottom pagination button is clicked.
        $('.paginationBottom .paginate_button').bind('click', function (event) {
            $('html, body').animate({
                scrollTop: $('#toTop').offset().top
            }, 250);
        });


        var msg = MsgHelper.getMessage('label.form.product.list.priceOnApplication');
        $('[title="PaA"]').attr('title', msg);

        // reset checkAll checkboxes.
        $('[data-uid=' + this.options.tableId + '-resultlist-check-all]').prop("checked", false);

        $('[data-active="false"]').parent().parent().addClass('deactivated');

        // init confirm actions
        this.delegate('click', '[data-toggle="confirmationSingle"]', this.confirmSingleAction);
        this.delegate('change', '[class="rowSelectCheckbox"]', this.selectionChange);
        this.delegate('change', '[data-uid="' + this.options.tableId + '-resultlist-check-all"]', this.selectionAllChange);

        this.showRowMenu();

        if (this.options.formDef.attributes['showNumberOfHits'] === true) {
            // add the number of matches to the title
            var numberOfMatches = MiscHelper.formatNumber(this.options.table.context[0]._iRecordsTotal);
            //var rgx = /(\d+)(\d{3})/;
            //while (rgx.test(numberOfMatches)) {
            //    numberOfMatches = numberOfMatches.replace(rgx, '$1' + '.' + '$2');
            //}
            var additional = MsgHelper.formatMessage('label.' + this.options.formDef.attributes.title + '.additional',
                [numberOfMatches]);
            $('#titleAdditionals').text(additional);
        }

        var dbInput = $('div[class*=databases_] input');
        if (dbInput.length > 0) {
            var dataAreaAuthorities = UserHelper.dataAreaAuthorities();
            dataAreaAuthorities = MiscHelper.getDbQueryStringForAggregatorIds(dataAreaAuthorities);
            for (var i = 0; i < dbInput.length; i++) {
                var id = dbInput[i].id.split('_')[1];
                if (_.contains(dataAreaAuthorities, id) || id === 'basic') {
                    $('div[class*=databases_' + id + '] input').removeClass('disabled');
                    $('div[class*=databases_' + id + '] input').prop('disabled', false);
                } else {
                    $('div[class*=databases_' + id + '] input').addClass('disabled');
                    $('div[class*=databases_' + id + '] input').prop('disabled', true);
                }
            }
        }

    },

    showRowMenu: function (productId) {
        var selector;
        if (productId !== undefined) {
            selector = 'tr[id="' + productId + '"]  .rowSelectCheckbox';
            if ($(selector).data('active')) {
                $(selector).parent().parent().removeClass('deactivated');
            } else {
                $(selector).parent().parent().addClass('deactivated');
            }

            if ($(selector).data('publisher') !== '') {
                $(selector).parent().find('.publisher').html($(selector).data('publisher'));
            }

            selector = 'tr[id="' + productId + '"]  [data-show-menu="true"]';
        } else {
            selector = '[data-show-menu="true"]';
        }
        $(selector).unbind("click");
        $(selector).click(function () {
            var _menu = $(this).parent().find('.settings-menu');
            $('.settings-menu').filter(function (a, b) {
                var retVal = _menu.get(0) != b;
                return retVal;
            }).hide();
            _menu.toggle();
            return false;
        });
    },


    beforeDataLoaded: function (tableSelector) {
        if (this.options.tableSelector !== tableSelector) {
            return;
        }
        // destroy confirm actions
        this.undelegate('click', '[data-toggle="confirmationSingle"]', this.confirmSingleAction);
        this.undelegate('change', '[class="rowSelectCheckbox"]', this.selectionChange);
        this.undelegate('change', '[data-uid="' + this.options.tableId + '-resultlist-check-all"]', this.selectionAllChange);
    },

    executedNewSearch: function (param) {
        sessionStorage.removeItem(this);
    },

    selectionAllChange: function (event) {
        var checked_status = event.currentTarget.checked;
        var selectionChangeDo = this.selectionChangeDo;
        var that = this;

        $('[data-uid=' + this.options.tableId + '-resultlist-check-all]').prop("checked", checked_status);

        $('#' + this.options.tableId + ' .rowSelectCheckbox').not("[disabled]").each(function () {
            this.checked = checked_status;
            selectionChangeDo(this, that);
        });
    },

    selectionChange: function (event) {
        var id = $(event.currentTarget).data('id');
        this.selectionChangeDo(event.currentTarget, this);
    },

    /**
     *
     * @param currentTarget
     * @param that use that instead of this!
     */
    selectionChangeDo: function (currentTarget, that) {
        var id = $(currentTarget).data('id');
        var o = null;
        var position = 0;
        for (var i = 0; i < that.selections.ids.length; i++) {
            if (that.selections.ids[i].id === id) {
                o = that.selections.ids[i];
                position = i;
            }
        }
        if (o === null && currentTarget.checked) {
            var o = that.copySelectedTitleData(currentTarget);
            that.selections.ids.push(o);
            //console.log('push->' + id);
        } else if (o !== null && !currentTarget.checked) {
            that.selections.ids.splice(position, 1);
            //console.log('remove->' + o.id);
        }
    }
    ,

    runSelectionAjax: function (action, url, selections) {
        jQuery.ajax({
            contentType: 'application/json',
            data: JSON.stringify(selections),
            dataType: 'json',
            url: url,
            type: 'POST',
            "success": function (data) {
                alertify.success(action['method'] + " was successfully");
            },
            "error": function (data) {
                if (data.status == 200) {
                    window.location.href = '/login?sessionExpired=true';
                } else {
                    logger.error(url + ": error", data);
                }
            },
            "beforeSend": function () {
                // jQuery('.datatableProcessing').show();
            },
            "complete": function () {
                // jQuery('.datatableProcessing').hide();
            },
            "headers": {
                'Authorization': "Bearer " + user.access_token
            }
        });
    },

    specialBehaviour: function (event) {
        if ('switchPerspective' === $(event.target).data('specialBehaviour') || 'switchPerspectiveBottom' === $(event.target).data('specialBehaviour')) {
            this.switchList(event.target.id, this.options.table);
        } else if ('switchPerspective2' === $(event.target).data('specialBehaviour') || 'switchPerspectiveBottom2' === $(event.target).data('specialBehaviour')) {
            this.switchList2(event.target.id, this.options.table);
        } else if ('orderingAttribute' === $(event.target).data('specialBehaviour')) {
            this.orderingAttribute(event);
        } else if ('sortOrder' === $(event.target).data('specialBehaviour')) {
            this.sortOrder(event);
        }
    },

    sortOrder: function (event) {
        var currentOrder = this.options.table.order();
        if (this.options.table.context[0].aoColumns[currentOrder[0][0]].name === 'score') {
            return;
        }
        var newDirection = currentOrder[0][1] === 'desc' ? 'asc' : 'desc';
        this.options.table.order(currentOrder[0][0], newDirection).draw();
        this.setSortOrderIcon(newDirection);
    },

    setSortOrderIcon: function (sortOrder) {
        var newDirection = sortOrder;
        var prevDirection = this.model.get('sortOrder');
        var selector = $('#block_switchPerspective .sortOrder .icon-link');
        selector.removeClass('sorting-' + prevDirection);
        selector.addClass('sorting-' + newDirection);
        var title = MsgHelper.getMessage('label.form.product.list.title.' + newDirection);
        selector.attr('title', title);
        this.model.set('sortOrder', sortOrder);
    },

    orderingAttribute: function (event) {

        // var prevOrder = this.options.table.order();
        var columnIndex = this.options.table.column(event.target.value + ':name').index();
        var formDefColumnIndex = columnIndex - 1;
        var currentOrder = this.options.table.order();
        var sortOrder;
        if (MiscHelper.isEmpty(this.options.formDef.attributes['column'][formDefColumnIndex].defaultSort)) {
            sortOrder = 'desc';
        } else {
            sortOrder = this.options.formDef.attributes['column'][formDefColumnIndex].defaultSort.toLowerCase();
        }
        if (columnIndex === parseInt(columnIndex, 10)) {
            if (this.options.table.context[0].aoColumns[currentOrder[0][0]].name === 'score') {
                this.options.table.order(columnIndex, sortOrder).draw();
            } else {
                if (MiscHelper.isEmpty(currentOrder[0][1])) {
                    this.options.table.order(columnIndex, sortOrder).draw();
                } else {
                    this.options.table.order(columnIndex, sortOrder).draw();
                }
            }
        }
        // var selector = $('#block_switchPerspective .sortOrder .icon-link');
        if (event.target.value === 'score') {
            this.setSortOrderIcon('desc');
        } else {
            this.setSortOrderIcon(sortOrder);
        }
    },

    switchList: function (listIdent, theTable) {
        var selector;
        var selectorBottom;

        if (this.options.formDef.get('collections') === undefined) {
            return;
        }

        this.model.set('perspective', listIdent);
        var arrCollections = this.options.formDef.get('collections').collection;
        var collection;
        for (var i = 0; i < arrCollections.length; i++) {
            if (arrCollections[i].id === 'perspective') {
                collection = arrCollections[i];
                break;
            }
        }
        if (collection === undefined) {
            return;
        }

        for (i = 0; i < collection.value.length; i++) {
            selector = $('#block_switchPerspective ' + '#' + collection.value[i].id);
            selectorBottom = $('#block_switchPerspectiveBottom ' + '#' + collection.value[i].id);
            if (collection.value[i].id === listIdent) {
                selector.addClass('active');
                selectorBottom.addClass('active');
            } else {
                selector.removeClass('active');
                selectorBottom.removeClass('active');
            }
        }

        if (theTable !== undefined) {
            // redraw the cell for the actions
            // that redraw resets the current page number to 0. So I keep the current start record to use it in the
            // callback 'fnServerParams' to set the realy required display start. After the redraw the kept start
            // must be thrown away again.
            var oSettings = theTable.settings();
            this.options.saveTheDsisplayStart = oSettings[0]._iDisplayStart;
            theTable.cell('.product-actions').invalidate().draw();
            this.options.saveTheDsisplayStart = undefined;
            var arrColumns = this.options.formDef.get('column');
            var iColumn = 0;
            if (this.enableRowSelection) {
                iColumn++;
            }
            for (i = 0; i < arrColumns.length; i++) {
                var column = arrColumns[i];
                if (column['showOn'] !== undefined) {
                    var show = MiscHelper.compareComplex(this.model.attributes, column['showOn']);
                    theTable.column(iColumn).visible(show);
                }
                iColumn++;
            }
        }

        selector = $('#' + this.id + ' .data-table');
        var selectorOrder = $('#block_switchPerspective .orderingAttribute');

        this.setListPerspective(selector, listIdent);

        // Set title to all cells which have dotToLongText set as class.
        $('.dotToLongText').each(function () {
            $(this).attr('title', $(this).text());
        });
    },

    switchList2: function (listIdent, theTable) {
        var selector;
        var selectorBottom;

        if (this.options.formDef.get('collections') === undefined) {
            return;
        }

        this.model.set('perspective2', listIdent);
        var arrCollections = this.options.formDef.get('collections').collection;
        var collection;
        for (var i = 0; i < arrCollections.length; i++) {
            if (arrCollections[i].id === 'perspective2') {
                collection = arrCollections[i];
                break;
            }
        }
        if (collection === undefined) {
            return;
        }

        for (i = 0; i < collection.value.length; i++) {
            selector = $('#block_switchPerspective2 ' + '#' + collection.value[i].id);
            selectorBottom = $('#block_switchPerspectiveBottom2 ' + '#' + collection.value[i].id);
            if (collection.value[i].id === listIdent) {
                selector.addClass('active');
                selectorBottom.addClass('active');
            } else {
                selector.removeClass('active');
                selectorBottom.removeClass('active');
            }
        }

        if (theTable !== undefined) {
            // that redraw resets the current page number to 0. So I keep the current start record to use it in the
            // callback 'fnServerParams' to set the realy required display start. After the redraw the kept start
            // must be thrown away again.
            var oSettings = theTable.settings();
            this.options.saveTheDsisplayStart = oSettings[0]._iDisplayStart;
            theTable.cell('.product-actions').invalidate().draw();
            this.options.saveTheDsisplayStart = undefined;
            var arrColumns = this.options.formDef.get('column');
            var iColumn = 0;
            if (this.enableRowSelection) {
                iColumn++;
            }
            for (i = 0; i < arrColumns.length; i++) {
                var column = arrColumns[i];
                if (column['showOn'] !== undefined) {
                    var show = MiscHelper.compareComplex(this.model.attributes, column['showOn']);
                    theTable.column(iColumn).visible(show);
                }
                iColumn++;
            }
        }

        selector = $('#' + this.id + ' .data-table');

        this.setListPerspective(selector, listIdent);

        // Set title to all cells which have dotToLongText set as class.
        $('.dotToLongText').each(function () {
            $(this).attr('title', $(this).text());
        });
    },

    selectionAction: function (event) {
        var action = ActionHelper.findActionById(this.options.formDef, event.currentTarget.attributes["action"].value);
        var url = FormHelper.buildApiUrl(action['targetUrl'] + "/" + action['method']);
        this.runSelectionAjax(action, url, this.selections);
    },

    extendFilter: function (event) {
        if ($('#collapser').hasClass('collapsed')) {
            $('#collapser').removeClass('collapsed');
            $('#extendedFilter').show();
        } else {
            $('#collapser').addClass('collapsed');
            $('#extendedFilter').hide();
        }

        return false;
    },

    confirmMultipleAction: function (event) {
        var action = ActionHelper.findActionById(this.options.formDef, event.currentTarget.attributes["action"].value);

        if (action['method'] === 'confirm' && this.selections !== undefined && this.selections.ids.length > 5000) {
            var scopeData = {};
            scopeData.text = MsgHelper.getMessage('label.form.confirmationSimple.toMuchTitle.text.' + action['method']);
            scopeData.ident = action['method'];
            scopeData.whichButton = 'ok';
            var html = ConfirmationAcknowledge(scopeData);
            var confirmDialog = function (event) {
                $.fancybox.close();
                var div = $('#' + scopeData.ident + 'Confirmation');
                div.fadeOut('slow');
                div.remove();
            };
            ConfirmationHelper.openDialog(html, scopeData.ident, confirmDialog);
        } else if ((action.confirmation === 'confirmationDownload' || action.confirmation === 'confirmationQuickExport' || action.confirmation === 'confirmationMigrate')
            && ((this.selections.ids.length === 0 && this.options.table.context[0]._iRecordsTotal > 25000) || this.selections.ids.length > 25000)) {
            // TODO: migrate should also have a max of 25000
            var scopeData = {};
            scopeData.text = MsgHelper.getMessage('label.form.confirmationAcknowledge.toMuchTitle.text.' + action['method']);
            scopeData.ident = action['method'];
            scopeData.whichButton = 'ok';
            var html = ConfirmationAcknowledge(scopeData);
            var confirmDialog = function (event) {
                $.fancybox.close();
                var div = $('#' + scopeData.ident + 'Confirmation');
                div.fadeOut('slow');
                div.remove();
            };
            ConfirmationHelper.openDialog(html, scopeData.ident, confirmDialog);

        } else if (action['method'] === 'confirm' || action['method'] === 'migrate') {
            var self = this;
            var successCallback = function (data) {
                var checkBox = $(self.options.tableSelector + ' tr .rowSelectCheckbox:checked').each(function () {
                    this.checked = false;
                });
                $('[data-uid=' + self.options.tableId + '-resultlist-check-all]').prop("checked", false);
                self.selections.ids = [];
            }
            ConfirmationHelper.confirmMultipleActionId(event, this.options, this.selections.ids, successCallback);
        } else if (action.confirmation === 'confirmationRadio'
            || action.confirmation === 'confirmationSimple'
            || action.confirmation === 'confirmationDownload'
            || action.confirmation === 'confirmationSimpleDownload'
            || action.confirmation === 'confirmationOrderbookOrder'
            || action.confirmation === 'confirmationAcknowledge'
            || action.confirmation === 'confirmationMigrate') {

            if (this.selections.ids.length > 0) {
                var self = this;
                var successCallback = function (data) {
                    // reset checkAll checkboxes.
                    $('[data-uid=' + self.options.tableId + '-resultlist-check-all]').prop("checked", false);
                    if (action.confirmation === 'confirmationDownload'
                        || action.confirmation === 'confirmationSimpleDownload') {

                        var checkBox = $(self.options.tableSelector + ' tr .rowSelectCheckbox:checked').each(function () {
                            this.checked = false;
                        });
                        self.selections.ids = [];
                    } else if (action['id'] === 'deleteTitleList') {
                        var checkBox = $(self.options.tableSelector + ' tr .rowSelectCheckbox:checked').each(function () {
                            this.checked = false;
                        });
                        self.selections.ids = [];
                        self.options.table.draw();
                    } else {
                        var idSuccess = data.productId || data.id;
                        if (idSuccess === undefined && action['method'] === 'migrate') {
                            idSuccess = data;
                        }
                        var row = $('tr[id=' + idSuccess + ']');
                        var temp = self.options.table.row(row);
                        if (temp.length > 0) {
                            temp.data(data.product);
                        }
                        self.showRowMenu(idSuccess);
                        var checkBox = $('tr[id=' + idSuccess + '] .rowSelectCheckbox');
                        if (checkBox !== undefined) {
                            checkBox.attr('checked', false);
                        }
                        for (var i = 0; i < self.selections.ids.length; i++) {
                            if (self.selections.ids[i].id === idSuccess) {
                                //console.log('splice->' + that.selections.ids[i].id);
                                self.selections.ids.splice(i, 1);
                                break;
                            }
                        }
                        if (action.method == 'ibuexport' || action.id == 'deleteOrderItem') {
                            self.selections.ids = [];
                        }
                    }
                };

                ConfirmationHelper.confirmMultipleActionId(event, this.options, this.selections.ids, successCallback);
            } else if (action.confirmation === 'confirmationDownload') {
                var thatOptions = this.options;
                var successCallback = function (data) {
                    // reset checkAll checkboxes.
                    $('[data-uid=' + thatOptions.tableId + '-resultlist-check-all]').prop("checked", false);
                };
                ConfirmationHelper.confirmMultipleActionId(event, this.options, this.selections.ids, successCallback);
            } else if (action.confirmation === 'confirmationMigrate') {
                var thatOptions = this.options;
                var tableRecords = this.options.table.rows({search: '*'}).data();
                var successCallback = function (data) {
                    // reset checkAll checkboxes.
                    $('[data-uid=' + thatOptions.tableId + '-resultlist-check-all]').prop("checked", false);
                };
                ConfirmationHelper.confirmMultipleActionId(event, this.options, this.selections.ids, successCallback);
            } else {
                ConfirmationHelper.confirmMultipleActionId(event, this.options, this.selections.ids, undefined);
            }

            var _menu = $('.settings-menu');
            _menu.hide();
        } else {
            var url = FormHelper.buildApiUrl(action['targetUrl'] + "/" + action['method']);
            var confirmMsg = MsgHelper.getMessage("msg.confirm.dialog");
            if (action.confirmation === 'filterConfirm') {
                globalCache.clear();
                AjaxSpinner.show();
                this.options.table.draw();
            } else if (action.confirmation === 'clearFilterConfirm') {
                globalCache.clear();
                $('*[id*=filter-]').each(function () {
                    $(this).val('');
                    $(this).select2("val", "");
                });
            } else if (action.confirmation === 'showExtendedView') {
                this.isExtendedView = !this.isExtendedView;

                for (var i = 0; i < this.columns.length; i++) {
                    if (this.columns[i].isExtendedView == true) {
                        this.options.table.columns(this.columns[i].index).visible(this.isExtendedView);
                    } else {
                        this.options.table.columns(this.columns[i].index).visible(true);
                    }
                }
            } else if (action.confirmation === 'directAccountUserExport') {
                var ids = [];

                // TODO: send filters with request
                for (var id in this.selections.ids) {
                    if (this.selections.ids[id].id !== null) {
                        ids.push(this.selections.ids[id].id);
                    }
                }

                var filters = globalCache.get(this.options.tableSelector + "_" + this.id);
                filters.ids = ids;
                var sendData = JSON.stringify(filters);
                var sendDataType = 'json';
                var localURL = applicationRoot + '/account/export' + '?access_token=' + user.access_token;
                ConfirmationHelper.doTheConfirmation(filters, sendDataType, localURL, 'GET', action, undefined);
            } else if (action.confirmation === 'directFilterExport') {
                var sendData = globalCache.get(this.options.tableSelector + "_" + this.id);
                var sendDataType = 'json';
                var localURL = apiRoot + 'assetLogentry/export' + '?access_token=' + user.access_token;
                ConfirmationHelper.doTheConfirmation(sendData, sendDataType, localURL, 'POST', action, undefined, undefined, 'application/x-www-form-urlencoded');
            } else if (action.confirmation === 'confirmationQuickExport') {
                var that = this;
                var successCallback = function (data) {
                    // reset checkAll checkboxes.
                    $('[data-uid=' + that.options.tableId + '-resultlist-check-all]').prop("checked", false);

                    var checkBox = $(that.options.tableSelector + ' tr .rowSelectCheckbox:checked').each(function () {
                        this.checked = false;
                    });
                    that.selections.ids = [];
                }

                ConfirmationHelper.confirmMultipleActionId(event, this.options, this.selections.ids, successCallback);
            } else if (action.confirmation === 'switchActiveLogEntryState') {
                var ids = [];

                for (var id in this.selections.ids) {
                    if (this.selections.ids[id].id !== undefined) {
                        ids.push(this.selections.ids[id].id);
                    }
                }

                var table = this.options.table;
                var that = this;

                var filetype = this.model.attributes.modelKey == "logentry" ? "metadata" : "asset";

                $.ajax({
                    contentType: 'application/json',
                    dataType: 'json',
                    url: apiRoot + "logentry/switchArchiveState/" + filetype + "?access_token=" + user.access_token,
                    data: JSON.stringify(ids),
                    type: 'POST',
                    "success": function (data) {
                        $(that.options.tableSelector + ' tr .rowSelectCheckbox:checked').each(function () {
                            this.checked = false;
                        });
                        that.selections.ids = [];
                        globalCache.clear();
                        table.draw();
                    }
                });
            } else if (action.method === 'saveAsFavourite') {
                var o = {};
                o.id = this.selections.queryId;
                ConfirmationHelper.confirmSingleAction(event, this.options, null, o);
            } else if (action.confirmation === 'exportReport') {
                var localURL = apiRoot + 'reporting/' + action.method + '' + '?access_token=' + user.access_token;
                ConfirmationHelper.doTheConfirmation('', 'json', localURL, 'POST', action, undefined);
            }
        }
    },

    confirmSingleAction: function (event) {
        var self = this;
        var action = ActionHelper.findActionById(this.options.formDef, event.currentTarget.attributes["action"].value);
        var id = event.currentTarget.attributes["data-id"].value;
        var currentTarget = $('#' + id + ' .selectionColumn input');

        if (action['method'] === 'copy' || action['method'] === 'copyAsEbook' || action['method'] === 'history') {
            var url = apiRoot + 'products/possibleProductActions?access_token=' + user.access_token + '&productIds=' + id;
            jQuery.ajax({
                contentType: 'application/json',
                url: url,
                type: 'GET',
                "success": function (data) {
                    if (data[0].publisherForMvbId === true) {
                        Chaplin.mediator.publish('redirectTo', {
                            url: self.options.modelKey + '_' + action['method'].toLowerCase(),
                            data: {
                                id: id
                            }
                        });
                    } else {
                        var scopeData = {};
                        scopeData.text = MsgHelper.getMessage('label.form.confirmationAcknowledge.notAllowed.text.' + action['method']);
                        scopeData.ident = action['method'];
                        scopeData.whichButton = 'ok';
                        var html = ConfirmationAcknowledge(scopeData);
                        var confirmDialog = function (event) {
                            $.fancybox.close();
                            var div = $('#' + scopeData.ident + 'Confirmation');
                            div.fadeOut('slow');
                            div.remove();
                        };
                        ConfirmationHelper.openDialog(html, scopeData.ident, confirmDialog);
                    }
                }
            });

        } else if (action['method'] === 'seriesView') {
            var seriesIds = $(currentTarget).data('seriesid');
            var arrSeriesIds = seriesIds.split(',');
            if (arrSeriesIds.length === 1) {
                Chaplin.mediator.publish('redirectTo', {
                    url: 'collection_list',
                    data: {
                        id: undefined,
                        rh: seriesIds,
                        search: 'RH=' + seriesIds,
                        mode: 'bool',
                        sortCol: 'hierarchySort',
                        contentType: 'series',
                        collectionId: seriesIds
                    }
                });
            } else {
                Chaplin.mediator.publish('redirectTo', {
                    url: 'product_show',
                    data: {
                        id: id
                    }
                });
            }
        } else if (action['method'] === 'hierarchieView') {
            var setId = $(currentTarget).data('setid');
            Chaplin.mediator.publish('redirectTo', {
                url: 'collection_list',
                data: {
                    id: undefined,
                    rh: setId,
                    search: 'RH=' + setId,
                    mode: 'bool',
                    sortCol: 'hierarchySort',
                    contentType: 'set',
                    collectionId: setId
                }
            });
        } else if (action['method'] === 'archiveLogEntry' || action['method'] === 'activateLogEntry') {
            var archive = action['method'] === 'archiveLogEntry' ? true : false;
            var target = action['targetUrl'];
            target = target.replace('$api/', apiRoot) + id + "/" + archive;

            var table = this.options.table;

            $.ajax({
                contentType: 'application/json',
                dataType: 'json',
                url: target + "?access_token=" + user.access_token,
                type: 'GET',
                "success": function (data) {
                    globalCache.clear();
                    table.draw();
                }
            });
        } else {
            var successCallback = function (data) {
                if (data !== undefined && data.id !== undefined && action.method !== 'delete') {
                    var idSuccess = data.id;
                    var row = $('tr[id=' + idSuccess + ']');
                    if (row.length > 0) {
                        var temp = self.options.table.row(row);
                        if (data.product === undefined) {
                            temp.data(data);
                        } else {
                            temp.data(data.product);
                        }
                        self.showRowMenu(idSuccess);
                    }
                } else {
                    if (action.method === 'saveAsFavourite') {
                        self.options.table.draw();
                        if (self.options.controller !== undefined) {
                            if (self.id === 'searchhistory_list') {
                                widgets = self.options.controller.model.get('widgets');
                                for (var i = 0; i < widgets.length; i++) {
                                    if (widgets[i].id === 'searchhistorySaved') {
                                        widgets[i].view.options.table.draw();
                                    }
                                }
                            } else if (self.id === 'searchhistorySaved_list') {
                                widgets = self.options.controller.model.get('widgets');
                                for (var i = 0; i < widgets.length; i++) {
                                    if (widgets[i].id === 'searchhistory') {
                                        widgets[i].view.options.table.draw();
                                    }
                                }
                            }
                        }
                    } else if (action.method === 'removeFromFavourite') {
                        self.options.table.draw();
                        if (self.id === 'searchhistorySaved_list' && self.options.controller !== undefined) {
                            widgets = self.options.controller.model.get('widgets');
                            for (var i = 0; i < widgets.length; i++) {
                                if (widgets[i].id === 'searchhistory') {
                                    widgets[i].view.options.table.draw();
                                }
                            }
                        }
                    }
                }
            };
            var o;
            if (action['id'] === 'editOrderItem' || action['id'] === 'deleteOrderItem') {
                o = {};
                o.id = id;
            } else {
                var o = this.copySelectedTitleData(currentTarget);
            }
            if (o.id === undefined) {
                // TODO deleting an asset bases on different data structures then actions on products has. So just ensure
                // TODO that there is the currently needed ID.
                // TODO Please fix that. Different actions must have the same structure or we have to use an other
                // TODO target where the information come from like the event.target.
                o.id = id;
            }
            // TODO find a way to transport any information independent from input selection column which is only used
            // TODO in the product list. (HGF)
            if (action.method === 'saveAsFavourite') {
                var tdElem = $('tr#' + id + ' td')[1];
                var name = tdElem.innerHTML;
                o.name = name;
            }
            o.aggregatorId = $(currentTarget).data('aggregatorid');
            o.productVLB = $(currentTarget).data('productvlb');
            if (o.productVLB == undefined && this.options.productId != undefined) {
                o.productVLB = this.options.productId;
            }

            ConfirmationHelper.confirmSingleAction(event, this.options, successCallback, o);
        }
    },

    copySelectedTitleData: function (currentTarget) {
        var o = {};

        o.id = $(currentTarget).data('id');
        o.active = $(currentTarget).data('active');
        o.isbn = $(currentTarget).data('isbn');
        o.type = $(currentTarget).data('type');
        o.ident = $(currentTarget).data('ident');
        o.aggregatorId = $(currentTarget).data('aggregatorid');
        o.productVLB = $(currentTarget).data('productvlb');
        o.lastModifiedDate = $(currentTarget).data('lastmodifieddate');
        o.productId = $(currentTarget).data('productid');
        o.publisherForMvbId = $(currentTarget).data('publisherformvbid');
        o.publisher = $(currentTarget).data('publisher');
        o.setId = $(currentTarget).data('setid');
        o.seriesId = $(currentTarget).data('seriesid');
        // TODO eliminate 'publisherForMvbId'
        o.publisherMvbId = $(currentTarget).data('publishermvbid');
        o.publisherId = $(currentTarget).data('publishermvbid');
        // o.announcementDate = $(currentTarget).data('announcementdate');

        return o;
    },

    eventId: function (event) {
        var eventId = event.currentTarget.parentNode.parentNode.parentNode.id;
        logger.debug("called action for id ", eventId);
        return eventId;
    },

    executeFilter: function (event) {
        if (event.keyCode === 13 && $('[id^="filter-"]').size() > 0) {
            globalCache.clear();
            AjaxSpinner.show();
            this.options.table.draw();
        }
    },

    historyBack: function (event) {
        window.history.back();
    },

    setListPerspective: function (selector, listData) {
        if (listData === 'detailedPerspective') {
            selector.removeClass('perspective-list');
            selector.addClass('perspective-detailed');
            //selectorOrder.removeClass('hidden');
        } else if (listData === 'listPerspective') {
            selector.removeClass('perspective-detailed');
            selector.addClass('perspective-list');
            //selectorOrder.addClass('hidden');
        }

        var list = {}
        list.perspective = listData;
        list.perspective2 = listData;
        sessionStorage.setItem('listPerspective', JSON.stringify(list));
    },

    tabContentRefresh: function (params) {
        if (this.region === params) {
            // refresh the table at tab change
            this.options.table.draw();
        }
    }
})
;


