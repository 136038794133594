var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<h3>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.title : stack1), {"name":"lbl","hash":{},"data":data})))
    + "</h3>";
},"3":function(depth0,helpers,partials,data) {
  return "    <div id=\"modalBox\"> </div>\n";
  },"5":function(depth0,helpers,partials,data) {
  var stack1, buffer = "                <form class=\"form-horizontal\">\n                    <div class=\"default-form\">\n";
  stack1 = helpers.each.call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.block : stack1), {"name":"each","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                    </div>\n                </form>\n";
},"6":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "                            <div id=\"block_"
    + escapeExpression(((helper = (helper = helpers.blockId || (depth0 != null ? depth0.blockId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"blockId","hash":{},"data":data}) : helper)))
    + "\" class=\"block block_"
    + escapeExpression(lambda((data && data.index), depth0))
    + " row form-group\">\n                            </div>\n";
},"8":function(depth0,helpers,partials,data) {
  var stack1, buffer = "                <div class=\"default-form\">\n";
  stack1 = helpers.each.call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.block : stack1), {"name":"each","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                </div>\n";
},"9":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "                        <div id=\"block_"
    + escapeExpression(((helper = (helper = helpers.blockId || (depth0 != null ? depth0.blockId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"blockId","hash":{},"data":data}) : helper)))
    + "\" class=\"block block_"
    + escapeExpression(lambda((data && data.index), depth0))
    + " row form-group\">\n                        </div>\n";
},"11":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "              <div class=\"row\">\n                  <div class=\"col-xs-8\">\n                     <ul class=\"nav action-nav\">\n";
  stack1 = helpers.each.call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1)) != null ? stack1.action : stack1), {"name":"each","hash":{},"fn":this.program(12, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                     </ul>\n                  </div>\n                  <div class=\"col-xs-4\">\n                  </div>\n               </div>\n";
},"12":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.equals || (depth0 && depth0.equals) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "PRIMARY", {"name":"equals","hash":{},"fn":this.program(13, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"13":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                              <li>\n                              "
    + escapeExpression(((helpers.action || (depth0 && depth0.action) || helperMissing).call(depth0, depth0, (depths[2] != null ? depths[2].modelKey : depths[2]), (depths[2] != null ? depths[2].modelUrlRoot : depths[2]), ((stack1 = (depths[2] != null ? depths[2].model : depths[2])) != null ? stack1.id : stack1), {"name":"action","hash":{},"data":data})))
    + "\n                              </li>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.showHeader : depth0), {"name":"if","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.modal : stack1), {"name":"if","hash":{},"fn":this.program(3, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n    <div class=\"mod-titleadministration\">\n\n        <div class=\"ui-tabs-panel ui-widget-content ui-corner-bottom\">\n\n";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.id : stack1), "product/subForm2", {"name":"compare","hash":{
    'operator': ("===")
  },"fn":this.program(5, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.id : stack1), "product/subForm1", {"name":"compare","hash":{
    'operator': ("===")
  },"fn":this.program(8, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.hasPrimaryAction : stack1), {"name":"if","hash":{},"fn":this.program(11, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n        </div>\n    </div>\n    ";
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}