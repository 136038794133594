var MiscHelper = require('lib/misc_helper');
var BaseWidget = require('./composite');
var FormHelper = require('lib/form_helper');
var MsgHelper = require('lib/msg_helper');
var Model = require('models/nested_model');
var PriceHelper = require('lib/price_helper');
var DateHelper = require('lib/date_helper');
// composite base widgets for working on list of objects

var CompositeWidget = BaseWidget.extend({
    initialize: function (widget, modelKey, parentProperty) {
        BaseWidget.prototype.initialize.call(this, widget, modelKey, parentProperty);
        if (!this.addDesc) {
            this.addDesc = "form." + modelKey + ".fieldset." + this['property'] + ".add";
        }
        if (!this.showAllDesc) {
            this.showAllDesc = "form." + modelKey + ".fieldset." + this['property'] + ".showAll";
        }
        if (!this.hideSomeDesc) {
            this.hideSomeDesc = "form." + modelKey + ".fieldset." + this['property'] + ".hideSome";
        }
    },

    binding: function (model, parentProperty, parentIndex) {

        if (!this.doRender) {
            return [];
        }

        var listIndexArray = this.getListIndexArray(model, parentProperty, parentIndex);
        var bindings = [];
        var foundContent = false;
        for (var i = 0; i < listIndexArray.length; i++) {
            for (var j = 0; j < this.column.length; j++) {
                for (var k = 0; k < this.column[j].widget.length; k++) {
                    var w = this.column[j].widget[k];
                    if (w.type !== 'LabelWidget') {
                        if (w.type === 'ListCompositeWidget') {
                            var obj = model.get(this['property'])[listIndexArray[i]];
                            var tempModel = new Model();
                            tempModel.set(w.property, obj[w.property]);
                            Array.prototype.push.apply(bindings, w.binding(tempModel, this.property, listIndexArray[i]));
                        } else if (w.type === 'TableCompositeWidget') {
                            var obj = model.get(this['property'])[listIndexArray[i]];
                            var tempModel = new Model();
                            tempModel.set(w.property, obj[w.property]);
                            Array.prototype.push.apply(bindings, w.binding(model, this.property, listIndexArray[i]));
                        } else {
                            var id = this.buildId(w, listIndexArray[i], parentProperty, parentIndex);
                            var name = this.buildName(w, listIndexArray[i], parentProperty, parentIndex);
                            var value = model.get(name);
                            if (!MiscHelper.isEmpty(value) && w.type !== 'HiddenWidget') {
                                foundContent = true;
                            }
                            if (w.type === 'RadioWidget' && !w.readonly) {
                                bindings.push({name: name, bindingSelector: '[name=' + id + ']'});
                            } else if (w.type === 'NumberWidget') {
                                bindings.push({
                                    name: name,
                                    bindingSelector: {selector: '#' + id, converter: w.buildConverter()}
                                });
                            } else if (w.type === 'DateWidget') {
                                Array.prototype.push.apply(bindings, w.binding(model, '#' + id, name));
                            } else if (w.specialBehaviour === "displayAsStar") {
                                bindings.push({
                                    name: name,
                                    bindingSelector: {selector: '#' + id, converter: this.starConverter}
                                });
                            } else if (w.specialBehaviour === "zeroPercentConverter") {
                                bindings.push({
                                    name: name,
                                    bindingSelector: {selector: '#' + id, converter: this.zeroPercentConverter}
                                });
                                // zeroPercentConverter
                            }
                            else {
                                bindings.push({name: name, bindingSelector: '#' + id});
                            }
                        }
                    }
                }
            }
        }
        if (!foundContent && !this.isEditable) {
            bindings = [];
        }
        return bindings;
    },

    starConverter: function (direction, value, attr) {
        if (MiscHelper.isEmpty(value)) {
            return "";
        }

        if (direction === 'ModelToView') {
            if (value) {
                return "*";
            }
        }
        return value;
    },

    zeroPercentConverter: function (direction, value, attr) {
        if (MiscHelper.isEmpty(value)) {
            return "";
        }

        if (direction === 'ModelToView') {
            if (!MiscHelper.isEmpty(value) && value.indexOf('0% MwSt.-Angabe vom Verlag') > -1) {
                return "+";
            }
        }
        return '';
    },

    addNew: function (model, parentProperty, parentIndex) {
        var id = '';
        if (parentProperty !== undefined) {
            id += parentProperty + "." + parentIndex + ".";
        }
        id += this['property'];
        if (model.get(id) === undefined) {
            model.set(id, []);
        }
        var newComposite;
        if (this['specialBehaviour'] === 'multiBundles') {
            id = id + '.' + parentIndex + '.bundleParts';
            newComposite = this.column[1].widget[0].prefill();

            $('#block_priceMultiBundle').addClass('has-removable-elements');

        } else {
            newComposite = this.prefill(model);
        }
        if (this['specialBehaviour'] === 'contributor') {
            var sequence = 0;
            var contributors = model.get('contributors');
            if (contributors !== undefined && contributors !== null) {
                for (var i = 0; i < contributors.length; i++) {
                    if (contributors[i] !== null && contributors[i].sequence !== undefined && contributors[i].sequence > sequence) {
                        sequence = contributors[i].sequence;
                    }
                }
            }
            sequence++;
            newComposite.sequence = sequence;
        }
        if (this['specialBehaviour'] === 'texts') {
            var sequence = 0;
            var texts = model.get('texts');
            if (texts !== undefined && texts !== null) {
                for (var i = 0; i < texts.length; i++) {
                    if (texts[i] !== null && texts[i].sequence !== undefined && texts[i].sequence > sequence) {
                        sequence = texts[i].sequence;
                    }
                }
            }
            sequence++;
            newComposite.sequence = sequence;
        }
        if (this['specialBehaviour'] === 'pricesJournal') {
            if (MiscHelper.isEmpty(newComposite.currencyCountry)) {
                var options;
                options = $.extend(true, {}, this.widget.column[0].widget[0].options);
                for (var i = 0; i < model.get('pricesJournal').length; i++) {
                    var currencyCountry = model.get('pricesJournal')[i].currencyCountry;
                    for (var op in options) {
                        if (options[op] !== undefined && options[op].id === currencyCountry) {
                            options[op] = undefined;
                            break;
                        }
                    }
                }
                for (var op in options) {
                    if (options[op] !== undefined) {
                        newComposite.currencyCountry = options[op].id;
                        var splits = newComposite.currencyCountry.split('_');
                        newComposite.currency = splits[0];
                        newComposite.country = splits[1];
                        break;
                    }
                }
            }
        } else if (this.id === 'duoBundles') {
            newComposite.country = 'AT';
            newComposite.currency = 'EUR';
            newComposite.currencyCountry = 'EUR_AT';
            var deBundle = model.get('duoBundles')[0];
            for (var i = 0; i < 2; i++) {
                var newBundlePart = newComposite.bundleParts[i];
                var deBundlePart = deBundle.bundleParts[i];
                newBundlePart.containedItemID = deBundlePart.containedItemID;
                newBundlePart.gtin = deBundlePart.gtin;
                newBundlePart.productIdentifierType = deBundlePart.productIdentifierType;
                newBundlePart.taxRate = deBundlePart.taxRate;
                newBundlePart.type = deBundlePart.type;
            }
        }
        if (this['specialBehaviour'] === 'multiBundles') {
            var multiBundles = model.get('multiBundles');
            for (var i = 0; i < multiBundles.length; i++) {
                multiBundles[i].bundleParts.push(newComposite);
            }
        } else {
            model.get(id).push(newComposite);
        }
    },

    prefill: function (model) {
        var tempModel = new Model();
        if (this.simple === true) {
            return "";
        }
        for (var j = 0; j < this.column.length; j++) {
            for (var k = 0; k < this.column[j].widget.length; k++) {
                var w = this.column[j].widget[k];
                if (w.type !== 'LabelWidget') {
                    w.changeDefaultValue(model);
                    w.initModelValue(tempModel, this.property);
                }
            }
        }

        if (this['specialBehaviour'] === "priceMerge") {
            tempModel.get(this['property']).priceTypeGroup = Math.random().toString(36);
        } else if (this['specialBehaviour'] === 'pricesJournal') {
            var journalCurrency;
            if (model.get('bundleswitch') === 'identicTax') {
                journalCurrency = ['EUR_DE', 'EUR_AT', 'CHF_CH', 'USD_US', 'GBP_GB'];
            } else {
                journalCurrency = ['EUR_AT', 'CHF_CH', 'USD_US', 'GBP_GB'];
            }
            for (var i = 0; i < model.get('pricesJournal').length; i++) {
                var priceHead = model.get('pricesJournal')[i];
                var index = journalCurrency.indexOf(priceHead.currencyCountry);
                if (i > -1) {
                    journalCurrency.splice(index, 1);
                }
            }
            tempModel.get('pricesJournal').currencyCountry = journalCurrency[0];
            for (var i = 0; i < tempModel.get('pricesJournal').priceParts.length; i++) {
                var pricePart = tempModel.get('pricesJournal').priceParts[i];
                pricePart.uvp = false;
                if (i === 0) {
                    pricePart.priceTypeCode = '14';
                    pricePart.unitPrice = false;
                } else {
                    pricePart.priceTypeCode = '04';
                    pricePart.unitPrice = true;
                }
            }
        }

        return tempModel.get(this['property']);
    },

    initModelValue: function (aModel, parentProperty, parentIndex) {

        var model = aModel;
        if (parentProperty !== undefined && parentIndex === undefined) {
            model = new Model();
        }

        if (model.get(this['property']) === undefined) {
            model.set(this['property'], []);
        }

        for (var j = 0; j < this.column.length; j++) {
            for (var k = 0; k < this.column[j].widget.length; k++) {
                var w = this.column[j].widget[k];
                if (w.type === 'SelectionWidget') {
                    listIndexArray = this.getListIndexArray(model);
                    for (var i = 0; i < listIndexArray.length; i++) {
                        w.initModelValue(model, this.property, listIndexArray[i]);
                    }
                }
            }
        }

        if (this['specialBehaviour'] === "publicationCity") {
            if (MiscHelper.isEmpty(model.get(this['property']))) {
                model.set(this['property'], []);
            } else {
                model.set(this['property'], model.get(this['property']).split(","));
            }
        }

        var listIndexArray = this.getListIndexArray(model, parentProperty, parentIndex);

        if (listIndexArray.length > 0) {
            this.hadValueOnLoad = true;
        }

        var minTemp = this['minNr'];

        if (this['specialBehaviour'] === "keywordsMin") {
            minTemp = 3;
        }

        if (this['property'] === 'texts') {
            var texts = model.get('texts');
            if (!MiscHelper.isEmpty(texts)) {
                for (var i = 0; i < texts.length; i++) {
                    var text = texts[i];
                    if (text.linkType === '01') {
                        text.additionalInfoSwitch = 'webSide';
                    } else {
                        text.additionalInfoSwitch = 'text';
                    }
                }
            }
        }

        if (this['property'] === 'salesRights') {
            for (var j = 0; j < this.column.length; j++) {
                for (var k = 0; k < this.column[j].widget.length; k++) {
                    var w = this.column[j].widget[k];
                    if (w.type === 'SelectionWidget') {
                        //listIndexArray = this.getListIndexArray(model);
                        //for (var i = 0; i < listIndexArray.length; i++) {
                        //    w.initModelValue(model, this.property, listIndexArray[i]);
                        //}
                    } else if (w['property'] === 'territoryROW' || w['property'] === 'territoryWORLD') {
                        var territory = w['property'].replace('territory', '');
                        listIndexArray = this.getListIndexArray(model);
                        for (var i = 0; i < listIndexArray.length; i++) {
                            var buildedName = this.buildName(w, listIndexArray[i]);
                            var value = model.get('salesRights.' + listIndexArray[i] + '.territory');
                            if (!MiscHelper.isEmpty(value) && value.indexOf(territory) > -1) {
                                model.set(buildedName, true);
                            } else {
                                model.set(buildedName, false);
                            }
                        }
                    }
                }
            }
        }

        if (this['specialBehaviour'] === 'stripTitle') {
            var collections = model.get('collections');
            if (collections != null) {
                var targetCollection;
                for (var i = 0; i < collections.length; i++) {
                    if (collections[i].master.type === 'set') {
                        targetCollection = collections[i];
                        break;
                    }
                }
                if (targetCollection != undefined) {
                    var masterTitle = targetCollection.master.title;
                    var masterSubtitle = targetCollection.master.subtitle;
                    var productTitle;
                    var productSubtitle;
                    var i;
                    for (i = 0; i < model.get('titles').length; i++) {
                        if (model.get('titles')[i].type === '01') {
                            productTitle = model.get('titles')[i].title;
                            productSubtitle = model.get('titles')[i].subtitle;
                            break;
                        }
                    }
                    if (!MiscHelper.isEmpty(masterTitle) && !MiscHelper.isEmpty(productTitle)
                        && productTitle.indexOf('/') > -1 && _.string.startsWith(productTitle, masterTitle)) {
                        var newProductTitle
                        newProductTitle = productTitle.substring(masterTitle.length);
                        newProductTitle = newProductTitle.trim();
                        if (_.string.startsWith(newProductTitle, '/')) {
                            newProductTitle = newProductTitle.substring(1);
                            newProductTitle = newProductTitle.trim();
                        }
                        model.set('titles.' + i + '.title', newProductTitle);
                    }
                    if (!MiscHelper.isEmpty(masterSubtitle) && !MiscHelper.isEmpty(productSubtitle)
                        && productSubtitle.indexOf('/') > -1 && _.string.startsWith(productSubtitle, masterSubtitle)) {
                        var newProductSubtitle
                        newProductSubtitle = productSubtitle.substring(masterSubtitle.length);
                        newProductSubtitle = newProductSubtitle.trim();
                        if (_.string.startsWith(newProductSubtitle, '/')) {
                            newProductSubtitle = newProductSubtitle.substring(1);
                            newProductSubtitle = newProductSubtitle.trim();
                        }
                        model.set('titles.' + i + '.subtitle', newProductSubtitle);
                    }

                }
            }
        }

        if (minTemp > 0 && (this.showOn === undefined || MiscHelper.compareComplex(model.attributes, this.showOn)) && this['specialBehaviour'] !== "priceRepeal") {
            var startIndex = listIndexArray.length;
            if (startIndex < minTemp) {

                if (model.get(this['property']) === null) {
                    // That might happen on lists which are currently not used in the UI. For example the bundle blocks
                    // These blocks are available but hidden if there is no bundle product. In this case the server
                    // might sent nothing. Not even an empty array.
                    model.set(this['property'], []);
                }

                for (var i = 0; i < (minTemp - startIndex); i++) {
                    model.get(this['property']).push(this.prefill(model));
                }
            }

            for (var j = 0; j < this.column.length; j++) {
                for (var k = 0; k < this.column[j].widget.length; k++) {
                    var w = this.column[j].widget[k];
                    if (w.type === 'ListCompositeWidget') {
                        listIndexArray = this.getListIndexArray(model);
                        for (var i = 0; i < listIndexArray.length; i++) {
                            w.initModelValue(model, this.property, listIndexArray[i]);
                        }
                    }
                }
            }

        }
        //warengruppen split field
        if (this['specialBehaviour'] === "categoryofgoods") {
            listIndexArray = this.getListIndexArray(model);
            var wg = model.get(this['property'])[listIndexArray[0]];
            if (wg !== undefined && wg.code !== undefined && wg.code != null && wg.code.length === 4) {
                wg.index = wg.code.substr(0, 1);
                wg.group = wg.code.substr(1, 4);
            }
        }

        if (this['specialBehaviour'] === "classificationOfGames"){
        }

        if (this['specialBehaviour'] === "priceMerge") {
            listIndexArray = this.getListIndexArray(model);
            if (model.get(this['property']) !== undefined) {
                var ptg = Math.random().toString(36);
                for (var i = 0; i < listIndexArray.length; i++) {
                    var obj = model.get(this['property'])[listIndexArray[i]];
                    PriceHelper.preparePriceObject(obj);
                    //we detect price belonging together on the priceTypeGroup and countryCurrency, But we can have more than one special price, but rest-api returns always special
                    if (this.property === 'specialPrices') {
                        if (MiscHelper.isEmpty(obj.validFrom)) {
                            ptg = Math.random().toString(36);
                        }
                        obj.priceTypeGroup = ptg;
                    }
                }
            }
        }
        if (this['specialBehaviour'] === 'duoBundles' || this['specialBehaviour'] === 'multiBundles'
            || this['specialBehaviour'] === 'pricesJournal' || this['specialBehaviour'] === 'pricesJournalBundle') {
            listIndexArray = this.getListIndexArray(model);
            if (model.get(this['property']) !== undefined) {
                for (var i = 0; i < listIndexArray.length; i++) {
                    var obj = model.get(this['property'])[listIndexArray[i]];
                    PriceHelper.prepareCurrencyCountry(obj);
                }
            }
        }

        if (this['specialBehaviour'] === "priceRepeal") {
            if (model.get(this['property']) !== undefined) {
                model.get(this['property']).push({
                    id: MiscHelper.uuid(),
                    type: "02",
                    value: "",
                    currencyCountry: "EUR_DE",
                    taxRate: ""
                });
            }
        }

        if (parentProperty !== undefined && parentIndex === undefined) {
            aModel.set(parentProperty + '.' + this.property, model.get(this.property));
        }

    },

    prepareForSubmit: function (model, parentProperty, parentIndex) {
        // Only execute this routine for containedItems if we don't have bundles. In the case of having bundles
        // it is part of the bundle routines to check if a contained item is relevant or is not relevant.
        if (this.id === 'containedItems'
            && ((model.get('uiType') === 'bundles') && model.get('type') !== 'nonbook')
            || (model.get('type') === 'journal' && model.get('zisInfo') === 'WW')) {
            return;
        }

        var listIndexArray = this.getListIndexArray(model, parentProperty, parentIndex);
        listIndexArray.reverse();
        for (var i = 0; i < listIndexArray.length; i++) {
            var key = this.buildKeyForObject(this, listIndexArray[i], parentProperty, parentIndex);
            var obj = model.get(key);
            var remove = true;
            if (this.simple === true) {
                if (!MiscHelper.isEmpty(obj)) {
                    remove = false;
                }
            } else if (this.id === 'specialPrices'
                && (model.get('type') === 'duoBundle' || model.get('type') === 'multiBundle')) {
                remove = true;
            } else if (this['specialBehaviour'] === "priceMerge") {
                if (!MiscHelper.isEmpty(obj['value']) || !MiscHelper.isEmpty(obj['validFrom'])) {
                    remove = false;
                }
            } else if (this['specialBehaviour'] === "duoBundles") {
                var bundlePrice = model.get(key);
                if (bundlePrice) {
                    if (MiscHelper.isEmpty(bundlePrice['sum']) && MiscHelper.isEmpty(bundlePrice['type'])
                        && MiscHelper.isEmpty(bundlePrice['validFrom']) && MiscHelper.isEmpty(bundlePrice['validTo'])) {
                        for (var j = 0; j < bundlePrice.bundleParts.length; j++) {
                            var bundlePart = bundlePrice.bundleParts[j];
                            if (MiscHelper.isEmpty(bundlePart['gtin']) && MiscHelper.isEmpty(bundlePart['productId'])
                                && MiscHelper.isEmpty(bundlePart['taxRate']) && MiscHelper.isEmpty(bundlePart['type'])
                                && MiscHelper.isEmpty(bundlePart['value'])) {
                                remove = true;
                            } else {
                                remove = false;
                                break;
                            }
                        }
                    } else {
                        remove = false;
                    }
                }
            } else if (this['specialBehaviour'] === 'removeInvalidIdentifiers' && model.get('type') === 'journal') {
                // this case is been checked later because it is a little bit mor complex.
                remove = false;
            } else {
                //prepare all sub composites
                for (var j = 0; j < this.column.length; j++) {
                    for (var k = 0; k < this.column[j].widget.length; k++) {
                        var w = this.column[j].widget[k];
                        if (w.type === 'ListCompositeWidget') {
                            w.prepareForSubmit(model, this.property, listIndexArray[i]);
                        }
                    }
                }
                //now check if we need to delete it
                columns: for (var j = 0; j < this.column.length; j++) {
                    for (var k = 0; k < this.column[j].widget.length; k++) {
                        var w = this.column[j].widget[k];
                        if (w.type !== 'HiddenWidget' && w.type !== 'LabelWidget') {
                            if (obj === undefined) {
                                continue;
                            }
                            var value = obj[w.property];

                            var dotIdx = w.property.indexOf('.');
                            if (dotIdx !== -1) {
                                value = obj[w.property.substring(0, dotIdx)][w.property.substring(dotIdx + 1, w.property.length)];

                            }
                            // always ignore empty values.
                            if (!MiscHelper.isEmpty(value)) {
                                // also ignore values which are the same as the default value. That additional check ist
                                // necessary because checkBoxes always have a value.
                                if (MiscHelper.isEmpty(w.defaultValue) || w.defaultValue !== value) {
                                    remove = false;
                                    break columns;
                                }
                            }
                        }
                    }
                }
            }

            if (remove) {
                model.get(this['property']).splice(listIndexArray[i], 1);
            }
        }

        listIndexArray = this.getListIndexArray(model, parentProperty, parentIndex);

        if (this['specialBehaviour'] === "publicationCity") {
            model.set(this['property'], model.get(this['property']).filter(function (n) {
                return n !== undefined && n !== null;
            }).join());
        }


        //warengruppen split field
        if (this['specialBehaviour'] === "categoryofgoods") {
            //listIndexArray = this.getListIndexArray(model);
            var wg = model.get(this['property'])[listIndexArray[0]];
            if (wg !== undefined) {
                wg.code = wg.index + wg.group;
                wg.main = true;
            }
        }

        if (this['specialBehaviour'] === "multiBundles" && !$('#block_priceMultiBundle').hasClass('hidden')) {
            var bundles = model.get('multiBundles');
            for (var i = 0; i < bundles.length; i++) {
                var bundle = bundles[i];
                var sumIsNull = true;
                for (var j = 0; j < bundle.bundleParts.length; j++) {
                    var bundlePart = bundle.bundleParts[j];
                    if (MiscHelper.isEmpty(bundlePart.taxAmount)) {
                        bundlePart.taxAmount = null;
                    } else {
                        sumIsNull = false;
                    }
                    if (MiscHelper.isEmpty(bundlePart.valueNetto)) {
                        bundlePart.valueNetto = null;
                    } else {
                        sumIsNull = false;
                    }
                }
                if (sumIsNull === true) {
                    bundle.sum = null;
                }
            }
            //var previousContainedItems = model.get('containedItems');
            model.set('bundles', bundles);
        }

        if (this['specialBehaviour'] === "duoBundles" && !$('#block_priceDuoBundle').hasClass('hidden')) {
            var bundles = model.get('duoBundles');
            var previousContainedItems = model.get('containedItems');
            model.set('bundles', bundles);
            var prices = model.get('prices');
            if (prices == undefined) {
                prices = [];
            }
            for (var i = 0; i < bundles.length; i++) {
                if (i === 0) {
                    var containedItems = [];
                    var containedItem = previousContainedItems[0] || {};
                    containedItem['productIdentifierValue'] = bundles[0].bundleParts[0].gtin;
                    containedItem['type'] = bundles[0].bundleParts[0].type;
                    containedItem['productIdentifierType'] = bundles[0].bundleParts[0].productIdentifierType;
                    containedItem['sequence'] = 1;
                    containedItems.push(containedItem);
                    containedItem = previousContainedItems[1] || {};
                    containedItem['productIdentifierValue'] = bundles[0].bundleParts[1].gtin;
                    containedItem['type'] = bundles[0].bundleParts[1].type;
                    containedItem['productIdentifierType'] = bundles[0].bundleParts[1].productIdentifierType;
                    containedItem['sequence'] = 2;
                    containedItems.push(containedItem);
                    model.set('containedItems', containedItems);
                }
                if (!MiscHelper.isEmpty(bundles[i]) && !MiscHelper.isEmpty(bundles[i].bundleParts)) {
                    var value = null;
                    if (!MiscHelper.isEmpty(bundles[i].bundleParts[0].valueNetto) || !MiscHelper.isEmpty(bundles[i].bundleParts[1].valueNetto)
                        || !MiscHelper.isEmpty(bundles[i].bundleParts[0].taxAmount) || !MiscHelper.isEmpty(bundles[i].bundleParts[1].taxAmount)) {
                        value = (MiscHelper.isEmpty(bundles[i].bundleParts[0].valueNetto) ? 0 : bundles[i].bundleParts[0].valueNetto)
                            + (MiscHelper.isEmpty(bundles[i].bundleParts[1].valueNetto) ? 0 : bundles[i].bundleParts[1].valueNetto)
                            + (MiscHelper.isEmpty(bundles[i].bundleParts[0].taxAmount) ? 0 : bundles[i].bundleParts[0].taxAmount)
                            + (MiscHelper.isEmpty(bundles[i].bundleParts[1].taxAmount) ? 0 : bundles[i].bundleParts[1].taxAmount);
                        value = value.toFixed(2);
                    }
                    var price = {
                            id: bundles[i].id,
                            type: bundles[i].type,
                            validFrom: bundles[i].validFrom,
                            validUntil: bundles[i].validUntil,
                            taxAmount: bundles[i].bundleParts[0].taxAmount,
                            taxAmount2: bundles[i].bundleParts[1].taxAmount,
                            taxableAmount: bundles[i].bundleParts[0].valueNetto,
                            taxableAmount2: bundles[i].bundleParts[1].valueNetto,
                            taxRate: bundles[i].bundleParts[0].taxRate,
                            taxRate2: bundles[i].bundleParts[1].taxRate,
                            typeQualifier: '',
                            value: value,
                            version: bundles[i].version,
                            currency: bundles[i].currency,
                            country: bundles[i].country
                        }
                    ;
                    prices.push(price);
                }
            }
            model.set('prices', prices);
        }

        if (this['specialBehaviour'] === "priceMerge") {
            if (model.get('pricing') === false && !MiscHelper.isEmpty(model.get('unpricedItemCode'))) {
                model.set('prices', []);
                model.set('foreignPrices', []);
                model.set('retailPrices', []);
                model.set('specialPrices', []);
            } else {
                model.set('unpricedItemCode', null);
                //listIndexArray = this.getListIndexArray(model);
                if (model.get('prices') === undefined) {
                    model.set('prices', []);
                }
                for (var i = 0; i < listIndexArray.length; i++) {
                    var obj = model.get(this['property'])[listIndexArray[i]];
                    obj['validFrom'] = DateHelper.convert(obj['validFrom'], undefined, 'dd.MM.yyyy');

                    var currencyCountry = obj.currencyCountry;
                    if (currencyCountry !== undefined && currencyCountry !== null) {
                        obj.currency = currencyCountry.substring(0, 3);
                        obj.country = currencyCountry.substring(4, 6);
                    }

                    if (obj.typeTypeQualifier !== undefined && obj.typeTypeQualifier !== null) {
                        var ttqIndex = obj.typeTypeQualifier.indexOf("_");
                        if (ttqIndex !== -1) {
                            obj.type = obj.typeTypeQualifier.substring(0, ttqIndex);
                            var typeQualifier = obj.typeTypeQualifier.substring(ttqIndex + 1, obj.typeTypeQualifier.length);
                            if (typeQualifier !== "M") {
                                obj.typeQualifier = typeQualifier;
                            } else {
                                obj.typeQualifier = null;
                            }

                        } else {
                            obj.type = obj.typeTypeQualifier;
                        }


                    }

                    obj.priceTypeGroup = undefined;
                    if (!obj.calculated) {
                        var prices = model.get('prices');
                        prices.push(obj);
                    }
                }
            }

        }

        if (this['specialBehaviour'] === 'pricesJournal' || this['specialBehaviour'] === 'pricesJournalBundle') {
            for (var i = 0; i < listIndexArray.length; i++) {
                var obj = model.get(this['property'])[listIndexArray[i]];
                var currencyCountry = obj.currencyCountry;
                if (currencyCountry !== undefined && currencyCountry !== null) {
                    obj.currency = currencyCountry.substring(0, 3);
                    obj.country = currencyCountry.substring(4, 6);
                }
            }
            if (this['specialBehaviour'] === 'pricesJournal') {
                if (model.get('pricingJournal') === false && !MiscHelper.isEmpty(model.get('unpricedItemCodeJournal'))) {
                    model.set('pricesJournal', []);
                } else {
                    model.set('unpricedItemCodeJournal', null);
                    //listIndexArray = this.getListIndexArray(model);
                    if (model.get('pricesJournal') === undefined) {
                        model.set('pricesJournal', []);
                    }
                }
            } else if (this['specialBehaviour'] === 'pricesJournalBundle') {

            }
        }

        if (this['specialBehaviour'] === "priceRepeal") {
            if (!MiscHelper.isEmpty(model.get('priceRepealDateUI'))) {
                var repealPriceDE = PriceHelper.findPriceByCountryAndValidFrom(model.get('prices'), "EUR_DE", model.get('priceRepealDateUI'));
                if (model.get('priceRepealFuturePrice') === "anew") {
                    var prp = model.get('priceRepealPrices');
                    for (var i = 0; i < prp.length; i++) {
                        if (!MiscHelper.isEmpty(prp[i].value)) {
                            prp[i].validFrom = model.get('priceRepealDateUI');
                            var currencyCountry = prp[i].currencyCountry;
                            if (currencyCountry !== undefined) {
                                prp[i].currency = currencyCountry.substring(0, 3);
                                prp[i].country = currencyCountry.substring(4, 6);
                            }
                            var existing = null;
                            if (prp[i].country === 'DE') {
                                existing = repealPriceDE;
                            } else if (prp[i].country === 'AT') {
                                existing = repealPriceAT;
                            } else {
                                existing = repealPriceCH;
                            }
                            if (existing !== undefined && existing !== null) {
                                existing.price.value = prp[i].value;
                                existing.price.taxRate = prp[i].taxRate;
                            } else {
                                model.get('prices').push(prp[i]);
                            }

                        }
                    }
                } else {
                    if (repealPriceDE === undefined || repealPriceDE === null) {
                        this.addRepealPriceFromExistingRetailPrice(model, "EUR_DE");
                    }
                }
            }
        }

        if (this['specialBehaviour'] === "hierarchyMaster" && listIndexArray.length > 0) {
            var hierarchy = model.get(this['property'])[listIndexArray[0]];
            if (this.getProperty(hierarchy, "master.identifiers.0.value") === "") {
                delete hierarchy['master']['identifiers'];
            }
        }
        if (this['specialBehaviour'] === "seriesMaster" && listIndexArray.length > 0) {
            for (var i = 0; i < listIndexArray.length; i++) {
                var obj = model.get(this['property'])[listIndexArray[i]];
                if (this.getProperty(obj, "master.identifiers.0.value") === "") {
                    delete obj['master']['identifiers'];
                }
            }
        }

        if (this['specialBehaviour'] === "contributor" && listIndexArray.length > 0) {
            if (model.get('noContributor') === true) {
                model.set('contributors', null);
            } else {
                for (var i = 0; i < listIndexArray.length; i++) {
                    var obj = model.get(this['property'])[listIndexArray[i]];
                    if (this.getProperty(obj, "company") === true) {
                        obj['lastName'] = null;
                        obj['firstName'] = null;
                        obj['professionalAffiliation'] = null;
                        obj['professionalPosition'] = null;
                        obj['dateOfBirth'] = null;
                        obj['dateOfDeath'] = null;
                    } else {
                        obj['groupName'] = null;
                    }
                }
            }
        }

        if (model.get('type') === 'journal') {
            // TODO This is executed for each composite in a journal. Ths must be executed only once.
            model.set('specialPrices', []);
            model.set('foreignPrices', []);
        }

        if (this['specialBehaviour'] === 'removeInvalidIdentifiers' && model.get('type') === 'journal') {
            var hasISSN = false;
            for (var i = 0; i < model.get('identifiers').length; i++) {
                var identifier = model.get('identifiers')[i];
                if (identifier === null || identifier === undefined) {
                    continue;
                }

                if (model.attributes.issnswitch === "without") {
                    if (identifier.type !== 'FI' && identifier.type !== '03') {
                        model.get('identifiers')[i] = null;
                    }
                } else {
                    if (identifier.type !== 'FI' && identifier.type !== 'IS' && identifier.type !== '03') {
                        model.get('identifiers')[i] = null;
                    }
                    if (identifier.type === 'IS' && !MiscHelper.isEmpty(identifier.value)) {
                        hasISSN = true;
                    }
                }
            }
            if (model.attributes.issnswitch === "with" && !hasISSN) {
                model.set('identifiers', []);
            }
        }

    },

    addRepealPriceFromExistingRetailPrice: function (model, currenyCountry) {
        var priceRetail = PriceHelper.findPrice(model.get('retailPrices'), currenyCountry);
        if (priceRetail && !MiscHelper.isEmpty(priceRetail.price.value)) {
            var price = $.extend({}, priceRetail.price);
            price.type = "02";
            price.id = MiscHelper.uuid();
            price.validFrom = model.get('priceRepealDateUI');
            model.get('prices').push(price);
        }
    },

    buildKeyForObject: function (w, index, parentProperty, parentIndex) {
        var id = "";
        if (parentProperty !== undefined) {
            id += parentProperty + "." + parentIndex + ".";
        }
        id += w['property'] + '.' + index;
        return id;
    },

    doWeHaveEvents: function () {
        for (var j = 0; j < this.column.length; j++) {
            for (var k = 0; k < this.column[j].widget.length; k++) {
                var w = this.column[j].widget[k];
                if (w.changeEvent !== undefined && w.changeEvent.length > 0) {
                    return true;
                }
                if (w.type === 'TableCompositeWidget') {
                    for (var l = 0; l < w.column.length; l++) {
                        for (var m = 0; m < w.column[l].widget.length; m++) {
                            var widget = w.column[l].widget[m];
                            if (widget.changeEvent !== undefined && widget.changeEvent.length > 0) {
                                return true;
                            }
                        }
                    }
                }
            }
        }
        return false;
    },

    selects: function (model, parentProperty, parentIndex) {
        var listIndexArray = this.getListIndexArray(model, parentProperty, parentIndex);
        var selects = [];
        for (var i = 0; i < listIndexArray.length; i++) {
            for (var j = 0; j < this.column.length; j++) {
                for (var k = 0; k < this.column[j].widget.length; k++) {
                    var w = this.column[j].widget[k];
                    if (w.type === 'SelectionWidget' || (w.type === 'RadioWidget')) {
                        var m = w.getOptionsSelectMapping();
                        if (m === undefined) {
                            m = {};
                        }
                        m.selector = '#' + this.buildId(w, listIndexArray[i], parentProperty, parentIndex);
                        m.key = this.buildName(w, listIndexArray[i], parentProperty, parentIndex);
                        selects.push({widget: w, selectMapping: m});
                    } else if (w.type === 'ListCompositeWidget' || w.type === 'TableCompositeWidget') {
                        Array.prototype.push.apply(selects, w.selects(model, this.property, listIndexArray[i]));
                    }
                }
            }
        }
        return selects;
    },

    typeaheads: function (model, parentProperty, parentIndex) {
        var listIndexArray = this.getListIndexArray(model, parentProperty, parentIndex);
        var selects = [];
        for (var i = 0; i < listIndexArray.length; i++) {
            for (var j = 0; j < this.column.length; j++) {
                for (var k = 0; k < this.column[j].widget.length; k++) {
                    var w = this.column[j].widget[k];
                    if (w.type === 'TypeaheadWidget') {
                        var m = w.getOptionsSelectMapping();
                        if (m === undefined) {
                            m = {};
                        }
                        m.selector = '#' + this.buildId(w, listIndexArray[i], parentProperty, parentIndex);
                        m.key = this.buildName(w, listIndexArray[i], parentProperty, parentIndex);
                        selects.push({widget: w, typeaheadMapping: m});
                    } else if (w.type === 'ListCompositeWidget') {
                        Array.prototype.push.apply(typeaheads, w.typeaheads(model, this.property, listIndexArray[i]));
                    }
                }
            }
        }
        return selects;
    },

    events: function (model, parentProperty, parentIndex) {
        var listIndexArray = this.getListIndexArray(model, parentProperty, parentIndex);
        var result = {};
        for (var i = 0; i < listIndexArray.length; i++) {
            for (var j = 0; j < this.column.length; j++) {
                for (var k = 0; k < this.column[j].widget.length; k++) {
                    var w = this.column[j].widget[k];
                    if (w.changeEvent !== undefined && w.changeEvent.length > 0) {
                        result[this.buildId(w, listIndexArray[i], parentProperty, parentIndex)] = w.changeEvent;
                    }
                    if (w.type === 'ListCompositeWidget' || w.type === 'TableCompositeWidget') {
                        var tmpResults = w.events(model, this.property, listIndexArray[i]);
                        for (id in tmpResults) {
                            result[id] = tmpResults[id];
                        }
                    }
                }
            }
        }
        return result;
    },

    initDatefieldsAfterBinding: function (model) {
        var listIndexArray = this.getListIndexArray(model);
        for (var i = 0; i < listIndexArray.length; i++) {
            for (var j = 0; j < this.column.length; j++) {
                for (var k = 0; k < this.column[j].widget.length; k++) {
                    var w = this.column[j].widget[k];
                    if (w.type === 'DateWidget' && !w.readonly) {
                        w.initAfterBinding(model, '#' + this.buildId(w, listIndexArray[i]), this.buildName(w, listIndexArray[i]));
                    }
                }
            }
        }
    },

    initSelectsAfterBinding: function (allCollections, model, editable, parentProperty, parentIndex) {
        var listIndexArray = this.getListIndexArray(model, parentProperty, parentIndex);
        for (var i = 0; i < listIndexArray.length; i++) {
            for (var j = 0; j < this.column.length; j++) {
                for (var k = 0; k < this.column[j].widget.length; k++) {
                    var w = this.column[j].widget[k];
                    if (w.type === 'SelectionWidget' || w.type === 'RadioWidget' || w.type === 'TypeaheadWidget') {

                        var options = [];
                        if (allCollections[w.collection] !== undefined) {
                            options = allCollections[w.collection].options;
                        }
                        w.initAfterBinding(options, model, editable, '#' + this.buildId(w, listIndexArray[i],
                            parentProperty, parentIndex), this.buildName(w, listIndexArray[i], parentProperty, parentIndex));
                    } else if (w.type === 'ListCompositeWidget' || w.type === 'TableCompositeWidget') {
                        w.initSelectsAfterBinding(allCollections, model, editable, this.property, listIndexArray[i]);
                    }
                }
            }
        }

        // remove statusrelevent class from select (mainly for languages)
        if (model.get('type') === "journal") {
            // I know it is a nasty hack here but i could not find another way of removing the statusrelevant class from fieldsets or selections or else
            $('.statusrelevent').removeClass('statusrelevent');
        }

    },

    getProperty: function (obj, prop) {
        if (prop.indexOf('.') !== -1) {
            var parts = prop.split('.'),
                last = parts.pop(),
                l = parts.length,
                i = 1,
                current = parts[0];

            while ((obj = obj[current]) && i < l) {
                if (obj === undefined) {
                    return null;
                }
                current = parts[i];
                i++;
            }

            if (obj) {
                return obj[last];
            }
            return null;
        } else {
            return obj[prop];
        }
    },

    matches: function (obj, filters) {
        if (obj === null) {
            return false;
        }

        //var matches = filters.length;
        for (var i = 0; i < filters.length; i++) {
            var validValues = filters[i].value.split(',');
            var nullPosition = validValues.indexOf('null');
            if (nullPosition > -1) {
                validValues[nullPosition] = null;
                validValues.push(undefined);
                validValues.push('');
            }
            var currentValue = this.getProperty(obj, filters[i].key);
            if (typeof(currentValue) === "boolean") {
                var matches = false;
                for (val in validValues) {
                    if (validValues[val] == '' + currentValue) {
                        matches = true;
                        break;
                    }
                }
                if (!matches) {
                    return false;
                }
            } else {
                if (validValues.indexOf(currentValue) > -1) {
                    //matches--;
                } else {
                    return false;
                }
            }
        }
        return true;
    },

    getListIndexArray: function (model, parentProperty, parentIndex) {
        var listIndexArray = [];
        var main = -1;

        var propObj = model.get(this['property']);
        if (parentProperty !== undefined && parentIndex !== undefined) {
            propObj = model.get(parentProperty)[parentIndex][this.property];
        }

        if (propObj) {
            if (this['filter'] && this['filter'].length > 0) {
                for (var i = 0; i < propObj.length; i++) {
                    var obj = propObj[i];
                    if (this.matches(obj, this['filter'])) {
                        listIndexArray.push(i);
                        if (this['property'] === 'classifications' && obj['main'] === true) {
                            main = i;
                        }
                    }
                }
            } else {
                for (var i = 0; i < propObj.length; i++) {
                    var obj = propObj[i];
                    if (obj !== null && obj !== undefined) {
                        listIndexArray.push(i);
                    }
                }
            }
        }

        //sort classifications
        if (main !== -1) {
            listIndexArray.sort(function (a, b) {
                if (a === main) {
                    return -1;
                }
                if (b === main) {
                    return 1;
                }
                return 0;
            });
        }

        return listIndexArray;
    },

    render: function (model) {
        if (this.hideWhenEmpty && MiscHelper.isEmpty(model.get(this.property))) {
            return "";
        }

        var listIndexArray = this.getListIndexArray(model);
        var isRemovable = this.property !== 'retailPrices' && this.isEditable && (!this['minNr'] || ((listIndexArray.length) > this['minNr']));
        var isAddable = this.isEditable && (this['maxNr'] === undefined || ((listIndexArray.length) < this['maxNr']));
        //we can add no more than 27 current entries for foreignPrices
        if (this['property'] === "foreignPrices" && isAddable === true) {
            isAddable = PriceHelper.countCurrentforeignPrices(model) < 27;
        }

        var scopeData = jQuery.extend(true, {}, this, {
            listIndex: listIndexArray,
            isRemovable: isRemovable,
            isAddable: isAddable

        });
        return this.template(scopeData, {data: arguments.data});
    }
});


module.exports = CompositeWidget;
