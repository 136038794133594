// Base class for all models
module.exports = Chaplin.Model.extend(
{
	sync: function (method, model, options) {    	
		var deferred = $.Deferred();
        var response = Chaplin.Model.prototype.sync.call(this, method, model, options);
        
        response.done(deferred.resolve);
        response.fail(function() {
        	if (response.status == 200) {
        		window.location.href = '/login?sessionExpired=true';
        	}
        });

        return deferred.promise();
    },
    
	deepClone: function() {
		  return new this.constructor(this.toJSON());
	}
}
);
