
var ListView = require('./list_view');
var MsgHelper = require('lib/msg_helper');
var MiscHelper = require('lib/misc_helper');
var SearchQuery = require('models/searchQuery');

var modelMap;

var logger = log4javascript.getLogger('views.SearchHistoryView');

module.exports = SearchHistoryView = ListView.extend({

    tagName: 'div',
    region: 'content',
    containerMethod: 'html',
    
    initialize : function(options) {
    	ListView.prototype.initialize.apply(this, [options]);
	},
    
    getTemplateData: function () {
    	var data = ListView.prototype.getTemplateData.call(this);
        return data;
    },
    
    formDefinitionLoaded: function (loadedFormDef) {
    	this.options.defaultSort = [ [2, 'desc'] ],
    	ListView.prototype.formDefinitionLoaded.call(this, loadedFormDef);
    },

    getDataFromRest: function (sSource, aoData, fnCallback, oSettings) {
        //extract name/value pairs into a simpler map for use later
        var paramMap = ListView.prototype.getAsParamMap(aoData);
        var tableSelector = paramMap["tableSelector"];
		// page calculations
		var pageSize = paramMap.length;
		var start = paramMap.start;
		var pageNum = (start == 0) ? 1 : (start / pageSize) + 1; // pageNum is 1 based

		// extract sort information
		var sortCol = paramMap.order[0].column;
		var sortDir = paramMap.order[0].dir;
		var sortName = paramMap.columns[sortCol].data;
        var datatableUrl =  "/searchhistorys";
        var httpType = 'GET';
        modelMap = new Object({});

        //finally, make the request
        jQuery.ajax({
            "dataType": 'json',
            url: datatableUrl,
            type: httpType,
            "data": {
                page: pageNum,
                size: pageSize,
                sort: sortName,
                direction: sortDir,
                search: oSettings.oPreviousSearch.sSearch
            },
            "success": function (data) {
                data.iTotalRecords = data.totalElements;
                data.iTotalDisplayRecords = data.totalElements;
                if (data.content) {
                	var nrOfCols = aoData[1].value;
            		var offset = 5;
                	for(var i=0; i<data.content.length; i++) {
                		for(var j=0; j<nrOfCols; j++) {
                			var property = aoData[offset + j].value;
                			if (data.content[i][property] === undefined) {
                				data.content[i][property] = "";
                			}
                		}
                		if (data.content[i]['id']) {
                			modelMap[data.content[i]['id']] = new SearchQuery(data.content[i]);
                		}
                	}
                }
                fnCallback(data);
            },
            "error": function (data) {
                logger.error(datatableUrl+": error", data);
            },
            "beforeSend": function () {
                // jQuery('.datatableProcessing').show();
                Chaplin.mediator.publish('beforeDataLoaded');
            },
            "complete": function () {
                // jQuery('.datatableProcessing').hide();
                Chaplin.mediator.publish('afterDataLoaded');
            },
            "headers": {
            	'Authorization' : "Bearer " + user.access_token
            }
        });
    },
    
    createColumnDef : function(column) {
		var widget = null;
		if (!MiscHelper.isEmpty(column['widget'])) {
			widget = column['widget'][0];
		}
		var name = null;
		if (!MiscHelper.isEmpty(column['property'])) {
			name = column['property'];
		} else if (widget != null) {
			name = widget['property'];
		} else if (!MiscHelper.isEmpty(column['action'])) {
			name = column['action'][0]['property'];
		}
		var modelKey = this.options.modelKey;
		
		var columnTitle = MsgHelper.getMessage("label.form." + modelKey + ".field." + name);
		column['title'] = columnTitle;
		
		var columnDef = {
			"sClass" : name + "Column",
			"mDataProp" : name,
			"sTitle" : columnTitle,
			"bSortable" : (widget != null)
		};
		
		var actions = column['action'];
		var buildActionButtons = this.buildActionButtons;
		var renderNameFct = this.renderName;
		var renderGlyphColumnFct = this.renderGlyphColumn;
		
		columnDef["render"] = function(value, type, row, meta) {
			var widgetHtml = value;
			if (widget != null) {
				
				if (widget['property'] === 'mode') {
					widgetHtml = MsgHelper.getMessage("searchhistory.mode."+value);
		        } else if (widget.property === 'name') {
		        	return renderNameFct(value);
		        } else if (widget.property === 'favourite') {
		        	return renderGlyphColumnFct(value);
		        }
				
			}
			
			return widgetHtml + buildActionButtons(row, value, actions, modelKey);
		};
		
		return columnDef;
	},
	
	buildActionButtons: function(aData, value, actions, modelKey) {
		actionsHtml = "";
		if (!MiscHelper.isEmpty(actions)) {
			for ( var j = 0; j < actions.length; j++) {
				var action = actions[j];
				var actionHtml = "";
				var linkTitle = "";
				var link = action.property;
				if (!MiscHelper.isEmpty(action.title)) {
					linkTitle = ' title="' + MsgHelper.getMessage(action.title) + '"';
				}
				if (!MiscHelper.isEmpty(action.icon)) {
					link = '<i class="fa fa-' + action['icon'] + '"' + linkTitle + '></i>';
				}
				if (action.type === "EDIT") {
					actionHtml += '<a class="editLink" href="' + Chaplin.utils.reverse(modelKey + '_edit', [aData.id]) + '"' + linkTitle + '>' + link + '</a>';
	    		} else if (action.type === "DELETE") {
	    			actionHtml += '<a class="deleteLink" callback="' + modelKey + '/' + aData.id + '" callback-type="DELETE" data-placement="top" data-toggle="confirmation" data-original-title=""' + linkTitle + '>' + link + '</a>';
	    		} else {
	    			actionHtml += link;
	    		}
				actionHtml = '<span class="' + action.type.toLowerCase() + 'LinkContainer">' + actionHtml + '</span>';
				actionsHtml += actionHtml;
			}
		}
        return actionsHtml;
	},
    
    renderGlyphColumn: function (value) {
    	var glyphTitle = MsgHelper.getMessage("searchhistory.favourite.doit");
    	var glyphClass = "fa fa-star-o";
    	if (value) {
    		glyphClass = "fa fa-star";
    		glyphTitle = MsgHelper.getMessage("searchhistory.favourite.nolonger");
    	}
        return '<i class="' + glyphClass + '" title="' + glyphTitle + '"></i>';
    },
    
    renderName: function (value) {
    	return value + '<span class="customLinkContainer"><i title="' + MsgHelper.getMessage('label.action.searchquery.changeName') + '" class="fa fa-pencil"></i></span>';
    },
    
    toggleFav: function (event) {
    	var queryId = event.currentTarget.parentNode.parentNode.id;
    	var searchQuery = this.getModelForId(queryId);
    	searchQuery.set('favourite',!searchQuery.get('favourite'));
    	var cellHtmlFct = this.renderGlyphColumn;
    	searchQuery.clone().save(searchQuery.attributes, {async: true, wait: false, success: function (model, response, options) {
    		modelMap[model.get('id')] = model;
    		var cellHtml = cellHtmlFct(model.get('favourite'));
    		$(event.currentTarget).parent().html(cellHtml);
    	}, error: function (model, response, options) {
    		logger.error('/searchquery/' + searchQuery.get('id') + ' error');
        }});
    },
    
    deleteQuery: function (event) {
        var queryId = this.eventId(event);
    	var table = this.options.table;
    	var propMap = {"_QUERY_": $(event.currentTarget).parent().parent().find('.queryColumn').text()};
    	var deleteConfirmMsg = MsgHelper.getPropertyMessage("msg.confirm.delete.search.query", propMap);
    	alertify.confirm(deleteConfirmMsg, function (e) {
    	    if (e) {
    	    	jQuery.ajax({
    	            "dataType": 'json',
    	            url: '/searchquery/delete/'+queryId,
    	            type: 'PUT',
    	            "success": function (data) {
    	            	table.fnDeleteRow();
    	            },
    	            "error": function (data) {
    	                logger.error('/searchquery/delete/'+queryId+': error', data);
    	            },
    	            "beforeSend": function () {
    	                // jQuery('.datatableProcessing').show();
    	            },
    	            "complete": function () {
    	                // jQuery('.datatableProcessing').hide();
    	            },
    	            "headers": {
    	            	'Authorization' : "Bearer " + user.access_token
    	            }
    	        });
    	    }
    	});
    },
    
    editQuery: function (event) {
        var queryId = this.eventId(event);
    	var searchQuery = this.getModelForId(queryId);
    	
    	switch (searchQuery.get('mode')) {
    	case "quick":
    		Chaplin.mediator.publish('editQuickSearch', queryId);
    		break;
    	case "batch":
    		Chaplin.mediator.publish('editBatchSearch', queryId);
    		break;
    	case "advanced":
    		Chaplin.mediator.publish('editAdvancedSearch', queryId);
    		break;
    	case "boole":
    		Chaplin.mediator.publish('editBooleSearch', queryId);
    		break;
    	}
    },
    
    
    executeQuery: function (event) {
        var queryId = this.eventId(event);
        var searchQuery = this.getModelForId(queryId);
        this.executeSearchHistoryQuery(queryId,searchQuery.get('query'),searchQuery.get('mode'));
    },
    
    executeSearchHistoryQuery: function (queryId,query, mode, action) {
		Chaplin.mediator.publish('executedNewSearch');
    	switch (mode) {
    	case "quick":
			if(action === undefined) {
				action = 'startQuickSearch';
			}
    		Chaplin.mediator.publish('quickSearchPublishPoint', queryId, query, action);
    		break;
    	case "batch":
    		Chaplin.mediator.publish('batchSearchPublishPoint', queryId, query, 'onlyDisplay');
    		break;
    	case "advanced":
            Chaplin.mediator.publish('advancedSearchPublishPoint', queryId, query, mode);
            break;
    	case "boole":
    		Chaplin.mediator.publish('booleSearchPublishPoint', queryId, query, mode);
    		break;
    	}
    },
    
    changeName: function (event) {
        var queryId = this.eventId(event);
    	var searchQuery = this.getModelForId(queryId);
    	var html = '<input type="text" name="name" value="' + searchQuery.get('name') + '"/>'
    		+ '<span class="customLinkContainer">'
    		+ '<i class="fa fa-check" title="' + MsgHelper.getMessage('label.form.button.save') + '"></i>'
    		+ '<i class="fa fa-eraser" title="' + MsgHelper.getMessage('label.form.button.cancel') + '"></i></span>';
    	this.delegate('click', '#' + queryId + ' td.nameColumn i.fa-check', this.submitName);
    	this.delegate('click', '#' + queryId + ' td.nameColumn i.fa-eraser', this.cancelName);
    	$(event.currentTarget).parent().parent().html(html);
    },
    
    submitName: function (event) {
    	var queryId = this.eventId(event);
    	var searchQuery = this.getModelForId(queryId);
    	var newName = $(event.currentTarget).parent().parent().find('input').val();
    	searchQuery.set('name', newName);
    	var renderNameFct = this.renderName;
    	this.undelegate('click', '#' + queryId + ' td.nameColumn i.fa-check', this.submitName);
    	this.undelegate('click', '#' + queryId + ' td.nameColumn i.fa-eraser', this.cancelName);
    	searchQuery.clone().save(searchQuery.attributes, {async: true, wait: false, success: function (model, response, options) {
    		modelMap[model.get('id')] = model;
    		$(event.currentTarget).parent().parent().html(renderNameFct(model.get('name')));
    	}, error: function (model, response, options) {
    		logger.error('/searchquery/' + searchQuery.get('id') + ' error');
        }});
    },
    
    cancelName: function (event) {
        var queryId = this.eventId(event);
    	var searchQuery = this.getModelForId(queryId);
    	this.undelegate('click', '#' + queryId + ' td.nameColumn i.fa-check', this.submitName);
    	this.undelegate('click', '#' + queryId + ' td.nameColumn i.fa-eraser', this.cancelName);
    	$(event.currentTarget).parent().parent().html(this.renderName(searchQuery.get('name')));
    },
    
    beforeDataLoaded: function() {
    	// favourite events
    	this.undelegate('click', 'td.favouriteColumn i', this.toggleFav);
    	// delete events
    	this.undelegate('click', 'td.deleteColumn i', this.deleteQuery);
    	// edit events
    	this.undelegate('click', 'td.editColumn i', this.editQuery);
    	// execute events
    	this.undelegate('click', 'td.executeColumn i', this.executeQuery);
    	// change name events
    	this.undelegate('click', 'td.nameColumn i.fa-pencil', this.changeName);
    },
    
    afterDataLoaded: function() {
    	// favourite events
    	this.delegate('click', 'td.favouriteColumn i', this.toggleFav);
    	// delete events
    	this.delegate('click', 'td.deleteColumn i', this.deleteQuery);
    	// edit events
    	this.delegate('click', 'td.editColumn i', this.editQuery);
    	// execute events
    	this.delegate('click', 'td.executeColumn i', this.executeQuery);
    	// change name events
    	this.delegate('click', 'td.nameColumn i.fa-pencil', this.changeName);
    },

    getModelForId: function(id) {
		return modelMap[id];
	}
    
});