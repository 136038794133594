var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "placeholder=\""
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, (depth0 != null ? depth0.placeholder : depth0), {"name":"lbl","hash":{},"data":data})))
    + "\" ";
},"3":function(depth0,helpers,partials,data) {
  return "data-changeEvent=\"true\"";
  },"5":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "error-"
    + escapeExpression(lambda(depth0, depth0))
    + " ";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing, buffer = "<input type=\"text\" ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.placeholder : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "id=\""
    + escapeExpression(lambda((data && data.id), depth0))
    + "\" name=\""
    + escapeExpression(lambda((data && data.id), depth0))
    + "\" size=\""
    + escapeExpression(((helper = (helper = helpers.size || (depth0 != null ? depth0.size : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"size","hash":{},"data":data}) : helper)))
    + "\" maxlength=\""
    + escapeExpression(((helper = (helper = helpers.maxLength || (depth0 != null ? depth0.maxLength : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"maxLength","hash":{},"data":data}) : helper)))
    + "\" ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.changeEvent : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " class=\"number ";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.errorAnchor : depth0), {"name":"each","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\" data-decimalPlace=\""
    + escapeExpression(((helper = (helper = helpers.decimalPlace || (depth0 != null ? depth0.decimalPlace : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"decimalPlace","hash":{},"data":data}) : helper)))
    + "\">";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}