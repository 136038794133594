var View = require('./view')
    , template = require('./templates/site');
var AjaxSpinner = require('lib/ajaxspinner_helper');
var Menu = require('models/menu');
var routes = require('routes');
var LinkHelper = require('lib/link_helper');

module.exports = View.extend({

    id: 'site',
    el: 'body',
    template: template,
    containerMethod: 'html',
    regions: {
        'sidebar': '#sidebar-container',
        'content': '#content-container',
        'navbar': '#nav-main-container',
        'footer': '#footer-container',
        'alertify': '#alertify .alertify-message'
    },

    events: {
        'click #renewforms': 'renewforms',
        'click .renewforms': 'renewforms',
        'click #renewpages': 'renewpages',
        'keyup #globalQuickSearch': 'submitOnEnter',
        //'mousedown #suggest_results': 'submitOnClick',
        'click #startGlobalQuickSearch': 'startQuickSearch',
        'click #showAllTitles': 'showAllTitles',
        'click a[data-toggle="tab"]': 'refreshTabContent'
    },

    refreshTabContent: function (e) {
        var string = $(e.currentTarget).attr('href').replace('#','');
        var lastIndexOf = string.lastIndexOf('-');
        var tab = string.substring(0,lastIndexOf);
        Chaplin.mediator.publish('tabContentRefresh', tab);
    },

    getTemplateData: function () {
        var data = View.prototype.getTemplateData.call(this);
        var menu = new Menu({id: 'main'});
        menu.fetch();
        data.model = menu.attributes;
        var showAllTitles = new Menu({id: 'showAllTitles'});
        showAllTitles.fetch();
        if (showAllTitles.get('entry').length > 0) {
            data.model.showAllTitles = showAllTitles.get('entry')[0];
        }

        if (data.model.entry.length > 1) {
            data.model.singleEntry = false;
        } else {
            data.model.singleEntry = true;
        }

        data.suggestServer = suggestServer;
        suggestAuthorities = ['ADMIN', 'BOOKSTORE', 'MVB'];
        var suggestAllowed = false;
        for (var i = 0; i < user.authorities.length; i++) {
            if($.inArray(user.authorities[i], suggestAuthorities) > -1) {
                suggestAllowed = true;
                break;
            }
        }

        data.suggestAllowed = suggestAllowed;

        data.versionCountry = versionCountry;

        return data;
    },

    renewforms: function () {
        AjaxSpinner.show('Form Definitions');
        $.ajax({
            url: "form/load",
            success: function () {
                window.location.reload();
            }
        });
        return false;
    },
    renewpages: function () {

        AjaxSpinner.show('Page Definitions');
        $.ajax({
            url: "page/load",
            success: function () {
                window.location.reload();
            }
        });

        return false;
    },

    submitOnEnter: function (event) {
        if (event.keyCode === 13) {
            this.startQuickSearch(event);
        }
    },

    submitOnClick: function (event) {
    	this.startQuickSearch(event);
    },

    startQuickSearch: function (event) {
        event.preventDefault();

        /* reset table */
        Chaplin.mediator.publish('executedNewSearch');

        $('#globalQuickSearch').focus();
        $('#suggest_results').hide();

        var val = $('#globalQuickSearch').val();
        if (val !== undefined && val.length > 0) {
            //Chaplin.mediator.publish('quickSearchPublishPoint');
            route = Backbone.history.fragment;

            // force reload, if needed --> that is rather dirty...
            if (route === 'search/quicksearch/startQuickSearch/' + encodeURI(val)) {
                Backbone.history.navigate(route);
                Backbone.history.loadUrl(route + "?forceReload=true");
            }
            else {
                Chaplin.mediator.publish("redirectTo", {
                    url: 'search_quicksearch',
                    data: {search: LinkHelper.encodeURI($('#globalQuickSearch').val()), action: 'startQuickSearch'}
                });
            }
        }
    },

    showAllTitles: function (event) {
        event.preventDefault();

        /* reset table */
        Chaplin.mediator.publish('executedNewSearch');
        Chaplin.mediator.publish('quickSearchPublishPoint');
        Chaplin.mediator.publish("redirectTo", {
            url: 'search_quicksearch',
            data: {search: '', action: 'showAllTitles'}
        });
    },

    attach: function () {
        //lightbox
        if (typeof $(".lightbox").fancybox == "function") {
            $(".lightbox").fancybox();

            $('.myfancybox-close').click(function () {
                $.fancybox.close();
            });
        }

        $('[data-show-menu-title="true"]').click(function () {
            var _menu = $(this).parent().find('.settings-menu');
            $('.settings-menu').filter(function (a, b) {
                return _menu.get(0) != b;
            }).hide();
            _menu.toggle();
            return false;
        });

        // collapsable header
        new Headroom(document.querySelector('#header')).init();
    }

});