var Application = require('application');
var View = require('./view');
var TabbedGroupWidgetTemplate = require('./templates/tabbed_widgetGroup');
var MsgHelper = require('lib/msg_helper');
var MiscHelper = require('lib/misc_helper');
var FormDefinition = require('models/formDefinition');
var FormHelper = require('lib/form_helper');
var RenderHelper = require('lib/renderer_helper');
var DownloadHelper = require('lib/download_helper');


this.logger = log4javascript.getLogger('views.WidgetGroupView');

module.exports = WidgetGroupView = View.extend({

	className : '',
	tagName : 'div',
	region: 'content',
	containerMethod : 'html',
	autoRender : true,

	initialize : function(options) {
		View.prototype.initialize.apply(this, [options]);
		
		var widgets = this.options.widgetDef['widgets'];
		for (var i = 0; i < widgets.length; i++) {
			var widget = widgets[i];
			this.registerRegion('widget-' + widget.id, '#widget-' + widget.id);
		}
	},
	
	getTemplateData : function() {
		return this.options.widgetDef;
	},
		
	getTemplateFunction : function() {
		return TabbedGroupWidgetTemplate;	
	},
	
	disposeWidget : function(widget, index) {
		if (widget.view !== undefined) {
			widget.view.dispose();
		}
	},
	
	dispose : function() {
		if (this.disposed) {
			return;
		}
		_.each(this.options.widgetDef['widgets'], this.disposeWidget, this);
		View.prototype.dispose.apply(this);
	},
	
    attach: function () {
        View.prototype.attach.call(this, arguments);
        
        $('[role="tablist"] a').click(function (e) {
        	  e.preventDefault();
        	  $(this).tab('show');
       	});

        return this;
    }
});

