//
var Controller = require('./controller');
var FormView = require('views/form_view');
var ListView = require('views/list_view');
var FormDefinition = require('models/formDefinition');
var PageContentModel = require('models/pagecontent');
var AjaxSpinner = require('lib/ajaxspinner_helper');
var MailView = require("views/mail_view");
var MiscHelper = require('lib/misc_helper');

var ModelController = Controller.extend({
    initialize: function (options) {
        this.logger = log4javascript.getLogger('controllers.ModelController');
    },

    getNewModel: function (params) {
        return new PageContentModel(params);
    },

    getModelKeyForForm: function (model) {
        return model.modelKey;
    },

    create: function (params) {
        AjaxSpinner.hide();
        AjaxSpinner.show();
        this.model = this.getNewModel(params);

        this.constructFormView('/create');
        Chaplin.mediator.subscribe(this.model.modelKey + 'Saved', this.modelSaved, this);
        Chaplin.mediator.subscribe(this.model.modelKey + 'SavedAndEdit', this.modelSavedAndEdit, this);
        Chaplin.mediator.subscribe(this.model.modelKey + 'SavedAndBack', this.modelSavedAndBack, this);
        Chaplin.mediator.subscribe(this.model.modelKey + 'SavedAndMmo', this.modelSavedAndMmo, this);
        Chaplin.mediator.subscribe(this.model.modelKey + 'SavedAndSendCredentials', this.modelSavedAndSendCredentials, this);
    },

    show: function (params) {
        AjaxSpinner.hide();
        AjaxSpinner.show();
        this.model = this.getNewModel(params);
        this.model.fetch();

        this.constructFormView('/show', params);
        Chaplin.mediator.subscribe(this.model.modelKey + 'List', this.modelList, this);
    },

    show_rec: function (params) {
        AjaxSpinner.hide();
        AjaxSpinner.show();
        if (params.record !== undefined && (typeof params.record).toLowerCase() === 'string' && params.record.indexOf('/') > 0) {
            var tmp = params.record.split('/');
            params.record = tmp[0];
            params.clickedInList = tmp[1];
        }
        this.model = this.getNewModel(params);
        if (params.content !== undefined) {
            for (var attr in params.content) {
                this.model.set(attr, params.content[attr]);
            }
        } else {
            this.model.fetch();
        }

        this.constructFormView('/show', params);
        Chaplin.mediator.subscribe(this.model.modelKey + 'List', this.modelList, this);
    },

    copy: function (params) {
        AjaxSpinner.hide();
        AjaxSpinner.show();
        var origModel = this.getNewModel(params);
        origModel.fetch();

        params.id = undefined;
        params.origType = origModel.get('type');
        this.model = this.getNewModel(params);

        // copy all attributes
        this.model.attributes = origModel.attributes;

        if (this.clearCopy !== undefined) {
            this.clearCopy(params);
        } else {
            // model specific attributes
            if (this.model.modelKey === "user") {
                this.model.attributes.id = undefined;
                this.model.attributes.password = null;
                this.model.attributes.loginMgmUserId = null;
                this.model.attributes.tokens = null;
                this.model.attributes.user.id = undefined;
                this.model.attributes.user.version = undefined;
                this.model.attributes.user.activationDate = null;
                this.model.attributes.user.loginName = null;
                this.model.attributes.user.lastLogin = null;
                this.model.attributes.user.firstLogin = null;
                this.model.attributes.user.credentialsSent = null;
                this.model.attributes.user.deactivationDate = null;
                for (var gpm in this.model.attributes.user.groupMappings) {
                    this.model.attributes.user.groupMappings[gpm].id = null;
                }
            }
        }

        this.constructFormView('/create', params);
        Chaplin.mediator.subscribe(this.model.modelKey + 'Saved', this.modelSaved, this);
        Chaplin.mediator.subscribe(this.model.modelKey + 'SavedAndEdit', this.modelSavedAndEdit, this);
        Chaplin.mediator.subscribe(this.model.modelKey + 'SavedAndBack', this.modelSavedAndBack, this);
        Chaplin.mediator.subscribe(this.model.modelKey + 'SavedAndMmo', this.modelSavedAndMmo, this);
        Chaplin.mediator.subscribe(this.model.modelKey + 'SavedAndSendCredentials', this.modelSavedAndSendCredentials, this);
    },

    edit: function (params) {
        AjaxSpinner.hide();
        AjaxSpinner.show();
        this.model = this.getNewModel(params);
        this.model.fetch();

        this.constructFormView('/edit', params);
        Chaplin.mediator.subscribe(this.model.modelKey + 'Saved', this.modelSaved, this);
        Chaplin.mediator.subscribe(this.model.modelKey + 'SavedAndEdit', this.modelSavedAndEdit, this);
        Chaplin.mediator.subscribe(this.model.modelKey + 'SavedAndBack', this.modelSavedAndBack, this);
        Chaplin.mediator.subscribe(this.model.modelKey + 'SavedAndMmo', this.modelSavedAndMmo, this);
        Chaplin.mediator.subscribe(this.model.modelKey + 'Cancelled', this.modelCancelled, this);
    },

    list: function (params, options) {
        AjaxSpinner.hide();
        AjaxSpinner.show();
        this.model = this.getNewModel(params);
        this.formDef = new FormDefinition({id: this.getModelKeyForForm(this.model) + '/list'});
        this.view = new ListView({model: this.model, formDef: this.formDef, batch: params.batch});
        this.formDef.fetch();
    },

    modelList: function () {
        this.redirectTo(this.model.modelKey + '_list');
    },

    modelSaved: function (id) {
        try {
            if (this.model.modelKey === 'product' && sessionStorage.getItem('form-view-paginator') !== undefined) {
                this.redirectTo(this.model.modelKey + '_show_rec', [id, sessionStorage.getItem('form-view-paginator')]);
            } else {
                this.redirectTo(this.model.modelKey + '_show', [id]);
            }
        } catch (err) {
            this.logger.error("Model saved with errors.", err);
            this.redirectTo(this.model.modelKey + '_list', [id]);
        }
    },

    modelSavedAndBack: function (id) {
        if (this.model.modelKey === 'user') {
            this.redirectTo('account_list', [id]);
        } else {
            this.redirectTo(this.model.modelKey + '_list', [id]);
        }
    },

    modelSavedAndEdit: function (id) {
        try {
            this.redirectTo(this.model.modelKey + '_edit', [id]);
        } catch (err) {
            this.redirectTo(this.model.modelKey + '_list', [id]);
        }
    },

    modelSavedAndMmo: function (id) {
        if (!MiscHelper.isEmpty(this.model.get('copyOfThisProduct'))) {
            var copy = false;
            if (this.model.get('mediaFiles') !== undefined && this.model.get('mediaFiles').length > 0) {
                copy = true;
            }
            if (copy === false && this.model.get('texts') !== undefined) {
                var texts = this.model.get('texts');
                for (var i = 0; i < texts.length; i++) {
                    if (texts[i] !== null && !MiscHelper.isEmpty(texts[i].assetFileId)) {
                        copy = true;
                        break;
                    }
                }
            }
            if (copy === true) {
                this.redirectTo(this.model.modelKey + '_editassets_copy', [id, 'copy']);
            } else {
                this.redirectTo(this.model.modelKey + '_editassets', [id]);
            }
        } else {
            this.redirectTo(this.model.modelKey + '_editassets', [id]);
        }
    },

    modelSavedAndSendCredentials: function (id) {
        try {
            this.redirectTo(this.model.modelKey + '_showAndSendCredentials', [id]);
        } catch (err) {
            this.redirectTo(this.model.modelKey + '_list', [id]);
        }
    },

    modelCancelled: function (id) {
        this.redirectTo(this.model.modelKey + '_show', [id]);
    },

    constructFormView: function (viewType, params) {
        this.formDef = new FormDefinition({id: this.getModelKeyForForm(this.model) + viewType});
        this.view = new FormView({model: this.model, formDef: this.formDef, routeParams: params});
        Chaplin.mediator.subscribe(this.model.modelKey + 'List', this.modelList, this);
        this.formDef.fetch();
    }


});

module.exports = ModelController;