var errorClass = 'vlx_error';
var warningClass = 'vlx_warning';
var MiscHelper = require('lib/misc_helper');

var MsgHelper = function () {
};

MsgHelper.prototype.formatMessage = function () {
    // The message key the format items (e.g. "{0}")
    // will and always has to be the first argument.
    var theString = this.getMessage(arguments[0]);
    if (arguments.length > 1) {
        theString = this.format(theString, arguments[1]);
    }
    return theString;
};

MsgHelper.prototype.format = function () {
    // The string containing the format items (e.g. "{0}")
    // will and always has to be the first argument.
    var theString = arguments[0];

    // start with the second argument (i = 1)
    for (var i = 0; i < arguments[1].length; i++) {
        // "gm" = RegEx options for Global search (more than one instance)
        // and for Multiline search
        var regEx = new RegExp("\\{" + (i) + "\\}", "gm");
        theString = theString.replace(regEx, arguments[1][i]);
    }

    return theString;
};

MsgHelper.prototype.showMsgOnField = function (field, msg, isWarning) {
    if (field.indexOf('tr[data-id') !== -1) {
        var parent = $(field + " td .control-group .controls");
        var controlGroup;
        if (parent.hasClass('control-group')) {
            controlGroup = parent;
        } else {
            controlGroup = parent.parents(".control-group");
        }
        if (isWarning === true) {
            controlGroup.addClass('warning');
        } else {
            controlGroup.addClass('error');
        }
        MsgHelper.prototype.buildOrAppendMsgSpan.call(this, parent, msg, isWarning);
        $(field).removeClass("hidden");
    } else {
        MsgHelper.prototype.showMsgOnFormField.call(this, field, msg, isWarning);
    }
};

MsgHelper.prototype.buildOrAppendMsgSpan = function (parent, msg, isWarning) {
    var msgBox = parent.find(".help-inline");
    var appendSpan = true;
    var msgClass = errorClass;
    if (isWarning === true) {
        msgClass = warningClass;
    }
    if (msgBox.length) {
        // there exists an empty helpline.
        if (msgBox.length === 1 && msgBox[0].classList.length === 1) {
            msgBox[0].classList.add(msgClass);
            $(msgBox[0]).html(msg);
            appendSpan = false;
        } else {
            for (var i = 0; i < msgBox.length; i++) {
                if (msgBox[i].classList.contains(msgClass)) {
                    if ($(msgBox[i]).html().length === 0) {
                        $(msgBox[i]).html(msg);
                    } else if($(msgBox[i]).html().indexOf(msg) < 0)  {
                        $(msgBox[i]).html($(msgBox[i]).html() + "<br>" + msg);
                    }
                    appendSpan = false;
                }
            }
            if (appendSpan === true) {
                var msgBoxParent = $(msgBox[i-1]).parent();
                //msgBoxParent.append('<br/>');
                var msgSpan = jQuery('<span/>', {
                    'html': msg,
                    'class': 'help-inline ' + msgClass
                });
                msgBoxParent.append(msgSpan);
                appendSpan = false;
            }
        }
    }
    if (appendSpan) {
        var msgSpan = jQuery('<span/>', {
            'html': msg,
            'class': 'help-inline ' + msgClass
        });
        parent.append(msgSpan);
    }

};

MsgHelper.prototype.showMsgOnFormField = function (field, msg, isWarning) {
    for(var i=0; i<$(field).length; i++) {
        var parent = $($(field)[i]).parent();
        var controlGroup;
        if (parent.hasClass('control-group')) {
            controlGroup = parent;
        } else if (parent.hasClass('control-group-row')) {
            controlGroup = parent;
        } else {
            controlGroup = parent.closest(".control-group");
        }
        if (isWarning === true) {
            controlGroup.addClass('warning');
        } else {
            controlGroup.addClass('error');
        }

        MsgHelper.prototype.buildOrAppendMsgSpan.call(this, parent, msg, isWarning);
    }
};

MsgHelper.prototype.removeAllMsgsOnFields = function () {
    $("." + errorClass).not("tr").each(function () {
        if (this.classList.contains('help-inline')) {
            while (this.firstChild) {
            	 this.removeChild(this.firstChild);
            }
        } else {
        	 $(this).remove();
        }
    });
    $("." + warningClass).not("tr").each(function () {
        if (this.classList.contains('help-inline')) {
            while (this.firstChild) {
            	 this.removeChild(this.firstChild);
            }
        } else {
        	 $(this).remove();
        }
    });

    $("tr ." + errorClass).each(function () {
        if($(this).parents('tr.' + errorClass).length === 0) {
            $(this).addClass('hidden');
        }
    });
    $("tr ." + warningClass).each(function () {
        if($(this).parents('tr.' + errorClass).length === 0) {
            $(this).addClass('hidden');
        }
    });
    $("tr." + errorClass).addClass("hidden");
    $("tr." + warningClass).addClass("hidden");

    $('.control-group').removeClass('error');
    $('.control-group').removeClass('warning');
};

MsgHelper.prototype.removeAllErrorMsgsOnFields = function () {
    $("." + errorClass).each(function () {
        if (this.classList.contains('help-inline')) {
            while (this.firstChild) {
            	 this.removeChild(this.firstChild);
            }
        } else {
        	 $(this).remove();
        }
    });
    $("." + warningClass).each(function () {
        if (this.classList.contains('help-inline')) {
            while (this.firstChild) {
            	 this.removeChild(this.firstChild);
            }
        } else {
        	 $(this).remove();
        }
    });
    $('.control-group').removeClass('error');
};

MsgHelper.prototype.clearAlerts = function () {
    $('#alertBox').empty();
};

MsgHelper.prototype.showErrorAlert = function (msg, isWarning) {
    var msgClass = 'alert-danger ' + errorClass;
    if (isWarning === true) {
        msgClass = 'alert-warning ' + warningClass;
    }
    var alertDiv = jQuery('<div/>', {
        'html': '<button type="button" class="close" data-dismiss="alert">&times;</button>' + msg,
        'class': 'alert ' + msgClass
    });

    $('#alertBox').append(alertDiv);
};

MsgHelper.prototype.showSuccessAlert = function (msg) {
    var alertDiv = jQuery('<div/>', {
        'html': '<button type="button" class="close" data-dismiss="alert">&times;</button>' + msg,
        'class': 'alert alert-success'
    });

    $('#alertBox').append(alertDiv);
};

MsgHelper.prototype.setMessage = function (key, msg) {
    messages[key] = msg;
};

MsgHelper.prototype.getMessage = function (key) {
    if (key in messages) {
        return messages[key];
    }
    return key;
};

MsgHelper.prototype.replaceMessagePlaceHolderWithString = function (message, placeHolder, replaceString){
	var regex = new RegExp('{' + this.escapeRegexp(placeHolder) + '}', "ig");
    return message.replace(regex, replaceString);;
};

MsgHelper.prototype.escapeRegexp = function(text) {
	  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

MsgHelper.prototype.replaceMessagePropertyYearPlaceHolder = function (stringWithYearPlaceHolder){
	var theReplacedMessage = stringWithYearPlaceHolder;
	var regex = "{year(?:(?:([+\-])([0-9])+)?(inv)?)?}";
	var date = new Date();
    var year = date.getFullYear();
    var replacements = MiscHelper.getReplacements(regex, theReplacedMessage);
    for(replacement in replacements){
    	var replacementParams = replacements[replacement];
		if(replacementParams == null){
		    theReplacedMessage = this.replaceMessagePlaceHolderWithString(theReplacedMessage, replacement.substring(1, replacement.length - 1), year);
		}else{
			var displayedYear = year;
			var invoice = replacements[replacement]["param1"];
			if(invoice !== undefined && invoice === "inv" ){
				displayedYear = this.getDisplayedYearForInvoice(date, 10, displayedYear, year);
			}else{
				var operator = replacements[replacement]["param1"];
		    	var yearsToAdd = replacements[replacement]["param2"];
		    	invoice = replacements[replacement]["param3"];
				if(operator === "-"){
					displayedYear = parseInt(year)- parseInt(yearsToAdd);
				}else if(operator === "+"){
					displayedYear = parseInt(year)+ parseInt(yearsToAdd);
				}
				if(invoice !== undefined){
					displayedYear = this.getDisplayedYearForInvoice(date, 10, displayedYear, year);
				}
			}
			theReplacedMessage = this.replaceMessagePlaceHolderWithString(theReplacedMessage, replacement.substring(1, replacement.length - 1), displayedYear);
		}
    }
    return theReplacedMessage;
};

MsgHelper.prototype.getDisplayedYearForInvoice = function(date, invoiceMonth, displayedYear, year){
	var month = date.getMonth();
	if(month >= invoiceMonth){
		if(displayedYear < year || displayedYear == year){
			return displayedYear + 1;
		}else if(displayedYear > year){
			return displayedYear - 1;
		}
	}
	return displayedYear;
};

MsgHelper.prototype.getMessageOrDefault = function (key, defaultText) {
    if (key in messages) {
        return messages[key];
    }
    return defaultText;
};

/**
 * get a list of labels that contain the given key
 * @param key the key to match with message list
 * @returns an array containing all messages with the rest of the original key as key
 *            (e.g. key= label.form.collections.test. and found match = label.form.collections.test.match
 *             then the new key would be "match")
 */
MsgHelper.prototype.getMessageList = function (key) {
    var matches = [];
    var suffix = ".";
    if (key.indexOf(suffix, key.length - suffix.length) === -1) {
        key = key + suffix;
    }

    for (var msg in messages) {
        if (msg.indexOf(key) === 0) {
            matches[msg.substring(key.length)] = messages[msg];
        }
    }

    return matches;
};

MsgHelper.prototype.getPropertyMessage = function (key, props) {
    if (key in messages) {
        var msg = messages[key];
        for (var propkey in props) {
            msg = msg.replace(propkey, props[propkey]);
        }
        return msg;
    }
    return key;
};

/**
 * function to generate the frequency message for journals
 * @param listtext - text from codelist
 * @param code - code from codelist
 * @param text - additional text, mainly for irregular frequencies
 */
MsgHelper.prototype.getZisFrequencyMessage = function (listtext, code, text) {
	if(code === '99' && !MiscHelper.isEmpty(text)){
		listtext += ', ' + text;
	}
	return listtext;
}

module.exports = new MsgHelper;