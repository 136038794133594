var Collection = require('./collection');

// Base class for models with oauth2 access_token send in fetch
module.exports = Collection.extend({

	sync: function (method, model, options) {
    	var theOptions = options ? options : {};
        
    	if(!("headers" in theOptions)) {
    		theOptions['headers'] = {};
    	}
        theOptions['headers']['Authorization'] = "Bearer " + user.access_token;
        return Collection.prototype.sync.call(this, method, model, theOptions);
    },
});
