var MsgHelper = require('lib/msg_helper');
var View = require('./view')
var CoverTemplate = require('./templates/forms/cover');
var NumberWidget = require('./forms/widgets/number');

var Model = require('models/model_with_auth');
var Collection = require('models/collection_with_auth');
var FormDefinition = require('models/formDefinition');
var ListView = require('./list_view');

var CoverView;

module.exports = CoverView = View.extend({

    LOGGER: log4javascript.getLogger('views.CoverView'),

    container: '#fieldSet_cover',
    autoRender: true,
    autoAttach: false,
    className: 'coverView',


    initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);
    },

    getTemplateData: function () {
        var data = View.prototype.getTemplateData.call(this);

        var ids = this.model.get('identifiers');
        var ean = null;
        if(ids !== undefined) {
            for (var i = 0; i < ids.length; i++) {
                if (ids[i].type === '03') {
                    ean = ids[i].value;
                    break;
                }
            }
        }
        data.src = apiRoot + "cover/" + ean + '/m';
        data.alt = this.model.get('title');
        if (versionCountry === 'brasil' || versionCountry === 'latam') {
            data.onerror = mediaRoot + "/images/dummycover/cover_mb_missing.png";
        } else {
            data.onerror = mediaRoot + "/images/dummycover/cover_vlb_missing.png";
        }
        if (data.src != undefined && data.src.indexOf(apiRoot) != -1) {
            data.src += '?access_token=' + user.access_token;
        }
        return data;
    },
    getTemplateFunction: function () {
        return CoverTemplate;
    },
    render: function () {
        View.prototype.render.call(this);
    },
    attach: function () {
        View.prototype.attach.call(this);
    }
});