var ConfirmationSimple = require('views/templates/forms/confirmationSimple');
var ConfirmationOpenDialogHelper = require('lib/confirmation_open_dialog_helper');
var FormCollectionAuth = require('models/formCollectionAuth');
var MsgHelper = require('lib/msg_helper');

var IGNORE_MESSAGE_FLAG = 'ignore_message_flag';

var MessageSelectorHelper = function () {
};

MessageSelectorHelper.prototype.findIndexById = function (listProp, id) {
    for (var i = 0; i < listProp.length; i++) {
        // The future is "headPriceRecordID". That attribute name ist much mor precise than that simple id.
        if (listProp[i] !== null && (listProp[i].id === id
            || listProp[i].headPriceRecordID === id
            || listProp[i].containedItemID === id
            || listProp[i].containedItemRecordID === id)) {
            return i;
        }
    }
    return null;
};

MessageSelectorHelper.prototype.findBundlePricePeriodIndexById = function (model, id, aPriceType) {

    var bundles;
    var index;
    var retVal = {};
    var priceType = aPriceType;
    if (model.get('type') === 'multiBundle') {
        bundles = model.get('multiBundles');
    } else if (model.get('type') === 'duoBundle') {
        bundles = model.get('duoBundles');
    } else if (model.get('type') === 'journal') {
        if (priceType === undefined) {
            if (model.get('bundleswitch') === 'identicTax') {
                bundles = model.get('pricesJournal');
            } else {
                bundles = model.get('pricesJournalBundle');
            }
        } else {
            // Remove me after been checked.
            if (priceType === 'priceDuoBundle') {
                bundles = model.get('pricesJournalBundle');
            } else {
                bundles = model.get('pricesJournal');
            }
        }
    }
    index = MessageSelectorHelper.prototype.findIndexById.call(this, bundles, id);
    retVal.index = index;
    return retVal;
};

MessageSelectorHelper.prototype.findBundlePriceIndexById = function (model, id) {

    var bundles;
    var index;
    var retVal = {};
    var priceParts;
    if (model.get('type') === 'multiBundle') {
        bundles = model.get('multiBundles');
        priceParts = 'bundleParts';
    } else if (model.get('type') === 'duoBundle') {
        bundles = model.get('duoBundles');
        priceParts = 'bundleParts';
    } else if (model.get('type') === 'journal') {
        bundles = model.get('pricesJournalBundle');
        priceParts = 'priceParts';
    }
    for (var i = 0; i < bundles.length; i++) {
        var bundleParts = bundles[i][priceParts];
        index = MessageSelectorHelper.prototype.findIndexById.call(this, bundleParts, id);
        if (index != null) {
            retVal.i = i;
            break;
        }
    }
    retVal.index = index;
    return retVal;
};

MessageSelectorHelper.prototype.findPricePeriodIndexById = function (model, id) {

    var retVal = {};
    if (model.get('type') === 'multiBundle' || model.get('type') === 'duoBundle') {
        retVal = MessageSelectorHelper.prototype.findBundlePricePeriodIndexById.call(this, model, id);
        retVal.type = model.get('type');
    } else if (model.get('type') === 'journal') {
        retVal = MessageSelectorHelper.prototype.findBundlePricePeriodIndexById.call(this, model, id);
        retVal.type = model.get('bundleswitch') === 'identicTax' ? 'pricesJournal' : 'pricesJournalBundle';
    } else {
        var index = MessageSelectorHelper.prototype.findIndexById(model.get('retailPrices'), id);
        var price = 'retailPrices';
        if (index == null) {
            index = MessageSelectorHelper.prototype.findIndexById(model.get('specialPrices'), id);
            price = 'specialPrices';
        }
        if (index == null) {
            index = MessageSelectorHelper.prototype.findIndexById(model.get('foreignPrices'), id);
            price = 'foreignPrices';
        }
        if (index != null) {
            retVal.index = index;
            retVal.type = price;
        }
    }

    return retVal;
};

MessageSelectorHelper.prototype.findPriceIndexById = function (model, id) {

    var retVal = {};
    if (model.get('type') === 'multiBundle' || model.get('type') === 'duoBundle' || model.get('type') === 'journal') {
        retVal = MessageSelectorHelper.prototype.findBundlePriceIndexById.call(this, model, id);
        retVal.type = model.get('type');
    } else {
        var index = MessageSelectorHelper.prototype.findIndexById(model.get('retailPrices'), id);
        var price = 'retailPrices';
        if (index == null) {
            index = MessageSelectorHelper.prototype.findIndexById(model.get('specialPrices'), id);
            price = 'specialPrices';
        }
        if (index == null) {
            index = MessageSelectorHelper.prototype.findIndexById(model.get('foreignPrices'), id);
            price = 'foreignPrices';
        }
        if (index != null) {
            retVal.index = index;
            retVal.type = price;
        }
    }

    return retVal;
};

MessageSelectorHelper.prototype.MDS103 = function (data, model) {

    var retVal;
    var parts = data.field.split(':');
    var position = MessageSelectorHelper.prototype.findPricePeriodIndexById(model, parts[2]);

    if (position.type === 'duoBundle') {
        retVal = '#block_priceDuoBundle .record_0.row_2 tr[data-id=1]';
    } else if (position.type === 'multiBundle') {
        var index = model.get('multiBundles.0.bundleParts').length - 1;
        retVal = '#block_priceMultiBundle .record_0.row_1 tr[data-id=' + index + ']';
    } else {
        retVal = '#' + position.type + ' tr[data-id="' + position.index + '"] ';
    }

    return retVal;
};

MessageSelectorHelper.prototype.MDS109 = function (data, model) {

    var retVal;
    var parts = data.field.split(':');
    // retVal = MessageSelectorHelper.prototype.findBundlePricePeriodIndexById.call(this, model, parts[2]);
    var position = MessageSelectorHelper.prototype.findPricePeriodIndexById(model, parts[2]);
    retVal = '#block_' + position.type + ' .record_' + position.index + '.row_1 tr[data-id="';
    retVal += parts[3] === 'taxRate' ? '0"]' : '1"]';

    return retVal;

};

MessageSelectorHelper.prototype.MDS129 = function (data, model) {
    var retVal;
    var parts = data.field.split(':');
    var position = MessageSelectorHelper.prototype.findPricePeriodIndexById(model, parts[2]);

    if (position.type === 'duoBundle') {
        retVal = '#block_priceDuoBundle .record_' + position.index + '.row_2 tr[data-id=1]';
    } else if (position.type === 'multiBundle') {
        var partPosition = model.get('multiBundles.0.bundleParts').length - 1;
        retVal = '#block_priceMultiBundle .record_' + position.index + '.row_1 tr[data-id=' + partPosition + ']';
    } else {
        retVal = '#' + position.type + ' tr[data-id="' + position.index + '"] ';
    }

    return retVal;
};

MessageSelectorHelper.prototype.MDS132 = function (data, model) {

    return '#block_priceDuoBundle .new-list-group';
};

MessageSelectorHelper.prototype.MDS166 = function (data, model) {

    if (model.get('availabilityChangeAccepted') !== true) {
        var scopeData = {};
        scopeData.text = MsgHelper.getMessage('label.form.confirmation.leadsToActivation');
        scopeData.ident = 'leadsToActivation';
        scopeData.noCloseCross = true;
        var html = ConfirmationSimple(scopeData);
        var confirmDialog = function (event) {
            model.set('availabilityChangeAccepted', true);
            $.fancybox.close();
            var div = $('#' + scopeData.ident + 'Confirmation');
            div.fadeOut('slow');
            div.remove();
        }
        var cancelDialog = function (event) {
            var value = model.get('availabilityRead');
            model.set('availability', value);

            var selectModel = new FormCollectionAuth({
                id: 65,
                'url_root': apiRoot + '/codelists/'
            });
            selectModel.fetch();
            var codes = selectModel.get('codes');
            var text = '';
            for (var i = 0; i < codes.length; i++) {
                if (codes[i].code === value) {
                    text = codes[i].description;
                    break;
                }
            }

            $('div.availability span div').text(text);
        }
        var options = {
            'closeOnClick': false,
        };
        ConfirmationOpenDialogHelper.openDialog(html, scopeData.ident, options, confirmDialog, cancelDialog);
    }

    return IGNORE_MESSAGE_FLAG;

};

MessageSelectorHelper.prototype.MDS167 = function (data, model, options) {

    if (model.get('availabilityChangeAccepted') !== true) {
        var scopeData = {};
        scopeData.text = MsgHelper.getMessage('label.form.confirmation.leadsToArchiving');
        scopeData.ident = 'leadsToArchiving';
        scopeData.noCloseCross = true;
        var html = ConfirmationSimple(scopeData);
        var confirmDialog = function (event) {
            model.set('availabilityChangeAccepted', true);
            $.fancybox.close();
            var div = $('#' + scopeData.ident + 'Confirmation');
            div.fadeOut('slow');
            div.remove();
        }
        var cancelDialog = function (event) {
            var value = model.get('availabilityRead');
            model.set('availability', value);

            var selectModel = new FormCollectionAuth({
                id: 65,
                'url_root': apiRoot + '/codelists/'
            });
            selectModel.fetch();
            var codes = selectModel.get('codes');
            var text = '';
            for (var i = 0; i < codes.length; i++) {
                if (codes[i].code === value) {
                    text = codes[i].description;
                    break;
                }
            }

            $('div.availability span div').text(text);
        }

        var options = {
            'closeOnClick': false,
        };
        ConfirmationOpenDialogHelper.openDialog(html, scopeData.ident, options, confirmDialog, cancelDialog);
    }

    return IGNORE_MESSAGE_FLAG;

};

MessageSelectorHelper.prototype.MDS272 = function (data, model) {
    var splits = data.field.split(':');
    var retVal;
    var position = MessageSelectorHelper.prototype.findPriceIndexById.call(this, model, splits[2]);
    if (model.get('type') === 'duoBundle') {
        retVal = '#block_priceDuoBundle .record_0.row_2 tr[data-id="' + position.index + '"]';
    } else if (model.get('type') === 'journal') {
        retVal = '#block_pricesJournalBundle .record_0.row_2 tr[data-id="' + position.index + '"]';
    }
    return retVal;
};

MessageSelectorHelper.prototype.bundlesZeroRow = function (data, model) {
    // cplx:containedItems:af9944cfaef347a38d3cfc93135705e3:type
    // cplx:containedItems:daa5e1cfeb30418cbacc2a923318448d:productIdentifierValue
    var splits = data.field.split(':');
    var retVal;
    var ciIdentifier = splits[2];
    var bundleParts;
    if (model.get('type') === 'multiBundle') {
        bundleParts = model.get('multiBundles.0.bundleParts');
    } else if (model.get('type') === 'duoBundle') {
        bundleParts = model.get('duoBundles.0.bundleParts');
    } else if (model.get('type') === 'journal') {
        // That message should only haben with bundles. Journals with different taxes are also bundles.
        if (model.get('bundleswitch') === 'duoTax') {
            bundleParts = model.get('pricesJournalBundle.0.priceParts');
        }
    }
    var index;
    for (var i = 0; i < bundleParts.length; i++) {
        // TODO containedItemRecordID is the new variant. Please refactor all the previously used pric structures and definitions.
        var containedItemRecordID = bundleParts[i].containedItemRecordID === undefined ? bundleParts[i].containedItemID : bundleParts[i].containedItemRecordID;
        if (containedItemRecordID === ciIdentifier) {
            index = i;
            break;
        }
    }

    if (model.get('type') === 'multiBundle') {
        retVal = '#block_priceMultiBundle .record_0.row_1 tr[data-id="' + index + '"]';
    } else if (model.get('type') === 'duoBundle') {
        retVal = '#block_priceDuoBundle .record_0.row_2 tr[data-id="' + index + '"]';
    } else if (model.get('type') === 'journal') {
        // That message should only hapen with bundles. Journals with different taxes are also bundles.
        if (model.get('bundleswitch') === 'duoTax') {
            retVal = '#block_pricesJournalBundle .record_0.row_2 tr[data-id="' + index + '"]';
        }
    }
    return retVal;

};

MessageSelectorHelper.prototype.MDS507 = function (data, model) {
    var splits = data.field.split(':');
    var retVal;
    var position = MessageSelectorHelper.prototype.findPriceIndexById.call(this, model, splits[2]);
    if (model.get('type') === 'duoBundle') {
        retVal = '#block_priceDuoBundle .record_0.row_2 tr[data-id="' + position.index + '"]';
    } else if (model.get('type') === 'journal') {
        retVal = '#block_pricesJournalBundle .record_0.row_2 tr[data-id="' + position.index + '"]';
    }
    return retVal;
};

MessageSelectorHelper.prototype.MDS509 = function (data, model) {

    var retVal;
    var splits = data.field.split(':');
    var position = MessageSelectorHelper.prototype.findBundlePriceIndexById.call(this, model, splits[2]);
    if (model.get('type') === 'multiBundle') {
        retVal = '#block_priceMultiBundle .record_' + position.i + '.row_1 tr[data-id="' + position.index + '"]';
    } else if (model.get('type') === 'duoBundle') {
        retVal = '#block_priceDuoBundle .record_' + position.i + '.row_2 tr[data-id="' + position.index + '"]';
    }
    return retVal;
};

MessageSelectorHelper.prototype.MDS510 = function (data, model) {

    var splits = data.field.split(':');
    var retVal;
    if (model.get('type') === 'duoBundle') {
        var position = MessageSelectorHelper.prototype.findBundlePricePeriodIndexById.call(this, model, splits[2]);
        retVal = '#block_priceDuoBundle .record_' + position.index + '.row_2 tr[data-id="';
        retVal += splits[3] === 'taxAmount' ? '0"]' : '1"]';
    } else if (model.get('type') === 'journal') {
        var position = MessageSelectorHelper.prototype.findBundlePricePeriodIndexById.call(this, model, splits[2]);
        retVal = '#block_pricesJournalBundle .record_' + position.index + '.row_2 tr[data-id="';
        retVal += splits[3] === 'taxAmount' ? '0"]' : '1"]';
    }
    return retVal;
};

MessageSelectorHelper.prototype.MDS512 = function (data, model) {

    // cplx:priceDuoBundle:d89e91cac8804701b77a6b486cf88947:taxAmount
    var splits = data.field.split(':');
    var retVal;
    var position = MessageSelectorHelper.prototype.findBundlePriceIndexById.call(this, model, splits[2]);
    if (model.get('type') === 'multiBundle') {
        retVal = '#block_priceMultiBundle .record_' + position.i + '.row_1 tr[data-id="' + position.index + '"]';
    }
    return retVal;
};

MessageSelectorHelper.prototype.MDS521 = function (data, model) {

    // cplx:prices:4ab0b630baf0427db0da97abe327435c
    var splits = data.field.split(':');
    var retVal;
    var position = MessageSelectorHelper.prototype.findPriceIndexById.call(this, model, splits[2]);
    if (model.get('type') === 'duoBundle') {
        retVal = '#block_priceDuoBundle .record_0.row_2 tr[data-id="' + position.index + '"]';
    } else if (model.get('type') === 'journal') {
        retVal = '#block_pricesJournalBundle .record_0.row_2 tr[data-id="' + position.index + '"]';
    }
    return retVal;
};

MessageSelectorHelper.prototype.MDS523 = function (data, model) {

    // cplx:prices:4ab0b630baf0427db0da97abe327435c
    var splits = data.field.split(':');
    var retVal;
    var position = MessageSelectorHelper.prototype.findPriceIndexById.call(this, model, splits[2]);
    if (model.get('type') === 'duoBundle') {
        retVal = '#block_priceDuoBundle .record_0.row_2 tr[data-id="' + position.index + '"]';
    } else if (model.get('type') === 'journal') {
        retVal = '#block_pricesJournalBundle .record_0.row_2 tr[data-id="' + position.index + '"]';
    }
    return retVal;
};

MessageSelectorHelper.prototype.MDS524 = function (data, model) {

    var splits = data.field.split(':');
    var retVal;
    if (model.get('type') === 'duoBundle') {
        var position = MessageSelectorHelper.prototype.findBundlePricePeriodIndexById.call(this, model, splits[2]);
        retVal = '#block_priceDuoBundle .record_' + position.index + '.row_2 tr[data-id="';
        retVal += splits[3] === 'taxAmount' ? '0"]' : '1"]';
    } else if (model.get('type') === 'journal') {
        var position = MessageSelectorHelper.prototype.findBundlePricePeriodIndexById.call(this, model, splits[2]);
        retVal = '#block_pricesJournalBundle .record_' + position.index + '.row_2 tr[data-id="';
        retVal += splits[3] === 'taxAmount' ? '0"]' : '1"]';
    }
    return retVal;
};

MessageSelectorHelper.prototype.UI002 = function (data, model) {
    var splits = data.field.split(':');
    var list = model.get(splits[1]);
    var i = 0;
    var theIndex;
    if (list != undefined) {
        for (; i < list.length; i++) {
            if (list[i] !== null && list[i].id === splits[2]) {
                theIndex = i;
                break;
            }
        }
    }

    var theSelector = '#block_additionalInformation .texts_additionalInfoSwitch_' + theIndex + ' .controls';
    return theSelector;
};

MessageSelectorHelper.prototype.errorAnchor = function (data, model) {
    return '.error-' + data.code;
}

module.exports = new MessageSelectorHelper;