var ConfirmationHelper = require('lib/confirmation_helper');
var MsgHelper = require('../lib/msg_helper');
var View = require('./view')
var VgWortTemplate = require('./templates/forms/vg_wort');
var AjaxSpinner = require('lib/ajaxspinner_helper');

var VgWort;

module.exports = VgWort = View.extend({

    container: '#vg_wort',
    autoRender: true,
    autoAttach: false,
    className: 'vgWortView',


    initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);
    },

    getTemplateFunction: function () {
        return VgWortTemplate;
    },

    events: {
        'click #vgwortexport': 'exportReport'
    },

    getTemplateData: function () {
        var data = View.prototype.getTemplateData.call(this);
        return data;
    },

    render: function () {
        View.prototype.render.call(this);
    },

    attach: function () {
        View.prototype.attach.call(this);
        this.initFileupload();
        this.toggleImportButton();
    },

    initFileupload: function () {
    	Chaplin.mediator.subscribe('vgWortFileUploadSuccess', this.uploadSuccess, this);
    	Chaplin.mediator.subscribe('vgWortFileUploadError', this.uploadError, this);

    	$('#vgwort').fileupload({
    	    url: apiRoot + 'vgwort',
    	    headers: {
            	'Authorization' :"Bearer " + user.access_token
            },
            add: function (e, data) {
                AjaxSpinner.show(undefined, MsgHelper.formatMessage('label.form.assetUpload.uploading.message', [data.files[0].name]));
    	        var jqXHR = data.submit()
    	            .success(function(result, textStatus, jqXHR) {
                            AjaxSpinner.hide();
	    	            	MsgHelper.clearAlerts();
	    	            	Chaplin.mediator.publish('vgWortFileUploadSuccess', result, textStatus, jqXHR);
    	            	})
    	            .error(function (jqXHR, textStatus, errorThrown) {
                            AjaxSpinner.hide();
	    	            	MsgHelper.clearAlerts();
	    	            	Chaplin.mediator.publish('vgWortFileUploadError', jqXHR, textStatus, errorThrown);
    	            	});
    	    }
    	});

    },

    exportReport: function () {
        var localUrl = apiRoot + 'vgwort?access_token=' + user.access_token;
        ConfirmationHelper.downloadReport(localUrl);
        MsgHelper.showSuccessAlert(MsgHelper.getMessage('label.form.vgWort.exportInitialized'));
        $('#vgwortexport').addClass('disabled');
    },

    uploadSuccess: function(result, textStatus, jqXHR) {
        MsgHelper.showSuccessAlert(MsgHelper.getMessage('label.form.vgWort.uploadSuccess'));
    },

    uploadError: function(jqXHR, textStatus, errorThrown) {
        MsgHelper.showErrorAlert(MsgHelper.getMessage('label.form.vgWort.uploadFailed'));
    },

    toggleImportButton: function () {
        var importButton = $('#vg_wort_import');
        var checkbox = $('#vg_wort_terms');
        checkbox.on('change', function () {
            importButton.toggleClass('disabled', !checkbox[0].checked);
        });
        // $('#vg_wort_import').toggle('disabled', $('#vg_wort_terms').onchange);
    }
});
