var View = require('./view');
var QuickSearchTemplate = require('./templates/quicksearch');
var MsgHelper = require('lib/msg_helper');
var MiscHelper = require('lib/misc_helper');

var QuickSearchView;

module.exports = QuickSearchView = View.extend({

    id: 'quick-search-view',
    tagName: "form",
    className: "quickform form-horizontal",
    region: 'content',
    containerMethod: 'html',
    
    initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);
        this.options.query = this.model.get('query');
        this.modelBinder = new Backbone.ModelBinder();
        this.options.quickSearchInputId = "quickSearchInput";
        this.options.bindings = {'query': '#' + this.options.quickSearchInputId};

        this.logger = log4javascript.getLogger('views.QuickSearchView');
    },

    getTemplateFunction: function () {
        return QuickSearchTemplate;
    },

    getTemplateData: function () {
        var data = View.prototype.getTemplateData.call(this);
        data.quickSearchInputId = this.options.quickSearchInputId;
        data.quickSearchName = this.model.get('name');
        return data;
    },

    events: {
        'click #startquicksearch': 'startQuickSearch',
        'keyup #quickSearchInput': 'submitOnEnter',
        'keyup #quickSearchInput': 'showStoreSearchAsNewBtn',
        'submit': 'startQuickSearch',
        'click #storesearchasnew': 'storeSearchAsNew',
        'click #backtosearchhistory': 'backToSearchHistory'
    },

    listen: {
        
    },

    attach: function () {
    	View.prototype.attach.call(this);
        this.modelBinder.bind(this.model, this.el, this.options.bindings, {'changeTriggers': {'*': 'change'}});
        return this;
    },
    
    submitOnEnter: function(event) {
    	if(event.keyCode === 13) {
    		this.startQuickSearch(event);
        }
    },
    
    showStoreSearchAsNewBtn: function(event) {
    	if (!MiscHelper.isEmpty(this.model.get('name'))) {
    		if (this.options.query != $('#'+this.options.quickSearchInputId).val()) {
    			$('#storesearchasnew').show();
    		} else {
    			$('#storesearchasnew').hide();
    		}
    	}
    },
    
    startQuickSearch: function(event) {
    	event.preventDefault();
    	Chaplin.mediator.publish('quickSearchPublishPoint', this.model.get('id'), this.model.get('query'));
    },
    
    storeSearchAsNew: function(event) {
    	Chaplin.mediator.publish('quickSearchPublishPoint', undefined, this.model.get('query'));
    },
    
    backToSearchHistory: function(event) {
    	Chaplin.mediator.publish('backToSearchHistory');
    }
});