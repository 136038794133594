var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "    <div class=\"uiType-"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.uiType : stack1), depth0))
    + "\">\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<h3 class=\""
    + escapeExpression(((helper = (helper = helpers.modelKey || (depth0 != null ? depth0.modelKey : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"modelKey","hash":{},"data":data}) : helper)))
    + "-header contentpage-header\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.title : stack1), {"name":"lbl","hash":{},"data":data})))
    + "</h3>";
},"5":function(depth0,helpers,partials,data) {
  return "    <div id=\"modalBox\"></div>\n";
  },"7":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.hasRight || (depth0 && depth0.hasRight) || helperMissing).call(depth0, "!PUBLISHER_BASIC", {"name":"hasRight","hash":{},"fn":this.program(8, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"8":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "        <div class=\"form-navigation\">\n        <ul class=\"icon-nav-list icon-nav-list-top clearfix\">\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1)) != null ? stack1.SECONDARY : stack1), {"name":"if","hash":{},"fn":this.program(9, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1)) != null ? stack1.TERTIARY : stack1), {"name":"if","hash":{},"fn":this.program(12, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n        </ul>\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.usePaginator : depth0), {"name":"if","hash":{},"fn":this.program(15, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n        </div>\n";
},"9":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "";
  stack1 = helpers.each.call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1)) != null ? stack1.SECONDARY : stack1), {"name":"each","hash":{},"fn":this.program(10, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"10":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                    <li class=\"action-"
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n                        "
    + escapeExpression(((helpers.iconAction || (depth0 && depth0.iconAction) || helperMissing).call(depth0, depth0, (depths[2] != null ? depths[2].modelKey : depths[2]), (depths[2] != null ? depths[2].modelUrlRoot : depths[2]), ((stack1 = (depths[2] != null ? depths[2].model : depths[2])) != null ? stack1.id : stack1), ((stack1 = (depths[2] != null ? depths[2].model : depths[2])) != null ? stack1.id : stack1), {"name":"iconAction","hash":{},"data":data})))
    + "\n                    </li>\n";
},"12":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "                <li>\n                    <div class=\"settings-menu-wrap\">\n                        <a title=\""
    + escapeExpression(((helpers.msg || (depth0 && depth0.msg) || helperMissing).call(depth0, (depth0 != null ? depth0.dealWithNavTitle : depth0), {"name":"msg","hash":{},"data":data})))
    + "\" class=\"vlb-action-icon vlb-action-icon-input-settings-dd\"\n                           href=\"javascript:;\"\n                           onclick=\"return false;\" data-show-menu=\"true\">\n                            <span>"
    + escapeExpression(((helpers.msg || (depth0 && depth0.msg) || helperMissing).call(depth0, (depths[2] != null ? depths[2].dealWithNavTitle : depths[2]), {"name":"msg","hash":{},"data":data})))
    + "</span>\n                        </a>\n                        <ul class=\"settings-menu\" style=\"display: none\">\n";
  stack1 = helpers.each.call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1)) != null ? stack1.TERTIARY : stack1), {"name":"each","hash":{},"fn":this.program(13, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                        </ul>\n                    </div>\n                </li>\n";
},"13":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                                "
    + escapeExpression(((helpers.action || (depth0 && depth0.action) || helperMissing).call(depth0, depth0, (depths[2] != null ? depths[2].modelKey : depths[2]), (depths[2] != null ? depths[2].modelUrlRoot : depths[2]), ((stack1 = (depths[2] != null ? depths[2].model : depths[2])) != null ? stack1.id : stack1), false, {"name":"action","hash":{},"data":data})))
    + "\n";
},"15":function(depth0,helpers,partials,data) {
  return "            <div class=\"form-table\">\n                <div id=\"paginationTop\">\n                    <table class=\"form-view-paginator\">\n                        <thead></thead>\n                        <tbody></tbody>\n                    </table>\n                </div>\n            </div>\n";
  },"17":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "    <div id=\"block_"
    + escapeExpression(((helper = (helper = helpers.blockId || (depth0 != null ? depth0.blockId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"blockId","hash":{},"data":data}) : helper)))
    + "\" class=\""
    + escapeExpression(((helper = (helper = helpers.styleClass || (depth0 != null ? depth0.styleClass : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"styleClass","hash":{},"data":data}) : helper)))
    + " block block_"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\">\n    </div>\n";
},"19":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "";
  stack1 = helpers.each.call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1)) != null ? stack1.action : stack1), {"name":"each","hash":{},"fn":this.program(20, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"20":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.equals || (depth0 && depth0.equals) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "PRIMARY", {"name":"equals","hash":{},"fn":this.program(21, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"21":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                "
    + escapeExpression(((helpers.action || (depth0 && depth0.action) || helperMissing).call(depth0, depth0, (depths[2] != null ? depths[2].modelKey : depths[2]), (depths[2] != null ? depths[2].modelUrlRoot : depths[2]), ((stack1 = (depths[2] != null ? depths[2].model : depths[2])) != null ? stack1.id : stack1), true, {"name":"action","hash":{},"data":data})))
    + "\n";
},"23":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers.each.call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.modal : stack1), {"name":"each","hash":{},"fn":this.program(24, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"24":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <a id=\""
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"btn btnModal\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, (depth0 != null ? depth0.buttonLabel : depth0), {"name":"lbl","hash":{},"data":data})))
    + "</a>\n";
},"26":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "";
  stack1 = helpers.each.call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1)) != null ? stack1.SECONDARY : stack1), {"name":"each","hash":{},"fn":this.program(27, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"27":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                <li class=\"action-"
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n                    "
    + escapeExpression(((helpers.iconAction || (depth0 && depth0.iconAction) || helperMissing).call(depth0, depth0, (depths[2] != null ? depths[2].modelKey : depths[2]), (depths[2] != null ? depths[2].modelUrlRoot : depths[2]), ((stack1 = (depths[2] != null ? depths[2].model : depths[2])) != null ? stack1.id : stack1), ((stack1 = (depths[2] != null ? depths[2].model : depths[2])) != null ? stack1.id : stack1), {"name":"iconAction","hash":{},"data":data})))
    + "\n                </li>\n";
},"29":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "            <li>\n                <div class=\"settings-menu-wrap\">\n                    <a title=\""
    + escapeExpression(((helpers.msg || (depth0 && depth0.msg) || helperMissing).call(depth0, (depths[2] != null ? depths[2].dealWithNavTitle : depths[2]), {"name":"msg","hash":{},"data":data})))
    + "\" class=\"vlb-action-icon vlb-action-icon-input-settings-dd\"\n                       href=\"javascript:;\"\n                       onclick=\"return false;\" data-show-menu=\"true\">\n                        <span>"
    + escapeExpression(((helpers.msg || (depth0 && depth0.msg) || helperMissing).call(depth0, (depths[2] != null ? depths[2].dealWithNavTitle : depths[2]), {"name":"msg","hash":{},"data":data})))
    + "</span>\n                    </a>\n                    <ul class=\"settings-menu\" style=\"display: none\">\n";
  stack1 = helpers.each.call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1)) != null ? stack1.TERTIARY : stack1), {"name":"each","hash":{},"fn":this.program(30, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                    </ul>\n                </div>\n            </li>\n";
},"30":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                            "
    + escapeExpression(((helpers.action || (depth0 && depth0.action) || helperMissing).call(depth0, depth0, (depths[2] != null ? depths[2].modelKey : depths[2]), (depths[2] != null ? depths[2].modelUrlRoot : depths[2]), ((stack1 = (depths[2] != null ? depths[2].model : depths[2])) != null ? stack1.id : stack1), false, {"name":"action","hash":{},"data":data})))
    + "\n";
},"32":function(depth0,helpers,partials,data) {
  return "        <div class=\"form-table\">\n            <div id=\"paginationBottom\">\n                <table class=\"form-view-paginator\">\n                    <thead></thead>\n                    <tbody></tbody>\n                </table>\n            </div>\n        </div>\n";
  },"34":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    <div class=\"navbar-fixed-bottom save-bar\">\n        <div class=\"container-fluid\">\n            <div class=\"save-bar-inner row\">\n                <div class=\"col-xs-5 infoText nowrap\">\n                    <p>\n                        <i title=\"Info\" class=\"vlb-action-icon vlb-action-icon-info\"\n                           href=\"javascript:;\"><span>Info</span></i> <strong>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.editModeWarningLabel", {"name":"lbl","hash":{},"data":data})))
    + "\n                        :</strong>\n                        <span class=\"message error\"></span>\n                    </p>\n                </div>\n                <div class=\"buttons col-xs-7\">\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1), {"name":"if","hash":{},"fn":this.program(35, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                </div>\n            </div>\n        </div>\n";
},"35":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "";
  stack1 = helpers.each.call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1)) != null ? stack1.action : stack1), {"name":"each","hash":{},"fn":this.program(36, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"36":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.equals || (depth0 && depth0.equals) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "PRIMARY", {"name":"equals","hash":{},"fn":this.program(37, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"37":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                                "
    + escapeExpression(((helpers.action || (depth0 && depth0.action) || helperMissing).call(depth0, depth0, (depths[2] != null ? depths[2].modelKey : depths[2]), (depths[2] != null ? depths[2].modelUrlRoot : depths[2]), ((stack1 = (depths[2] != null ? depths[2].model : depths[2])) != null ? stack1.id : stack1), true, {"name":"action","hash":{},"data":data})))
    + "\n";
},"39":function(depth0,helpers,partials,data) {
  return "    </div>\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.uiType : stack1), {"name":"if","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.showHeader : depth0), {"name":"if","hash":{},"fn":this.program(3, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n<div id=\"alertBox\"></div>\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.modal : stack1), {"name":"if","hash":{},"fn":this.program(5, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.hasRight || (depth0 && depth0.hasRight) || helperMissing).call(depth0, "!BOOKSTORE_BASIC", {"name":"hasRight","hash":{},"fn":this.program(7, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers.each.call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.block : stack1), {"name":"each","hash":{},"fn":this.program(17, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n<div class=\"form-group form-actions\">\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1), {"name":"if","hash":{},"fn":this.program(19, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.modal : stack1), {"name":"if","hash":{},"fn":this.program(23, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div> <!-- form-actions -->\n\n<div class=\"form-navigation\">\n    <ul class=\"icon-nav-list icon-nav-list-bottom clearfix\">\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1)) != null ? stack1.SECONDARY : stack1), {"name":"if","hash":{},"fn":this.program(26, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1)) != null ? stack1.TERTIARY : stack1), {"name":"if","hash":{},"fn":this.program(29, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n    </ul>\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.usePaginator : depth0), {"name":"if","hash":{},"fn":this.program(32, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n\n";
  stack1 = helpers.unless.call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.hideEditModeBar : stack1), {"name":"unless","hash":{},"fn":this.program(34, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.uiType : stack1), {"name":"if","hash":{},"fn":this.program(39, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}