var MiscHelper = require('lib/misc_helper');
var EditableWidget = require('./editable');
var FormHelper = require('lib/form_helper');
var MsgHelper = require('lib/msg_helper');
var ActionHelper = require('lib/action_helper');

// radio icon widgets

var RadioIconWidget = EditableWidget.extend({
    initialize: function (widget, modelKey, parentProperty) {
        EditableWidget.prototype.initialize.call(this, widget, modelKey,
            parentProperty);
    },

    getOptions: function (formCollections, model) {
        if (!this.readonly) {
        	
            this.radioIcons = FormHelper.getCollection(formCollections, this.collection, undefined, model);
            for (var i = 0; i < this.radioIcons.length; i++) {
                this.radioIcons[i].clazz = "icon-link " + this.radioIcons[i].label;
                this.radioIcons[i].labelz = MsgHelper.getMessage("label.form." + this.label + "." + this.radioIcons[i].id);
                this.radioIcons[i].title = MsgHelper.getMessage("label.form." + this.label + ".title." + this.radioIcons[i].id);
                this.radioIcons[i].showToolTip = true;
                
                if(this.id === 'perspective' || this.id === 'perspectiveBottom' || this.id === 'perspective2') {
                	this.radioIcons[i].linktitle = MsgHelper.getMessage("label.form." + this.label + ".title." + this.radioIcons[i].id);
                	this.radioIcons[i].showToolTip = false;
                }
                 
                if(this.defaultValue === this.radioIcons[i].id) {
                    this.radioIcons[i].default = true;
                }
                if (this.specialBehaviour !== undefined) {
                    this.radioIcons[i].href = 'javascript:;';
                    this.radioIcons[i].specialBehaviour = this.specialBehaviour;
                } else if(this.action !== undefined) {
                    this.radioIcons[i].href = Chaplin.utils.reverse(this.modelKey + "_" + this.action.method, [this.radioIcons[i].id]);
                }

            }
            return this.radioIcons;
        }
    },

    getOptionsSelectMapping: function () {
        if (!this.readonly) {
            return this.bindingSelector;
        }
    },

    binding: function () {
        return [];
    }

});

module.exports = RadioIconWidget;
