var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, (depth0 != null ? depth0.labelPosition : depth0), "LEFT", {"name":"compare","hash":{
    'operator': ("===")
  },"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, (depth0 != null ? depth0.labelPosition : depth0), (depth0 != null ? depth0.undefined : depth0), {"name":"compare","hash":{
    'operator': ("===")
  },"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <label class=\""
    + escapeExpression(((helper = (helper = helpers.labelStyleClass || (depth0 != null ? depth0.labelStyleClass : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"labelStyleClass","hash":{},"data":data}) : helper)))
    + "\" for=\""
    + escapeExpression(((helpers.lbl_id || (depth0 && depth0.lbl_id) || helperMissing).call(depth0, (data && data.id), {"name":"lbl_id","hash":{},"data":data})))
    + "\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, (depth0 != null ? depth0.label : depth0), {"name":"lbl","hash":{},"data":data})))
    + "</label>\n";
},"4":function(depth0,helpers,partials,data) {
  return "";
},"6":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, (depth0 != null ? depth0.labelPosition : depth0), "RIGHT", {"name":"compare","hash":{
    'operator': ("===")
  },"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, options, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda, blockHelperMissing=helpers.blockHelperMissing, buffer = "<div class=\"control-group widget_"
    + escapeExpression(((helper = (helper = helpers.widgetIndex || (depth0 != null ? depth0.widgetIndex : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"widgetIndex","hash":{},"data":data}) : helper)))
    + " "
    + escapeExpression(lambda((data && data.id), depth0))
    + " "
    + escapeExpression(((helper = (helper = helpers.styleClass || (depth0 != null ? depth0.styleClass : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"styleClass","hash":{},"data":data}) : helper)))
    + " "
    + escapeExpression(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"type","hash":{},"data":data}) : helper)))
    + "\">\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.hasLabel : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n    <!-- widget coming -->\n    <div class=\"controls\">\n";
  stack1 = ((helper = (helper = helpers.widget || (depth0 != null ? depth0.widget : depth0)) != null ? helper : helperMissing),(options={"name":"widget","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data}),(typeof helper === functionType ? helper.call(depth0, options) : helper));
  if (!helpers.widget) { stack1 = blockHelperMissing.call(depth0, stack1, options); }
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </div>\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.hasLabel : depth0), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div> <!-- form-group widget_"
    + escapeExpression(((helper = (helper = helpers.widgetIndex || (depth0 != null ? depth0.widgetIndex : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"widgetIndex","hash":{},"data":data}) : helper)))
    + " "
    + escapeExpression(lambda((data && data.id), depth0))
    + " -->";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}