var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                                <li><label><input type=\"checkbox\" name=\""
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\" id=\"medium_"
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"mediumRecord\"\n                                                  disabled=\"disabled\">\n                                    &nbsp;&nbsp;&nbsp;<a href=\""
    + escapeExpression(((helper = (helper = helpers.calculatedUrl || (depth0 != null ? depth0.calculatedUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"calculatedUrl","hash":{},"data":data}) : helper)))
    + "\"\n                                                         target=\"_blank\">"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.collection.importTypes", (depth0 != null ? depth0.typeLabel : depth0), {"name":"label2","hash":{},"data":data})))
    + "\n                                        - "
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "</a>\n                                </label></li>\n";
},"3":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <p>\n            <div class=\"copyMediaFilesHint\">\n                "
    + escapeExpression(((helper = (helper = helpers.hint || (depth0 != null ? depth0.hint : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"hint","hash":{},"data":data}) : helper)))
    + "\n            </div>\n            </p>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div id=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "\" class=\"lb-default selectMediafilesForm\">\n\n    <h2><span>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.confirmation.headline", (depth0 != null ? depth0.ident : depth0), {"name":"label2","hash":{},"data":data})))
    + "</span></h2>\n\n    <p>\n        "
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.confirmation.text", (depth0 != null ? depth0.ident : depth0), {"name":"label2","hash":{},"data":data})))
    + "\n    </p>\n\n    <form class=\"form-horizontal\">\n\n        <div class=\"form-group\">\n            <div class=\"row\">\n                <div class=\"col-xs-12\">\n                    <div class=\"form-wrap-inner\">\n                        <input type=\"radio\" id=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-takeAllMedia\" value=\"takeAllMedia\" class=\"selectMediafiles\"\n                               name=\"selectMediafiles\">\n                        <label for=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-takeAllMedia\">"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.confirmation.takeAllMedia", (depth0 != null ? depth0.ident : depth0), {"name":"label2","hash":{},"data":data})))
    + "</label>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row\">\n                <div class=\"col-xs-12\">\n                    <div class=\"form-wrap-inner\">\n                        <input type=\"radio\" id=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-takeMedia\" value=\"takeMedia\" class=\"selectMediafiles\"\n                               name=\"selectMediafiles\">\n                        <label for=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-takeMedia\">"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.confirmation.takeMedia", (depth0 != null ? depth0.ident : depth0), {"name":"label2","hash":{},"data":data})))
    + "</label>\n                    </div>\n                    <div id=\"medium\">\n                        <ul>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.mediaFiles : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "                        </ul>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row\">\n                <div class=\"col-xs-12\">\n                    <div class=\"form-wrap-inner\">\n                        <input type=\"radio\" id=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-dontTakeMedia\" value=\"dontTakeMedia\" class=\"selectMediafiles\"\n                               name=\"selectMediafiles\">\n                        <label for=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-dontTakeMedia\">"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.confirmation.dontTakeMedia", (depth0 != null ? depth0.ident : depth0), {"name":"label2","hash":{},"data":data})))
    + "</label>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.hint : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n        <div class=\"form-actions align-right\" style=\"float: right\">\n            <a href=\"javascript:;\"\n               class=\"btn green btn-arrow "
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-close\"><span>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.confirmation.abort", (depth0 != null ? depth0.ident : depth0), {"name":"label2","hash":{},"data":data})))
    + "</span></a>\n            <a href=\"javascript:;\" onclick=\"return false;\"\n               class=\"btn green btn-arrow "
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-confirm\"><span>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.confirmation.confirm", (depth0 != null ? depth0.ident : depth0), {"name":"label2","hash":{},"data":data})))
    + "</span></a>\n        </div>\n    </form>\n\n</div>";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}