var AnnualInvoiceReportZisTemplate = require('./templates/forms/annual_invoice_report_zis');
var AnnualInvoiceReport = require('./annual_invoice_report_view');

var AnnualInvoiceReportZis;

module.exports = AnnualInvoiceReportZis = AnnualInvoiceReport.extend({

    initialize: function (options) {
        AnnualInvoiceReport.prototype.initialize.apply(this, [options]);
    },

    getTemplateFunction: function () {
        return AnnualInvoiceReportZisTemplate;
    }

});