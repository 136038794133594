var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "    <div class=\"helpbox clearfix\">\n        <div class=\"add-icons\">\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.helpbox : depth0)) != null ? stack1.url : stack1), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </div>\n";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.helpbox : depth0)) != null ? stack1.isPlainDescription : stack1), "true", {"name":"compare","hash":{
    'operator': ("===")
  },"fn":this.program(4, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.helpbox : depth0)) != null ? stack1.isPlainDescription : stack1), "true", {"name":"compare","hash":{
    'operator': ("!==")
  },"fn":this.program(6, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\n";
},"2":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                <a class=\"txt-link txt-link-help\" href=\""
    + escapeExpression(((helpers.msg || (depth0 && depth0.msg) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.helpbox : depth0)) != null ? stack1.url : stack1), {"name":"msg","hash":{},"data":data})))
    + "\" target=\"_blank\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "help", {"name":"lbl","hash":{},"data":data})))
    + "</a>\n                <a href=\""
    + escapeExpression(((helpers.msg || (depth0 && depth0.msg) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.helpbox : depth0)) != null ? stack1.url : stack1), {"name":"msg","hash":{},"data":data})))
    + "\" target=\"_blank\" class=\"vlb-action-icon vlb-action-icon-help\"\n                   title=\""
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "help", {"name":"lbl","hash":{},"data":data})))
    + "\"><span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "help", {"name":"lbl","hash":{},"data":data})))
    + "</span></a>\n";
},"4":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "            <p>"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.helpbox : depth0)) != null ? stack1.description : stack1), depth0))
    + "</p>\n";
},"6":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <p>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.helpbox : depth0)) != null ? stack1.description : stack1), {"name":"lbl","hash":{},"data":data})))
    + "</p>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1;
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.helpbox : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { return stack1; }
  else { return ''; }
  },"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}