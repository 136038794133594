
var Model = require('./model');

var FormCollectionAuth = Model.extend({
	
    urlRoot: function() {
    	return this.get('url_root');
    },

	fetch: function (options) {
    	var theOptions = options ? options : {};
        
    	var theUrl = this.urlRoot;
    	if(_.isFunction(theUrl)) {
    		theUrl = this.urlRoot();
    	}
    	
    	if(_.string.startsWith(theUrl, apiRoot)) {
        	if(!("headers" in theOptions)) {
        		theOptions['headers'] = {};
        	}
            theOptions['headers']['Authorization'] = "Bearer " + user.access_token;    		
    	}
    	theOptions['async'] = false;
	    return Model.prototype.fetch.call(this, theOptions);
	}

});

module.exports = FormCollectionAuth;