var MsgHelper = require('lib/msg_helper');
var MiscHelper = require('lib/misc_helper');
var DateHelper = require('lib/date_helper');
var PriceHelper = require('lib/price_helper');
var FormHelper = require('lib/form_helper');

var EventsHelper = function () {
};

EventsHelper.prototype.changedIsbn = function (event, changeEvent, model) {

    if (versionCountry == 'latam') {
        var isbn;
        var identifiers = model.get('identifiers');
        for (var i = 0; i < identifiers.length; i++) {
            var identifier = identifiers[i];
            if (identifier.type === '15') {
                isbn = identifier.value;
                break;
            }
        }

        if (isbn) {
            var currency;
            $.ajax(
                {
                    contentType: 'application/json',
                    dataType: 'json',
                    url: apiRoot + 'publisher/currency/' + isbn + '?access_token=' + user.access_token,
                    type: 'GET',
                    async: false,
                    success: function (data) {
                        var currency = data.currency;
                        if (currency) {
                            var currencyCountry = currency.currency + '_' + currency.region;
                            model.set('retailPrices.0.country', currency.region);
                            model.set('retailPrices.0.currency', currency.currency);
                            model.set('retailPrices.0.currencyCountry', currencyCountry);
                            var specialPrices = model.get('specialPrices');
                            for (var i = 0; i < specialPrices.length; i++) {
                                model.set('specialPrices.' + i + '.currencyCountry', currencyCountry);
                            }

                        } else {
                            var retailPrices = model.get('retailPrices');
                            if (retailPrices) {
                                for (var i = 0; i < retailPrices.length; i++) {
                                    model.set('retailPrices.' + i + '.country', 'MX');
                                    model.set('retailPrices.' + i + '.currency', 'MXN');
                                    model.set('retailPrices.' + i + '.currencyCountry', 'MXN_MX');
                                }
                            }
                            var specialPrices = model.get('specialPrices');
                            if (specialPrices) {
                                for (var i = 0; i < specialPrices.length; i++) {
                                    model.set('specialPrices.' + i + '.country', 'MX');
                                    model.set('specialPrices.' + i + '.currency', 'MXN');
                                    model.set('specialPrices.' + i + '.currencyCountry', 'MXN_MX');
                                }
                            }
                        }
                    }
                }
            );
        }
    }

}
;

EventsHelper.prototype.valueIsinWhen = function (event, changeEvent, model) {
    var value = event.target.value;
    if (event.target.type === 'checkbox') {
        value = $('#' + event.target.id).prop('checked');
        if (value === (changeEvent.when === 'true')) {
            return true;
        } else {
            return false;
        }
    }

    if ($('#' + event.target.id).hasClass('readonlyText') && model !== undefined) {
        var id = event.target.id;
        if (id.indexOf("_") > -1) {
            if (id.split("_").length > 2) {
                var parts = id.split("_");
                id = parts[0] + "." + parts[2] + "." + parts[1];
            } else {
                id = id.replace(/_/, '.');
            }
        }
        value = model.get(id);
        if ($.inArray(value, changeEvent.when.split(",")) !== -1) {
            return true;
        } else {
            return false;
        }
    }
    if ($.inArray(value, changeEvent.when.split(",")) !== -1) {
        return true;
    }

    if (value === changeEvent.when) {
        return true;
    } else {
        return false;
    }
};

EventsHelper.prototype.getTargetSelector = function (event, changeEvent, isClass) {
    return this.getTargetSelectorProp(event, changeEvent.targetProperty, isClass);
};


EventsHelper.prototype.getTargetSelectorProp = function (event, targetProperty, isClass) {
    var target = targetProperty;
    target = target.replace(/\./g, '_');
    var ident;
    if (event.target.type === 'radio') {
        ident = event.target.name;
    } else {
        ident = event.target.id;
    }
    if (ident.indexOf("_") > -1) {
        if (ident.split("_").length > 2) {
            target = ident.replace(/_.*_/, "_" + target + "_");
        } else {
            target = ident.replace(/_.*/, "_" + target);
        }
    }
    if (isClass) {
        return "." + target;
    }
    return "#" + target;
};

EventsHelper.prototype.getTargetLabelSelector = function (event, targetProperty, isClass) {
    var target = this.getTargetSelectorProp(event, targetProperty);
    return 'label[for="' + target.substring(1) + '"]';
};


EventsHelper.prototype.hideFormGroup = function (event, changeEvent) {
    var valueInWhen = EventsHelper.prototype.valueIsinWhen.call(this, event, changeEvent);
    var target = EventsHelper.prototype.getTargetSelector.call(this, event, changeEvent, true);
    if (valueInWhen) {
        $(target).hide();
    } else {
        $(target).show();
    }
};

EventsHelper.prototype.hideElement = function (event, changeEvent) {
    var valueInWhen = EventsHelper.prototype.valueIsinWhen.call(this, event, changeEvent);
    if (valueInWhen) {
        $('#' + changeEvent.targetProperty).hide();
    } else {
        $('#' + changeEvent.targetProperty).show();
    }
};

EventsHelper.prototype.recalculateBundleValue = function (bundleTyp, bundleNumber, model) {

    var priceParts;
    if (bundleTyp === 'pricesJournalBundle') {
        // This is the future. We will have always prices as a structure of priceHead and pricePart.
        priceParts = model.get(bundleTyp + '.' + bundleNumber + '.priceParts');
        var sum = 0;
        var anyValueAvailable = false;
        for (var i = 0; i < priceParts.length; i++) {
            if (!MiscHelper.isEmpty(priceParts[i].netPriceAmount)) {
                sum += priceParts[i].netPriceAmount;
                anyValueAvailable = true;
            }
            if (!MiscHelper.isEmpty(priceParts[i].taxAmount)) {
                sum += priceParts[i].taxAmount;
                anyValueAvailable = true;
            }
        }
        if (anyValueAvailable === true) {
            sum = sum.toFixed(2);
        } else {
            sum = '';
        }
        model.set(bundleTyp + '.' + bundleNumber + '.grossPriceAmount', sum);
        $('#' + bundleTyp + '_grossPriceAmount_' + bundleNumber).text(sum.replace(/\./, ','));
    } else {
        priceParts = model.get(bundleTyp + '.' + bundleNumber + '.bundleParts');
        var sum = 0;
        for (var i = 0; i < priceParts.length; i++) {
            sum += MiscHelper.isEmpty(priceParts[i].valueNetto) ? 0 : priceParts[i].valueNetto;
            sum += MiscHelper.isEmpty(priceParts[i].taxAmount) ? 0 : priceParts[i].taxAmount;
        }
        sum = sum.toFixed(2);
        model.set(bundleTyp + '.' + bundleNumber + '.sum', sum);
        sum = sum.replace(/\./, ",");
        $('#' + bundleTyp + '_sum_' + bundleNumber).text(sum);
    }
}

EventsHelper.prototype.changedBundlePrice = function (event, changeEvent, model, data) {

    if (data === 'attach') {
        return;
    }

    var bundleIdSplit = event.target.id.split('_');
    var bundleNumber = bundleIdSplit[1];
    var bundleTyp = bundleIdSplit[0];
    this.recalculateBundleValue(bundleTyp, bundleNumber, model);
};

EventsHelper.prototype.changedPricePart = function (event, changeEvent, model, data, options) {

    if (data === 'attach') {
        return;
    }

    var value = event.target.value;
    var idSplits = event.target.id.split('_');
    var pricesListName = idSplits[0];
    var indexHead = idSplits[1];
    var pricesPartName = idSplits[2];
    var attribute = idSplits[3];
    var indexPart = idSplits[4];
    var thePrices = model.get(pricesListName);
    var rowPrice = thePrices[indexHead];
    var currencyCountry = rowPrice.currency + '_' + rowPrice.country;
    var pcs;
    var attr;
    var codelist;

    if (attribute === 'taxRate') {
        codelist = FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '62minValues');
    } else if (attribute === 'productTypeCode') {
        codelist = FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '7');
    } else if (attribute === 'productIdentifierType') {
        codelist = FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, 'journalIdentifierType');
    }

    pcs = PriceHelper.filterPricesByCurrencyCountry(thePrices, rowPrice, currencyCountry);
    for (var i = 1; i < pcs.length; i++) {
        attr = pricesListName + '.' + pcs[i].index + '.' + pricesPartName + '.' + indexPart + '.' + attribute;
        var val = model.get(attr);
        model.set(attr, value);
        var selector = '#' + pricesListName + '_' + pcs[i].index + '_' + pricesPartName + '_' + attribute + '_' + indexPart;
        if (codelist === undefined) {
            $(selector).text(value);
        } else {
            $(selector).text(FormHelper.getCodelistLabel(codelist, value));
        }
    }
};

EventsHelper.prototype.changedPriceHead = function (event, changeEvent, model, data, options) {

    if (data === 'attach') {
        return;
    }

    var value = event.target.value;
    var idSplits = event.target.id.split('_');
    var pricesListName = idSplits[0];
    var attribute = idSplits[1];
    var index = idSplits[2];
    var thePrices = model.get(pricesListName);
    var rowPrice = thePrices[index];
    var currencyCountry = rowPrice.currency + '_' + rowPrice.country;
    var pcs;
    var attr;
    var codelist;

    if (attribute === 'currencyCountry') {
        var splits2 = value.split('_');
        rowPrice.currency = splits2[0];
        rowPrice.country = splits2[1];
        codelist = FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, 'currencyJournal');
    }

    pcs = PriceHelper.filterPricesByCurrencyCountry(thePrices, rowPrice, currencyCountry);
    for (var i = 0; i < pcs.length; i++) {
        attr = pricesListName + '.' + pcs[i].index + '.' + attribute;
        model.set(attr, value);
        var selector = '#' + pricesListName + '_' + attribute + '_' + pcs[i].index;
        $(selector).text(FormHelper.getCodelistLabel(codelist, value));
    }
};

EventsHelper.prototype.clearProductFormType = function (event, changeEvent, model, data) {
    var retVal;
    if ((model._previousAttributes.uiType === 'bundles' || model.attributes.uiType === 'bundles') && data !== 'attach') {
        retVal = true;
    }

    var type = event.target.value;
    if (type === 'bundles' && data !== 'attach') {
        model.set('bundleswitch', 'identicTax');
        model.set('type', 'nonbook');
    }

    if ($('#form_type').length > 0) {
        var form_type = $('#form_type').val();

        if (
            (type === 'bundles' && form_type !== 'WW') ||
            (type === 'pbook' && !_.string.startsWith(form_type, "B")) ||
            (type === 'ebook' && form_type !== 'DG') ||
            (type === 'abook' && !_.string.startsWith(form_type, "A")) ||
            (type === 'avbook' && !(_.string.startsWith(form_type, "A") || _.string.startsWith(form_type, "V"))) ||
            (type === 'calendar' && form_type !== 'PC') ||
            (type === 'map' && !_.string.startsWith(form_type, "C")) ||
            (type === 'digital' && (form_type === 'DG' || !_.string.startsWith(form_type, "D"))) ||
            (type === 'undefined' && form_type !== '00') ||
            (type === 'journal' && form_type !== 'JO') ||
            (type === 'nonbook' && (form_type === 'JO' || form_type === '00' || _.string.startsWith(form_type, "B")
                || _.string.startsWith(form_type, "D") || _.string.startsWith(form_type, "A") || _.string.startsWith(form_type, "V")
                || _.string.startsWith(form_type, "C") || form_type === 'PC' || form_type === 'WW'))
        ) {
            if (type === 'bundles') {
                model.set('form.type', 'WW');
                $('#form_type').select2('val', 'WW');
            } else if (type === 'journal') {
                model.set('form.type', 'JO');
            } else {
                model.set("form.type", "");
                $("#form_type").select2("val", "");
            }
        }
    }

    if (data !== 'attach') {
        // Reset e-book attributes if product type is switched.
        model.set('extent.drmCode', null);
        $("#extent_drmCode").select2("val", "");
        model.set('extent.epubTypeVersion', null);
        model.set('extent.fileFormat', null);
        $("#extent_fileFormat").select2("val", "");
    }

    return retVal;
};

EventsHelper.prototype.showFormGroup = function (event, changeEvent, model) {
    var valueInWhen = EventsHelper.prototype.valueIsinWhen.call(this, event, changeEvent, model);
    var target = EventsHelper.prototype.getTargetSelector.call(this, event, changeEvent, true); //.user_form_2
    if (valueInWhen) {
        $(target).show();
    } else {
        $(target).hide();
    }
};

//this code is bad, but i didnt found better solution. sorry :(
EventsHelper.prototype.reportsMultiSelect = function (event, changeEvent, model) {
    var valueInWhen = EventsHelper.prototype.valueIsinWhen.call(this, event, changeEvent, model);
    var targetSelectorSplit = EventsHelper.prototype.getTargetSelector.call(this, event, changeEvent, true).split("_");
    var index = targetSelectorSplit[targetSelectorSplit.length - 1];
    var selector = "#s2id_user_groupMapping_reports_" + index;
    if (valueInWhen) {
        $(selector).show();
    } else {
        model.attributes.user.groupMapping[index].reports = []; //clear model
        $(selector).select2("val", ""); //clear field in ui
        $(selector).hide(); //hide field
    }
};

EventsHelper.prototype.initFromTillRowWithDatepicker = function (event, changeEvent) {

    var valueInWhen = EventsHelper.prototype.valueIsinWhen.call(this, event, changeEvent);
    var valueInputs = $(event.target).closest('.row').find('.selectionValue input');
    valueInputs.toggleClass('datepicker', valueInWhen);
    if (valueInWhen) {
        valueInputs.addClass('date-field');
        valueInputs.attr('placeholder', MsgHelper.getMessage('label.placeholder.dateformat.de'));
        var language = 'de';
        if (versionCountry === 'brasil') {
            language = 'pt-BR';
        } else if (versionCountry === 'latam') {
            language = 'es-MX';
        }
        valueInputs.datepicker({
            orientation: "top auto",
            format: formatDateDayMonthYear.toLowerCase(),
            autoclose: true,
            forceParse: true,
            language: language,
            keyboardNavigation: false,
            showOnFocus: false  // copied from version 1.4.0 to our current datepicker version.
        });
//            .on("changeMonth", function(e) {
//            var label = $(e.target).closest('.control-group').find('.overlabel');
//            label.hide();
//            var dp = $(e.currentTarget).data('datepicker');
//            var container = label.parent();
//            if (container[0] !== undefined && container[0].className !== undefined && container[0].className.indexOf("tillProperty") > 0) {
//                e.date.setMonth((e.date.getMonth() + 1));
//            }
//            else {
//                e.date.setHours(3);
//            }
//
//            dp.date = e.date;
//            dp.dates.splice(0, 1);
//            dp.dates.push(e.date);
//            dp.setValue();
//            dp.update();
//            dp.hide();
//        }).on("changeYear", function(e) {
//            var label = $(e.target).closest('.control-group').find('.overlabel');
//            label.hide();
//            var dp = $(e.currentTarget).data('datepicker');
//            var container = label.parent();
//            if (container[0] !== undefined && container[0].className !== undefined && container[0].className.indexOf("tillProperty") > 0) {
//                e.date.setMonth(12);
//            }
//            else {
//                e.date.setMonth(0);
//                e.date.setHours(3);
//            }
//
//            dp.date = e.date;
//            dp.dates.splice(0, 1);
//            dp.dates.push(e.date);
//            dp.setValue();
//            dp.update();
//            dp.hide();
//        }).on("changeDate", function(e) {
//            var label = $(e.target).closest('.control-group').find('.overlabel');
//            label.hide();
////            dp.hide();
//        });
        // only add a calendar if not already there from prvious fie
        if (valueInputs.next().hasClass('vlb-action-icon-calendar')) {
            valueInputs.next().remove();
        }

        valueInputs.after(function () {
            return '<a title="Kalender" ' +
                'class="vlb-action-icon vlb-action-icon-calendar small" id="' + this.id + '_icon" onclick="$(\'#' + this.id + '\').datepicker(\'show\'); return false;"><span>Kalender</span></a>';
        });


    } else {
        valueInputs.first().attr('placeholder', MsgHelper.getMessage('label.from'));
        valueInputs.last().attr('placeholder', MsgHelper.getMessage('label.till'));
        valueInputs.datepicker("remove");
        valueInputs.next('a').remove();
        valueInputs.removeClass('date-field');
    }
};

EventsHelper.prototype.star = function (target) {
    var val = $(target).text();
    if (!_.string.endsWith(val, "*")) {
        $(target).text(val + "*");
    }
};

EventsHelper.prototype.unstar = function (target) {
    var val = $(target).text();
    if (_.string.endsWith(val, "*")) {
        $(target).text(val.substring(0, val.length - 1));
    }
};

EventsHelper.prototype.markRequired = function (event, changeEvent) {
    var valueInWhen = EventsHelper.prototype.valueIsinWhen.call(this, event, changeEvent);
    var target = EventsHelper.prototype.getTargetSelector.call(this, event, changeEvent);

    target = 'label[for="' + target.substring(1) + '"]';

    if (valueInWhen) {
        this.star(target);
    } else {
        this.unstar(target);
    }
};

EventsHelper.prototype.removeAnyBlank = function (event, changeEvent, model, data) {

    if (data === 'attach') {
        return;
    }

    var value = $("#" + event.target.id).val();
    var path = MiscHelper.buildModelPathFromUiId(event.target.id);
    value = value.replace(/\s/g, '');
    model.set(path, value);

};

EventsHelper.prototype.descriptionChanged = function (event, changeEvent, model, data) {
    if (MiscHelper.isEmpty(event.target.value)) {
        model.set('form.description', null);
        model.set('form.binding', null);
    }
};

EventsHelper.prototype.disableField = function (event, changeEvent) {
    var valueInWhen = EventsHelper.prototype.valueIsinWhen.call(this, event, changeEvent);
    var target = EventsHelper.prototype.getTargetSelector.call(this, event, changeEvent);
    if (valueInWhen) {
        $(target).prop('disabled', true);
    } else {
        $(target).prop('disabled', false);
    }
};

EventsHelper.prototype.resetBinding = function (event, changeEvent, model, data) {
    if (data === 'attach') {
        return;
    }
    var value = $("#" + event.target.id).val();
    if (value === 'DG') {
        model.set('form.description', null);
        $("#form_description").select2("val", "");
    }
};

EventsHelper.prototype.resetProductformDetails = function (event, changeEvent, model) {
    var value = $("#" + event.target.id).val();
    if (!MiscHelper.isEmpty(value)) {
        var pfd = model.get("form.detail");
        var pfdO3 = model.get("form.detailO3");
        if (!MiscHelper.isEmpty(pfd) && !_.string.startsWith(pfd, value.charAt(0)) && MiscHelper.isEmpty(pfdO3)) {
            model.set("form.detail", "");
            $("#form_detail").select2("val", "");
        }
    }
};

EventsHelper.prototype.resetSupplier = function (event, changeEvent, model) {
    var value = $("#" + event.target.id).val();
    var supplier = model.get("supplier");

    if (!MiscHelper.isEmpty(supplier)) {
        var supplierId = value;
        var nameCodeType = supplier.nameCodeType;
        model.set("supplier", {
            supplierId: supplierId,
            nameCodeType: nameCodeType,
        });
    }
}


EventsHelper.prototype.enableField = function (event, changeEvent) {
    var valueInWhen = EventsHelper.prototype.valueIsinWhen.call(this, event, changeEvent);
    var target = EventsHelper.prototype.getTargetSelector.call(this, event, changeEvent);
    if (valueInWhen) {
        $(target).prop('disabled', false);
    } else {
        $(target).prop('disabled', true);
    }
};
EventsHelper.prototype.enableFieldElseHideSubtitleIfEmpty = function (event, changeEvent) {
    var valueInWhen = EventsHelper.prototype.valueIsinWhen.call(this, event, changeEvent);
    var target = EventsHelper.prototype.getTargetSelector.call(this, event, changeEvent);
    if (valueInWhen) {
        $(target).prop('disabled', false);
        $(target).closest('.control-group').removeClass('hidden');
    } else {
        $(target).prop('disabled', true);

        var subtitle = $($(target).closest('.control-group-row').find('.control-group')[1]).find('input').val();
        if (subtitle == '') {
            $(target).closest('.control-group').addClass('hidden');
        } else {
            $(target).closest('.control-group').removeClass('hidden');
        }
    }
};


EventsHelper.prototype.updatePagesFields = function (event, changeEvent, model, data) {
    var target = EventsHelper.prototype.getTargetSelector.call(this, event, changeEvent);

    if (model.get('extent')) {
        var roman = model.get('extent.pagesRoman');
        var arabic = model.get('extent.pagesArabic');
        var ca = model.get('extent.pages');

        if (!ca && (roman || arabic)) {
            $('#extent_pages').prop('disabled', true);
        } else {
            $('#extent_pages').prop('disabled', false);
        }
        if (ca && (!roman && !arabic)) {
            $('#extent_pagesRoman').prop('disabled', true);
            $('#extent_pagesArabic').prop('disabled', true);
        } else {
            $('#extent_pagesRoman').prop('disabled', false);
            $('#extent_pagesArabic').prop('disabled', false);
        }
    }
}

EventsHelper.prototype.checkRetailPriceLabel = function (event, changeEvent, model, data) {
    if (model.get('type') === 'duoBundle') {
        var msg = MsgHelper.getMessage('label.form.product.field.retailPricesOnlyCHF');
        $('#block_prices #fieldSet_prices .retailPrices h5').text(msg);
    }
};

EventsHelper.prototype.showFieldset = function (event, changeEvent, model) {
    var valueInWhen = EventsHelper.prototype.valueIsinWhen.call(this, event, changeEvent, model);
    var target = "#fieldSet_" + changeEvent.targetProperty;
    var target_action = '#fieldSetAction_' + changeEvent.targetProperty;
    if (valueInWhen) {
        $(target).show();
        $(target_action).show();
    } else {
        $(target).hide();
        $(target_action).hide();
    }

    // handle the visibility of the ejournal part for zis products
    if (model.attributes.type === 'journal' && model.attributes.zisInfo.medium !== 'DG' && changeEvent.targetProperty === 'extentebook') {
        $(target).hide();
        $(target_action).hide();
    }
};

EventsHelper.prototype.showEducationalWarning = function (event, changeEvent, model) {
    var valueInWhen = EventsHelper.prototype.valueIsinWhen.call(this, event, changeEvent, model);
    // check existence of educational level
    var audiences = model.get("audiences");
    var hasEducationalLevel = false;
    if (audiences !== null) {
        for (var i in audiences) {
            if (audiences[i] !== null && audiences[i].rangeCode === "31") {
                hasEducationalLevel = true;
            }
        }
    }

    if (valueInWhen && !hasEducationalLevel) {
        $(".educationalWarning").removeClass("hidden");
    } else {
        $(".educationalWarning").addClass("hidden");
    }
};

EventsHelper.prototype.copyEducationalWarning = function (event, changeEvent, model) {
    var audiences = model.get("audiences");
    if (audiences !== null) {
        for (var i in audiences) {
            if (audiences[i] !== null && audiences[i].rangeCode === "31") {
                audiences[i].ageTo = audiences[i].ageFrom;
            }
        }
    }
    model.set('audiences', audiences);
};

EventsHelper.prototype.salesRightsChange = function (event, changeEvent, model, data) {
    if (data === 'attach') {
        return;
    }
    var value;
    if (event.target.type === 'checkbox') {
        value = $('#' + event.target.id).prop('checked');
    } else {
        value = event.target.value;
    }
    var identifier = MiscHelper.isEmpty(event.target.id) ? event.target.getAttribute('name') : event.target.id;
    var index = parseInt(identifier.replace(/.*_/, ''));
    var salesRights = model.get('salesRights');
    //var target = EventsHelper.prototype.getTargetSelectorProp.call(this, event, 'salesRights_type_' + index, false);
    var type = $('#salesRights_type_' + index).val();
    var prop = identifier.substring(0, identifier.lastIndexOf('_'));
    prop = prop.substring(prop.lastIndexOf('_') + 1);

    for (var i = 0; i < salesRights.length; i++) {
        if (salesRights[i] != null && salesRights[i].type === type) {
            if (_.string.startsWith(prop, 'territory')) {
                var territory = prop.replace('territory', '');
                if (value === true) {
                    if (MiscHelper.isEmpty(salesRights[i]['territory'])) {
                        salesRights[i]['territory'] = territory;
                    } else {
                        salesRights[i]['territory'] = salesRights[i]['territory'] + ' ' + territory;
                    }
                } else {
                    salesRights[i]['territory'] = salesRights[i].territory.replace(territory, '');
                    salesRights[i]['territory'] = salesRights[i].territory.trim();
                }
            } else {
                for (attr in salesRights[i]) {
                    if (attr !== 'type' && attr !== 'id' && attr !== 'version') {
                        if (attr === prop) {
                            salesRights[i][attr] = value;
                        } else {
                            // currently do nothing
                        }
                    }
                }
            }
        }
    }
    model.set('salesRights', salesRights);

    return true;
};

EventsHelper.prototype.setOtherField = function (prop, index, value) {
    var selector = "#" + prop + (index);
    if (MiscHelper.isEmpty($(selector).val())) {
        $(selector).val(value);
        $(selector).trigger('datepickerChange');
    }

};

EventsHelper.prototype.addFuturePriceJournalBundle = function (event, changeEvent, model, data) {
    if (data === 'attach') {
        return;
    }
    return EventsHelper.prototype.addFuturePrice.call(this, event, changeEvent, model, 'pricesJournalBundle');
};

EventsHelper.prototype.addFuturePriceJournal = function (event, changeEvent, model, data) {
    if (data === 'attach') {
        return;
    }
    return EventsHelper.prototype.addFuturePrice.call(this, event, changeEvent, model, 'pricesJournal');
};

EventsHelper.prototype.addFuturePriceSpecial = function (event, changeEvent, model) {
    return EventsHelper.prototype.addFuturePrice.call(this, event, changeEvent, model, 'specialPrices');
};

EventsHelper.prototype.addFuturePriceRetail = function (event, changeEvent, model, data) {
    if (data === 'attach') {
        return;
    }
    return EventsHelper.prototype.addFuturePrice.call(this, event, changeEvent, model, 'retailPrices');
};

EventsHelper.prototype.addFuturePriceMultiBundle = function (event, changeEvent, model, data) {
    if (data === 'attach') {
        return;
    }
    return EventsHelper.prototype.addFuturePrice.call(this, event, changeEvent, model, 'multiBundles');
};

EventsHelper.prototype.addFuturePriceDuoBundle = function (event, changeEvent, model, data) {
    if (data === 'attach') {
        return;
    }
    return EventsHelper.prototype.addFuturePrice.call(this, event, changeEvent, model, 'duoBundles');
};

EventsHelper.prototype.resetCalculatedRetailPrice = function (event, changeEvent, model, data) {
    if (data === 'attach') {
        return;
    }
    var index = parseInt(event.target.id.replace(/.*_/, ''));
    var thePrices = model.get('retailPrices');

    var rowPrice = thePrices[index];
    rowPrice.calculated = false;
};


EventsHelper.prototype.addFuturePrice = function (event, changeEvent, model, priceFieldName) {
    var value = event.target.value;
    var rerender = false;
    var index = parseInt(event.target.id.replace(/.*_/, ''));
    var prop = event.target.id.substring(0, event.target.id.lastIndexOf('_') + 1);
    var thePrices = model.get(priceFieldName);

    var rowPrice = thePrices[index];
    var theNextPrice = null;
    var existingIndex = -1;
    var pcs;

    if (priceFieldName === 'multiBundles') {
        pcs = [];
        for (var i = 0; i < thePrices.length; i++) {
            pcs.push({price: thePrices[i], index: i});
        }
    } else {
        pcs = PriceHelper.filterPricesByCurrencyCountry(thePrices, rowPrice);
    }

    var rowLocalIndex = -1;
    for (var i = 0; i < pcs.length; i++) {
        if (pcs[i].index === index) {
            rowLocalIndex = i;
            break;
        }
    }

    if (!MiscHelper.isEmpty(value)) {
        if (pcs.length === 1 || (rowLocalIndex + 1) >= pcs.length) {
            theNextPrice = $.extend(true, {}, rowPrice);
            theNextPrice.id = MiscHelper.uuid();
            theNextPrice.validUntil = null;
            theNextPrice.value = null;
            if (priceFieldName === 'duoBundles' || priceFieldName === 'multiBundles') {
                theNextPrice.sum = null;
                for (var i = 0; i < theNextPrice.bundleParts.length; i++) {
                    var bundlePart = theNextPrice.bundleParts[i];
                    bundlePart.taxAmount = null;
                    bundlePart.valueNetto = null;
                }
            } else if (priceFieldName === 'pricesJournal') {
                for (var i = 0; i < theNextPrice.priceParts.length; i++) {
                    var pricePart = theNextPrice.priceParts[i];
                    pricePart.grossPriceAmount = null;
                    pricePart.shippingCostsAbroad = null;
                    pricePart.shippingCostsInland = null;
                    pricePart.partPriceRecordID = MiscHelper.uuid();
                }
            } else if (priceFieldName === 'pricesJournalBundle') {
                theNextPrice.shippingCostsAbroad = null;
                theNextPrice.shippingCostsInland = null;
                theNextPrice.grossPriceAmount = null;
                for (var i = 0; i < theNextPrice.priceParts.length; i++) {
                    var pricePart = theNextPrice.priceParts[i];
                    pricePart.taxAmount = null;
                    pricePart.netPriceAmount = null;
                }
            }
        } else {
            theNextPrice = pcs[rowLocalIndex + 1].price;
            existingIndex = pcs[rowLocalIndex + 1].index;
        }

        var selector = "#" + prop + index;
        var cpValue = $(selector).val();
        if (cpValue) {
            var d = DateHelper.getDateFromFormat(cpValue);
            d.setDate(d.getDate() + 1);
            if (existingIndex !== -1) {
                model.set(priceFieldName + "." + (existingIndex) + ".calculated", false);
                model.set(priceFieldName + "." + (existingIndex) + ".validFrom", DateHelper.formatDate(d));
            } else {
                rerender = true;
                theNextPrice.validFrom = DateHelper.formatDate(d, formatDateDayMonthYear);
                thePrices.splice((index + 1), 0, theNextPrice);
            }
        }

    } else {
        if (pcs.length !== 1 && (rowLocalIndex + 1) < pcs.length) {
            for (var i = (pcs.length - 1); i > rowLocalIndex; i--) {
                thePrices.splice(pcs[i].index, 1);
            }
            $("#" + event.target.id).trigger('change');
            rerender = true;
        }
    }

    return rerender;
};

EventsHelper.prototype.syncSpecialPriceType = function (event, changeEvent, model) {
    var priceFieldName = 'specialPrices';
    var value = event.target.value;
    var index = parseInt(event.target.id.replace(/.*_/, ''));
    var thePrices = model.get(priceFieldName);

    var rowPrice = thePrices[index];
    var pcs = PriceHelper.filterPricesByCurrencyCountry(thePrices, rowPrice);

    var rowLocalIndex = -1;
    for (var i = 0; i < pcs.length; i++) {
        if (pcs[i].index === index) {
            rowLocalIndex = i;
            if (pcs[i].price.typeTypeQualifier !== undefined && pcs[i].price.typeTypeQualifier.indexOf("_M") === -1) {
                pcs[i].price.minimumQuantity = null;
            }
            break;
        }
    }

    if (!MiscHelper.isEmpty(value) && pcs.length > 1) {
        for (var i = (rowLocalIndex + 1); i < pcs.length; i++) {
            //model.set(priceFieldName + "." + (pcs[i].index) + ".typeTypeQualifier", value);
            pcs[i].price.type = rowPrice.type;
            pcs[i].price.typeQualifier = rowPrice.typeQualifier;
            pcs[i].price.typeTypeQualifier = rowPrice.typeTypeQualifier;
            if (rowPrice.typeTypeQualifier !== undefined && rowPrice.typeTypeQualifier.indexOf("_M") === -1) {
                pcs[i].price.minimumQuantity = null;
            }
        }
    }
};

EventsHelper.prototype.syncSpecialPriceQuantity = function (event, changeEvent, model) {
    var priceFieldName = 'specialPrices';
    var value = event.target.value;
    var index = parseInt(event.target.id.replace(/.*_/, ''));
    var thePrices = model.get(priceFieldName);

    var rowPrice = thePrices[index];
    var pcs = PriceHelper.filterPricesByCurrencyCountry(thePrices, rowPrice);

    var rowLocalIndex = -1;
    for (var i = 0; i < pcs.length; i++) {
        if (pcs[i].index === index) {
            rowLocalIndex = i;
            break;
        }
    }

    if (pcs.length > 1) {
        for (var i = (rowLocalIndex + 1); i < pcs.length; i++) {
            //model.set(priceFieldName + "." + (pcs[i].index) + ".typeTypeQualifier", value);
            pcs[i].price.minimumQuantity = rowPrice.minimumQuantity;
        }
    }
};


EventsHelper.prototype.addFuturePriceForeign = function (event, changeEvent, model) {
    return EventsHelper.prototype.addFuturePrice.call(this, event, changeEvent, model, 'foreignPrices');
};

EventsHelper.prototype.checkAdbAvailable = function (event, changeEvent, model) {
    var jsonData = undefined;

    if (model.get('account.mvbId') !== "") {
        $.ajax(
            {
                dataType: 'json',
                url: applicationRoot + '/adb/account/' + model.get('account.mvbId'),
                type: 'GET',
                async: false,
                success: function (data) {
                    jsonData = data;
                }
            });

        if (jsonData.mvbId !== null) {
            model.set('partner.adbStatus', jsonData.adbStatus);
            model.set('partner.partnerName', jsonData.partnerName);
            model.set('partner.shortName', jsonData.shortName);
            model.set('partner.isbnPrefixes', jsonData.isbnPrefixes);
            model.set('partner.street', jsonData.street);
            model.set('partner.city', jsonData.city);
            model.set('partner.country', jsonData.country);
        } else {
            model.set('partner.adbStatus', MsgHelper.getMessage('msg.adb.not.available'));
            model.set('partner.partnerName', '');
            model.set('partner.shortName', '');
            model.set('partner.isbnPrefixes', '');
            model.set('partner.street', '');
            model.set('partner.city', '');
            model.set('partner.country', '');
        }
    }

    return false;
};

EventsHelper.prototype.addToHierarchy = function (event, changeEvent, model, data) {
    if (data === 'attach') {
        return;
    }
    var value = event.target.value;
    if (!MiscHelper.isEmpty(value)) {
        var index = event.target.id.substr(event.target.id.length - 1);
        var hierarchy = model.get('collections')[index];
        var master = null;
        if ("createNew" === value) {
            master = {
                'type': 'set', 'identifiers': [{'value': ""}],
                'title': "", 'subtitle': ""
            };
        } else {
            var data = $("#" + event.target.id).select2('data');
            master = {
                'type': 'set', 'identifiers': [{'value': value, "type": "01"}],
                'title': data['title'], 'subtitle': data['subTitle']
            };
            // Remove the selections box value only if one existing record was selected. "createNew" instead must be
            // kept for the case of re rendering.
            hierarchy['hierarchy'] = "";
        }

        model.set("collections." + index + ".master", master);

    }
};

EventsHelper.prototype.addToSeries = function (event, changeEvent, model, data) {
    if (data === 'attach') {
        return;
    }
    var value = event.target.value;
    if (!MiscHelper.isEmpty(value)) {
        var index = event.target.id.substr(event.target.id.length - 1);
        var series = model.get('collections')[index];
        var master = null;
        if ("createNew" === value) {
            master = {
                'type': 'series', 'identifiers': [{'value': ""}],
                'title': "", 'subtitle': ""
            };
        } else {
            var data = $("#" + event.target.id).select2('data');
            master = {
                'type': 'series', 'identifiers': [{'value': value, "type": "04"}],
                'title': data['title'], 'subtitle': data['subTitle']
            };
            // Remove the selections box value only if one existing record was selected. "createNew" instead must be
            // kept for the case of re rendering.
            series['series'] = "";
        }

        model.set("collections." + index + ".master", master);

    }
};

EventsHelper.prototype.changeContributorType = function (event, changeEvent, model, data) {
    if (data === 'attach') {
        return;
    }
    // (HGF) I was told that the metadatstore will keep the sequence numbers if they are already set. So I implemented
    // this routine. After that I found out that the metadatastore always calculates the sequence numbers even if they
    // are available. I will keep this routine for the case that perhapse the metadatastore will change it's behaviour.
    var index = event.target.id.split('_')[2];
    var contributors = {};
    var prevContributors = model.get('contributors');
    for (var i = 0; i < prevContributors.length; i++) {
        var contributor = prevContributors[i];
        if (contributors[contributor.type] == undefined) {
            contributors[contributor.type] = [];
        }
        contributor.position = i;
        contributors[contributor.type].push(contributor);
    }

    for (var contributorType in contributors) {
        contributors[contributorType].sort(function (a, b) {
            return a.sequence - b.sequence;
        });
    }
    for (var contributorType in contributors) {
        var counter = 1;
        for (var i = 0; i < contributors[contributorType].length; i++) {
            var contributor = contributors[contributorType][i];
            contributor.sequenceWithinRole = counter;
            model.set('contributors.' + contributor.position + '.sequenceWithinRole', counter);
            counter++;
        }
    }
    for (var i = 0; i < prevContributors.length; i++) {
        var contributor = prevContributors[i];
        console.log(contributor.type + "(" + contributor.sequence + "/" + contributor.sequenceWithinRole + "):" + contributor.lastName + ", " + contributor.firstName);
    }
};


EventsHelper.prototype.changeAdditionalInfoSwitch = function (event, changeEvent, model, data) {
    var index = event.target.name.substr(event.target.name.length - 1);
    var textHint = $('.record_' + index).find('.text-hint');
    // initial visibility for html hint
    if (event.target.value === 'text') {
        textHint.removeClass('hidden');
    } else {
        textHint.addClass('hidden');
    }

    if (data === 'attach') {
        return;
    }

    var value = event.target.value;
    if (value === 'text') {
        model.set('texts.' + index + '.formatCode', '05');
        model.set('texts.' + index + '.link', null);
        model.set('texts.' + index + '.linkType', null);
        textHint.removeClass('hidden');
    } else {
        model.set('texts.' + index + '.formatCode', '02');
        model.set('texts.' + index + '.linkType', '01');
        model.set('texts.' + index + '.value', null);
        textHint.addClass('hidden');
    }
};

/**
 * Remember the origin availability code that was delivered from the server. That information is importand if the
 * availability  is changed in a way that the activity state of the product is been changed. If the user will cancel the
 * action we need the previouis value to reset the availabiltiy code.
 *
 * @param event
 * @param changeEvent
 * @param model
 * @param data
 */
EventsHelper.prototype.changedAvailability = function (event, changeEvent, model, data) {
    if (data === 'attach') {
        model.set('availabilityRead', model.get('availability'));
    }

};

EventsHelper.prototype.changeSelectionareasFromTo = function (event, changeEvent, model) {

    var value = event.target.value;
    var id = event.target.id;
    var index = id.split('_')[2];
    var selectionInput = $('#selectionareas_column_' + index);
    var selectionInputValue = selectionInput.attr('value');
    if (selectionInputValue === 'PRICE_DE') {
        var valFrom;
        var valTo;
        if (id.indexOf('from') >= 0) {
            var toId = id.replace('from', 'till');
            var valTo = $('#' + toId).val();
            if (!MiscHelper.isEmpty(valTo)) {
                valFrom = parseFloat(value.replace(/,/, ".").replace(/[^\d.]/g, ''));
                valTo = parseFloat(valTo.replace(/,/, ".").replace(/[^\d.]/g, ''));
                if (Math.round(valFrom * 100) > Math.round(valTo * 100)) {
                    event.target.closest('.control-group').classList.add('error');
                    var theMsg = MsgHelper.getMessage('msg.checkPrice');
                    MsgHelper.showMsgOnField('#' + id, theMsg, false);
                } else {
                    $('#' + id).closest('.control-group').find('.vlx_error').remove();
                    $('#' + toId).closest('.control-group').find('.vlx_error').remove();
                    event.target.closest('.control-group').classList.remove('error');
                    $('#' + toId).closest('.control-group').removeClass('error');
                }
            }
        } else {
            var fromId = id.replace('till', 'from');
            var valFrom = $('#' + fromId).val();
            if (!MiscHelper.isEmpty(valFrom)) {
                valTo = parseFloat(value.replace(/,/, ".").replace(/[^\d.]/g, ''));
                valFrom = parseFloat(valFrom.replace(/,/, ".").replace(/[^\d.]/g, ''));
                if (Math.round(valFrom * 100) > Math.round(valTo * 100)) {
                    event.target.closest('.control-group').classList.add('error');
                    var theMsg = MsgHelper.getMessage('msg.checkPrice');
                    MsgHelper.showMsgOnField('#' + id, theMsg, false);
                } else {
                    $('#' + id).closest('.control-group').find('.vlx_error').remove();
                    $('#' + fromId).closest('.control-group').find('.vlx_error').remove();
                    event.target.closest('.control-group').classList.remove('error');
                    $('#' + fromId).closest('.control-group').removeClass('error');
                }
            }
        }
    } else {
        if (!MiscHelper.isEmpty(value)) {
            var valFrom;
            var valTo;
            if (id.indexOf('from') >= 0) {
                var toId = id.replace('from', 'till');
                var valTo = $('#' + toId).val();
                if (!MiscHelper.isEmpty(valTo)) {
                    valFrom = DateHelper.getDateFromFormat(value, formatDateDayMonthYear);
                    valTo = DateHelper.getDateFromFormat(valTo, formatDateDayMonthYear);
                    if (valFrom.getTime() > valTo.getTime()) {
                        event.target.closest('.control-group').classList.add('error');
                        if ($('#' + id).closest('.control-group').find('.vlx_error').length === 0) {
                            var theMsg = MsgHelper.getMessage('msg.checkDate');
                            MsgHelper.showMsgOnField('#' + id, theMsg, false);
                        }
                    } else {
                        $('#' + id).closest('.control-group').find('.vlx_error').remove();
                        $('#' + toId).closest('.control-group').find('.vlx_error').remove();
                        event.target.closest('.control-group').classList.remove('error');
                        $('#' + toId).closest('.control-group').removeClass('error');
                    }
                }
            } else {
                var fromId = id.replace('till', 'from');
                var valFrom = $('#' + fromId).val();
                if (!MiscHelper.isEmpty(valFrom)) {
                    valFrom = DateHelper.getDateFromFormat(valFrom, formatDateDayMonthYear);
                    valTo = DateHelper.getDateFromFormat(value, formatDateDayMonthYear);
                    if (valFrom.getTime() > valTo.getTime()) {
                        event.target.closest('.control-group').classList.add('error');
                        if ($('#' + id).closest('.control-group').find('.vlx_error').length === 0) {
                            var theMsg = MsgHelper.getMessage('msg.checkDate');
                            MsgHelper.showMsgOnField('#' + id, theMsg, false);
                        }
                    } else {
                        $('#' + id).closest('.control-group').find('.vlx_error').remove();
                        $('#' + fromId).closest('.control-group').find('.vlx_error').remove();
                        event.target.closest('.control-group').classList.remove('error');
                        $('#' + fromId).closest('.control-group').removeClass('error');
                    }
                }
            }
        }
    }
};

EventsHelper.prototype.changeSelectionareasFormat = function (event, changeEvent, model) {
    var value = event.target.value;
    var target = EventsHelper.prototype.getTargetSelector.call(this, event, changeEvent);
    var regEx = new RegExp('^\\d+(,\\d{0,2})?$');
    if (value === 'PRICE_DE') {
        if (!regEx.test($(target).val())) {
            $(target).val('');
        }
        $(target).blur(function (e) {
            var value = e.target.value;
            value = value.replace(/[^0-9,]+/g, '');
            var splitVal = value.split(',');
            if (splitVal.length > 2) {
                value = splitVal[0] + ',' + splitVal[1];
            }
            if (splitVal.length > 1) {
                if (splitVal[1].length === 0) {
                    value = value.substring(0, value.length - 1);
                } else if (splitVal[1].length > 2) {
                    var l = splitVal[1].length - 2;
                    value = value.substring(0, value.length - l);
                }
                while (_.string.startsWith(value, '00')) {
                    value = value.substring(1);
                }
                if (_.string.startsWith(value, '0') && value.split(',')[0] > 1) {
                    value = value.substring(1);
                }
            }
            e.target.value = value;
        });
        $(target).keypress(function (e) {
            return MiscHelper.checkDecimalNumberOnKeyPressed(e, 2);
        });
    } else {
        if (regEx.test($(target).val())) {
            $(target).val('');
        }
        $(target).unbind('keypress');
        $(target).unbind('blur');
    }
};

EventsHelper.prototype.changedProductForm = function (event, changeEvent, model, data, options) {

    var value = event.target.value;
    if (versionCountry === 'brasil' || versionCountry === 'latam') {
        var dimensionHeadline = $('.brasil #block_extent #fieldSet_sizeweight');
        if (dimensionHeadline) {
            if (value === 'DG') {
                dimensionHeadline.removeClass('mandetory');
            } else {
                dimensionHeadline.addClass('mandetory');
            }
        }
    }
};

EventsHelper.prototype.noEditBicCode = function (event, changeEvent, model, data, options) {

    if (!MiscHelper.isEmpty(model.get('bicMainSubject'))) {
        $('#block_bicbisac').attr('style', 'display: inline;');
    }
};

EventsHelper.prototype.changedProductType = function (event, changeEvent, model, data, options) {
    if (data === 'attach') {
        return;
    }

    // set the value for the new type
    var value = event.target.value;
    if (value === 'bundles') {
        model.set('type', 'nonbook');
    } else if(value === 'avbook') {
        model.set('type', 'abook');
    } else {
        model.set('type', value);
    }

    // check if we changed form journal to another product type
    var prevType = model._previousAttributes.type,
        type = model.attributes.type;
    if (prevType === 'journal' && type !== 'journal') {
        var msg = MsgHelper.getMessage('label.form.lightbox.zisinfo');
    }

    // change the "main" divs class.
    var mainDiv = $('#content-container > .form-horizontal > div');
    mainDiv.removeClass();
    mainDiv.attr('class', 'uiType-' + value)

    return this.changePriceBlocks('identicTax', event, changeEvent, model, data, options);
};

EventsHelper.prototype.bundleKindChange = function (event, changeEvent, model, data, options) {
    var value = event.target.value;
    if (value === 'identicTax') {
        $('#block_prices').addClass('mandetory');
        $('.form-horizontal#product_edit #block_productType #fieldSet_bundleswitch .radio-inline label[for=bundleswitch-duoTax]').attr('style', 'width: 340px;');
        $('.form-horizontal#product_edit #block_productType #fieldSet_bundleswitch .radio-inline label[for=bundleswitch-multiTax]').attr('style', 'display: none;');
        $('.form-horizontal#product_edit #block_productType #fieldSet_bundleswitch .radio-inline #bundleswitch-multiTax').attr('style', 'display: none;');
    } else {
        $('#block_prices').removeClass('mandetory');
        if (data === 'attach') {
            // on attaching the bundle switch check if multiBundle or duoBundle is checked. In the case of duoBundle
            // hide the selection of multiBundles.
            if (value === 'duoTax') {
                $('.form-horizontal#product_edit #block_productType #fieldSet_bundleswitch .radio-inline label[for=bundleswitch-duoTax]').attr('style', 'width: 340px;');
                $('.form-horizontal#product_edit #block_productType #fieldSet_bundleswitch .radio-inline label[for=bundleswitch-multiTax]').attr('style', 'display: none;');
                $('.form-horizontal#product_edit #block_productType #fieldSet_bundleswitch .radio-inline #bundleswitch-multiTax').attr('style', 'display: none;');
            } else {
                $('.form-horizontal#product_edit #block_productType #fieldSet_bundleswitch .radio-inline label[for=bundleswitch-duoTax]').attr('style', '');
                $('.form-horizontal#product_edit #block_productType #fieldSet_bundleswitch .radio-inline label[for=bundleswitch-multiTax]').attr('style', 'display: inline;');
                $('.form-horizontal#product_edit #block_productType #fieldSet_bundleswitch .radio-inline #bundleswitch-multiTax').attr('style', 'display: inline;');
            }
        }
        var retailPrices = model.get('retailPrices');
        for (var i = 0; i < retailPrices.length; i++) {
            if (retailPrices[i] != null && retailPrices[i].country === 'CH' && MiscHelper.isEmpty(retailPrices[i].taxRate)) {
                model.set('retailPrices.' + i + '.taxRate', 'S');
            }
        }
    }

    if (data === 'attach') {
        return;
    }

    model.set('pricing', true);

    // var value = event.target.value;
    if (value === 'identicTax') {
        model.set('type', 'nonbook');
    }

    return this.changePriceBlocks(value, event, changeEvent, model, data, options);
};

EventsHelper.prototype.changePriceBlocks = function (taxSplitting, event, changeEvent, model, data, options) {

    if (user.authorities.indexOf('SHOW_BUNDLESWITCH') < 0) {
        return false;
    }

    var value = taxSplitting;
    var retailPrices = model.get('retailPrices');

    if (value === 'identicTax') {
        //model.set('type', 'nonbook');
        var hasGermanRetailPrice = false;
        var hasAustrianRetailPrice = false;
        $('#block_priceDuoBundle').addClass('hidden');
        $('#block_priceMultiBundle').addClass('hidden');
        //$('#block_prices').addClass('mandetory');
        for (var i = 0; i < retailPrices.length; i++) {
            if (retailPrices[i] !== null) {
                if (retailPrices[i].country === 'DE') {
                    hasGermanRetailPrice = true;
                } else if (retailPrices[i].country === 'AT') {
                    hasAustrianRetailPrice = true;
                }
            }
        }
        if (!hasGermanRetailPrice) {
            var theNextPrice = {};
            theNextPrice.id = MiscHelper.uuid();
            theNextPrice.validFrom = null;
            theNextPrice.validUntil = null;
            theNextPrice.value = null;
            theNextPrice.calculated = false;
            theNextPrice.country = 'DE';
            theNextPrice.currency = 'EUR';
            theNextPrice.currencyCountry = 'EUR_DE';
            theNextPrice.value = null;
            theNextPrice.provisional = false;
            theNextPrice.taxRate = null;
            theNextPrice.type = '04';
            theNextPrice.typeTypeQualifier = '04';
            theNextPrice.priceTypeGroup = "RETAIL";
            retailPrices.splice(0, 0, theNextPrice);
            model.set('retailPrices', retailPrices);
            //model.get('retailPrices').push(theNextPrice);
        }
        if (!hasAustrianRetailPrice) {
            var theNextPrice = {};
            theNextPrice.id = MiscHelper.uuid();
            theNextPrice.validFrom = null;
            theNextPrice.validUntil = null;
            theNextPrice.value = null;
            theNextPrice.calculated = false;
            theNextPrice.country = 'AT';
            theNextPrice.currency = 'EUR';
            theNextPrice.currencyCountry = 'EUR_AT';
            theNextPrice.value = null;
            theNextPrice.provisional = false;
            theNextPrice.taxRate = null;
            theNextPrice.type = '04';
            theNextPrice.typeTypeQualifier = '04';
            theNextPrice.priceTypeGroup = "RETAIL";
            retailPrices.splice(1, 0, theNextPrice);
            model.set('retailPrices', retailPrices);
            //model.get('retailPrices').push(theNextPrice);
        }
        retailPrices = null;
    } else if (value === 'duoTax') {
        model.set('type', 'duoBundle');
        var duoBundles = [this.buildBundle(2, 'R', 'DE')];
        model.set('duoBundles', duoBundles);
        var containedItems = model.get('containedItems');
        var counter = 0;
        // now check the contained items
        for (var i = 0; i < containedItems.length; i++) {
            if (containedItems[i] !== null) {
                if (counter < 2) {
                    duoBundles[0].bundleParts[counter].containedItemID = containedItems[i].id;
                    duoBundles[0].bundleParts[counter].type = containedItems[i].type;
                }
                counter++;
            }
        }
        for (var i = counter; i < 2; i++) {
            containedItems.push({
                id: duoBundles[0].bundleParts[i].id,
                numberOfPieces: '',
                type: null
            });

            duoBundles[0].bundleParts[counter].containedItemID = duoBundles[0].bundleParts[i].id;
            duoBundles[0].bundleParts[counter].type = null;
        }
        for (var i = containedItems.length; counter > 2; i--) {
            var ci = containedItems[i - 1];
            if (ci != null) {
                counter--;
            }
            containedItems.splice(i - 1, 1);
        }
        $('#block_priceDuoBundle').removeClass('hidden');
        $('#block_priceMultiBundle').addClass('hidden');
        //$('#block_prices').removeClass('mandetory');
    } else if (value === 'multiTax') {
        model.set('type', 'multiBundle');
        var multiBundles = [this.buildBundle(3, '')];
        model.set('multiBundles', multiBundles);
        var containedItems = model.get('containedItems');
        var counter = 0;
        // now check the contained items
        for (var i = 0; i < containedItems.length; i++) {
            if (containedItems[i] !== null) {
                if (counter < 3) {
                    multiBundles[0].bundleParts[counter].containedItemID = containedItems[i].id;
                    // type mus be set by choosing a bundle part product.
                }
                counter++;
            }
        }
        for (var i = counter; i < 3; i++) {
            var ciId = MiscHelper.uuid();
            multiBundles[0].bundleParts[i].containedItemID = ciId;
            containedItems.push({
                id: ciId,
                numberOfPieces: '',
                type: null
            });
        }
        for (var i = containedItems.length; counter > 3; i--) {
            var ci = containedItems[i - 1];
            if (ci != null) {
                counter--;
            }
            containedItems.splice(i - 1, 1);
        }
        $('#block_priceDuoBundle').addClass('hidden');
        $('#block_priceMultiBundle').removeClass('hidden');
        //$('#block_prices').removeClass('mandetory');
    }
    if (retailPrices !== null) {
        for (var i = retailPrices.length - 1; i >= 0; i--) {
            if (retailPrices[i] !== null) {
                if (retailPrices[i].country === 'DE') {
                    model.set('retailPrices.' + i, null);
                } else if (retailPrices[i].country === 'AT') {
                    model.set('retailPrices.' + i, null);
                }
            }
        }
    }
    return true;
};

EventsHelper.prototype.buildPricesHead = function (numberOfParts, taxRate, priceTypeCode, aCountry) {

    var country = aCountry;
    if (country === undefined) {
        country = 'DE'
    }
    var currency;
    var currencyCountry;
    if (country === 'DE') {
        currency = 'EUR';
    } else if (country === 'AT') {
        currency = 'EUR';
    }
    currencyCountry = currency + '_' + country;


    var pricesHead = {
        headPriceRecordID: MiscHelper.uuid(),
        priceParts: [],
        country: country,
        currency: currency,
        currencyCountry: currencyCountry,
        // grossPriceAmount: 0,
        priceTypeCode: priceTypeCode,
        taxRate: taxRate,
        unitPriceAsString: (priceTypeCode === '02' || priceTypeCode === '04') ? 'true' : 'false',
        uvp: false,
        validFrom: null,
        validUntil: null
    };

    for (var i = 0; i < numberOfParts; i++) {
        pricesHead.priceParts.push({
            partPriceRecordID: MiscHelper.uuid(),
            containedItemRecordID: MiscHelper.uuid(),
            identifier: null,
            productIdentifierType: 'IS',
            priceTypeCode: i % 2 === 1 ? '14' : '04',
            taxAmount: null,
            taxRate: taxRate,
            productTypeCode: null,
            grossPriceAmount: null,
            netPriceAmount: null
        });
    }

    return pricesHead;

};

EventsHelper.prototype.buildBundle = function (numberOfParts, taxRate, aCountry, aCurrency) {

    var country = aCountry ? aCountry : 'DE';
    var currency = aCurrency ? aCurrency : 'EUR';
    var currencyCountry = currency + '_' + country;

    var bundle = {
        id: MiscHelper.uuid(),
        bundleParts: [],
        country: country,
        currency: currency,
        currencyCountry: currencyCountry,
        sum: 0,
        type: '04',
        validFrom: null,
        validUntil: null
    };
    for (var i = 0; i < numberOfParts; i++) {
        bundle.bundleParts.push({
            id: MiscHelper.uuid(),
            gtin: null,
            productIdentifierType: '03',
            taxAmount: null,
            taxRate: taxRate,
            type: null,
            value: null,
            valueNetto: null
        });
    }

    return bundle;
};

EventsHelper.prototype.bundleLabel = function (event, changeEvent, model, data, options) {

    var parts = event.target.id.split('_');
    var position = parts[parts.length - 1];
    position = parseInt(position) + 1;
    $(event.target).html('<b>' + position + '.<span class="asterisk">*</span>:</b>');
};

EventsHelper.prototype.journalLabel = function (event, changeEvent, model, data, options) {

    var parts = event.target.id.split('_');
    var position = parts[parts.length - 1];
    position = parseInt(position) + 1;
    $(event.target).html('<b>' + position + '.<span class="asterisk">*</span>:</b>');
};

EventsHelper.prototype.journalPartChange = function (event, changeEvent, model, data, options) {
    if (data === 'attach') {
        return;
    }

    var value = event.target.value;
    var splits = event.target.id.split('_');
    var bundleType = splits[0];
    var pricePart = splits[2];
    var attribute = splits[3];
    var rowIndex = splits[4];
    var bundles = model.get(bundleType);
    var selector;
    var attr;
    var codelistsTaxRates = FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '62');

    for (var priceIndex = 1; priceIndex < bundles.length; priceIndex++) {
        var bundleParts = bundles[priceIndex].priceParts;
        //for (var rowIndex = 0; rowIndex < bundleParts.length; rowIndex++)
        {
            attr = bundleType + '.' + priceIndex + '.' + pricePart + '.' + rowIndex + '.' + attribute;
            model.set(attr, value);
            selector = '#' + bundleType + '_' + priceIndex + '_' + pricePart + '_' + attribute + '_' + rowIndex;
            if (attribute === 'taxRate') {
                $(selector).text(FormHelper.getCodelistLabel(codelistsTaxRates, value));
            }
        }
    }

};

EventsHelper.prototype.duoBundlePartChange = function (event, changeEvent, model, data, options) {
    if (data === 'attach') {
        return;
    }

    var value = event.target.value;
    var splits = event.target.id.split('_');
    var bundleType = splits[0];
    var attribute = splits[3];
    var rowIndex = splits[4];
    var bundles = model.get(bundleType);
    var selector;
    var attr;
    var codelistsPriceTypes = FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '7');
    var codelistsTaxRates = FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '62');
    var productIdentifierType = FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, 'productIdentifierType');

    for (var priceIndex = 1; priceIndex < bundles.length; priceIndex++) {
        var bundleParts = bundles[priceIndex].bundleParts;
        //for (var rowIndex = 0; rowIndex < bundleParts.length; rowIndex++)
        {
            attr = bundleType + '.' + priceIndex + '.bundleParts.' + rowIndex + '.' + attribute;
            model.set(attr, value);
            selector = '#' + bundleType + '_' + priceIndex + '_bundleParts_' + attribute + '_' + rowIndex;
            if (attribute === 'gtin') {
                $(selector).text(value);
            } else if (attribute === 'type') {
                $(selector).text(FormHelper.getCodelistLabel(codelistsPriceTypes, value));
            } else if (attribute === 'taxRate') {
                $(selector).text(FormHelper.getCodelistLabel(codelistsTaxRates, value));
            } else if (attribute === 'productIdentifierType') {
                $(selector).html(FormHelper.getCodelistLabel(productIdentifierType, value));
            }
        }
    }
};

EventsHelper.prototype.bundleJournalIdentChange = function (event, changeEvent, model, data, options) {
    if (data === 'attach') {
        return;
    }
    var splits = event.target.id.split('_');
    var bundleKind = splits[0];
    var priceIndex = splits[1];
    var rowIndex = splits[4];
    var attr;
    var bundleKindAttr = bundleKind + '.';
    var ident;

    attr = bundleKindAttr + priceIndex + '.priceParts.' + rowIndex + '.productIdentifierType';
    var productIdentifierType = model.get(attr);

    var codelistsTaxRates = FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '62');

    if (bundleKind === 'pricesJournalBundle') {
        if (productIdentifierType === '01') {
            return false;
        }
        attr = bundleKindAttr + priceIndex + '.priceParts.' + rowIndex + '.identifier';
        ident = model.get(attr);
    } else {
        ident = event.target.value;
    }

    var bundleKindSelector = '#' + bundleKind + '_';
    var selector;
    var bundles = model.get(bundleKind);

    if (MiscHelper.isEmpty(ident)) {
        for (var priceIndex = 0; priceIndex < bundles.length; priceIndex++) {
            attr = bundleKindAttr + priceIndex + '.priceParts.' + rowIndex + '.productTypeCode';
            model.set(attr, null);
            selector = bundleKindSelector + priceIndex + '_priceParts_productTypeCode_' + rowIndex;
            if ($(selector).is('span')) {
                $(selector).text('');
            } else {
                $(selector).select2('val', '');
            }
            attr = bundleKindAttr + priceIndex + '.priceParts.' + rowIndex + '.taxRate';
            model.set(attr, null);
            selector = bundleKindSelector + priceIndex + '_priceParts_taxRate_' + rowIndex;
            if ($(selector).is('span')) {
                $(selector).text(FormHelper.getCodelistLabel(codelistsTaxRates, 'R'));
            } else {
                $(selector).select2('val', 'R');
            }
            attr = bundleKindAttr + priceIndex + '.priceParts.' + rowIndex + '.taxAmount';
            model.set(attr, null);
            selector = bundleKindSelector + priceIndex + '_priceParts_taxAmount_' + rowIndex;
            $(selector).val('');
            attr = bundleKindAttr + priceIndex + '.priceParts.' + rowIndex + '.netPriceAmount';
            model.set(attr, null);
            selector = bundleKindSelector + priceIndex + '_priceParts_netPriceAmount_' + rowIndex;
            $(selector).val('');
            this.recalculateBundleValue(bundleKind, priceIndex, model);
        }
        // ToDo check if it is not better to return undefined and set the ui values direct.
        return undefined;
    }


    var product = undefined;
    var that = this;
    var retVal = undefined;

    $.ajax(
        {
            dataType: 'json',
            url: apiRoot + '/product/byIdentifier/' + ident + '?access_token=' + user.access_token,
            type: 'GET',
            async: false,
            success: function (data) {
                var product = data.product;
                var identifier = ident;
                for (var i = 0; i < product.identifiers.length; i++) {
                    // May be 03 for a GTIN or IS for ISSN
                    if (product.identifiers[i].type === productIdentifierType) {
                        identifier = product.identifiers[i].value;
                        break;
                    }
                }
                var attr;
                var codelistsPriceTypes = FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '7');
                for (var priceIndex = 0; priceIndex < bundles.length; priceIndex++) {
                    if (product.form !== null) {
                        attr = bundleKindAttr + priceIndex + '.priceParts.' + rowIndex + '.productTypeCode';
                        model.set(attr, product.form.type);
                        selector = bundleKindSelector + priceIndex + '_priceParts_productTypeCode_' + rowIndex;
                        if ($(selector).is('span')) {
                            $(selector).text(FormHelper.getCodelistLabel(codelistsPriceTypes, product.form.type));
                        } else {
                            $(selector).select2('val', product.form.type);
                        }
                    } else {
                        attr = bundleKindAttr + priceIndex + '.priceParts.' + rowIndex + '.productTypeCode';
                        model.set(attr, null);
                        selector = bundleKindSelector + priceIndex + '_priceParts_productTypeCode_' + rowIndex;
                        if ($(selector).is('span')) {
                            $(selector).text('');
                        } else {
                            $(selector).select2('val', '');
                        }
                    }
                    var taxRate = null;
                    for (var i = 0; i < product.retailPrices.length; i++) {
                        if (product.retailPrices[i].country === 'DE') {
                            taxRate = product.retailPrices[i].taxRate;
                            attr = bundleKindAttr + priceIndex + '.priceParts.' + rowIndex + '.taxRate';
                            model.set(attr, taxRate);
                            selector = bundleKindSelector + priceIndex + '_priceParts_taxRate_' + rowIndex;
                            if ($(selector).is('span')) {
                                $(selector).text(FormHelper.getCodelistLabel(codelistsTaxRates, taxRate));
                            } else {
                                $(selector).select2('val', taxRate);
                            }
                            break;
                        }
                    }
                    if (taxRate === null) {
                        selector = bundleKindSelector + priceIndex + '_priceParts_taxRate_' + rowIndex;
                        if (bundleKind === 'duoBundles') {
                            $(selector).select2('val', 'R');
                        } else {
                            $(selector).text('');
                        }
                    }
                    attr = bundleKindAttr + priceIndex + '.priceParts.' + rowIndex + '.identifier';
                    model.set(attr, identifier);
                    selector = bundleKindSelector + priceIndex + '_priceParts_identifier_' + rowIndex;
                    if ($(selector).is('span')) {
                        $(selector).text(identifier);
                    } else {
                        $(selector).val(identifier);
                    }
                    var targetProductBundlePrice = product.bundlePrices[priceIndex];
                    if (targetProductBundlePrice) {
                        attr = bundleKindAttr + priceIndex + '.priceParts.' + rowIndex + '.taxAmount';
                        model.set(attr, targetProductBundlePrice.taxAmount);
                        attr = bundleKindAttr + priceIndex + '.priceParts.' + rowIndex + '.netPriceAmount';
                        model.set(attr, targetProductBundlePrice.taxableAmount);
                        selector = bundleKindSelector + priceIndex + '_priceParts_taxAmount_' + rowIndex;
                        var value = targetProductBundlePrice.taxAmount;
                        if ((typeof value).toLowerCase() === 'string') {
                            value = parseFloat(value);
                        }
                        value = value.toFixed(2);
                        value = value.replace(/\./, ",");
                        $(selector).val(value);
                        value = targetProductBundlePrice.taxableAmount;
                        if ((typeof value).toLowerCase() === 'string') {
                            value = parseFloat(value);
                        }
                        value = value.toFixed(2);
                        value = value.replace(/\./, ",");
                        selector = bundleKindSelector + priceIndex + '_priceParts_netPriceAmount_' + rowIndex;
                        $(selector).val(value);
                        that.recalculateBundleValue(bundleKind, priceIndex, model);
                    } else {
                        attr = bundleKindAttr + priceIndex + '.priceParts.' + rowIndex + '.taxAmount';
                        model.set(attr, null);
                        attr = bundleKindAttr + priceIndex + '.priceParts.' + rowIndex + '.netPriceAmount';
                        model.set(attr, null);
                        selector = bundleKindSelector + priceIndex + '_priceParts_taxAmount_' + rowIndex;
                        $(selector).val('');
                        selector = bundleKindSelector + priceIndex + '_priceParts_netPriceAmount_' + rowIndex;
                        $(selector).val('');
                        that.recalculateBundleValue(bundleKind, priceIndex, model);
                    }
                }
            },
            error: function (data) {
                console.log('error');
            },
            failure: function (data) {
                console.log('failure');
            }
        });

    return retVal;
};

EventsHelper.prototype.bundleGtinChanged = function (event, changeEvent, model, data, options) {
    if (data === 'attach') {
        return;
    }
    var splits = event.target.id.split('_');
    var bundleKind = splits[0];
    var priceIndex = splits[1];
    var rowIndex = splits[4];
    var attr;
    var bundleKindAttr = bundleKind + '.';
    var ident;

    if (bundleKind === 'duoBundles') {
        attr = bundleKindAttr + priceIndex + '.bundleParts.' + rowIndex + '.productIdentifierType';
        var productIdentifierType = model.get(attr);
        if (productIdentifierType === '01') {
            return false;
        }
        attr = bundleKindAttr + priceIndex + '.bundleParts.' + rowIndex + '.gtin';
        ident = model.get(attr);
    } else {
        ident = event.target.value;
    }

    var bundleKindSelector = '#' + bundleKind + '_';
    var selector;
    var bundles = model.get(bundleKind);

    if (MiscHelper.isEmpty(ident)) {
        for (var priceIndex = 0; priceIndex < bundles.length; priceIndex++) {
            attr = bundleKindAttr + priceIndex + '.bundleParts.' + rowIndex + '.gtin';
            model.set(attr, null);
            selector = bundleKindSelector + priceIndex + '_bundleParts_gtin_' + rowIndex;
            $(selector).val(null);
            attr = bundleKindAttr + priceIndex + '.bundleParts.' + rowIndex + '.type';
            model.set(attr, null);
            selector = bundleKindSelector + priceIndex + '_bundleParts_type_' + rowIndex;
            $(selector).text('');
            attr = bundleKindAttr + priceIndex + '.bundleParts.' + rowIndex + '.taxAmount';
            model.set(attr, null);
            selector = bundleKindSelector + priceIndex + '_bundleParts_taxAmount_' + rowIndex;
            $(selector).val('');
            attr = bundleKindAttr + priceIndex + '.bundleParts.' + rowIndex + '.valueNetto';
            model.set(attr, null);
            selector = bundleKindSelector + priceIndex + '_bundleParts_valueNetto_' + rowIndex;
            $(selector).val('');
            attr = bundleKindAttr + priceIndex + '.bundleParts.' + rowIndex + '.taxRate';
            model.set(attr, null);
            selector = bundleKindSelector + priceIndex + '_bundleParts_taxRate_' + rowIndex;
            $(selector).text('');
            this.recalculateBundleValue(bundleKindAttr, priceIndex, model);
        }
        // ToDo check if it is not better to return undefined and set the ui values direct.
        return undefined;
    }

    var product = undefined;
    var that = this;
    var retVal = undefined;

    $.ajax(
        {
            dataType: 'json',
            url: apiRoot + '/product/byIdentifier/' + ident + '?access_token=' + user.access_token,
            type: 'GET',
            async: false,
            success: function (data) {
                var product = data.product;
                var identifier = ident;
                for (var i = 0; i < product.identifiers.length; i++) {
                    if (product.identifiers[i].type === '03') {
                        identifier = product.identifiers[i].value;
                        break;
                    }
                }
                var attr;
                var codelistsPriceTypes = FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '7');
                var codelistsTaxRates = FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '62');
                for (var priceIndex = 0; priceIndex < bundles.length; priceIndex++) {
                    if (product.form !== null) {
                        attr = bundleKindAttr + priceIndex + '.bundleParts.' + rowIndex + '.type';
                        model.set(attr, product.form.type);
                        selector = bundleKindSelector + priceIndex + '_bundleParts_type_' + rowIndex;
                        if (bundleKind === 'duoBundles') {
                            $(selector).select2('val', product.form.type);
                        } else {
                            $(selector).text(FormHelper.getCodelistLabel(codelistsPriceTypes, product.form.type));
                        }
                    } else {
                        attr = bundleKindAttr + priceIndex + '.bundleParts.' + rowIndex + '.type';
                        model.set(attr, null);
                        selector = bundleKindSelector + priceIndex + '_bundleParts_type_' + rowIndex;
                        if (bundleKind === 'duoBundles') {
                            $(selector).select2('val', '');
                        } else {
                            $(selector).text('');
                        }
                    }
                    var taxRate = null;
                    for (var i = 0; i < product.retailPrices.length; i++) {
                        if (product.retailPrices[i].country === 'DE') {
                            taxRate = product.retailPrices[i].taxRate;
                            attr = bundleKindAttr + priceIndex + '.bundleParts.' + rowIndex + '.taxRate';
                            model.set(attr, taxRate);
                            selector = bundleKindSelector + priceIndex + '_bundleParts_taxRate_' + rowIndex;
                            if (bundleKind === 'duoBundles') {
                                $(selector).select2('val', taxRate);
                            } else {
                                $(selector).text(FormHelper.getCodelistLabel(codelistsTaxRates, taxRate));
                            }
                            break;
                        }
                    }
                    if (taxRate === null) {
                        selector = bundleKindSelector + priceIndex + '_bundleParts_taxRate_' + rowIndex;
                        if (bundleKind === 'duoBundles') {
                            $(selector).select2('val', 'R');
                        } else {
                            $(selector).text('');
                        }
                    }
                    attr = bundleKindAttr + priceIndex + '.bundleParts.' + rowIndex + '.gtin';
                    model.set(attr, identifier);
                    selector = bundleKindSelector + priceIndex + '_bundleParts_gtin_' + rowIndex;
                    if ($(selector).is('span')) {
                        $(selector).text(identifier);
                    } else {
                        $(selector).val(identifier);
                    }
                    var targetProductBundlePrice = product.bundlePrices[priceIndex];
                    if (targetProductBundlePrice) {
                        attr = bundleKindAttr + priceIndex + '.bundleParts.' + rowIndex + '.taxAmount';
                        model.set(attr, targetProductBundlePrice.taxAmount);
                        attr = bundleKindAttr + priceIndex + '.bundleParts.' + rowIndex + '.valueNetto';
                        model.set(attr, targetProductBundlePrice.taxableAmount);
                        selector = bundleKindSelector + priceIndex + '_bundleParts_taxAmount_' + rowIndex;
                        var value = targetProductBundlePrice.taxAmount;
                        if ((typeof value).toLowerCase() === 'string') {
                            value = parseFloat(value);
                        }
                        value = value.toFixed(2);
                        value = value.replace(/\./, ",");
                        $(selector).val(value);
                        value = targetProductBundlePrice.taxableAmount;
                        if ((typeof value).toLowerCase() === 'string') {
                            value = parseFloat(value);
                        }
                        value = value.toFixed(2);
                        value = value.replace(/\./, ",");
                        selector = bundleKindSelector + priceIndex + '_bundleParts_valueNetto_' + rowIndex;
                        $(selector).val(value);
                        that.recalculateBundleValue(bundleKindAttr, priceIndex, model);
                    } else {
                        attr = bundleKindAttr + priceIndex + '.bundleParts.' + rowIndex + '.taxAmount';
                        model.set(attr, null);
                        attr = bundleKindAttr + priceIndex + '.bundleParts.' + rowIndex + '.valueNetto';
                        model.set(attr, null);
                        selector = bundleKindSelector + priceIndex + '_bundleParts_taxAmount_' + rowIndex;
                        $(selector).val('');
                        selector = bundleKindSelector + priceIndex + '_bundleParts_valueNetto_' + rowIndex;
                        $(selector).val('');
                        that.recalculateBundleValue(bundleKindAttr, priceIndex, model);
                    }
                }
            },
            error: function (data) {
                console.log('error');
            },
            failure: function (data) {
                console.log('failure');
            }
        });

    return retVal;
};

EventsHelper.prototype.initSearchPhrase = function (event, changeEvent, model, data, options) {
    var value = event.target.value;
    var target = EventsHelper.prototype.getTargetSelector.call(this, event, changeEvent);
//    $(target).val('');
//    $(target).unbind('keydown');

    var localName = FormHelper.selector2Id(target);

    if (model[localName] !== undefined) {
        if (model[localName].condition !== value) {
            // re-initialize typeahead widget
            var ajaxUrl = model[localName].baseUrl;
            var useThisAjaxURL = ajaxUrl;

            if (value !== undefined) {
                useThisAjaxURL += '/' + value;
            }

            useThisAjaxURL += '?search=%QUERY&access_token=' + user.access_token;

            // construct datasource
            var dataSource = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                remote: {
                    url: useThisAjaxURL,
                    wildcard: '%QUERY',
                    filter: function (response) {
                        return response;
                    }
                }
            });

            var config = model[localName].typeaheadConfig;
            var data = {
                name: value,
                displayKey: 'value',
                source: dataSource
            };

            model[localName].typeaheadData = data;

            $(target).typeahead('destroy');
            $(target).typeahead(config, data);
        } else {
            // don't do anything in here, this is just for saftey purposes...
        }
    }
//    $(target).typeahead('destroy');
//    console.log('value: ' + value);
};

EventsHelper.prototype.mediumChange = function (event, changeEvent, model, data, options) {

    // set the asterisk before parameter data is checked, because the asterisk must be removed in edit mode independing
    // from a real mediumChange event.
    if (event.val === "WW" || model.attributes.zisInfo.medium === "WW") {
        $('#block_pricesJournal').removeClass('mandetory');
    } else {
        $('#block_pricesJournal').addClass('mandetory');
    }

    if (data === 'attach') {
        return;
    }

    var retVal = undefined;

    if (event.val === 'DG' || model.attributes.zisInfo.medium === "DG") {
        $('.extentebook .fileformat label').text(MsgHelper.getMessage('label.form.product.fieldset.form.ejorunalformat'));
    } else {
        // if we don't have an e-journal as medium remove all the extent related infos.
        // Reset e-book attributes if medium is switched.
        model.set('extent.drmCode', null);
        $("#extent_drmCode").select2("val", "");
        model.set('extent.epubTypeVersion', null);
        model.set('extent.fileFormat', null);
        $("#extent_fileFormat").select2("val", "");
    }

    // if we have an zis bundle product then change the label (ticket #12417)
    if (event.val === "WW" || model.attributes.zisInfo.medium === "WW") {
        var labelsCreate = $('.identifiers .issnswitch .radio-inline label');
        labelsCreate.each(function () {
            $(this).text($(this).text().replace(MsgHelper.getMessage('label.form.product.issn'), MsgHelper.getMessage('label.form.product.issnl')));
        });
        var labelsEdit = $('.identifiers .issn label');
        labelsEdit.each(function () {
            $(this).text($(this).text().replace(MsgHelper.getMessage('label.form.product.issn'), MsgHelper.getMessage('label.form.product.issnl')));
        });

        model.set('bundleswitch', 'duoTax');
        $('#block_pricesJournalBundle').removeClass('hidden');
        // initialize journal bundle prices
        var pricesJournalBundle = [this.buildPricesHead(2, 'R', '04'), this.buildPricesHead(2, 'R', '14')];
        model.set('pricesJournalBundle', pricesJournalBundle);
        // initialize journal prices
        var pricesJournal = model.get('pricesJournal');
        for (var i = 0; i < pricesJournal.length; i++) {
            var priceHead = pricesJournal[i];
            if (priceHead.country === 'DE') {
                pricesJournal.splice(i, 1);
                break;
            }
        }
        if (pricesJournal.length === 0) {
            pricesJournal = [this.buildPricesHead(2, 'R', '04', 'AT')];
        }
        model.set('pricesJournal', pricesJournal);
        model.set('pricingJournal', true);
        var containedItems = model.get('containedItems');
        var counter = 0;
        // now check the contained items
        for (var i = 0; i < containedItems.length; i++) {
            if (containedItems[i] !== null) {
                if (counter < 2) {
                    for (var j = 0; j < pricesJournalBundle.length; j++) {
                        pricesJournalBundle[j].priceParts[counter].containedItemRecordID = containedItems[i].id;
                        pricesJournalBundle[j].priceParts[counter].productTypeCode = containedItems[i].type;
                    }
                }
                counter++;
            }
        }
        for (var i = counter; i < 2; i++) {
            containedItems.push({
                id: pricesJournalBundle[0].priceParts[i].containedItemRecordID,
                numberOfPieces: '',
                type: null
            });
        }
        retVal = true;
    } else {
        $('.identifiers .issn label').text(MsgHelper.getMessage('label.form.product.issn'));
        var labels = $('.identifiers .issnswitch .radio-inline label');
        labels.each(function () {
            $(this).text($(this).text().replace(MsgHelper.getMessage('label.form.product.issnl'), MsgHelper.getMessage('label.form.product.issn')));
        });
        if (model.get('bundleswitch') !== 'identicTax') {
            model.set('bundleswitch', 'identicTax');
            // $('#block_pricesJournal').removeClass('hidden');
            $('#block_pricesJournalBundle').addClass('hidden');
            retVal = true;
        }
    }

    return retVal;

};

EventsHelper.prototype.issnChange = function (event, changeEvent, model, data, options) {
    if (data === 'attach') {
        return;
    }

    var $issn = $('.identifiers .issn');
    var input = $issn.find('input[type=text]');

    if (event.target.value === 'with') {
        $issn.removeClass('hidden');
    } else {
        $issn.addClass('hidden');
        input.val('');

        var fiIdentifier;
        for (var i = 0; i < model.get('identifiers').length; i++) {
            if (model.get('identifiers')[i].type === 'FI') {
                fiIdentifier = model.get('identifiers')[i];
                break;
            }
        }
        if (fiIdentifier === undefined) {
            model.set('identifiers.' + i + '.id', MiscHelper.uuid());
            model.set('identifiers.' + i + '.type', 'FI');
            model.set('identifiers.' + i + '.value', 'todo');
        } else if (MiscHelper.isEmpty(model.get('identifiers')[i].value)) {
            model.set('identifiers.' + i + '.value', 'todo');
        }
    }


// if (event.target.value === 'with') {
//     $issn.removeClass('hidden');
//     for (var i=0; i < model.get('identifiers').length; i++) {
//     	if (model.get('identifiers')[i].type === 'FI') {
//     		model.set('identifiers.' + i + '.type', 'IS');
//     		model.set('identifiers.' + i + '.value', '');
//     	}
//     }
// } else {
//     $issn.addClass('hidden');
//     for (var i=0; i < model.get('identifiers').length; i++) {
//     	if (model.get('identifiers')[i].type === 'IS') {
//     		model.set('identifiers.' + i + '.type', 'FI');
//     		model.set('identifiers.' + i + '.value', 'todo');
//     	}
//     }
// }
}
;

EventsHelper.prototype.addArchivedReason = function (event, changeEvent, model, data, options) {
    var $archivedDate = $("#archivedDate");
    if (model.attributes.type !== 'journal') {
        return;
    }

    var $archivedReason = $(".archivedReason");
    var value = event.target.value;

    var today = new Date();
    var archivedDate = model.get('archivedDate');

    if (archivedDate !== "" || archivedDate !== null) {
        var archivedDateAry = archivedDate.split('.');
        archivedDate = new Date(archivedDateAry[2], parseInt(archivedDateAry[1]) - 1, archivedDateAry[0], 0, 0, 0, 0);
    }

    if (data === "attach") {
        // should the archived date be visible?
        // show date when product is inactive or when product is active and archived date is in future (#13201)
        var showDate = !model.get('active') || (model.get('active') && archivedDate !== "" && archivedDate.getTime() > today.getTime());

        if (!showDate) {
            model.set('archivedDate', null);
            model.set('archivedReason', null);
            $archivedDate.val('');
            $('#archivedReason').select2('val', '');
            $archivedReason.addClass('hidden');
            value = "";
        }
    }

    if (!MiscHelper.isEmpty(value)) {
        $archivedReason.removeClass('hidden');
    } else {
        $archivedReason.addClass('hidden');
    }

    // set the availability only if the archivedDate is in the past or the current day
    if (archivedDate !== "" && archivedDate.getTime() <= today.getTime()) {
        model.set('availability', '40');
    } else {
        model.set('availability', '10');
    }
    if (model.get('archivedDate') !== "" || model.get('archivedDate') !== null) {
        model.set('archivedDate', model.get('archivedDate'));
    }

};

EventsHelper.prototype.setArchivedReason = function (event, changeEvent, model, data, options) {
    if (data === 'attach') {
        return;
    }

    if (model.attributes.type !== 'journal') {
        return;
    }


    var today = new Date();
    var archivedDate = model.get('archivedDate');

    if (archivedDate !== "" || archivedDate !== null) {
        var archivedDateAry = archivedDate.split('.');
        archivedDate = new Date(archivedDateAry[1] + '-' + archivedDateAry[0] + '-' + archivedDateAry[2]);
    }

    // as I don't see any other way... we need to work with a mapping array
    availabilitiesMapping = new Array;
    availabilitiesMapping['NOT_DELIVERABLE'] = 51;
    availabilitiesMapping['WITHDRAWN_FROM_SALE'] = 46;
    availabilitiesMapping['it'] = 46;
    availabilitiesMapping['lzt'] = 98;
    availabilitiesMapping['rnb'] = 98;
    availabilitiesMapping['vte'] = 98;

    // set the availability only if the archivedDate is in the past or the current day
    if (archivedDate !== "" && archivedDate.getTime() <= today.getTime()) {
        if (event.val !== "") {
            model.set('availability', availabilitiesMapping[event.val]);
        } else {
            model.set('availability', '40');
        }
    } else {
        model.set('availability', '10');
    }

    if (model.get('archivedDate') !== "" || model.get('archivedDate') !== null) {
        model.set('archivedDate', model.get('archivedDate'));
    }
};

EventsHelper.prototype.zisSubjectgroups = function (event, changeEvent, model, data, options) {
    if (model.attributes.type !== 'journal') {
        return;
    }

    var classifications = model.get('classifications');
    for (var i = 0; i < classifications.length; i++) {
        var classification = classifications[i];
        var codelist = FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '405');
        if (classification.type === 'ZS') {
            model.set('classifications.' + i + '.value', FormHelper.getCodelistLabel(codelist, classification.code));
        }
    }
};

EventsHelper.prototype.emptyZisSubGroups = function (event, changeEvent, model, data, options) {
    if (data === 'attach' || model.attributes.type !== 'journal') {
        return;
    }

    var target = event.currentTarget.id;
    var index = target.replace(/.*_/, '');
    var subcode = $('#classifications_subcode_' + index);

    // reset the subcode dropdown if you select another value from the maincode dropdown
    if (!MiscHelper.isEmpty(subcode.val())) {
        subcode.val('').trigger('change');
    }
};

EventsHelper.prototype.gameClassification = function (event, changeEvent, model, data, options) {
    if (model.attributes.type !== 'nonbook' || model.attributes.type !== 'toy') {
        return;
    }

    var classifications = model.get('classifications');
    var codelist = FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '531');
    for (var i = 0; i < classifications.length; i++) {
        var classification = classifications[i];
        if (classification.type === 'C7') {
            model.set('classifications.' + i + '.value', FormHelper.getCodelistLabel(codelist, classification.code));
        }
    }
};


module.exports = new EventsHelper;