var AjaxSpinner = require('lib/ajaxspinner_helper');

var ConfirmationOpenDialogHelper = function () {
};

ConfirmationOpenDialogHelper.prototype.openDialog = function (dialogDiv, dialogId, options, confirmFunction, cancelFunction) {

    AjaxSpinner.hide();
    // ensure that eventually previous confirmations divs are realy thrown away.
    // cklicking escape or clicking beneath the dialog just hides but does not remove
    // the dialog. Perhaps sometimes find a way to remove that dialogs direct if they
    // are not longer needed.
    $('#fancyboxes-div').empty();

    var html = $("<div id=\"" + dialogId + "Confirmation\"></div>");
    html.append(dialogDiv);
    $('#fancyboxes-div').append(html);

    var closeOnClick;
    var maxWidth;
    var minHeight;
    var openSpeed;
    var closeImmediateOnConfirm = false;
    if (options !== undefined) {
        closeOnClick = options.closeOnClick;
        maxWidth = options.maxWidth;
        minHeight = options.minHeight;
        openSpeed = options.openSpeed;
        closeImmediateOnConfirm = options.closeImmediateOnConfirm;
    }

    if (closeOnClick === undefined) {
        // check if we should preventing to close the overlay by clicking outside of it
        var closeOnClick = true;
        if (dialogId === 'migrateProductList' || dialogId === 'migrateProduct') {
            closeOnClick = false;
        }
    }
    if (maxWidth === undefined) {
        maxWidth = '880px';
    }
    if (minHeight === undefined) {
        // let fancybox calculate the minimal height.
    }
    if(openSpeed === undefined) {
        openSpeed = 250;
    }

    $.fancybox('#' + dialogId, {
        'minWidth': '480px',
        'maxWidth': maxWidth,
        'maxHeight': '800px',
        'minHeight': minHeight,
        'openSpeed': openSpeed,
        helpers: {
            overlay: {closeClick: closeOnClick} // prevents closing when clicking OUTSIDE fancybox if false
        }
    });

    $('.' + dialogId + '-close').unbind("click");
    $('.' + dialogId + '-close').click(function () {
        if(cancelFunction !== undefined) {
            cancelFunction();
        }
        $.fancybox.hideLoading();
        $.fancybox.close();
        var div = $('#' + dialogId + 'Confirmation');
        div.fadeOut('slow');
        div.remove();
    });

    $('.' + dialogId + '-confirm').unbind("click");
    if(closeImmediateOnConfirm === true) {
        $('.' + dialogId + '-confirm').click(function (event) {
            $.fancybox.hideLoading();
            $.fancybox.close();
            var div = $('#' + dialogId + 'Confirmation');
            div.fadeOut('slow');
            div.remove();

            if(confirmFunction) {
                confirmFunction(event);
            }
        });
    } else {
        $('.' + dialogId + '-confirm').click({options: options}, confirmFunction);
    }
};

module.exports = new ConfirmationOpenDialogHelper;