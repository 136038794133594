var ModelController = require('./model_controller');
var SearchQuery = require('models/searchQuery');
var MiscHelper = require('lib/misc_helper');
var FormDefinition = require('models/formDefinition');
var ProductSearchResultView = require('views/product_search_result_view');
var PageContentModel = require('models/pagecontent');
var AjaxSpinner = require('lib/ajaxspinner_helper');

var CollectionController = ModelController.extend({
    initialize: function (options) {
        this.logger = log4javascript.getLogger('controllers.CollectionController');
    },

    getModelKeyForForm: function(model) {
        return 'collection';
    },

    // HGF Do not longer redirect to list view (#14086).
    // show: function (params) {
    //     AjaxSpinner.show();
    //     this.model = this.getNewModel(params);
    //     this.model.fetch();
    //
    //     params.rh = this.model.get('identifier');
    //     params.contentType = this.model.get('collectionType');
    //     params.urlRoot = "$api/collections/collection";
    //
    //     this.list(params);
    // },

    list: function (params) {
        this.model = new SearchQuery({});
        this.model.set('sortCol', '');
        this.model.set('contentType', params.contentType);
        this.model.set('query', params.rh);
        this.model.formModel = new PageContentModel({urlRoot : '$api/collection/ident/' + params.rh});
        this.model.formModel.fetch();
        this.formDef = new FormDefinition({id: 'product/list'});
        this.view = new ProductSearchResultView({
            model: this.model,
            formDef: this.formDef,
            modelKey: 'product',
            searchType: 'collection',
            urlRoot: params.urlRoot
        });
        this.formDef.fetch();
    }
});

module.exports = CollectionController;
