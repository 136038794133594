var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <ul class=\"nav nav-tabs\" id=\"themaTab\">\n                <li class=\"active\"><a href=\"#themaSubject\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "themaDialog.tab.thema.subject", {"name":"lbl","hash":{},"data":data})))
    + "</a></li>\n                <li><a href=\"#themAddition\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "themaDialog.tab.thema.addition", {"name":"lbl","hash":{},"data":data})))
    + "</a></li>\n            </ul>\n";
},"3":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                <div id=\"themAddition\" class=\"tab-pane fade\">\n                    <ul class=\"nav nav-tabs\" id=\"themaAdditionTab\">\n                        <li class=\"active\"><a href=\"#themaAdditionHierarchy\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "themaDialog.tab.thema.hierarchy", {"name":"lbl","hash":{},"data":data})))
    + "</a></li>\n                        <li><a href=\"#themaAdditionSearch\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "themaDialog.tab.thema.search", {"name":"lbl","hash":{},"data":data})))
    + "</a></li>\n                    </ul>\n                    <div class=\"tab-content\">\n                        <div id=\"themaAdditionHierarchy\" class=\"tab-pane fade in active\">\n                            <div id=\"themaAdditiontree\" class=\"aciTree\"></div>\n                        </div>\n                        <div id=\"themaAdditionSearch\" class=\"tab-pane fade\">\n                            <input id=\"themaAdditionTreeSearch\" class=\"treesearch\" type=\"text\" value=\"\">\n                            <div id=\"themaAdditiontreeflat\" class=\"aciTree\"></div>\n                        </div>\n                    </div>\n                </div>\n";
},"5":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <p id=\"themaSubjectSelectionEmpty\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "themaDialog.tab.thema.subject.empty", {"name":"lbl","hash":{},"data":data})))
    + "</p>\n            <h3>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "themaDialog.tab.thema.addition", {"name":"lbl","hash":{},"data":data})))
    + "</h3>\n            <ul id=\"themaAdditionSelection\" class=\"list-group\">\n            </ul>\n            <p id=\"themaAdditionSelectionEmpty\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "themaDialog.tab.thema.addition.empty", {"name":"lbl","hash":{},"data":data})))
    + " </p>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"add-icons\">\n    <a href=\"javascript:;\" onclick=\"return false;\" class=\""
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "-close myfancybox-close\" title=\"Close\">CLOSE</a>\n</div>\n\n<h2><span>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.themaDialog.headline", (depth0 != null ? depth0.id : depth0), {"name":"label2","hash":{},"data":data})))
    + "</span></h2>\n\n\n<div class=\"row themaDialog\">\n    <div class=\"col-sm-6\">\n";
  stack1 = ((helpers.hasRight || (depth0 && depth0.hasRight) || helperMissing).call(depth0, "SHOW_THEMA_ADDITION", {"name":"hasRight","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "        <div class=\"tab-content\">\n            <div id=\"themaSubject\" class=\"tab-pane fade in active\">\n                <ul class=\"nav nav-tabs\" id=\"themaSubjectTab\">\n                    <li class=\"active\"><a href=\"#themaSubjectHierarchy\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "themaDialog.tab.thema.hierarchy", {"name":"lbl","hash":{},"data":data})))
    + "</a>\n                    </li>\n                    <li><a href=\"#themaSubjectSearch\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "themaDialog.tab.thema.search", {"name":"lbl","hash":{},"data":data})))
    + "</a></li>\n                </ul>\n                <div class=\"tab-content\">\n                    <div id=\"themaSubjectHierarchy\" class=\"tab-pane fade in active\">\n                        <div id=\"themaSubjecttree\" class=\"aciTree\"></div>\n                    </div>\n                    <div id=\"themaSubjectSearch\" class=\"tab-pane fade\">\n                        <input id=\"themaSubjectTreeSearch\" class=\"treesearch\" type=\"text\" value=\"\">\n                        <div id=\"themaSubjecttreeflat\" class=\"aciTree\"></div>\n                    </div>\n                </div>\n            </div>\n";
  stack1 = ((helpers.hasRight || (depth0 && depth0.hasRight) || helperMissing).call(depth0, "SHOW_THEMA_ADDITION", {"name":"hasRight","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </div>\n    </div>\n    <div id=\"themaDialogChoosenClasses\" class=\"col-sm-6\">\n        <h2>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "themaDialog.tab.thema.selection", {"name":"lbl","hash":{},"data":data})))
    + "</h2>\n        <h3>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "themaDialog.tab.thema.subject", {"name":"lbl","hash":{},"data":data})))
    + "</h3>\n        <ul id=\"themaSubjectSelection\" class=\"list-group\">\n\n        </ul>\n\n";
  stack1 = ((helpers.hasRight || (depth0 && depth0.hasRight) || helperMissing).call(depth0, "SHOW_THEMA_ADDITION", {"name":"hasRight","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\n\n</div>\n\n\n<form class=\"form-horizontal\">\n    <div class=\"form-actions align-right\">\n        <a href=\"javascript:;\" onclick=\"return false;\"\n           class=\"btn green btn-arrow "
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "-close\"><span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "cancel", {"name":"lbl","hash":{},"data":data})))
    + "</span></a>\n        <a href=\"javascript:;\" onclick=\"return false;\"\n           class=\"btn green btn-arrow "
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "-confirm\"><span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "confirm", {"name":"lbl","hash":{},"data":data})))
    + "</span></a>\n    </div>\n</form>\n\n\n\n\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}