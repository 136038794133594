var View = require('./view'),
	template = require('./templates/forms/modalReceiverRow');

module.exports = View.extend({

	container: '#modalReceiverBody',	
	tagName: 'tr',
    template: template,
    containerMethod: 'append',
    
    getTemplateData: function () {
        var data = {};
        if (this.model && this.model.attributes.modalData) {
        	
        	var items = [];
        	for (var i = 0;i < this.model.attributes.modalData.length;i++) {
        		var inner = [];
        		for (var j = 0;j < this.model.attributes.modalData[i].length;j++) {
        			inner.push(this.model.attributes.modalData[i][j]);
        		}        		
        		items.push({inner: inner});
        	}

        	data.items = items;        	  
        	data.actions = this.model.attributes.receiver.action; 
        }
        return data;
    }
});