var EditableWidget = require('./editable');
var WidgetTemplate = require('views/templates/forms/widgets/composite');
var Model = require('models/nested_model');
var MiscHelper = require('lib/misc_helper');

var CompositeWidget = EditableWidget.extend({
    initialize: function (widget, modelKey, parentProperty) {
        EditableWidget.prototype.initialize.call(this, widget, modelKey, parentProperty);
        this.hasTitle = !this['noTitle'];
    },

    render: function (model) {
        return WidgetTemplate(this, {data: arguments.data});
    },

    binding: function (model) {

        if (!this.doRender) {
            return [];
        }

        var bindings = [];
        for (var j = 0; j < this.column.length; j++) {
            for (var k = 0; k < this.column[j].widget.length; k++) {
                var w = this.column[j].widget[k];
                if (w.doRender) {
                    if (w.type === 'DateWidget') {
                        Array.prototype.push.apply(bindings, w.binding(model, w.bindingSelector, w.name));
                    } else if (w.type === 'RadioWidget' && !w.readonly) {
                        var binding = w.binding(model);
                        bindings.push(binding[0]);
                    } else if (w.type === 'NumberWidget') {
                        var binding = w.binding(model);
                        bindings.push(binding[0]);
                    } else {
                        bindings.push({name: w.name, bindingSelector: w.bindingSelector});
                    }
                }
            }
        }
        return bindings;
    },

    initModelValue: function (model, parentProperty, parentIndex) {
        // check if composite has already value on load call initModelValue on each column.
        if (this.hadValueOnLoad !== true) {
            for (var j = 0; j < this.column.length; j++) {
                for (var k = 0; k < this.column[j].widget.length; k++) {
                    var w = this.column[j].widget[k];
                    if (_.isFunction(w.initModelValue)) {
                        w.initModelValue(model);
                    }
                    if (w.hadValueOnLoad === true) {
                        this.hadValueOnLoad = true;
                    }
                }
            }
        }

        this.prefill(model);
    },

    prefill: function (model) {
        var propName = this.name;
        if (this.name.lastIndexOf('.') >= 0) {
            propName = this.name.substring(0, this.name.lastIndexOf('.'));
        }
        if (!model.has(propName)) {
            model.set(propName, new Object());
            model.get(propName).id = MiscHelper.uuid();
        }

        var tempModel = new Model();
        for (var j = 0; j < this.column.length; j++) {
            for (var k = 0; k < this.column[j].widget.length; k++) {
                var w = this.column[j].widget[k];
                if (w.type !== 'LabelWidget') {
                    w.initModelValue(tempModel);
                }
            }
        }
        var target = model.get(propName);
        var modelVals = tempModel.get(propName);
        $.extend(modelVals, target);
        model.set(propName, modelVals);

        return;
    },

    doWeHaveEvents: function () {
        for (var j = 0; j < this.column.length; j++) {
            for (var k = 0; k < this.column[j].widget.length; k++) {
                var w = this.column[j].widget[k];
                if (w.changeEvent !== undefined && w.changeEvent.length > 0) {
                    return true;
                }
                if (w.type === 'TableCompositeWidget') {
                    for (var l = 0; l < w.column.length; l++) {
                        for (var m = 0; m < w.column[l].widget.length; m++) {
                            var widget = w.column[l].widget[m];
                            if (widget.changeEvent !== undefined && widget.changeEvent.length > 0) {
                                return true;
                            }
                        }
                    }
                }
            }
        }
        return false;
    },

    events: function (model, parentProperty, parentIndex) {
        var result = {};
        for (var j = 0; j < this.column.length; j++) {
            for (var k = 0; k < this.column[j].widget.length; k++) {
                var w = this.column[j].widget[k];
                if (w.changeEvent !== undefined && w.changeEvent.length > 0) {
                    result[this.buildId(w)] = w.changeEvent;
                }
                if (w.type === 'ListCompositeWidget' || w.type === 'TableCompositeWidget') {
                    var tmpResults = w.events(model, this.property, listIndexArray[i]);
                    for (id in tmpResults) {
                        result[id] = tmpResults[id];
                    }
                }
            }
        }
        return result;
    },

    selects: function (model) {
        var selects = [];
        for (var j = 0; j < this.column.length; j++) {
            for (var k = 0; k < this.column[j].widget.length; k++) {
                var w = this.column[j].widget[k];
                if (w.type === 'SelectionWidget' || (w.type === 'RadioWidget')) {
                    selects.push({widget: w, selectMapping: w.getOptionsSelectMapping()});
                }
            }
        }
        return selects;
    },

    typeaheads: function (model) {
        var typeaheads = [];
        for (var j = 0; j < this.column.length; j++) {
            for (var k = 0; k < this.column[j].widget.length; k++) {
                var w = this.column[j].widget[k];
                if (w.type === 'TypeaheadWidget') {
                    typeaheads.push({widget: w, selectMapping: w.getOptionsSelectMapping()});
                }
            }
        }
        return typeaheads;
    },

    //hasEvents: function () {
    //    return false;
    //},

    prepareForSubmit: function (model) {
        //not implemented
    },

    initDatefieldsAfterBinding: function (model) {
        for (var j = 0; j < this.column.length; j++) {
            for (var k = 0; k < this.column[j].widget.length; k++) {
                var w = this.column[j].widget[k];
                if (w.type === 'DateWidget' && !w.readonly) {
                    w.initAfterBinding(model, '#' + this.buildId(w), this.buildName(w));
                }
            }
        }
    },

    initSelectsAfterBinding: function (allCollections, model, editable, parentProperty, parentIndex) {
        for (var j = 0; j < this.column.length; j++) {
            for (var k = 0; k < this.column[j].widget.length; k++) {
                var w = this.column[j].widget[k];
                if (w.type === 'SelectionWidget' || w.type === 'RadioWidget' || w.type === 'TypeaheadWidget') {
                    var options = [];
                    if (allCollections[w.collection] !== undefined) {
                        options = allCollections[w.collection].options;
                    }
                    w.initAfterBinding(options, model, editable, '#' + this.buildId(w), this.buildName(w));
                }
            }
        }
    }

});


module.exports = CompositeWidget;
