var MiscHelper = require('lib/misc_helper');
var MsgHelper = require('lib/msg_helper');
var EditableWidget = require('./editable');
var FormHelper = require('lib/form_helper');

// select widgets

TypeaheadWidget = EditableWidget.extend({
    initialize: function (widget, modelKey, parentProperty) {
        EditableWidget.prototype.initialize.call(this, widget, modelKey, parentProperty);
    },

    getOptions: function (formCollections) {
        return FormHelper.getCollection(formCollections, this.collection, false);
    },

    getOptionsSelectMapping: function () {
        return {
            selector: this.bindingSelector,
            key: this.name,
            showId: this.showId,
            collectionId: this.collection
        };
    },

    hasElementWithPropertyValue: function (array, property, value) {
        for (var l = 0; l < array.length; l++) {
            if (array[l][property] === value) {
                return true;
            }
        }
        return false;
    },

    initModelValue: function (model, parentProperty, parentIndex) {
        EditableWidget.prototype.initModelValue.call(this, model);
        var selectValue = '';
        var buildedName = this.buildName(this, parentIndex);
        var modelValue = model.get(buildedName);
        if (modelValue === undefined || modelValue === null) {
            modelValue = "";
        }
        if (this.multipleAsFlat === true) {
            if (MiscHelper.isEmpty(modelValue)) {
                selectValue = "";
            } else {
                selectValue = modelValue.replace(new RegExp('[ ]', 'g'), ',');
            }
        } else if ($.isArray(model.get(buildedName))) {
            for (var i = 0; i < modelValue.length; i++) {
                if (selectValue.length > 0) {
                    selectValue += ',';
                }
                // not all selects are nested object which have an id, we got
                // lists of string too
                var theValue = modelValue[i].id;
                if (theValue === undefined) {
                    theValue = modelValue[i];
                }
                selectValue += theValue;
            }
        } else if (modelValue.id) {
            selectValue = modelValue.id;
        } else {
            selectValue = modelValue;
        }

        if ((selectValue === undefined || selectValue === "") && this.multiple !== true) {
            selectValue = null;
        }
        model.set(buildedName, selectValue);
    },

    initAfterBinding: function (options, model, editable, bindingSelectorOverride, nameOverride) {
        var localBindingSelector = bindingSelectorOverride;

        var localName = nameOverride;
        if (nameOverride === undefined) {
            localName = this.name;
        }

        var typeaheadElement = $(localBindingSelector);
        var filterFunction = undefined;

        var show = true;

        if (show && editable && typeaheadElement != null && typeaheadElement.tagName !== "SPAN") {
            var minimumInputLength = 3;
            if (this.minimumInputLength !== undefined) {
                minimumInputLength = this.minimumInputLength;
            }

            // construct basic ocnfig for typeahead
            var typeaheadConfig = {
                highlight: true,
                hint: true,
                minLength: minimumInputLength
            };

            var index = localName.split('.')[1];
            var condition = model.get('conditions')[index];

            if (false && condition !== undefined) { // quickfix in order to remove suggestion temporarily

                // construct datasource url
                var ajaxUrl = options.url;
                var self = this;
                var useThisAjaxURL = ajaxUrl;

                if (condition.column !== null) {
                    useThisAjaxURL += '/' + condition.column ;
                }

                useThisAjaxURL += '?search=%QUERY&access_token=' + user.access_token;

                // construct datasource
                var dataSource = new Bloodhound({
                    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
                    queryTokenizer: Bloodhound.tokenizers.whitespace,
                    remote: {
                        url: useThisAjaxURL,
                        wildcard: '%QUERY',
                        filter: function (response) {
                            return response;
                        }
                    }
                });

                var typeaheadData = {
                    name: condition.condition,
                    displayKey: 'value',
                    source: dataSource,
                    limit: 25
                };

                model[FormHelper.selector2Id(localBindingSelector)] = {
                    condition: condition.column,
                    baseUrl: options.url,
                    typeaheadConfig: typeaheadConfig,
                    typeaheadData: typeaheadData
                };

//                console.log("jquery id " + localName + " uses " + useThisAjaxURL);
                typeaheadElement.bind('typeahead:select', function(ev, suggestion) {
                    $(ev.target).change();
                });

                typeaheadElement.addClass('typeahead');
                typeaheadElement.typeahead(typeaheadConfig, typeaheadData);
            }
        }

    }

});

module.exports = TypeaheadWidget;
