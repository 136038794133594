
var Model = require('./model');

var FormCollection = Model.extend({
	
    urlRoot: function() {
    	return this.get('url_root');
    },

	fetch: function (options) {
        var theOptions = options ? options : {};

        theOptions['success'] = function (model, response, options) {
	    	
	    };
	    theOptions['cache'] = false;
	    theOptions['async'] = false;
	    return Model.prototype.fetch.call(this, theOptions);
	}

});

module.exports = FormCollection;