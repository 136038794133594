//
var ModelController = require('./model_controller');
var BatchSearchView = require('views/batch_search_view');
var QuickSearchView = require('views/quick_search_view');
var AdvancedSearchView = require('views/advanced_search_view');
var BooleSearchView = require('views/boole_search_view');
var FormDefinition = require('models/formDefinition');
var SearchQuery = require('models/searchQuery');
var MsgHelper = require('lib/msg_helper');
var ProductSearchResultView = require('views/product_search_result_view');
var BatchSearchResultView = require('views/batch_search_result_view');
var SearchHistoryView = require('views/search_history_view');
var MiscHelper = require('lib/misc_helper');
var LinkHelper = require('lib/link_helper');
var AjaxSpinner = require('lib/ajaxspinner_helper');

var SearchController = ModelController.extend({
    initialize: function (options) {
        this.logger = log4javascript.getLogger('controllers.SearchController');
    },

    batch: function (params) {
        this.model = this.getNewModel(params.id);
        this.model.set('copyToText', true);
        this.model.set('saveFile', false);
        this.model.set('nrOfTitleLines', '0');
        this.model.set('nrOfCols', '1');
        this.model.set('notFoundIds', 'onlyDisplay');
        this.view = new BatchSearchView({model: this.model});
        Chaplin.mediator.subscribe('batchSearchPublishPoint', this.batchSearchPublishPoint, this);
        Chaplin.mediator.subscribe('backToSearchHistory', this.backToSearchHistory, this);
        this.view.render();
    },
    quick: function (params) {
        this.model = this.getNewModel(params.id);
        this.view = new QuickSearchView({model: this.model});
        Chaplin.mediator.subscribe('quickSearchPublishPoint', this.quickSearchPublishPoint, this);
        Chaplin.mediator.subscribe('backToSearchHistory', this.backToSearchHistory, this);
        this.view.render();
    },
    advanced: function (params) {
        this.model = this.getNewModel(params.id);
        this.formDef = new FormDefinition({id: 'advancedsearch/edit'});
        this.view = new AdvancedSearchView({model: this.model, formDef: this.formDef});
        Chaplin.mediator.subscribe('advancedSearchPublishPoint', this.advancedSearchPublishPoint, this);
        Chaplin.mediator.subscribe('backToSearchHistory', this.backToSearchHistory, this);
        this.formDef.fetch();
    },
    boole: function (params) {
        this.model = this.getNewModel(params.id);
        this.view = new BooleSearchView({model: this.model});
        Chaplin.mediator.subscribe('booleSearchPublishPoint', this.booleSearchPublishPoint, this);
        Chaplin.mediator.subscribe('backToSearchHistory', this.backToSearchHistory, this);
        this.view.render();
    },
    searchhistory: function (params) {
        this.model = new SearchQuery({});
        this.formDef = new FormDefinition({id: 'searchhistory/list'});
        tmpFormDef = this.formDef;
        this.view = new SearchHistoryView({model: this.model, formDef: tmpFormDef});
        this.formDef.fetch();
        Chaplin.mediator.subscribe('quickSearchPublishPoint', this.quickSearchPublishPoint, this);
        Chaplin.mediator.subscribe('batchSearchPublishPoint', this.batchSearchPublishPoint, this);
        Chaplin.mediator.subscribe('advancedSearchPublishPoint', this.advancedSearchPublishPoint, this);
        Chaplin.mediator.subscribe('booleSearchPublishPoint', this.booleSearchPublishPoint, this);
        Chaplin.mediator.subscribe('editQuickSearch', this.editQuickSearch, this);
        Chaplin.mediator.subscribe('editBatchSearch', this.editBatchSearch, this);
        Chaplin.mediator.subscribe('editAdvancedSearch', this.editAdvancedSearch, this);
        Chaplin.mediator.subscribe('editBooleSearch', this.editBooleSearch, this);
    },
    executequery: function (params) {
        AjaxSpinner.show();
        this.model = this.getNewModel(params.id);
        jQuery.extend(true, params, this.model.attributes);
        Chaplin.mediator.subscribe('quickSearchPublishPoint', this.quickSearchPublishPoint, this);
        Chaplin.mediator.subscribe('batchSearchPublishPoint', this.batchSearchPublishPoint, this);
        Chaplin.mediator.subscribe('advancedSearchPublishPoint', this.advancedSearchPublishPoint, this);
        Chaplin.mediator.subscribe('booleSearchPublishPoint', this.booleSearchPublishPoint, this);
        Chaplin.mediator.subscribe('editQuickSearch', this.editQuickSearch, this);
        Chaplin.mediator.subscribe('editBatchSearch', this.editBatchSearch, this);
        Chaplin.mediator.subscribe('editAdvancedSearch', this.editAdvancedSearch, this);
        Chaplin.mediator.subscribe('editBooleSearch', this.editBooleSearch, this);
        this.view = new SearchHistoryView({model: this.model, formDef: this.formDef, modelKey: 'product'});
        var search = "";
        if (params.batchIds !== null && params.batchIds.length > 0) {
            for (var i = 0; i < params.batchIds.length; i++) {
                search += params.batchIds[i];
                if (i < params.batchIds.length - 1) {
                    search += ' ';
                }
            }
        } else {
            search = params.query;
        }
        this.view.executeSearchHistoryQuery(params.id, search, params.mode, params.action);
    },
    checkQueryForDatabases: function (query, mode) {
        if (MiscHelper.isEmpty(query)) {
            query = MiscHelper.getAllDbQueryString(mode);
        } else if (query.indexOf('db=') < 0) {
            if (query.indexOf('und') > -1 || query.indexOf('oder') > -1) {
                query = '(' + query + ')' + ' und db=vlb'; // + MiscHelper.getAllDbQueryString(mode);
            } else {
                query += ' und db=vlb'; // + MiscHelper.getAllDbQueryString(mode);
            }
        }

        return query;
    },
    getDatabases: function (query) {
        var equals = query.split("=");
        for (var i = 0; i < equals.length - 1; i++) {
            var words = equals[i].split(" ");
            var afterWords = equals[i + 1].split(" ");
            var cat = words[words.length - 1];
            if (cat == 'db') {
                var databases = {};
                var databasesArray = afterWords[0].split(',');
                for (db in databasesArray) {
                    databases[databasesArray[db]] = true;
                }
            }
        }
        return databases;
    },
    batchsearch: function (params) {
        globalCache.clear();
        this.model = this.getNewModel(params.id);
        this.model.set('mode', 'batch');
        this.model.set('sortCol', params.sortCol);
        this.model.set('contentType', params.contentType);
        if (params.search === undefined) {
            params.search = "";
        }
        var query = decodeURIComponent(params.search);
        var query = this.checkQueryForDatabases(query);
        params.search = query;
        // dbFilterArray is the query database filter.
        this.model.set('dbFilterArray', MiscHelper.getDatabases(query));
        // databases contains the value of the UI components.
        this.model.set('databases', MiscHelper.getDatabases(query));
        this.model.set('query', query);

        var fileFormatMapper = new Object({
            'onlyDisplay': '',
            'asTxtFile': 'txt',
            'asCsvFile': 'csv',
            'asXlsFile': 'xls',
            'asXlsxFile': 'xlsx'
        });
        this.model.set('fileFormat', fileFormatMapper[params.unknownIds]);
        this.model.set('fileName', MsgHelper.getMessage('placeholder.batch.search.notFoundIds.filename'));
        this.formDef = new FormDefinition({id: 'product/list'});
        this.view = new BatchSearchResultView({
            model: this.model,
            formDef: this.formDef,
            modelKey: 'product',
            searchType: "batch"
        });
        this.formDef.fetch();
    },
    quicksearch: function (params, options) {
        globalCache.clear();
        this.model = this.getNewModel(params.id);
        if (params.search === undefined) {
            params.search = "";
        }
        var query = decodeURIComponent(params.search);
        $('.quick-search #globalQuickSearch').val(query);
        var query = this.checkQueryForDatabases(query, 'quick');
        // dbFilterArray is the query database filter.
        this.model.set('dbFilterArray', MiscHelper.getDatabases(query));
        // databases contains the value of the UI components.
        this.model.set('databases', MiscHelper.getDatabases(query));
        this.model.set('query', query);
        this.model.set('mode', 'quick');
        this.model.set('sortCol', params.sortCol);
        this.model.set('cotentType', params.contentType);
        if(params.action === undefined) {
            /* if no action is assigned I have to assume one */
            this.model.set('action', 'startQuickSearch');
        } else {
            this.model.set('action', params.action);
        }
        this.formDef = new FormDefinition({id: 'product/list'});
        this.view = new ProductSearchResultView({
            model: this.model,
            formDef: this.formDef,
            modelKey: 'product',
            searchType: "quick"
        });
        this.formDef.fetch();
    },
    advancedsearch: function (params) {
        globalCache.clear();
        this.model = this.getNewModel(params.id);
        if (params.search === undefined) {
            params.search = "";
        }
        var query = decodeURIComponent(params.search);
        var query = this.checkQueryForDatabases(query);
        // dbFilterArray is the query database filter.
        this.model.set('dbFilterArray', MiscHelper.getDatabases(query));
        // databases contains the value of the UI components.
        this.model.set('databases', MiscHelper.getDatabases(query));
        this.model.set('query', query);
        this.model.set('mode', 'advanced');
        this.model.set('sortCol', params.sortCol);
        this.model.set('contentType', params.contentType);
        if(params.action === undefined) {
            /* if no action is assigned I have to assume one */
            this.model.set('action', 'startAdvancedSearch');
        } else {
            this.model.set('action', params.action);
        }
        this.formDef = new FormDefinition({id: 'product/list'});
        this.view = new ProductSearchResultView({
            model: this.model,
            formDef: this.formDef,
            modelKey: 'product',
            searchType: "advanced"
        });
        this.formDef.fetch();
    },
    boolesearch: function (params) {
        globalCache.clear();
        this.model = this.getNewModel(params.id);
        if (params.search === undefined) {
            params.search = "";
        }
        var query = decodeURIComponent(params.search);
        var query = this.checkQueryForDatabases(query);
        // dbFilterArray is the query database filter.
        this.model.set('dbFilterArray', MiscHelper.getDatabases(query));
        // databases contains the value of the UI components.
        this.model.set('databases', MiscHelper.getDatabases(query));
        this.model.set('query', query);
        this.model.set('mode', 'boole');
        this.model.set('sortCol', params.sortCol);
        this.model.set('contentType', params.contentType);
        this.formDef = new FormDefinition({id: 'product/list'});
        this.view = new ProductSearchResultView({
            model: this.model,
            formDef: this.formDef,
            modelKey: 'product',
            searchType: "boole"
        });
        this.formDef.fetch();
    },
    quickSearchPublishPoint: function (id, search, action) {
        this.redirectTo('search_quicksearch', {'id': id, 'search': LinkHelper.encodeURI(search), 'action': action});
    },
    batchSearchPublishPoint: function (id, search, unknownIds) {
        this.redirectTo('search_batchsearch', {'id': id, 'search': search, 'unknownIds': unknownIds});
    },
    advancedSearchPublishPoint: function (id, search, mode) {
        this.redirectTo('search_advancedsearch', {'id': id, 'search': LinkHelper.encodeURI(search), 'mode': mode});
    },
    booleSearchPublishPoint: function (id, search, mode) {
        this.redirectTo('search_boolesearch', {'id': id, 'search': LinkHelper.encodeURI(search), 'mode': 'boole'});
    },
    editQuickSearch: function (queryId) {
        this.redirectTo('search_quick', {'id': queryId});
    },
    editBatchSearch: function (queryId) {
        this.redirectTo('search_batch', {'id': queryId});
    },
    editAdvancedSearch: function (queryId) {
        this.redirectTo('search_advanced', {'id': queryId});
    },
    editBooleSearch: function (queryId) {
        this.redirectTo('search_boole', {'id': queryId});
    },
    backToSearchHistory: function () {
        this.redirectTo('searchhistory_list', {'id': user.userId});
    },
    getNewModel: function (id) {
        var newModel;
        if (!MiscHelper.isEmpty(id)) { //contentType
            newModel = new SearchQuery({id: id});
            newModel.fetch();
        } else {
            newModel = new SearchQuery({});
            newModel.set('userId', user.userId);
            newModel.set('favourite', 'false');
            newModel.set('deactivated', 'false');
            newModel.set('nrOfExecutions', 0);
        }
        return newModel;
    }
});

module.exports = SearchController;