var StringUtil = function() {
};

/**
 * Replace all corresponding placeholder in the given pattern parameter by the
 * assigned values from the arguments parameter.
 * 
 * @param pattern
 *            the pattern containing the place holder.
 * @param args
 *            the assigned values.
 */
/**
 * Example : pattern "hallo {0} {1}." | args :"[my,world]" | result hallo my
 * world.
 */
StringUtil.prototype.format = function(pattern, args) {
	return pattern.replace(/{(\d+)}/g, function(match, number) { 
	      return typeof args[number] != 'undefined'
	        ? args[number]
	        : match
	      ;
	    });
};

/**
 * extracts all placeholders encapsuled in a {} from a given string
 * 
 * @param str 
 * 			the string to get placeholders from
 */
StringUtil.prototype.getPlaceHolders = function(str) {
	var regex = /\{(\w.+)\}/g;
    var result = [];
    
    while (match = regex.exec(str))
    {
        result.push(match[1]);    
    }
    
    return result;
};

StringUtil.prototype.toStringArray1D = function(array){
	var blkstr = [];
	$.each(array, function(idx2,val2) {                    
	     var str = idx2 +":\'"+val2+"\'";
	     blkstr.push(str);
	});
	return "{"+ blkstr.join(", ")+"}";
};

module.exports = new StringUtil;