var View = require('./view')
var template = require('./templates/multipiece');
var bundleSwitch;

module.exports = bundleSwitch = View.extend({

    id: 'multipiece',
    autoRender: true,
    autoAttach: false,
    className: "lb-default modal-bundles",

    initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);

        // set default setting
        this.model = new Backbone.Model({
            taxRate: 'identic',
            kindOfProduct: 'two'
        });

        // used for binding when attached
        this.modelBinder = new Backbone.ModelBinder();
    },

    getTemplateFunction: function () {
        return template;
    },

    render: function () {
        View.prototype.render.call(this);
    },

    getTemplateData: function () {
        var data = View.prototype.getTemplateData.call(this);

        data.ident = 'multipiece';

        data.labelPrefix = 'form.confirmationMultipiece';

        // setting up input fields
        data.options = [
            {property:'taxRate', collection: ['identic', 'different'], hidden: false}, // is visible by default
            {property:'kindOfProduct', collection: ['two', 'multiple'], hidden: false}  // is hidden by default
        ];
        return data;
    },

    attach: function() {
        View.prototype.attach.call(this);

        //var bindings = {kindOfProduct: '[name=kindOfProduct]', taxRate: '[name=taxRate]', huhu: '[name=huhu]'};
        this.modelBinder.bind(this.model, this.el);
    }


});