var Model = require('./nested_model');

// Base class for models with oauth2 access_token send in fetch
module.exports = Model.extend({

	sync: function (method, model, options) {
    	var theOptions = options ? options : {};
        
    	var theUrl = this.urlRoot;
    	if(_.isFunction(theUrl)) {
    		theUrl = this.urlRoot();
    	}
    	
    	if(_.string.startsWith(theUrl, apiRoot)) {
        	if(!("headers" in theOptions)) {
        		theOptions['headers'] = {};
        	}
            theOptions['headers']['Authorization'] = "Bearer " + user.access_token;    		
    	}

    	var deferred = $.Deferred();
        var response = Chaplin.Model.prototype.sync.call(this, method, model, theOptions);
        
        response.done(deferred.resolve);
        response.fail(function() {
	        if (response.status == 200) {
	    		window.location.href = '/login?sessionExpired=true';
	    	}
        });

        return deferred.promise();
    },
    
	deepClone: function() {
		  return new this.constructor(this.toJSON());
	}
});
