var MiscHelper = require('lib/misc_helper');
var MsgHelper = require('lib/msg_helper');
var FormHelper = require('lib/form_helper');
var StringUtil = require('lib/string_util');

var Logger = log4javascript.getLogger('lib.LinkHelper');

var LinkHelper = function() {
};

LinkHelper.prototype.encodeURI = function(str) {
    var retVal = encodeURIComponent(str).replace(/\(/g, "%28").replace(/\)/g, "%29");
    return retVal;
}

LinkHelper.prototype.initLinks = function(linkWidgets, bindings, model) {
	for(var key in linkWidgets) {
        var linkWidget = linkWidgets[key];

        var name = linkWidget.property;            
        // if the link is contained in a list
        if (linkWidget.parentProperty) {
        	// as long as the list has elements
        	var index = 0;
        	if (bindings[linkWidget.parentProperty + "." + index + "." + name]) {
            	while (bindings[linkWidget.parentProperty + "." + index + "." + name]) {
            		var propName = linkWidget.parentProperty + "." + index + "." + name;
            		this.initLink(linkWidget, FormHelper.selector2Id(bindings[propName]), propName, model);
            		index++;
            	}
        	} else {
        		this.initLink(linkWidget, FormHelper.selector2Id(bindings[linkWidget.parentProperty + "." + name]), name, model);
        	}
        } else {
        	this.initLink(linkWidget, FormHelper.selector2Id(bindings[name]), name, model);
        }        
    }
};

LinkHelper.prototype.initLink = function(linkWidget, selector, propertyName, model, element) {
	var widgetUrl = undefined;
	
	var placeHolders = StringUtil.getPlaceHolders(linkWidget.url);
	
	if (linkWidget.url.indexOf('{property}', 0) > 0) {
		// if the property is not put to the last position replace it on its occurrence
		var replace = model.get(propertyName);
		if (replace === undefined && element !== undefined) {
			replace = element.innerHTML; 
		}
		widgetUrl = linkWidget.url.replace('{property}', replace);
	} else if (placeHolders !== undefined && placeHolders.length > 0) {
		widgetUrl = linkWidget.url;
	} else {
		widgetUrl = linkWidget.url + model.get(propertyName);
	}			
	
	placeHolders = StringUtil.getPlaceHolders(widgetUrl);
	// do some fancy replacement stuff if the widgetUrl contains properties 	
	if (placeHolders !== undefined && placeHolders.length > 0) {
		for (var index = 0; index < placeHolders.length; index++) {
			widgetUrl = widgetUrl.replace('{' + placeHolders[index] + '}', model.get(placeHolders[index]));
		}
	} 

    if (_.string.startsWith(widgetUrl, "$api/")) {
        widgetUrl = widgetUrl.replace("$api/", apiRoot);
    } else  if (_.string.startsWith(widgetUrl, "$applicationRoot")) {
    	widgetUrl = widgetUrl.replace("$applicationRoot", applicationRoot);
    }

    var accessTokenRequired = linkWidget.accessTokenRequired;

    var linkWidgetElement = document.getElementById(selector);
    
    if (!linkWidgetElement) {
    	linkWidgetElement = element;
    }    	
    
    if (linkWidgetElement) {
        var text = linkWidgetElement.innerHTML;
        
        // if instead of the actual value a custom value has to be displayed
        if (linkWidget.customValue) {
        	text = MsgHelper.getMessage(linkWidget.customValue);
        }

        Logger.debug("LinkWidget in init: ", linkWidgetElement);

        if (accessTokenRequired) {
            widgetUrl = widgetUrl + "?access_token=" + user.access_token;
        }                              
       
        linkWidgetElement.innerHTML = this.getHtmlLink(model.get(propertyName), linkWidget, text, widgetUrl);
    }
};

LinkHelper.prototype.getHtmlLink = function(propertyName, linkWidget, text, aWidgetUrl) {
    var widgetUrl = aWidgetUrl;
    if (_.string.startsWith(widgetUrl, "$api/")) {
        widgetUrl = widgetUrl.replace("$api/", apiRoot);
    } else  if (_.string.startsWith(widgetUrl, "$applicationRoot")) {
        widgetUrl = widgetUrl.replace("$applicationRoot", applicationRoot);
    }
	var link = "<a href='";
    if (linkWidget.jsEventAction) {
    	link = link + "javascript:;' onclick='return false;' id='" + propertyName + "'";
    } else {
    	link = link + widgetUrl + "'";
    }
    if (!MiscHelper.isEmpty(linkWidget.styleClass)) {
        link = link + " class='" + linkWidget.styleClass + "'";
    }
    if (!MiscHelper.isEmpty(linkWidget.linkTarget)) {
        link = link + " target='" + linkWidget.linkTarget + "'";
    }
    link = link + "/>" + text + "</a>";
    
    return link;
};

module.exports = new LinkHelper;