var MiscHelper = require('lib/misc_helper');
var DateHelper = require('lib/date_helper');

var PriceHelper = function () {
};

PriceHelper.prototype.findPrice = function (prices, currencyCountry) {
    for (var i = prices.length - 1; i >= 0; i--) {
        var p = prices[i];
        if (p !== null && p.currencyCountry === currencyCountry) {
            return {price: p, index: i};
        }
    }
    return null;
};

PriceHelper.prototype.findPriceByCountryAndValidFrom = function (prices, currencyCountry, validFrom) {
    for (var i = 0; i < prices.length; i++) {
        var p = prices[i];
        if (p !== null && p.currencyCountry === currencyCountry && p.validFrom === validFrom) {
            return {price: p, index: i};
        }
    }
    return null;
};

PriceHelper.prototype.filterPricesByCurrencyCountry = function (prices, aPrice, aCurrencyCountry) {
    var r = [];
    var currencyCountry;
    if (aCurrencyCountry === undefined) {
        currencyCountry = aPrice.currencyCountry;
    } else {
        currencyCountry = aCurrencyCountry;
    }
    for (var i = 0; i < prices.length; i++) {
        var p = prices[i];
        if (p !== null && p !== undefined && aPrice !== undefined && p.currencyCountry === currencyCountry && p.priceTypeGroup === aPrice.priceTypeGroup) {
            r.push({price: p, index: i});
        }
    }
    return r;
};


PriceHelper.prototype.countCurrentforeignPrices = function (model) {
    var fp = model.get('foreignPrices');
    var count = 0;
    if (fp !== undefined && fp !== null) {
        for (var i = 0; i < fp.length; i++) {
            var p = fp[i];
            if (p !== null && MiscHelper.isEmpty(p.validFrom)) {
                count += 1;
            }
        }
    }
    return count;
};

PriceHelper.prototype.prepareCurrencyCountry = function (obj) {
    if (!MiscHelper.isEmpty(obj.currency) && !MiscHelper.isEmpty(obj.country)) {
        obj.currencyCountry = obj.currency + "_" + obj.country;
    }
};

PriceHelper.prototype.preparePriceObject = function (obj) {

    this.prepareCurrencyCountry(obj);

    obj.typeTypeQualifier = obj.type;
    if (!MiscHelper.isEmpty(obj.typeQualifier)) {
        obj.typeTypeQualifier += "_" + obj.typeQualifier;
    }
    if (obj.type === '02' || obj.type === '04') {
        obj.minimumQuantity = null;
    } else if (!MiscHelper.isEmpty(obj.minimumQuantity)) {
        obj.typeTypeQualifier += "_M";
    }

};

PriceHelper.prototype.sortRetailsPrices = function (prices) {
    prices.sort(function (a, b) {
        if (a.text && b.text) {
            if (a.currencyCountry === b.currencyCountry) {
                if (a.validFrom === null || a.validFrom === undefined) {
                    return -1;
                }
                if (b.validFrom === null || b.validFrom === undefined) {
                    return 1;
                }

                if (a.validUntil === null || a.validUntil === undefined) {
                    return 1;
                }
                if (b.validUntil === null || b.validUntil === undefined) {
                    return -1;
                }

                return -1;
            }
            if (a.currencyCountry === 'EUR_DE') {
                return -1;
            }
            if (b.currencyCountry === 'EUR_DE') {
                return 1;
            }
            if (a.currencyCountry === 'EUR_AT') {
                return -1;
            }
            if (b.currencyCountry === 'EUR_AT') {
                return 1;
            }
            return a.text.toLowerCase().localeCompare(b.text.toLowerCase());
        }
    });
};

PriceHelper.prototype.copyAutocorrections = function (pricesSrc, model, attrName) {
    var pricesDst = model.get(attrName);

    for (var j = pricesDst.length - 1; j >= 0; j--) {
        if (pricesDst[j] === null || pricesDst[j].calculated) {
            pricesDst.splice(j, 1);
        }
    }
    var priceCount = pricesSrc != undefined ? pricesSrc.length : 0;
    for (var i = 0; i < priceCount; i++) {
        var pS = pricesSrc[i];
        if (pS !== null) {
            PriceHelper.prototype.preparePriceObject(pS);
            var found = false;
            for (var j = 0; j < pricesDst.length; j++) {
                var pD = pricesDst[j];
                if (pD.id === pS.id || (pD.country === pS.country && pD.currency === pS.currency && pD.validFrom === pS.validFrom)) {
                    for (var k in pS) {
                        if (model.get('type') === 'duoBundle' && k === 'taxRate' && pS[k] === null) {

                        } else {
                            pD[k] = pS[k];
                        }
                    }
                    found = true;
                    break;
                }
            }
            if (!found) {
                pricesDst.push(pS);
            }
        }
    }
    PriceHelper.prototype.sortRetailsPrices(pricesDst);
};
module.exports = new PriceHelper;