var GlobalErrorView = require('views/globalerror_view');
var GlobalErrorModel = require('models/globalError');
var MsgHelper = require('./msg_helper');
var MiscHelper = require('lib/misc_helper');

var ErrorHelper = function () {
};

ErrorHelper.prototype.showGlobalError = function (errorUrl, errorCode, responseJSON, errorMsg) {

		var errorMessage = "";
		switch (errorCode) {
			case 401:
				errorMessage = MsgHelper.getMessage('errorCode.401');
				break;
			case 403:
				errorMessage = MsgHelper.getMessage('errorCode.403');
				break;
			case 404:
				if(!MiscHelper.isEmpty( errorUrl) && errorUrl.indexOf("transformation") > -1) {
                    errorMsg = "abort";
				} else {
                    errorMessage = MsgHelper.getMessage('errorCode.404');
				}
				break;
            case 422:
                if(!MiscHelper.isEmpty( errorUrl) && errorUrl.indexOf("transformation") > -1) {
                    errorMsg = "abort";
                } else {
                    errorMessage = MsgHelper.getMessage('errorCode.422');
                }
                break;
			case 500:
				if (!responseJSON) {
					errorMessage = MsgHelper.getMessage('errorCode.500');
				} else {
					errorMessage = MsgHelper.formatMessage('errorCode.500.JSON', [responseJSON.error_description]);
				}
				break;			
			default:
				errorMessage = errorMsg;
		}
//		var errorModel = new GlobalErrorModel({statusCode: errorCode, errorMessage: errorMessage});
//		var errorView = new GlobalErrorView({autoAttach: true, model: errorModel});
		// prevent double popup-bug
//		if (this.notPoppedUpAlready()) {
//			alertify.alert('');
//		}
//		errorView.render();
		if (errorCode !== null && errorMsg !== "abort") {
	        alertify.set({ delay: 10000 });
	        alertify.error(errorMessage);
		}
};

ErrorHelper.prototype.showCustomError = function (errorMsg) {
	var errorModel = new GlobalErrorModel({errorMessage: errorMsg});
	var errorView = new GlobalErrorView({autoAttach: true, model: errorModel});
//	if (this.notPoppedUpAlready()) {
//		alertify.alert('');
//	}
//    errorView.render();
    alertify.set({ delay: 10000 });
    alertify.error(errorMsg);
};

ErrorHelper.prototype.notPoppedUpAlready = function () {
	return ($('#alertify').size() == 0) || 
			(($('#alertify').size() == 1) &&
					($('#alertify').hasClass('alertify-hide') ||
					$('#alertify').hasClass('alertify-hidden') ||
					$('#alertify').hasClass('alertify-isHidden')));
};

module.exports = new ErrorHelper;