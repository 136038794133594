var View = require('./view');
// Templates
var ExportTemplate = require('./templates/export');
// Models
var ExportModel = require('models/export');
// Helpers
var XMLHelper = require('lib/xml_helper');

var logger = log4javascript.getLogger('views.ExportView');

var constants = 
{
    urlRoot : apiRoot + 'export/',
    httpType : 'GET'
};

var ExportView = View.extend({

    id: 'export-view',
    modalSelector : '#exportModal',
    containerMethod: 'prepend',
    container : '#body',
    autoRender : false,
    autoAttach : false,    

    initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);
    },

    getTemplateFunction: function () {
        return ExportTemplate;
    },

    show : function()
    {   
        /// If the export view container already exists, remove it
        if ($(this.container).data('exportView.initialized') === true)
        {
            $(this.modalSelector).remove();            
        }

        this.init();

        // Set the content of the ExportView
        $('.modal-body .content', this.container).html(this.model.get('exportContent')).css('overflow', 'auto');

        $(this.modalSelector).modal('show');

        return this;
    },

    init : function()
    {
        this.render();
        this.attach();

        $(this.container).data('exportView.initialized', true);
    }

});

var fetchData = function(id, type, context, callback)
{
    var resultModel = new ExportModel(
    {
    });

    switch(type)
    {
        case 'onix21': { resultModel.set('exportType', 'Onix21'); } break;
        case 'ascii': { resultModel.set('exportType', 'ASCII'); } break;
        case 'csv': { resultModel.set('exportType', 'CSV'); } break;
    }
    var fields = [];

    // Populate fields TEMP
    $.each(context.options.table.fnSettings().aoColumns,
        function()
        {
            fields.push(
            {
                display : this.sTitle,
                id : this.mData
            });
        }
    );
    resultModel.set('fields', fields);
        

    var url = constants.urlRoot + '/' + type + '/' + id;
    jQuery.ajax(
    {
        dataType : 'text',
        url : url,
        type : constants.httpType,
        success : function(data) 
        {
            resultModel.set('exportContent', data);
            callback(resultModel);
        },
        error : function(data) 
        {
            logger.error("Error: ", data);
        },
        beforeSend : function() {},
        complete : function() {},        
        headers : 
        {
            'Authorization' : 'Bearer ' + user.access_token
        }
    });
};

ExportView.showOnix = function(id, context)
{
    fetchData(id, 'onix21', context,
        function(model)
        {
            console.log('Model: ', model.get('exportType'), model);
            /// Escape the XML
            var escapedXml = XMLHelper.escapeXML(model.get('exportContent'));
            escapedXml = $('<div>').append($('<code>').html(escapedXml)).html();
            model.set('exportContent', escapedXml);

            var view = new ExportView({ model : model});
            view.show();
        }
    );
};

ExportView.showAscii = function(id, context)
{
    fetchData(id, 'ascii', context,
        function(model)
        {
            // Wrap it in <code>
            var code = $('<div>').append($('<code />').text(model.get('exportContent')));
            model.set('exportContent', code.html().replace(/(?:\r\n|\r|\n|\\n)/g,'<br/>'));
            console.log('ASCIII ', model.get('exportContent'));

            var view = new ExportView({ model : model});
            view.show();
        }
    );
};

ExportView.showCSV = function(id, context)
{
    fetchData(id, 'csv', context,
        function(model)
        {            
            var view = new ExportView({ model : model});
            view.show();
        }
    );
};

ExportView.showProductHistory = function(id) 
{
    logger.debug("called action showProductHistory for id ", id);
    window.open(Chaplin.utils.reverse("productHistory_show", {'id': id}), '_self');
};

// Store the delegate references, so we can unbind them later
var actionDelegates =
{
    showOnixDelegate : null,
    showAsciiDelegate : null,
    showCSVDelegate : null,
    showProductHistoryDelegate : null
};

ExportView.BindActions = function(context)
{
    // Bind click event handles in the table rows
    var self = this;

    actionDelegates.showOnixDelegate = context.delegate('click', 'td.showOnixColumn i', 
        function(event) 
        {
            var id = this.eventId(event);
            self.showOnix(id, context);
        }
    );
    actionDelegates.showAsciiDelegate = context.delegate('click', 'td.showAsciiColumn i', 
        function(event)
        {
            var id = this.eventId(event);
            self.showAscii(id, context);            
        }
    );
    actionDelegates.showCSVDelegate = context.delegate('click', 'td.showCSVColumn i',
        function(event)
        {
            var id = this.eventId(event);
            self.showCSV(id, context);            
        }
    );
    actionDelegates.showProductHistoryDelegate = context.delegate('click', 'td.showProductHistoryColumn i',
        function(event)
        {
            var id = this.eventId(event);
            self.showProductHistory(id);
        }
    );
};

ExportView.UnbindActions = function(context)
{
    // Unbind event handlers
    context.undelegate('click', 'td.showOnixColumn i', actionDelegates.showOnixDelegate);
    context.undelegate('click', 'td.showAsciiColumn i', actionDelegates.showAsciiDelegate);
    context.undelegate('click', 'td.showCSVColumn i', actionDelegates.showCSVDelegate);
    context.undelegate('click', 'td.showProductHistoryColumn i', actionDelegates.showProductHistoryDelegate);
};


module.exports = ExportView;