var MsgHelper = require('lib/msg_helper');
var View = require('./view');
var SummaryTemplate = require('./templates/forms/product_data2');
var DateHelper = require('lib/date_helper');
var PageContentModel = require('models/pagecontent');
var ListView = require('./list_view');
var FormDefinition = require('models/formDefinition');
var NumberWidget = require('./forms/widgets/number');
var MiscHelper = require('lib/misc_helper');

var FormHelper = require('lib/form_helper');

var CoverView;

module.exports = CoverView = View.extend({

    LOGGER: log4javascript.getLogger('views.ProductDataView'),

    container: '#product_data2',
    autoRender: true,
    autoAttach: false,
    className: 'productData2',
    regions: {
        'asset-list-view': '#asset-list-view'
    },
    codelists: {},
    assetListModel: null,

    initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);

        this.codelists["languageCodes"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '74');
        this.codelists["countryCodes"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '91');
        this.codelists["editionTypes"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '21');
        this.codelists["availabilities"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '65');
        this.codelists["publishingStatus"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '64');
        this.codelists["illustrationTypes"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '25');
        this.codelists["productFormTypes"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '7');
        this.codelists["coggroup"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, 'coggroup');
        this.codelists["cogindex"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, 'cogindex');
        this.codelists["customerGroups"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '28');
        this.codelists["educationalLevel"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '238');
        this.codelists["textTypes"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '33');
        this.codelists["textFormatTypes"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '34');
        this.codelists["formFeatuireTypes"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '184');
        this.codelists["relationTypes"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '51');
        this.codelists["ddcIdentifiers"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '309');

        this.codelists["frequency"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '400');
        this.codelists["zisSubjectGroups"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '405');
        if (user.authorities.indexOf('MULTIPLE_BISAC_CODES') >= 0) {
            this.codelists["bisacMainSubjects"] =
                FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '407');
        }
        this.codelists["archivedReason"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, 'archivedReason');
        this.codelists["gameClassification"]=
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections,'531');

        this.modelKey = 'assets';

        // loading assets from assetStore
        this.assetListModel = new PageContentModel({
            urlRoot: "$api/asset/" + options.model.get("id") + "/assetFile",
            modelKey: 'assets'
        });
        if (this.assetListModel) {
            // loading listView with form definition (auto-attached)
            var assetListFormDef = new FormDefinition({id: 'assetFile/list', wid: "assets-table"});
            new ListView({
                model: this.assetListModel,
                formDef: assetListFormDef,
                region: "asset-list-view",
                id: "assets-table",
                showHeader: false,
                productId: options.model.get('id')
            });
            assetListFormDef.fetch();
        }
    },

    getTemplateData: function () {
        var data = View.prototype.getTemplateData.call(this);

        /*
         * routines for mb basic
         */
        data.showProductBasic = user.authorities.indexOf('PUBLISHER_BASIC') >= 0
            || user.authorities.indexOf('BOOKSTORE_BASIC') >= 0
            || data.model.basic;

        // returnInfo
        if (data.model.type === 'journal' || user.authorities.indexOf('HAS_RETURN_INFO') < 0) {
            data.model.showReturnInfo = false;
        } else {
            data.model.showReturnInfo = true;
            if (data.model.returnInfo) {
                data.returnInfoRemarks = '';
                if (data.model.returnInfo.invoiceDateNeeded) {
                    data.returnInfoRemarks += MsgHelper.getMessage('label.form.product.field.invoiceDateNeeded') + '<br/>';
                }
                if (data.model.returnInfo.invoiceNumberNeeded) {
                    data.returnInfoRemarks += MsgHelper.getMessage('label.form.product.field.invoiceNumberNeeded') + '<br/>';
                }
                if (data.model.returnInfo.returnPermissionNeeded) {
                    data.returnInfoRemarks += MsgHelper.getMessage('label.form.product.field.returnPermissionNeeded') + '<br/>';
                }
                if (data.model.returnInfo.simplifiedRemissionPossible) {
                    data.returnInfoRemarks += MsgHelper.getMessage('label.form.product.field.simplifiedRemissionPossible') + '<br/>';
                }
                if (data.model.returnInfo.returnComment) {
                    if (data.returnInfoRemarks) {
                        data.returnInfoRemarks += '<br/>';
                    }
                    data.returnInfoRemarks += data.model.returnInfo.returnComment;
                }
            }
        }

        // edition
        if (data.model.edition) {
            data.editionText = '';
            if (data.model.edition.number) {
                data.editionText = data.model.edition.number + '. ' + MsgHelper.getMessage('label.form.product.fieldset.edition');
            }
            if (data.model.edition.text) {
                if (data.editionText) {
                    data.editionText += ', ' + data.model.edition.text;
                } else {
                    data.editionText = data.model.edition.text;
                }
            }

            data.editionTypes = [];
            var editionTypesArr = data.model.edition.type ? data.model.edition.type.split(' ') : [];
            for (var i = 0; i < editionTypesArr.length; i++) {
                data.editionTypes.push(FormHelper.getCodelistLabel(this.codelists['editionTypes'], editionTypesArr[i]));
            }
            data.editionTypes = data.editionTypes.join(', ');

            data.publicationCity = data.model.publicationCity ? data.model.publicationCity.replace(/,/g, ", ") : '';
            data.publicationCountry = FormHelper.getCodelistLabel(this.codelists['countryCodes'], data.model.publicationCountry);
        }

        // availability
        data.publicationDate = DateHelper.convert(data.model.publicationDate);
        data.availability = FormHelper.getCodelistLabel(this.codelists['availabilities'], data.model.availability);
        data.publishingStatus = FormHelper.getCodelistLabel(this.codelists['publishingStatus'], data.model.publishingStatus);
        data.additionAvailabilities = data.model.availabilityDate || data.model.onSaleDate || data.model.announcementDate;
        data.onSaleDate = DateHelper.convert(data.model.onSaleDate);
        data.announcementDate = DateHelper.convert(data.model.announcementDate);
        data.availabilityDate = DateHelper.convert(data.model.availabilityDate);
        data.reprintDate = DateHelper.convert(data.model.reprintDate);

        // set the availability label part (we need it for zis products)
        data.availabilityLabel = 'availability';

        // extent
        data.pages = '';
        if (data.model.extent) {
            if (data.model.extent.pages && !data.model.extent.pagesArabic) {
                data.pages = 'ca. ' + data.model.extent.pages;
            } else {
                if (data.model.extent.pagesRoman) {
                    data.pages = data.model.extent.pagesRoman
                }
                if (data.model.extent.pagesArabic) {
                    if (data.pages != '') {
                        data.pages += ' / ';
                    }
                    data.pages += data.model.extent.pagesArabic
                }
            }
            data.fileSize = data.model.extent.fileSize ? NumberWidget.prototype.format(data.model.extent.fileSize / 1024).replace(/\./, ",") + ' kByte' : '';
            data.duration = data.model.extent.duration ? data.model.extent.duration + ' min' : '';
            data.mapScale = data.model.extent.mapScale ? '1:' + data.model.extent.mapScale : '';

            if (data.model.extent.numIllustrations || data.model.extent.illustrationText) {
                data.illustrationsTotal = '<span class="number">' +
                    (data.model.extent.numIllustrations ? data.model.extent.numIllustrations : '') + '</span>'
                    + '<span class="description">' + (data.model.extent.illustrationText ? data.model.extent.illustrationText : '') + '</span>';
            }
        }

        // form
        if (data.model.form) {
            if (data.model.form.width || data.model.form.height || data.model.form.thickness || data.model.form.weight) {
                var sizeWeight = [];
                var height;
                var width;
                if(data.model.form.height) {
                    height = NumberWidget.prototype.format(data.model.form.height / 10);
                    if(decimalSeparator != '.') {
                        height = height.replace(/\./, decimalSeparator);
                    }
                } else {
                    height = '-';
                }
                if(data.model.form.width) {
                    width = NumberWidget.prototype.format(data.model.form.width / 10);
                    if(decimalSeparator != '.') {
                        width = width.replace(/\./, decimalSeparator);
                    }
                } else {
                    width = '-';
                }
                sizeWeight.push(height);
                sizeWeight.push(width);
                if (user.authorities.indexOf('SHOW_FORM_THICKNESS') > -1) {
                    var thickness;
                    if(data.model.form.width) {
                        thickness = NumberWidget.prototype.format(data.model.form.thickness / 10);
                        if(decimalSeparator != '.') {
                            thickness = thickness.replace(/\./, decimalSeparator);
                        }
                    } else {
                        thickness = '-';
                    }
                    sizeWeight.push(thickness);
                }
                sizeWeight.push(data.model.form.weight ? data.model.form.weight : '-');
                data.sizeweight = MsgHelper.formatMessage('label.form.product.fieldset.form.sizeWeightAbbr', sizeWeight);
            }
            if (data.model.form.numberOfPieces) {
                data.numberOfPieces = data.model.form.numberOfPieces + ' ' +
                    MsgHelper.getMessage('label.form.product.fieldset.form.numberOfPieces.show.text');
            }
            if (data.model.aggregatorId == '5109037') { // umbreit only: pcf field 1220
                data.formatPages = data.model.form.description;
            }
        }

        // illustrations
        data.illustrations = [];
        if (data.model.illustrations) {
            for (var i = 0; i < data.model.illustrations.length; i++) {
                data.illustrations.push({
                    type: FormHelper.getCodelistLabel(this.codelists["illustrationTypes"], data.model.illustrations[i].type),
                    text: '<span class="number">' + (data.model.illustrations[i].number ? data.model.illustrations[i].number : '') + '</span>' +
                    '<span class="description">' + ( data.model.illustrations[i].description ? data.model.illustrations[i].description : '' ) + '</span>'
                });
            }
        }

        // volumes
        data.showVolumes = data.model.type !== 'duoBundle' && data.model.type !== 'multiBundle';
        data.volumes = [];
        if (data.model.containedItems) {
            for (var i = 0; i < data.model.containedItems.length; i++) {
                data.volumes.push(data.model.containedItems[i].numberOfPieces + ' x ' +
                    FormHelper.getCodelistLabel(this.codelists["productFormTypes"], data.model.containedItems[i].type));
            }
        }

        // classifications
        // take care that codelist is realy available.
        if (data.model.bisacMainSubject && user.authorities.indexOf('MULTIPLE_BISAC_CODES') >= 0) {
            data.bisacMainSubjectValue = data.model.bisacMainSubject + ' - '
                + FormHelper.getCodelistLabel(this.codelists['bisacMainSubjects'], data.model.bisacMainSubject);
        }

        data.keywords = [];
        data.keywordsDNB = [];
        data.groupsDDC = [];
        data.bisacCodes = [];
        data.themaCategories = [];
        data.themaQualifier = [];
        data.zisKeywords = [];
        if (user.authorities.indexOf('HAS_PRODUCT_GROUP') > -1) {
            data.showProductGroup = true;
        } else {
            data.showProductGroup = false;
        }

        if (data.model.classifications) {
            for (var i = 0; i < data.model.classifications.length; i++) {
                if (data.model.classifications[i].type == '26') {
                    if (data.model.classifications[i].code) {
                        var index = data.model.classifications[i].code[0];
                        var group = data.model.classifications[i].code.substring(1);
                        data.categoryOfGoods = index + ' ';
                        data.categoryOfGoods += group + ' ';
                        data.categoryOfGoods += FormHelper.getCollectionLabel(this.codelists["coggroup"], group) + '<br/>';
                        data.categoryOfGoods += FormHelper.getCollectionLabel(this.codelists["cogindex"], index);
                    }
                } else if (data.model.classifications[i].type == '20') {
                    data.keywords.push(data.model.classifications[i].value);
                } else if (data.model.classifications[i].type == '27' || data.model.classifications[i].type == '91') {
                    data.keywordsDNB.push(data.model.classifications[i].value);
                } else if (data.model.classifications[i].type == '18' || data.model.classifications[i].type == '30') {
                    data.groupsDDC.push(FormHelper.getCodelistLabel(this.codelists['ddcIdentifiers'], data.model.classifications[i].code));
                } else if (data.model.classifications[i].type == '93') {
                    data.themaCategories.push(data.model.classifications[i]);
                } else if (['94', '95', '96', '97', '98', '99'].indexOf(data.model.classifications[i].type) > -1) {
                    data.themaQualifier.push(
                        data.model.classifications[i].code + ' - ' + data.model.classifications[i].value +
                        (['WGSneuMapping', 'WGS1Mapping'].indexOf(data.model.classifications[i].sourceName) > -1 ? ' *' : '')
                    );
                } else if (data.model.classifications[i].type == '10') {
                    data.bisacCodes.push(data.model.classifications[i].code + ' - ' +FormHelper.getCodelistLabel(this.codelists['bisacMainSubjects'], data.model.classifications[i].code));
                }
                // ZiS
                if (data.model.classifications[i].type == 'ZS') {
                    var code = data.model.classifications[i].code,
                        index = "",
                        category = "",
                        mainCategory = "",
                        result = "";

                    if (!MiscHelper.isEmpty(code)) {
                        index = code.substring(0, code.length - 2) + '00';

                        // remove leading 0 in string - just in case
                        index = index.replace(/^(0+)/g, '');
                        code = code.replace(/^(0+)/g, '');

                        // check if we have a main category here, otherwise set the main category
                        if (code !== index) {
                            mainCategory = FormHelper.getCollectionLabel(this.codelists["zisSubjectGroups"], index);
                        }

                        category = FormHelper.getCollectionLabel(this.codelists["zisSubjectGroups"], code);
                        result = mainCategory !== "" ? mainCategory + ' - ' + category : category;

                        data.zisKeywords.push(result);
                    }
                }
            }
            if (data.themaCategories.length > 0 || data.themaQualifier.length > 0) {
                data.themaCategories.sort(function (a, b) {
                    if (a.main === b.main) {
                        if (a.code < b.code) {
                            return -1;
                        } else {
                            return 1;
                        }
                        return 0;
                    } else if (a.main === true) {
                        return -1;
                    } else {
                        return 1;
                    }
                });
                var tmpCategorie = data.themaCategories.slice();
                data.themaCategories = [];
                data.themaInfo = false;
                data.themInfoBisac = false
                for (var i = 0; i < tmpCategorie.length; i++) {
                    var themaInfo = (['WGSneuMapping', 'WGS1Mapping'].indexOf(tmpCategorie[i].sourceName)) > -1;
                    var themaInfoBisac = (['BISACMapping'].indexOf(tmpCategorie[i].sourceName)) > -1;
                    if (themaInfo === true) {
                        data.themaInfo = true;
                    }
                    if( themaInfoBisac === true) {
                        data.themaInfoBisac = true;
                    }
                    data.themaCategories.push(
                        tmpCategorie[i].code + ' - ' + tmpCategorie[i].value + ((themaInfo || themaInfoBisac) ? ' *' : ''));
                }
            }
            data.keywords = data.keywords.join("; ");
            data.keywordsDNB = data.keywordsDNB.join("; ");
            data.groupsDDC = data.groupsDDC.join("; ");
            data.zisKeywords = data.zisKeywords.join("<br/> ");
        }

        // audiences
        data.customerGroups = [];
        data.educationalLevel = [];
        if (data.model.audiences) {
            // set the label for audiences part (we need it for zis)
            data.audiencesLabel = 'audiences';
            for (var i = 0; i < data.model.audiences.length; i++) {
            	var classification = data.model.audiences[i].audienceClassification;
                if (classification == 'main_description') {
                    data.mainAudienceClassification = data.model.audiences[i].description;
                }
                else if (classification == 'customer_group') {
                    data.customerGroups.push(
                        FormHelper.getCodelistLabel(this.codelists["customerGroups"], data.model.audiences[i].value));
                }
                else if (classification == 'educational_level') {
                    data.educationalLevel.push(
                        FormHelper.getCodelistLabel(this.codelists["educationalLevel"], data.model.audiences[i].ageFrom));
                }
                else if (classification == 'reading_age') {
                    if (data.model.audiences[i].rangeCode == '18') {
                        data.readAge = '';
                        if (data.model.audiences[i].ageFrom) {
                            data.readAge += MsgHelper.getMessage('label.form.product.fieldset.audience.ageFrom') + ' ';
                            data.readAge += data.model.audiences[i].ageFrom + ' ';
                        }
                        if (data.model.audiences[i].ageTo) {
                            data.readAge += MsgHelper.getMessage('label.form.product.fieldset.audience.ageTo') + ' ';
                            data.readAge += data.model.audiences[i].ageTo;
                        }
                    }
                    if (data.model.audiences[i].rangeCode == '17') {
                        data.interestAge = '';
                        if (data.model.audiences[i].ageFrom) {
                            data.interestAge += MsgHelper.getMessage('label.form.product.fieldset.audience.ageFrom') + ' ';
                            data.interestAge += data.model.audiences[i].ageFrom + ' ';
                        }
                        if (data.model.audiences[i].ageTo) {
                            data.interestAge += MsgHelper.getMessage('label.form.product.fieldset.audience.ageTo') + ' ';
                            data.interestAge += data.model.audiences[i].ageTo;
                        }
                    }
                }
                else if (classification == 'fsk' && data.model.audiences[i].type == '05') {
                    data.fskAge = data.model.audiences[i].value;
                }

            }
            data.customerGroups = data.customerGroups.join("; ");
            data.educationalLevel = data.educationalLevel.join("; ");
            if (user.authorities.indexOf('HAS_FSK') > -1 && data.fskAge) {
                data.agesBlock = data.fskAge;
            } else {
            	data.agesBlock = data.interestAge || data.readAge;
            }
        }
        // additionInformation
        data.additionalInformation = [];
        data.mainDescriptions = [];
        data.shortDescription = [];

        if (data.model.texts) {
            for (var i = 0; i < data.model.texts.length; i++) {

                if (data.model.texts[i].link == null &&
                	data.model.texts[i].assetFileId == null &&
                    data.model.texts[i].internalUrl == null &&
                    ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17',
                        '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '30', '31', '32', '33', '34',
                        '35', '40', '41', '42'].indexOf(data.model.texts[i].type) > -1) {
                    // #15498 & #14959 - Bibi and Burkhard missed type 22 / Register
                    var label = FormHelper.getCodelistLabel(this.codelists['textTypes'], data.model.texts[i].type);
                    if (data.model.texts[i].language) {
                        label += '<br/>(' + FormHelper.getCodelistLabel(this.codelists['languageCodes'], data.model.texts[i].language) + ')';
                    }

                    var text = '';
                    var isHtml = false;
                    if (data.model.texts[i].value) {
                        text += data.model.texts[i].value;
                    }
                    if (data.model.texts[i].link) {
                        text += data.model.texts[i].link;
                    }
                    text = '<div class="collapser">' + text + '</div>';
                    if (data.model.texts[i].formatCode &&
                        data.model.texts[i].formatCode != '06' &&
                        data.model.texts[i].formatCode != '02' &&
                        data.model.texts[i].formatCode != '05') {
                        text += '(' + FormHelper.getCodelistLabel(this.codelists['textFormatTypes'], data.model.texts[i].formatCode) + ')';
                    }

                    if (data.model.texts[i].formatCode && (data.model.texts[i].formatCode == '02' || data.model.texts[i].formatCode == '05')) {
                        isHtml = true;
                    }

                    if (data.model.texts[i].type == '01') {
                        data.mainDescriptions.push({label: label, text: text, isHtml: isHtml});
                    } else {
                        data.additionalInformation.push({label: label, text: text, isHtml: isHtml});
                    }
                } else if (data.model.texts[i].type == "") {
                    // we assume that ZIS has no type, otherwise please add it to the array above
                    var label = MsgHelper.getMessage('label.form.product.block.shortDescription');
                    var text = '';
                    if (data.model.texts[i].value) {
                        text += data.model.texts[i].value;
                    }
                    if (data.model.texts[i].link) {
                        text += data.model.texts[i].link;
                    }
                    text = '<div class="collapser">' + text + '</div>';
                    if (data.model.texts[i].formatCode && data.model.texts[i].formatCode != '06') {
                        text += ' - ' + FormHelper.getCodelistLabel(this.codelists['textFormatTypes'], data.model.texts[i].formatCode);
                    }

                    data.shortDescription.push({label: label, text: text});
                }
            }
        }
        data.assetListModel = this.assetListModel;
        // Zolltarifnummer
        if (user.authorities.indexOf('SHOW_COMMERCIAL_CLASSIFICATION_VLB') > -1 && user.authorities.indexOf('SHOW_COMMERCIAL_CLASSIFICATION_BIPB') < 0) {
            data.commercialClassifications = [];
            if (data.model.commercialClassifications) {
                for (var i = 0; i < data.model.commercialClassifications.length; i++) {
                    if (data.model.commercialClassifications[i].type == '04') {
                        data.commercialClassifications.push(data.model.commercialClassifications[i].value);
                    }
                }
                data.commercialClassifications = data.commercialClassifications.join("; ");
            }
        }
        // countryOfManufacture
        data.countryOfManufacture = FormHelper.getCodelistLabel(this.codelists['countryCodes'], data.model.countryOfManufacture);

        // formFeatures
        if (user.authorities.indexOf('SHOW_BLOCK_SAFTY_WARNINGS') > -1) {
            data.saftyWarnings = [];
            if (data.model.formFeatures) {
                for (var i = 0; i < data.model.formFeatures.length; i++) {
                    if (data.model.formFeatures[i].type == '13') {
                        if (data.model.formFeatures[i].code) {
                            data.saftyWarnings.push({
                                typeText: FormHelper.getCodelistLabel(this.codelists['formFeatuireTypes'], data.model.formFeatures[i].code),
                                description: data.model.formFeatures[i].description
                            });
                        }
                    }
                }
            }
        }

        // determine gameduration,gamepieces,numberofplayers from formfeatures
        data.gameduration = '';
        data.gamepieces = '';
        data.numberofplayers = '';
        if(data.model.formFeatures){
            for (var i = 0; i < data.model.formFeatures.length; i++) {
                if (data.model.formFeatures[i].type == '24' && data.model.formFeatures[i].code) {
                        data.gameduration = data.model.formFeatures[i].code;
                        if(data.model.formFeatures[i].code.match(/[a-zA-Z]+/) == null){
                            data.gameduration += ' Minuten';
                        }
                }
                if (data.model.formFeatures[i].type == '22' && data.model.formFeatures[i].code) {
                    data.gamepieces = data.model.formFeatures[i].code;
                }
                if (data.model.formFeatures[i].type == '23' && data.model.formFeatures[i].code) {
                    data.numberofplayers = data.model.formFeatures[i].code;
                }
            }
        }

        // determine gameClassifications
        data.gameClassification = '';
        if (data.model.classifications) {
            for (var i = 0; i < data.model.classifications.length; i++) {
                if (data.model.classifications[i].type == 'C7' && data.model.classifications[i].sequence != null) {
                    if(data.gameClassification.length == 0){
                        data.gameClassification = FormHelper.getCodelistLabel(this.codelists['gameClassification'], data.model.classifications[i].code);
                    }else{
                        data.gameClassification += '; ' + FormHelper.getCodelistLabel(this.codelists['gameClassification'], data.model.classifications[i].code);
                    }
                }
            }
        }

        // relatedProducts
        data.relatedProducts = [];
        if (data.model.relatedProducts) {
            for (var i = 0; i < data.model.relatedProducts.length; i++) {
                var label = FormHelper.getCodelistLabel(this.codelists['relationTypes'], data.model.relatedProducts[i].type);
                var text = 'ISBN/GTIN ' + data.model.relatedProducts[i].productIdValue;
                if (data.model.relatedProducts[i].productForm) {
                    text += ' - ' + FormHelper.getCodelistLabel(this.codelists['productFormTypes'], data.model.relatedProducts[i].productForm);
                }
                data.relatedProducts.push({label: label, text: text});
            }
        }

        var setsAndSeries = FormHelper.getSetAndSeriesFromProduct(data.model);
        data.series = setsAndSeries.series;
        if (user.authorities.indexOf('SHOW_BLOCK_HIERARCHIE') > -1) {
            data.hierarchie = setsAndSeries.hierarchie;
        } else {
            data.hierarchie = [];
        }
        if (data.series.length > 0 || data.hierarchie.length > 0) {
            data.setsAndSeries = true;
        }

        // salesrights
        data.exclusiveRights = [];
        data.notForSale = [];
        data.nonExclusiveRights = [];
        if (data.model.salesRights) {
            for (var i = 0; i < data.model.salesRights.length; i++) {
                var text = '';
                if (data.model.salesRights[i].country) {
                    text += data.model.salesRights[i].country;
                }
                if (data.model.salesRights[i].region) {
                    if (text) {
                        text += " ";
                    }
                    text += data.model.salesRights[i].region;
                }
                if (data.model.salesRights[i].territory) {
                    if (text) {
                        text += " ";
                    }
                    text += data.model.salesRights[i].territory;
                }
                var textArr = text.split(" ");
                for (var j = 0; j < textArr.length; j++) {
                    if (data.model.salesRights[i].country) {
                        textArr[j] = FormHelper.getCodelistLabel(this.codelists["countryCodes"], textArr[j]);
                    }
                    if (data.model.salesRights[i].type == '01') {
                        data.exclusiveRights.push(textArr[j]);
                    }
                    if (data.model.salesRights[i].type == '02') {
                        data.nonExclusiveRights.push(textArr[j]);
                    }
                    if (data.model.salesRights[i].type == '03') {
                        data.notForSale.push(textArr[j]);
                    }
                }
            }
        }
        data.exclusiveRights = data.exclusiveRights.join(", ");
        data.notForSale = data.notForSale.join(", ");
        data.nonExclusiveRights = data.nonExclusiveRights.join(", ");

        data.barsortiment = data.model.type !== 'journal' && user.authorities.indexOf('SHOW_BARSORTIMENT') > -1;

        var deliveries = {};
        deliveries["DE"] = [];
        deliveries["AT"] = [];
        deliveries["CH"] = [];
        if (data.model.publisherData) {
            if (data.model.publisherData.sigelDE)
                deliveries["DE"].push(data.model.publisherData.sigelDE.replace(/^\//, ""));
            if (data.model.publisherData.sigelAT)
                deliveries["AT"].push(data.model.publisherData.sigelAT.replace(/^\//, ""));
            if (data.model.publisherData.sigelCH)
                deliveries["CH"].push(data.model.publisherData.sigelCH.replace(/^\//, ""));
        }
        if (data.model.aggregatorId !== '5001015') {
            if (data.model.wholesalers) {
                for (var i = 0; i < data.model.wholesalers.length; i++) {
                    if (['umb', 'icm', 'edis'].indexOf(data.model.wholesalers[i].recordSource) > -1) {
                        deliveries["DE"].push(data.model.wholesalers[i].sigl);
                        if ('umb' === data.model.wholesalers[i].recordSource) {
                            data.orderNoUmbreit = data.model.wholesalers[i].orderNumber;
                        } else if ('edis' === data.model.wholesalers[i].recordSource) {
                            data.orderNoEdis = data.model.wholesalers[i].orderNumber;
                        }
                    }
                    if (['koebu'].indexOf(data.model.wholesalers[i].recordSource) > -1) {
                        deliveries["AT"].push(data.model.wholesalers[i].sigl);
                    }
                    if (!(MiscHelper.isEmpty(data.model.aggregatorId) || ['0', '1'].indexOf(data.model.aggregatorId) > -1)) {
                        if (['oesb'].indexOf(data.model.wholesalers[i].recordSource) > -1) {
                            deliveries["AT"].push(data.model.wholesalers[i].sigl);
                        }
                    }
                    if (['sbz', 'uava'].indexOf(data.model.wholesalers[i].recordSource) > -1) {
                        deliveries["CH"].push(data.model.wholesalers[i].sigl);
                        if ('sbz' === data.model.wholesalers[i].recordSource) {
                            data.orderNoCbz = data.model.wholesalers[i].orderNumber;
                        }
                    }
                }
            }
        }
        deliveries["DE"].sort();
        deliveries["AT"].sort();
        deliveries["CH"].sort();
        deliveries = deliveries["DE"].concat(deliveries["AT"]).concat(deliveries["CH"]);
        var uniqueNames = [];
        $.each(deliveries, function (i, el) {
            if ($.inArray(el, uniqueNames) === -1) uniqueNames.push(el);
        });
        data.deliveries = uniqueNames.join("; ");
        if (data.model.aggregatorId === '5001015' && MiscHelper.isEmpty(data.deliveries && !MiscHelper.isEmpty(data.model.publisherData.deliveryAddendum))) {
            data.deliveries = data.model.publisherData.deliveryAddendum;
        }

        data.editButtonRole = data.model.publisherForMvbId && data.model.type !== 'multiBundle' ? 'MODIFY_PRODUCT' : 'doNotRenderButtonsHack';

        //ZIS
        if (data.model.zisInfo && data.model.type === 'journal') {
            //edition
            data.publisherFrequency = MsgHelper.getZisFrequencyMessage(FormHelper.getCodelistLabel(this.codelists["frequency"], data.model.zisInfo.frequency), data.model.zisInfo.frequency, data.model.zisInfo.frequencyText);
            data.advertising = data.model.zisInfo.advertising == true ? MsgHelper.getMessage('label.form.product.block.advertising.contained') : MsgHelper.getMessage('label.form.product.block.advertising.notContained');

            // availability
            if (data.model.archivedDate !== null || data.model.archivedReason !== null) {
                if (data.model.archivedDate !== null) {
                    data.archivedDate = DateHelper.convert(data.model.archivedDate, undefined, formatDateDayMonthYear);
                }

                // as I don't see any other way... we need to work with a mapping array
                authoritiesMapping = new Array;
                authoritiesMapping['NOT_DELIVERABLE'] = 'ARCHIVE_NOT_DELIVERABLE';
                authoritiesMapping['WITHDRAWN_FROM_SALE'] = 'ARCHIVE_WITHDRAWN_FROM_SALE';
                authoritiesMapping['it'] = 'ARCHIVE_INDEXED';
                authoritiesMapping['lzt'] = 'ARCHIVE_VLB_TOO_EXPENSIVE';
                authoritiesMapping['rnb'] = 'ARCHIVE_BILL_NOT_PAID';
                authoritiesMapping['vte'] = 'ARCHIVE_CEASED_ACTIVITIES';

                for (var i = 0; i < user.authorities.length; i++) {
                    if (data.model.archivedReason !== null && user.authorities[i] === authoritiesMapping[data.model.archivedReason]) {
                        data.archivedReason = FormHelper.getCodelistLabel(this.codelists['archivedReason'], data.model.archivedReason);
                    }
                }

                // should the archived date be visible?
                var today = new Date();
                var archivedDate = data.model.archivedDate;
                if (!MiscHelper.isEmpty(archivedDate)) {
                    var archivedDateAry = archivedDate.split('.');
                    archivedDate = new Date(archivedDateAry[1] + '-' + archivedDateAry[0] + '-' + archivedDateAry[2]);
                }
                // show date when product is inactive or when product is active and archived date is in future (#13201)
                var hasDate = !data.model.active || (data.model.active && archivedDate !== "" && archivedDate.getTime() > today.getTime());

                data.hasDate = hasDate;
            }

            // reset the availability label part
            data.availabilityLabel = 'availabilityJournal';

            // reset the audiences label part
            data.audiencesLabel = 'audiences.zis';
        }

        return data;
    },
    getTemplateFunction: function () {
        return SummaryTemplate;
    },
    render: function () {
        View.prototype.render.call(this);
    },
    attach: function () {
        View.prototype.attach.call(this);
        $('.' + this.el.className).find('.collapser').collapser(
            {
                mode: 'lines',
                truncate: 2,
                showText: MsgHelper.getMessage('label.show.more'),
                hideText: MsgHelper.getMessage('label.show.less'),
                showClass: 'show-class',
                hideClass: 'collapsed',
                controlBtn: 'collapseButton with-label',
                changeText: false
            }
        );
    },
    sortObjectByKey: function (obj) {
        var keys = [];
        var sorted_obj = {};

        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                keys.push(key);
            }
        }

        // sort keys
        keys.sort();

        // create new array based on Sorted Keys
        jQuery.each(keys, function (i, key) {
            sorted_obj[key] = obj[key];
        });

        return sorted_obj;
    }
});