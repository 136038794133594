var View = require('./view')
    , template = require('./templates/bisac_dialog');
var FormHelper = require('lib/form_helper');
var MiscHelper = require('lib/misc_helper');
var MsgHelper = require('lib/msg_helper');
var FormHelper = require('lib/form_helper');

module.exports = View.extend({

    id: 'bisac-dialog',
    autoRender: false,
    autoAttach: false,
    template: template,
    className: "lb-default",

    initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);

        var generalDesc = ' / ' + MsgHelper.getMessage('codelist407.bisac.node.separator');
        var codeList407 = this.getCollection('407').sort(function (a, b) {
            var desc1 = a.description.replace(generalDesc, '');
            var desc2 = b.description.replace(generalDesc, '');
            return (desc1 < desc2 ? -1 : (desc1 > desc2 ? 1 : 0));
        });
        var r = this.buildTree(codeList407);
        this.bisacSubjectTreeDataOrg = r[0];
        this.bisacSubjectTreeFlatData = r[1];

        this.bisacSubjectTreeData = [];
        for (var i = 0; i < r[0].length; i++) {
            var branch = r[0][i] === undefined ? undefined : [];
            var item = {
                branch: branch,
                checkbox: r[0][i].checkbox,
                description: r[0][i].description,
                id: r[0][i].id,
                inode: r[0][i].inode,
                label: r[0][i].label,
                radio: r[0][i].radio,
                disabled: r[0][i].disabled,
                code: r[0][i].code
            };
            this.bisacSubjectTreeData.push(item);
        }

        this.bisacSubjectTreeFlatLoaded = false;

    },

    getTemplateData: function () {
        var data = View.prototype.getTemplateData.call(this);
        data.id = this.id;
        return data;
    },

    dialogConfirm: function () {

        var classificationsModel = this.model.get('classifications');
        var bisacSelections = [];
        $('#bisacSubjectSelection li').each(function (index) {
            bisacSelections.push({
                type: '10',
                code: $(this).attr("data-code"),
                sourceName: 'Publisher',
                main: index === 0
            });

        });

        for (var i = classificationsModel.length - 1; i >= 0; i--) {
            if (classificationsModel[i] !== null && classificationsModel[i].type === '10') {
                var found = false;
                for (var j = 0; j < bisacSelections.length; j++) {
                    if (classificationsModel[i].code === bisacSelections[j].code) {
                        found = true;
                        this.model.set('classifications.' + i + '.sourceName', 'Publisher');
                        this.model.set('classifications.' + i + '.main', bisacSelections[j].main);
                        // classificationsModel[i].sourceName = 'Publisher';
                        // classificationsModel[i].main = bisacSelections[j].main;
                        if (bisacSelections[j].main === true) {
                            this.model.set('bisacMainSubject', bisacSelections[j].code)
                        }
                        bisacSelections.splice(j, 1);
                        break;
                    }
                }
                if (!found) {
                    // code was removed
                    classificationsModel[i] = null;
                    this.model.get('classifications')[i] = null;
                }
            }
        }

        // add new codes.
        for (var i = 0; i < bisacSelections.length; i++) {
            this.model.get('classifications').push(bisacSelections[i]);
            if (bisacSelections[i].main === true) {
                this.model.set('bisacMainSubject', bisacSelections[i].code)
            }
        }

        FormHelper.sortClassificationsWithoutType(this.model);

    },

    /**
     * Is been called from the underlying form_view immediate after the dialog is opend.
     */
    dialogOpened: function () {

        var that = this;
        // This "loaded" information is needed as an information for the tree control to see if the control is building
        // uo or in regular service.
        // this.bisacSubjectTreeFlatLoaded = false;

        $("#bisacSubjectTab a").click(function (e) {
            e.preventDefault();
            $(this).tab('show');
        });

        // $('#bisacSubject [href=#bisacSubjectSearch]').click(function (e) {
        //         if (that.bisacSubjectTreeFlatLoaded === false) {
        that.initTree('#bisacSubjecttreeflat', []);
        that.initSearchBox('#bisacSubjecttreeflat', '#bisacSubjectTreeSearch', that);
        //$('#bisacSubjecttreeflat').aciTree('init');
        $('#bisacSubjecttreeflat').on('acitree', that.getEventHandlerFunction(true, '#bisacSubjectSelection', ["407"], '#bisacSubjecttree'));
        // that.bisacSubjectTreeFlatLoaded = true;
        //         }
        //     }
        // );

        this.initTree('#bisacSubjecttree', this.bisacSubjectTreeData);
        $('#bisacSubjecttree').on('acitree', this.getEventHandlerFunction(false, '#bisacSubjectSelection', ["407"], '#bisacSubjecttreeflat'));
    },

    buildTree: function (collection) {
        var collectionData = [];
        var collectionDataFlat = [];

        var generalDesc = MsgHelper.getMessage('codelist407.bisac.node.separator');

        for (var i = 0; i < collection.length; i++) {
            var code = collection[i];
            var parts = code.description.split('/');
            // remove the literal "Genreral" on nodes.
            var general = false;
            // do we have a root node.
            var root = false;
            // the text been shown next to the node or leaf.
            var description;
            // remove the white space around the literals.
            for (var j = 0; j < parts.length; j++) {
                parts[j] = parts[j].trim();
            }

            if (parts[parts.length - 1] === generalDesc) {
                general = true;
                description = parts[parts.length - 2];
                if (parts.length === 2) {
                    root = true;
                }
            } else {
                description = parts[parts.length - 1];
            }

            var node = {
                "id": '',
                "code": code.code,
                "label": '',
                "inode": false,
                "checkbox": true,
                "radio": false,
                "description": description,
                // dummy nodes may not bee selected.
                "disabled": _.string.endsWith(code.code, '999')
            };

            if (root) {
                // let the id start with an underline to bee able to see if an identier is an bisac code or an
                // internal used id. The internal id is needed to be able to handle an hierarchical tree.
                node.id = '_' + code.code.substring(0, 3);
                collectionData.push(node);
            } else {
                if (general === true) {
                    // remove the genral part of that node. if not the node would be treated as a leaf.
                    parts.splice(parts.length - 1, 1);
                }
                var parentNode = this.findIn(collectionData, parts, 0);
                if (parentNode === null) {
                    continue;
                }
                if (parentNode.branch === undefined) {
                    parentNode.branch = [];
                    parentNode.inode = true;
                }
                // generate the internal id. This is very simular to the bisac codes. But starting with an underline.
                // Than follows the three signs known from bisac codes. The hext two tripples of numbers shows the
                // hierarchy depth and the position in the hierarchy.
                node.id = parentNode.id + MiscHelper.padding(parentNode.branch.length, 3);
                parentNode.branch.push(node);
            }
            node.label = '<span id="' + node.id + '_LBL"></span>[' + code.code + "] " + description;

            // Fir the flat tree also takes the literal from the above node to make the content clear.
            var description = parts.length === 3 ? parts[1] + ' / ' + parts[2] : node.description;
            collectionDataFlat.push({
                "id": node.id,
                "code": node.code,
                "label": '<span id="' + node.id + '_FLBL"></span>[' + node.code + "] " + description,
                "inode": node.inode,
                "checkbox": node.checkbox,
                "radio": node.radio,
                "description": description
            });
        }

        collectionData.sort(function (a, b) {
            return (a.code < b.code ? -1 : (a.code > b.code ? 1 : 0));
        });
        collectionDataFlat.sort(function (a, b) {
            return (a.code < b.code ? -1 : (a.code > b.code ? 1 : 0));
        });


        return [collectionData, collectionDataFlat];
    },

    /**
     * Saarch in an hierarchic or flat tree for special item.
     *
     * aParts may be an array of descriptions corresponding to the bisac node descriptions. It also may be a
     * bisac code or an internal id.
     *
     * @param collection An arrax of items been searched for the node.
     * @param aParts describes the item looking for.
     * @param aLevel starting search from that level. This only relevant in hierarchic collections.
     * @returns The collection record matching aParts ore nothing if no match eas found.
     */
    findIn: function (collection, aParts, aLevel) {

        var parts = aParts;
        var level = aLevel;

        if (Array.isArray(parts)) {
            var part = parts[level];
            for (var i = 0; i < collection.length; i++) {
                if (collection[i].description === part) {
                    if (parts.length - 2 > level) {
                        var r = this.findIn(collection[i].branch, parts, ++level);
                        return r;
                    } else {
                        return collection[i];
                    }
                }
            }
        } else {
            if (_.string.startsWith(parts, '_')) {
                if (!level) {
                    level = 0;
                }
                for (var i = level; i < (parts.length - 1) / 3; i++) {
                    var subParts = parts.substring(0, ((level + 1) * 3) + 1);
                    for (var j = 0; j < collection.length; j++) {
                        if (collection[j].id === subParts) {
                            if (subParts.length === parts.length) {
                                return collection[j];
                            } else {
                                if (collection[j].branch) {
                                    // it is an hierarchical structure.
                                    return this.findIn(collection[j].branch, aParts, ++level);
                                } else {
                                    // it seems to be a flat tree.
                                    return this.findIn(collection, aParts, ++level);
                                }
                            }
                        }
                    }
                }

            } else {
                for (var i = 0; i < collection.length; i++) {
                    if (collection[i].code === parts) {
                        return collection[i];
                    }
                    if (collection[i].branch !== undefined) {
                        var r = this.findIn(collection[i].branch, parts);
                        if (r !== null) {
                            return r;
                        }
                    }
                }
            }
        }
        return null;
    },

    getCollection: function (collectionId) {
        if (collectionId in this.options.collections) {
            return this.options.collections[collectionId].options;
        }
        var options = FormHelper.getCollection(this.options.formDef.attributes['collections'], collectionId);
        this.options.collections[collectionId] = {options: options, selects: []};
        return options;
    },

    removeSelectedSubject: function (event) {
        var id = $(event.target).parent().attr('id');

        var treeSelector = '#bisacSubjecttree';
        var api = $(treeSelector).aciTree('api');
        this.uncheck(false, id, api);
    },

    initTree: function (selector, data) {
        $(selector).aciTree({
            rootData: data,
            checkbox: true,
            checkboxChain: -1,
            animateRoot: false,
            queue: {
                async: 1,
                interval: 50,
                delay: 100
            }
        });
    },

    getEventHandlerFunction: function (isFlat, bisacSelectionElem, codelistCodes, otherTreeId) {
        var self = this;
        if (!isFlat) {
            self.syncBisacSubjectTrees = false;
        }

        var eventFunction = function (event, api, item, eventName, options) {
            var id;
            var apiOther;
            if (eventName === "beforecheck") {
                if (api.isItem(item) && api.hasParent(item)) {
                    var parents = api.path(item, true);

                    $(parents).each(function () {
                        api.uncheck($(this));
                    });
                }
            } else if (eventName === "checked") {
                if (api.isItem(item)) {
                    id = api.getId(item);


                    if (self.isOneChildChecked(api, item)) {
                        return;
                    }

                    var data = api.itemData(item);
                    var allreadyAvailable = false;
                    $('#bisacSubjectSelection li').each(function (index) {
                        if ($(this).attr("data-code") == data.code) {
                            allreadyAvailable = true;
                        }
                    });
                    if (!allreadyAvailable) {
                        var li = '<li class="list-group-item" data-code="' + data.code + '" id="' + id + '">[' + data.code + '] ' + data.description + '<span class="vlb-action-icon vlb-action-icon-delete bisacsubject-remove"></span>';
                        li += '<span class="bisacsubject-up">Up</span><span class="bisacsubject-down">Down</span>';
                        li += '</li>';
                        var prepend = false;
                        var classifications = self.model.get('classifications');
                        for (var i = 0; i < classifications.length; i++) {
                            if (classifications[i] !== null && classifications[i].code === data.id && classifications[i].type === '10' && classifications[i].main === true) {
                                prepend = true;
                            }
                        }
                        if (prepend === true) {
                            $(bisacSelectionElem).prepend(li);
                        } else {
                            $(bisacSelectionElem).append(li);
                        }
                    }

                    $(bisacSelectionElem + ' #' + id + ' .bisacsubject-remove').click(self.removeSelectedSubject.bind(self));

                    if (isFlat) {
                        self.ensureItemsAreAppended(id, bisacSelectionElem, $(otherTreeId).aciTree('api'), true, self);
                    } else {
                        if (self.isOneChildChecked(api, item)) {
                            return;
                        }
                        $('.bisac-dialog-confirm').removeClass('disabled');
                    }

                    $(bisacSelectionElem + ' #' + id + ' .bisacsubject-up').click(function (event) {
                        var parent = $(event.target).parent();
                        var prev = $(parent).prev();
                        if ($(prev).length > 0) {
                            $(parent).insertBefore(prev);
                        }
                    });
                    $(bisacSelectionElem + ' #' + id + ' .bisacsubject-down').click(function (event) {
                        var parent = $(event.target).parent();
                        var next = $(parent).next();
                        if ($(next).length > 0) {
                            $(parent).insertAfter(next);
                        }
                    });

                    if (self.syncBisacSubjectTrees) {
                        if (self.bisacSubjectTreeFlatLoaded === true) {
                            apiOther = $(otherTreeId).aciTree('api');
                            self.check(!isFlat, id, apiOther);
                        }
                    }
                }
            } else if (eventName === "unchecked") {
                if (api.isItem(item)) {
                    var data = api.itemData(item);
                    var id = api.getId(item);
                    $(bisacSelectionElem + ' #' + id).remove();
                    apiOther = $(otherTreeId).aciTree('api');
                    self.uncheck(!isFlat, id, apiOther);
                    if ($('#bisacSubjectSelection li').length === 0) {
                        $('.bisac-dialog-confirm').addClass('disabled');
                    }
                }
            } else if (eventName === "init") {
                self.syncBisacSubjectTrees = true;
                // make sure the main items are placed at the topmost position.
                var classificationsModel = self.model.get('classifications');
                for (var i = 0; i < classificationsModel.length; i++) {
                    if (classificationsModel[i] !== null
                        && classificationsModel[i].type === '10'
                        && classificationsModel[i].main === true) {
                        if (isFlat === false) {
                            self.ensureItemsAreAppended(classificationsModel[i].code,
                                bisacSelectionElem, api, true, self);
                        }
                        self.check(isFlat, classificationsModel[i].code, api);
                    }
                }
                for (var i = 0; i < classificationsModel.length; i++) {
                    if (classificationsModel[i] !== null
                        && classificationsModel[i].type === '10'
                        && classificationsModel[i].main === false) {
                        if (isFlat === false) {
                            self.ensureItemsAreAppended(classificationsModel[i].code,
                                bisacSelectionElem, api, true, self);
                        }
                        self.check(isFlat, classificationsModel[i].code, api);
                    }
                }

            } else if (eventName === "beforetoggle") {
                var hasChildren = api.hasChildren(item, true);
                if (!hasChildren) {
                    if (!isFlat) {
                        var arrThema = self.bisacSubjectTreeDataOrg;
                        ;
                        id = api.getId(item);
                        var itemData;
                        for (var j = 0; j < ((id.length - 1) / 3); j++) {
                            if (arrThema === undefined) {
                                break;
                            }
                            itemData = null;
                            var subId = id.substring(0, ((j + 1) * 3) + 1);
                            for (var i = 0; i < arrThema.length; i++) {
                                if (arrThema[i].id === subId) {
                                    break;
                                }
                            }
                            if (arrThema[i].id === id) {
                                api.append(item, {itemData: arrThema[i].branch});
                            } else {
                                arrThema = arrThema[i].branch;
                            }
                        }
                    }
                }
            }
        }

        return eventFunction;
    },

    showOrHideEmptyDesc: function () {
        if ($("#bisacSubjectSelection li").length === 0) {
            $("#bisacSubjectSelectionEmpty").show();
        } else {
            $("#bisacSubjectSelectionEmpty").hide();
        }
    },

    ensureItemsAreAppended: function (aCode, bisacSelectionElem, api, setChecked, that) {

        var item = null;
        var items = api.children(item, false, {});
        var arrThema = this.bisacSubjectTreeDataOrg;
        var currentPostion = 0;
        var anythingToAppend = false;
        var code = aCode;

        if (!_.string.startsWith(code, '_')) {
            var collectionItem = that.findIn(arrThema, code, 0);
            code = collectionItem.id;
        }

        for (var i = 1; i <= (code.length - 1) / 3; i++) {
            var codeTmp = code.substring(0, (i * 3) + 1);
            while (arrThema[currentPostion].id.substring(0, (i * 3) + 1) !== codeTmp) {
                currentPostion++;
                if (currentPostion >= arrThema.length) {
                    continue;
                }
            }
            item = $(items[currentPostion]);
            if (!api.hasChildren(item, true) && i < ((code.length - 1) / 3)
                && arrThema[currentPostion].branch) {
                anythingToAppend = true;
                api.append(item, {
                    _size: arrThema[currentPostion].branch.length,
                    itemData: arrThema[currentPostion].branch,
                    success: function (item, options) {
                        if (api.hasChildren($(item[0]), true)) {
                            var children = api.children(item, false, true);
                            for (var i = 0; i < children.length; i++) {
                                var child = $(children[i]);
                                if (!api.hasCheckbox(child)) {
                                    api.addCheckbox(child);
                                }
                            }
                            if (children.length > options._size) {
                                $.each(options.items, function () {
                                    api.remove($(this), {});
                                });
                            }
                            // when the children has been successful appended we can start the next cycle.
                            that.ensureItemsAreAppended(code, bisacSelectionElem, api, true, that);
                        }
                    }
                });
            }
            items = api.children(item, false, {});
            if (items.length === 0) {
                // If there are no children available we can stop this run and start again when the append success
                // function is called. At this time the children are appended and a new cyle can start.
                break;
            }
            arrThema = arrThema[currentPostion].branch;
            currentPostion = 0;
        }
        if (anythingToAppend === false) {
            // OK. We have reached the leaves. Now all items are appended and can be set checked.
            that.check(false, code, api);
        }
    },

    initSearchBox: function (treeSelector, searchBoxSelector, that) {

        $(searchBoxSelector).keyup(function () {
            if ($(this).val().length > 2) {
                var value = $(this).val();
                that.value = value;
                window.setTimeout(function () {
                    that.execFilter(treeSelector, that.value, that);
                }, 500);
            }
        });
    },

    /**
     * Filtering the items that have value in there description or id. The filter does it's work by first unloading all
     * items and afterward appending the items that matches the filter value.
     *
     * @param treeSelector css selector for the current flat tree.
     * @param value the filter value
     * @param that reference to this
     */
    execFilter: function (treeSelector, value, that) {
        var api = $(treeSelector).aciTree('api');
        // execute filtering only if the api tree does not currently filter and only if the previous and the current
        // values are different.
        if (api.isBusy() || this.previousValue === value) {
            return;
        }
        this.previousValue = value;
        api.unload(null, {
            // remember that the previous items must be unloaded successfully first bevore we can append the new items.
            success: function (item, options) {
                var unload = api.wasLoad(null);
                var allItems = that.bisacSubjectTreeFlatData;
                var filteredItems = [];
                that.filter(allItems, filteredItems, value);
                api.loadFrom(null, {
                    itemData: filteredItems,
                    success: function (item, options) {
                        var children = api.children(item, true, true);
                        for (var i = 0; i < children.length; i++) {
                            var child = $(children[i]);
                            if (!api.hasCheckbox(child)) {
                                api.addCheckbox(child);
                            }
                            var itemData = api.itemData(child);
                            if (itemData._checked === true) {
                                api.check(child, {});
                            }
                        }
                    }
                });
            }
        });
    },

    check: function (isFlat, aCode, api) {
        var itemCode = aCode;
        if (!_.string.startsWith(itemCode, '_')) {
            var arrThema = this.bisacSubjectTreeDataOrg;
            var collectionItem = this.findIn(arrThema, itemCode, 0);
            itemCode = collectionItem.id;
        }

        var lbl = this.getLabelElement(isFlat, itemCode);
        if (lbl.length > 0 && api.isItem($(lbl[0]))) {
            api.check($(lbl[0]));
        }
        var itemData;
        var itemDataFlat;
        itemData = this.bisacSubjectTreeDataOrg;
        itemDataFlat = this.bisacSubjectTreeFlatData;
        var item = this.findIn(itemData, itemCode);
        item._checked = true;
        item = this.findIn(itemDataFlat, itemCode);
        item._checked = true;
    },

    uncheck: function (isFlat, aCode, api) {
        var itemCode = aCode;
        if (!_.string.startsWith(itemCode, '_')) {
            var arrThema = this.bisacSubjectTreeDataOrg;
            var collectionItem = this.findIn(arrThema, itemCode, 0);
            itemCode = collectionItem.id;
        }

        var lbl = this.getLabelElement(isFlat, itemCode);
        if (lbl.length > 0 && api.isItem($(lbl[0]))) {
            api.uncheck($(lbl[0]));
        }
        itemData = this.bisacSubjectTreeDataOrg;
        itemDataFlat = this.bisacSubjectTreeFlatData;
        var item = this.findIn(itemData, itemCode);
        item._checked = false;
        item = this.findIn(itemDataFlat, itemCode);
        item._checked = false;
    },

    isOneChildChecked: function (api, rootItem) {
        var children = api.children(rootItem, true);
        for (var i = 0; i < children.length; i++) {
            var cc = children[i];
            if (api.isItem($(cc)) && api.isChecked($(cc))) {
                return true;
            }
        }
        return false;
    },

    /**
     *
     * @param orgItems items to be filtered
     * @param filteredItems the already filtered items and the array where to add further items that matches the filter.
     * @param aFilter the filter term
     */
    filter: function (orgItems, filteredItems, aFilter) {

        var filter = aFilter.toUpperCase();
        var bisacCodelist = this.getCollection('407');
        
        for (var i = 0; i < orgItems.length; i++) {        	
        	var item = orgItems[i];

        	if (item.description.toUpperCase().indexOf(filter) > -1 || item.code.toUpperCase().indexOf(filter) > -1) {
        		/*
        		 * item.label contains a span element containing the unique id of the item (see getLabelElement and call for that matter).
        		 * 18915 requests the label to be changed to display the whole tree since the tree view is not visible in the search results.
        		 * this lead to wrong choosing of bisac codes by the customers.
        		 */
        		item.label = item.label.substring(0, item.label.indexOf('</span>')) + this.getDescriptionFromCodelist(item, bisacCodelist);                 
                filteredItems.push(item);
            } 
            
            if (item.branch !== undefined) {
                this.filter(item.branch, filteredItems, aFilter);
            }
        }
    },
    
    getDescriptionFromCodelist: function (item, codelist) {
    	for (var i = 0; i < codelist.length;i++) {
    		if (item.code === codelist[i].code) {
    			return "[" + codelist[i].code + "] " + codelist[i].description;
    		}
    	}
    	
    	return "[" + item.code + "] ?";
    },

    getLabelElement: function (isFlat, itemCode) {
        var lblSuffix = '_LBL';
        if (isFlat) {
            lblSuffix = '_FLBL';
        }
        var retVal = $('#' + itemCode + lblSuffix).parents('li');
        
        return retVal;
    }

});
