var AfterLoadEventsHelper = function () {
};

AfterLoadEventsHelper.prototype.markArchivedProducts = function (widget) {
	if (widget === undefined || $(widget).find('a.isbn')[0] === undefined) {
		return;
	}
	
	var item = $(widget).find('a.isbn')[0]
					
	$.ajax({
		contentType: 'application/json',   
        dataType: 'json',	            
        url: apiRoot + "product/isactive/" + item.text +
        	"?access_token=" + user.access_token,
        type: 'GET',
        "success": function (data) {	            	
        	if (!data) {
        		$(item).append("<b>&nbsp;(a)*</b>");
        	}           	
        }                        
    });
};

module.exports = new AfterLoadEventsHelper;