var Model = require('models/nested_model');
var View = require('./view');
var MiscHelper = require('lib/misc_helper');
var SelectMediaFilesTemplate = require('./templates/forms/selectMediafilesForm');
var Collection = require('models/collection_with_auth');
var AssetTypeCollection = Collection.extend({url: apiRoot + "asset/fileTypes"});
var HistoryHelper = require('lib/history_helper');
var MsgHelper = require('lib/msg_helper');
var SelectMediafilesView;

module.exports = SelectMediafilesView = View.extend({

    className: 'selectMediafilesForm',
    autoRender: true,
    autoAttach: false,

    // assetFileTypes: new AssetTypeCollection(),

    initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);

        // loading collection of fileTypes
        // this.assetFileTypes.fetch({async: false});

        this.options.scopeData.mediaFiles.sort(function (a, b) {
            return a.sequenceNumber - b.sequenceNumber;
        });

        var medium = {};
        for (var i = 0; i < this.options.scopeData.mediaFiles.length; i++) {
            medium[this.options.scopeData.mediaFiles[i].id] = true;
            this.options.scopeData.mediaFiles[i].calculatedUrl = apiRoot + 'asset/download/'
                + this.options.scopeData.mediaFiles[i].assetFileId + '?access_token=' + user.access_token;
        }

        this.model = new Model({
            'selectMediafiles': 'takeAllMedia',
            'medium': medium
        });

        this.modelBinder = new Backbone.ModelBinder();
    },

    getTemplateData: function () {
        var data = View.prototype.getTemplateData.call(this);
        $.extend(data, data, this.options.scopeData);
        var mediaFiles = data.mediaFiles;
        if(this.options.scopeData.routeParams.origType !== 'ebook' && this.options.scopeData.routeParams.relateEbook === 1) {
            data.hint = new Handlebars.SafeString(MsgHelper.getMessage('msg.selectMediafiles.ebookCopyHint'));
        }
        for (var i = 0; i < mediaFiles.length; i++) {
            // var typeName = this.assetFileTypes.model.get()
        }

        return data;
    },

    getTemplateFunction: function () {
        return SelectMediaFilesTemplate;
    },

    attach: function () {
        View.prototype.attach.call(this);
        var bindings = {};
        bindings.selectMediafiles = '[name=selectMediafiles]';
        // bindings.medium = {};
        if (this.options.scopeData.mediaFiles.length > 0) {
            for (var i = 0; i < this.options.scopeData.mediaFiles.length; i++) {
                bindings['medium.' + this.options.scopeData.mediaFiles[i].id] = '#medium_' + this.options.scopeData.mediaFiles[i].id;
            }

        }

        this.modelBinder.bind(this.model, this.el, bindings);
    },

    events: {
        'change [name=selectMediafiles]': 'changeSelectMediafiles',
        'change .mediumRecord': 'changeMediumRecord',
        'click .selectMediafiles-close' : 'closeClicked'
    },

    closeClicked: function(event) {

        HistoryHelper.writeHistory(undefined, 'back');
        Backbone.history.history.back();
    },

    changeSelectMediafiles: function (event) {
        var selector = '.' + this.options.scopeData.ident + '-confirm';
        var obj = this.model.get('medium');
        if (event.target.value === 'dontTakeMedia') {
            for (var medium in obj) {
                this.model.set('medium.' + medium, false);
            }
            $(selector).removeClass('disabled');
            $('.mediumRecord').attr("disabled", true);
        }
        else if (event.target.value === 'takeAllMedia') {
            for (var medium in obj) {
                this.model.set('medium.' + medium, true);
            }
            $(selector).removeClass('disabled');
            $('.mediumRecord').attr("disabled", true);
        } else {
            for (var medium in obj) {
                this.model.set('medium.' + medium, false);
            }
            $(selector).addClass('disabled');
            $('.mediumRecord').attr("disabled", false);
        }
    },

    changeMediumRecord: function (event) {
        var selector = '.' + this.options.scopeData.ident + '-confirm';
        if (event.target.checked === true) {
            $(selector).removeClass('disabled');
        } else {
            var disabled = true;
            var obj = this.model.get('medium');
            for (var medium in obj) {
                if (obj[medium] === true && medium !== event.target.name) {
                    disabled = false;
                    break;
                }
            }
            if (disabled === true) {
                $(selector).addClass('disabled');
            } else {
                $(selector).removeClass('disabled');
            }
        }
    }

});