var HistoryHelper = function () {

    var history = sessionStorage.getItem('page-call-history');

    if (history === undefined || history === null) {
        var history = {
            counter: 0,
            size: 50,
            list: []
        };
        this.setHistoryItems(history);
    }
};

HistoryHelper.prototype.getHistoryItems = function () {
    var storageObject = sessionStorage.getItem('page-call-history');
    var history = JSON.parse(storageObject);
    return history;
};

HistoryHelper.prototype.setHistoryItems = function (history) {
    var storageObject = JSON.stringify(history);
    sessionStorage.setItem('page-call-history', storageObject);
};

HistoryHelper.prototype.writeHistory = function (params, action) {

    var history = this.getHistoryItems();
    var position = history.counter % HISTORY_SIZE;
    var historyElement = history.list[position];
    if (historyElement === undefined || historyElement === null) {
        historyElement = {};
        history.list[position] = historyElement;
    }
    historyElement.fragment = Backbone.history.fragment;
    historyElement.historyParams = params;
    history.counter++;
    this.setHistoryItems(history);
};

HistoryHelper.prototype.getHistory = function () {

    var history = this.getHistoryItems();
    return history;
};

HistoryHelper.prototype.getCurrentHistory = function () {

    var history = this.getHistoryItems();
    var position = (history.counter - 1) % HISTORY_SIZE;
    return history.list[position];
};

HistoryHelper.prototype.getHistoryRelativeFromCurrent = function (relativePositionFromCurrent) {

    var history = this.getHistoryItems();
    var position = (history.counter + relativePositionFromCurrent) % HISTORY_SIZE;
    return history.list[position];
};


module.exports = new HistoryHelper;