var Collection = require('./collection_with_auth');

var PageContentDataCollection = Collection.extend(
{
    initialize: function (options) {
        this.url = apiRoot + options.urlRoot;
        this.modelKey = options.modelKey;
        this.wid = options.wid;
    },	
    
    fetch: function (options) {

    	var theOptions = options ? options : {};
        theOptions['async'] = false;
        theOptions['cache'] = false;

        theOptions['success'] = function (collection, response, options) {        	        	        	       	
    		Chaplin.mediator.publish('collection_loaded', collection);
        };
        theOptions['error'] = function (model, response, options) {
            logger.info("Model: " + JSON.stringify(model));
            logger.info("Response: " + JSON.stringify(response));
            logger.info("Options: " + JSON.stringify(options));
        };
        return Collection.prototype.fetch.call(this, theOptions);
    }

});


module.exports = PageContentDataCollection;