var ListView = require('./list_view');
var MiscHelper = require('lib/misc_helper');
var Product = require('models/product');
var DownloadHelper = require('lib/download_helper');
var AjaxSpinner = require('lib/ajaxspinner_helper');

var ProductSearchResultView;

var logger = log4javascript.getLogger('views.ProductSearchResultView');

module.exports = ProductSearchResultView = ListView.extend({

    tagName: 'div',
    region: 'content',
    containerMethod: 'html',
    enableRowSelection: true,

    initialize: function (options) {
        ListView.prototype.initialize.apply(this, [options]);
        this.product = new Product({urlRoot: options.urlRoot});
        this.product.set('publisherForMvbId', true);
    },

    getDatatableUrl: function (tableSelector) {
        var url = $(tableSelector).attr("data-modelurl") + 's/';
        if (this.options.searchType !== 'all') {
            url += this.options.searchType;
        }
        if (this.options.searchType === 'collection') {
            url += '/' + this.model.formModel.get('identifier');
        }
        return url;
    },

    getModelUrl: function () {
        if (this.model.get('contentType') === 'series' || this.model.get('contentType') === 'set') {
            return apiRoot + 'collection';
        }
        return this.product.urlRoot;
    },

    displaySearchBox: function () {
        return false;
    },

    getSearchPhrase: function (paramMap) {
        if (this.options.searchType !== 'all') {
            return this.model.get('query');
        }
        return "";
    },

    afterDataLoaded: function (tableSelector) {
        if (this.options.tableSelector !== tableSelector) {
            return;
        }
        ListView.prototype.afterDataLoaded.apply(this, [tableSelector]);
        var databasesArray = this.model.attributes['dbFilterArray'];
        if (databasesArray !== undefined) {
            for (db in databasesArray) {
                if (databasesArray[db]) {
                    //nothingIsChecked = false;
                    $('.databases input[name=databases_' + db + ']').prop('checked', true);
                }
            }

            this.delegate('click', '.databases input[name^=databases]', this.changedDatabase);
        }
    },

    beforeDataLoaded: function (tableSelector) {
        if (this.options.tableSelector !== tableSelector) {
            return;
        }
        ListView.prototype.beforeDataLoaded.apply(this, [tableSelector]);

        this.undelegate('click', '.databases input[name^=databases]', this.changedDatabase);
    },

    dataLoaded: function () {
        DownloadHelper.init(this);
    },

    changedDatabase: function (event) {
        var id = event.target.id;
        var value = $('#' + id).prop('checked');
        var db = id.substring(10);
        var mode = this.model.get('mode');
        var query = decodeURIComponent(this.model.get('query'));
        var queryId = null;
        var databasesObject = this.model.attributes['databases'];
        globalCache.clear();

        var validAggregatorIDs = MiscHelper.getAggregatorIds(databasesObject);
        for (var i = this.selections.ids.length-1; i >= 0; i--) {
            if($.inArray(this.selections.ids[i].aggrID, validAggregatorIDs) === -1) {
                this.selections.ids.splice(i, 1);
            }
        }

        // TODO: check if we need this switch!
        switch (mode) {
            case "quick":
                var newQuery = MiscHelper.rebuildQuery(query, db, value, databasesObject, mode);
                this.model.set('query', newQuery.query);
                // dbFilterArray is the query database filter.
                this.model.set('dbFilterArray', MiscHelper.getDatabases(newQuery.query));
                break;
            case "batch":
                var newQuery = MiscHelper.rebuildQuery(query, db, value, databasesObject, mode);
                this.model.set('query', newQuery.query);
                // dbFilterArray is the query database filter.
                this.model.set('dbFilterArray', MiscHelper.getDatabases(newQuery.query));
                break;
            case "advanced":
                var newQuery = MiscHelper.rebuildQuery(query, db, value, databasesObject, mode);
                this.model.set('query', newQuery.query);
                // dbFilterArray is the query database filter.
                this.model.set('dbFilterArray', MiscHelper.getDatabases(newQuery.query));
                break;
            case "boole":
                var newQuery = MiscHelper.rebuildQuery(query, db, value, databasesObject, mode);
                this.model.set('query', newQuery.query);
                // dbFilterArray is the query database filter.
                this.model.set('dbFilterArray', MiscHelper.getDatabases(newQuery.query));
                break;
        }
        AjaxSpinner.show();
        this.options.table.draw();
    },

    events: {
        'click #actionBtn-changeSearch': 'changeSearch'
    },

    changeSearch: function (event) {
        Chaplin.mediator.publish('redirectTo', {
            url: 'search_page_' + this.options.searchType,
            data: {
                queryid: this.selections.queryId,
                query: this.model.attributes.query,
                searchType: this.options.searchType
            }
        });

        return false;
    }
});

