var View = require('./view');
var QualityStatusTemplate = require('./templates/forms/product_quality_status');
var MiscHelper = require('lib/misc_helper');
var MsgHelper = require('lib/msg_helper');
var InvoiceRecord = require('../models/invoiceRecord');

var ProductQualityStatusView;

module.exports = ProductQualityStatusView = View.extend({

    container: '#product_quality_status',
    autoRender: true,
    autoAttach: false,
    className: 'productQualityStatus',

    initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);
    },

    getTemplateData: function () {
        var data = View.prototype.getTemplateData.call(this);

        data.labelPrefix = 'form.product.block.productQualityStatus';

        if (this.model.get('type') !== 'journal') {
            var invoiceRecord = new InvoiceRecord({
                id: this.model.get('id')
                // Undefined price codelist
            });
            data.invoices = [];
            invoiceRecord.fetch();
            for (var i = 0; i < invoiceRecord.get('content').length; i++) {
                currentIR = invoiceRecord.get('content')[i];
                if (i === 0) {
                    data.show = true;
                    data.year = currentIR['year'];
                    var invoiceStatus = this.model.get('invoiceStatus');

                    data.invoiceStatus = MsgHelper.formatMessage('label.' + data.labelPrefix + '.' + invoiceStatus, [data.year]);
                    data.stateRelevantAttributes = [];
                    data.stateRelevantAttributes.push({label: 'cover', value: currentIR['cover']});
                    data.stateRelevantAttributes.push({label: 'contributor', value: currentIR['contributor']});
                    data.stateRelevantAttributes.push({
                        label: 'mainDescription',
                        value: currentIR['mainDescription']
                    });
                    data.stateRelevantAttributes.push({label: 'language', value: currentIR['language']});
                    data.stateRelevantAttributes.push({label: 'extent', value: currentIR['extent']});
                    data.stateRelevantAttributes.push({label: 'keywords', value: currentIR['keywords']});
                    data.stateRelevantAttributes.push({label: 'thema', value: currentIR['thema']});
                    if (versionCountry === 'VLBde') {
	                    data.stateRelevantAttributes.push({label: 'dimension', value: currentIR['dimension']});
	                    data.stateRelevantAttributes.push({label: 'countryOfManufacture', value: currentIR['countryOfManufacture']});
	                    data.stateRelevantAttributes.push({label: 'tariffNumber', value: currentIR['tariffNumber']});
                    }
                    for (var j = 0; j < data.stateRelevantAttributes.length; j++) {
                        if (!data.stateRelevantAttributes[j].value) {
                            data.stateRelevantAttributes[j].value = false;
                        }
                    }
                    if (versionCountry === 'VLBde') {
                        data.ageClass = currentIR['ageClass'];
                    }
                }

                var availabilities = [];
                availabilities.push({
                    label: 'availability1',
                    value: currentIR['availability1'],
                    year: currentIR['year']
                });
                availabilities.push({
                    label: 'availability2',
                    value: currentIR['availability2'],
                    year: currentIR['year']
                });
                availabilities.push({
                    label: 'availability3',
                    value: currentIR['availability3'],
                    year: currentIR['year']
                });
                availabilities.push({
                    label: 'availability4',
                    value: currentIR['availability4'],
                    year: currentIR['year']
                });
                for (var j = 0; j < availabilities.length; j++) {
                    if (!availabilities[j].value) {
                        availabilities[j].value = false;
                    }
                }
                if (versionCountry === 'VLBde') {
                    data.invoices.push({year: currentIR['year'], availabilities: availabilities});
                }
            }
        }

        return data;
    },

    getTemplateFunction: function () {
        return QualityStatusTemplate;
    },

    render: function () {
        View.prototype.render.call(this);
    },

    attach: function () {
        View.prototype.attach.call(this);
    }

});
