var CSSHelper = function() {

};
/**
 * Appends widget basic style class to the given style class array parameter.
 * 
 * @param styleClass
 *            the array style class to append basic widget style.
 */
CSSHelper.prototype.widgetStyleClass = function(styleClass) {
	var basis_wigdet_css = "wigdet-css";
	if (styleClass == null || !($.isArray(styleClass))) {
		styleClass = [];
	}
	styleClass.unshift(basis_wigdet_css);
	return styleClass.join(' ').toString();
};