var View = require('./view')
    , template = require('./templates/thema_dialog');
var FormHelper = require('lib/form_helper');

module.exports = View.extend({

    id: 'thema-dialog',
    autoRender: false,
    autoAttach: false,
    template: template,
    className: "lb-default",
    themaAdditions: ['94', '95', '96', '97', '98', '99'],

    events: {
        'click .themasubject-remove': 'removeSelectedSubject'
    },

    initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);

        var r = this.buildTree(this.getCollection('393'), "93");
        this.themaSubjectTreeDataOrg = r[0];
        this.themaSubjectTreeFlatData = r[1];

        this.themaSubjectTreeData = [];
        for (var i = 0; i < r[0].length; i++) {
            var branch = r[0][i] === undefined ? undefined : [];
            var item = {
                branch: branch,
                checkbox: r[0][i].checkbox,
                description: r[0][i].description,
                id: r[0][i].id,
                inode: r[0][i].inode,
                label: r[0][i].label,
                radio: r[0][i].radio,
                themaType: r[0][i].themaType
            };
            this.themaSubjectTreeData.push(item);
        }

        this.themaAdditionTreeDataOrg = [];
        this.themaAdditionTreeFlatData = [];
        for (var i = 0; i < this.themaAdditions.length; i++) {
            r = this.buildTree(this.getCollection('3' + this.themaAdditions[i]), this.themaAdditions[i]);
            Array.prototype.push.apply(this.themaAdditionTreeDataOrg, r[0]);
            Array.prototype.push.apply(this.themaAdditionTreeFlatData, r[1]);
        }

        this.themaAdditionTreeData = [];
        for (var i = 0; i < this.themaAdditionTreeDataOrg.length; i++) {
            var branch = this.themaAdditionTreeDataOrg[i] === undefined ? undefined : [];
            var item = {
                branch: branch,
                checkbox: this.themaAdditionTreeDataOrg[i].checkbox,
                description: this.themaAdditionTreeDataOrg[i].description,
                id: this.themaAdditionTreeDataOrg[i].id,
                inode: this.themaAdditionTreeDataOrg[i].inode,
                label: this.themaAdditionTreeDataOrg[i].label,
                radio: this.themaAdditionTreeDataOrg[i].radio,
                themaType: this.themaAdditionTreeDataOrg[i].themaType
            };
            this.themaAdditionTreeData.push(item);
        }
        this.themaSubjectTreeFlatLoaded = false;
        this.themaAdditionsTreeFlatLoaded = false;
    },

    getSelectedFromModel: function (typeCode) {
        var selected = [];
        var classificationsModel = this.model.get('classifications');
        for (var i = 0; i < classificationsModel.length; i++) {
            if (classificationsModel[i].type === typeCode) {
                selected.push(classificationsModel[i].code);
            }
        }
        return selected;
    },

    getCollection: function (collectionId) {
        if (collectionId in this.options.collections) {
            return this.options.collections[collectionId].options;
        }
        var options = FormHelper.getCollection(this.options.formDef.attributes['collections'], collectionId);
        this.options.collections[collectionId] = {options: options, selects: []};
        return options;
    },

    buildTree: function (collection, themaType) {
        var collectionData = [];
        var collectionDataFlat = [];
        var previousCode = null;
        var qualifierParents = [];

        for (var i = 0; i < collection.length; i++) {
            var code = collection[i];

            var node = {
                "id": code.code,
                "label": '<span id="' + code.code + '_LBL"></span>[' + code.code + "] " + code.description,
                "inode": false,
                "checkbox": true,
                "radio": false,
                "description": code.description,
                "themaType": themaType
            };

            collectionDataFlat.push({
                "id": code.code,
                "label": '<span id="' + code.code + '_FLBL"></span>[' + code.code + "] " + code.description,
                "inode": false,
                "checkbox": true,
                "radio": false,
                "description": code.description,
                "themaType": themaType
            });

            if (code.code.length === 1) {
                collectionData.push(node);
            } else {
                var parentId = code.code.substring(0, code.code.length - 1);

                var dashIndex = code.code.indexOf("-");
                if (dashIndex > -1) {
                    if (previousCode === parentId) {
                        qualifierParents.push(parentId);
                    }
                    parentId = qualifierParents.length > 0 && qualifierParents.indexOf(parentId) > -1 ? parentId :  code.code.substring(0, dashIndex);
                }

                var parentNode = this.findIn(collectionData, parentId);
                if (parentNode.branch === undefined) {
                    parentNode.branch = [];
                    parentNode.inode = true;
                }

                parentNode.branch.push(node);

                previousCode = code.code;
            }
        }
        return [collectionData, collectionDataFlat];
    },

    findIn: function (collection, node) {
        for (var i = 0; i < collection.length; i++) {
            if (collection[i].id === node) {
                return collection[i];
            }
            if (collection[i].branch !== undefined) {
                var r = this.findIn(collection[i].branch, node);
                if (r !== null) {
                    return r;
                }
            }
        }

        return null;
    },

    getTemplateData: function () {
        var data = View.prototype.getTemplateData.call(this);
        data.id = this.id;
        return data;
    },

    dialogConfirm: function () {
        var classificationsModel = this.model.get('classifications');
        var themaSelections = [];
        $('#themaSubjectSelection li').each(function (index) {
            themaSelections.push({
                type: $(this).attr("data-cl"),
                code: $(this).attr("id"),
                sourceName: 'Publisher',
                main: index === 0
            });

        });

        $('#themaAdditionSelection li').each(function (index) {
            themaSelections.push({
                type: $(this).attr("data-cl"),
                code: $(this).attr("id"),
                sourceName: 'Publisher',
                main: false
            });

        });

        var previousUsedIssues = [];
        for (var i = classificationsModel.length - 1; i >= 0; i--) {
            if (classificationsModel[i] !== null && (classificationsModel[i].type === "93" || this.themaAdditions.indexOf(classificationsModel[i].type) !== -1)) {
                var found = false;
                for (var j = 0; j < themaSelections.length; j++) {
                    if (classificationsModel[i].type === themaSelections[j].type && classificationsModel[i].code === themaSelections[j].code) {
                        found = true;
                        // this.model.set('classifications.' + i + '.sourceName', 'Publisher');
                        // this.model.set('classifications.' + i + '.main', themaSelections[j].main);
                        classificationsModel[i].sourceName = 'Publisher';
                        classificationsModel[i].main = themaSelections[j].main;
                        themaSelections.splice(j, 1);
                        break;
                    }
                }
                if (!found) {
                    // code was removed
                    classificationsModel[i] = null;
                }
            }
        }

        // add new codes.
        for (var i = 0; i < themaSelections.length; i++) {
            // this.model.get('classifications').push(themaSelections[i]);
            classificationsModel.push(themaSelections[i]);
        }

        FormHelper.sortClassificationsWithoutType(classificationsModel);

        return classificationsModel;
    },

    removeSelectedSubject: function (event) {
        var id = $(event.target).parent().attr('id');
        var codelistId = $(event.target).parent().attr('data-cl');

        var treeSelector = '#themaSubjecttree';
        if (codelistId !== '93') {
            treeSelector = '#themaAdditiontree';
        }
        var api = $(treeSelector).aciTree('api');
        this.uncheck(false, id, api);
    },

    initTree: function (selector, data) {
        $(selector).aciTree({
            rootData: data,
            checkbox: true,
            checkboxChain: -1,
            animateRoot: false,
            queue: {
                async: 1,
                interval: 50,
                delay: 100
            }
        });
    },

    initSearchBox: function (treeSelector, searchBoxSelector, that) {
        $(searchBoxSelector).keyup(function () {
            if ($(this).val().length > 2) {
                var value = $(this).val();
                that.value = value;
                window.setTimeout(function () {
                    that.execFilter(treeSelector, that.value, that);
                }, 500);
            }
        });
    },

    /**
     * Filtering the items that have value in there description or id. The filter does it's work by first unloading all
     * items and afterward appending the items that matches the filter value.
     *
     * @param treeSelector css selector for the current flat tree.
     * @param value the filter value
     * @param that reference to this
     */
    execFilter: function (treeSelector, value, that) {
        var api = $(treeSelector).aciTree('api');
        // execute filtering only if the api tree does not currently filter and only if the previous and the current
        // values are different.
        if (api.isBusy() || this.previousValue === value) {
            return;
        }
        this.previousValue = value;
        api.unload(null, {
            // remember that the previous items must be unloaded successfully first bevore we can append the new items.
            success: function (item, options) {
                var unload = api.wasLoad(null);
                var allItems;
                if (treeSelector === '#themaSubjecttreeflat') {
                    allItems = that.themaSubjectTreeFlatData;
                } else {
                    allItems = that.themaAdditionTreeFlatData;
                }
                var filteredItems = [];
                that.filter(allItems, filteredItems, value);
                api.loadFrom(null, {
                    itemData: filteredItems,
                    success: function (item, options) {
                        var children = api.children(item, true, true);
                        for (var i = 0; i < children.length; i++) {
                            var child = $(children[i]);
                            if (!api.hasCheckbox(child)) {
                                api.addCheckbox(child);
                            }
                            var itemData = api.itemData(child);
                            if (itemData._checked === true) {
                                api.check(child, {});
                            }
                        }
                    }
                });
            }
        });
    },

    /**
     *
     * @param orgItems items to be filtered
     * @param filteredItems the already filtered items and the array where to add further items that matches the filter.
     * @param aFilter the filter term
     */
    filter: function (orgItems, filteredItems, aFilter) {

        var filter = aFilter.toUpperCase();
        for (var i = 0; i < orgItems.length; i++) {
            if (orgItems[i].description.toUpperCase().indexOf(filter) > -1) {
                filteredItems.push(orgItems[i]);
            } else if (orgItems[i].id.toUpperCase().indexOf(filter) > -1) {
                filteredItems.push(orgItems[i]);
            }
            if (orgItems[i].branch !== undefined) {
                this.filter(orgItems[i].branch, filteredItems, aFilter);
            }
        }

    },

    dialogOpened: function () {

        var that = this;
        this.themaSubjectTreeFlatLoaded = false;
        this.themaAdditionsTreeFlatLoaded = false;

        $("#themaTab a").click(function (e) {
            e.preventDefault();
            $(this).tab('show');
        });

        $("#themaSubjectTab a").click(function (e) {
            e.preventDefault();
            $(this).tab('show');
        });

        $("#themaAdditionTab a").click(function (e) {
            e.preventDefault();
            $(this).tab('show');
        });

        $('#themaSubject [href=#themaSubjectSearch]').click(function (e) {
                if (that.themaSubjectTreeFlatLoaded === false) {
                    that.initTree('#themaSubjecttreeflat', []);
                    that.initSearchBox('#themaSubjecttreeflat', '#themaSubjectTreeSearch', that);
                    //$('#themaSubjecttreeflat').aciTree('init');
                    $('#themaSubjecttreeflat').on('acitree', that.getEventHandlerFunction(true, '#themaSubjectSelection', ["93"], '#themaSubjecttree'));
                    that.themaSubjectTreeFlatLoaded = true;
                }
            }
        );
        $('#themAddition [href=#themaAdditionSearch]').click(function (e) {
                if (that.themaAdditionsTreeFlatLoaded === false) {
                    that.initTree('#themaAdditiontreeflat', []);
                    that.initSearchBox('#themaAdditiontreeflat', '#themaAdditionTreeSearch', that);
                    //$('#themaAdditiontreeflat').aciTree('init');
                    $('#themaAdditiontreeflat').on('acitree', that.getEventHandlerFunction(true, '#themaAdditionSelection', that.themaAdditions, '#themaAdditiontree'));
                    that.themaAdditionsTreeFlatLoaded = true;
                }
            }
        );

        // init the tree
        //subject
        this.initTree('#themaSubjecttree', this.themaSubjectTreeData);

        $('#themaSubjecttree').on('acitree', this.getEventHandlerFunction(false, '#themaSubjectSelection', ["93"], '#themaSubjecttreeflat'));

        //additions
        this.initTree('#themaAdditiontree', this.themaAdditionTreeData);

        $('#themaAdditiontree').on('acitree', this.getEventHandlerFunction(false, '#themaAdditionSelection', this.themaAdditions, '#themaAdditiontreeflat'));
    },

    getLabelElement: function (isFlat, itemCode) {
        var lblSuffix = '_LBL';
        if (isFlat) {
            lblSuffix = '_FLBL';
        }
        var retVal = $('#' + itemCode + lblSuffix).parents('li');
        return retVal;
    },

    check: function (isFlat, itemCode, api) {
        var lbl = this.getLabelElement(isFlat, itemCode);
        if (lbl.length > 0 && api.isItem($(lbl[0]))) {
            api.check($(lbl[0]));
            this.showOrHideEmptyDesc();
        }
        var itemData;
        var itemDataFlat;
        if (api._instance.jQuery[0].id.indexOf('Subject') > -1) {
            itemData = this.themaSubjectTreeDataOrg;
            itemDataFlat = this.themaSubjectTreeFlatData;
        } else {
            itemData = this.themaAdditionTreeDataOrg;
            itemDataFlat = this.themaAdditionTreeFlatData;
        }
        var item = this.findIn(itemData, itemCode);
        item._checked = true;
        item = this.findIn(itemDataFlat, itemCode);
        item._checked = true;
    },

    uncheck: function (isFlat, itemCode, api) {
        var lbl = this.getLabelElement(isFlat, itemCode);
        if (lbl.length > 0 && api.isItem($(lbl[0]))) {
            api.uncheck($(lbl[0]));
            this.showOrHideEmptyDesc();
        }
        if (api._instance.jQuery[0].id.indexOf('Subject') > -1) {
            itemData = this.themaSubjectTreeDataOrg;
            itemDataFlat = this.themaSubjectTreeFlatData;
        } else {
            itemData = this.themaAdditionTreeDataOrg;
            itemDataFlat = this.themaAdditionTreeFlatData;
        }
        var item = this.findIn(itemData, itemCode);
        item._checked = false;
        item = this.findIn(itemDataFlat, itemCode);
        item._checked = false;
    },

    isOneChildChecked: function (api, rootItem) {
        var children = api.children(rootItem, true);
        for (var i = 0; i < children.length; i++) {
            var cc = children[i];
            if (api.isItem($(cc)) && api.isChecked($(cc))) {
                return true;
            }
        }
        return false;
    },

    showOrHideEmptyDesc: function () {
        if ($("#themaSubjectSelection li").length === 0) {
            $("#themaSubjectSelectionEmpty").show();
        } else {
            $("#themaSubjectSelectionEmpty").hide();
        }
        if ($("#themaAdditionSelection li").length === 0) {
            $("#themaAdditionSelectionEmpty").show();
        } else {
            $("#themaAdditionSelectionEmpty").hide();
        }
    },

    ensureItemsAreAppended: function (code, themaSelectionElem, api, setChecked, that) {
        var item = null;
        var items = api.children(item, false, {});
        var arrThema;
        var currentPostion = 0;
        var anythingToAppend = false;

        if (themaSelectionElem === '#themaSubjectSelection') {
            arrThema = this.themaSubjectTreeDataOrg;
        } else {
            arrThema = this.themaAdditionTreeDataOrg;
        }
        for (var i = 1; i <= code.length; i++) {
            var codeTmp = code.substring(0, i);
            var dashIndex = codeTmp.indexOf('-');

            if (dashIndex > -1) {
                var codeOnPos = arrThema[currentPostion].id;
                codeTmp = code.substring(0,codeOnPos.length);
                while (!_.string.startsWith(arrThema[currentPostion].id,codeTmp)) {
                    currentPostion++;
                }
            } else {
                while (arrThema[currentPostion].id !== codeTmp) {
                    currentPostion++;
                }
            }
            item = $(items[currentPostion]);
            if (!api.hasChildren(item, true) && i < code.length
                && arrThema[currentPostion].branch) {
                anythingToAppend = true;
                api.append(item, {
                    _size: arrThema[currentPostion].branch.length,
                    itemData: arrThema[currentPostion].branch,
                    success: function (item, options) {
                        if (api.hasChildren($(item[0]), true)) {
                            var children = api.children(item, false, true);
                            for (var i = 0; i < children.length; i++) {
                                // Just make sure that all the children has a checkbox.
                                var child = $(children[i]);
                                if (!api.hasCheckbox(child)) {
                                    api.addCheckbox(child);
                                }
                            }
                            if (children.length > options._size) {
                                $.each(options.items, function () {
                                    api.remove($(this), {});
                                });
                            }
                            // when the children has been successfull appended we can start the next cycle.
                            that.ensureItemsAreAppended(code, themaSelectionElem, api, true, that);
                        }
                    }
                });
            }
            items = api.children(item, false, {});
            if (items.length === 0) {
                // If there are no children available we can stop this run and start again when the append success
                // function is called. At this time the children are appended and a new cyle can start.
                break;
            }
            arrThema = arrThema[currentPostion].branch;
            currentPostion = 0;
        }
        if (anythingToAppend === false) {
            // OK. We have reached the leaves. Now all items are appended and can be set checked.
            that.check(false, code, api);
        }
    },

    getEventHandlerFunction: function (isFlat, themaSelectionElem, codelistCodes, otherTreeId) {
        var self = this;
        if (!isFlat) {
            if (codelistCodes.indexOf('93') !== -1) {
                self.syncThemaSubjectTrees = false;
            } else {
                self.syncThemaAdditionTrees = false;
            }
        }

        var eventFunction = function (event, api, item, eventName, options) {
            if (eventName === "beforecheck") {
                if (api.isItem(item) && api.hasParent(item)) {
                    var parents = api.path(item, true);

                    $(parents).each(function () {
                        api.uncheck($(this));
                    });
                }
            } else if (eventName === "checked") {
                if (api.isItem(item)) {
                    var id = api.getId(item);

                    if (self.isOneChildChecked(api, item)) {
                        return;
                    }

                    var data = api.itemData(item);
                    if ($(themaSelectionElem + ' #' + id).length < 1) {
                        var li = '<li class="list-group-item" data-cl="' + data.themaType + '" id="' + id + '">[' + id + '] ' + data.description + '<span class="vlb-action-icon vlb-action-icon-delete themasubject-remove"></span>';
                        if (codelistCodes.indexOf('93') !== -1) {
                            li += '<span class="themasubject-up">Up</span><span class="themasubject-down">Down</span>';
                        }

                        li += '</li>';
                        var prepend = false;
                        if (data.themaType === '93') {
                            var classifications = self.model.get('classifications');
                            for (var i = 0; i < classifications.length; i++) {
                                if (classifications[i] !== null && classifications[i].code === data.id && classifications[i].type === '93' && classifications[i].main === true) {
                                    prepend = true;
                                }
                            }
                        }
                        if (prepend === true) {
                            $(themaSelectionElem).prepend(li);
                        } else {
                            $(themaSelectionElem).append(li);
                        }

                        $(themaSelectionElem + ' #' + id + ' .themasubject-remove').click(self.removeSelectedSubject.bind(self));

                        if (isFlat === true) {
                            self.ensureItemsAreAppended(id, themaSelectionElem, $(otherTreeId).aciTree('api'), true, self);
                        }

                        if (codelistCodes.indexOf('93') !== -1) {
                            $(themaSelectionElem + ' #' + id + ' .themasubject-up').click(function (event) {
                                var parent = $(event.target).parent();
                                var prev = $(parent).prev();
                                if ($(prev).length > 0) {
                                    $(parent).insertBefore(prev);
                                }
                            });
                            $(themaSelectionElem + ' #' + id + ' .themasubject-down').click(function (event) {
                                var parent = $(event.target).parent();
                                var next = $(parent).next();
                                if ($(next).length > 0) {
                                    $(parent).insertAfter(next);
                                }
                            });

                            if (self.syncThemaSubjectTrees) {
                                if (self.themaSubjectTreeFlatLoaded === true) {
                                    var apiOther = $(otherTreeId).aciTree('api');
                                    self.check(!isFlat, id, apiOther);
                                }
                            }
                        } else {
                            if (self.syncThemaAdditionTrees) {
                                if (self.themaAdditionsTreeFlatLoaded === true) {
                                    var apiOther = $(otherTreeId).aciTree('api');
                                    self.check(!isFlat, id, apiOther);
                                }
                            }
                        }
                    }
                }
            } else if (eventName === "unchecked") {
                if (api.isItem(item)) {
                    var id = api.getId(item);
                    if ($(themaSelectionElem + ' #' + id).length > 0) {
                        $(themaSelectionElem + ' #' + id).remove();

                        var apiOther = $(otherTreeId).aciTree('api');
                        self.uncheck(!isFlat, id, apiOther);
                    }
                }
            } else if (eventName === "init") {
                // make sure the main items are placed at the topmost position.
                var classificationsModel = self.model.get('classifications');
                for (var i = 0; i < classificationsModel.length; i++) {
                    if (classificationsModel[i] !== null
                        && codelistCodes.indexOf(classificationsModel[i].type) !== -1
                        && classificationsModel[i].main === true) {
                        if (isFlat === false) {
                            self.ensureItemsAreAppended(classificationsModel[i].code,
                                themaSelectionElem, api, true, self);
                        }
                        self.check(isFlat, classificationsModel[i].code, api);
                    }
                }
                for (var i = 0; i < classificationsModel.length; i++) {
                    if (classificationsModel[i] !== null
                        && codelistCodes.indexOf(classificationsModel[i].type) !== -1
                        && classificationsModel[i].main !== true) {
                        if (isFlat === false) {
                            self.ensureItemsAreAppended(classificationsModel[i].code,
                                themaSelectionElem, api, true, self);
                        }
                        self.check(isFlat, classificationsModel[i].code, api);
                    }
                }
                if (codelistCodes.indexOf('93') !== -1) {
                    self.syncThemaSubjectTrees = true;
                } else {
                    self.syncThemaAdditionTrees = true;
                    var items = api.children(null, false, {});
                    for (var i = 0; i < items.length; i++) {
                        api.disable($(items[i]), {});
                    }
                }
            } else if (eventName === "beforetoggle") {
                var hasChildren = api.hasChildren(item, true);
                if (!hasChildren) {
                    if (!isFlat) {
                        var arrThema;
                        if (themaSelectionElem === '#themaSubjectSelection') {
                            arrThema = self.themaSubjectTreeDataOrg;
                        } else {
                            arrThema = self.themaAdditionTreeDataOrg;
                        }
                        var searchTerm = api.getId(item);

                        for (var j = 0; j < searchTerm.length; j++) {
                            if (!arrThema) {
                                break;
                            }

                            var searchPartial = searchTerm.substring(0, j + 1);

                            var searchResults = arrThema.filter(function (thema) {
                                return thema.id === searchPartial;
                            });

                            if (searchResults.length === 1) {
                                if (searchResults[0].id === searchTerm) {
                                    api.append(item, {itemData: searchResults[0].branch});
                                    break;
                                } else {
                                    arrThema = searchResults[0].branch;
                                }
                            }
                        }
                    }
                }
            }
        }

        return eventFunction;
    }

})
;
