var FormView = require('./form_view');
var PreviewFormViewTemplate = require('./templates/previewForm');
var ActionHelper = require('lib/action_helper');
var MiscHelper = require('lib/misc_helper');
var MsgHelper = require('lib/msg_helper');
var ConfirmationHelper = require('lib/confirmation_helper');
var ConfirmationInfo = require('views/templates/forms/confirmationInfo');
var FormHelper = require('lib/form_helper');
var LinkHelper = require('lib/link_helper');

var PreviewFormView;

this.logger = log4javascript.getLogger('views.PreviewFormView');

module.exports = PreviewFormView = FormView.extend({

    className: 'preview-form-view',
    tagName: 'div',
    containerMethod: 'html',
    enableEvents: false,
    useNod: false,

    initialize: function (options) {
        FormView.prototype.initialize.apply(this, [options]);
    },

    afterDataLoaded: function () {
//    DownloadHelper.init(this);
    },

    getTemplateFunction: function () {
        return PreviewFormViewTemplate;
    },

    formDefinitionLoaded: function (loadedFormDef) {
        // on classic single form page,both are undefined, in pagecontent style we
        // need to check if its the correct widget
        if (loadedFormDef.get('wid') !== this.id) {
            return;
        }

        ActionHelper.prepareHeaderActions(this.options.formDef);

        FormView.prototype.formDefinitionLoaded.apply(this, [loadedFormDef]);

        Chaplin.mediator.subscribe('afterDataLoaded', this.afterDataLoaded, this);

        this.delegate('click', '[data-toggle="confirmationSingle"]', this.confirmSingleAction);
        this.delegate('click', '[data-toggle="targetURL"]', this.actionTargetURL);
        this.delegate('click', '[data-toggle="methodCall"]', this.methodCall);
        // TODO (HGF) Find a better place for registering that click handler. Within this preview form ist the wrong
        // place because the link belongs to the outer widget group. But I did not find out a way where to registe that
        // handler there.
        // ensure that only one click is attached because there are at least two preview forms available.
        $('[data-toggle="showAllTitles"]').off('click', this.showAllTitles);
        $('[data-toggle="showAllTitles"]').on('click', this.showAllTitles);
    },

    showAllTitles: function (event) {
        event.preventDefault();
        Chaplin.mediator.publish('quickSearchPublishPoint');
        Chaplin.mediator.publish("redirectTo", {
            url: 'search_quicksearch',
            data: {search: '', action: 'showAllTitles'}
        });
    },

    methodCall: function (event) {
        this.checkISBNandGoOn(event, function (event, options, product) {
            var action = ActionHelper.findActionById(options.formDef,
                event.currentTarget.attributes["action"].value);
            var method = event.currentTarget.attributes["data-modelUrlRoot"].value;
            if (method === 'transformation') {
                var productIDs = [product.id];
                var o = {};
                o.product = [];
                //product = {};
                //product.id = productIDs;
                o.product.push(product);
                var sendData = JSON.stringify(o);
                var sendDataType = 'json';
                var localURL = apiRoot + 'export/transformation/onix21';
                localURL = localURL + '?access_token=' + user.access_token + '&filename=&columnHeading=false&publisherData=false&delimiter=;&textRecognition=%22';
                ConfirmationHelper.doTheConfirmation(sendData, sendDataType, localURL, 'POST', action, undefined);
            }
        });
    },

    actionTargetURL: function (event) {
        this.checkISBNandGoOn(event, function (event, options, product) {
            var action = ActionHelper.findActionById(options.formDef,
                event.currentTarget.attributes["action"].value);
            var modelUrlRoot = event.currentTarget.attributes["data-modelUrlRoot"].value;
            Chaplin.mediator.publish('redirectTo', {url: modelUrlRoot, data: product});
        });
    },

    confirmSingleAction: function (event) {
        this.checkISBNandGoOn(event, undefined);
    },

    checkISBNandGoOn: function (event, callback) {
        $('#isbnAdminInput + span').remove();
        var isbn = this.options.model.attributes["isbnAdminInput"];
        if (isbn === undefined || isbn.length === 0) {
            // user has entered nothing for an isbn and pushed the button.
            $('.control-group.isbnAdminInput').removeClass('valid');
            $('.control-group.isbnAdminInput').addClass('error');
            var msg = MsgHelper.formatMessage('label.widget.viewProductAdministrationEdit.missingISBN');
            $('<span class=\"help-inline nod_msg\">' + msg + '</span>').insertAfter('#isbnAdminInput');
            return;
        }

        isbn = MiscHelper
            .normalizeBatches(this.options.model.attributes["isbnAdminInput"]);
        var options = this.options;
        var that = this;

        jQuery.ajax({
            dataType: 'json',
            url: apiRoot + "product/" + isbn + "/validateAndConvert",
            type: 'post',
            "success": function (data) {
                if (data[isbn].responseType === 'OK') {
                    that.checkISBNandGoOn2(data[isbn].id, event, callback, options);
                } else {
                    $('.control-group.isbnAdminInput').removeClass('valid');
                    $('.control-group.isbnAdminInput').addClass('error');
                    var msg = MsgHelper.formatMessage('label.widget.viewProductAdministrationEdit.ISBNnotValid');
                    $('<span class=\"help-inline nod_msg\">' + msg + '</span>').insertAfter('#isbnAdminInput');
                }
            },
            "error": function (data) {
                FormHelper.jsonResponseError(data);
            },
            "beforeSend": function () {
            },
            "complete": function () {
            },
            "headers": {
                'Authorization': "Bearer " + user.access_token
            }
        });
    },

    checkISBNandGoOn2: function (isbn, event, callback, options) {
        var that = this;
        jQuery.ajax({
            dataType: 'json',
            url: apiRoot + "products/batch?batch=" + isbn + '&search=' + LinkHelper.encodeURI(MiscHelper.getAllDbQueryString()),
            type: 'post',
            "success": function (data) {
                // remove message if one exists.
                $('#isbnAdminInput + span').remove();
                $('.control-group.isbnAdminInput').removeClass('error');
                $('.control-group.isbnAdminInput').removeClass('valid');
                if (data.content.length === 1) {
                    that.checkAccessAuthorisationAndGoOn(data.content[0], event, callback, options);
                } else {
                    $('.control-group.isbnAdminInput').addClass('error');
                    var msg = "";
                    if (data.content.length == 0) {
                        msg = MsgHelper.formatMessage("label.pageContent.viewProductAdministrationEdit.foundNothing");
                    } else {
                        msg = MsgHelper.formatMessage("label.pageContent.viewProductAdministrationEdit.foundToMuch");
                    }
                    $('<span class=\"help-inline nod_msg\">' + msg + '</span>').insertAfter('#isbnAdminInput');
                }
            },
            "error": function (data) {
                FormHelper.jsonResponseError(data);
            },
            "beforeSend": function () {
            },
            "complete": function () {
            },
            "headers": {
                'Authorization': "Bearer " + user.access_token
            }
        });

    },

    checkAccessAuthorisationAndGoOn: function (product, event, callback, options) {

        var url = apiRoot + 'products/possibleProductActions?access_token=' + user.access_token + '&productIds=' + product.id;
        jQuery.ajax({
            contentType: 'application/json',
            url: url,
            type: 'GET',
            "success": function (data) {
                var action = ActionHelper.findActionById(options.formDef, event.currentTarget.attributes["action"].value);
                if (data[0].publisherForMvbId || action['method'] === 'transformation') {
                    if ((action['method'] === 'archive' || action['method'] === 'confirm') && !data[0].archive) {
                        $('.control-group.isbnAdminInput').addClass('error');
                        var msg = MsgHelper.formatMessage('label.widget.viewProductAdministrationEdit.' + action['method'] + '.alreadyArchived');
                        $('<span class=\"help-inline nod_msg\">' + msg + '</span>').insertAfter('#isbnAdminInput');
                    } else {
                        //$('.control-group.isbnAdminInput').removeClass('error');
                        $('.control-group.isbnAdminInput').addClass('valid');
                        if (callback === undefined) {
                            var successCallback = function (data) {
                                if (data.responseType === 'OK') {
                                    var action = ActionHelper.findActionById(options.formDef,
                                        event.currentTarget.attributes["action"].value);
                                    var msg = MsgHelper.formatMessage("label.pageContent." + action.id + ".success.simple");
                                    $('<span class=\"help-inline nod_msg\" style=\"color: #14aa96;\">'
                                    + msg + '</span>').insertAfter('#isbnAdminInput');
                                }
                            };
                            ConfirmationHelper.confirmSingleActionProduct(event, options, product, successCallback);
                        } else {
                            callback(event, options, product);
                        }
                    }
                } else {
                    var scopeData = {};
                    scopeData.ident = event.currentTarget.attributes["action"].value;
                    var prop = 'msg.form.confirmationInfo.text.' + scopeData.ident;
                    var msg = MsgHelper.getMessage(prop);
                    scopeData.text = new Handlebars.SafeString(msg);
                    var html = ConfirmationInfo(scopeData);
                    var confirmDialog = function (event) {
                        $.fancybox.close();
                        var div = $('#' + scopeData.ident + 'Confirmation');
                        div.fadeOut('slow');
                        div.remove();
                    }
                    ConfirmationHelper.openDialog(html, scopeData.ident, confirmDialog);
                }
            }
        });
    },

    confirmAction: function (event) {
        var action = ActionHelper.findActionById(this.options.formDef,
            event.currentTarget.attributes["action"].value);
    }

})
;
