var MiscHelper = require('lib/misc_helper');
var BaseWidget = require('./composite_multibase');
var FormHelper = require('lib/form_helper');
var WidgetTemplate = require('views/templates/forms/widgets/composite_list');
var Model = require('models/nested_model');
var ActionHelper = require('lib/action_helper');
var MsgHelper = require('lib/msg_helper');
// radio widgets

var CompositeWidget = BaseWidget.extend({
    template: WidgetTemplate,

    initialize: function (widget, modelKey, parentProperty) {
        BaseWidget.prototype.initialize.call(this, widget, modelKey, parentProperty);
    },

    render: function (model, data, parentProperty, parentIndex) {
        var listIndexArray = this.getListIndexArray(model, parentProperty, parentIndex);
        var isRemovable = this['isRemovable'] && this.isEditable && (!this['minNr'] || ((listIndexArray.length) > this['minNr']));
        var isAddable = this['isAddable'] && this.isEditable;
        if (model.get('type') === 'journal' && this.specialBehaviour === 'pricesJournal'
            || model.get('type') === 'duoBundle' && this.specialBehaviour === 'duoBundles') {
            var prices;
            if(this.specialBehaviour === 'duoBundles') {
                prices = model.get('duoBundles');
            } else {
                prices = model.get('pricesJournal');
            }
            var currencyCountry = [];
            for (var i = 0; i < prices.length; i++) {
                var price = prices[i];
                if (currencyCountry.indexOf(price.currencyCountry) === -1) {
                    currencyCountry.push(price.currencyCountry);
                }
            }
            isAddable = isAddable && (!this['maxNr'] || (currencyCountry.length < this['maxNr']));
        } else {
            isAddable = isAddable && (!this['maxNr'] || (listIndexArray.length < this['maxNr']));
        }
        var hideMoreThan = this["hideMoreThan"] && listIndexArray.length > this['hideMoreThan'] ? this["hideMoreThan"] : null;
        var styleClass = this["styleClass"];
        var styleClassWrapper = this["styleClassWrapper"];
        var readOnly = [];

        if (this.specialBehaviour === "hierarchyMaster" || this.specialBehaviour === "seriesMaster") {
            isRemovable = true;
        }
        if (this.specialBehaviour === "toggleThemaQualifierLabel") {
            // really dirty hack - but no idea how to get a title for multiple list widgets that only gets showed if one of them is not empty
            // with other techniques that are quickly and clean
            var classifications = model.get('classifications');
            var hasQualifiers = false;
            if (classifications) {
                for (var i = 0; i <= classifications.length; i++) {
                    if (classifications[i] != null && ['94', '95', '96', '97', '98', '99'].indexOf(classifications[i].type) > -1) {
                        hasQualifiers = true;
                        break;
                    }
                }
            }
            setTimeout(function () {
                $('.themaClassificationLabel').toggle(hasQualifiers);
            }, 100);
        }

        var renderedRow = [];
        var foundContent = false;
        // if a separator is been used we need that offset to separate between different ranges in an array.
        var separatorOffset = 0;
        var lastElementInSeparation = false;
        var separatorRemoveLabel = null;
        var separatorCurrencyLabel = null;
        var previousSeparatorValue = null;
        var nextSeparatorValue = null;
        for (var i = 0; i < listIndexArray.length; i++) {
            var listIndex = listIndexArray[i];
            lastElementInSeparation = false;
            for (var j = 0; j < this.column.length; j++) {
                var rendered = "";
                for (var k = 0; k < this.column[j].widget.length; k++) {
                    var w = this.column[j].widget[k];

                    if (w.type === 'ListCompositeWidget' || w.type === 'TableCompositeWidget') {
                        rendered += w.render(model, data, this.property, listIndex, separatorOffset);
                    } else {
                        var buildId = this.buildId(w, listIndex, parentProperty, parentIndex);
                        var buildName = this.buildName(w, listIndex, parentProperty, parentIndex);
                        var data = {id: buildId};
                        var value = model.get(buildName);
                        if (!MiscHelper.isEmpty(value) && w.type !== 'HiddenWidget') {
                            foundContent = true;
                        }
                        if (((this.id === 'duoBundles' || this.id === 'pricesJournal' || this.id === 'pricesJournalBundle') && w.property === 'currencyCountry')
                            || (this.id === 'pricesJournalBundle'
                            && (w.property === 'unitPrice' || w.property === 'unitPriceAsString' || w.property === 'validUntil'))) {
                            if (this.separator !== undefined) {
                                if (previousSeparatorValue !== value) {
                                    separatorOffset = i;
                                    previousSeparatorValue = value;
                                }
                                if (i < listIndexArray.length - 1) {
                                    var nextValue = model.get(this.buildName(w, listIndex + 1, parentProperty, parentIndex));
                                    if (value !== nextValue) {
                                        lastElementInSeparation = true;
                                    }
                                }
                            }
                            if (i - separatorOffset > 0) {
                                w.readonly = true;
                            } else {
                                if (this.id === 'duoBundles' || this.id === 'pricesJournalBundle') {
                                    w.readonly = true;
                                } else {
                                    w.readonly = false;
                                }
                            }
                            rendered += w.render(model, data, this['property'], listIndex);
                        } else {
                            if (this.id === 'duoBundles' && !MiscHelper.isEmpty(w.msgKey) && w.msgKey.indexOf('form.product.block.priceDuoBundle.sum') > -1) {
                                w.msgKey = 'form.product.block.priceDuoBundle.sum_' + previousSeparatorValue;
                            }
                            rendered += w.render(model, data, this['property'], listIndex);
                        }
                    }
                }
                for (var k = 0; k < this.column[j].action.length; k++) {
                    rendered += ActionHelper.buildAction(this.column[j].action[k], this.modelKey, undefined, undefined, model.get('id'), model);
                }
                if (parentIndex !== undefined) {
                    listIndex += "_" + parentIndex;
                }
                if (['duoBundles', 'multiBundles', 'pricesJournal', 'pricesJournalBundle'].indexOf(this.property) !== -1) {
                    if (i === 0) {
                        isRemovable = false;
                    } else {
                        if (this.property === 'pricesJournalBundle' && model.get('bundleswitch') === 'duoTax') {
                            isRemovable = false;
                        } else {
                            isRemovable = this.isEditable;
                        }
                    }
                }

                // set the remove label according to the separator offset/index
                if (['duoBundles', 'pricesJournal', 'pricesJournalBundle'].indexOf(this.property) !== -1) {
                    if (i - separatorOffset === 0) {
                        separatorRemoveLabel = MsgHelper.getMessageOrDefault('label.' + this.addDesc.replace(/add$/g, 'removeSeparator'), MsgHelper.getMessageOrDefault('label.' + this.property + '.removeSeparator', ''));

                    } else {
                        separatorRemoveLabel = "";
                    }
                }

                if (['duoBundles'].indexOf(this.property) !== -1) {
                    if (i - separatorOffset === 0) {
                        var buildName = this.buildName(w, listIndex, parentProperty, parentIndex);
                        var value = model.get(buildName);
                        var currency = MsgHelper.getMessage('label.form.collection.currencyCountryDuoBundle.' + value);
                        separatorCurrencyLabel = MsgHelper.formatMessage('label.form.product.block.priceDuoBundle.legend', [currency]);
                    }
                }

                renderedRow.push({
                    lastElementInSeparation: lastElementInSeparation,
                    separatorIndex: i - separatorOffset,
                    separatorRemoveLabel: separatorRemoveLabel,
                    separatorCurrencyLabel: separatorCurrencyLabel,
                    isRemovable: isRemovable,
                    listIndex: listIndex,
                    rendered: rendered,
                    rowIndex: j,
                    lastGroupedRow: (j > 0 && this.column.length - 1 == j)
                });
            }
        }

        if (!foundContent && !this.isEditable) {
            renderedRow = []
        }

        // check if we need to hide elements
        if (this.showOn !== undefined) {
            if (MiscHelper.isEmpty(styleClass)) {
                styleClass = "";
            } else {
                styleClass = styleClass.replace(/hidden/g, '').trim();
            }
            if (!MiscHelper.compareComplex(model.attributes, this.showOn)) {
                styleClass = styleClass + ' hidden';
            }
        }

        var scopeData = jQuery.extend(true, {}, this, {
            removeLabel: MsgHelper.getMessageOrDefault('label.' + this.addDesc.replace(/add$/g, 'remove'), MsgHelper.getMessageOrDefault('label.' + this.property + '.remove', '')),
            isAddable: isAddable,
            renderedRow: renderedRow,
            hideMoreThan: hideMoreThan,
            parentIndex: parentIndex,
            hasParentIndex: parentIndex !== undefined,
            styleClass: styleClass,
            styleClassWrapper: styleClassWrapper
        });

        return this.template(scopeData, {data: arguments.data});
    }
});


module.exports = CompositeWidget;
