var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div class=\"add-icons\">\n    <a href=\"javascript:;\" onclick=\"return false;\" class=\""
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "-close myfancybox-close\" title=\"Close\">CLOSE</a>\n</div>\n\n<h2><span>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.bisacDialog.headline", (depth0 != null ? depth0.id : depth0), {"name":"label2","hash":{},"data":data})))
    + "</span></h2>\n\n\n<div class=\"row bisacDialog\">\n    <div class=\"col-sm-6\">\n        <div class=\"tab-content\">\n            <div id=\"bisacSubject\" class=\"tab-pane fade in active\">\n                <ul class=\"nav nav-tabs\" id=\"bisacSubjectTab\">\n                    <li class=\"active\"><a href=\"#bisacSubjectHierarchy\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "bisacDialog.tab.bisac.hierarchy", {"name":"lbl","hash":{},"data":data})))
    + "</a></li>\n                    <li><a href=\"#bisacSubjectSearch\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "bisacDialog.tab.bisac.search", {"name":"lbl","hash":{},"data":data})))
    + "</a></li>\n                </ul>\n                <div class=\"tab-content\">\n                    <div id=\"bisacSubjectHierarchy\" class=\"tab-pane fade in active\">\n                        <div id=\"bisacSubjecttree\" class=\"aciTree\"></div>\n                    </div>\n                    <div id=\"bisacSubjectSearch\" class=\"tab-pane fade\">\n                        <input id=\"bisacSubjectTreeSearch\" class=\"treesearch\" type=\"text\" value=\"\">\n                        <div id=\"bisacSubjecttreeflat\" class=\"aciTree\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div id=\"bisacDialogChoosenClasses\" class=\"col-sm-6\">\n        <h2>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "bisacDialog.tab.bisac.selection", {"name":"lbl","hash":{},"data":data})))
    + "</h2>\n        <h3>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "bisacDialog.tab.bisac.subject", {"name":"lbl","hash":{},"data":data})))
    + "</h3>\n        <ul id=\"bisacSubjectSelection\" class=\"list-group\">\n        </ul>\n    </div>\n\n</div>\n\n\n<form class=\"form-horizontal\">\n    <div class=\"form-actions align-right\">\n        <a href=\"javascript:;\" onclick=\"return false;\" class=\"btn green btn-arrow "
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "-close\"><span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "cancel", {"name":"lbl","hash":{},"data":data})))
    + "</span></a>\n        <a href=\"javascript:;\" onclick=\"return false;\" class=\"btn green btn-arrow "
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "-confirm disabled\"><span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "confirm", {"name":"lbl","hash":{},"data":data})))
    + "</span></a>\n    </div>\n</form>\n\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}