//
var routes = require('routes');
var errorHelper = require('./lib/error_helper');
var MsgHelper = require('lib/msg_helper');
//var AjaxSpinner = require('lib/ajaxspinner_helper');

// Application bootstrapper.
var Application = Chaplin.Application.extend({

    start: function () {
        alertify.set({labels: {ok: MsgHelper.getMessage("label.ok"), cancel: MsgHelper.getMessage("label.cancel")}});

        $(document).ajaxError(function (event, jqXHR, ajaxSettings, thrownError) {
            if (jqXHR.ajaxErrorProcessed === undefined || !jqXHR.ajaxErrorProcessed) {
                errorHelper.showGlobalError(ajaxSettings.url, jqXHR.status, jqXHR.responseJSON, thrownError);
            }
            jqXHR.ajaxErrorProcessed === undefined;
        });

        $(document).click(function () {
            var _menu = $('.settings-menu');
            //console.log(_menu);
            _menu.hide();
        });

        this.constructor.__super__.start.call(this);

        var select2locale;
        if(typeof datePickersLocale === 'undefined') {
            select2locale = 'de';
		} else {
            select2locale = datePickersLocale;
		}

        $.extend($.fn.select2.defaults, $.fn.select2.locales[select2locale]);
	}
});

module.exports = Application;
