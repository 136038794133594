var MsgHelper = require('lib/msg_helper');
var View = require('./view');
var OrderbookOrderTemplate = require('./templates/forms/orderbookOrderForm');
var NumberWidget = require('./forms/widgets/number');
var Selection = require('./forms/widgets/selection');
var Model = require('models/model_with_auth');
var OrderItemsOptionsModel = Model.extend({urlRoot : apiRoot+'orderbook/options'});
var OrderItemsModel = Model.extend({urlRoot : apiRoot+'orderbook'});
var OrderbookOrderFromView;

module.exports = OrderbookOrderFromView =  View.extend({

    LOGGER : log4javascript.getLogger('views.OrderbookOrderFromView'),

    className: 'orderbookOrderForm',
    autoRender:true,
    autoAttach:false,

    orderitemOptions: new OrderItemsOptionsModel(),

    notOrderbleProductTypes:[],


initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);

        this.mode = options.mode;

        this.scopeData = options.scopeData;

        // load and wait for userspecific settings
        this.orderitemOptions.fetch({ async:false });

        // set default setting
        this.model = new Backbone.Model({
            productIds:options.productIds,
            orderDestination:this.orderitemOptions.get('defaultOrderDestination'),
            orderCondition:'STANDARD',
            preMark:'STANDARD',
            orderDispatch:this.orderitemOptions.get('defaultOrderDispatch')}
        );

        if(this.mode == "edit"){
            // in edit mode we do not get product ids but orderitem ids
            this.model = new OrderItemsModel({
                id: options.productIds[0]
            });
            this.model.fetch({ async:false });
            this.model.set("productIds", [this.model.get(["productId"])]);
            this.model.unset("createDate");

            if(!this.model.get("orderCondition")){ this.model.set("orderCondition", "STANDARD"); }
            if(!this.model.get("preMark")){ this.model.set("preMark", "STANDARD"); }
            if(!this.model.get("orderDispatch")){ this.model.set("orderDispatch", "LIKE_VENDOR"); }

            var that = this;
            this.model.on('change', function() {
                $(that.el).find('.'+that.scopeData.ident+'-confirm').toggleClass("disabled", that.model.get('orderQuantity') <= 0);
            });
        }

        this.productCheckResults = {};
        this.notOrderbleProductTypes = [];
        //if(options.counterSet >= 1){
        //    this.notOrderbleProductTypes.push("set");
        //}
        //if(options.counterSeries >= 1){
        //    this.notOrderbleProductTypes.push("series");
        //}

        // check products and load metadata
        this.checkProducts();

        // used for binding when attached
        this.modelBinder = new Backbone.ModelBinder();
    },

    successCallback: function(data){
        if(this.mode != 'edit'){
            alertify.success(MsgHelper.getMessage('msg.form.confirmationOrderbookOrder.success'));
        }
    },
    getTemplateData: function () {
        var data = View.prototype.getTemplateData.call(this);

        data.mode = this.mode;
        data.ident = this.scopeData.ident;
        data.headerMessage = this.getHeaderMessage(this.model.get('productIds'));
        data.hintMessage = this.getHintMessage(this.notOrderbleProductTypes);
        data.displayAtSchoolbookMessage = this.displayAtSchoolbookMessage;
        data.productIds = this.model.get('productIds');

        data.text = this.scopeData.text;
        data.textSecondLine = this.scopeData.textSecondLine;
        data.warnings = this.scopeData.warnings;

        data.labelPrefix = 'form.confirmationOrderbookOrder';

        // setting up input fields
        data.options = [
            {property:'orderDestination', collection:this.orderitemOptions.get('orderDestinations')},
            {property:'orderSign'},
            {property:'orderCondition', collection:this.orderitemOptions.get('orderConditions')},
            {property:'preMark', collection:this.orderitemOptions.get('preMarks')},
            {property:'orderDispatch', collection:this.orderitemOptions.get('orderDispatches')}
        ];

        return data;
    },
    getTemplateFunction: function () {
        return OrderbookOrderTemplate;
    },
    render: function () {
        View.prototype.render.call(this);
    },
    attach: function() {
        View.prototype.attach.call(this);

        this.modelBinder.bind(this.model, this.el);//, null, {'changeTriggers': {'*': 'change'}});

        $(this.el).find('select').select2({ sortResults : Selection.prototype.sortResults });
        $(this.el).find('input.number').keydown(NumberWidget.prototype.keydown);
    },
    
    events: {
        'click .mandetoryinfo': 'debug',
        'keyup .orderQuantity': 'orderQuantityChanged',
        'keyup .accountQuantity': 'checkMaxAccountQuantity'
    },

    orderQuantityChanged:function(event) {
        $(this.el).find('.'+this.scopeData.ident+'-confirm').toggleClass("disabled", event.target.value <= 0);
        this.checkMaxAccountQuantity();
    },
    checkMaxAccountQuantity:function() {
        var orderQuantity = $(this.el).find('.orderQuantity').val();
        var accountQuantity = $(this.el).find('.accountQuantity');
        if(orderQuantity == '' || parseInt(accountQuantity.val()) > parseInt(orderQuantity)){
            accountQuantity.val(orderQuantity);
            accountQuantity.change();
        }
    },

    debug: function(event) {
    	console.info(this.model.attributes);
    },

    checkProducts: function() {
        var productIds = this.model.get('productIds');
        if(productIds.length == 0){
            return;
        }
        var productCheckResults = this.productCheckResults;
        var notOrderbleProductTypes = this.notOrderbleProductTypes;
        var model = this.model;
        jQuery.ajax({
            "dataType": 'json',
            url: apiRoot + "orderbook/availability",
            type: "GET",
            "data": {'productIds': productIds.join(",")},
            async:false,
            "success": function (data) {
                var availableProductIds = [];
                for (var i = 0; i < data.length; i++) {
                    var productInfo = data[i];

                    productCheckResults[productInfo.productId] = productInfo;
                    if(productInfo.available){
                        availableProductIds.push(productInfo.productId);
                    } else {
                        var displayProductType = productInfo.productType;
                        if(displayProductType == "ebook"){
                            displayProductType = "digital";
                        }
                        if(!(notOrderbleProductTypes.indexOf(displayProductType) > -1)) {
                            notOrderbleProductTypes.push(displayProductType)
                        }
                    }

                    model.set('productIds', availableProductIds);
                }
            },
            "error": function (data) {
			    console.log("error", data);
            },
            "headers": {
                'Authorization': "Bearer " + user.access_token
            }
        });
    },

    getHeaderMessage: function(arrProductId) {

        // set up headermessage
        if(arrProductId.length == 1) {

            // looking up productdata from datatable - maybe we should fetch them fresh from rest
            // in order to make the dialog independent from the datatable and the dom loaded
            var productData = this.productCheckResults[arrProductId[0]];

            return MsgHelper.getPropertyMessage(
                'msg.form.confirmationOrderbookOrder.text.order.top.single',
                {'{0}':arrProductId.length });
        } else if (arrProductId.length > 1){
            return MsgHelper.getPropertyMessage(
                'msg.form.confirmationOrderbookOrder.text.order.top.multi',{'{0}':arrProductId.length});
        }
    },
    getHintMessage: function(notOrderbleProductTypes) {
        this.displayAtSchoolbookMessage = false;

        if(notOrderbleProductTypes.length == 0){
            return null;
        }

        var typeString = "";

        for (var i = 0; i < notOrderbleProductTypes.length; i++) {
            if(notOrderbleProductTypes[i] == "at-schoolbook"){
                this.displayAtSchoolbookMessage = true;
            }
            if (typeString != "") {
                typeString += " / ";
            }
            typeString += MsgHelper.getMessage("label.form.confirmationOrderbookOrder.hint.producttype."+notOrderbleProductTypes[i]);
        }

        if(typeString != "") {
            return MsgHelper.getPropertyMessage('msg.form.confirmationOrderbookOrder.hint', {'{0}': typeString});
        }
        return null;
    }
});