var Model = require('./nested_model');
var MsgHelper = require('lib/msg_helper');

// join Model, Backbone.NestedModel and our stuff together
var Account = Model.extend(
{
    urlRoot: applicationRoot + "/account",
    modelKey: "account",

    fetch: function (options) {

    	var theOptions = options ? options : {};
        theOptions['async'] = false;
        theOptions['cache'] = false;
        return Model.prototype.fetch.call(this, theOptions);
    },

    initActions: function() {
    	$("#version-btn").click(function(){
    		$.ajax({
    			url: "/adb/account/" + $("#mvbId").val(),
				type: "GET",
				dataType: "json",
				success: function(data){
					$("#version-btnResponse").html(data.partnerName);
				},
				error: function(data) {
					$("#version-btnResponse").html(MsgHelper.getMessage("msg.adb.not.available"));
				}
			});
    	});
    }
});


module.exports = Account;