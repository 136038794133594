var Model = require('models/model');
var MiscHelper = require('lib/misc_helper');

var TableHelper = function() {
};

/**
 * fetches data from a given table - it assumes that each table column contains
 * at least one div with a p tag for the value and probably a hidden id field if
 * the value is actually an object
 * 
 * @param tableId
 *            the table to fetch data for
 */
TableHelper.prototype.fetchTableData = function(tableId) {
	var values = {};
	
	values['rows'] = [];
	$('#' + tableId + ' tbody tr').each(function(index, item) {		
		var row = {};
		row['columns'] = [];
		// get each column for this row
		$(item).children('td.tdValue').each(function(tdIdx, tdItem) {			
			var column = {};
			column['values'] = [];
			$(tdItem).children('div').each(function(divIdx, divItem) {
				var value = {};
				var id = $(divItem).children('input') ? $(divItem).children('input').val() : undefined;
				var val = $(divItem).children('p') ? $(divItem).children('p').text() : undefined;
				
				if (id !== undefined) {
					value.id = id;
				}
				if (val !== undefined) {
					value.val = val;
				}
				column['values'].push(value);
			});			
			row['columns'].push(column);			
		});		
		values['rows'].push(row);
	});
	
	return values;
};

/**
 * attaches the table data for each modal receiver table to the given model
 * 
 * @param model
 *            the model as fetched before submitting the form
 * @param modalReceiver
 *            the receiver markup definition
 */
TableHelper.prototype.attachTableDataToModel = function(model, modalReceiver) {
	delete model.attributes.modal;
	var tableHelper = new TableHelper();
	var tableData = tableHelper.fetchTableData("tbl-" + modalReceiver.modalId);
	
	model.set(modalReceiver.property, []);
	
	for (var row in tableData.rows) {		
		var map = {};
		for (var col in tableData.rows[row].columns) {
			for (var val in tableData.rows[row].columns[col].values) {
				if (modalReceiver.column[col].idWidget){
					MiscHelper.addValueToProp(map, modalReceiver.column[col].idWidget.property, tableData.rows[row].columns[col].values[val].id);
				}	
				MiscHelper.addValueToProp(map, modalReceiver.column[col].valueWidget.property, tableData.rows[row].columns[col].values[val].val);
			}
		}
		model.get(modalReceiver.property)[row] = map;
	}				
	
	return model;
};

module.exports = new TableHelper;