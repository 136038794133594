var View = require('./view');
var MsgHelper = require('lib/msg_helper');
// Templates
var MailTemplate = require('./templates/mail');


var logger = log4javascript.getLogger('views.MailView');

var MailView = View.extend({

    id: 'export-view',
    modalSelector : '#exportModal',    
    containerMethod: 'prepend',
    container : '#body',
    autoRender : false,
    autoAttach : false,    
    
    events: {
        'click .sendCredentialsConfirmed': 'sendCredentialsConfirmed',
    },

    initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);
    },

    getTemplateFunction: function () {
        return MailTemplate;
    },

    show : function()
    {   
    	var tokens = null;
    	// get tokens 
    	$.ajax({
		    contentType: 'application/json',            
		    dataType: 'json',
		    async: false,
		    url: applicationRoot + "/user/tokens/" + this.model.get("user.loginName"),
		    data: {},
		    type: 'GET',
		    "success": function (data) {
		    	tokens = data;
		    }                        
		});		
        /// If the export view container already exists, remove it
        if ($(this.container).data('mailView.initialized') === true)
        {
            $(this.modalSelector).remove();            
        }
                
        this.init();
        
        var mainUserCreationAllowed = false;
        if ($('#mayCreateUsers')) {
        	mainUserCreationAllowed = $('#mayCreateUsers').val(); 
        } else if (!$('#allowedMappings')) {
        	mainUserCreationAllowed = true;
        }

        $(this.modalSelector).modal('show');
        
        // get correct texts
        var body = "";
        if (mainUserCreationAllowed) {
        	body = MsgHelper.getMessage('label.email.user.credentials.body');
        } else {
        	body = MsgHelper.getMessage('label.email.subuser.credentials.body');
        }
        var username = this.model.get("user.loginName");
        var academicTitle = this.model.get("academicTitle");        
        var lastname = this.model.get("lastname");
        var firstname = this.model.get("firstname");
        var addressation;
        var addressationBr;

        addressation = MsgHelper.getMessage('label.email.user.credentials.neutral');

        if (academicTitle !== undefined && academicTitle !== "") {
        	addressation += " " + MsgHelper.getMessage('label.email.user.credentials.title.' + academicTitle); 
        }
        
        // #16537 brazil doesn't use last names. Therefore we use the firstname here 
        addressationBr = addressation + " " + firstname + MsgHelper.getMessage('label.email.user.credential.ending');
        addressation += " " + firstname + " " + lastname;
        
        /* determine rights */
        var rights = "";
        if (this.model.get("user.groupMapping") !== undefined && this.model.get("user.groupMapping").length > 0) {
        	var hasRegularRight = false;
        	var hasArchivedRight = false;
        	var ftpRights = "<ul>";
        	var publisherRights = "";        	        	
        	
        	for (var i in this.model.get("user.groupMapping")) {
        		var gpm = this.model.get("user.groupMapping")[i];
        		if (gpm.group.name === "Buchhändler" && !hasRegularRight) {
        			rights += "<li>" + MsgHelper.getMessage('label.email.credentials.rights.regular') + "</li>";
        			hasRegularRight = true;
        		}
        		if (gpm.account !== null && gpm.account.subscribedToArchivedProducts === true && !hasArchivedRight) {
        			rights += "<li>" + MsgHelper.getMessage('label.email.credentials.rights.archived') + "</li>";
        			hasArchivedRight = true;
        		}        		        		
        		
        		if (gpm.group.name === "Publisher" && gpm.account !== null) {
        			// managed accounts
        			var shortName = "";
                	for (var j in this.model.get("managedAccounts")) {
                		var account = this.model.get("managedAccounts")[j];
                		if (account.mvbId === gpm.account.mvbId) {
                			shortName = account.shortName;
                			break;
                		}                		        		
                	}
        			if (publisherRights === "") {
        				publisherRights += shortName + " (" + gpm.account.mvbId + ")";
        			} else {
        				publisherRights += ", " + shortName + " (" + gpm.account.mvbId + ")";
        			}
        		}
        		
        		// get ftp rights        		
        		for (var j in gpm.group.roles) {
        			if (gpm.group.roles[j].name.indexOf("FOLDER_") === 0) {
        				var right = gpm.group.roles[j].name.substring(7);
        				if (ftpRights.indexOf("<li>" + right + "</li>") >= 0) {
        					continue;
        				} else {
        					ftpRights += "<li>" + right + "</li>";
        				}
        			}
        		}        		
        	}        	
        	ftpRights += "</ul>";
        	
        	if (publisherRights !== "") {
        		rights += "<li>" + MsgHelper.getMessage('label.email.credentials.rights.publisher.account') + " " + publisherRights + "</li>";
        	} 
        	        	       	        
        	if (this.model.get("ftpAccount") === true) {
        		if (ftpRights === "<ul></ul>") {
        			rights += "<li>" + MsgHelper.getMessage('label.email.credentials.rights.ftp.upload.only') + "</li>";
        		} else {
        			rights += "<li>" + MsgHelper.getMessage('label.email.credentials.rights.ftp') + "</li>" + ftpRights;
        		}
        	}
        	if (tokens !== null && tokens.length > 0) {
        		var api = "<li>" + MsgHelper.getMessage('label.email.credentials.rights.api') + "</li>";
        		var foundTokens = false;
        		        		
    			api += "<ul>";
    			api += "<li>" + MsgHelper.getMessage('label.email.credentials.rights.api.tokens') +"</li>";
        		for (var i in tokens) {
        			// only send those who don't expire
        			if (tokens[i].expirationDate === null) {
        				foundTokens = true;
        				api += "<li>" + tokens[i].clientId + ": " + tokens[i].tokenValue + "</li>";
        			}
        		}
        		api += "</ul>";        		
        		
        		if (foundTokens) {
        			rights += api;
        		}
        	}        	
        }
        
        body = body.replace('[ADDRESSATION]', addressation);
        body = body.replace('[ADDRESSATION_BR]', addressationBr);        
        body = body.replace('[USERNAME]', username);
        body = body.replace('[RIGHTS]', "<ul>" + rights + "</ul>");
        
        $('#mail-subject').val(MsgHelper.getMessage('label.email.user.credentials.subject'));        
        $('#mail-body').append(body);               
        $('#mail-to').text(this.model.get('user.email'));
              
        // if the user may only create subusers he may not edit text 
        if (!mainUserCreationAllowed) {
        	body = body.replace('[USER]', user.username);
        	$("#mail-subject").prop("readonly", true);
        	$("#mail-body").prop("contenteditable", false);
        }

        return this;
    },

    init : function()
    {
        this.render();
        this.attach();

        $(this.container).data('mailView.initialized', true);
    },
    
    sendCredentialsConfirmed: function(event) {
    	var mainUserCreationAllowed = false;
        if ($('#mayCreateUsers')) {
        	mainUserCreationAllowed = $('#mayCreateUsers').val(); 
        } else if (!$('#allowedMappings')) {
        	mainUserCreationAllowed = true;
        }
    	var from = "";
    	
    	if (mainUserCreationAllowed) {
    		from = MsgHelper.getMessage('label.email.user.credentials.from');
    	} else {
    		from = MsgHelper.getMessage('label.email.subuser.credentials.from');
    	}
    	
		$.ajax({
		    contentType: 'application/json',            
		    dataType: 'json',
		    url: applicationRoot + "/user/resend/" + this.model.get('id'),
		    data: {
		    	from: from,
		    	cc: $('#mail-cc').val(),
		    	bcc: $('#mail-bcc').val(),
		    	subject: $('#mail-subject').val(),
		    	body: $('#mail-body').html(),
		    	footer: MsgHelper.getMessage('label.email.mvb.footer')
		    },
		    type: 'GET',
		    "success": function (data) {
		    	window.location.reload();
		    }                        
		});			
		return false;
    }
});	


module.exports = MailView;