var Model = require('./model_with_auth');

// join Model, Backbone.NestedModel and our stuff together
var PageContent = Model.extend(
{
    initialize: function (options) {
		if (_.string.startsWith(options.urlRoot, "$api/")) {
			this.urlRoot = options.urlRoot.replace("$api/", apiRoot);
		} else {
			this.urlRoot = applicationRoot + "/" + options.urlRoot;
		}
        
        this.modelKey = options.modelKey;
    },
    
    fetch: function (options) {

    	var theOptions = options ? options : {};
        theOptions['async'] = false;
        theOptions['cache'] = false;
        return Model.prototype.fetch.call(this, theOptions);
    }
});

module.exports = PageContent;