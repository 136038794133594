var Model = require('./model_with_auth');

var InvoiceRecord = Model.extend({
    urlRoot: apiRoot + "reporting/product",

    fetch: function (options) {
        var theOptions = {};
        theOptions['cache'] = true;
        theOptions['async'] = false;
        return Model.prototype.fetch.call(this, theOptions);
    }

    });

module.exports = InvoiceRecord;