var Application = require('application');
var View = require('./view');
var ListView = require('./list_view');
var PreviewListViewTemplate = require('./templates/previewListview');
var MsgHelper = require('lib/msg_helper');
var MiscHelper = require('lib/misc_helper');
var OnixCodelist = require('models/codelist');
var FormDefinition = require('models/formDefinition');
var FormHelper = require('lib/form_helper');
var RenderHelper = require('lib/renderer_helper');
var DownloadHelper = require('lib/download_helper');

var ListView;
var codelists;

this.logger = log4javascript.getLogger('views.PreviewListView');

module.exports = PreviewListView = ListView.extend({

	className : 'preview-list-view',
	tagName : 'div',
	containerMethod : 'html',
	enableRowSelection : false,
    enableHead : false,
    enableScrollX: "", //used for Datatable scrollX, its docu says boolean, but theier code checks for === "" as true

	initialize : function(options) {
		ListView.prototype.initialize.apply(this, [options]);
	},

	getTemplateFunction : function() {
		return PreviewListViewTemplate;
	},

	getDatatableSDom : function() {
		return "t";
	},

    hasLengthChanging: function() {
        return false;
    }
	


});

