var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<fieldset class=\"textareabatchsearch\">\n\n    <div class=\"form-group\">\n        <span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "widget.contributors.text", {"name":"lbl","hash":{},"data":data})))
    + "</span>\n    </div>\n\n    <div class=\"form-group\">\n        <button type=\"button\" class=\"btn btn-primary\" id=\"contributorsexport\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "widget.contributors.download", {"name":"lbl","hash":{},"data":data})))
    + "</button>\n    </div>\n\n    <div class=\"form-group\">\n        <span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "widget.contributors.importText", {"name":"lbl","hash":{},"data":data})))
    + "</span>\n    </div>\n\n    <div class=\"form-group\">\n        <span id=\"contributors_import\" class=\"btn btn-primary fileinput-button report-upload-button\">\n            <span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "widget.contributors.upload", {"name":"lbl","hash":{},"data":data})))
    + "</span>\n            <input id=\"contributors\" type=\"file\" name=\"file\">\n        </span>\n    </div>\n\n</fieldset>";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}