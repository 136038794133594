var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                                        <a id=\"showAllTitles\" class=\"show-all\"\n                                           href=\"javascript:;\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.showAllTitles : stack1)) != null ? stack1.label : stack1), {"name":"lbl","hash":{},"data":data})))
    + "</a>\n";
},"3":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                                        <a class=\"advanced-search\" href=\"#search\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "nav.advancedSearch", {"name":"lbl","hash":{},"data":data})))
    + "</a>\n";
},"5":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "                                            <a data-show-menu-title=\"true\" href=\"javascript:;\"\n                                               class=\"vlb-action-icon vlb-action-icon-input-settings-dd vlb-action-icon-input-settings-dd-white\"\n                                               title="
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "nav.main.gearwheel", {"name":"lbl","hash":{},"data":data})))
    + "></a>\n                                            <ul style=\"display: none;\" class=\"settings-menu\">\n";
  stack1 = this.invokePartial(partials.sidebar, '                                                ', 'sidebar', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                                            </ul>\n";
},"7":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers.each.call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.entry : stack1), {"name":"each","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "                                                <a ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.id : depth0), {"name":"if","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + " href=\""
    + escapeExpression(((helpers.url || (depth0 && depth0.url) || helperMissing).call(depth0, (depth0 != null ? depth0.href : depth0), {"name":"url","hash":{},"data":data})))
    + "\"\n                                                   class=\"vlb-action-icon vlb-action-icon-input-settings-no-arrow-dd vlb-action-icon-input-settings-no-arrow-dd-white\"\n                                                   title=\""
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, (depth0 != null ? depth0.label : depth0), {"name":"lbl","hash":{},"data":data})))
    + "\"></a>\n";
},"9":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "id=\""
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\"";
},"11":function(depth0,helpers,partials,data) {
  return "        <div class=\"layout-page-head-inner header-nav container-fluid\">\n            <div id=\"nav-main-container\">\n\n            </div>\n            <!-- #navbar-container -->\n        </div>\n";
  },"13":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "  <script type=\"text/javascript\">\n    var vlbsuggest = new _LT(\n        'globalQuickSearch',\n        'suggest_results',\n        {\n          host: \""
    + escapeExpression(((helper = (helper = helpers.suggestServer || (depth0 != null ? depth0.suggestServer : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"suggestServer","hash":{},"data":data}) : helper)))
    + "\",\n          url: \"suggest/legacy/\",\n          appendParams: 'access_token="
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.access_token : stack1), depth0))
    + "',\n          solr: true,\n          submitFormOnSubmit: false,\n          submitOnReturn: true,\n          minQueryLength: 3,\n          jsonCallback: 'vlbsuggest._results',\n          postprocess: 'vlbPostprocess',\n          extraFields: [\n            \"Autor\"\n          ]\n        }\n    );\n\n    LTFilter.vlbPostprocess = function () {\n      // modify deeplinks to work with application root\n      for (var i = 0; i < this.suggests.length; i++) {\n        var sug = this.suggests[i]['list'];\n        for (var j = 0; j < sug.length; j++) {\n          sug[j].deeplink = applicationRoot + sug[j].deeplink;\n        }\n      }\n      return this;\n    }\n\n  </script>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "<div id=\"toTop\" class=\"layout-page home\" name=\"toTop\">\n\n    <div id=\"header\" class=\"layout-page-head navbar-fixed-top\">\n\n        <div class=\"layout-page-head-inner layout-page-head-top container-fluid\">\n            <div class=\"header\">\n                <div class=\"row\">\n                    <div class=\"col-xs-3\">\n                        <a class=\"logo active\" href=\"#\" target=\"_self\"><span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "topnav.brand", {"name":"lbl","hash":{},"data":data})))
    + "</span></a>\n                    </div>\n                    <div class=\"col-xs-6\">\n                        <div class=\"quick-search\">\n                            <form class=\"\">\n                                <div class=\"form-group\">\n                                    <input type=\"text\" id=\"globalQuickSearch\">\n                                    <div id=\"suggest_results\" class=\"suggestions\" style=\"display: none;\"></div>\n                                    <button class=\"btn btn-search\" type=\"submit\" id=\"startGlobalQuickSearch\"></button>\n                                </div>\n                                <div class=\"form-group form-links\">\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.showAllTitles : stack1), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.hasRight || (depth0 && depth0.hasRight) || helperMissing).call(depth0, "ADVANCED_SEARCH", {"name":"hasRight","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "                                </div>\n                            </form>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-3\">\n                        <div class=\"header-meta-nav\">\n                            <ul class=\"icon-nav-list\">\n                                <li>\n                                    <div class=\"settings-menu-wrap\">\n";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.singleEntry : stack1), false, {"name":"compare","hash":{
    'operator': ("===")
  },"fn":this.program(5, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.singleEntry : stack1), true, {"name":"compare","hash":{
    'operator': ("===")
  },"fn":this.program(7, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "                                    </div>\n\n                                </li>\n                                <li><a class=\"link-help\" href=\""
    + escapeExpression(((helpers.msg || (depth0 && depth0.msg) || helperMissing).call(depth0, "help.link.search.homePage", {"name":"msg","hash":{},"data":data})))
    + "\"\n                                       target=\"_blank\"><span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "nav.help", {"name":"lbl","hash":{},"data":data})))
    + "</span></a></li>\n                                <li><a class=\"link-logout\" href=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.logoutUrl : stack1), depth0))
    + "\"\n                                       onclick=\"sessionStorage.clear();\"><span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "nav.logout", {"name":"lbl","hash":{},"data":data})))
    + "</span></a></li>\n                            </ul>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n";
  stack1 = ((helpers.hasRight || (depth0 && depth0.hasRight) || helperMissing).call(depth0, "!BOOKSTORE_BASIC", {"name":"hasRight","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </div>\n\n    <div style=\"display:none\">\n        <div id=\"sidebar-menue\" class=\"lb-default\">\n            <div class=\"add-icons\">\n                <a href=\"javascript:;\" onclick=\"return false;\" class=\"myfancybox-close\" title=\"Close\">CLOSE</a>\n            </div>\n            <div id=\"sidebar-container\" class=\"well sidebar-nav\">\n            </div>\n        </div>\n    </div>\n\n    <div id=\"ajax-loader\">\n        <div class=\"loader-content\">\n            <div class=\"loader-progress\">Lädt...</div>\n            <div class=\"loader-info\"></div>\n        </div>\n    </div>\n\n    <!-- append fancy box on the fly to this div -->\n    <div id=\"fancyboxes-div\" style=\"display:none\">\n    </div>\n\n    <!-- append fancy box on the fly to this div -->\n    <div id=\"download-div\" style=\"display:none\">\n    </div>\n\n    <!-- modal dialog used e.g. for exports -->\n    <div class=\"modal fade\" id=\"modal\" tabindex=\"-1\" role=\"dialog\" aria-hidden=\"true\">\n        <div class=\"modal-dialog\">\n            <div class=\"modal-content\">\n                <div class=\"modal-header\"></div>\n                <div class=\"modal-body\"></div>\n                <div class=\"modal-footer\"></div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"layout-page-body container-fluid\">\n        <div class=\"layout-page-body-inner\" id=\"content-container\">\n        </div>\n        <div class=\"row\">\n            <div class=\"col-xs-12\">\n                <div class=\"to-top-wrap\">\n                    <a class=\"to-top-link anchor-link\" href=\"#toTop\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "backToTop", {"name":"lbl","hash":{},"data":data})))
    + "</a>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class=\"layout-page-foot container-fluid\">\n        <div class=\"layout-page-foot-inner\" id=\"footer-container\">\n\n        </div>\n        <!-- footer-container -->\n    </div>\n\n</div>\n<!-- Suggestbox -->\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.suggestAllowed : depth0), {"name":"if","hash":{},"fn":this.program(13, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}