var ConfirmationHelper = require('lib/confirmation_helper');
var MsgHelper = require('../lib/msg_helper');
var View = require('./view')
var ContributorsTemplate = require('./templates/forms/contributors');
var AjaxSpinner = require('lib/ajaxspinner_helper');

var Contributors;

module.exports = Contributors = View.extend({

    container: '#contributors',
    autoRender: true,
    autoAttach: false,
    className: 'contributorsView',


    initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);
    },

    getTemplateFunction: function () {
        return ContributorsTemplate;
    },

    events: {
        'click #contributorsexport': 'exportContributor'
    },

    getTemplateData: function () {
        var data = View.prototype.getTemplateData.call(this);
        return data;
    },

    render: function () {
        View.prototype.render.call(this);
    },

    attach: function () {
        View.prototype.attach.call(this);
        this.initFileupload();
    },

    initFileupload: function () {
    	Chaplin.mediator.subscribe('contributorsFileUploadSuccess', this.uploadSuccess, this);
    	Chaplin.mediator.subscribe('contributorsFileUploadError', this.uploadError, this);

    	$('#contributors').fileupload({
    	    url: apiRoot + 'contributor/import',
    	    headers: {
            	'Authorization' :"Bearer " + user.access_token
            },
            add: function (e, data) {
                AjaxSpinner.show(undefined, MsgHelper.formatMessage('label.form.assetUpload.uploading.message', [data.files[0].name]));
    	        data.submit()
    	            .success(function(result, textStatus, jqXHR) {
                            AjaxSpinner.hide();
	    	            	MsgHelper.clearAlerts();
	    	            	Chaplin.mediator.publish('contributorsFileUploadSuccess', result, textStatus, jqXHR);
    	            	})
    	            .error(function (jqXHR, textStatus, errorThrown) {
                            AjaxSpinner.hide();
	    	            	MsgHelper.clearAlerts();
	    	            	Chaplin.mediator.publish('contributorsFileUploadError', jqXHR, textStatus, errorThrown);
    	            	});
    	    }
    	});

    },

    exportContributor: function () {
        var localUrl = apiRoot + 'contributor/report?access_token=' + user.access_token;
        ConfirmationHelper.downloadReport(localUrl);
        MsgHelper.showSuccessAlert(MsgHelper.getMessage('label.form.contributors.exportInitialized'));
    },

    uploadSuccess: function(result, textStatus, jqXHR) {
        MsgHelper.showSuccessAlert(MsgHelper.getMessage('label.form.contributors.uploadSuccess'));
    },

    uploadError: function(jqXHR, textStatus, errorThrown) {
        MsgHelper.showErrorAlert(MsgHelper.getMessage('label.form.contributors.uploadFailed'));
    }
});
