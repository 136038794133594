var MiscHelper = require('lib/misc_helper');
var BaseWidget = require('./composite_multibase');
var FormHelper = require('lib/form_helper');
var MsgHelper = require('lib/msg_helper');
var PriceHelper = require('lib/price_helper');
var WidgetTemplate = require('views/templates/forms/widgets/composite_table');
// radio widgets

var foreignPricesVisibleLatam = ["", "MXN_MX", "ARS_AR", "BOB_BO", "CLP_CL", "COP_CO", "CRC_CR", "CUP_CU", "DOP_DO", "EUR_ES", "HNL_HN", "USD_EC",
    "SVC_SV", "GTQ_GT", "NIO_NI", "PAB_PA", "PYG_PY", "PEN_PE", "USD_PR", "USD_US", "UYU_UY", "VEF_VE"];

var CompositeWidget = BaseWidget.extend({
    template: WidgetTemplate,
    initialize: function (widget, modelKey, parentProperty) {
        BaseWidget.prototype.initialize.call(this, widget, modelKey, parentProperty);
        this['columnHeader'] = [];
        this.additionalRows = [];
        for (var j = 0; j < this['column'].length; j++) {
            this['column'][j]['hasDateWidget'] = this.columnHasDateWidget(this['column'][j]);
            if (this['column'][j].newRow !== true) {
                this['columnHeader'][j] = [];
                this['columnHeader'][j]['title'] = this['column'][j].noLabel === true ? '' : this.getColumnTitle(this['column'][j]);
                this['columnHeader'][j]['styleClass'] = this['column'][j].styleClass;
            } else {
                this.additionalRows.push(this['column'][j]);
            }
        }

        this.columns_length = this['column'].length - this.additionalRows.length;
    },

    doWeHaveEvents: function () {
        for (var j = 0; j < this.column.length; j++) {
            for (var k = 0; k < this.column[j].widget.length; k++) {
                var w = this.column[j].widget[k];
                if (w.changeEvent !== undefined && w.changeEvent.length > 0) {
                    return true;
                }
            }
        }
        return false;
    },

    columnHasDateWidget: function (column) {
        for (var k = 0; k < column.widget.length; k++) {
            var w = column.widget[k];
            if (w.type === 'DateWidget') {
                return true;
            }
        }
        return false;
    },

    getColumnTitle: function (column) {
        var name = null;
        if (!MiscHelper.isEmpty(column['property'])) {
            name = column['property'];
        } else if (column.widget != null && column.widget.length > 0) {
            if (column.widget[0].label !== undefined) {
                return MsgHelper.getMessage("label." + column.widget[0].label);
            }
            name = column.widget[0]['property'];
        }
        return MsgHelper.getMessage("label.form." + this.modelKey + ".field." + name);
    },

    render: function (model, data, parentProperty, parentIndex, separatorOffset) {
        if (this.hideWhenEmpty && MiscHelper.isEmpty(model.get(this.property))) {
            return "";
        }
        var listIndexArray = this.getListIndexArray(model, parentProperty, parentIndex);
        var listIndex = [];
        var renderRemoveColumnHeader = false;
        var minimumQuantity = null;
        var previousPrice = null;
        var even = true;
        for (var i = 0; i < listIndexArray.length; i++) {

            var isRemovable = this.isEditable && (!this['minNr'] || ((listIndexArray.length) > this['minNr']));
            var forceFieldReadonly = null;
            var rowCss = "";
            var readOnly = [];

            if (["retailPrices", "specialPrices", "foreignPrices", "journalPrices"].indexOf(this.property) !== -1) {
                renderRemoveColumnHeader = true; // always render for pricetables to avoid new table width calculation for addition column
                var obj = model.get(this.property)[listIndexArray[i]];

                if ("retailPrices" === this.property && MiscHelper.isEmpty(obj.validFrom)) {
                    isRemovable = false;
                } else {
                    isRemovable = true;
                }
                if ("specialPrices" === this.property && !MiscHelper.isEmpty(obj.minimumQuantity) && (minimumQuantity === obj.minimumQuantity)) {
                    forceFieldReadonly = "minimumQuantity";
                }
                minimumQuantity = obj.minimumQuantity;

                var priceReferenceInfo = model.get('priceReference');
                var isPriceReference = false;
                for (id in priceReferenceInfo) {
                    if (id === obj.id) {
                        isPriceReference = priceReferenceInfo[id];
                        break;
                    }
                }
                if (isPriceReference === true) {
                    rowCss += ' priceReference';
                }

                if (previousPrice === null) {
                    previousPrice = obj.currencyCountry;
                } else if (previousPrice !== obj.currencyCountry) {
                    previousPrice = obj.currencyCountry;
                    even = !even;
                }

                if (even) {
                    rowCss += ' even';
                } else {
                    rowCss += ' odd';
                }
                if (versionCountry === 'latam' && "foreignPrices" === this.property && obj.currencyCountry !== null && !foreignPricesVisibleLatam.includes(obj.currencyCountry)) {
                    rowCss += ' hidden';
                }

            } else {
                var separatorIndex = parentIndex - separatorOffset;
                if (this.property === 'bundleParts' && parentIndex > 0) {
                    readOnly = ['productIdentifierType', 'gtin', 'type', 'taxRate'];
                } else if (this.name === 'pricesJournal.priceParts' && separatorIndex > 0) {
                    readOnly = ['taxRate'];
                } else if (this.name === 'pricesJournalBundle.priceParts' && separatorIndex > 0) {
                    readOnly = ['taxRate', 'productIdentifierType', 'identifier', 'productTypeCode'];
                }
            }
            listIndex.push({
                "lindex": listIndexArray[i],
                "isRemovable": isRemovable,
                "forceFieldReadonly": forceFieldReadonly,
                "rowCss": rowCss,
                "readOnly": readOnly
            });
        }


        var isAddable = this.isEditable && (this['maxNr'] === undefined || ((listIndexArray.length) < this['maxNr']));
        //we can add no more than 27 current entries for foreignPrices
        if (this['property'] === "foreignPrices" && isAddable === true) {
            isAddable = PriceHelper.countCurrentforeignPrices(model) < 27;
        }
        var scopeData;
        if (this.property === 'bundleParts' && parentIndex > 0) {
            isAddable = false;
        }
        if (parentProperty === undefined) {
            scopeData = jQuery.extend(true, {}, this, {
                listIndex: listIndex,
                isAddable: isAddable,
                renderRemoveColumnHeader: renderRemoveColumnHeader,
                parentId: ''
            });
        } else {
            //var id = parentProperty + '_' + parentIndex;
            scopeData = jQuery.extend(true, {}, this, {
                listIndex: listIndex,
                isAddable: isAddable,
                renderRemoveColumnHeader: renderRemoveColumnHeader,
                parentId: parentProperty + '_' + parentIndex
            });
        }

        return this.template(scopeData, {data: arguments.data});
    }
});


module.exports = CompositeWidget;
