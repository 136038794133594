var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <br>"
    + escapeExpression(((helpers.safeString || (depth0 && depth0.safeString) || helperMissing).call(depth0, (depth0 != null ? depth0.textSecondLine : depth0), {"name":"safeString","hash":{},"data":data})))
    + "\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.warnings : depth0), {"name":"each","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                "
    + escapeExpression(((helpers.safeString || (depth0 && depth0.safeString) || helperMissing).call(depth0, (depth0 != null ? depth0.text : depth0), {"name":"safeString","hash":{},"data":data})))
    + "<br>\n";
},"6":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "            <span class=\"message\">\n                <span class=\"message-label\">"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, (depth0 != null ? depth0.labelPrefix : depth0), "hint", {"name":"label2","hash":{},"data":data})))
    + "</span>\n                "
    + escapeExpression(((helper = (helper = helpers.hintMessage || (depth0 != null ? depth0.hintMessage : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"hintMessage","hash":{},"data":data}) : helper)))
    + "\n            </span>\n";
},"8":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <span class=\"message\">\n                <span class=\"message-label\">"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, (depth0 != null ? depth0.labelPrefix : depth0), "hint", {"name":"label2","hash":{},"data":data})))
    + "</span>\n                "
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, (depth0 != null ? depth0.labelPrefix : depth0), "hint.at-schoolbook", {"name":"label2","hash":{},"data":data})))
    + "\n            </span>\n";
},"10":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "        <form class=\"form-horizontal\">\n            <div>\n                <div class=\"form-group\">\n                    <div class=\"row\">\n                        <div class=\"col-xs-3\"><label>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, (depth0 != null ? depth0.labelPrefix : depth0), "quantity", {"name":"label2","hash":{},"data":data})))
    + "</label></div>\n                        <div class=\"col-xs-9\">\n\n                            <input type=\"text\" id=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-orderQuantity\" name=\"orderQuantity\"\n                                   class=\"number orderQuantity\" pattern=\"\\d*\" maxlength=\"4\">\n                            <label for=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-orderQuantity\">"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, (depth0 != null ? depth0.labelPrefix : depth0), "orderQuantity", {"name":"label2","hash":{},"data":data})))
    + "</label>\n                            <br>\n                            <input type=\"text\" id=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-accountQuantity\" name=\"accountQuantity\"\n                                   class=\"number accountQuantity\" pattern=\"\\d*\" maxlength=\"4\" >\n                            <label for=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-accountQuantity\">"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, (depth0 != null ? depth0.labelPrefix : depth0), "accountQuantity", {"name":"label2","hash":{},"data":data})))
    + "</label>\n                        </div>\n                    </div>\n\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.options : depth0), {"name":"each","hash":{},"fn":this.program(11, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                </div>\n                <span class=\"mandetoryinfo message\">"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, (depth0 != null ? depth0.labelPrefix : depth0), "mandetoryfieldsinfo", {"name":"label2","hash":{},"data":data})))
    + "</span>\n            </div>\n            <div class=\"form-actions align-right\">\n                <a href=\"javascript:;\" onclick=\"return false;\"\n                   class=\"btn green btn-arrow "
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-close\"><span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "cancel", {"name":"lbl","hash":{},"data":data})))
    + "</span></a>\n                <a href=\"javascript:;\" onclick=\"return false;\"\n                   class=\"disabled btn green btn-arrow "
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-confirm\"><span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "ok", {"name":"lbl","hash":{},"data":data})))
    + "</span></a>\n            </div>\n        </form>\n";
},"11":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "                        <div class=\"row\">\n                            <div class=\"col-xs-3\"><label>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, (depths[1] != null ? depths[1].labelPrefix : depths[1]), (depth0 != null ? depth0.property : depth0), {"name":"label2","hash":{},"data":data})))
    + "</label></div>\n                            <div class=\"col-xs-9\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.collection : depth0), {"name":"if","hash":{},"fn":this.program(12, data, depths),"inverse":this.program(15, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                            </div>\n                        </div>\n";
},"12":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "                                    <select id=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-"
    + escapeExpression(((helper = (helper = helpers.property || (depth0 != null ? depth0.property : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"property","hash":{},"data":data}) : helper)))
    + "\" name=\""
    + escapeExpression(((helper = (helper = helpers.property || (depth0 != null ? depth0.property : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"property","hash":{},"data":data}) : helper)))
    + "\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.collection : depth0), {"name":"each","hash":{},"fn":this.program(13, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                                    </select>\n";
},"13":function(depth0,helpers,partials,data,depths) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;
  return "                                            <option value=\""
    + escapeExpression(lambda(depth0, depth0))
    + "\">"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, (depths[1] != null ? depths[1].property : depths[1]), depth0, {"name":"label2","hash":{},"data":data})))
    + "</option>\n";
},"15":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                                    <div class=\""
    + escapeExpression(((helper = (helper = helpers.property || (depth0 != null ? depth0.property : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"property","hash":{},"data":data}) : helper)))
    + "\">\n                                        <input type=\"text\" maxlength=\"10\" id=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-"
    + escapeExpression(((helper = (helper = helpers.property || (depth0 != null ? depth0.property : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"property","hash":{},"data":data}) : helper)))
    + "\"\n                                               name=\""
    + escapeExpression(((helper = (helper = helpers.property || (depth0 != null ? depth0.property : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"property","hash":{},"data":data}) : helper)))
    + "\">\n                                        <div class=\"errorText hint\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.orderbook.orderSign", {"name":"lbl","hash":{},"data":data})))
    + "</div>\n                                    </div>\n";
},"17":function(depth0,helpers,partials,data) {
  var helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";
  return "\n        <p>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, (depth0 != null ? depth0.labelPrefix : depth0), "text.order.noOrdableProductsSelected", {"name":"label2","hash":{},"data":data})))
    + "</p>\n\n        <div class=\"form-actions align-right\">\n            <a href=\"javascript:;\" onclick=\"return false;\"\n               class=\"btn green btn-arrow "
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-close\"><span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "cancel", {"name":"lbl","hash":{},"data":data})))
    + "</span></a>\n        </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div id=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "\" class=\"lb-default orderbookOrderForm\">\n    <div class=\"add-icons\">\n        <a href=\"javascript:;\" onclick=\"return false;\" class=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-close myfancybox-close\" title=\"Close\">CLOSE</a>\n    </div>\n\n    <h2><span>"
    + escapeExpression(((helpers.label3 || (depth0 && depth0.label3) || helperMissing).call(depth0, (depth0 != null ? depth0.labelPrefix : depth0), "headline", (depth0 != null ? depth0.mode : depth0), {"name":"label3","hash":{},"data":data})))
    + "</span></h2>\n\n    <p>\n        "
    + escapeExpression(((helpers.safeString || (depth0 && depth0.safeString) || helperMissing).call(depth0, (depth0 != null ? depth0.text : depth0), {"name":"safeString","hash":{},"data":data})))
    + "\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.textSecondLine : depth0), {"name":"if","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </p>\n\n    <p>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.warnings : depth0), {"name":"if","hash":{},"fn":this.program(3, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.hintMessage : depth0), {"name":"if","hash":{},"fn":this.program(6, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.displayAtSchoolbookMessage : depth0), {"name":"if","hash":{},"fn":this.program(8, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </p>\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.productIds : depth0), {"name":"if","hash":{},"fn":this.program(10, data, depths),"inverse":this.program(17, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n</div>\n";
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}