var MsgHelper = require('lib/msg_helper');
var View = require('./view')
var SummaryTemplate = require('./templates/forms/product_summary');
var FormHelper = require('lib/form_helper');
var MiscHelper = require('lib/misc_helper');
var DateHelper = require('lib/date_helper');

var CoverView;

module.exports = CoverView = View.extend({

    LOGGER: log4javascript.getLogger('views.ProductSummaryView'),

    container: '#fieldSet_product_summary',
    autoRender: true,
    autoAttach: false,
    className: 'productSummary',
    codelists: {},


    initialize: function (options) {
        View.prototype.initialize.apply(this, [options]);
        this.codelists["priceTypes"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '58');
        this.codelists["taxRates"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '62');
        this.codelists["availabilities"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '65');
        this.codelists["contributorTypes"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '17');
        this.codelists["frequency"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '400');
        this.codelists["journalPriceTypes"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, 'journalPriceTypes');
        this.codelists["languages"] =
            FormHelper.getCollection(options.parentView.options.formDef.attributes.collections, '74');
    },

    ab: function (text) {
        // append br if not empty else empty string
        return text ? text + '<br/>' : '';
    },
    aw: function (text) {
        // append whitespace if not empty else empty string
        return text ? text + ' ' : '';
    },

    getTemplateData: function () {
        var data = View.prototype.getTemplateData.call(this);

        /*
         * routines for mb basic
         */
        data.showProductBasic = user.authorities.indexOf('PUBLISHER_BASIC') >= 0
            || user.authorities.indexOf('BOOKSTORE_BASIC') >= 0
            || data.model.basic;
        data.showProductBasicStar = user.authorities.indexOf('PUBLISHER_BASIC') < 0 && data.model.basic;
        for (var i=0; i<data.model.identifiers.length; i++) {
            if (data.model.identifiers[i].type === '03') {
                data.gtin13 = data.model.identifiers[i].value;
            }
        }
        if (data.model.languages && data.model.languages.length > 0) {
            data.language = FormHelper.getCodelistLabel(this.codelists["languages"], data.model.languages[0].value);
        }
        /*******************/

        data.labelPrefix = 'form.product.block.productHeader';
        data.renderSourceHint = ['5109037', '5001015', '70601'].indexOf(data.model.aggregatorId) > -1; // for Umbreit, ÖSB, SBZ

        // check product state
        if (data.model.active === false) {
            data.productStatusText = MsgHelper.getMessage('label.form.product.block.productHeader.archived');
        }
        if (!MiscHelper.isEmpty(data.model.announcementDate)) {
            var announcementDate = DateHelper.parseToDateObject(data.model.announcementDate, 'dd.MM.yyyy', formatDateDayMonthYear);
            if (announcementDate > new Date()) {
                if (data.productStatusText) {
                    data.productStatusText += '<p/>' + MsgHelper.getMessage('label.form.product.block.productHeader.futureAnnouncementDate');
                } else {
                    data.productStatusText = MsgHelper.getMessage('label.form.product.block.productHeader.futureAnnouncementDate');
                }
            }
        }
        if (!MiscHelper.isEmpty(data.model.publicationDate)) {
            data.publicationDate = DateHelper.convert(data.model.publicationDate);
            var publicationDate
            if (data.model.publicationDate.length === 8) {
                publicationDate = DateHelper.parseToDateObject(data.model.publicationDate);
            } else if (data.model.publicationDate.length === 6) {
                publicationDate = DateHelper.parseToDateObject(data.model.publicationDate + '01', 'yyyyMMdd');
            } else if (data.model.publicationDate.length === 4) {
                publicationDate = DateHelper.parseToDateObject(data.model.publicationDate + '0101', 'yyyyMMdd');
            } else {
                publicationDate = new Date();
            }

            if (publicationDate > new Date()) {
                if (data.productStatusText) {
                    data.productStatusText += '<p/>' + MsgHelper.getMessage('label.form.product.block.productHeader.futurePublicationDate');
                } else {
                    data.productStatusText = MsgHelper.getMessage('label.form.product.block.productHeader.futurePublicationDate');
                }
            }
        }
        if (data.model.type === 'multiBundle' && data.model.publisherForMvbId === true) {
            if (data.productStatusText) {
                data.productStatusText += '<p/>' + MsgHelper.getMessage('label.form.product.block.productHeader..multiBundleProduct');
            } else {
                data.productStatusText = MsgHelper.getMessage('label.form.product.block.productHeader..multiBundleProduct');
            }
        }

        // format first 3 contributors
        var arrAuthors = [];
        var arrOthers = [];
        if (data.model.contributors !== undefined) {
            for (var i = 0; i < data.model.contributors.length; i++) {
                if (data.model.contributors[i].type == 'A01') {
                    arrAuthors.push(data.model.contributors[i]);
                } else {
                    arrOthers.push(data.model.contributors[i]);
                }
            }
            arrAuthors.sort(function (a, b) {
                return a.sequence - b.sequence;
            });
            var authorArray = [];
            for (var i = 0; i < arrAuthors.length && authorArray.length < 3; i++) {
                if (versionCountry === 'brasil' || versionCountry === 'latam') {
                    var typeTranslation = FormHelper.getCodelistLabel(this.codelists["contributorTypes"], arrAuthors[i].type);
                    authorArray.push(arrAuthors[i].fullName + ' (' + typeTranslation + ')');
                } else {
                    authorArray.push(arrAuthors[i].fullName);
                }
            }
            if (arrAuthors.length < 3) {
                arrOthers.sort(function (a, b) {
                    return a.sequence - b.sequence;
                });
                for (var i = 0; i < arrOthers.length && authorArray.length < 3; i++) {
                    if (versionCountry === 'brasil' || versionCountry === 'latam') {
                        var typeTranslation = FormHelper.getCodelistLabel(this.codelists["contributorTypes"], arrOthers[i].type);
                        authorArray.push(arrOthers[i].fullName + ' (' + typeTranslation + ')');
                    } else {
                        authorArray.push(arrOthers[i].fullName);
                    }
                }
            }
            data.contributorString = authorArray.join(" / ");
        }

        data.invoiceStatus = MsgHelper.formatMessage('label.' + data.labelPrefix + '.' + this.model.get('invoiceStatus'), [MiscHelper.annualInvoiceYear()]);
        data.titleShortVersion = this.model.get('titleShortVersion');
        data.subtitleShortVersion = this.model.get('subtitleShortVersion');

        // publisherData
        if (data.model.publisherData) {
            if (data.model.publisherData.postalAddress) {
                if (data.model.publisherData.postalAddress.street1 != null
                    || data.model.publisherData.postalAddress.street2 != null
                    || data.model.publisherData.postalAddress.zipStreet != null) {
                    var address = this.ab(data.model.publisherData.postalAddress.street1);
                    address += this.ab(data.model.publisherData.postalAddress.street2);
                    address += this.aw(data.model.publisherData.postalAddress.country);
                    address += this.aw(data.model.publisherData.postalAddress.zipStreet);
                    address += this.aw(data.model.publisherData.postalAddress.cityStreet);
                    data.postalAddress = address;
                } else {
                    var address = this.aw(MsgHelper.getMessage('label.publisher.postalBox'));
                    address += this.ab(data.model.publisherData.postalAddress.postalBox);
                    address += this.aw(data.model.publisherData.postalAddress.country);
                    address += this.aw(data.model.publisherData.postalAddress.zipPostalBox);
                    if (data.model.publisherData.postalAddress.cityPostalBox != null) {
                        address += this.aw(data.model.publisherData.postalAddress.cityPostalBox);
                    } else {
                        address += this.aw(data.model.publisherData.postalAddress.cityStreet);
                    }
                    data.postalAddress = address;
                }
            }
            if (data.model.publisherData.contacts) {
                var teltel = '', tel = '', fax = '', email = '', url = '', contactData = '';
                for (var i = 0; i < data.model.publisherData.contacts.length; i++) {
                    var type = data.model.publisherData.contacts[i].type;
                    var label = MsgHelper.getMessage('label.publisher.contact.type.' + type);
                    var value = data.model.publisherData.contacts[i].value;
                    if (type == 'TELEPHONE') {
                        if (data.model.publisherData.contacts[i].purpose == 'TELEPHONE' && !teltel) {
                            teltel = label + ' ' + value;
                        } else if (!tel) {
                            tel = label + ' ' + value;
                        }
                    }
                    if (type == 'FAX' && !fax) {
                        fax = label + ' ' + value;
                    }
                    if (type == 'EMAIL' && !email) {
                        email = label + ' <a href="mailto:' + value + '">' + value + '</a>';
                    }
                    if (type == 'URL' && !url) {
                        // If the URL already starts with http or https no http should be added to the url.
                        url = label + ' <a target="_blank" href="' + ((value.indexOf('http://') === 0 || value.indexOf('https://') === 0) ? '' : 'http://') + value + '">' + value + '</a>';
                    }
                }
                contactData += this.ab(teltel ? teltel : tel);
                contactData += this.ab(fax);
                contactData += this.ab(email);
                contactData += this.ab(url);
                data.contactData = contactData;
            }

            if (data.model.publisherData.mvbId) {
                data.mvbId = '<strong> ' + MsgHelper.getMessage('label.form.product.field.mvbId') + '</strong> ' + data.model.publisherData.mvbId;
            }

            data.deliveries = [];
            if (data.model.publisherData) {
                if (data.model.publisherData.sigelDE)
                    data.deliveries.push(data.model.publisherData.sigelDE.replace(/^\//, ""));
                if (data.model.publisherData.sigelAT)
                    data.deliveries.push(data.model.publisherData.sigelAT.replace(/^\//, ""));
                if (data.model.publisherData.sigelCH)
                    data.deliveries.push(data.model.publisherData.sigelCH.replace(/^\//, ""));
            }
            data.deliveries = data.deliveries.join(" ");
            if (data.deliveries) {
                data.deliveries = '<strong> ' + MsgHelper.getMessage('label.publisher.deliverer') + '</strong> ' + data.deliveries;
                data.deliveryAddendum = data.model.publisherData.deliveryAddendum;
            } else {
                if (MiscHelper.isEmpty(data.model.publisherData.deliveryAddendum)) {
                    data.deliveryAddendum = '';
                } else {
                    data.deliveryAddendum = '<strong> ' + MsgHelper.getMessage('label.publisher.deliverer') + '</strong> ' + data.model.publisherData.deliveryAddendum;
                }
            }

            if (data.model.publisherData.deliveryNote) {
                data.deliveryNote = '';
                if (data.model.publisherData.deliveryNote.indexOf("e") > -1 || data.model.publisherData.deliveryNote.indexOf("E") > -1) {
                    data.deliveryNote += this.ab(MsgHelper.getMessage('label.publisher.deliveryNoteE'));
                }
                if (data.model.publisherData.deliveryNote.indexOf("r") > -1 || data.model.publisherData.deliveryNote.indexOf("R") > -1) {
                    data.deliveryNote += this.ab(MsgHelper.getMessage('label.publisher.deliveryNoteR'));
                }
                if (data.model.publisherData.deliveryNote.indexOf("n") > -1 || data.model.publisherData.deliveryNote.indexOf("N") > -1) {
                    data.deliveryNote += this.ab(MsgHelper.getMessage('label.publisher.deliveryNoteN'));
                }
                if (data.deliveryNote) {
                    data.deliveryNote = '<strong>' + this.ab(MsgHelper.getMessage('label.publisher.deliveryNote')) + '</strong>' + data.deliveryNote;
                }
            }

            // publisher name
            data.publisherShortName = data.model.publisherData.shortNameVLB;
            if (versionCountry === 'latam') {
                // for mexico the name of the imprint holder is relevant
                if(data.model.publisherData.imprintHolder && data.model.publisherData.companyName) {
                    data.publisherShortName = data.model.publisherData.companyName + ' - ' + data.publisherShortName;
                }
            }
        }

        // format retail & journal prices
        data.retailPrices = [];
        data.journalPrices = [];
        if (data.model.type === 'duoBundle' || data.model.type === 'multiBundle') {
            if (data.model.bundles == null) {
                console.error('invalid bundle has no prices. ProductId is->' + data.model.id);
            } else {
                data.calculatedHint = false;
                var bundles = data.model.bundles;
                for (var i = 0; i < bundles.length; i++) {
                    var bundle = bundles[i];
                    if (bundle.validFrom === null && bundle.sum !== null) {
                        var sum = bundle.sum.toFixed(2);
                        if (decimalSeparator != '.') {
                            sum.replace(/\./, decimalSeparator);
                        }
                        data.retailPrices.push(
                            '<span class="priceValue">' + sum + '</span>' +
                            '<span class="priceCurrency">' + bundle.currency + (bundle.currency == 'EUR' ? ' (' + bundle.country + ')' : '') + '</span>' +
                            //', ' + FormHelper.getCodelistLabel(this.codelists["taxRates"], bundle.taxRate) +
                            ', ' + FormHelper.getCodelistLabel(this.codelists["priceTypes"], bundle.type) +
                            (this.isPriceReference(data.model.priceReference, data.model.bundleMasterPrices[i]) ? '<i title="Preis ist offizieller VLB-Referenzpreis" class="vlb-icon vlb-icon-reference-price"><span>Referenz Preis</span></i>' : '')
                        );
                    }
                }
            }
        }
        //else
        {
            data.calculatedHint = false;
            data.zeroPercentHint = false;

            // retail prices
            var prices = data.model.retailPrices;
            if (prices) {
                for (var i = 0; i < prices.length; i++) {
                    if (prices[i].calculated === true) {
                        data.calculatedHint = true;
                    }
                    if (prices[i].value != null && prices[i].validFrom == null) {
                        var price = prices[i].value.toFixed(2)
                        if (decimalSeparator != '.') {
                            price = price.replace(/\./, decimalSeparator)
                        }
                        if (prices[i].provisional === true) {
                            price = 'ca. ' + price;
                        }
                        var priceLine = '';
                        priceLine = '<span class="priceValue">' + price + '</span>';

                        if (versionCountry === 'brasil') {
                            priceLine = '<span class="priceCurrency">R$</span> ' + priceLine;
                        } else if (versionCountry === 'latam') {
                            priceLine = '<span class="priceCurrency">' + prices[i].currency + '</span> ' + priceLine;
                        } else {
                            priceLine += '<span class="priceCurrency">' + prices[i].currency + (prices[i].currency == 'EUR' ? ' (' + prices[i].country + ')' : '') + '</span>';
                            if (!MiscHelper.isEmpty(prices[i].typeDescription) && prices[i].typeDescription.indexOf('0% MwSt.-Angabe vom Verlag') > -1) {
                                data.zeroPercentHint = true;
                            }
                            priceLine +=
                                (prices[i].calculated ? '<span class="priceCalculated">*</span>' : '') +
                                ', ' + FormHelper.getCodelistLabel(this.codelists["taxRates"], prices[i].taxRate) +
                                (!MiscHelper.isEmpty(prices[i].typeDescription) && prices[i].typeDescription.indexOf('0% MwSt.-Angabe vom Verlag') > -1 ? '<span class="zeroPercentTax"><sup> +</sup></span>' : '') +
                                ', ' + FormHelper.getCodelistLabel(this.codelists["priceTypes"], prices[i].type) +
                                (this.isPriceReference(data.model.priceReference, prices[i]) ? '<i title="Preis ist offizieller VLB-Referenzpreis" class="vlb-icon vlb-icon-reference-price"><span>Referenz Preis</span></i>' : '');
                        }
                        data.retailPrices.push(priceLine);
                    }
                }
            }

            // journal bundle Prices
            var jbPrices = data.model.pricesJournalBundle;
            var prevPriceCountry = "";

            if (jbPrices != null) {
                for (var i = 0; i < jbPrices.length; i++) {
                    var priceCountry = jbPrices[i].country;

                    if (jbPrices[i].grossPriceAmount != null && jbPrices[i].validFrom == null) {
                        var jbPrice = jbPrices[i].grossPriceAmount.toFixed(2)
                        if (decimalSeparator != '.') {
                            jbPrice = jbPrice.replace(/\./, decimalSeparator);
                        }

                        // make sure we have no country more than once
                        if (prevPriceCountry != priceCountry) {

                            // do we have a provisional price?
                            if (jbPrices[i].provisional === true) {
                                jbPrice = 'ca. ' + jbPrice;
                            }

                            // tax rate
                            var taxRate = jbPrices[i].taxRate !== null ? ", " + FormHelper.getCodelistLabel(this.codelists["taxRates"], jbPrices[i].taxRate) : "";

                            // do we have an uvp price?
                            var uvp = jbPrices[i].uvp === true ? ', UVP' : '';

                            // get the priceTypeCode (jährlich/einzeln)
                            var priceType = " " + FormHelper.getCodelistLabel(this.codelists["journalPriceTypes"], jbPrices[i].priceTypeCode);
                            priceType = priceType.toLowerCase();

                            // shipping costs
                            var shipping = "";
                            if (!MiscHelper.isEmpty(jbPrices[i].shippingCostsInland)) {
                                shipping = ", zzgl. " + jbPrices[i].shippingCostsInland.toFixed(2).replace(/\./, ',') + " VK";
                            } else {
                                shipping = ", exkl. VK";
                            }

                            // push information into journalPrices
                            data.journalPrices.push(
                                '<span class="priceValue">' + jbPrice + '</span>' +
                                '<span class="priceCurrency">' + jbPrices[i].currency + (jbPrices[i].currency == 'EUR' ? ' (' + jbPrices[i].country + ')' : '') + '</span>' +
                                priceType + shipping + uvp +
                                (this.isPriceReference(data.model.priceReference, jbPrices[i]) ? '<i title="Preis ist offizieller VLB-Referenzpreis" class="vlb-icon vlb-icon-reference-price"><span>Referenz Preis</span></i>' : '')
                            );
                        }

                        // set the previous price country
                        prevPriceCountry = jbPrices[i].country;
                    }
                }
            }

            // journal Prices
            var jPrices = data.model.journalPrices;
            var prevPriceCountry = "";

            if (jPrices != null) {
                for (var i = 0; i < jPrices.length; i++) {
                    var priceCountry = jPrices[i].country;

                    if (jPrices[i].grossPriceAmount != null && jPrices[i].validFrom == null) {
                        var jPrice = jPrices[i].grossPriceAmount.toFixed(2).replace(/\./, ',');

                        // make sure we have no country more than once
                        if (prevPriceCountry != priceCountry) {

                            // do we have a provisional price?
                            if (jPrices[i].provisional === true) {
                                jPrice = 'ca. ' + jPrice;
                            }

                            // tax rate
                            var taxRate = jPrices[i].taxRate !== null ? ", " + FormHelper.getCodelistLabel(this.codelists["taxRates"], jPrices[i].taxRate) : "";

                            // do we have an uvp price?
                            var uvp = jPrices[i].uvp === true ? ', UVP' : '';

                            // get the priceTypeCode (jährlich/einzeln)
                            var priceType = " " + FormHelper.getCodelistLabel(this.codelists["journalPriceTypes"], jPrices[i].priceTypeCode);
                            priceType = priceType.toLowerCase();

                            // shipping costs
                            var shipping = "";
                            if (!MiscHelper.isEmpty(jPrices[i].shippingCostsInland)) {
                                shipping = ", zzgl. " + jPrices[i].shippingCostsInland.toFixed(2).replace(/\./, ',') + " VK";
                            } else {
                                shipping = ", exkl. VK";
                            }

                            // push information into journalPrices
                            data.journalPrices.push(
                                '<span class="priceValue">' + jPrice + '</span>' +
                                '<span class="priceCurrency">' + jPrices[i].currency + (jPrices[i].currency == 'EUR' ? ' (' + jPrices[i].country + ')' : '') + '</span>' +
                                priceType + shipping + taxRate + uvp +
                                (this.isPriceReference(data.model.priceReference, jPrices[i]) ? '<i title="Preis ist offizieller VLB-Referenzpreis" class="vlb-icon vlb-icon-reference-price"><span>Referenz Preis</span></i>' : '')
                            );
                        }

                        // set the previous price country
                        prevPriceCountry = jPrices[i].country;
                    }
                }
            }
        }
        data.editionString = "";
        if (data.model.edition) {
            if (data.model.edition.number) {
                data.editionString = data.model.edition.number + '. ' + MsgHelper.getMessage('label.form.product.fieldset.edition');
            }
            if (data.model.edition.text) {
                if (data.editionString != "") {
                    data.editionString += ", ";
                }
                data.editionString += data.model.edition.text;
            }
        }

        // availability
        data.availability = FormHelper.getCodelistLabel(this.codelists['availabilities'], data.model.availability);


        // format pages
        data.pagesString = "";
        if (data.model.extent) {
            if (data.model.extent.pages) {
                data.pagesString = MsgHelper.getMessage('abbreviation.circum.ea') + ' ' + data.model.extent.pages;
            } else {
                if (data.model.extent.pagesRoman) {
                    data.pagesString = data.model.extent.pagesRoman
                }
                if (data.model.extent.pagesArabic) {
                    if (data.pagesString != '') {
                        data.pagesString += ' / ';
                    }
                    data.pagesString += data.model.extent.pagesArabic
                }
            }
        }

        var setsAndSeries = FormHelper.getSetAndSeriesFromProduct(data.model);
        data.series = setsAndSeries.series;
        data.hierarchie = setsAndSeries.hierarchie;

        data.editButtonRole = data.model.publisherForMvbId && data.model.type !== 'multiBundle' ? 'MODIFY_PRODUCT' : 'doNotRenderButtonsHack';

        //ZIS
        if (data.model.zisInfo && data.model.type === 'journal') {
            //publisher frequency
            data.publisherFrequency = MsgHelper.getZisFrequencyMessage(FormHelper.getCodelistLabel(this.codelists["frequency"], data.model.zisInfo.frequency), data.model.zisInfo.frequency, data.model.zisInfo.frequencyText);

            // publisher shortname
            if (data.model.publisherData.shortNameZIS !== null) {
                data.publisherShortName = data.model.publisherData.shortNameZIS;
            }

            //issn
            var identifiers = data.model.identifiers;
            for (var i = 0; i < identifiers.length; i++) {
                if (identifiers[i].type === "IS") {
                    data.issn = identifiers[i].value;
                    data.issnlabel = MsgHelper.getMessage('label.form.product.issn');
                    // check if we need another label for zis bundles
                    if (data.model.zisInfo.medium === "WW") {
                        data.issnlabel = MsgHelper.getMessage('label.form.product.issnl');
                    }
                }
            }
        }

        return data;
    },
    getTemplateFunction: function () {
        return SummaryTemplate;
    },
    render: function () {
        View.prototype.render.call(this);
    },
    attach: function () {
        View.prototype.attach.call(this);

        $('fieldset.summaryFieldset #title span').attr('title', this.model.get('title'));
        $('fieldset.summaryFieldset #subtitle span').attr('title', this.model.get('subtitle'));


        var titleAppendix = '';
        // check product state
        if (this.model.get('active') === false) {
            titleAppendix = MsgHelper.getMessage('label.form.product.block.productTitleAppendix.archived');
        }
        if (!MiscHelper.isEmpty(this.model.get('announcementDate'))) {
            var announcementDate = DateHelper.convert(this.model.get('announcementDate'), 'dd.MM.yyyy');
            if (announcementDate > new Date()) {
                if (titleAppendix.length > 0) {
                    titleAppendix += ' / ' + MsgHelper.getMessage('label.form.product.block.productTitleAppendix.futureAnnouncementDate');
                } else {
                    titleAppendix = MsgHelper.getMessage('label.form.product.block.productTitleAppendix.futureAnnouncementDate');
                }
            }
        }
        if (!MiscHelper.isEmpty(this.model.get('publicationDate'))) {
            var publicationDate = this.model.get('publicationDate');
            if (publicationDate.length === 8) {
                publicationDate = DateHelper.convert(this.model.get('publicationDate'), 'yyyyMMdd');
            } else if (publicationDate.length === 6) {
                publicationDate = DateHelper.convert(this.model.get('publicationDate') + '01', 'yyyyMMdd');
            } else if (publicationDate.length === 4) {
                publicationDate = DateHelper.convert(this.model.get('publicationDate') + '0101', 'yyyyMMdd');
            }

            if (publicationDate > new Date()) {
                if (titleAppendix.length > 0) {
                    titleAppendix += ' / ' + MsgHelper.getMessage('label.form.product.block.productTitleAppendix.futurePublicationDate');
                } else {
                    titleAppendix = MsgHelper.getMessage('label.form.product.block.productTitleAppendix.futurePublicationDate');
                }
            }
        }
        if (titleAppendix.length > 0) {
            var titleText = $('.contentpage-header').text();
            titleText += ' - ' + titleAppendix;
            $('.contentpage-header').text(titleText);
        }
    },

    isPriceReference: function (priceReferenceInfo, price) {
        var retVal = false;

        for (id in priceReferenceInfo) {
            if (id === price.id) {
                retVal = priceReferenceInfo[id];
            }
        }

        return retVal;
    }
});