var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"helpbox clearfix\">\n    <div class=\"add-icons\">\n        <a class=\"txt-link txt-link-help\" href=\""
    + escapeExpression(((helpers.msg || (depth0 && depth0.msg) || helperMissing).call(depth0, ((helpers.add || (depth0 && depth0.add) || helperMissing).call(depth0, "help.link.", (depth0 != null ? depth0.modelKey : depth0), {"name":"add","hash":{},"data":data})), {"name":"msg","hash":{},"data":data})))
    + "\" target=\"_blank\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "help", {"name":"lbl","hash":{},"data":data})))
    + "</a>\n        <a href=\""
    + escapeExpression(((helpers.msg || (depth0 && depth0.msg) || helperMissing).call(depth0, ((helpers.add || (depth0 && depth0.add) || helperMissing).call(depth0, "help.link.", (depth0 != null ? depth0.modelKey : depth0), {"name":"add","hash":{},"data":data})), {"name":"msg","hash":{},"data":data})))
    + "\" target=\"_blank\" class=\"vlb-action-icon vlb-action-icon-help\"\n           title=\""
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "help", {"name":"lbl","hash":{},"data":data})))
    + "\"><span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "help", {"name":"lbl","hash":{},"data":data})))
    + "</span></a>\n    </div>\n        <p>";
  stack1 = ((helpers.msg || (depth0 && depth0.msg) || helperMissing).call(depth0, ((helpers.add || (depth0 && depth0.add) || helperMissing).call(depth0, "help.link.description.", (depth0 != null ? depth0.modelKey : depth0), {"name":"add","hash":{},"data":data})), {"name":"msg","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</p>\n</div>";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}