var MiscHelper = require('lib/misc_helper');
var DateHelper = require('lib/date_helper');
var WidgetTemplate = require('views/templates/forms/widget');
var BaseWidget = require('./base');

// Base class for all editable widgets

var EditableWidget = BaseWidget.extend({
    initialize: function (widget, modelKey, parentProperty) {
        BaseWidget.prototype.initialize.call(this, widget, modelKey, parentProperty);
        this.name = this.determineName();
        this.bindingSelector = this.key2Selector(this.name);
        // give an ID to our little newborn baby widget and label it
        this.id = this.sanitizeId(this.name);
        this.hasLabel = !this.noLabel;
        if (this.hasLabel && !this.label) {
            this.label = "form." + modelKey + ".field." + this.property;
        }
        if (this.labelStyleClass === undefined) {
            this.labelStyleClass = 'control-label';
        }
        // This style is used for readOnly specific css hack. For example mot to change mouse to pointer if been
        // placed over a read only widgets label.
        if (this.readonly && this.widget.type === 'TextWidget') {
            if (MiscHelper.isEmpty(this.styleClass)) {
                this.styleClass = "readonlyWidget";
            } else {
                this.styleClass += " readonlyWidget";
            }
        }
        this.hasEvents = this.doWeHaveEvents();
    },

    determineName: function () {
        if (!MiscHelper.isEmpty(this.parentProperty)) {
            return this.parentProperty + '.' + this.widget['property'];
        }
        return this.widget['property'];
    },

    binding: function () {
        if (this.doRender) {
            return [{name: this.name, bindingSelector: this.bindingSelector}];
        } else {
            return [];
        }
    },

    sanitizeId: function (id) {
        return id.replace(/\./g, '_');
    },

    key2Selector: function (key) {
        return '#' + this.sanitizeId(key);
    },

    getOptions: function (formCollections) {

    },

    getOptionsSelectMapping: function () {

    },

    changeDefaultValue: function (model) {
        if (versionCountry == 'latam' && this.id == 'specialPrices_currencyCountry') {
            var retailPrices = model.get('retailPrices');
            if (retailPrices && retailPrices.length > 0) {
                var retailPrice = retailPrices[0];
                var currencyCountry = retailPrices[0].currencyCountry;
                this.defaultValue = currencyCountry;
            }

        }
    },

    initModelValue: function (model) {
        // create the model value if it doesn't exist yet
        // process objects/arrays
        if (this.name.lastIndexOf('.') >= 0) {
            var propName = this.name.substring(0, this.name.lastIndexOf('.'));
            if (!model.has(propName)) {
                model.set(propName, new Object());
                model.get(propName).id = MiscHelper.uuid();
            }
        }
        // process default values
//        if ((widget.readonly || widget.isHidden || (this.model.get(name) === null || this.model.get(name) === undefined)) && widget.defaultValue) {
        if (model.get(this.name) !== null && model.get(this.name) !== undefined) {
            this.hadValueOnLoad = true;

            if (model.get('type') === 'journal' && this.property === 'discountText') {
                if (MiscHelper.isEmpty(model.get('zisInfo.discountValue'))) {
                    this.label = 'form.product.column.discount';
                } else {
                    this.label = 'form.product.column.discount.nolabel';
                }
            }
        }
        if ((model.get(this.name) === null || model.get(this.name) === undefined) && this.defaultValue) {
            if (this.type === 'CheckboxWidget') {
                // Remember that checkboxes usually handles with boolean values and not with string.
                // Retaining the default value as string will be interpreted as true.
                if (this.defaultValue === 'true') {
                    this.defaultValue = true;
                    model.set(this.name, true);
                } else {
                    // the UI has only two states for a checkbox. So it seems to be better to set any checkbox default
                    // value to false even if the attribute defaultValue is undefined.
                    this.defaultValue = false;
                    model.set(this.name, false);
                }
            } else if (this.type === 'RadioWidget') {
                if (this.defaultValue === 'true') {
                    this.defaultValue = true;
                    model.set(this.name, true);
                } else if (this.defaultValue === 'false') {
                    this.defaultValue = false;
                    model.set(this.name, false);
                } else {
                    model.set(this.name, this.defaultValue);
                }
            } else if (this.type === 'HiddenWidget' && this.defaultValue === 'null') {
                this.defaultValue = null;
                model.set(this.name, null);
            } else {
                model.set(this.name, this.defaultValue);
            }
        }

        if (model.get(this.name) === null || model.get(this.name) === undefined) {
            if (this.multiple && this.multipleAsFlat !== true) {
                model.set(this.name, []);
            } else {
                model.set(this.name, '');
            }
        }
    },

    buildId: function (w, index, parentProperty, parentIndex) {
        var id = "";
        if (parentProperty !== undefined) {
            id += parentProperty + "_" + parentIndex + "_";
        }
        if (w.parentProperty === undefined) {
            id += w['property'];
        } else {
            id += w.parentProperty + '_' + w['property'];
        }
        if (index !== undefined) {
            id += '_' + index;
        }
        return this.sanitizeId(id);
    },

    buildName: function (w, index, parentProperty, parentIndex) {
        var id = "";
        if (parentProperty !== undefined) {
            id += parentProperty + "." + parentIndex + ".";
        }
        if (!MiscHelper.isEmpty(w.parentProperty) && MiscHelper.isEmpty(index)) {
            id += w.parentProperty + '.' + w['property'];
        } else if (!MiscHelper.isEmpty(w.parentProperty) && !MiscHelper.isEmpty(index)) {
            id += w.parentProperty + '.' + index + '.' + w['property'];
        } else {
            id = this.name;
        }
        return id;
    },

    prepareForSubmit: function (model) {
        if (this['specialBehaviour'] === "priceRepealArchive") {
            if (model.get(this.property) && !MiscHelper.isEmpty(model.get('priceRepealDateUI'))) {
                // ATTENTION: archived date is also used in the ui for journals with german date format.
                var date = model.get('priceRepealDateUI');
                model.set('archivedDate', DateHelper.convert(date, formatDateDayMonthYear, 'dd.MM.yyyy'));
                model.set('archivedReason', 'Repeal');
            }
        }
    },

    initAfterBinding: function () {

    },

    fillDataId: function (dataIn) {
        var data = dataIn;
        if (!dataIn) {
            data = {};
        }
        if (!data.id) {
            data.id = this.id;
        }
        return data;
    },

    doWeHaveEvents: function () {
        if (this.changeEvent !== undefined && this.changeEvent.length > 0) {
            return true;
        }
        return false;
    },

    events: function (model) {
        var result = {};
        if (this.changeEvent !== undefined) {
            result[this.id] = this.changeEvent;
        }
        return result;
    },

    render: function (model, renderData) {
        if (this.doRender === false) {
            return '';
        }
        var data = this.fillDataId(renderData);
        return WidgetTemplate(this, {data: data});
    }
});

EditableWidget.extend = Backbone.Model.extend;

module.exports = EditableWidget;
