var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <h3 class=\"contentpage-header "
    + escapeExpression(((helper = (helper = helpers.modelKey || (depth0 != null ? depth0.modelKey : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"modelKey","hash":{},"data":data}) : helper)))
    + "-header\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.title : stack1), {"name":"lbl","hash":{},"data":data})))
    + "<span id=\"titleAdditionals\"></span></h3>\n";
},"3":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.mform : stack1)) != null ? stack1.title : stack1), {"name":"if","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.each.call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.mform : stack1)) != null ? stack1.block : stack1), {"name":"each","hash":{},"fn":this.program(6, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <h4>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.mform : stack1)) != null ? stack1.title : stack1), {"name":"lbl","hash":{},"data":data})))
    + "</h4>\n";
},"6":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, (depth0 != null ? depth0.showOnBottom : depth0), false, {"name":"compare","hash":{},"fn":this.program(7, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"7":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compareComplex || (depth0 && depth0.compareComplex) || helperMissing).call(depth0, (depths[2] != null ? depths[2].modelAttributes : depths[2]), (depth0 != null ? depth0.visible : depth0), {"name":"compareComplex","hash":{},"fn":this.program(8, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"8":function(depth0,helpers,partials,data,depths) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "	            <div id=\"block_"
    + escapeExpression(((helper = (helper = helpers.blockId || (depth0 != null ? depth0.blockId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"blockId","hash":{},"data":data}) : helper)))
    + "\" class=\"block block_"
    + escapeExpression(lambda((data && data.index), depth0))
    + " content-type-"
    + escapeExpression(lambda((depths[3] != null ? depths[3].contentType : depths[3]), depth0))
    + "\">\n	            </div>\n";
},"10":function(depth0,helpers,partials,data) {
  var stack1, buffer = "    <table class=\"filter-table\">\n";
  stack1 = helpers.each.call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.filters : stack1)) != null ? stack1.filterGroup : stack1), {"name":"each","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </table>\n";
},"11":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.headerText : depth0), {"name":"if","hash":{},"fn":this.program(12, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "            <tr>                \n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.filter : depth0), {"name":"each","hash":{},"fn":this.program(14, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </tr>\n";
},"12":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                <tr><td class=\"filter-header\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, (depth0 != null ? depth0.headerText : depth0), {"name":"lbl","hash":{},"data":data})))
    + "</td></tr>\n";
},"14":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials.filter, '                    ', 'filter', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"16":function(depth0,helpers,partials,data) {
  var stack1, buffer = "    <br>\n    <div id=\"collapser\" class=\"collapser collapsed\">           \n        <div id=\"extendedFilter\">\n            <table class=\"filter-table\">\n";
  stack1 = helpers.each.call(depth0, ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.filters : stack1)) != null ? stack1.extendedFilter : stack1)) != null ? stack1.filterGroup : stack1), {"name":"each","hash":{},"fn":this.program(17, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </table>\n        </div>\n        <a class=\"collapseButton with-label float-right\" data-ctrl=\"\" href=\"#\"></a>\n    </div>\n";
},"17":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.headerText : depth0), {"name":"if","hash":{},"fn":this.program(18, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "                    <tr>                \n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.filter : depth0), {"name":"each","hash":{},"fn":this.program(20, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                    </tr>\n";
},"18":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                        <tr><td class=\"filter-header\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, (depth0 != null ? depth0.headerText : depth0), {"name":"lbl","hash":{},"data":data})))
    + "</td></tr>\n";
},"20":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials.filter, '                            ', 'filter', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"22":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1), {"name":"if","hash":{},"fn":this.program(23, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"23":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1)) != null ? stack1.FILTER : stack1), {"name":"if","hash":{},"fn":this.program(24, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        \n";
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1)) != null ? stack1.PRIMARY : stack1), {"name":"if","hash":{},"fn":this.program(27, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    \n        <ul class=\"icon-nav-list fl-left\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.enableRowSelection : depth0), {"name":"if","hash":{},"fn":this.program(29, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1)) != null ? stack1.SECONDARY : stack1), {"name":"if","hash":{},"fn":this.program(31, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1)) != null ? stack1.TERTIARY : stack1), {"name":"if","hash":{},"fn":this.program(34, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </ul>\n";
},"24":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "            <div class=\"row \" role=\"toolbar\">\n                <div class=\"col-xs-9\">                    \n";
  stack1 = helpers.each.call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1)) != null ? stack1.FILTER : stack1), {"name":"each","hash":{},"fn":this.program(25, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                </div>\n            </div>\n";
},"25":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                        "
    + escapeExpression(((helpers.action || (depth0 && depth0.action) || helperMissing).call(depth0, depth0, (depths[2] != null ? depths[2].modelKey : depths[2]), (depths[2] != null ? depths[2].modelUrlRoot : depths[2]), ((stack1 = (depths[2] != null ? depths[2].model : depths[2])) != null ? stack1.id : stack1), true, {"name":"action","hash":{},"data":data})))
    + "\n";
},"27":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "            <div class=\"row btn-line btn-line-top\" role=\"toolbar\">\n                <div class=\"col-xs-9\">\n";
  stack1 = helpers.each.call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1)) != null ? stack1.PRIMARY : stack1), {"name":"each","hash":{},"fn":this.program(25, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                </div>\n            </div>\n";
},"29":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                <li class=\"select-all\">\n                    <input type=\"checkbox\" id=\"selectAll\" data-uid=\""
    + escapeExpression(((helper = (helper = helpers.tableId || (depth0 != null ? depth0.tableId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"tableId","hash":{},"data":data}) : helper)))
    + "-resultlist-check-all\">\n                </li>\n";
},"31":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "";
  stack1 = helpers.each.call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1)) != null ? stack1.SECONDARY : stack1), {"name":"each","hash":{},"fn":this.program(32, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"32":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                    <li>\n                        "
    + escapeExpression(((helpers.iconAction || (depth0 && depth0.iconAction) || helperMissing).call(depth0, depth0, (depths[2] != null ? depths[2].modelKey : depths[2]), (depths[2] != null ? depths[2].modelUrlRoot : depths[2]), ((stack1 = (depths[2] != null ? depths[2].model : depths[2])) != null ? stack1.id : stack1), {"name":"iconAction","hash":{},"data":data})))
    + "\n                    </li>\n";
},"34":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "                <li>\n                    <div class=\"settings-menu-wrap\">\n                        <a title=\""
    + escapeExpression(((helpers.msg || (depth0 && depth0.msg) || helperMissing).call(depth0, (depths[2] != null ? depths[2].dealWithNavTitle : depths[2]), {"name":"msg","hash":{},"data":data})))
    + "\" class=\"vlb-action-icon vlb-action-icon-input-settings-dd\"\n                           href=\"javascript:;\"\n                           onclick=\"return false;\" data-show-menu=\"true\">\n                            <span>"
    + escapeExpression(((helpers.msg || (depth0 && depth0.msg) || helperMissing).call(depth0, (depths[2] != null ? depths[2].dealWithNavTitle : depths[2]), {"name":"msg","hash":{},"data":data})))
    + "</span>\n                        </a>\n                        <ul class=\"settings-menu\" style=\"display: none\">\n";
  stack1 = helpers.each.call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1)) != null ? stack1.TERTIARY : stack1), {"name":"each","hash":{},"fn":this.program(35, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                        </ul>\n                    </div>\n                </li>\n";
},"35":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                                "
    + escapeExpression(((helpers.action || (depth0 && depth0.action) || helperMissing).call(depth0, depth0, (depths[2] != null ? depths[2].modelKey : depths[2]), (depths[2] != null ? depths[2].modelUrlRoot : depths[2]), ((stack1 = (depths[2] != null ? depths[2].model : depths[2])) != null ? stack1.id : stack1), false, {"name":"action","hash":{},"data":data})))
    + "\n";
},"37":function(depth0,helpers,partials,data) {
  return "                <th>selectionColumn</th> ";
  },"39":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                <th>"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</th>\n";
},"41":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <p>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.footerText : stack1), {"name":"lbl","hash":{},"data":data})))
    + "</p>\n";
},"43":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "    <ul class=\"icon-nav-list fl-left\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.enableRowSelection : depth0), {"name":"if","hash":{},"fn":this.program(44, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1)) != null ? stack1.SECONDARY : stack1), {"name":"if","hash":{},"fn":this.program(46, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1)) != null ? stack1.TERTIARY : stack1), {"name":"if","hash":{},"fn":this.program(49, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </ul>\n\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1)) != null ? stack1.PRIMARY : stack1), {"name":"if","hash":{},"fn":this.program(52, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"44":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <li class=\"select-all\">\n                <input type=\"checkbox\" id=\"selectAll\" data-uid=\""
    + escapeExpression(((helper = (helper = helpers.tableId || (depth0 != null ? depth0.tableId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"tableId","hash":{},"data":data}) : helper)))
    + "-resultlist-check-all\">\n            </li>\n";
},"46":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "";
  stack1 = helpers.each.call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1)) != null ? stack1.SECONDARY : stack1), {"name":"each","hash":{},"fn":this.program(47, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"47":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                <li>\n                    "
    + escapeExpression(((helpers.iconAction || (depth0 && depth0.iconAction) || helperMissing).call(depth0, depth0, (depths[2] != null ? depths[2].modelKey : depths[2]), (depths[2] != null ? depths[2].modelUrlRoot : depths[2]), ((stack1 = (depths[2] != null ? depths[2].model : depths[2])) != null ? stack1.id : stack1), {"name":"iconAction","hash":{},"data":data})))
    + "\n                </li>\n";
},"49":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "            <li>\n                <div class=\"settings-menu-wrap\">\n                    <a title=\""
    + escapeExpression(((helpers.msg || (depth0 && depth0.msg) || helperMissing).call(depth0, (depths[2] != null ? depths[2].dealWithNavTitle : depths[2]), {"name":"msg","hash":{},"data":data})))
    + "\" class=\"vlb-action-icon vlb-action-icon-input-settings-dd\"\n                       href=\"javascript:;\"\n                       onclick=\"return false;\" data-show-menu=\"true\">\n                        <span>"
    + escapeExpression(((helpers.msg || (depth0 && depth0.msg) || helperMissing).call(depth0, (depths[2] != null ? depths[2].dealWithNavTitle : depths[2]), {"name":"msg","hash":{},"data":data})))
    + "</span>\n                    </a>\n                    <ul class=\"settings-menu\" style=\"display: none\">\n";
  stack1 = helpers.each.call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1)) != null ? stack1.TERTIARY : stack1), {"name":"each","hash":{},"fn":this.program(50, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                    </ul>\n                </div>\n            </li>\n";
},"50":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                            "
    + escapeExpression(((helpers.action || (depth0 && depth0.action) || helperMissing).call(depth0, depth0, (depths[2] != null ? depths[2].modelKey : depths[2]), (depths[2] != null ? depths[2].modelUrlRoot : depths[2]), ((stack1 = (depths[2] != null ? depths[2].model : depths[2])) != null ? stack1.id : stack1), false, {"name":"action","hash":{},"data":data})))
    + "\n";
},"52":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "        <div class=\"row btn-line ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.bottomText : stack1), {"name":"if","hash":{},"fn":this.program(53, data, depths),"inverse":this.program(55, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" role=\"toolbar\">\n            <div class=\"col-xs-9\">\n";
  stack1 = helpers.each.call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1)) != null ? stack1.PRIMARY : stack1), {"name":"each","hash":{},"fn":this.program(57, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </div>\n            <!--<div class=\"col-xs-3\">-->\n            <!--<ul class=\"switch-view fl-right\">-->\n            <!--<li><span class=\"label\">Ansicht</span></li>-->\n            <!--<li>-->\n            <!--<a href=\"javascript:;\" onclick=\"return false;\" class=\"vlb-action-icon vlb-action-icon-listview active\" title=\"Ansicht Liste (aktiv)\"><span>Ansicht Liste (aktiv)</span></a>-->\n            <!--<a href=\"javascript:;\" onclick=\"return false;\" class=\"vlb-action-icon vlb-action-icon-detailview inactive\" title=\"Ansicht Detail (inaktiv)\"><span>Ansicht Detail (inaktiv)</span></a>-->\n            <!--</li>-->\n            <!--</ul>-->\n            <!--</div>-->\n        </div>\n";
},"53":function(depth0,helpers,partials,data) {
  return "btn-line-bottom2";
  },"55":function(depth0,helpers,partials,data) {
  return "btn-line-bottom";
  },"57":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                    "
    + escapeExpression(((helpers.action || (depth0 && depth0.action) || helperMissing).call(depth0, depth0, (depths[2] != null ? depths[2].modelKey : depths[2]), (depths[2] != null ? depths[2].modelUrlRoot : depths[2]), ((stack1 = (depths[2] != null ? depths[2].model : depths[2])) != null ? stack1.id : stack1), true, {"name":"action","hash":{},"data":data})))
    + "\n";
},"59":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "";
  stack1 = helpers.each.call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.mform : stack1)) != null ? stack1.block : stack1), {"name":"each","hash":{},"fn":this.program(60, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"60":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.showOnBottom : depth0), {"name":"if","hash":{},"fn":this.program(61, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"61":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compareComplex || (depth0 && depth0.compareComplex) || helperMissing).call(depth0, (depths[2] != null ? depths[2].modelAttributes : depths[2]), (depth0 != null ? depth0.visible : depth0), {"name":"compareComplex","hash":{},"fn":this.program(62, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"62":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "	            <div id=\"block_"
    + escapeExpression(((helper = (helper = helpers.blockId || (depth0 != null ? depth0.blockId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"blockId","hash":{},"data":data}) : helper)))
    + "\" class=\"block block_"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\">\n	            </div>\n";
},"64":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <p>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.bottomText : stack1), {"name":"lbl","hash":{},"data":data})))
    + "</p>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.showHeader : depth0), {"name":"if","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "<div id=\"alertBox\"></div>\n";
  stack1 = this.invokePartial(partials.helpBox, '', 'helpBox', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n<!-- show form elements top start -->\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.mform : stack1), {"name":"if","hash":{},"fn":this.program(3, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "<!-- show form elements end -->\n\n\n<!-- filters start -->\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.filters : stack1), {"name":"if","hash":{},"fn":this.program(10, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.filters : stack1)) != null ? stack1.extendedFilter : stack1), {"name":"if","hash":{},"fn":this.program(16, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "<!-- filters end -->\n\n<!-- actions start -->\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.showButtonsOnTop : stack1), {"name":"if","hash":{},"fn":this.program(22, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "<!-- actions end -->\n\n<!-- Please keep this commented out stuff for test purposes -->\n<!--<p>-->\n<!--<table id=\""
    + escapeExpression(((helper = (helper = helpers.tableId || (depth0 != null ? depth0.tableId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"tableId","hash":{},"data":data}) : helper)))
    + "_2\" >-->\n<!--<thead>-->\n\n<!--</thead>-->\n\n<!--<tbody>-->\n\n<!--</tbody>-->\n<!--</table>-->\n<!--</p>-->\n\n<div class=\"data-table "
    + escapeExpression(((helper = (helper = helpers.perspectiveClass || (depth0 != null ? depth0.perspectiveClass : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"perspectiveClass","hash":{},"data":data}) : helper)))
    + "\">\n    <table cellpadding=\"0\" cellspacing=\"0\" border=\"0\" id=\""
    + escapeExpression(((helper = (helper = helpers.tableId || (depth0 != null ? depth0.tableId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"tableId","hash":{},"data":data}) : helper)))
    + "\" class=\"dataTable no-footer\">\n        <thead>\n        <tr>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.enableRowSelection : depth0), {"name":"if","hash":{},"fn":this.program(37, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers.each.call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.column : stack1), {"name":"each","hash":{},"fn":this.program(39, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </tr>\n        </thead>\n        <tbody>\n\n        </tbody>\n    </table>\n</div>\n\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.footerText : stack1), {"name":"if","hash":{},"fn":this.program(41, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.actions : stack1), {"name":"if","hash":{},"fn":this.program(43, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n<!-- show form elements bottom start -->\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.mform : stack1), {"name":"if","hash":{},"fn":this.program(59, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "<!-- show form elements end -->\n\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.formDef : depth0)) != null ? stack1.bottomText : stack1), {"name":"if","hash":{},"fn":this.program(64, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"usePartial":true,"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}