var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "    <style>\n        [id^=\"actionBtn-edit\"] {\n            display: none;\n        }\n    </style>\n";
  },"3":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <span id=\"fa-star-half-o\">\n        <h3>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.block.productHeader.mb.basic", {"name":"lbl","hash":{},"data":data})))
    + "</h3>\n    </span>\n";
},"5":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "    <h3>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.block.productHeader", {"name":"lbl","hash":{},"data":data})))
    + "</h3>\n";
},"7":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '    ', 'readonlyControlGroup', depth0, {
    'wrapperStyles': ("status errorText"),
    'text': ((depth0 != null ? depth0.productStatusText : depth0)),
    'label': (((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, (depth0 != null ? depth0.labelPrefix : depth0), "status", {"name":"label2","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"9":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "-->\n    <!--";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '', 'readonlyControlGroup', depth0, {
    'wrapperStyles': ("status errorText"),
    'text': (((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, (depth0 != null ? depth0.labelPrefix : depth0), "archived", {"name":"label2","hash":{},"data":data}))),
    'label': (((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, (depth0 != null ? depth0.labelPrefix : depth0), "status", {"name":"label2","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "-->\n<!--";
},"11":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '    ', 'readonlyControlGroup', depth0, {
    'wrapperStyles': ("status errorText"),
    'text': (((helpers.label3 || (depth0 && depth0.label3) || helperMissing).call(depth0, (depth0 != null ? depth0.labelPrefix : depth0), "aggregatorHint", ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.aggregatorId : stack1), {"name":"label3","hash":{},"data":data}))),
    'label': (((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, (depth0 != null ? depth0.labelPrefix : depth0), "source", {"name":"label2","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"13":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '    ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.gtin13 : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.gtin13", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"15":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, functionType="function", escapeExpression=this.escapeExpression, buffer = "    <div class=\"control-group publisherData\">\n        <label class=\"control-label\">\n            ";
  stack1 = ((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.publishers", {"name":"lbl","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n        </label>\n\n        <div class=\"controls\">\n            <div class=\"publisherShortName\">\n                <span class=\"readonlyText\">";
  stack1 = ((helper = (helper = helpers.publisherShortName || (depth0 != null ? depth0.publisherShortName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"publisherShortName","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</span>\n                <a class=\"txt-link txt-link-plus add collapseButton\" data-toggle=\"collapse\"\n                   href=\"#publisherDetailData\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "blank", {"name":"lbl","hash":{},"data":data})))
    + "</a>\n            </div>\n            <div class=\"collapse in\" id=\"publisherDetailData\">\n";
  stack1 = this.invokePartial(partials.simpleContainer, '                ', 'simpleContainer', depth0, {
    'wrapperStyles': ("address"),
    'text': ((depth0 != null ? depth0.postalAddress : depth0))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.simpleContainer, '                ', 'simpleContainer', depth0, {
    'wrapperStyles': ("contact"),
    'text': ((depth0 != null ? depth0.contactData : depth0))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = this.invokePartial(partials.simpleContainer, '                ', 'simpleContainer', depth0, {
    'wrapperStyles': ("mvbId"),
    'text': ((depth0 != null ? depth0.mvbId : depth0))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "				\n";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.uiType : stack1), "journal", {"name":"compare","hash":{
    'operator': ("!==")
  },"fn":this.program(16, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = this.invokePartial(partials.simpleContainer, '                ', 'simpleContainer', depth0, {
    'wrapperStyles': ("deliveryNote"),
    'text': ((depth0 != null ? depth0.deliveryNote : depth0))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.publisherForMvbId : stack1), {"name":"if","hash":{},"fn":this.program(18, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </div>\n\n        </div>\n    </div>\n";
},"16":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials.simpleContainer, '	                ', 'simpleContainer', depth0, {
    'wrapperStyles': ("deliverer"),
    'text': ((depth0 != null ? depth0.deliveries : depth0))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.simpleContainer, '	                ', 'simpleContainer', depth0, {
    'wrapperStyles': ("deliveryAdendum"),
    'text': ((depth0 != null ? depth0.deliveryAddendum : depth0))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"18":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "					<br />\n					<b>";
  stack1 = ((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.user.mvb.contact.change.header", {"name":"lbl","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</b><br/>\n					";
  stack1 = ((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.user.mvb.contact.change.body", {"name":"lbl","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"20":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '    ', 'readonlyControlGroup', depth0, {
    'text': (((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.publisherName : stack1)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.publishers", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"22":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.retailPrices : depth0), {"name":"if","hash":{},"fn":this.program(23, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"23":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "	    <div class=\"retailPrices\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.retailPrices : depth0), {"name":"each","hash":{},"fn":this.program(24, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, (depth0 != null ? depth0.calculatedHint : depth0), true, {"name":"compare","hash":{
    'operator': ("===")
  },"fn":this.program(26, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, (depth0 != null ? depth0.zeroPercentHint : depth0), true, {"name":"compare","hash":{
    'operator': ("===")
  },"fn":this.program(28, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "	    </div>\n";
},"24":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '	            ', 'readonlyControlGroup', depth0, {
    'wrapperStyles': ("retailPrice"),
    'text': (((helpers.safeString || (depth0 && depth0.safeString) || helperMissing).call(depth0, depth0, {"name":"safeString","hash":{},"data":data}))),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.prices", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"26":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '	            ', 'readonlyControlGroup', depth0, {
    'wrapperStyles': ("hint text-primary"),
    'text': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.prices.starinfo", {"name":"lbl","hash":{},"data":data}))),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "blank", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"28":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '                ', 'readonlyControlGroup', depth0, {
    'wrapperStyles': ("hint text-primary"),
    'text': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.prices.plusinfo", {"name":"lbl","hash":{},"data":data}))),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "blank", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"30":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.journalPrices : depth0), {"name":"if","hash":{},"fn":this.program(31, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"31":function(depth0,helpers,partials,data) {
  var stack1, buffer = "	    <div class=\"journalPrices\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.journalPrices : depth0), {"name":"each","hash":{},"fn":this.program(32, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "	    </div>\n";
},"32":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '	            ', 'readonlyControlGroup', depth0, {
    'wrapperStyles': ("journalPrice"),
    'text': (((helpers.safeString || (depth0 && depth0.safeString) || helperMissing).call(depth0, depth0, {"name":"safeString","hash":{},"data":data}))),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.prices", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"34":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '    ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.editionString : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.fieldset.edition", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"36":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '    ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.publicationDate : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.publicationDate", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"38":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.publishingStatus : stack1), {"name":"if","hash":{},"fn":this.program(39, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"39":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '        ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.publishingStatus : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.publishingStatus", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"41":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.type : stack1), "journal", {"name":"compare","hash":{
    'operator': ("!==")
  },"fn":this.program(42, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"42":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '        ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.availability : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.availability", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"44":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.languages : stack1), {"name":"if","hash":{},"fn":this.program(45, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"45":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '        ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.language : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.language", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"47":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.mainReadingRationale : stack1), {"name":"if","hash":{},"fn":this.program(48, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"48":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '        ', 'readonlyControlGroup', depth0, {
    'text': (((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.collection.readingRationales", ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.mainReadingRationale : stack1), {"name":"label2","hash":{},"data":data}))),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.block.mainReadingRationaleSubject", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"50":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '    ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.publisherFrequency : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.block.publicationFrequency", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"52":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.series : depth0), {"name":"each","hash":{},"fn":this.program(53, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '    ', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.hierarchie : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.block.hierarchy", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"53":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (data && data.first), {"name":"if","hash":{},"fn":this.program(54, data),"inverse":this.program(56, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"54":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'text': (depth0),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.block.series", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"56":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '            ', 'readonlyControlGroup', depth0, {
    'text': (depth0),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "blank", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = escapeExpression(((helpers.actionConstructFromHashIfRole || (depth0 && depth0.actionConstructFromHashIfRole) || helperMissing).call(depth0, (depth0 != null ? depth0.editButtonRole : depth0), (depth0 != null ? depth0.modelKey : depth0), (depth0 != null ? depth0.modelUrlRoot : depth0), ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.id : stack1), "edit_metadata", true, {"name":"actionConstructFromHashIfRole","hash":{
    'customLabel': ("form.button.editMetadata"),
    'style': ("grey pull-right"),
    'method': ("edit")
  },"data":data})))
    + "\n\n";
  stack1 = helpers.unless.call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.publisherForMvbId : stack1), {"name":"unless","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.equals || (depth0 && depth0.equals) || helperMissing).call(depth0, (depth0 != null ? depth0.showProductBasicStar : depth0), true, {"name":"equals","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.notequals || (depth0 && depth0.notequals) || helperMissing).call(depth0, (depth0 != null ? depth0.showProductBasicStar : depth0), true, {"name":"notequals","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.productStatusText : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n<!--";
  stack1 = helpers.unless.call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.active : stack1), {"name":"unless","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "-->\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.renderSourceHint : depth0), {"name":"if","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '', 'readonlyControlGroup', depth0, {
    'divId': ("title"),
    'text': ((depth0 != null ? depth0.titleShortVersion : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.title", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '', 'readonlyControlGroup', depth0, {
    'divId': ("subtitle"),
    'text': ((depth0 != null ? depth0.subtitleShortVersion : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.subtitle", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.contributorString : depth0)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.field.contributors.author", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '', 'readonlyControlGroup', depth0, {
    'text': (((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.isbnHyphens : stack1)),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.isbn13", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.equals || (depth0 && depth0.equals) || helperMissing).call(depth0, (depth0 != null ? depth0.showProductBasic : depth0), true, {"name":"equals","hash":{},"fn":this.program(13, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = this.invokePartial(partials.readonlyControlGroup, '', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.issn : depth0)),
    'label': ((depth0 != null ? depth0.issnlabel : depth0))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.publisherData : stack1), {"name":"if","hash":{},"fn":this.program(15, data),"inverse":this.program(20, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n\n";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.type : stack1), "journal", {"name":"compare","hash":{
    'operator': ("!==")
  },"fn":this.program(22, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.type : stack1), "journal", {"name":"compare","hash":{
    'operator': ("===")
  },"fn":this.program(30, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.notequals || (depth0 && depth0.notequals) || helperMissing).call(depth0, (depth0 != null ? depth0.showProductBasic : depth0), true, {"name":"notequals","hash":{},"fn":this.program(34, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.publicationDate : stack1), {"name":"if","hash":{},"fn":this.program(36, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.equals || (depth0 && depth0.equals) || helperMissing).call(depth0, (depth0 != null ? depth0.showProductBasic : depth0), true, {"name":"equals","hash":{},"fn":this.program(38, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.notequals || (depth0 && depth0.notequals) || helperMissing).call(depth0, (depth0 != null ? depth0.showProductBasic : depth0), true, {"name":"notequals","hash":{},"fn":this.program(41, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '', 'readonlyControlGroup', depth0, {
    'text': (((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.collection.productTypes", ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.type : stack1), {"name":"label2","hash":{},"data":data}))),
    'label': (((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.product.block.productType", {"name":"lbl","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.equals || (depth0 && depth0.equals) || helperMissing).call(depth0, (depth0 != null ? depth0.showProductBasic : depth0), true, {"name":"equals","hash":{},"fn":this.program(44, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = this.invokePartial(partials.readonlyControlGroup, '', 'readonlyControlGroup', depth0, {
    'text': ((depth0 != null ? depth0.pagesString : depth0)),
    'label': (((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, (depth0 != null ? depth0.labelPrefix : depth0), "pages", {"name":"label2","hash":{},"data":data})))
  }, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.hasRight || (depth0 && depth0.hasRight) || helperMissing).call(depth0, "READING_RATIONALES", {"name":"hasRight","hash":{},"fn":this.program(47, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.publisherFrequency : depth0), {"name":"if","hash":{},"fn":this.program(50, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, (depth0 != null ? depth0.series : depth0), (depth0 != null ? depth0.hierarchie : depth0), {"name":"compare","hash":{
    'operator': ("||")
  },"fn":this.program(52, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}