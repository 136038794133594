var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return " "
    + escapeExpression(((helper = (helper = helpers.styleClassWrapper || (depth0 != null ? depth0.styleClassWrapper : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"styleClassWrapper","hash":{},"data":data}) : helper)));
},"3":function(depth0,helpers,partials,data) {
  var stack1, buffer = "        <div class=\"compositeWidgetHeader\">\n            <h5 class=\"compositeWidgetLabel\">";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.hasTitle : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</h5>\n        </div>\n";
},"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, (depth0 != null ? depth0.title : depth0), {"name":"lbl","hash":{},"data":data})));
  },"6":function(depth0,helpers,partials,data) {
  var stack1, buffer = "        <div class=\"errorAnchors ";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.errorAnchor : depth0), {"name":"each","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\"></div>\n";
},"7":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "error-"
    + escapeExpression(lambda(depth0, depth0))
    + " ";
},"9":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "        <div class=\"";
  stack1 = helpers['if'].call(depth0, ((stack1 = (data && data.root)) && stack1.styleClass), {"name":"if","hash":{},"fn":this.program(10, data),"inverse":this.program(12, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "record_"
    + escapeExpression(((helper = (helper = helpers.listIndex || (depth0 != null ? depth0.listIndex : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listIndex","hash":{},"data":data}) : helper)))
    + " row_"
    + escapeExpression(((helper = (helper = helpers.rowIndex || (depth0 != null ? depth0.rowIndex : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"rowIndex","hash":{},"data":data}) : helper)))
    + " listWidget ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.lastGroupedRow : depth0), {"name":"if","hash":{},"fn":this.program(14, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = helpers['if'].call(depth0, (data && data.last), {"name":"if","hash":{},"fn":this.program(16, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, ((stack1 = (data && data.root)) && stack1.hideMoreThan), {"name":"if","hash":{},"fn":this.program(18, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (data && data.root)) && stack1.isRemovable), {"name":"if","hash":{},"fn":this.program(21, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, (depth0 != null ? depth0.separatorIndex : depth0), 0, {"name":"compare","hash":{
    'operator': ("===")
  },"fn":this.program(23, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (data && data.root)) && stack1.itemTitle), {"name":"if","hash":{},"fn":this.program(25, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n            <div class=\"control-group-row\">\n                <div class=\"controls-row\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.separatorCurrencyLabel : depth0), {"name":"if","hash":{},"fn":this.program(30, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n                    ";
  stack1 = ((helper = (helper = helpers.rendered || (depth0 != null ? depth0.rendered : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"rendered","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isRemovable : depth0), {"name":"if","hash":{},"fn":this.program(34, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n                    <span class=\"help-inline forOneRow vlx_error\"/>\n                </div>\n            </div>\n\n        </div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.lastGroupedRow : depth0), {"name":"if","hash":{},"fn":this.program(44, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"10":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return escapeExpression(lambda(((stack1 = (data && data.root)) && stack1.styleClass), depth0))
    + " ";
},"12":function(depth0,helpers,partials,data) {
  return "row ";
  },"14":function(depth0,helpers,partials,data) {
  return "lastGroupRow";
  },"16":function(depth0,helpers,partials,data) {
  return " lastRow";
  },"18":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing;
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, (data && data.index), ((stack1 = (data && data.root)) && stack1.hideMoreThan), {"name":"compare","hash":{
    'operator': (">=")
  },"fn":this.program(19, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { return stack1; }
  else { return ''; }
  },"19":function(depth0,helpers,partials,data) {
  return " hideable";
  },"21":function(depth0,helpers,partials,data) {
  return "removeable";
  },"23":function(depth0,helpers,partials,data) {
  return " new-list-block";
  },"25":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "                <h5 class=\"itemDescriptionLabel ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (data && data.root)) && stack1.styleClass), {"name":"if","hash":{},"fn":this.program(26, data),"inverse":this.program(28, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, ((stack1 = (data && data.root)) && stack1.itemTitle), {"name":"lbl","hash":{},"data":data})))
    + "</h5>\n";
},"26":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return escapeExpression(lambda(((stack1 = (data && data.root)) && stack1.styleClass), depth0));
  },"28":function(depth0,helpers,partials,data) {
  return "col-sm-12";
  },"30":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, (depth0 != null ? depth0.rowIndex : depth0), 0, {"name":"compare","hash":{
    'operator': ("===")
  },"fn":this.program(31, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"31":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, (depth0 != null ? depth0.separatorIndex : depth0), 0, {"name":"compare","hash":{
    'operator': ("===")
  },"fn":this.program(32, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"32":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                                <div class=\"col-xs-12\">\n                                    <p class=\"legend\">\n                                        <span>"
    + escapeExpression(((helper = (helper = helpers.separatorCurrencyLabel || (depth0 != null ? depth0.separatorCurrencyLabel : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"separatorCurrencyLabel","hash":{},"data":data}) : helper)))
    + "</span>\n                                    </p>\n                                </div>\n";
},"34":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, (depth0 != null ? depth0.rowIndex : depth0), 0, {"name":"compare","hash":{
    'operator': ("===")
  },"fn":this.program(35, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"35":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, functionType="function", escapeExpression=this.escapeExpression, buffer = "                            <!-- Index must be last class!! due to implementation in form_block_view.js.getWidgetIndex and getCompositeWidget - should be refactored -->\n                            <div class=\"removeButtonContainer ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (data && data.root)) && stack1.removeLabel), {"name":"if","hash":{},"fn":this.program(36, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, (depth0 != null ? depth0.separatorIndex : depth0), 0, {"name":"compare","hash":{
    'operator': ("===")
  },"fn":this.program(38, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += " button_"
    + escapeExpression(((helper = (helper = helpers.listIndex || (depth0 != null ? depth0.listIndex : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listIndex","hash":{},"data":data}) : helper)))
    + "\">\n                                <i class=\"vlb-action-icon vlb-action-icon-delete remove button_"
    + escapeExpression(((helper = (helper = helpers.listIndex || (depth0 != null ? depth0.listIndex : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listIndex","hash":{},"data":data}) : helper)))
    + "\">\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (data && data.root)) && stack1.removeLabel), {"name":"if","hash":{},"fn":this.program(40, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.compare || (depth0 && depth0.compare) || helperMissing).call(depth0, (depth0 != null ? depth0.separatorIndex : depth0), 0, {"name":"compare","hash":{
    'operator': ("===")
  },"fn":this.program(42, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                                </i>\n                            </div>\n";
},"36":function(depth0,helpers,partials,data) {
  return "withLabel";
  },"38":function(depth0,helpers,partials,data) {
  return " withSeparatorLabel remove-list-group";
  },"40":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "                                        <label class=\"button_"
    + escapeExpression(((helper = (helper = helpers.listIndex || (depth0 != null ? depth0.listIndex : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listIndex","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(lambda(((stack1 = (data && data.root)) && stack1.removeLabel), depth0))
    + "</label>\n";
},"42":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                                        <label class=\"separatorLabel button_"
    + escapeExpression(((helper = (helper = helpers.listIndex || (depth0 != null ? depth0.listIndex : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listIndex","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(((helper = (helper = helpers.separatorRemoveLabel || (depth0 != null ? depth0.separatorRemoveLabel : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"separatorRemoveLabel","hash":{},"data":data}) : helper)))
    + "</label>\n";
},"44":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers.unless.call(depth0, (data && data.last), {"name":"unless","hash":{},"fn":this.program(45, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"45":function(depth0,helpers,partials,data) {
  var stack1, buffer = "                <div class=\"control-group-row\">\n                    <div class=\"listWidgetSeperator";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.lastElementInSeparation : depth0), {"name":"if","hash":{},"fn":this.program(46, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\"></div>\n                </div>\n";
},"46":function(depth0,helpers,partials,data) {
  return " new-list-group";
  },"48":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, ((stack1 = (data && data.root)) && stack1.emptyDesc), {"name":"if","hash":{},"fn":this.program(49, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"49":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "            <div class=\"emptyList\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, ((stack1 = (data && data.root)) && stack1.emptyDesc), {"name":"lbl","hash":{},"data":data})))
    + "</div>\n";
},"51":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "        <div class=\"add-button control-group ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (data && data.root)) && stack1.styleClass), {"name":"if","hash":{},"fn":this.program(10, data),"inverse":this.program(12, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"\n             ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.hasParentIndex : depth0), {"name":"if","hash":{},"fn":this.program(52, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n            <a class=\"txt-link txt-link-plus add\" style=\"height: 15px;\" href=\"javascript:;\"\n               onclick=\"return false;\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, ((stack1 = (data && data.root)) && stack1.addDesc), {"name":"lbl","hash":{},"data":data})))
    + "</a>\n        </div>\n";
},"52":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "data-parentIndex=\""
    + escapeExpression(((helper = (helper = helpers.parentIndex || (depth0 != null ? depth0.parentIndex : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"parentIndex","hash":{},"data":data}) : helper)))
    + "\"";
},"54":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "        <div class=\"control-group toggle-hidden-button ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (data && data.root)) && stack1.styleClass), {"name":"if","hash":{},"fn":this.program(10, data),"inverse":this.program(12, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n            <a class=\"txt-link txt-link-toggle-hidden toggleHidden\" href=\"javascript:;\" onclick=\"return false;\">\n                <span class=\"showAll\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, ((stack1 = (data && data.root)) && stack1.showAllDesc), {"name":"lbl","hash":{},"data":data})))
    + "</span>\n                <span class=\"hideSome hideable\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, ((stack1 = (data && data.root)) && stack1.hideSomeDesc), {"name":"lbl","hash":{},"data":data})))
    + "</span>\n            </a>\n        </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "<div class=\""
    + escapeExpression(((helper = (helper = helpers.property || (depth0 != null ? depth0.property : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"property","hash":{},"data":data}) : helper)))
    + " comp_"
    + escapeExpression(((helper = (helper = helpers.compIndex || (depth0 != null ? depth0.compIndex : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"compIndex","hash":{},"data":data}) : helper)))
    + " contains-"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.renderedRow : depth0)) != null ? stack1.length : stack1), depth0))
    + "-rows";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.styleClassWrapper : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " listSet\">\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.hasTitle : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.errorAnchor : depth0), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.renderedRow : depth0), {"name":"each","hash":{},"fn":this.program(9, data),"inverse":this.program(48, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isAddable : depth0), {"name":"if","hash":{},"fn":this.program(51, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.hideMoreThan : depth0), {"name":"if","hash":{},"fn":this.program(54, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}