var Logger = log4javascript.getLogger('lib.SimpleStringConverter');

var SimpleStringConverter = function () {
};

SimpleStringConverter.prototype.getAsObject = function(value){
	return value;
};
SimpleStringConverter.prototype.getAsString = function(value, pattern){
	return value;
};