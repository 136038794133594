var Application = require('application');
var ModalPanelView = require('views/modalPanel_view');
var ModalReceiverRowView = require('views/modalReceiverRow_view');
var ModalPanelModel = require('models/modalPanel');
var MsgHelper = require('./msg_helper');
var FormCollectionAuth = require('models/formCollectionAuth');
var MiscHelper = require('lib/misc_helper');
var StringUtil = require('lib/string_util');
var MessageSelectorHelper = require('./msgSelector_helper');

var Logger = log4javascript.getLogger('lib.FormHelper');

// Added MDS135 - Alxander Haffner pleased me to ignore this message (#14635).
var arrMessagesToBeIgnored = [
    'MDS001', 'MDS002', 'MDS004', 'MDS005', 'MDS006', 'MDS007', 'MDS008', 'MDS009', 'MDS012', 'MDS013', 'MDS032', 'MDS033',
    'MDS034', 'MDS035', 'MDS036', 'MDS037', 'MDS039', 'MDS040', 'MDS041', 'MDS042', 'MDS043', 'MDS044', 'MDS052', 'MDS054',
    'MDS055', 'MDS056', 'MDS057', 'MDS058', 'MDS059', 'MDS060', 'MDS061', 'MDS102', 'MDS108', 'MDS110', 'MDS111', 'MDS113',
    'MDS114', 'MDS117', 'MDS122', 'MDS127', 'MDS133', 'MDS134', 'MDS135', 'MDS136', 'MDS136', 'MDS139', 'MDS140', 'MDS141',
    'MDS157', 'MDS165', 'MDS170', 'MDS202', 'MDS204', 'MDS205', 'MDS213', 'MDS219', 'MDS220', 'MDS252', 'MDS254', 'MDS255',
    'MDS256', 'MDS258', 'MDS259', 'MDS266', 'MDS275', 'MDS278', 'MDS287', 'MDS293', 'MDS296', 'MDS297', 'MDS301', 'MDS304',
    'MDS307', 'MDS309', 'MDS311', 'MDS312', 'MDS313', 'MDS314', 'MDS316', 'MDS318', 'MDS319', 'MDS320', 'MDS323', 'MDS331',
    'MDS333', 'MDS340', 'MDS350', 'MDS351', 'MDS401', 'MDS402', 'MDS403', 'MDS404', 'MDS405', 'MDS406', 'MDS504', 'MDS505',
    'MDS515', 'MDS518', 'MDS519', 'MDS650', 'MDS651', 'MDS652',
    'MDS20110', 'MDS20258', 'MDS20704', 'MDS20705', 'MDS20706'];

var IGNORE_MESSAGE_FLAG = 'ignore_message_flag';

var FormHelper = function () {
    this.msgSelectors = {
        'MDS103': MessageSelectorHelper.MDS103,
        // 'MDS109': MessageSelectorHelper.MDS109,
        'MDS129': MessageSelectorHelper.MDS129,
        'MDS132': MessageSelectorHelper.MDS132,
        'MDS166': MessageSelectorHelper.MDS166,
        'MDS167': MessageSelectorHelper.MDS167,
        'MDS272': MessageSelectorHelper.MDS272,
        'MDS502': MessageSelectorHelper.bundlesZeroRow,
        'MDS507': MessageSelectorHelper.MDS507,
        'MDS509': MessageSelectorHelper.MDS509,
        'MDS510': MessageSelectorHelper.MDS510,
        'MDS511': MessageSelectorHelper.bundlesZeroRow,
        'MDS512': MessageSelectorHelper.MDS512,
        'MDS514': MessageSelectorHelper.MDS514,
        'MDS516': MessageSelectorHelper.bundlesZeroRow,
        'MDS517': MessageSelectorHelper.bundlesZeroRow,
        'MDS521': MessageSelectorHelper.MDS521,
        'MDS522': MessageSelectorHelper.bundlesZeroRow,
        'MDS523': MessageSelectorHelper.MDS523,
        'MDS524': MessageSelectorHelper.MDS524,
        'MDS606': MessageSelectorHelper.bundlesZeroRow,
        'MDS20708': MessageSelectorHelper.errorAnchor,
        'UI002': MessageSelectorHelper.UI002
    };

};

FormHelper.prototype.sortClassifications = function (model) {

    if (model.get('classifications')) {
        model.get('classifications').sort(function (a, b) {

            if (MiscHelper.isEmpty(a) || MiscHelper.isEmpty(b)) {
                return 0;
            }

            if (a.type !== b.type) {
                return a.type - b.type;
            }
            if (a.main === true) {
                return -1;
            } else if (b.main === true) {
                return 1;
            } else if (a.code - b.code !== 0) {
                return a.code - b.code;
            } else {
                return a.value - b.value;
            }
        });
    }
};

FormHelper.prototype.sortKeywordsBySequence = function(model) {
    if(model.get('classifications')) {
        model.get('classifications').sort(function (a, b) {
            if(a.type === '20' && a.type === b.type) {
                return a.sequence - b.sequence;
            }
        });
    }
};

FormHelper.prototype.sortClassificationsWithoutType = function (model) {
    let classification;
    if (model) {
        if (typeof model.get === 'function') {
            if (model.get('classifications')) {
                classification = model.get('classifications');
            }
        } else if ($.isArray(model)) {
            classification = model;
        }
        if (classification) {
            classification.sort(function (a, b) {
                if (MiscHelper.isEmpty(a) || MiscHelper.isEmpty(b)) {
                    return 0;
                }

                if (a.type !== b.type) {
                    return 0;
                }
                if (a.main === true) {
                    return -1;
                } else if (b.main === true) {
                    return 1;
                } else if (a.code - b.code !== 0) {
                    return a.code - b.code;
                } else {
                    return a.value - b.value;
                }
            });
        }
    }
};

FormHelper.prototype.fromUiIdtoModelNode = function (id) {

    var retVal = '';
    var split = id.split('_');
    var index = split.length - 1;
    while (index >= 0) {
        if (Number.isInteger(parseInt(split[index]))) {
            retVal = split[index] + '.' + split[index - 1];
            index--;
        } else {
            if (retVal.length > 0) {
                retVal = split[index] + '.' + retVal;
            } else {
                retVal = split[index];
            }
        }
        index--;
    }

    return retVal;
};

FormHelper.prototype.showModal = function (modal, modalReceiver) {
    var modalModel = new ModalPanelModel({modalData: modal});
    var modalView = new ModalPanelView({autoAttach: true, model: modalModel});
    // prevent double popup-bug
    if (FormHelper.prototype.notPoppedUpAlready()) {
        alertify.set({
            labels: {
                ok: MsgHelper.getMessage("label.form.button.apply"),
                cancel: MsgHelper.getMessage("label.cancel")
            }
        });
        alertify.confirm('<h5>' + MsgHelper.getMessage("label.select.group.partner.mapping") + '</h5>', function (confirmed) {
            // user confirmed
            if (confirmed) {
                // fetch all items available in this panel
                var items = [];
                $('#modalPanel').addClass('modal_' + modal.modalIndex);
                $('#modalPanel [id^=modal_]').each(function (index, item) {
                    var widget = FormHelper.prototype.getWidget(item, modal);
                    switch (widget.type) {
                        case "TextWidget":
                            items.push($(item).val());
                            break;
                        case "PasswordWidget":

                            break;
                        case "DateWidget":

                            break;
                        case "ModelDetailsLinkWidget":

                            break;
                        case "SelectionWidget":
                            if (widget.multiple) {
                                var result = $(item).select2("data");
                                for (var i = 0; i < result.length; i++) {
                                    items.push([{id: result[i].id, name: result[i].text}]);
                                }
                            } else {
                                items.push([{id: $(item).select2("data").id, name: $(item).select2("data").text}]);
                            }
                            break;
                        case "CheckboxWidget":

                            break;
                        case "TextareaWidget":

                            break;
                        case "HiddenWidget":

                            break;
                        case "IconWidget":

                            break;
                        case "RadioWidget":

                            break;
                        case "RadioIconWidget":

                            break;
                        case "GlyphWidget":

                            break;
                        case "ActionWidget":

                            break;
                    }
                });

                // attach items to receiver
                var modalRowModel = new ModalPanelModel({modalData: items, receiver: modalReceiver});
                new ModalReceiverRowView({autoRender: true, autoAttach: true, model: modalRowModel});
            }
        });
    }
    modalView.render();
};

FormHelper.prototype.notPoppedUpAlready = function () {
    return ($('#alertify').size() == 0) ||
        (($('#alertify').size() == 1) &&
            ($('#alertify').hasClass('alertify-hide') ||
                $('#alertify').hasClass('alertify-hidden') ||
                $('#alertify').hasClass('alertify-isHidden')));
};

/**
 * builds the modal receivers content from columns as provided by markup
 * @param index the index of the current modal
 * @param columns the columns defined by the markup
 * @param receiver the modal receiver
 * @param model the model containing the values to be selected
 * @returns the data that can be provided to a modal receiver row
 */
FormHelper.prototype.buildFormModalColumns = function (index, columns, receiver, model) {
    var items = [];
    // fetch each defined columns data
    for (var j = 0; j < columns.length; j++) {
        var inner = [];
        var valProperty = columns[j]['valueWidget']['property'];
        var idProperty = columns[j]['idWidget'] ? columns[j]['idWidget']['property'] : undefined;

        var val = model.get(receiver.property + "[" + index + "]." + valProperty);
        var id = idProperty ? model.get(receiver.property + "[" + index + "]." + idProperty) : undefined;

        if (val !== undefined) {
            inner.push({value: val, id: id});
        } else {
            inner.push({value: ""});
        }

        items.push(inner);
    }

    var modalRowModel = new ModalPanelModel({modalData: items, receiver: receiver});
    new ModalReceiverRowView({autoRender: true, autoAttach: true, model: modalRowModel});
};


FormHelper.prototype.getIndexMap = function (element, indexMap) {
    var searchArray = ['fieldSet', 'comp', 'col', 'widget'];
    if (element.className) {
        var classes = element.className.split(' ');
        for (var i = 0; i < classes.length; i++) {
            for (var j = 0; j < searchArray.length; j++) {
                if (classes[i].indexOf(searchArray[j] + '_') === 0) {
                    var possibleIndex = classes[i].substr(searchArray[j].length + 1);
                    if ((FormHelper.prototype.isNumber(possibleIndex) || searchArray[j] === 'comp') && indexMap[searchArray[j]] === undefined) {
                        indexMap[searchArray[j]] = possibleIndex;
                        break;
                    }
                }
            }
        }
    }
    if (indexMap.fieldSet) {
        return indexMap;
    } else {
        return this.getIndexMap(element.parentNode, indexMap);
    }
};

FormHelper.prototype.isNumber = function (n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
};

FormHelper.prototype.showIdForSelect = function (options) {

    var retVal = options.slice(0);

    for (var i = 0; i < retVal.length; i++) {
        retVal[i] = $.extend({}, retVal[i]);
    }

    for (var i = 0; i < retVal.length; i++) {
        retVal[i].text = retVal[i].id + ' - ' + retVal[i].text;
    }

    return retVal;
}

FormHelper.prototype.getArrayForSelect = function (options, multiple, showId, value, nullable) {
    //only split by , when select is multiple
    var text = [];
    var textReplacement;

    // in this case the user should be able to choose out of all available options - so we're building an array of ids and texts
    text = value;
    textReplacement = [];

    for (var j = 0; j < options.length; j++) {
        var option = options[j];
        if (MiscHelper.isEmpty(text) || $.inArray(option.id, text) != -1) {
            if (showId) {
                textReplacement.push({id: option.id, text: option.id + " - " + option.text});
            }
            else {
                textReplacement.push({id: option.id, text: option.text});
            }
        }
    }

    return textReplacement;
};

FormHelper.prototype.getTextForSelect = function (options, multiple, showId, value) {
    //only split by , when select is multiple
    var text = [];
    var textReplacement = "";
    if (multiple === true) {
        if ($.isArray(value)) {
            text = value;
        } else {
            text = value.split(',');
        }
    } else {
        text.push(value);
    }

    // right now collections need to be filled
    var hasOne = false;
    if (options) {
        for (var j = 0; j < options.length; j++) {
            var option = options[j];
            if ($.inArray(option.id, text) != -1) {
                if (hasOne) {
                    textReplacement += ", ";
                }
                hasOne = true;
                if (showId) {
                    textReplacement += option.id + ' - ' + option.text;
                }
                else {
                    textReplacement += option.text;
                }
            }
        }
    }
    return textReplacement;
};

FormHelper.prototype.getCodelistLabel = function (codelist, code) {
    if (!MiscHelper.isEmpty(codelist)) {
        for (var i = 0; i < codelist.length; i++) {
            if (codelist[i].code == code) {
                return codelist[i].description;
            }
        }
    }
    return code;
}

FormHelper.prototype.getCollectionLabel = function (collection, id) {
    for (var i = 0; i < collection.length; i++) {
        if (collection[i].id == id) {
            var value = collection[i].value;
            // if the value is undefined (e.g. for zis subject groups) use the text
            if (value === undefined) {
                value = collection[i].text;
            }
            return value;
        }
    }
    return id;
}


FormHelper.prototype.getCollection = function (collections, collectionId, doNotFetch, model) {
//  console.log('fetching collection with id: ' + collectionId);
    var values = [];
    // find the correct collection (is null on Show-pages)
    if (collections !== undefined && collections !== null) {
        for (var i = 0; i < collections.collection.length; i++) {
            if (collections.collection[i].id === collectionId) {
                var collection = collections.collection[i];
                if (!collection.labelPrefix) {
                    collection.labelPrefix = "form.collection." + collectionId;
                }
                if (collection.url) {
                    Logger.debug("collection: ", collection);
                    var modelId = collection.id;
                    if (collection.urlId) {
                        modelId = collection.urlId;
                    }
                    var urlRoot;
                    var collectionUrl = String(collection.url);
                    if (_.string.startsWith(collectionUrl, "$api/")) {
                        urlRoot = collectionUrl.replace("$api/", apiRoot);
                    } else {
                        urlRoot = applicationRoot + collectionUrl;
                    }
                    values.url = urlRoot + modelId;
                    values.collectionId = collectionId;

                    if (Array.isArray(collection.value) && collection.value.length > 0) {
                        var valuesForStorage = collection.value;
                        for (var i = 0; i < valuesForStorage.length; i++) {
                            values.push(valuesForStorage[i]);
                        }
                    } else {
                        var sessionValues = sessionStorage.getItem('collection_' + collectionId);
                        if (MiscHelper.isEmpty(sessionValues)) {
                            var selectModel;
                            selectModel = new FormCollectionAuth({
                                id: modelId,
                                'url_root': urlRoot
                            });

                            if (!doNotFetch) {
                                selectModel.fetch();
                                var fetchedData = selectModel.attributes;
                                if (!MiscHelper.isEmpty(collection.subProperty)) {
                                    fetchedData = selectModel.attributes[collection.subProperty];
                                }

                                var valuesForStorage = [];
                                for (var key in fetchedData) {
                                    if ($.isNumeric(key)) {
                                        if (collection.simple === false) {
                                            fetchedData[key].text = fetchedData[key][collection.textProperty];
                                            fetchedData[key].id = fetchedData[key][collection.idProperty];
                                            values.push(fetchedData[key]);
                                            valuesForStorage.push(fetchedData[key]);
                                        } else {
                                            values.push(this.getCollectionItem(fetchedData[key], collection.labelPrefix));
                                            valuesForStorage.push(this.getCollectionItem(fetchedData[key], collection.labelPrefix));
                                        }
                                    }
                                }
                                collection.value = valuesForStorage;
                                var jsonValues = JSON.stringify(valuesForStorage);
                                if(collectionId != 407 && collectionId != 393){
                                    sessionStorage.setItem('collection_' + collectionId, jsonValues);
                                }
                            }
                        } else {
                            var valuesForStorage = JSON.parse(sessionValues);
                            for (var i = 0; i < valuesForStorage.length; i++) {
                                values.push(valuesForStorage[i]);
                            }
                        }
                    }

                } else {
                    // get all collection values
                    for (var j = 0; j < collection.value.length; j++) {
                        if (MiscHelper.isEmpty(collection.value[j].showOn) || MiscHelper.compareComplex(model.attributes, collection.value[j].showOn)) {
                            values.push(this.getCollectionItem(collection.value[j], collection.labelPrefix));

                        }
                    }
                }
                break;
            }
        }
    } else {
        // no collections attached to page (probably a show page?)
        Logger.warn('No collection entries found...');
    }

    return values;
};

FormHelper.prototype.getCollectionItem = function (value, labelPrefix) {
    var option = new Object();
    if ($.isPlainObject(value)) {
        option.id = value.id;
        option.code = value.id;
        option.label = value.label;
        if (!value.label) {
            option.text = labelPrefix + "." + option.id.replace(/ /, '_');
        } else {
            option.text = value.label;
        }
    } else {
        option.id = value;
        option.code = value;
        option.text = labelPrefix + "." + option.id.replace(/ /, '_');
    }
    option.description = MsgHelper.getMessage('label.' + option.text);
    option.text = MsgHelper.getMessage('label.' + option.text);
    return option;
};

FormHelper.prototype.getActionOnRowClick = function (formDef) {
    if (formDef.get('actionOnRowClick') !== undefined) {
        var actionName = formDef.get('actionOnRowClick');
        var allActions = formDef.get('actions');
        if (allActions !== undefined) {
            for (var i = 0; i < allActions['action'].length; i++) {
                if (actionName === allActions['action'][i]['id']) {
                    return allActions['action'][i];
                }
            }
        }
    }
    return undefined;
};

FormHelper.prototype.buildApiUrl = function (urlPattern, tokenInUrl) {
    if (urlPattern) {
        var url = "";
        if (_.string.startsWith(urlPattern, "$api/")) {
            url = urlPattern.replace("$api/", apiRoot);
            if (tokenInUrl) {
                url += '?access_token=' + user.access_token;
            }
        } else {
            url = applicationRoot + "/" + urlPattern;
        }
        return url;
    }
    throw new Error("url pattern is undefined");

};


FormHelper.prototype.selector2Key = function (selector) {
    var key = this.selector2Id(selector);
    key = key.replace(/_/g, '\\.');
    return key;
},

    FormHelper.prototype.selector2Id = function (selector) {
        var id = selector;
        if (_.string.startsWith(selector, ".") || _.string.startsWith(selector, "#")) {
            id = selector.substring(1);
        }
        return id;
    };

FormHelper.prototype.jsonResponseError = function (response) {
    var data = jQuery.parseJSON(response.responseText);
    var msg = "status: " + response.status + " - " + data.error_description;
    MsgHelper.showErrorAlert(msg);
};

FormHelper.prototype.findIndexById = function (listProp, id) {
    for (var i = 0; i < listProp.length; i++) {
        if (listProp[i] !== null && listProp[i].id === id) {
            return i;
        }
    }
    return null;
};

FormHelper.prototype.determineRecordSelector = function (model, listName) {
    var parts = listName.split(":");
    if (parts[1] === 'prices') {
        var index = FormHelper.prototype.findIndexById.call(this, model.get('retailPrices'), parts[2]);
        var thePrice = model.get('retailPrices')[index];
        var price = 'retailPrices';
        if (index == null) {
            index = FormHelper.prototype.findIndexById.call(this, model.get('specialPrices'), parts[2]);
            price = 'specialPrices';
        }

        if (index !== null) {
            if (thePrice !== undefined && thePrice !== null && thePrice.country === 'AT' && thePrice.calculated === true) {
                return IGNORE_MESSAGE_FLAG;
            }
            return '#' + price + ' tr[data-id="' + index + '"] ';
        }
    } else if (parts[1] === 'containedItems' && model.get('type') === 'duoBundle') {
        var list = model.get(parts[1]);
        var i = 0;
        if (list != undefined) {
            for (; i < list.length; i++) {
                if (list[i] !== null && list[i].id === parts[2]) {
                    theIndex = i;
                    var theSelector = '.duoBundles .record_0 tr[data-id="' + theIndex + '"]';
                    return theSelector;
                    break;
                }
            }
        }
    } else if (parts[1] === 'multiBundles') {
        var bundleIndex = FormHelper.prototype.findIndexById.call(this, model.get('multiBundles'), parts[2]);
        var bundleParts = model.get('multiBundles.' + bundleIndex).bundleParts;
        var partIndex = FormHelper.prototype.findIndexById.call(this, bundleParts, parts[4]);
        var theSelector = '.multiBundles .record_' + bundleIndex + ' tr[data-id="' + partIndex + '"]';
        return theSelector;
    } else {
        var list = model.get(parts[1]);
        var i = 0;
        if (list != undefined) {
            for (; i < list.length; i++) {
                if (list[i] !== null && list[i].id === parts[2]) {
                    theIndex = i;
                    break;
                }
            }
        }
        return '.' + parts[1] + ' .record_' + i;
    }
};

FormHelper.prototype.getSetAndSeriesFromProduct = function (productModel) {
    var data = {};
    data['model'] = productModel;
    data.series = [];
    data.hierarchie = [];
    if (data.model.collections) {
        for (var i = 0; i < data.model.collections.length; i++) {
            var id;
            if (data.model.collections[i].master.identifiers[0] === undefined) {
                id = '???';
            } else {
                id = data.model.collections[i].master.identifiers[0].value;
            }
            var appRoot;
            if (MiscHelper.isEmpty(applicationRoot)) {
                appRoot = '/';
            } else {
                appRoot = applicationRoot;
                if (!_.string.startsWith(applicationRoot, "/")) {
                    appRoot = '/' + appRoot;
                }
                if (!_.string.endsWith(applicationRoot, "/")) {
                    appRoot = appRoot + '/';
                }
            }
            var text = '<a href="' + appRoot + '#collections/collection/' + id + '/' + data.model.collections[i].master.type + '">';
            text += data.model.collections[i].master.title;
            if (data.model.collections[i].master.subtitle) {
                text += ', ' + data.model.collections[i].master.subtitle;
            }
            text += ' (' + id + ')';
            if (data.model.collections[i].sequence) {
                text += ', ' + MsgHelper.getMessage('label.form.product.field.setSeries.sequence.show') + ': ';
                text += data.model.collections[i].sequence;
            }

            if (data.model.collections[i].master.type == 'series') {
                text += "</a>";
                data.series.push(text);
            }
            if (data.model.collections[i].master.type == 'set') {
                if (data.model.collections[i].levelSequence) {
                    text += '<br/>' + MsgHelper.getMessage('label.form.product.field.hierarchy.level') + ': ';
                    text += data.model.collections[i].levelSequence;
                }
                text += "</a>";
                data.hierarchie.push(text);
            }
        }
    }
    return data;
}

FormHelper.prototype.determineFieldSelector = function (model, fieldName, code) {
    var parts = fieldName.split(":");
    if (parts.length > 1) {

        var result = parts[0] + "_" + parts[parts.length - 1].replace(/\./, "_");

        if (parts[0] === 'prices') {
            var index = FormHelper.prototype.findIndexById.call(this, model.get('retailPrices'), parts[1]);
            var thePrice = model.get('retailPrices')[index];
            var price = 'retailPrices';
            if (index == null) {
                index = FormHelper.prototype.findIndexById.call(this, model.get('specialPrices'), parts[1]);
                price = 'specialPrices';
            }
            if (index == null) {
                index = FormHelper.prototype.findIndexById.call(this, model.get('foreignPrices'), parts[1]);
                price = 'foreignPrices';
            }
            if (index == null) {
                if (model.get('type') === 'duoBundle') {
                    index = FormHelper.prototype.findIndexById.call(this, model.get('duoBundles'), parts[1])
                    price = 'fieldSet_priceDuoBundle';
                } else if (model.get('type') === 'multiBundle') {
                    index = FormHelper.prototype.findIndexById.call(this, model.get('multiBundles'), parts[1])
                    price = 'fieldSet_priceMultiBundle';
                }
                result = price + ' .error-' + code;
                index = null;
            }

            if (index !== null) {
                if (thePrice !== undefined && thePrice !== null && thePrice.country === 'AT' && thePrice.calculated === true) {
                    return IGNORE_MESSAGE_FLAG;
                }
                result = price + ' tr[data-id="' + index + '"] ';
            }
        } else {
            var prop = model.get(parts[0]);
            if ($.isArray(prop)) {
                var index = FormHelper.prototype.findIndexById.call(this, prop, parts[1]);
                if (index !== null) {
                    if (parts[0] === "contributors" && parts[2] === "auto") {
                        if (prop[index]["company"]) {
                            result = parts[0] + "_groupName_" + index;
                        } else {
                            result = parts[0] + "_lastName_" + index;
                        }

                    } else {
                        result += "_" + index;
                    }
                }
            }
        }

        return result;
    } else {
        return parts[0];
    }
};
FormHelper.prototype.hasError = function (data) {
    for (var i = 0; i < data.length; i++) {
        // // TODO Hot-Fix to be able to prevent saving without an expected ISSN
        // if (data[i].code === 'MDS601') {
        //     data[i].preventSave = true;
        // }
        if (data[i].preventSave === true && arrMessagesToBeIgnored.indexOf(data[i].code) < 0) {
            return true;
        }
    }
    return false;
};

FormHelper.prototype.error = function (data, model, isCreateMode) {
    MsgHelper.removeAllMsgsOnFields();

    var theRecordSelector;
    var theMsg = '';

    for (var i = 0; i < data.length; i++) {

        theMsg = '';

        if (arrMessagesToBeIgnored.indexOf(data[i].code) > -1) {
            continue;
        }

        if (this.msgSelectors[data[i].code] !== undefined) {
            theRecordSelector = this.msgSelectors[data[i].code](data[i], model);
            if (theRecordSelector === IGNORE_MESSAGE_FLAG) {
                continue;
            }
            if (theRecordSelector !== undefined) {
                theMsg += MsgHelper.getMessage(data[i].msg);
                MsgHelper.showMsgOnField(theRecordSelector, theMsg, data[i].preventSave === false);
                theRecordSelector = undefined;
                continue;
            }
        }

        // // TODO Hot-Fix to be able to prevent saving without an expected ISSN
        // if (data[i].code === 'MDS601') {
        //     data[i].preventSave = true;
        // }

        var theField = data[i].field;

        if (theField !== null) {
            //field is from mds
            // MDS217 (PRODUCT_NO_IDENTIFIER_SPECIFIED) delivers no information about the identifier. Instead we get "value"
            // what has no worth because we have up to four identifier attributes and all of them are saved in a that field.
            if (data[i].code !== 'MDS217' && theField.indexOf("cplx:") !== -1 && theField.split(":").length === 3) {
                // If there are only three values separated by a colon we have no attribute.
                // In that case we have to append the message to the records div.
                theRecordSelector = FormHelper.prototype.determineRecordSelector.call(this, model, theField.replace("cplx:null:", ""));
                if (theRecordSelector === IGNORE_MESSAGE_FLAG) {
                    continue;
                }
                if (false /*data[i].code */) {
                    theMsg += data[i].code + " - ";
                }
                theMsg += MsgHelper.getMessage(data[i].msg);
                //if (data[i].code === 'MDS272' && !$(theRecordSelector).is(':visible')) {
                //    MsgHelper.showMsgOnField('.error-' + data[i].code, theMsg, data[i].preventSave === false);
                //} else
                if ($(theRecordSelector).length > 0) {
                    MsgHelper.showMsgOnField(theRecordSelector, theMsg, data[i].preventSave === false);
                } else if (data[i].code && $('.error-' + data[i].code).length > 0) {
                    MsgHelper.showMsgOnField('.error-' + data[i].code, theMsg, data[i].preventSave === false);
                } else {
                    var msg = '';
                    msg += theMsg;
                    if (msg.length > 0) {
                        MsgHelper.showErrorAlert(msg, data[i].preventSave === false);
                    }
                }
            } else {
                if (false /*data[i].code */) {
                    theMsg += data[i].code + " - ";
                }
                theMsg += MsgHelper.getMessage(data[i].msg);

                if (theField.indexOf("cplx:") !== -1) {
                    if (data[i].code === 'MDS131') {
                        var retailPrices = model.get('retailPrices');
                        for (var j = 0; j < retailPrices.length; j++) {
                            var retailPrice = retailPrices[j];
                            if (MiscHelper.isEmpty(retailPrice.taxRate) && !MiscHelper.isEmpty(retailPrice.value)) {
                                var selector = '#retailPrices tr[data-id="' + j + '"] ';
                                MsgHelper.showMsgOnField(selector, theMsg, data[i].preventSave === false);
                            }
                        }
                        continue;
                    } else {
                        theField = FormHelper.prototype.determineFieldSelector.call(this, model, theField.replace("cplx:", ""), data[i].code);
                        if (theField === IGNORE_MESSAGE_FLAG) {
                            continue;
                        }
                    }
                } else {
                    //fieldname is from javax.validation
                    theField = theField.replace('.', '_');
                }
                if (isCreateMode && data[i].code === 'MDS340') {
                    continue;
                }
                if (_.string.startsWith(theField, 'collections_id_')) {
                    theField = theField.replace('collections_id_', 'collections_master_title_');
                }

                if ($("#" + theField).length > 0) {
                    MsgHelper.showMsgOnField('#' + theField, theMsg, data[i].preventSave === false);
                } else if (data[i].code === 'MDS290') {
                    var selections = $('.error-MDS290 .select2-chosen');
                    var duplicates = [];
                    for (var k = 0; k < selections.length - 1; k++) {
                        for (var j = 1; j < selections.length; j++) {
                            if ($(selections[k]).text() === $(selections[j]).text()) {
                                if ($.inArray(selections[k], duplicates) === -1) {
                                    duplicates.push(selections[k]);
                                }
                                if ($.inArray(selections[j], duplicates) === -1) {
                                    duplicates.push(selections[j]);
                                }
                            }
                        }
                    }
                    for (var k = 0; k < duplicates.length; k++) {
                        var formFeature = $(duplicates[k]).closest('.select2-container');
                        var index = formFeature[0].id.split('_')[3]
                        var path = '.record_' + index + ' .error-MDS290';
                        MsgHelper.showMsgOnField(path, theMsg, data[i].preventSave === false);
                    }
                } else if (data[i].code === 'MDS108' || data[i].code === 'MDS112' || data[i].code === 'MDS123'
                    || data[i].code === 'MDS124') {
                    var type = model.get('type');
                    var selector;
                    if (type === 'duoBundle') {
                        selector = '#block_priceDuoBundle .error-';
                    } else if (type === 'multiBundle') {
                        selector = '#block_priceMultiBundle .error-';
                    } else if (type === 'journal') {
                        if (model.get('bundleswitch') === 'identicTax') {
                            selector = '#block_pricesJournal .error-';
                        } else {
                            selector = '#block_pricesJournalBundle .error-';
                        }
                    } else {
                        selector = '#block_prices .error-';
                    }
                    selector += data[i].code;
                    MsgHelper.showMsgOnField(selector, theMsg, data[i].preventSave === false);
                } else if (data[i].code && $('.error-' + data[i].code).length > 0) {
                    MsgHelper.showMsgOnField('.error-' + data[i].code, theMsg, data[i].preventSave === false);
                    if (data[i].code === 'MDS315') {
                        $('.error-MDS315 .controls').each(function () {
                            if (data[i].preventSave === false) {
                                $(this).parents('.control-group').addClass('warning');
                            } else {
                                $(this).parents('.control-group').addClass('error');
                            }
                        });
                    }
                }
                else if (data[i].code === 'MDS250') {
                    MsgHelper.showMsgOnField('fieldset.publishers div.lastRow .controls-row', theMsg, data[i].preventSave === false);
                }
                else {
                    if (theMsg.length > 0) {
                        placeHolders = StringUtil.getPlaceHolders(theMsg);
                        if (placeHolders !== undefined && placeHolders.length > 0) {
                            theMsg = theMsg.replace('{' + placeHolders[0] + '}', data[i].field);
                        }
                        MsgHelper.showErrorAlert(theMsg, data[i].preventSave === false);
                    }
                }

            }
        } else {
            MsgHelper.showErrorAlert(MsgHelper.getMessage(data[i].msg), data[i].preventSave === false);
        }

    }
};

module.exports = new FormHelper;