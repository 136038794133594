var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <p>"
    + escapeExpression(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"text","hash":{},"data":data}) : helper)))
    + "</p>\r\n";
},"3":function(depth0,helpers,partials,data,depths) {
  var helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing;
  return "                                    <div class=\"form-wrap-inner\">\r\n                                        <input type=\"radio\" name=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].ident : depths[1]), depth0))
    + "\" value=\""
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\">\r\n                                        <label>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.confirmationRadio.item", (depth0 != null ? depth0.label : depth0), {"name":"label2","hash":{},"data":data})))
    + "</label>\r\n                                    </div>\r\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div id=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "\" class=\"lb-default\">\r\n    <div class=\"add-icons\">\r\n        <a href=\"javascript:;\" onclick=\"return false;\" class=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-close myfancybox-close\" title=\"Close\">CLOSE</a>\r\n    </div>\r\n    <div class=\"add-icons\">\r\n        <a class=\"txt-link txt-link-help\" href=\""
    + escapeExpression(((helpers.msg || (depth0 && depth0.msg) || helperMissing).call(depth0, "help.link.search.productExport", {"name":"msg","hash":{},"data":data})))
    + "\"\r\n           target=\"_blank\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "help", {"name":"lbl","hash":{},"data":data})))
    + "</a>\r\n        <a href=\""
    + escapeExpression(((helpers.msg || (depth0 && depth0.msg) || helperMissing).call(depth0, "help.link.search.productExport", {"name":"msg","hash":{},"data":data})))
    + "\" target=\"_blank\" class=\"vlb-action-icon vlb-action-icon-help\"\r\n           title=\""
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "help", {"name":"lbl","hash":{},"data":data})))
    + "\"><span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "help", {"name":"lbl","hash":{},"data":data})))
    + "</span></a>\r\n    </div>\r\n\r\n    <h2><span>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.confirmationDownload.headline", (depth0 != null ? depth0.ident : depth0), {"name":"label2","hash":{},"data":data})))
    + "</span></h2>\r\n\r\n    <p>"
    + escapeExpression(((helper = (helper = helpers.headerText || (depth0 != null ? depth0.headerText : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"headerText","hash":{},"data":data}) : helper)))
    + "</p>\r\n\r\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.warnings : depth0), {"name":"each","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\r\n    <!--<p>"
    + escapeExpression(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"text","hash":{},"data":data}) : helper)))
    + "</p>-->\r\n\r\n    <form class=\"form-horizontal\">\r\n\r\n        <div id=\"confirmationDownload\">\r\n            <div class=\"form-group\">\r\n                <div class=\"row\">\r\n                    <div class=\"col-xs-4\">\r\n                        <div id=\"cdFormat\">\r\n                            <fieldset style=\"border-color: #000000; border-style: solid;\">\r\n                                <legend style=\"display: inline; margin-bottom: 20px; font-size: 15px;\">\r\n                                    "
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "pageContent.download.formatSelection", {"name":"lbl","hash":{},"data":data})))
    + "\r\n                                </legend>\r\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.collection : depth0), {"name":"each","hash":{},"fn":this.program(3, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                            </fieldset>\r\n                        </div>\r\n\r\n\r\n\r\n                        <div id=\"cdAdditionals\" style=\"visibility: hidden; padding-top: 7px;\">\r\n                            "
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "pageContent.download.delimiter", {"name":"lbl","hash":{},"data":data})))
    + "\r\n                            <div class=\"form-wrap-inner\">\r\n                                <input type=\"radio\" name=\"separatorCheck\" value=\"select\" checked=\"checked\">\r\n                                <label>\r\n                                    <select id=\"delimiterSelect\" style=\"width: 145px;\">\r\n                                        <option value=\";\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "pageContent.download.delimiterSelect.semicolon", {"name":"lbl","hash":{},"data":data})))
    + "</option>\r\n                                        <option value=\",\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "pageContent.download.delimiterSelect.comma", {"name":"lbl","hash":{},"data":data})))
    + "</option>\r\n                                        <option value=\" \">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "pageContent.download.delimiterSelect.blank", {"name":"lbl","hash":{},"data":data})))
    + "</option>\r\n                                        <option value=\"&#09;\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "pageContent.download.delimiterSelect.tab", {"name":"lbl","hash":{},"data":data})))
    + "</option>\r\n                                    </select>\r\n                                </label>\r\n                            </div>\r\n\r\n                            <div class=\"form-wrap-inner\" style=\"margin-top: 7px;\">\r\n                                <input type=\"radio\" name=\"separatorCheck\" value=\"free\">\r\n                                <label>\r\n                                    "
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "pageContent.download.delimiterSelect.other", {"name":"lbl","hash":{},"data":data})))
    + "\r\n                                    <input id=\"separatorSign\" name=\"separatorSign\" type=\"text\" maxlength=\"1\" size=\"2\"\r\n                                           style=\"width: 82px;\">\r\n                                </label>\r\n                            </div>\r\n                            <br/>\r\n                            "
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "pageContent.download.textDelimiter", {"name":"lbl","hash":{},"data":data})))
    + "\r\n                            <div class=\"form-wrap-inner\" style=\"margin-left: -9px;\">\r\n                                <label>\r\n                                    <select id=\"textRecognition\">\r\n                                        <option value=\"&#34;\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "pageContent.download.textDelimiter.doubleQuotes", {"name":"lbl","hash":{},"data":data})))
    + "</option>\r\n                                        <option value=\"&#39;\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "pageContent.download.textDelimiter.singleQuotes", {"name":"lbl","hash":{},"data":data})))
    + "</option>\r\n                                        <option value=\"\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "pageContent.download.textDelimiter.none", {"name":"lbl","hash":{},"data":data})))
    + "</option>\r\n                                    </select>\r\n                                </label>\r\n                            </div>\r\n\r\n                        </div>\r\n                    </div>\r\n\r\n                    <div id=\"cdColumns\" class=\"col-xs-4\" style=\"visibility: hidden; height: 300px;\">\r\n                        <fieldset style=\"border-color: #000000; border-style: solid;\">\r\n                            <legend style=\"display: inline; margin-bottom: 20px; font-size: 15px;\">\r\n                                "
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "pageContent.download.columns", {"name":"lbl","hash":{},"data":data})))
    + "\r\n                            </legend>\r\n                            <div class=\"form-wrap-inner\">\r\n                                <input type=\"checkbox\" name=\"columnHeading\" value=\"columnHeading\" checked=\"checked\">\r\n                                <label>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.confirmationRadio.item", "columnHeading", {"name":"label2","hash":{},"data":data})))
    + "</label>\r\n                            </div>\r\n                            <div class=\"form-wrap-inner\">\r\n                                <input type=\"radio\" name=\"columns\" value=\"full\" checked=\"checked\">\r\n                                <label>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.confirmationRadio.item", "full", {"name":"label2","hash":{},"data":data})))
    + "</label>\r\n                            </div>\r\n                            <div class=\"form-wrap-inner\">\r\n                                <input type=\"radio\" name=\"columns\" value=\"short\">\r\n                                <label>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.confirmationRadio.item", "short", {"name":"label2","hash":{},"data":data})))
    + "</label>\r\n                            </div>\r\n                            <div class=\"form-wrap-inner\">\r\n                                <input type=\"radio\" name=\"columns\" value=\"user\">\r\n                                <label>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.confirmationRadio.item", "user", {"name":"label2","hash":{},"data":data})))
    + "</label>\r\n                            </div>\r\n                            <br/>\r\n\r\n                            <div class=\"savedSelection\" style=\"visibility: hidden;\">\r\n                                <input type=\"hidden\" class=\"js-data-example-ajax\">\r\n                            </div>\r\n                        </fieldset>\r\n\r\n                        <div id=\"cdPublisherData\" style=\"visibility: hidden; margin-top: -163px;\">\r\n                            <fieldset style=\"border-color: #000000; border-style: solid;\">\r\n                                <legend style=\"display: inline; margin-bottom: 20px; font-size: 15px;\">\r\n                                    "
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "pageContent.download.columns", {"name":"lbl","hash":{},"data":data})))
    + "\r\n                                </legend>\r\n                                <div class=\"form-wrap-inner\">\r\n                                    <input type=\"checkbox\" name=\"publisherData\" value=\"publisherData\">\r\n                                    <label>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.confirmationRadio.item", "publisherData", {"name":"label2","hash":{},"data":data})))
    + "</label>\r\n                                </div>\r\n                            </fieldset>\r\n                        </div>\r\n\r\n                        <div id=\"bwaOrderHint\" style=\"visibility: hidden;\">\r\n                            <span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.confirmationRadio.item.bwaOrder.hint", {"name":"lbl","hash":{},"data":data})))
    + "</span>\r\n                        </div>\r\n                    </div>\r\n\r\n                    <div id=\"cdSelection\" class=\"col-xs-4\" style=\"visibility: hidden;\">\r\n                        <fieldset style=\"border-color: #000000; border-style: solid;\">\r\n                            <legend style=\"display: inline; margin-bottom: 20px; font-size: 15px;\">\r\n                                "
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "pageContent.download.dataField", {"name":"lbl","hash":{},"data":data})))
    + "\r\n                            </legend>\r\n                            <div style=\"overflow: auto;\">\r\n                                <ul id=\"simpleList\" class=\"list-group\" style=\"height: 220px;\"></ul>\r\n                            </div>\r\n                            <div class=\"cdLineSeperator\"></div>\r\n                            <div class=\"form-wrap-inner savedSelection\">\r\n                                <input type=\"checkbox\" name=\"selectAll\" value=\"selectAll\" checked=\"checked\">\r\n                                <label>"
    + escapeExpression(((helpers.label2 || (depth0 && depth0.label2) || helperMissing).call(depth0, "form.confirmationRadio.item", "selectAll", {"name":"label2","hash":{},"data":data})))
    + "</label>\r\n                            </div>\r\n                            <div class=\"savedSelection form-actions\"\r\n                                 style=\"visibility: hidden; margin-top: 15px;\">\r\n                                <input id=\"selectionName\" name=\"selectionName\" type=\"text\" size=\"2\"\r\n                                       style=\"width: 50%; float: left;\" placeholder=\""
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "pageContent.download.safeAsTemplate", {"name":"lbl","hash":{},"data":data})))
    + "\">\r\n                                <a class=\"vlb-action-icon vlb-action-icon-save small "
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-save\"\r\n                                   style=\"float: left; margin-left: 5px;\"></a>\r\n\r\n                                <div class=\"vlb-icon vlb-icon-input-check\"\r\n                                     style=\"float: left; margin-left: 5px; display: none\"></div>\r\n                            </div>\r\n                        </fieldset>\r\n                    </div>\r\n                </div>\r\n            </div>\r\n        </div>\r\n\r\n        <div class=\"form-actions\">\r\n        	<div class=\"row\">\r\n            	<div id=\""
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-msg\" class=\"col-xs-12 col-sm-7 col-md-7\">\r\n                   <div id=\"error\" style=\"display: none\">\r\n                       <div class=\"vlb-icon vlb-icon-input-error\"></div>\r\n                       <span style=\"color: #e24844;\" class=\"help-inline nod_msg\"></span>\r\n                   </div>\r\n                   <!--<div id=\"valid\" style=\"display: none\">-->\r\n                   <!--<div class=\"vlb-icon vlb-icon-input-check\"></div>-->\r\n                   <!--<span style=\"color: #14aa96;\" class=\"help-inline nod_msg\"></span>-->\r\n                   <!--</div>-->\r\n               	</div>\r\n	        	<div class=\"action-elements col-xs-12 col-sm-5 col-md-5\">\r\n	        		<div class=\"row\">\r\n		            	<input id=\"toBeSend\" type=\"text\" placeholder=\""
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "pageContent.download.fileName", {"name":"lbl","hash":{},"data":data})))
    + "\" class=\"pull-right\">\r\n		            </div>\r\n		            <div class=\"row\">\r\n						<a href=\"javascript:;\" onclick=\"return false;\" class=\"btn green btn-arrow disabled "
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-confirm pull-right\">\r\n			                <span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "pageContent.download.doTheDownload", {"name":"lbl","hash":{},"data":data})))
    + "</span>\r\n			            </a>\r\n			            <a href=\"javascript:;\" onclick=\"return false;\" class=\"btn green btn-arrow "
    + escapeExpression(((helper = (helper = helpers.ident || (depth0 != null ? depth0.ident : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"ident","hash":{},"data":data}) : helper)))
    + "-close pull-right\">\r\n			                <span>"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "pageContent.download.cancel", {"name":"lbl","hash":{},"data":data})))
    + "</span>\r\n			            </a>\r\n		            </div>\r\n	            </div>\r\n        	</div>\r\n        </div>\r\n    </form>\r\n</div>\r\n";
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}