var ModelController = require('./model_controller');
var Account = require('models/account');
var FormDefinition = require('models/formDefinition');

var AccountController = ModelController.extend({
    initialize: function (options) {
        this.logger = log4javascript.getLogger('controllers.AccountController');
    },

    getNewModel: function(param) {
		if(param.id !== undefined) {
			return new Account({id: param.id});
		}
		return new Account();
	},

});

module.exports = AccountController;