var __templateData = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "<!-- modal dialog used for email templates -->\n<div class=\"modal fade\" id=\"exportModal\" tabindex=\"-1\" role=\"dialog\" aria-hidden=\"true\">\n\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n            <div class=\"modal-header\">\n                <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-hidden=\"true\">&times;</button>                \n            </div>\n            <div class=\"modal-body\">\n                <table>\n                    <tr>\n                        <td>\n                            "
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "email.to", {"name":"lbl","hash":{},"data":data})))
    + "\n                        </td>\n                        <td>\n                            <label id=\"mail-to\" />\n                        </td>\n                    </tr>\n                    <tr>\n                        <td>\n                            "
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "email.cc", {"name":"lbl","hash":{},"data":data})))
    + "\n                        </td>\n                        <td>\n                            <input type=\"text\" id=\"mail-cc\" size=\"50\"/>\n                        </td>\n                    </tr>\n                    <tr>\n                        <td>\n                            "
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "email.bcc", {"name":"lbl","hash":{},"data":data})))
    + "\n                        </td>\n                        <td>\n                            <input type=\"text\" id=\"mail-bcc\" size=\"50\"/>\n                        </td>\n                    </tr>\n                    <tr>\n                        <td>\n                            "
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "email.subject", {"name":"lbl","hash":{},"data":data})))
    + "\n                        </td>\n                        <td>\n                            <input type=\"text\" id=\"mail-subject\" val=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.subject : stack1), depth0))
    + "\" size=\"50\"/>\n                        </td>\n                    </tr>\n                    <tr>                       \n                        <td colspan=\"2\">\n                            <div contenteditable=\"true\" id=\"mail-body\">\n                                "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.body : stack1), depth0))
    + "\n                            </div>\n                        </td>\n                    </tr>\n                </table>\n            </div>\n            <div class=\"modal-footer\">\n                <button type=\"button\" class=\"btn btn-default\" data-dismiss=\"modal\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.button.cancel", {"name":"lbl","hash":{},"data":data})))
    + "</button>\n                <button type=\"button\" class=\"btn btn-default sendCredentialsConfirmed\" data-dismiss=\"modal\">"
    + escapeExpression(((helpers.lbl || (depth0 && depth0.lbl) || helperMissing).call(depth0, "form.button.send", {"name":"lbl","hash":{},"data":data})))
    + "</button>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}