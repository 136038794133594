require('lib/view_helper');
var StringUtil = require('lib/string_util');

// Base class for all views
module.exports = Chaplin.View.extend({
    initialize: function (options) {
    	Chaplin.View.prototype.initialize.apply(this);
        this.options = options;
        this.on("addWidget", this.addWidget);
    },
    
    getTemplateFunction: function () {
        return this.template;
    },

    template: function () {
    },
    
    getTemplateData: function () {
        var data = {};
        if (this.model) {
            data.model = this.model.attributes; //old template code is using model props this way
            data.modelReal = this.model; // real chaplin model class is need in composite widgets
            data.modelKey = this.model.modelKey;
            data.modelUrlRoot = this.model.urlRoot;
        }
        data.user = user;
        return data;
    }
});
